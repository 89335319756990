import GlobalState from "globalState";

const getInfrastructureState = (state: GlobalState) => state.infrastructureArea;
const getAccountsState = (state: GlobalState) => getInfrastructureState(state).accounts;
const getMachinesState = (state: GlobalState) => getInfrastructureState(state).machines;
const getEndpointsState = (state: GlobalState) => getInfrastructureState(state).endpoints;
const getEndpointSelectionState = (state: GlobalState) => getEndpointsState(state).selection;
const getAccounts = (state: GlobalState) => getAccountsState(state).byId;
export const getAccountById = (state: GlobalState, props: {accountId: string}) => {
    return getAccounts(state)[props.accountId];
};
export const getCurrentMachine = (state: GlobalState) => getMachinesState(state).current;
export const getSelectedEndpoint = (state: GlobalState): string => getEndpointSelectionState(state).id;
