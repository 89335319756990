exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_box__bdd4A {\n  padding: 0.5rem;\n  align-self: stretch;\n  font-weight: 400;\n  margin-right: 0.5rem;\n}\n.style_taskSucceeded__1n_w0 {\n  background: rgba(72, 179, 80, 0.1);\n  padding: 0.5rem;\n  align-self: stretch;\n  font-weight: 400;\n  margin-right: 0.5rem;\n  color: #48B350;\n}\n.style_taskSucceeded__1n_w0 .style_title__2M_kp {\n  color: #48B350;\n}\n.style_taskFailed__3H-8r {\n  background: rgba(219, 68, 55, 0.1);\n  padding: 0.5rem;\n  align-self: stretch;\n  font-weight: 400;\n  margin-right: 0.5rem;\n  color: #db4437;\n}\n.style_taskFailed__3H-8r .style_title__2M_kp {\n  color: #db4437;\n}\n.style_taskTime__28SDQ {\n  margin-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"box": "style_box__bdd4A",
	"taskSucceeded": "style_taskSucceeded__1n_w0",
	"title": "style_title__2M_kp",
	"taskFailed": "style_taskFailed__3H-8r",
	"taskTime": "style_taskTime__28SDQ"
};