import * as React from "react";
import {List} from "../../../../../components/List/List";
import {repository} from "../../../../../clientInstance";
import {DeploymentResource} from "../../../../../client/resources/deploymentResource";
import {ResourceCollection} from "../../../../../client/resources/resourceCollection";
import {TaskStatusDetails} from "../../../../projects/components/ProjectDashboard/TaskStatusDetails/TaskStatusDetails";
import {TaskResource} from "../../../../../client/resources/taskResource";
import {ReleaseResource} from "../../../../../client/resources/releaseResource";
import {values, keyBy} from "lodash";

interface TaskHistoryProps {
    environmentIds?: string[];
    tenantIds?: string[];
    projectIds?: string[];
    currentDeploymentId?: string;
    header?: JSX.Element;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}
interface TaskHistoryState {
    page: number;
    deployments?: ResourceCollection<DeploymentResource>;
    tasks: {[taskId: string]: TaskResource<any>};
    releases: {[releaseId: string]: ReleaseResource};
}
export default class TaskHistory extends React.Component<TaskHistoryProps, TaskHistoryState> {
    pageSize: number = 4;

    constructor(props: TaskHistoryProps) {
        super(props);
        this.state = {
            page: 0,
            releases: {},
            tasks: {}
        };
    }

    componentDidMount() {
        this.props.doBusyTask(async () => {
            const deployments = await repository.Deployments.list({
                take: this.pageSize,
                environments: this.props.environmentIds,
                tenants: this.props.tenantIds,
                projects: this.props.projectIds,
                skip: this.state.page * this.pageSize
            });

            const {releases: newRelease, tasks: newTasks} = await this.loadMissingTasksAndReleases(deployments.Items);

            this.setState({
                deployments,
                releases: {...this.state.releases, ...keyBy(newRelease, r => r.Id)},
                tasks: {...this.state.tasks, ...keyBy(newTasks, r => r.Id)}
            });
        });
    }

    loadMissingTasksAndReleases = async (deployments: DeploymentResource[]): Promise<{releases: ReleaseResource[], tasks: Array<TaskResource<any>>}> => {
        const releasePromises: {[releaseId: string]: Promise<ReleaseResource>} = {};
        const taskPromises: {[taskId: string]: Promise<TaskResource<any>>} = {};

        deployments.map((deployment: DeploymentResource) => {
            if (!this.state.releases[deployment.ReleaseId] && !releasePromises[deployment.ReleaseId]) {
                releasePromises[deployment.ReleaseId] = repository.Releases.get(deployment.ReleaseId);
            }

            if (!this.state.tasks[deployment.TaskId] && !taskPromises[deployment.TaskId]) {
                taskPromises[deployment.TaskId] = repository.Tasks.get(deployment.TaskId);
            }
        });

        return {
            releases:  await Promise.all(values(releasePromises)),
            tasks:  await Promise.all(values(taskPromises)),
        };
    }

    render() {
        if (!this.state.deployments || !this.state.deployments.Items) {
            return null;
        }

        const matchingDeployments =
            this.state.deployments.Items.filter(e => e.Id !== this.props.currentDeploymentId);

        return <div>
            {matchingDeployments.length > 0 && this.props.header}
            {matchingDeployments.slice(0, 3)
                .map(m => {
                    const item = {...this.state.tasks[m.TaskId], TaskId: m.TaskId, ReleaseVersion: this.state.releases[m.ReleaseId].Version, Name: m.Name};
                    return <TaskStatusDetails item={item} key={m.Id} showName={true} style={{maxWidth: "none"}} />; }
                )}
        </div>;
    }
}