import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ActionEditProps} from "components/Actions/pluginRegistry";
import {KubernetesServiceProperties} from "components/Actions/kubernetes/kubernetesProperties";
import {KubernetesServiceComponent} from "components/Actions/kubernetes/kubernetesServiceComponent";
import * as React from "react";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import {default as ExpandableFormSection} from "components/form/Sections/ExpandableFormSection";
import {JsonUtils} from "utils/jsonUtils";
import * as _ from "lodash";
import Summary from "components/form/Sections/Summary";
import pluginRegistry from "components/Actions/pluginRegistry";
import {ActionExecutionLocation} from "client/resources";
import {TargetRoles} from "areas/projects/components/DeploymentProcess/ActionDetails";
import {ActionSummaryProps} from "components/Actions/actionSummaryProps";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import Note from "components/form/Note/Note";

class KubernetesDeployServiceActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Deploy a service resource to Kubernetes
        </div>;
    }
}

class KubernetesDeployServiceActionEdit extends BaseComponent<ActionEditProps<KubernetesServiceProperties>, never> {
    constructor(props: ActionEditProps<KubernetesServiceProperties>) {
        super(props);
    }

    render() {
        return <div>
            <KubernetesServiceComponent
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                getFieldError={this.props.getFieldError}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                errors={this.props.errors}
                projectId={this.props.projectId}
                expandedByDefault={true}/>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Service Labels"
                summary={this.labelsSummary()}
                help={"The labels applied to the service resource"}>
                <Note>
                    Labels are optional name/value pairs that are assigned to the Service resource.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-service#service-labels">service labels</ExternalLink>.
                </Note>
                <StringKeyValueEditList
                    items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]}
                    name="Label"
                    separator="="
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })}
                    valueLabel="Value"
                    keyLabel="Name"
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.SelectorLabels"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Service Selector Labels"
                summary={this.selectorLabelsSummary()}
                help={"The selector labels used to match pod resources"}>
                <Note>
                    Service selector labels are used to match the pods that traffic will be sent to.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-service#service-selector-labels">service selector labels</ExternalLink>.
                </Note>
                <StringKeyValueEditList
                    items={this.props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"]}
                    name="Selector Label"
                    separator="="
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.SelectorLabels"]: val })}
                    valueLabel="Value"
                    keyLabel="Name"
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                />
            </ExpandableFormSection>
        </div>;
    }

    private selectorLabelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.SelectorLabels"], {}));

        if (labels.length === 0) {
            return Summary.placeholder("The service resource selector labels");
        }

        return Summary.summary(<span>Selecting pod resources with the label{labels.length > 1 && <span>s</span>} {
            _.chain(labels)
                .flatMap(pair => [<strong>{pair[0]}: {pair[1]}</strong>, <span>, </span>])
                .slice(0, -1)
                .value()
        }</span>);
    }

    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));

        if (labels.length === 0) {
            return Summary.placeholder("The service resource labels");
        }

        return Summary.summary(<span>Add label{labels.length > 1 && <span>s</span>} {
            _.chain(labels)
                .flatMap(pair => [<strong>{pair[0]}: {pair[1]}</strong>, <span>, </span>])
                .slice(0, -1)
                .value()
        }</span>);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployService",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployServiceActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeployServiceActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false
});