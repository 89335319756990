exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_serverUri__25VtQ {\n  word-wrap: break-word;\n  word-break: break-all;\n}\n.style_filterBoxStandardWidth__2Lk7N {\n  width: 15.6rem !important;\n  /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */\n}\n", ""]);

// exports
exports.locals = {
	"serverUri": "style_serverUri__25VtQ",
	"filterBoxStandardWidth": "style_filterBoxStandardWidth__2Lk7N"
};