import GlobalState from "../../../globalState";

const ProjectActions = {
    PROJECT_FETCHED: "PROJECT_FETCHED",
    PROJECT_SAVED: "PROJECT_SAVED",
    PROJECT_STEPS_UPDATED: "PROJECT_STEPS_UPDATED",
    PROJECT_UPDATED: "PROJECT_UPDATED",
};

export interface ProjectsAreaState {
    currentProject: ProjectState;
}

export interface ProjectState {
    name: string;
    description: string;
    logoUrl: string;
    id: string;
    projectGroupId: string;
    isDisabled: boolean;
    numberOfSteps: number;
    slug: string;
}

export function projectFetched(state: ProjectState) {
    return { type: ProjectActions.PROJECT_FETCHED, state };
}

export function projectSaved(newState: ProjectState) {
    return { type: ProjectActions.PROJECT_SAVED, state: newState };
}

export function projectStepsUpdated(numberOfSteps: number) {
    // We need to update just the numberOfSteps property of our currentProject...
    return async (dispatch: any, getState: () => GlobalState) => {
        const currentProject = getState().projectsArea.currentProject;
        dispatch(projectUpdated({
            id: currentProject.id,
            name: currentProject.name,
            description: currentProject.description,
            projectGroupId: currentProject.projectGroupId,
            logoUrl: currentProject.logoUrl,
            isDisabled: currentProject.isDisabled,
            slug: currentProject.slug,
            numberOfSteps,
        }));
    };
}

export function projectUpdated(state: ProjectState) {
    return { type: ProjectActions.PROJECT_UPDATED, state };
}

const projectsArea = (state: ProjectsAreaState = {currentProject: null}, action: any): ProjectsAreaState => {
    switch (action.type) {
        case ProjectActions.PROJECT_SAVED:
        case ProjectActions.PROJECT_FETCHED:
        case ProjectActions.PROJECT_STEPS_UPDATED:
        case ProjectActions.PROJECT_UPDATED:
            const project = action.state as ProjectState;
            return {
                currentProject: {
                    name: project.name,
                    description: project.description,
                    logoUrl: project.logoUrl,
                    projectGroupId: project.projectGroupId,
                    id: project.id,
                    isDisabled: project.isDisabled,
                    numberOfSteps: project.numberOfSteps,
                    slug: project.slug
                },
            };
        default:
            return state;
    }
};

export default projectsArea;