exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_descriptionIcon__3vCFC {\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n.style_highlightedDescriptionIcon__4ZTVy {\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n.style_highlightedDescriptionIcon__4ZTVy:hover {\n  opacity: 1;\n}\n.style_iconSizing__3yQTk {\n  height: 1.5rem;\n  width: 1.5rem;\n}\n.style_canClick__28U8G {\n  cursor: pointer;\n}\n.style_iconContainer__KW3vC {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"descriptionIcon": "style_descriptionIcon__3vCFC",
	"highlightedDescriptionIcon": "style_highlightedDescriptionIcon__4ZTVy",
	"iconSizing": "style_iconSizing__3yQTk",
	"canClick": "style_canClick__28U8G",
	"iconContainer": "style_iconContainer__KW3vC"
};