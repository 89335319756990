import Resource from "./resource";

export class RootResource extends Resource {
    Application: string;
    Version: string;
    ApiVersion: string;
    InstallationId: string;
    ExtensionsCSS: string[];
    ExtensionsJavascript: string[];
    ExtensionAngularModules: string[];
    IsEarlyAccessProgram: boolean;
    HasLongTermSupport: boolean;
}

export default RootResource;