exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_filterBox__3fxv7 {\n  margin-left: 1rem;\n  margin-top: 2.6rem;\n}\n.style_filter__1YTGK {\n  width: 50rem;\n  align-items: flex-end;\n}\n.style_row__alQYM {\n  display: flex;\n  flex-direction: row;\n}\n.style_avatar__3qPfv {\n  vertical-align: middle;\n  justify-content: center;\n  padding-right: 0.5rem;\n}\n.style_linkWithCopy__2SqYG {\n  display: inline-flex;\n  flex-direction: row;\n}\n.style_linkWithCopy__2SqYG .style_copyAction__397F_ {\n  margin: -1rem 0;\n}\n.style_linkWithCopy__2SqYG .style_link__1OK38 {\n  width: 400px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", ""]);

// exports
exports.locals = {
	"filterBox": "style_filterBox__3fxv7",
	"filter": "style_filter__1YTGK",
	"row": "style_row__alQYM",
	"avatar": "style_avatar__3qPfv",
	"linkWithCopy": "style_linkWithCopy__2SqYG",
	"copyAction": "style_copyAction__397F_",
	"link": "style_link__1OK38"
};