import * as React from "react";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
const styles = require("./style.less");
import { UnstructuredFormSection, ExpandableFormSection, SummaryNode } from "components/form";
import { OverflowMenu } from "components/Menu";
import { IId } from "client/resources";

import * as cn from "classnames";

interface RemovableExpandersListProps<R extends IId> {
    typeDisplayName: string;
    empty?: React.ReactNode;
    data: R[];
    listActions?: JSX.Element[];
    helpElement?: JSX.Element;
    onRow(item: R, idx: number): React.ReactNode;
    onRowSummary(item: R): SummaryNode;
    onRowHelp(item: R): string;
    onRemoveRowByIndex?(index: number): void;
}

export abstract class RemovableExpandersList<R extends IId> extends BaseComponent<RemovableExpandersListProps<R>, any> {
    private bottomActionVisibilityThreshold = 3;

    constructor(props: RemovableExpandersListProps<R>) {
        super(props);
    }

    render() {
        const actions = (this.props.listActions || []).map((action, index) => React.cloneElement(action, { key: index }));
        return (
            <React.Fragment>
                <UnstructuredFormSection>
                    <div className={styles.headerBar}>
                        <div>{this.props.helpElement}</div>
                        <div className={styles.actionsMenu}>{actions}</div>
                    </div>
                </UnstructuredFormSection>
                {this.props.data.length === 0 && this.props.empty && <div className={styles.emptyList}>{this.props.empty}</div>}
                {this.props.data.length !== 0 && <div>
                    {this.props.data.map((item, index) => this.buildItem(item, index))}
                </div>}
                <UnstructuredFormSection>
                    <div className={cn({ [styles.hidden]: this.props.data.length < this.bottomActionVisibilityThreshold }, styles.actionsMenu)}>{actions}</div>
                </UnstructuredFormSection>
            </React.Fragment>
        );
    }

    private buildItem(item: R, index: number) {
        const removeAction = OverflowMenu.item(`Remove ${this.props.typeDisplayName}`, () => this.props.onRemoveRowByIndex(index));
        const visualIndex = index + 1;
        return <ExpandableFormSection
            key={`${visualIndex}-${item.Id}`} // An Id may not exist yet for this item, so needs to be a combination of index+Id.
            errorKey={`${visualIndex}-${item.Id}`}
            title={`${this.props.typeDisplayName} ${visualIndex}`}
            overflowMenuItems={[removeAction]}
            summary={this.props.onRowSummary(item)}
            help={this.props.onRowHelp(item)}
            isExpandedByDefault={!item.Id} // Expand automatically for new entries.
        >
            {this.props.onRow(item, index)}
        </ExpandableFormSection>;
    }
}