import * as React from "react";
import { LifecycleResource, PhaseResource, RetentionPeriod } from "client/resources";
import envCount from "../LifecycleMap/envCount";
import {
    Text, Note
} from "components/form";
import { List } from "material-ui/List";
import * as cn from "classnames";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";
import AddEnvironment from "areas/library/components/Lifecycle/AddEnvironment/AddEnvironment";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import SelectRetentionPolicy from "areas/library/components/Lifecycle/RetentionPolicy/SelectRetentionPolicy";
import ChangePhaseProgression from "areas/library/components/Lifecycle/ChangePhaseProgression/ChangePhaseProgression";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { EnvironmentResource } from "client/resources";
import ToolTip from "components/ToolTip";
const styles = require("./style.less");
import BorderedListItem from "components/BorderedListItem";
import { RetentionPolicyText } from "../RetentionPolicy/RetentionPolicySummary";
import { ActionButton } from "components/Button/ActionButton";
const iconStyles = require("components/PhaseStatusIcon/style.less");

interface PhaseProps {
    phase: PhaseResource;
    index: number;
    lifecycle: LifecycleResource;
    environmentsById: { [id: string]: EnvironmentResource };
    onPhaseDeleteClick(): void;
    onPhaseNameChange(value: string): void;
    onAutomaticEnvironmentDeleteClick(index: number): void;
    onOptionalEnvironmentDeleteClick(index: number): void;
    onAddEnvironment(environmentId: string, automatic: boolean): void;
    onPhaseProgressionChange(isOptional: boolean, minimumEnvironments: number): void;
    onChangeRetentionPolicy(releaseRetentionPolicy: RetentionPeriod, tentacleRetentionPolicy: RetentionPeriod): void;
}

class Phase extends BaseComponent<PhaseProps, never> {
    render() {
        return <div>
            <div className={styles.sectionControl}>
                <Text
                    className={styles.cardTitle}
                    id="phaseName"
                    value={this.props.phase.Name}
                    onChange={this.props.onPhaseNameChange}
                    label="Phase name"
                    style={{ width: "100%" }}
                    autoFocus={true}
                />
            </div>
            <div className={styles.sectionControl}>
                <h4>Environments</h4>
                <div>
                    {(envCount(this.props.phase) === 0) && <div>
                        <strong className={styles.anyEnvironment}>Any environment</strong>
                        <p>
                            When the release is in this phase, any
                            environment {this.props.lifecycle.Phases.length > 1 &&
                                <span>that is not explicitly named in another phase</span>} can
                            be deployed to. Use the <em>Add environment</em> button to limit the
                            phase to a specific list of environments.
                        </p>
                        <div className={styles.actionsMenu}>
                            {this.addEnvironmentButton()}
                        </div>
                    </div>}
                    {(envCount(this.props.phase) > 0) && <div>
                        <div className={styles.actionsMenu}>
                            {this.addEnvironmentButton()}
                        </div>
                        <List className={cn(styles.environmentList, styles.padUnder)}>
                            {this.props.phase.AutomaticDeploymentTargets.map((target, index) =>
                                <BorderedListItem
                                    key={target}
                                    primaryText={<div>
                                        <span className={cn(iconStyles.phaseIcon, iconStyles.automatic)}
                                            title="When the release enters this phase, this environment will be automatically deployed to" />
                                        {this.renderEnvironmentName(target)}
                                    </div>}
                                    rightIconButton={
                                        <IconButton
                                            onClick={() => this.props.onAutomaticEnvironmentDeleteClick(index)}
                                            touch={true} tooltip="Remove this environment">
                                            <ClearIcon />
                                        </IconButton>}
                                    disabled={true}
                                />)}

                            {this.props.phase.OptionalDeploymentTargets.map((target, index) =>
                                <BorderedListItem
                                    key={target}
                                    primaryText={<div>
                                        <span
                                            className={cn(iconStyles.phaseIcon, { [iconStyles.skipped]: this.props.phase.IsOptionalPhase })}
                                            title="When the release enters this phase, this environment can be manually deployed to" />
                                        {this.renderEnvironmentName(target)}
                                    </div>}
                                    rightIconButton={
                                        <IconButton
                                            onClick={() => this.props.onOptionalEnvironmentDeleteClick(index)}
                                            touch={true} tooltip="Remove this environment">
                                            <ClearIcon />
                                        </IconButton>}
                                    disabled={true}
                                />)}
                        </List>
                    </div>}
                </div>
            </div>
            <div className={styles.sectionControl}>
                <h4>Required to progress</h4>
                <div>
                    <ChangePhaseProgression onOk={this.props.onPhaseProgressionChange}
                        environmentCount={envCount(this.props.phase)}
                        isOptionalPhase={this.props.phase.IsOptionalPhase}
                        minimumEnvironmentsBeforePromotion={this.props.phase.MinimumEnvironmentsBeforePromotion}
                    />
                </div>
            </div>
            {!this.props.phase.ReleaseRetentionPolicy && <div className={styles.sectionControl}>
                <h4>Retention policy (inherited)</h4>
                <div>
                    <p>
                        <RetentionPolicyText releaseRetentionPolicy={this.props.lifecycle.ReleaseRetentionPolicy}
                            tentacleRetentionPolicy={this.props.lifecycle.TentacleRetentionPolicy} />
                    </p>
                    <div className={styles.actionsMenu}>
                        <ActionButton label={"Override Retention Policy"} onClick={() => this.props.onChangeRetentionPolicy(this.props.lifecycle.ReleaseRetentionPolicy, this.props.lifecycle.TentacleRetentionPolicy)} />
                    </div>
                </div>
            </div>}
            {this.props.phase.ReleaseRetentionPolicy && <div className={styles.sectionControl}>
                <h4>Retention policy</h4>
                <div>
                    <SelectRetentionPolicy releaseRetentionPolicy={this.props.phase.ReleaseRetentionPolicy}
                        tentacleRetentionPolicy={this.props.phase.TentacleRetentionPolicy}
                        onOk={(r, t) => this.props.onChangeRetentionPolicy(r, t)} />
                </div>
                <div className={styles.actionsMenu}>
                    <ActionButton label={"Reset Retention Policy"} onClick={() => this.props.onChangeRetentionPolicy(null, null)} />
                </div>
            </div>}
        </div>;
    }

    private renderEnvironmentName = (target: string) => {
        const text = "Missing Resource";

        // There are cases where this isn't right, but will just have 1 message to simplify
        // e.g. Variable Snapshots will show this for deleted environments, it's not an issue in that case, but good to show this.
        const description = `The environment document '${target}' referenced by this phase is no longer available or `
            + "you do not have permissions to see this resource. Please check with you Octopus Administrator regarding your "
            + "permissions. If you believe the resource is missing (and this is not permissions-related), please let Octopus "
            + "support know so that we can prevent this from happening in the future.";

        return this.props.environmentsById[target]
            ? this.props.environmentsById[target].Name
            : <ToolTip content={description}><span className={styles.missingResource}>{text}</span></ToolTip>;
    }

    private addEnvironmentButton = () => {
        return <OpenDialogButton label="Add Environment">
            <AddEnvironment onOk={this.props.onAddEnvironment}
                environmentsById={this.props.environmentsById}
                lifecycle={this.props.lifecycle} />
        </OpenDialogButton>;
    }
}

export default Phase;