export class ExtensionManager  {
    extensions: { [type: string]: { [name: string]: any }; } = {};

    getExtension(name: string, type: string) {
        return this.extensions && this.extensions[type]
            ? this.extensions[type][name]
            : null;
    }

    registerExtension(name: string, type: string, extension: any): void {
        if (!this.extensions[type]) {
            this.extensions[type] = {name, extension};
        }
        this.extensions[type][name] = extension;
    }
}

const extensionManager = new ExtensionManager();

export default extensionManager;