import * as React from "react";
import {repository} from "clientInstance";
import {ActionTemplateResource} from "client/resources";
import Summary from "components/form/Sections/Summary";
import {has} from "lodash";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import {RouteComponentProps} from "react-router";
import {default as TaskResource} from "client/resources/taskResource";
import {ActionTemplateParameterResource} from "client/resources/actionTemplateParameterResource";
import convertPropertyValueResourceToString from "components/convertPropertyValueResourceToString";
import Callout, {CalloutType} from "components/Callout/Callout";
import Markdown from "components/Markdown/index";
import {Section} from "components/Section/Section";
const styles = require("./style.less");
import AdHocScript, {AdHocScriptModel, AdHocScriptState} from "components/AdHocScript/AdHocScript";
import LibraryLayout from "areas/library/components/LibraryLayout/LibraryLayout";
import ActionProperties from "client/resources/actionProperties";
import ActionTemplateParameterInputExpandableFormElement from "../../../../components/ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";

interface RunActionTemplateParams {
    templateId: string;
}

interface RunActionTemplateModel extends AdHocScriptModel {
    Properties: ActionProperties;
    ActionTemplateId: string;
}

interface RunActionTemplateState extends AdHocScriptState<RunActionTemplateModel> {
    template?: ActionTemplateResource;
}

export default class RunActionTemplate extends AdHocScript<RouteComponentProps<RunActionTemplateParams>, RunActionTemplateState, RunActionTemplateModel> {

    constructor(props: any) {
        super(props);
    }

    getCustomInputs() {

        return this.state.template.Parameters && this.state.template.Parameters.length > 0 &&
            <div>
                <FormSectionHeading title="Parameters"/>
                {this.state.isRetry && this.state.template.Parameters.find(p => p.DisplaySettings["Octopus.ControlType"] === "Sensitive") &&
                <Callout type={CalloutType.Information}>
                    Please re-enter the sensitive values, they are not remembered across runs.
                </Callout>}
                {this.state.template.Parameters.map(p => <ActionTemplateParameterInputExpandableFormElement
                    parameter={p}
                    key={p.Name}
                    isExpandedByDefault={true}
                    value={this.state.model.Properties[p.Name]}
                    sourceItems={{}}
                    doBusyTask={this.doBusyTask}
                    onChange={(value) => this.handleParameterInputChanged(p.Name, value)} />)}
            </div>;
    }

    handleParameterInputChanged = (name: string, value: any) => {
        this.setState(state => ({
            model: {
                ...state.model,
                Properties: {
                    ...state.model.Properties,
                    [name]: value
                }
            }
        }));
    }

    getHeading() {
        return <div>
            <Section><Markdown markup={this.state.template.Description}/></Section>
            {this.dependsOnStepName(this.state.template) &&
                <Callout type={CalloutType.Danger}>
                    One of the parameters of this step template depends on previous deployment step name which isn't available in
                    this context. The step template may not work as expected.
                </Callout>}
        </div>;
    }

    async getInitialPartialModel(taskToRetry?: TaskResource<any>) {

        const template = await repository.ActionTemplates.get(this.props.match.params.templateId);
        this.setState({template});

        // The properties for this task can come from an existing task (Modify and Re-run option)
        // Otherwise use the default values from the template as a starting place
        const model = {
            Properties: this.fromExistingTaskOrDefaultValues(taskToRetry, template),
            ActionTemplateId: template.Id
        };

        return model;
    }

    getTitle() {
        return this.state.template.Name;
    }

    getTaskDescription() {
        return "Run step template: " + this.state.template.Name;
    }

    wrapWithLayout(content: React.ReactNode) {
        return  <LibraryLayout {...this.props}>
            {content}
        </LibraryLayout>;
    }

    private fromExistingTaskOrDefaultValues = (task: TaskResource, template: ActionTemplateResource) => {
        const empty: ActionProperties = {};

        return template.Parameters.map(p => {
            return {
                name: p.Name,
                value: (task && task.Arguments && task.Arguments.Properties && task.Arguments.Properties[p.Name]) || p.DefaultValue || null
            };
        }).filter(nameValue => !!nameValue.value)
            .reduce((properties, nameValue) => {
                properties[nameValue.name] = nameValue.value;
                return properties;
            }, empty);
    }

    private dependsOnStepName(template: ActionTemplateResource) {
        return template.Parameters && template.Parameters.some(p => p.DisplaySettings &&  p.DisplaySettings["Octopus.ControlType"] === "StepName");
    }
}