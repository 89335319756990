import * as React from "react";
import SimpleDataTable from "components/SimpleDataTable";

interface ActionPropertyTableProps {
    actionProperties: {};
    currentPropertyValues: any;
}

const ActionPropertyTable = (props: ActionPropertyTableProps) => {
        const data = Object.keys(props.actionProperties).map(ap => ({name: ap, value: props.currentPropertyValues[ap]}));
        return <SimpleDataTable
            data={data}
            headerColumns={["Property Name", "Property Value"]}
            onRow={buildRow} />;
    };

const buildRow = (dataPoint: {name: string; value: string; }) => {
    return [
        dataPoint.name,
        dataPoint.value,
    ];
};

export default ActionPropertyTable;