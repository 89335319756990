exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_description__2nM_J {\n  color: #666666;\n  font-size: 0.8125rem;\n  font-weight: 300;\n  line-height: 1rem;\n}\n.style_description__2nM_J p {\n  margin: 0.5rem 0;\n  font-size: 0.8125rem;\n  color: #666666;\n}\n", ""]);

// exports
exports.locals = {
	"description": "style_description__2nM_J"
};