exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_topMargin__t0_A5 {\n  margin-top: 1rem;\n  width: 100%;\n}\n.style_title__1j5K0 {\n  color: rgba(0, 0, 0, 0.3);\n  font-size: 0.75rem;\n  font-weight: 400;\n  margin-bottom: 0.25rem;\n}\n.style_checkboxNote__1BltZ {\n  margin-left: 2rem;\n}\n.style_message__10cC8 {\n  font-size: 0.75rem;\n}\n.style_message__10cC8 hr {\n  border-width: 2px;\n  margin-bottom-width: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"topMargin": "style_topMargin__t0_A5",
	"title": "style_title__1j5K0",
	"checkboxNote": "style_checkboxNote__1BltZ",
	"message": "style_message__10cC8"
};