import * as React from "react";
import {History} from "history";
import {Redirect, RouteComponentProps, withRouter} from "react-router";
import {resolvePathWithSpaceId} from "../resolvePathWithSpaceId";
type LocationDescriptor = History.LocationDescriptor;

interface InternalRedirectComponentProps {
    to: LocationDescriptor;
    push?: boolean;
    exact?: boolean;
    from?: string;
}

type InternalRedirectProps = InternalRedirectComponentProps & RouteComponentProps<any>;

const InternalRedirect: React.SFC<InternalRedirectProps> = (props: InternalRedirectProps) => {
    /* tslint:disable:jsx-ban-elements */
    return <Redirect from={props.from} to={resolvePathWithSpaceId(props.to, props.match.params.spaceId)} push={props.push} exact={props.exact} />;
};

const EnhancedInternalRedirect = withRouter(InternalRedirect);

const InternalRedirectWithHistory: React.SFC<InternalRedirectProps> = (props) => (
    <EnhancedInternalRedirect push={true} {...props} />
);

export default EnhancedInternalRedirect;

export { InternalRedirectWithHistory };