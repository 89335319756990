import Resource from "./resource";

export enum DashboardRenderMode {
    VirtualizeColumns = "VirtualizeColumns",
    VirtualizeRowsAndColumns = "VirtualizeRowsAndColumns"
}
export class PerformanceConfigurationResource extends Resource {
    DefaultDashboardRenderMode: DashboardRenderMode;
}

export default PerformanceConfigurationResource;