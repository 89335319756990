import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import { ActionSummaryProps } from "../actionSummaryProps";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import { ExpandableFormSection, Summary, SummaryNode } from "components/form";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { ProjectResource } from "client/resources";
import { KeyValueEditList } from "components/EditList";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import { BoundSelect } from "components/form/Select/Select";
import Note from "../../form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import InternalLink from "../../Navigation/InternalLink/InternalLink";
import routeLinks from "../../../routeLinks";

class DeployReleaseSummary extends BaseComponent<ActionSummaryProps, { project: ProjectResource }> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = {
            project: null
        };
    }
    async componentDidMount() {
        const id = this.props.properties["Octopus.Action.DeployRelease.ProjectId"] as string;
        const project = id.includes("#{") ? null : await repository.Projects.get(id);
        this.setState({ project });
    }
    render() {
        return <div>
            Deploy a release from project <b>{this.state.project ? this.state.project.Name : this.props.properties["Octopus.Action.DeployRelease.ProjectId"]}</b>.
        </div>;
    }
}

enum DeploymentCondition {
    Always = "Always",
    IfNotCurrentVersion = "IfNotCurrentVersion",
    IfNewer = "IfNewer"
}

interface DeployReleaseProperties {
    "Octopus.Action.DeployRelease.ProjectId": string;
    "Octopus.Action.DeployRelease.DeploymentCondition": string;
    "Octopus.Action.DeployRelease.Variables": string;
}

interface DeployReleaseEditState {
    projects: Array<{ value: string, text: string }>;
}

class DeployReleaseEdit extends BaseComponent<ActionEditProps<DeployReleaseProperties>, DeployReleaseEditState> {
    constructor(props: ActionEditProps<DeployReleaseProperties>) {
        super(props);
        this.state = {
            projects: []
        };
    }
    componentDidMount() {
        this.props.doBusyTask(async () => {
            if (!this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"]) {
                this.props.setProperties({ ["Octopus.Action.DeployRelease.DeploymentCondition"]: DeploymentCondition.Always });
            }
            const projects = await repository.Projects.all();
            this.setState({ projects: projects.filter(p => p.Id !== this.props.projectId).map(p => ({ value: p.Id, text: p.Name })) });
        });
    }

    render() {
        const properties = this.props.properties;

        return <div>
            <ExpanderSectionHeading title="Deploy a release" />
            <ExpandableFormSection
                errorKey="Octopus.Action.DeployRelease.ProjectId"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Project"
                summary={this.summary()}
                help={<div>Select a project that will be deployed.</div>}>
                <BoundSelect
                    label="Select a Project"
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={""}
                    items={this.state.projects}
                    onChange={val => this.props.setProperties({ ["Octopus.Action.DeployRelease.ProjectId"]: val })}
                    error={this.props.getFieldError("Octopus.Action.DeployRelease.ProjectId")}
                    allowFilter={true}
                    autoFocus
                    value={this.props.properties["Octopus.Action.DeployRelease.ProjectId"]} />
                <Note>
                    See our <ExternalLink href="DeployReleaseStep">documentation</ExternalLink> for more information on deploying projects
                    with the Deploy Release step.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Octopus.Action.DeployRelease.DeploymentCondition"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Deployment condition"
                summary={this.summaryDeployment()}
                help={<span>Control when this deployment should run.</span>}>
                <BoundStringRadioButtonGroup
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={DeploymentCondition.Always}
                    value={this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.DeployRelease.DeploymentCondition"]: x })}
                    label="Deployment condition">
                    <RadioButton value={DeploymentCondition.Always} label="Always" isDefault />
                    <RadioButton value={DeploymentCondition.IfNotCurrentVersion} label="If the selected release is not the current release in the environment" />
                    <RadioButton
                        value={DeploymentCondition.IfNewer}
                        label="If the selected release has a higher version than the current release in the environment" />
                </BoundStringRadioButtonGroup>
            </ExpandableFormSection>
            <ExpandableFormSection errorKey="Octopus.Action.DeployRelease.Variables"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Variables"
                summary={this.summaryVariables()}
                fillCardWidth={CardFill.FillRight}
                help={<span>Pass variables through to the child deployment.</span>}>
                <KeyValueEditList items={this.props.properties["Octopus.Action.DeployRelease.Variables"]}
                    name="Variable"
                    separator="="
                    onChange={val => this.props.setProperties({ ["Octopus.Action.DeployRelease.Variables"]: val })}
                    valueLabel="Value"
                    keyLabel="Variable name"
                    hideBindOnKey={true}
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                />
                <Note>
                    See our <ExternalLink href="DeployReleaseStepVariables">documentation</ExternalLink> for more information on passing variables to
                    deployments triggered by the Create Release step.
                </Note>
            </ExpandableFormSection>
        </div>;
    }

    private summary(): SummaryNode {
        const type = this.props.properties["Octopus.Action.DeployRelease.ProjectId"];
        if (!type) {
            return Summary.placeholder("Select a project that will be deployed");
        }
        const projectId = this.props.properties["Octopus.Action.DeployRelease.ProjectId"];
        const projectName = this.getProjectName(projectId);
        // Don't link to bound fields
        const link = projectId.includes("#{") ?
            <b>{projectName}</b> :
            <InternalLink to={routeLinks.project(encodeURIComponent(projectId)).root}>{projectName}</InternalLink>;

        return Summary.summary(<span>Deploy a release from project {link}</span>);
    }

    private summaryDeployment(): SummaryNode {
        const condition = this.props.properties["Octopus.Action.DeployRelease.DeploymentCondition"];

        if (condition === DeploymentCondition.IfNotCurrentVersion) {
            return Summary.summary(<span>Deploy the release if it is <b>not the current release</b> in the environment</span>);
        }

        if (condition === DeploymentCondition.IfNewer) {
            return Summary.summary(<span>Deploy the release if it has a <b>higher version</b> than the current release in the environment</span>);
        }

        return Summary.default(<span>Deploy every time</span>);
    }

    private summaryVariables(): SummaryNode {
        const variables = JSON.parse(this.props.properties["Octopus.Action.DeployRelease.Variables"] || "{}");
        if (Object.keys(variables).length === 0) {
            return Summary.placeholder("No variables specified");
        } else {
            const text = Object.keys(variables).map(m => m + " = " + variables[m]).join(", ");
            return Summary.summary(text);
        }
    }

    private getProjectName(projectId: string): string {
        const project = this.state.projects.find(p => p.value === projectId);
        return project ? project.text : projectId;
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.DeployRelease",
    summary: (properties, targetRolesAsCSV) => <DeployReleaseSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => false,
    canBeChild: false, // cannot be a child as it doesn't make sense to deploy a release for each machine in a role. we can't do anything useful with the role
    edit: DeployReleaseEdit,
    targetRoleOption: (action) => TargetRoles.None
});