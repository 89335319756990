import * as React from "react";
import {
    ColumnMeasurements,
    DraggableResizeColumnHandle
} from "components/ScrollTable/DraggableResizeColumnHandle/DraggableResizeColumnHandle";
const styles = require("./style.less");

interface AlignedScrollTableRowProps {
    cells: JSX.Element[];
    relativeColumnWidthsInPercent: ReadonlyArray<number>;
    minimumColumnWidthsInPx: ReadonlyArray<number>;
    showResizeHandles: boolean;
    onColumnWidthsChanged(newColumnWidths: ReadonlyArray<number>): void;
}

interface AlignedScrollTableRowState {
    isDragging: boolean;
    isHovered: boolean;
}

class AlignedScrollTableRow extends React.Component<AlignedScrollTableRowProps, AlignedScrollTableRowState> {
    private readonly columnSizeElements: {[index: number]: HTMLElement | null} = {};

    constructor(props: AlignedScrollTableRowProps) {
        super(props);
        this.state = {
            isDragging: false,
            isHovered: false
        };
    }

    render() {
        return <div
            className={styles.alignedCellsWrapper}
            onMouseEnter={() => this.setState({isHovered: this.props.showResizeHandles})}
            onMouseLeave={() => this.setState({isHovered: false})}>
            {this.props.cells.map((c, index) => {
                return <div style={asPercentWidth(this.props.relativeColumnWidthsInPercent[index])}
                            key={index}
                            className={styles.cellWrapper}
                            ref={el => {
                                if (this.props.showResizeHandles) {
                                    this.columnSizeElements[index] = el;
                                }
                            }}
                >
                    {this.props.showResizeHandles && index > 0 && <DraggableResizeColumnHandle
                        isDark={!this.state.isDragging && this.state.isHovered}
                        getColumnMeasurements={() => this.getMeasurements(index)}
                        onResize={(deltaX, measurements) => this.onResize(index, deltaX, measurements)}
                        onIsDraggingChanged={isDragging => this.setState({isDragging})}
                    />}
                    <div className={styles.cellContentWrapper}>
                        {c}
                    </div>
                </div>;
            })}
        </div>;
    }

    private getMeasurements = (index: number): ColumnMeasurements => {
        const rightColumnIndex = index;
        const leftColumnIndex = index - 1;
        return {
            rightWidth: this.columnSizeElements[rightColumnIndex].offsetWidth,
            leftWidth: this.columnSizeElements[leftColumnIndex].offsetWidth,
            rightMinimumWidth: this.props.minimumColumnWidthsInPx[rightColumnIndex],
            leftMinimumWidth: this.props.minimumColumnWidthsInPx[leftColumnIndex]
        };
    }

    private onResize = (index: number, deltaX: number, initialColumnMeasurements: ColumnMeasurements) => {
        const newRelativeColumnWidths = adjustColumnWidths(index, deltaX, initialColumnMeasurements, this.props.relativeColumnWidthsInPercent);
        this.props.onColumnWidthsChanged(newRelativeColumnWidths);
    }
}

export function adjustColumnWidths(index: number, deltaX: number, initialColumnMeasurements: ColumnMeasurements, originalColumnWidths: ReadonlyArray<number>) {
    const leftColumnIndex = index - 1;
    const rightColumnIndex = index;
    const newColumnWidths = [...originalColumnWidths];
    newColumnWidths[leftColumnIndex] = getNewColumnWidthInPercent(originalColumnWidths[leftColumnIndex],
        initialColumnMeasurements.leftWidth, deltaX);
    newColumnWidths[rightColumnIndex] = getNewColumnWidthInPercent(originalColumnWidths[rightColumnIndex],
        initialColumnMeasurements.rightWidth, -deltaX);
    return newColumnWidths;

    function getNewColumnWidthInPercent(originalColumnWidthInPercent: number,
                                        originalColumnWidth: number,
                                        changeInColumnWidth: number) {
        const newColumnWidth = originalColumnWidth + changeInColumnWidth;
        return (newColumnWidth / originalColumnWidth) * originalColumnWidthInPercent;
    }
}

function asPercentWidth(percent: number) {
    return { width: `${percent}%`};
}

export default AlignedScrollTableRow;
