import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import AspNetCategory from "../../Definitions/AspNetCategoryDefinition";
import DotNetOtherCategory from "../../Definitions/DotNetOtherCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import {
    CommonBuildServerPackagingTextForOctoExe,
    CommonBuildServerPackagingTextForIntegration,
    CommonBuildServerPackagingTextForOctoPack,
} from "../CommonRegistrationText";

const BuildServerPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET applications from build servers, we have extensions available that will typically use
            either <i>OctoPack</i> or <i>Octo.exe</i> to package your application behind the scenes.
        </p>
        {CommonBuildServerPackagingTextForOctoPack}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export const BuildServerPackagingRegistrationForAspNet: CategorizedPackagingRegistration = {
    displayOrder: 300,
    categories: [AspNetCategory, DotNetOtherCategory],
    name: "Build Server",
    type: "BuildServerForAspNet",
    renderCard: InfoRegistrationCards.basic(
        () => <BuildServerPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};