import NamedResource from "./namedResource";
import {TenantedDeploymentMode} from "./";
import {SensitiveValue} from "./propertyValueResource";

export enum AccountType {
    None = "None",
    UsernamePassword = "UsernamePassword",
    Token = "Token",
    SshKeyPair = "SshKeyPair",
    AzureSubscription = "AzureSubscription",
    AzureServicePrincipal = "AzureServicePrincipal",
    AmazonWebServicesAccount = "AmazonWebServicesAccount"
}

export abstract class AccountResource extends NamedResource {
    Name: string;
    Description: string;
    EnvironmentIds: string[];
    TenantIds: string[];
    TenantTags: string[];
    AccountType: AccountType;
    TenantedDeploymentParticipation: TenantedDeploymentMode;
}

export class AzureServicePrincipalAccountResource extends AccountResource {
    SubscriptionNumber: string;
    ClientId: string;
    TenantId: string;
    Password: SensitiveValue;
    AzureEnvironment: string;
    ResourceManagementEndpointBaseUri: string;
    ActiveDirectoryEndpointBaseUri: string;

    constructor() {
        super();
        this.AccountType = AccountType.AzureServicePrincipal;
    }
}

export class AzureSubscriptionAccountResource extends AccountResource {
    SubscriptionNumber: string;
    CertificateBytes: SensitiveValue;
    CertificateThumbprint: string;
    AzureEnvironment: string;
    ServiceManagementEndpointBaseUri: string;
    ServiceManagementEndpointSuffix: string;

    constructor() {
        super();
        this.AccountType = AccountType.AzureSubscription;
    }
}

export class SshKeyPairAccountResource extends AccountResource {
    Username: string;
    PrivateKeyFile: SensitiveValue;
    PrivateKeyPassphrase: SensitiveValue;

    constructor() {
        super();
        this.AccountType = AccountType.SshKeyPair;
    }
}

export class UsernamePasswordAccountResource extends AccountResource {
    Username: string;
    Password: SensitiveValue;

    constructor() {
        super();
        this.AccountType = AccountType.UsernamePassword;
    }
}

export class TokenAccountResource extends AccountResource {
    Token: SensitiveValue;

    constructor() {
        super();
        this.AccountType = AccountType.Token;
    }
}

export class AmazonWebServicesAccountResource extends AccountResource {
    AccessKey: string;
    SecretKey: SensitiveValue;
    constructor() {
        super();
        this.AccountType = AccountType.AmazonWebServicesAccount;
    }
}

export default AccountResource;