import * as React from "react";
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import {ObfuscatedPlaceholder} from "components/form/Sensitive/Sensitive";

interface ReadonlySensitiveProps {
    hasValue: boolean;
    className?: string;
    monoSpacedFont?: boolean;
}

const ReadonlySensitive: React.SFC<ReadonlySensitiveProps> = (props) => {
    return <ReadonlyText text={props.hasValue ? ObfuscatedPlaceholder : ""}
                         className={props.className}
                         monoSpacedFont={props.monoSpacedFont}/>;
};

export default ReadonlySensitive;