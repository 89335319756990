exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_machineIcon__3vNt0 {\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n  width: 2.875rem;\n  height: 3.125rem;\n}\n", ""]);

// exports
exports.locals = {
	"machineIcon": "style_machineIcon__3vNt0"
};