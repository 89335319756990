import * as React from "react";
import List from "components/List";
import { ResourceCollection } from "client/resources";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { ProxyResource } from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton, NavigationButtonType } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../InfrastructureLayout";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import routeLinks from "routeLinks";
import InternalLink from "components/Navigation/InternalLink";
import ExternalLink from "components/Navigation/ExternalLink";
import Callout, { CalloutType } from "components/Callout";
import SectionNote from "components/SectionNote/SectionNote";

class ProxyList extends List<ProxyResource> { }

interface ProxyLayoutState extends DataBaseComponentState {
    proxiesResponse: ResourceCollection<ProxyResource>;
}

export default class ProxiesLayout extends DataBaseComponent<RouteComponentProps<void>, ProxyLayoutState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = ({
            proxiesResponse: null,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const proxiesResponse = await repository.Proxies.list();
            this.setState({ proxiesResponse });
        });
    }

    render() {
        const addButton = <PermissionCheck permission={Permission.ProxyCreate}>
            <NavigationButton type={NavigationButtonType.Primary} label="Add Proxy" href={routeLinks.infrastructure.proxies.create} />
        </PermissionCheck>;

        return <InfrastructureLayout {...this.props}>
            <PaperLayout busy={this.state.busy}
                errors={this.state.errors}
                title="Proxies"
                sectionControl={addButton}>
                <SectionNote>Octopus can talk to deployment targets through a proxy. Create proxies here and then configure each deployment target that needs a proxy. <ExternalLink href="ProxySupport">Learn more</ExternalLink></SectionNote>
                <Callout type={CalloutType.Warning}>Proxies created here are not used by the Server or Tentacles for web requests, see&nbsp;
                    <InternalLink to={routeLinks.configuration.setting("server-proxy")}>Web Request Proxy</InternalLink> for the current configuration and the&nbsp;
                    <ExternalLink href="ConfigureWebRequestProxy">documentation</ExternalLink> for modifying the Web Request Proxy.</Callout>
                {this.state.proxiesResponse && <ProxyList
                    initialData={this.state.proxiesResponse}
                    onRow={(item: any) => this.buildProxyRow(item)}
                    onFilter={this.Filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["partialName"]}
                    match={this.props.match}
                    filterHintText="Filter by name..." />}
            </PaperLayout>
        </InfrastructureLayout>;
    }

    private Filter(filter: string, resource: ProxyResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildProxyRow(proxy: ProxyResource) {
        return (
            <ListTitle>{proxy.Name}</ListTitle>
        );
    }
}
