import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {ActionButton} from "components/Button/ActionButton";
import {DialogLayout} from "components/DialogLayout/DialogLayout";
import Dialog from "components/Dialog/Dialog";

interface DialogProps {
    open: boolean;
    title: string;

    onCloseClick(): any;
}

export default class NoActionDialog extends DataBaseComponent<DialogProps, DataBaseComponentState> {
    constructor(props: DialogProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <Dialog open={this.props.open}>
                    <DialogLayout actions={<ActionButton label="Close" onClick={() => this.props.onCloseClick()}/>}
                                title={this.props.title}
                                busy={null}
                                  closeDialog={this.props.onCloseClick}
                                errors={null}>
                        {this.props.children}
                    </DialogLayout>
                </Dialog>;
    }

}