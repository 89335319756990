import {FilterableValue} from "areas/variables/VariableFilter";
import {groupBy, flatten} from "lodash";
import {VariableType} from "client/resources/variableResource";
import {ScopeValues} from "client/resources/variableSetResource";
import {ScopeSpecification} from "areas/variables/ReadonlyVariableResource";
import {ReferenceDataItem} from "client/resources";

export function compareValues(l: FilterableValue, r: FilterableValue) {
    return compareTypes(l.type, r.type) || compareValueExpression(l.value, r.value);
}

function compareValueExpression(l: string | undefined, r: string | undefined) {
    if (l === r) {
        return 0;
    } else if (!l) {
        return 1;
    } else if (!r) {
        return -1;
    } else {
        return l.localeCompare(r);
    }
}

function compareTypes(l: VariableType, r: VariableType) {
    return getTypeOrder(l) - getTypeOrder(r);

    function getTypeOrder(t: VariableType) {
        switch (t) {
            case VariableType.String:
                return 1;
            case VariableType.Sensitive:
                return 2;
            case VariableType.Certificate:
                return 3;
            default:
                return 4;
        }
    }
}

export function compareScopes(l: ScopeSpecification, r: ScopeSpecification, availableScopes: ScopeValues) {
    const leftScopes = getAllScopeItems(l, availableScopes);
    const rightScopes = getAllScopeItems(r, availableScopes);
    return leftScopes.length - rightScopes.length
        || compareScopesOfSameLength(leftScopes, rightScopes);
}

// A collection of scope items L should have an index lower than that another collection of scope items R,
// IF there exists a scope item in L which, if it were combined with all of the scope items in R and the resulting collection was sorted,
// it would have an index lower than all of the other scope items in R
// The same result is achieved by combining both collections L and R, removing duplicates, sorting, and working out
// which collection the item at index 0 of the sorted collection originally came from.
function compareScopesOfSameLength(l: ReadonlyArray<ScopeItem>, r: ReadonlyArray<ScopeItem>) {
    const leftScopeItems = l.map(i => createItemWithSide("left", i));
    const rightScopeItems = r.map(i => createItemWithSide("right", i));

    const allItems: ReadonlyArray<ScopeItemWithSide> = [...leftScopeItems, ...rightScopeItems];
    const groupedItems = groupBy<ScopeItemWithSide>(allItems, i => `${i.item.type}-${i.item.id}`);
    const onlyUniqueItems = flatten(Object
        .keys(groupedItems)
        .map(k => groupedItems[k])
        .filter(g => g.length === 1)); // Exclude any items that exist in both lists
    if (!onlyUniqueItems.length) {
        return 0;
    }

    const sortedItems = [...onlyUniqueItems].sort((a, b) => compareScopeItems(a.item, b.item));
    return sortedItems[0].side === "left" ? -1 : 1;

    interface ScopeItemWithSide {
        side: "left" | "right";
        item: ScopeItem;
    }

    function createItemWithSide(side: "left" | "right", item: ScopeItem): ScopeItemWithSide {
        return { side, item };
    }
}

export function compareScopeItems(l: ScopeItem, r: ScopeItem) {
    return l.type - r.type || (l.name || "").localeCompare(r.name || "");
}

// Numbers used for sorting
export enum ScopeType {
    Environment = 1,
    Role = 2,
    Machine = 3,
    Action = 4,
    Channel = 5,
    TenantTag = 6
}

export interface ScopeItem {
    type: ScopeType;
    id: string;
    name: string | null; // null if it could not be found in the available scopes
}

function getAllScopeItems(scope: ScopeSpecification, availableScopes: ScopeValues): ReadonlyArray<ScopeItem> {
    return [
        ...getSpecificScopeItems(ScopeType.Environment, scope.Environment, availableScopes.Environments),
        ...getSpecificScopeItems(ScopeType.Role, scope.Role, availableScopes.Roles),
        ...getSpecificScopeItems(ScopeType.Machine, scope.Machine, availableScopes.Machines),
        ...getSpecificScopeItems(ScopeType.Action, scope.Action, availableScopes.Actions),
        ...getSpecificScopeItems(ScopeType.Channel, scope.Channel, availableScopes.Channels),
        ...getSpecificScopeItems(ScopeType.TenantTag, scope.TenantTag, availableScopes.TenantTags)
    ];
}

function getSpecificScopeItems(type: ScopeType,
                               scopeIds: ReadonlyArray<string> | undefined,
                               scopeValues: ReadonlyArray<ReferenceDataItem>) {
    return (scopeIds || [])
        .map(id => {
            const item = scopeValues.find(v => v.Id === id);
            return {
                type,
                id,
                name: item ? item.Name : null
            };
        });
}
