import {Dictionary, remove} from "lodash";
import {Dispatch} from "redux";

// State Type
export enum NotificationType {
    happy,
    info,
    warn,
    error
}

export interface NotificationItem {
    id: string;
    value?: string;
    text: string;
    description: string;
    links?: Array<NotificationItemLink | NotificationItemHrefLink>;
    type: NotificationType;
}

export type NotificationItemHrefLink  = {
    type: "Internal" | "External";
    label: string;
    href: string;
};

export type NotificationItemLink  = {
    type: "None" | "Refresh";
    label: string;
};

export type NotificationItemState = Dictionary<NotificationItem>;

// Actions
const NotificationActions = {
    RAISE: "NotificationActions/RAISE",
    DISMISS: "NotificationActions/DISMISS"
};

// Action Creator
export const raiseNotification = (dispatch: Dispatch<any>) => (notification: NotificationItem) => {
    dispatch({ type: NotificationActions.RAISE, notification });
};

export const dismissNotification = (dispatch: Dispatch<any>) => (notificationType: string) => {
    dispatch({ type: NotificationActions.DISMISS, notificationType });
};

// Reducer
export const notificationReducer = (state: NotificationItemState = {}, action: any): NotificationItemState => {
    switch (action.type) {
        case NotificationActions.RAISE:
            const notification: NotificationItem = action.notification;
            return {
                ...state,
                [notification.id]: notification
            };
        case NotificationActions.DISMISS:
            const newState = {...state};
            delete newState[action.notificationType];
            return newState;
    }
    return state;
};