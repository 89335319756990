exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_internalLink__WVl2K {\n  color: #2f93e0;\n  text-decoration: none;\n}\n.style_internalLink__WVl2K:hover {\n  color: #0A67B1;\n}\n.style_listInternalLink__3Z097 {\n  color: inherit;\n}\n.style_listInternalLink__3Z097:hover,\n.style_listInternalLink__3Z097:focus {\n  color: inherit;\n}\n", ""]);

// exports
exports.locals = {
	"internalLink": "style_internalLink__WVl2K",
	"listInternalLink": "style_listInternalLink__3Z097"
};