import { ListArgs } from "./basicRepository";
import Client from "../client";
import { TaskResource, ResourceCollection, TaskTypeResource } from "client/resources";
import { TaskDetailsResource } from "client/resources/taskDetailsResource";
import {chunk, flatMap} from "lodash";
import MixedScopeBaseRepository from "./mixedScopeBaseRepository";

interface TaskListArgs extends ListArgs {
    name?: string;
    node?: string;
    tenant?: string;
    environment?: string;
    project?: string;
    taskName?: string;
    states?: string;
    running?: boolean;
    active?: boolean;
    hasPendingInterruptions?: boolean;
    hasWarningsOrErrors?: boolean;
    ids?: string | string[];
    includeSystem?: boolean;
}

type TaskDetailsArgs = {
    verbose: boolean;
    tail: number;
};

export interface StatsResourceCollection extends ResourceCollection<TaskResource<any>> {
    TotalCounts: { [state: string]: number };
}

class TaskRepository extends MixedScopeBaseRepository<TaskResource<any>, TaskResource<any>, TaskListArgs> {
    constructor(client: Client) {
        super("Tasks", client);
    }

    details(task: TaskResource<any>, args: TaskDetailsArgs) {
        return this.client.get<TaskDetailsResource>(task.Links["Details"], args);
    }

    getQueuedBehind(task: TaskResource<any>, args?: { skip?: number, take?: number }): Promise<ResourceCollection<TaskResource<any>>> {
        const combinedParameters = this.extend(this.spacePartitionParameters(), args);

        return this.client.get(task.Links["QueuedBehind"], combinedParameters);
    }

    getRaw(task: TaskResource<any>) {
        return this.client.getRaw(task.Links["Raw"]);
    }

    taskTypes(): Promise<TaskTypeResource[]> {
        return this.client.get<TaskTypeResource[]>(this.client.getLink("TaskTypes"), {});
    }

    filter(args: any): Promise<ResourceCollection<TaskResource<any>>> {
        const combinedParameters = this.extend(this.spacePartitionParameters(), args);
        return this.client.get<ResourceCollection<TaskResource<any>>>(this.client.getLink("Tasks"), combinedParameters);
    }

    rerun(task: TaskResource<any>): Promise<TaskResource<any>> {
        return this.client.post(task.Links["Rerun"]);
    }

    cancel(task: TaskResource<any>) {
        return this.client.post(task.Links["Cancel"]);
    }

    changeState(task: TaskResource<any>, state: any, reason: any): Promise<TaskResource<any>> {
        return this.client.post(task.Links["State"], { state, reason });
    }

    list(args?: TaskListArgs): Promise<StatsResourceCollection> {
        return super.list(args) as Promise<StatsResourceCollection>;
    }

    byIds(ids: string[]): Promise<Array<TaskResource<any>>> {
        const batchSize = 300;
        const idArrays = chunk(ids, batchSize);
        const promises: Array<Promise<StatsResourceCollection>> =
            idArrays.map(i => {
                return this.list({ids: i, take: batchSize});
            });
        return Promise.all(promises)
            .then(result => flatMap(result, c => c.Items));
    }
}

export default TaskRepository;