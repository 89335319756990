import * as React from "react";
import {
    MachinePolicyResource,
    MachineScriptPolicyRunType,
    MachineConnectivityBehavior,
    CalamariUpdateBehavior,
    TentacleUpdateBehavior,
    DeleteMachinesBehavior,
} from "client/resources";
import BaseComponent from "components/BaseComponent";
import {
    DataTable,
    DataTableBody,
    DataTableRow,
    DataTableRowColumn
} from "components/DataTable";
import MarkdownDescription from "components/MarkdownDescription";
import ActionButton, { ActionButtonType } from "components/Button";

const styles  = require("./style.less");

interface MachinePolicySummaryProps {
    machinePolicy: MachinePolicyResource;
    hideDescription: boolean;
    conciseView: boolean;
}

interface MachinePolicySummaryState {
    conciseView: boolean;
}

class MachinePolicySummary extends BaseComponent<MachinePolicySummaryProps, MachinePolicySummaryState> {
    constructor(props: MachinePolicySummaryProps) {
        super(props);
        this.state = {
            conciseView: props.conciseView
        };
    }

    render() {
        const details = <DataTable>
                <DataTableBody>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Health check interval</DataTableRowColumn>
                        <DataTableRowColumn>{this.builtHealthCheckIntervalHtml()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Health check (Tentacle endpoints)</DataTableRowColumn>
                        <DataTableRowColumn>{this.buildTentacleEndpointHealthCheckHtml()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Health check (SSH endpoints)</DataTableRowColumn>
                        <DataTableRowColumn>{this.buildSshEndpointHealthCheckHtml()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Machine connectivity</DataTableRowColumn>
                        <DataTableRowColumn>{this.buildMachineConnectivityHtml()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Calamari Machine updates</DataTableRowColumn>
                        <DataTableRowColumn>{this.buildMachineCalamariUpdatesHtml()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Tentacle Machine updates</DataTableRowColumn>
                        <DataTableRowColumn>{this.buildMachineTentacleUpdatesHtml()}</DataTableRowColumn>
                    </DataTableRow>
                    <DataTableRow>
                        <DataTableRowColumn className={styles.machinePolicySummaryLabel}>Clean up</DataTableRowColumn>
                        <DataTableRowColumn>{this.buildCleanUpOfflineHtml()}</DataTableRowColumn>
                    </DataTableRow>
                </DataTableBody>
            </DataTable>;
        const showDetailsButton = <ActionButton
            type={ActionButtonType.Secondary}
            label="Show Details"
            onClick={() => this.setState({ conciseView: false })}
        />;
        return (
            <div className={styles.machinePolicySummary}>
                {
                    !this.props.hideDescription &&
                    this.buildMachinePolicyDescriptionHtml()
                }
                {this.state.conciseView
                ? showDetailsButton
                : details}
            </div>
        );
    }

    private parseTimeSpan(timeSpan: any) {
        const timeSpanParts = timeSpan.split(":");
        let days = 0;
        let hours = 0;
        let mins = 0;
        const tsPart1 = timeSpanParts[0];
        if (tsPart1.indexOf(".") !== -1) {
            // days included
            const daysAndHours = tsPart1.split(".");
            days = parseInt(daysAndHours[0], 10);
            hours = parseInt(daysAndHours[1], 10);
            mins = parseInt(timeSpanParts[1], 10);
        } else {
            // no days
            hours = parseInt(timeSpanParts[0], 10);
            mins = parseInt(timeSpanParts[1], 10);
        }
        return {
            Days: days,
            Hours: hours,
            Minutes: mins
        };
    }

    private buildMachinePolicyDescriptionHtml() {
        const policy = this.props.machinePolicy;
        return (
            <div className={styles.description}>
                <MarkdownDescription markup={policy.Description}/>
            </div>
        );
    }

    private builtHealthCheckIntervalHtml() {
        const policy = this.props.machinePolicy;
        const healthCheckInterval = this.parseTimeSpan(policy.MachineHealthCheckPolicy.HealthCheckInterval);
        let healthCheckIntervalHtml = "Performs health checks every";
        if (healthCheckInterval.Days > 0) {
            healthCheckIntervalHtml += " " + healthCheckInterval.Days + " day" + (healthCheckInterval.Days === 1 ? "" : "s");
        }
        if (healthCheckInterval.Hours > 0) {
            healthCheckIntervalHtml += " " + healthCheckInterval.Hours + " hour" + (healthCheckInterval.Hours === 1 ? "" : "s");
        }
        if (healthCheckInterval.Minutes > 0 || healthCheckInterval.Hours === 0) {
            healthCheckIntervalHtml += " " + healthCheckInterval.Minutes + " minute" + (healthCheckInterval.Minutes === 1 ? "" : "s");
        }
        return healthCheckIntervalHtml;
    }

    private buildTentacleEndpointHealthCheckHtml() {
        const policy = this.props.machinePolicy;
        let tentacleEndpointHealthCheckHtml = "";
        if (policy.MachineHealthCheckPolicy.TentacleEndpointHealthCheckPolicy.RunType === MachineScriptPolicyRunType.InheritFromDefault) {
            tentacleEndpointHealthCheckHtml = "Inherits from the default machine policy";
        } else if (policy.MachineHealthCheckPolicy.TentacleEndpointHealthCheckPolicy.RunType === MachineScriptPolicyRunType.Inline) {
            tentacleEndpointHealthCheckHtml = "Uses custom script";
        }
        return tentacleEndpointHealthCheckHtml;
    }

    private buildSshEndpointHealthCheckHtml() {
        const policy = this.props.machinePolicy;
        let sshEndpointHealthCheckHtml = "";
        if (policy.MachineHealthCheckPolicy.SshEndpointHealthCheckPolicy.RunType === MachineScriptPolicyRunType.InheritFromDefault) {
            sshEndpointHealthCheckHtml = "Inherits from the default machine policy";
        } else if (policy.MachineHealthCheckPolicy.SshEndpointHealthCheckPolicy.RunType === MachineScriptPolicyRunType.Inline) {
            sshEndpointHealthCheckHtml = "Uses custom script";
        } else if (policy.MachineHealthCheckPolicy.SshEndpointHealthCheckPolicy.RunType === MachineScriptPolicyRunType.OnlyConnectivity) {
            sshEndpointHealthCheckHtml = "Only performs connection test";
        }
        return sshEndpointHealthCheckHtml;
    }

    private buildMachineConnectivityHtml() {
        const policy = this.props.machinePolicy;
        let machineConnectivityHtml = "";
        if (policy.MachineConnectivityPolicy.MachineConnectivityBehavior === MachineConnectivityBehavior.ExpectedToBeOnline) {
            machineConnectivityHtml = "Fails when a deployment target is unavailable";
        } else if (policy.MachineConnectivityPolicy.MachineConnectivityBehavior === MachineConnectivityBehavior.MayBeOfflineAndCanBeSkipped) {
            machineConnectivityHtml = "Ignore unavailable deployment targets";
        }
        return machineConnectivityHtml;
    }

    private buildMachineCalamariUpdatesHtml() {
        const policy = this.props.machinePolicy;
        let machineCalamariUpdatesHtml = "";
        if (policy.MachineUpdatePolicy.CalamariUpdateBehavior === CalamariUpdateBehavior.UpdateOnDeployment) {
            machineCalamariUpdatesHtml = "Automatically update when a deployment target is involved in a deployment";
        } else if (policy.MachineUpdatePolicy.CalamariUpdateBehavior === CalamariUpdateBehavior.UpdateOnNewMachine) {
            machineCalamariUpdatesHtml = "Automatically update the first time a deployment target comes online and then when it is involved in a deployment";
        } else if (policy.MachineUpdatePolicy.CalamariUpdateBehavior === CalamariUpdateBehavior.UpdateAlways) {
            machineCalamariUpdatesHtml = "Always keep up to date";
        }
        return machineCalamariUpdatesHtml;
    }

    private buildMachineTentacleUpdatesHtml() {
        const policy = this.props.machinePolicy;
        let machineTentacleUpdatesHtml = "";
        if (policy.MachineUpdatePolicy.TentacleUpdateBehavior === TentacleUpdateBehavior.NeverUpdate) {
            machineTentacleUpdatesHtml = "Do not update";
        } else if (policy.MachineUpdatePolicy.TentacleUpdateBehavior === TentacleUpdateBehavior.Update) {
            machineTentacleUpdatesHtml = "Automatically update";
        }
        return machineTentacleUpdatesHtml;
    }

    private buildCleanUpOfflineHtml() {
        const policy = this.props.machinePolicy;
        let cleaningUpOfflineHtml = "";
        if (policy.MachineCleanupPolicy.DeleteMachinesBehavior === DeleteMachinesBehavior.DoNotDelete) {
            cleaningUpOfflineHtml = "Does not delete deployment targets automatically";
        } else if (policy.MachineCleanupPolicy.DeleteMachinesBehavior === DeleteMachinesBehavior.DeleteUnavailableMachines) {
            const deleteMachinesElapsedTimeSpan = this.parseTimeSpan(policy.MachineCleanupPolicy.DeleteMachinesElapsedTimeSpan);
            let deleteMachinesElapsedTimeSpanHtml = "Automatically delete unavailable deployment targets after";
            if (deleteMachinesElapsedTimeSpan.Days > 0) {
                deleteMachinesElapsedTimeSpanHtml += " " + deleteMachinesElapsedTimeSpan.Days + " day" + (deleteMachinesElapsedTimeSpan.Days === 1 ? "" : "s");
            }
            if (deleteMachinesElapsedTimeSpan.Hours > 0) {
                deleteMachinesElapsedTimeSpanHtml += " " + deleteMachinesElapsedTimeSpan.Hours + " hour" + (deleteMachinesElapsedTimeSpan.Hours === 1 ? "" : "s");
            }
            if (deleteMachinesElapsedTimeSpan.Minutes > 0 || deleteMachinesElapsedTimeSpan.Hours === 0) {
                deleteMachinesElapsedTimeSpanHtml += " " + deleteMachinesElapsedTimeSpan.Minutes + " minute" + (deleteMachinesElapsedTimeSpan.Minutes === 1 ? "" : "s");
            }
            cleaningUpOfflineHtml = deleteMachinesElapsedTimeSpanHtml;
        }
        return cleaningUpOfflineHtml;
    }
}

export default MachinePolicySummary;