import * as React from "react";
import ActionButton, {ActionButtonType} from "components/Button/ActionButton";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {DialogLayout, DialogLayoutDispatchProps} from "components/DialogLayout/DialogLayout";
import {DialogLayoutConnect} from "components/Dialog/DialogLayoutConnect";
import {AvailableRoleScopes, ScopedUserRoleModel} from "./TeamEdit";
import Select from "../../../../components/form/Select/Select";
import Note from "../../../../components/form/Note/Note";
import {UserRoleResource, UserRoleConstants} from "client/resources/userRoleResource";
import {SpaceResource, TeamResource} from "client/resources";
import DisplayProperties from "components/DisplayProperties/DisplayProperties";
import {ProjectGroupMultiSelect} from "components/MultiSelect/ProjectGroupMultiSelect";
import {EnvironmentMultiSelect, ProjectMultiSelect, TenantMultiSelect} from "components/MultiSelect";
import {Feature, FeatureToggle} from "components/FeatureToggle";
import Callout, {CalloutType} from "components/Callout";
import {client} from "clientInstance";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import styles = require("./style.less");

interface IncludeRoleDialogProps {
    roles: UserRoleResource[];
    isTeamConstrainedToASpace: boolean;
    spaces: SpaceResource[];
    loadScopedUserRoles: (spaceId: string) => Promise<AvailableRoleScopes>;
    scopedUserRole: ScopedUserRoleModel | null;
    isOnlySpaceManagerRoleOnSpaceManagerTeam: boolean;
    restrictToSpaceId?: string | null;
    onSave: (value: IncludeRoleDialogResult) => Promise<void>;
}

export interface IncludeRoleDialogResult {
    userRoleId: string;
    spaceId: string | null;
    projectGroupIds: string[];
    projectIds: string[];
    environmentIds: string[];
    tenantIds: string[];
}

interface IncludeRoleDialogModel {
    userRoleId: string | null;
    spaceId: string | null;
    projectGroupIds: string[];
    projectIds: string[];
    environmentIds: string[];
    tenantIds: string[];
}

interface IncludeRoleDialogState extends DataBaseComponentState {
    model: IncludeRoleDialogModel;
    page: IncludeRoleDialogPage;
    showPermissionDescriptions: boolean;
    availableScopes: AvailableRoleScopes | null;
}

enum IncludeRoleDialogPage {
    Value,
    Scope,
}

class IncludeRoleDialogInternal extends DataBaseComponent<IncludeRoleDialogProps & DialogLayoutDispatchProps, IncludeRoleDialogState> {
    constructor(props: IncludeRoleDialogProps & DialogLayoutDispatchProps) {
        super(props);
        const model = this.createScopedUserRoleViewModel(this.props.scopedUserRole);
        this.state = {
            model,
            page: this.props.scopedUserRole && this.canSetScopes(this.props.scopedUserRole.UserRoleId)
                ? IncludeRoleDialogPage.Scope
                : IncludeRoleDialogPage.Value,
            showPermissionDescriptions: false,
            availableScopes: null
        };
    }

    componentDidMount() {
        if (this.state.page === IncludeRoleDialogPage.Scope) {
            this.doBusyTask(async () => {
                const availableScopes = await this.props.loadScopedUserRoles(this.state.model.spaceId);
                this.setState({ availableScopes, page: IncludeRoleDialogPage.Scope });
            });
        }
    }

    render() {
        const selectedRole = this.state.model
            && this.state.model.userRoleId
            && this.props.roles.find(x => x.Id === this.state.model.userRoleId);
        const selectedRoleName = selectedRole && selectedRole.Name;

        const selectedSpace = this.state.model
            && this.state.model.spaceId
            && this.props.spaces.find(x => x.Id === this.state.model.spaceId);

        return <DialogLayout title={!!this.props.scopedUserRole ? "Edit User Role" : "Include User Role"}
            busy={this.state.busy}
            errors={this.state.errors}
            key="IncludeRole"
            closeDialog={this.props.close}
            actions={this.getRightSideActions()}
            additionalActions={this.getLeftSideActions()}
            extraHeaderContent={this.state.page === IncludeRoleDialogPage.Scope
                ? alignmentClassName => <div className={styles.detailsHighlight}>
                    <div className={alignmentClassName}>
                        {<DisplayProperties properties={[
                            { key: "User Role", value: selectedRoleName },
                            { key: "Space", value: selectedSpace!.Name }]} />}
                        {selectedRole && selectedRole.Description && <div>{selectedRole.Description}</div>}
                    </div>
                </div>
                : undefined}
        >
            <div>
                {this.state.page === IncludeRoleDialogPage.Value && this.renderValuePage()}
                {this.state.page === IncludeRoleDialogPage.Scope && this.renderScopePage()}
            </div>
        </DialogLayout>;
    }

    renderValuePage() {
        const rolesMustBeSpaceScoped = !!this.props.restrictToSpaceId;
        const roleMustBeSystemScoped = this.props.restrictToSpaceId === null;
        const roles = rolesMustBeSpaceScoped
            ? this.props.roles.filter(roleCanApplyToSpace)
            : roleMustBeSystemScoped ? this.props.roles.filter(roleCanApplyToSystem) : this.props.roles;

        const selectedRole = this.props.roles.find(r => r.Id === this.state.model.userRoleId);

        return <React.Fragment>
            <Select label="Select a user role"
                items={roles.map(t => ({ value: t.Id, text: t.Name }))}
                onChange={this.handleRoleChange}
                error={this.state.errors && this.state.errors.fieldErrors.role}
                allowFilter={true}
                disabled={this.props.isOnlySpaceManagerRoleOnSpaceManagerTeam}
                allowClear={true}
                autoFocus
                value={this.state.model.userRoleId}
            />
            <Note>A user role specifies what actions users in this team will be able to perform.</Note>
            {selectedRole && roleCanApplyToSpace(selectedRole) && !this.props.isTeamConstrainedToASpace &&  <div><Select label="Select a space"
                items={this.props.spaces.map(t => ({ value: t.Id, text: t.Name }))}
                onChange={this.handleSpaceChange}
                allowFilter={true}
                value={this.state.model.spaceId}
                disabled={this.props.restrictToSpaceId !== undefined} // They can't modify this is they've been sent a specific space.
            />
            <Note>This team will be able to perform the selected user role actions within this space.</Note></div>}
            {this.renderRoleInfo()}
        </React.Fragment>;
    }

    renderScopePage() {
        if (this.state.availableScopes == null) {
            return null;
        }
        const selectedRole = this.props.roles.find(x => x.Id === this.state.model.userRoleId);
        return selectedRole ? <React.Fragment>
                <h4>Restrict this user role to one or more project groups, projects, environments or tenants.</h4>
                <Note>Leave empty to grant permissions across all project groups, projects, environments and tenants.</Note>
            <ProjectGroupMultiSelect
                items={this.state.availableScopes.projectGroups}
                onChange={x => this.handleProjectGroupScopeChange(x)}
                value={this.state.model.projectGroupIds}
            />
            <ProjectMultiSelect
                items={this.state.availableScopes.projects}
                onChange={x => this.handleProjectScopeChange(x)}
                value={this.state.model.projectIds}
            />
            <EnvironmentMultiSelect
                items={this.state.availableScopes.environments}
                onChange={x => this.handleEnvironmentScopeChange(x)}
                value={this.state.model.environmentIds}
            />
            <FeatureToggle feature={Feature.MultiTenancy}>
                <TenantMultiSelect
                    items={this.state.availableScopes.tenants}
                    onChange={x => this.handleTenantScopeChange(x)}
                    value={this.state.model.tenantIds}
                />
            </FeatureToggle>
        </React.Fragment>
            : null;
    }

    renderRoleInfo() {
        const roleId = this.state.model.userRoleId;
        if (!roleId) {
            return null;
        }

        const role = this.props.roles.find(r => r.Id === roleId);
        if (!role) {
            return null;
        }
        return <div>
            {role.Description && <Callout type={CalloutType.Information} title={role.Name}>{role.Description}
                <div className={styles.permissionDescriptionsActionContainer}>
                    <ActionButton label={this.state.showPermissionDescriptions ? "Hide Permissions" : "Show Permissions"}
                                  type={ActionButtonType.Secondary}
                                  onClick={() => {
                                      this.doBusyTask(async () => { // #dialogResizeHack
                                          this.setState({ showPermissionDescriptions: !this.state.showPermissionDescriptions });
                                      });
                                  }}
                    />
                </div>
                {this.state.showPermissionDescriptions && role.SystemPermissionDescriptions.length > 0 && <TransitionAnimation>
                    <Note>
                        <div className={styles.permissions}>
                            <h5><strong>System permissions granted:</strong></h5>
                        <ul>
                            {role.SystemPermissionDescriptions.map((pd, i) => <li key={i}>{pd}</li>)}
                        </ul>
                        </div>
                    </Note>
                </TransitionAnimation>}
                {this.state.showPermissionDescriptions && role.SpacePermissionDescriptions.length > 0 && <TransitionAnimation>
                    <Note>
                        <div className={styles.permissions}>
                            <h5><strong>Space permissions granted:</strong></h5>
                        <ul>
                            {role.SpacePermissionDescriptions.map((pd, i) => <li key={i}>{pd}</li>)}
                        </ul>
                        </div>
                    </Note>
                </TransitionAnimation>}
            </Callout>}
        </div>;
    }

    private createScopedUserRoleViewModel(scopedUserRole: ScopedUserRoleModel | null): IncludeRoleDialogModel {
        if (!scopedUserRole) {
            return {
                spaceId: getDefaultSpaceId(this.props.restrictToSpaceId, this.props.spaces),
                environmentIds: [],
                projectGroupIds: [],
                projectIds: [],
                tenantIds: [],
                userRoleId: null
            };
        }

        const scopes = this.canSetScopes(scopedUserRole.UserRoleId) ? {
            environmentIds: scopedUserRole.EnvironmentIds,
            projectGroupIds: scopedUserRole.ProjectGroupIds,
            projectIds: scopedUserRole.ProjectIds,
            tenantIds: scopedUserRole.TenantIds
        } : {
            environmentIds: [],
            projectGroupIds: [],
            projectIds: [],
            tenantIds: []
        };

        return {
            ...scopes,
            spaceId: scopedUserRole.SpaceId,
            userRoleId: scopedUserRole.UserRoleId
        };

        function getDefaultSpaceId(restrictToSpaceId: string | null | undefined, spaces: SpaceResource[]) {
            return restrictToSpaceId !== undefined
                ? restrictToSpaceId
                : getDefaultSpaceIdIfAvailable();

            function getDefaultSpaceIdIfAvailable(): string | null {
                const defaultSpaceToSelect = spaces.find(s => s.Id === client.spaceId);
                if (defaultSpaceToSelect) {
                    return defaultSpaceToSelect.Id;
                }
                return null;
            }
        }
    }

    private canSetScopes(userRoleId: string) {
        // Space Manager role AND System scoped user roles can't have scopes, so we can't go to the scope page
        const selectedRole = this.props.roles.find(r => r.Id === userRoleId);
        return selectedRole && !isSpaceManagerRole(userRoleId) && roleCanApplyToSpace(selectedRole);

        function isSpaceManagerRole(roleId: string): boolean {
            return roleId === UserRoleConstants.SpaceManagerRole;
        }
    }

    private handleRoleChange = (userRoleId: string) => {
        this.doBusyTask(async () => { // #dialogResizeHack
            this.setState(prev => ({ model: { ...prev.model, userRoleId } }));
        });
    }

    private handleSpaceChange = (spaceId: string) => {
        this.doBusyTask(async () => { // #dialogResizeHack
            this.setState(prev => ({ model: { ...prev.model, spaceId } }));
        });
    }

    private handleProjectScopeChange = (projectIds: string[]) => {
        this.doBusyTask(async () => { // #dialogResizeHack
            this.setState(prev => ({ model: { ...prev.model, projectIds } }));
        });
    }

    private handleProjectGroupScopeChange = (projectGroupIds: string[]) => {
        this.doBusyTask(async () => { // #dialogResizeHack
            this.setState(prev => ({ model: { ...prev.model, projectGroupIds } }));

        });
    }

    private handleEnvironmentScopeChange = (environmentIds: string[]) => {
        this.doBusyTask(async () => { // #dialogResizeHack
            this.setState(prev => ({ model: { ...prev.model, environmentIds } }));
        });
    }

    private handleTenantScopeChange = (tenantIds: string[]) => {
        this.doBusyTask(async () => { // #dialogResizeHack
            this.setState(prev => ({ model: { ...prev.model, tenantIds } }));
        });
    }

    private getLeftSideActions() {
        const actions = [];
        if (this.state.page === IncludeRoleDialogPage.Value) {
            const canProgressToDefineScopePage = this.canSetScopes(this.state.model.userRoleId);
            if (canProgressToDefineScopePage || !!this.props.restrictToSpaceId) {
                const defineScope = <ActionButton
                    key="define scope"
                    label="Define Scope"
                    type={ActionButtonType.Secondary}
                    disabled={!canProgressToDefineScopePage}
                    onClick={() => {
                        this.doBusyTask(async () => {
                            const availableScopes = await this.props.loadScopedUserRoles(this.state.model.spaceId);
                            this.setState({ availableScopes, page: IncludeRoleDialogPage.Scope });
                        });
                    }}
                />;
                actions.push(defineScope);
            }
        } else {
            const previous = <ActionButton
                key="previous"
                label="Previous"
                type={ActionButtonType.Secondary}
                onClick={() => {
                    // #dialogResizeHack
                    this.doBusyTask(async () => this.setState({ page: IncludeRoleDialogPage.Value }));
                }}
            />;
            actions.push(previous);
        }
        return actions;
    }

    private getRightSideActions() {
        const cancel = <ActionButton
            key="cancel"
            label="Cancel"
            onClick={this.props.close}
        />;
        return [cancel, this.createDoneAction()];
    }

    private createDoneAction() {
        return <ActionButton
            disabled={!this.state.model.userRoleId}
            key="apply"
            label={"Apply"} // Do not switch text here based on whether an ID is set.
            type={ActionButtonType.Primary}
            onClick={async () => {
                await this.props.onSave(this.convertToResult(this.state.model));
                this.props.close();
            }}
        />;
    }

    private convertToResult = (model: IncludeRoleDialogModel): IncludeRoleDialogResult => {
        const selectedRole = this.props.roles.find(r => r.Id === model.userRoleId);
        const spaceId = roleCanApplyToSpace(selectedRole) ? model.spaceId : null;

        const scopes = this.canSetScopes(model.userRoleId) ? {
            environmentIds: model.environmentIds,
            projectGroupIds: model.projectGroupIds,
            projectIds: model.projectIds,
            tenantIds: model.tenantIds
        } : {
            environmentIds: [],
            projectGroupIds: [],
            projectIds: [],
            tenantIds: []
        };

        return {
            spaceId,
            userRoleId: model.userRoleId,
            ...scopes
        };
    }
}

function roleCanApplyToSpace(role: UserRoleResource) {
    return !!role.GrantedSpacePermissions.length;
}

function roleCanApplyToSystem(role: UserRoleResource) {
    // A role is either applicable at the space level, or the system level
    return !roleCanApplyToSpace(role);
}

const IncludeRoleDialog = DialogLayoutConnect.to<IncludeRoleDialogProps>(IncludeRoleDialogInternal);
export default IncludeRoleDialog;