exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_menuItem__1I231 {\n  display: flex;\n}\n.style_content__21blJ {\n  flex-grow: 1;\n}\n", ""]);

// exports
exports.locals = {
	"menuItem": "style_menuItem__1I231",
	"content": "style_content__21blJ"
};