export interface ParsedText {
    content: string;
}

export function isTextPart(part: ParsedPart): part is ParsedText {
    return (part as ParsedText).content !== undefined;
}

export interface ParsedInternalLink {
    internalUrl: string;
    text: string;
}

export interface ParsedExternalLink {
    externalUrl: string;
    text: string;
}

export function isInternalLinkPart(part: ParsedPart): part is ParsedInternalLink {
    return (part as ParsedInternalLink).internalUrl !== undefined;
}

type ParsedPart = ParsedText | ParsedExternalLink | ParsedInternalLink;

const AppRelativeLinkPrefix = "~/app#";

export function parseLinks(text: string): ParsedPart[] {

    // Find any markdown-style links
    const markdownLinkPattern = /\[([^\]]+)]\(([^)]+)\)/g;

    /*
     We are going to iterate over each match for the regex above.
     The plain text before, between, and after the link matches will be
     returned as <span> elements.
     Any matching markdown links will be converted to links.

     I know what you're thinking, there must be a more elegant way. And there probably is,
     but a couple of approaches that won't work:

     - string.replace() because this returns one big string, this would involve using dangerouslySetInnerHTML(),
     which introduces a XSS vulnerability.

     - <Markdown> component.  We don't want to convert anything except links.  That feels like a bad idea for logs.

     - string.split() the results of this don't have the information we need.
    */

    const parts: ParsedPart[]  = [];
    let lastMatchIndex = 0;

    let match = markdownLinkPattern.exec(text);
    while (match !== null) {

        // If there is text since the last match and this match,
        // then we wrap it in a span.
        if (match.index > lastMatchIndex) {
            parts.push({content: text.slice(lastMatchIndex, match.index)});
        }

        const possibleTextMatch = match[1];
        // If it is a relative URL, then we need to let the client resolve it. The reason relative links start with
        // ~ is due to virtual directories.
        const possibleUrlMatch = match[2];

        if (isInternalUrl(possibleUrlMatch)) {
            const internalUrl = urlStartsWith(possibleUrlMatch, AppRelativeLinkPrefix) ? possibleUrlMatch.substring(AppRelativeLinkPrefix.length) : possibleUrlMatch;
            parts.push({internalUrl, text: possibleTextMatch});
        } else {
            if (isExternalUrl(possibleUrlMatch)) {
                parts.push({externalUrl: possibleUrlMatch, text: possibleTextMatch});
            } else {
                // Sometimes what we match looks like a link but it is not a link
                parts.push({content: match[0]});
            }
        }

        lastMatchIndex = markdownLinkPattern.lastIndex;

        match = markdownLinkPattern.exec(text);
    }

    // If there is text after the last match then return a final span
    if (lastMatchIndex < text.length) {
        parts.push({content: text.slice(lastMatchIndex, text.length)});
    }

    return parts;
}

function urlStartsWith(url: string, value: string) {
    return url.toLowerCase().startsWith(value);
}

function isInternalUrl(url: string) {
    return urlStartsWith(url, AppRelativeLinkPrefix) || url.startsWith("/");
}

function isExternalUrl(url: string) {
    return urlStartsWith(url, "http");
}