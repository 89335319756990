import * as React from "react";
import Summary from "components/form/Sections/Summary";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import Note from "components/form/Note/Note";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { GuidedFailureMode } from "client/resources";

interface FailureModeProps {
    title?: string;
    guidedFailureMode: GuidedFailureMode;
    onModeChanged(mode: GuidedFailureMode): void;
}

const FailureMode: React.StatelessComponent<FailureModeProps> = (props) => {
    return <ExpandableFormSection
                    title={props.title || "Failure mode"}
                    errorKey="failureMode"
                    help="Choose what will happen when a deployment error occurs"
                    summary={buildSummary()}>
            <div>
                <RadioButtonGroup value={props.guidedFailureMode}
                                  onChange={props.onModeChanged}>
                    <RadioButton value={GuidedFailureMode.Off} label="Do not use guided failure mode"/>
                    <RadioButton value={GuidedFailureMode.EnvironmentDefault}
                                 label="Use the default setting from the target environment" isDefault={true}/>
                    <RadioButton value={GuidedFailureMode.On} label="Use guided failure mode"/>
                    <Note>
                        When <ExternalLink href="GuidedFailures">Guided Failure</ExternalLink> is
                        enabled,
                        if an error is encountered during the deployment Octopus will pause and allow you to correct the
                        error before proceeding.
                        Otherwise, any errors encountered will result in the deployment failing.
                    </Note>
                </RadioButtonGroup>
            </div>
        </ExpandableFormSection>;

    function buildSummary() {
        switch (props.guidedFailureMode) {
            case GuidedFailureMode.Off:
                return Summary.summary("Do not use guided failure mode");
            case GuidedFailureMode.EnvironmentDefault:
                return Summary.default("Use the default setting from the target environment");
            case GuidedFailureMode.On:
                return Summary.summary("Use guided failure mode");
            default:
                return Summary.summary("Select a failure mode");
        }
    }
};
export default FailureMode;