import { MachinesLayoutProps, MachinesLayoutState, BaseMachinesLayout, MachineFilterLayout } from "./BaseMachinesLayout";
import { WorkerPoolsSummaryResource, WorkerPoolResource, Permission, SummaryResource } from "client/resources";
import { repository } from "clientInstance";
import { WorkerPoolsSummaryArgs } from "client/repositories/workerpoolsRepository";
import * as React from "react";
import { WorkerPoolMultiSelect } from "components/MultiSelect";
import GlobalState from "globalState";
import { machineActions } from "../../reducers/machines";
import { connect } from "react-redux";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton, NavigationButtonType } from "components/Button";
import routeLinks from "routeLinks";
import { cloneDeep } from "lodash";
import Section from "components/Section";
import WorkersOnboarding, { OnboardingTextForWorkers } from "./WorkersOnboarding";
import WorkerMachinesSummarySection from "./WorkerMachinesSummarySection";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note } from "components/form";
import { NoResults } from "components/NoResults/NoResults";

const { machineCleared } = machineActions;

// tslint:disable-next-line:no-empty-interface
interface WorkerMachinesLayoutProps extends MachinesLayoutProps {}

interface WorkerMachinesLayoutState extends MachinesLayoutState {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    workerPools: WorkerPoolResource[];
}

class WorkerMachinesLayoutInternal extends BaseMachinesLayout<WorkerMachinesLayoutProps, WorkerMachinesLayoutState> {

    protected initialState(): WorkerMachinesLayoutState {
        return {
            ...this.baseInitialState(),
            workerPoolsSummary: null,
            workerPools: null,
            hasMachines: false
        };
    }

    protected async loadLookupData(): Promise<void> {
        const workerPoolsPromise = repository.WorkerPools.all();

        this.setState({
            workerPools: await workerPoolsPromise,
            hasMachines: (await repository.Workers.list({ take: 0 })).TotalResults > 0,
        });
    }

    protected async loadSummaries(): Promise<void> {
        const filter = this.state.filter;
        const args: Partial<WorkerPoolsSummaryArgs> = {};

        if (filter.isDisabled) {
            args.isDisabled = true;
        }

        if (filter.workerPoolIds && filter.workerPoolIds.length) {
            args.ids = filter.workerPoolIds.join(",");
        }

        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }

        if (filter.commStyles && filter.commStyles.length) {
            args.commStyles = filter.commStyles.join(",");
        }

        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }

        if (filter.partialName && filter.partialName !== "") {
            args.machinePartialName = filter.partialName;
        }

        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.WorkerPools.summary(args), (response) => {
            this.setState({
                workerPoolsSummary: response as WorkerPoolsSummaryResource,
            });
        });
    }

    protected getSummaries(): SummaryResource {
        return this.state.workerPools && this.state.workerPoolsSummary;
    }

    protected extraFilters(): React.ReactNode[] {
        return [
            <WorkerPoolMultiSelect
                items={this.state.workerPools}
                value={this.state.filter.workerPoolIds}
                onChange={x => {
                    this.setFilterState({workerPoolIds: x}, this.onFilterChange);
                }}
            />
        ];
    }

    protected getActions(): JSX.Element[] {
        return [
            <PermissionCheck permission={Permission.MachineCreate} environment="*" tenant="*">
                <NavigationButton
                    href={routeLinks.infrastructure.workerMachines.new()}
                    label="Add worker"
                    type={NavigationButtonType.Primary}
                />
            </PermissionCheck>,
        ];
    }

    protected renderPageSummary(): JSX.Element {
        return <Section>
            <Note>{OnboardingTextForWorkers} <ExternalLink href="OnboardingWorkersLearnMore">Learn more</ExternalLink></Note>
        </Section>;
    }

    protected renderOnboarding(): JSX.Element {
        return <WorkersOnboarding />;
    }

    protected renderMachinesExpander(): React.ReactNode {
        let machinesExpander: React.ReactNode = null;
        const workerPoolsSummaries = this.state.workerPools && this.state.workerPoolsSummary;
        if (workerPoolsSummaries) {
            machinesExpander = <WorkerMachinesSummarySection
                key="allMachines"
                workerPoolsSummary={workerPoolsSummaries}
                filter={cloneDeep(this.state.filter)}
                workerPools={this.state.workerPools}
            />;
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            machinesExpander = <Section><NoResults /></Section>;
        }
        return machinesExpander;
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        title: "Workers",
        itemDescriptions: "workers"
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onClearMachine: () => {
            dispatch(machineCleared());
        }
    };
};

const WorkerMachinesLayout = connect<{}, {}, MachinesLayoutProps>(
    mapStateToProps,
    mapDispatchToProps
)(WorkerMachinesLayoutInternal);

export default WorkerMachinesLayout;
