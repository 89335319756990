import NamedResource from "./namedResource";
import {SensitiveValue} from "./propertyValueResource";
import {OfflineDropDestinationResource, OfflineDropDestinationType} from "./offlineDropDestinationResource";

export enum CommunicationStyle {
    None = "None",
    TentaclePassive = "TentaclePassive",
    TentacleActive = "TentacleActive",
    Ssh = "Ssh",
    OfflineDrop = "OfflineDrop",
    AzureWebApp = "AzureWebApp",
    Ftp = "Ftp",
    AzureCloudService = "AzureCloudService",
    AzureServiceFabricCluster = "AzureServiceFabricCluster",
    Kubernetes = "Kubernetes"
}

// Emulating a resource so we can refer to this enum in dropdownlists easily etc.
export interface CommunicationStyleResource {
    Id: CommunicationStyle;
    Name: string;
}

export interface TentacleDetailsResource {
    UpgradeLocked: boolean;
    Version: string;
    UpgradeSuggested: boolean;
    UpgradeRequired: boolean;
}

export abstract class EndpointResource extends NamedResource {
    CommunicationStyle: CommunicationStyle;
}

export abstract class TentacleEndpointResource extends EndpointResource {
    Thumbprint: string;
    TentacleVersionDetails: TentacleDetailsResource;
    CertificateSignatureAlgorithm: string;
}

export class ListeningTentacleEndpointResource extends TentacleEndpointResource {
    Uri: string;
    ProxyId: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.TentaclePassive;
    }
}

export class PollingTentacleEndpointResource extends TentacleEndpointResource {
    Uri: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.TentacleActive;
    }
}

// tslint:disable-next-line:no-empty-interface
export abstract class AgentlessEndpointResource extends EndpointResource {
}

export class AzureWebAppEndpointResource extends AgentlessEndpointResource {
    AccountId: string;
    ResourceGroupName: string;
    WebAppName: string;
    WebAppSlotName: string;
    DefaultWorkerPoolId: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.AzureWebApp;
    }
}

export interface IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
}

export const KubernetesCertificate: string = "KubernetesCertificate";
export const KubernetesStandard: string = "KubernetesStandard";
export const KubernetesAzure: string = "KubernetesAzure";
export const KubernetesAws: string = "KubernetesAws";

export class KubernetesCertificateAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
    ClientCertificate: string;

    constructor() {
        this.AuthenticationType = KubernetesCertificate;
    }
}

export class KubernetesStandardAccountAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
    AccountId: string;

    constructor() {
        this.AuthenticationType = KubernetesStandard;
    }
}

export class KubernetesAzureAuthentication extends KubernetesStandardAccountAuthentication {
    ClusterName: string;
    ClusterResourceGroup: string;

    constructor() {
        super();
        this.AuthenticationType = KubernetesAzure;
    }
}

export class KubernetesAwsAuthentication extends KubernetesStandardAccountAuthentication {
    AccountId: string;
    ClusterName: string;

    constructor() {
        super();
        this.AuthenticationType = KubernetesAws;
    }
}

export class KubernetesEndpointResource extends AgentlessEndpointResource {
    AccountType: string;
    ClusterUrl: string;
    ClusterCertificate: string;
    Namespace: string;
    SkipTlsVerification: string;
    ProxyId: string;
    DefaultWorkerPoolId: string;
    Authentication: KubernetesAwsAuthentication |
        KubernetesAzureAuthentication |
        KubernetesStandardAccountAuthentication |
        KubernetesCertificateAuthentication;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.Kubernetes;
    }
}

export class AzureServiceFabricClusterEndpointResource extends AgentlessEndpointResource {
    ConnectionEndpoint: string;
    SecurityMode: AzureServiceFabricSecurityMode;
    ServerCertThumbprint: string;
    ClientCertVariable: string;
    CertificateStoreLocation: string;
    CertificateStoreName: string;
    AadCredentialType: AADCredentialType;
    AadClientCredentialSecret: string;
    AadUserCredentialUsername: string;
    AadUserCredentialPassword: SensitiveValue;
    DefaultWorkerPoolId: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.AzureServiceFabricCluster;
    }
}

export enum AzureServiceFabricSecurityMode {
    Unsecure = "Unsecure",
    SecureClientCertificate = "SecureClientCertificate",
    SecureAzureAD = "SecureAzureAD",
}

export enum AADCredentialType {
    ClientCredential = "ClientCredential",
    UserCredential = "UserCredential"
}

export enum AzureCloudServiceEndpointDeploymentSlot {
    Staging = "Staging",
    Production = "Production",
}

export class AzureCloudServiceEndpointResource extends AgentlessEndpointResource {
    AccountId: string;
    CloudServiceName: string;
    DefaultWorkerPoolId: string;
    StorageAccountName: string;
    Slot: AzureCloudServiceEndpointDeploymentSlot;
    SwapIfPossible: boolean;
    UseCurrentInstanceCount: boolean;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.AzureCloudService;
    }
}

export class CloudRegionEndpointResource extends AgentlessEndpointResource {
    DefaultWorkerPoolId: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.None;
    }
}

export class OfflineDropEndpointResource extends AgentlessEndpointResource {
    Destination: OfflineDropDestinationResource;
    SensitiveVariablesEncryptionPassword: SensitiveValue;
    ApplicationsDirectory: string;
    OctopusWorkingDirectory: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.OfflineDrop;
        this.Destination = {
            DestinationType: OfflineDropDestinationType.Artifact
        };
    }
}

export class SshEndpointResource extends AgentlessEndpointResource {
    AccountId: string;
    Host: string;
    Port: number;
    Fingerprint: string;
    Uri: string;
    ProxyId: string;
    DotNetCorePlatform: string;
    constructor() {
        super();
        this.CommunicationStyle = CommunicationStyle.Ssh;
    }
}

export default EndpointResource;