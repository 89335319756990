import * as React from "react";
import {ExpandableFormSection, Summary} from "components/form";
import { PackageRequirement } from "client/resources/deploymentStepResource";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";

const PackageRequirementText: { [key: string]: string } = {
    [PackageRequirement.LetOctopusDecide]: "Let Octopus decide",
    [PackageRequirement.AfterPackageAcquisition]: "After package acquisition",
    [PackageRequirement.BeforePackageAcquisition]: "Before package acquisition"
};

interface PackageRequirementExpandableFormSectionProps {
    packageRequirement: PackageRequirement;
    onChange(packageRequirement: PackageRequirement): void;
}

class PackageRequirementRadioButtonGroup extends RadioButtonGroup<PackageRequirement> {}

class PackageRequirementExpandableFormSection extends React.Component<PackageRequirementExpandableFormSectionProps> {
    shouldComponentUpdate(nextProps: PackageRequirementExpandableFormSectionProps) {
        return nextProps.packageRequirement !== this.props.packageRequirement;
    }

    render() {
        return <ExpandableFormSection title="Package Requirement"
                help="Controls if this step needs to run before or after package acquisition."
                summary={this.props.packageRequirement === PackageRequirement.LetOctopusDecide
                    ? Summary.default(PackageRequirementText[this.props.packageRequirement])
                    : Summary.summary(PackageRequirementText[this.props.packageRequirement])}
                errorKey="packagerequirement">
            <PackageRequirementRadioButtonGroup
                value={this.props.packageRequirement}
                onChange={this.props.onChange}>
                {Object.keys(PackageRequirementText)
                    .map(pr => <RadioButton value={pr} label={PackageRequirementText[pr]} key={pr}/>)}
            </PackageRequirementRadioButtonGroup>
        </ExpandableFormSection>;
    }
}

export default PackageRequirementExpandableFormSection;