import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
import { ProjectResource } from "client/resources";

const Onboarding = (props: { project: ProjectResource }) => {
  return (
    <OnboardingPage
      title="Start adding steps and configuration variables to define your deployment process"
      intro="A project is the collection of deployment steps, configuration variables, packages and assets that Octopus brings together and executes during the process of deploying your software."
      learnMore={<span><ExternalLink href="OnboardingDeploymentProcessLearnMore">Learn more</ExternalLink>.</span>} />
  );
};

export default Onboarding;