exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_addRefreshButtons__2EBDl {\n  margin-top: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"addRefreshButtons": "style_addRefreshButtons__2EBDl"
};