exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_environmentList__2wXIx {\n  padding: 0!important;\n}\n.style_phaseDeleteButton__15OV2 {\n  margin-right: 1.4rem!important;\n  margin-top: 0.5rem!important;\n}\n.style_sectionControl__3xYO- {\n  margin-top: 1rem;\n}\n.style_actionsMenu__3WtpT {\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n}\n.style_anyEnvironment__SDX8J {\n  margin-top: 1rem;\n  display: block;\n}\n.style_padUnder__3d140 {\n  margin-bottom: 0.25rem;\n}\n.style_missingResource__20SCp {\n  color: #db4437;\n}\n.style_strongText__cZzCp {\n  font-weight: strong;\n}\n", ""]);

// exports
exports.locals = {
	"environmentList": "style_environmentList__2wXIx",
	"phaseDeleteButton": "style_phaseDeleteButton__15OV2",
	"sectionControl": "style_sectionControl__3xYO-",
	"actionsMenu": "style_actionsMenu__3WtpT",
	"anyEnvironment": "style_anyEnvironment__SDX8J",
	"padUnder": "style_padUnder__3d140",
	"missingResource": "style_missingResource__20SCp",
	"strongText": "style_strongText__cZzCp"
};