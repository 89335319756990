import SimpleDataTable from "../../../../components/SimpleDataTable/index";
import * as React from "react";
import { PermissionDescription } from "areas/configuration/components/Roles/RoleEdit";
import Checkbox from "../../../../components/form/Checkbox/Checkbox";
import ToolTip from "components/ToolTip/index";
const styles = require("./style.less");
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { words } from "lodash";
import ComponentRow from "components/ComponentRow/ComponentRow";
import CheckIcon from "material-ui/svg-icons/toggle/check-box";

interface PermissionTableProps {
    allPermissions: PermissionDescription[];
    permissions: { [permission: string]: boolean };

    onPermissionsChanged(permissions: { [permission: string]: boolean }): void;
}

interface PermissionTableState {
    filter?: string;
    selectedOnly: boolean;
}

class PermissionTable extends SimpleDataTable<PermissionDescription> {
}

export default class PermissionsTable extends React.Component<PermissionTableProps, PermissionTableState> {
    constructor(props: PermissionTableProps) {
        super(props);
        this.state = { selectedOnly: false };
    }

    //we return a new permissions object each change so we can just do ref compare
    shouldComponentUpdate(nextProps: PermissionTableProps, nextState: PermissionTableState): boolean {
        return nextProps.permissions !== this.props.permissions ||
            nextState.filter !== this.state.filter ||
            nextState.selectedOnly !== this.state.selectedOnly;
    }

    render() {
        const lcFilter = this.state.filter && this.state.filter.toLowerCase();
        const data = this.props.allPermissions
            .filter(p => !lcFilter ||
                p.name.toLowerCase().startsWith(lcFilter) ||
                words(p.description.toLowerCase()).filter(w => w.startsWith(lcFilter)).length > 0
            )
            .filter(p => !this.state.selectedOnly || this.props.permissions[p.name]);

        const filterArea = <ComponentRow>
            <FilterSearchBox hintText="Filter by permission..." value={this.state.filter} onChange={filter => this.setState({ filter })} />
            <Checkbox label="View selected only" value={this.state.selectedOnly} onChange={selectedOnly => this.setState({ selectedOnly })} />
        </ComponentRow>;

        const table = <PermissionTable
            data={data}
            headerColumns={["Permission", "Description"]}
            headerColumnClassNames={[styles.permissionColumn, styles.descriptionColumn]}
            onRow={this.buildRow}
        />;
        return [filterArea, table];
    }

    buildRow = (item: PermissionDescription) => {

        const toolTip = this.requiredTooltip(item.name);

        const checkbox =
            // just disabling the checkbox proved troublesome, have a go, or fix the change the sytle/icon if you're here
            this.isPermissionDisabled(item.name)
                ? <div key={item.name} className={styles.nameContainer}>
                    <div className={styles.lockedCheck}>
                        <ToolTip key={`tooltip-${item.name}`} content={toolTip}>
                            <CheckIcon />
                        </ToolTip>
                    </div>
                    <div>
                        {item.name}
                    </div>
                </div>
                : <Checkbox key={item.name}
                    label={item.name}
                    value={!!this.props.permissions[item.name]}
                    noMargin={true}
                    onChange={this.handleSelected(item.name)} />;

        return [checkbox, item.description];
    }

    isPermissionDisabled(permission: string): boolean {
        if (!permission.endsWith("View") && !permission.endsWith("ViewUnscoped")) {
            return false;
        }

        if (this.isMatchingPermissionSelected(permission, "ViewUnscoped", "EditUnscoped")) {
            return true;
        }

        if (this.isMatchingPermissionSelected(permission, "View", "Edit")) {
            return true;
        }

        if (this.isMatchingPermissionSelected(permission, "View", "Delete")) {
            return true;
        }

        return false;
    }

    isMatchingPermissionSelected = (permission: string, suffix: string, output: string) => {

        if (permission.endsWith(suffix)) {
            return this.isPermissionSelected(this.permissionName(permission, suffix, output));
        }

        return false;
    }

    permissionName = (permission: string, suffix: string, output: string) => {
        return permission.substr(0, permission.length - suffix.length) + output;
    }

    isPermissionSelected = (permission: string): boolean => {
        return !!permission && this.props.permissions[permission];
    }

    handleSelected = (permission: string) => {
        return (e: any) => {
            const permissions = { ...this.props.permissions };
            if (e) {
                permissions[permission] = true;
                const match = this.matchingPermission(permission);
                if (match) {
                    permissions[match] = true;
                }
            } else {
                delete permissions[permission];
            }
            // always return a new object so we can just do a ref compare in shouldupdate
            this.props.onPermissionsChanged(permissions);
        };
    }

    matchingPermission = (permission: string): string => {
        let match: string = null;

        if (permission.endsWith("EditUnscoped")) {
            match = this.permissionName(permission, "EditUnscoped", "ViewUnscoped");
        }
        if (permission.endsWith("Edit")) {
            match = this.permissionName(permission, "Edit", "View");
        }
        if (permission.endsWith("DeleteUnscoped")) {
            match = this.permissionName(permission, "DeleteUnscoped", "ViewUnscoped");
        }
        if (permission.endsWith("Delete")) {
            match = this.permissionName(permission, "Delete", "View");
        }

        // Make sure it exists
        if (!this.props.allPermissions.some(p => p.name === match)) {
            return null;
        }

        return match;
    }

    requiredTooltip = (permission: string): string | null => {
        if (!this.isPermissionSelected(permission)) {
            return null;
        }

        const editPerm = this.permissionName(permission, "View", "Edit");
        const deletePerm = this.permissionName(permission, "View", "Delete");
        const unscopedEditPerm = this.permissionName(permission, "ViewUnscoped", "EditUnscoped");

        if (this.isPermissionSelected(editPerm) && this.isPermissionSelected(deletePerm)) {
            return `${permission} is required because ${editPerm} and ${deletePerm} are selected`;
        } else if (this.isPermissionSelected(editPerm) || this.isPermissionSelected(unscopedEditPerm)) {
            return `${permission} is required because ${editPerm} is selected`;
        } else if (this.isPermissionSelected(deletePerm)) {
            return `${permission} is required because ${deletePerm} is selected`;
        }
    }
}