import * as React from "react";
import BaseComponent from "components/BaseComponent";
import SelectScriptModules from "./SelectScriptModules";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { DeploymentProcessResource } from "client/resources/deploymentProcessResource";
import { LifecycleResource, PhaseResource } from "client/resources";
import { EnvironmentResource } from "client/resources/environmentResource";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap/LifecycleMap";
import SelectLifecycle from "areas/projects/components/DeploymentProcess/SelectLifecycle";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { Permission } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import Callout, { CalloutType } from "components/Callout/Callout";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import Note from "components/form/Note/Note";

interface SideBarProps {
    deploymentProcess: DeploymentProcessResource;
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource;
    environmentsById?: { [id: string]: EnvironmentResource; };
    onDataChanged: () => any;
}

interface SideBarState {
    largeLifecyleToggle?: boolean;
}

export interface ScriptModule {
    Id: string;
    Name: string;
}

export default class SideBar extends BaseComponent<SideBarProps, SideBarState> {

    private lifecycleThreshold = 20;

    constructor(props: SideBarProps) {
        super(props);
        this.state = {
            largeLifecyleToggle: true
        };
    }

    render() {
        const largeLifecyle = this.props.lifecyclePreview && this.props.lifecyclePreview.Phases && (this.props.lifecyclePreview.Phases.length > this.lifecycleThreshold
            || this.props.lifecyclePreview.Phases.some((p: PhaseResource) => p.OptionalDeploymentTargets.length > this.lifecycleThreshold));

        return (
            <div>
                <PermissionCheck permission={Permission.LifecycleView}
                    alternate={<Callout type={CalloutType.Information}>
                        The {Permission.LifecycleView} permission is required to view the Lifecycle
                    </Callout>}>
                    {this.props.lifecyclePreview && <div>
                        <h4>Lifecycle</h4>
                        <InternalLink to={routeLinks.library.lifecycle(this.props.lifecyclePreview.Id)}>
                            <span className={styles.lifeCycleName}>
                                {this.props.lifecyclePreview.Name}
                            </span>
                        </InternalLink>

                        {largeLifecyle && this.props.lifecyclePreview && this.props.lifecyclePreview.Phases
                            && <Note>This lifecycle has many phases / environments</Note>}

                        {largeLifecyle && <ActionButton onClick={() => this.setState({ largeLifecyleToggle: !this.state.largeLifecyleToggle })}
                            label={this.state.largeLifecyleToggle ? "SHOW LIFECYCLE" : "HIDE LIFECYCLE"}
                            type={ActionButtonType.Secondary}
                        />}

                        <OpenDialogButton label={"CHANGE"}
                            permission={{
                                permission: Permission.ProjectEdit,
                                project: this.props.deploymentProcess.ProjectId,
                                wildcard: true
                            }}>
                            <SelectLifecycle projectId={this.props.deploymentProcess.ProjectId}
                                saveDone={() => this.props.onDataChanged()} />
                        </OpenDialogButton>
                        {(!largeLifecyle || !this.state.largeLifecyleToggle)
                            && <LifecycleMap environmentsById={this.props.environmentsById} lifecyclePreview={this.props.lifecyclePreview} />}
                    </div>}
                    <Note>Lifecycles can be defined in the <InternalLink to={routeLinks.library.lifecycles}>Library</InternalLink></Note>
                </PermissionCheck>
                <hr />
                <PermissionCheck permission={Permission.LibraryVariableSetView}
                    alternate={<Callout type={CalloutType.Information}>
                        The {Permission.LibraryVariableSetView} permission is required to view any included
                        script modules
                    </Callout>}>
                    {this.props.includedScriptModules && <div>
                        <h4>Script modules</h4>
                        {this.hasScriptModules()
                            ? <p className={styles.scriptModulesStatus}>
                                Included script modules:
                        </p>
                            : <div>
                                <p>No script modules have been included</p>
                                <Note>
                                    Modules can be created in the <InternalLink
                                        to={routeLinks.library.scripts.root}>Library</InternalLink>
                                </Note>
                            </div>}
                        <ul className={styles.includedScriptModules}>
                            {this.props.includedScriptModules.map(sm => <li key={sm.Id}>
                                <InternalLink to={routeLinks.library.script(sm.Id)}>{sm.Name}</InternalLink>
                            </li>)}
                        </ul>
                        <OpenDialogButton label={this.hasScriptModules() ? "CHANGE" : "INCLUDE"}
                            permission={{
                                permission: Permission.ProcessEdit,
                                project: this.props.deploymentProcess.ProjectId,
                                tenant: "*"
                            }}>
                            <SelectScriptModules projectId={this.props.deploymentProcess.ProjectId}
                                saveDone={() => this.props.onDataChanged()} />
                        </OpenDialogButton>
                    </div>}
                </PermissionCheck>
            </div>
        );
    }

    private hasScriptModules() {
        return this.props.includedScriptModules.length > 0;
    }
}
