import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import {groupBy} from "lodash";
import ActionTemplateCategory from "components/ActionTemplates/ActionTemplateCategory";
import ActionTemplateCard from "components/ActionTemplates/ActionTemplateCard";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import CommunityTemplateInstallation from "./CommunityTemplateInstallation";
import {ActionTemplateResource} from "client/resources/actionTemplateResource";
import matchesFilter from "components/ActionTemplates/matchesFilter";
import {Callout, CalloutType} from "components/Callout/Callout";
import FeatureToggle, {Feature} from "components/FeatureToggle/FeatureToggle";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
const styles = require("./styles.less");
import PageDivider from "../PageDivider/PageDivider";
import ActionTemplateCardList from "./ActionTemplateCardList";
import routeLinks from "../../routeLinks";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";

require("animate.css");

interface CommunityActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: { Type: string, Id: string }) => string;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => string;
    installationActionName: string;
}

interface CommunityActionTemplateListState {
    categories?: Array<{ name: string; templates: ActionTemplateSearchResource[] }>;
    categoryTemplates?: ActionTemplateSearchResource[];
    redirectTo?: string;
}

class CommunityActionTemplateList extends React.Component<CommunityActionTemplateListProps, CommunityActionTemplateListState> {
    categoriesElement: HTMLOListElement;
    private categoryTemplatesElement: any;

    constructor(props: CommunityActionTemplateListProps) {
        super(props);

        this.state = {
            categories: this.categoriseTemplates(props.templates),
            categoryTemplates: []
        };
    }

    categoriseTemplates(templates: ActionTemplateSearchResource[]) {
        const categorised = groupBy(templates, t => t.Category);
        return Object.keys(categorised).sort((category1, category2) => category1.toLowerCase().localeCompare(category2.toLowerCase()))
            .map(categoryName => ({name: categoryName, templates: categorised[categoryName]}));
    }

    showCategoryTemplates(templates: any) {
        // We are setting min-hight here to avoid "screen jumping" when the user switches from categories to templates.
        // Ideally this would be handled by a CSS transform but it did not work.
        this.categoryTemplatesElement.style.minHeight = this.categoriesElement.clientHeight + "px";
        this.setState({categoryTemplates: templates, categories: []});
    }

    showCategories = () => {
        this.setState({categoryTemplates: [], categories: this.categoriseTemplates(this.props.templates)});
    }

    showAllTemplates = () => {
        this.setState({categoryTemplates: this.props.templates, categories: []});
    }

    isInCategoryMode() {
        return this.state.categoryTemplates.length === 0 && this.state.categories.length > 0;
    }

    isInCategoryTemplatesMode() {
        return this.state.categoryTemplates.length > 0 && this.state.categories.length === 0;
    }

    componentWillReceiveProps(nextProps: CommunityActionTemplateListProps) {
        const nextState: Partial<CommunityActionTemplateListState> = {categories: this.categoriseTemplates(nextProps.templates)};
        if (!nextProps.filter) {
            nextState.categoryTemplates = [];
        }
        this.setState(nextState);
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true}/>;
        }
        const searchAllTemplates = this.props.filter;

        return <div>
            <PageDivider>Community Contributed Step Templates</PageDivider>
            <FeatureToggle feature={Feature.CommunityActionTemplates} enabled={false}>
                <Callout type={CalloutType.Information}>
                    Community library integration is disabled.
                    If you want to take advantage of hundreds of great templates developed by the members of our community
                    then please <InternalLink to={routeLinks.configuration.features}>
                    enable the integration feature.</InternalLink>
                </Callout>
            </FeatureToggle>

            <FeatureToggle feature={Feature.CommunityActionTemplates}>
                {this.props.templates.length === 0 && !this.props.filter && <Callout type={CalloutType.Information}>
                    Community library integration is enabled but there aren't any community step templates available.
                    Review the community step templates <InternalLink to={routeLinks.configuration.features}>feature
                    toggle</InternalLink> for more information.
                </Callout>}
            </FeatureToggle>

            {!searchAllTemplates ?
                <div>
                    <div className={styles.actions}>
                        {this.isInCategoryMode() && <ActionButton label="Show all" type={ActionButtonType.Ternary}
                                                                  onClick={this.showAllTemplates}/>}
                        {this.isInCategoryTemplatesMode() &&
                        <ActionButton label="Back" type={ActionButtonType.Ternary} onClick={this.showCategories}/>}
                    </div>
                    <div className={styles.groupingContainer}>
                        <ol className={styles.categories} ref={(element) => this.categoriesElement = element}>
                                <div>
                                {this.state.categories.map(category => {
                                    return this.withAnimation(category.name,
                                                        <ActionTemplateCategory name={category.name}
                                                                               templates={category.templates}
                                                                               onCategorySelected={() => this.showCategoryTemplates(category.templates)}/>);
                                })}
                                </div>
                        </ol>
                        <ol className={styles.categoryTemplates}
                            ref={(element) => this.categoryTemplatesElement = element}>
                                <div>
                                    {this.state.categoryTemplates.map(template => this.renderSingle(template))}
                                </div>
                            </ol>
                    </div>
                </div>
                :
                this.filteredTemplates()
            }
        </div>;
    }

    private filteredTemplates() {
        const filteredResults = this.props.templates.filter(at => matchesFilter(at, this.props.filter));

        if (filteredResults.length === 0 && this.props.filter) {
            return <p>There are no community step templates that match your filter criteria.</p>;
        }

        return <ActionTemplateCardList>
            {filteredResults.map(template => this.renderSingle(template))}
        </ActionTemplateCardList>;
    }

    private renderSingle(template: ActionTemplateSearchResource) {
        return this.withAnimation(template.Type + template.Id,
            <ActionTemplateCard template={template}
                                   primaryAction={this.installationDialog(template)}
                                   secondaryAction={<InternalLink size={0.75} to={this.props.onDetailsUrlRequested(template)}>View details</InternalLink>}
            />);
    }

    private withAnimation(key: string, content: React.ReactNode) {
        return <div key={key} className="animated zoomIn">
            {content}
        </div>;
    }

    private installationDialog(template: ActionTemplateSearchResource) {
        return <OpenDialogButton
            label={this.props.installationActionName}
            type={ActionButtonType.Primary}>
            <CommunityTemplateInstallation communityTemplateId={template.Id}
                                           title={this.props.installationActionName}
                                           detailsLink={this.props.onDetailsUrlRequested(template)}
                                           saveDone={this.installationSucceeded}/>
        </OpenDialogButton>;
    }

    private installationSucceeded = (actionTemplate: ActionTemplateResource) => {
        const url = this.props.onPostSelectionUrlRequested({Type: actionTemplate.ActionType, Id: actionTemplate.Id});
        this.setState({redirectTo: url});
    }
}

export default CommunityActionTemplateList;