exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_headerContainer__2pa04 {\n  width: 100%;\n}\n.style_headerRowContainer__p7cSn {\n  display: flex;\n  flex-direction: row;\n}\n.style_virtualList__3ipPv {\n  overflow: visible !important;\n}\n.style_virtualList__3ipPv:focus {\n  outline: none;\n}\n.style_virtualList__3ipPv > * {\n  overflow: visible !important;\n}\n.style_table__18q4A {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.style_tableBody__2wPZW {\n  flex: 1 0 auto;\n  will-change: transform;\n}\n", ""]);

// exports
exports.locals = {
	"headerContainer": "style_headerContainer__2pa04",
	"headerRowContainer": "style_headerRowContainer__p7cSn",
	"virtualList": "style_virtualList__3ipPv",
	"table": "style_table__18q4A",
	"tableBody": "style_tableBody__2wPZW"
};