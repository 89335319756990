import * as React from "react";
import { CategoryDefinition } from "../Registry/packagingRegistry";

const category: CategoryDefinition = {
    category: "ASP.NET Core",
    title: <React.Fragment>How would you like to package this <strong>ASP.NET Core</strong> application?</React.Fragment>,
    help: null,
    displayOrder: 20,
 };

export default category;
