import * as React from "react";
import StandardLayoutInternal from "./StandardLayoutInternal";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import GlobalState from "globalState";
import {clearUnhandledErrorAction} from "./reducers";

interface StandardLayoutDispatchers {
    onErrorClose(): void;
}

const StandardLayout = connect(
    (state: GlobalState) => {
        return {
            unhandledError: state.unhandledError
        };
    },
    (dispatch): StandardLayoutDispatchers => {
        return {
            onErrorClose: bindActionCreators(clearUnhandledErrorAction, dispatch)
        };
    }
)(StandardLayoutInternal);

export default StandardLayout;