exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__2wjRD {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__2MnDs {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__165Gg {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__2eyR8 {\n    display: none !important;\n  }\n}\n.style_container__2gDxh {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n}\n.style_select__F6zV8 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex: 0 0 auto;\n}\n.style_summary__S_TBQ {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  flex: 1 1 100%;\n}\n.style_row__1Veoa {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  width: 100%;\n}\n.style_row__1Veoa:nth-last-child(1) {\n  margin-top: 0.5rem;\n}\n.style_titleContainer__3R39k {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n.style_title__1AHNH {\n  width: 100%;\n}\n.style_details__11prN {\n  display: flex;\n  flex-direction: row;\n}\n.style_icon__1mnue {\n  padding: 0 0 0 0.4rem;\n  align-self: center;\n}\n.style_propertyContainer__1YqQN {\n  padding: 0.4rem 0.4rem 0 0;\n  display: flex;\n  color: #333333;\n  font-size: 0.875rem;\n}\n.style_propertyContainer__1YqQN span:nth-child(2) {\n  margin-left: 0.25rem;\n  margin-right: 0.75rem;\n}\n.style_propertyContainer__1YqQN + .style_thumbprint__2NMpH {\n  font-weight: 400;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__2wjRD",
	"hideSm": "style_hide-sm__2wjRD",
	"visible-sm": "style_visible-sm__2MnDs",
	"visibleSm": "style_visible-sm__2MnDs",
	"hide-md": "style_hide-md__165Gg",
	"hideMd": "style_hide-md__165Gg",
	"visible-md": "style_visible-md__2eyR8",
	"visibleMd": "style_visible-md__2eyR8",
	"container": "style_container__2gDxh",
	"select": "style_select__F6zV8",
	"summary": "style_summary__S_TBQ",
	"row": "style_row__1Veoa",
	"titleContainer": "style_titleContainer__3R39k",
	"title": "style_title__1AHNH",
	"details": "style_details__11prN",
	"icon": "style_icon__1mnue",
	"propertyContainer": "style_propertyContainer__1YqQN",
	"thumbprint": "style_thumbprint__2NMpH"
};