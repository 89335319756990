import * as React from "react";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import {repository} from "clientInstance";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import BuiltinActionTemplateList from "areas/library/components/ActionTemplates/BuiltinActionTemplateList";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";

interface BuiltinTemplatesState extends DataBaseComponentState {
    templates: ActionTemplateSearchResource[];
    isLoaded: boolean;
    filterText?: string;
}

class BuiltinTemplates extends DataBaseComponent<any, BuiltinTemplatesState> {

    constructor(props: any) {
        super(props);

        this.state = {
            templates: null,
            isLoaded: false,
            filterText: null
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const templates = await repository.ActionTemplates.search();
            this.setState({
                templates: templates.filter(t => t.IsBuiltIn),
                isLoaded: true
            });
        });
    }

    render() {
        return <LibraryLayout {...this.props}>
            <PaperLayout title="Choose Step Template"
                breadcrumbTitle={"Step Templates"}
                breadcrumbPath={routeLinks.library.stepTemplates.root}
                fullWidth={true}
                flatStyle={true}
                busy={this.state.busy}
                errors={this.state.errors}>
                {this.state.isLoaded &&
                    <div>
                        <FilterSearchBox
                            hintText="Filter by name, category or description..."
                            onChange={(value) => this.setState({ filterText: value.toLowerCase()})}
                            fullWidth={true}
                            autoFocus={true}
                            containerClassName={styles.filterSearchBoxContainer}
                        />
                        <BuiltinActionTemplateList templates={this.state.templates}
                            onPostSelectionUrlRequested={routeLinks.library.stepTemplates.new}
                            filter={this.state.filterText} />
                    </div>}
            </PaperLayout>
        </LibraryLayout>;
    }
}

export default BuiltinTemplates;