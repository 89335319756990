exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_packageTableContainer__2kgTf {\n  overflow-x: auto;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk {\n  font-size: 1rem;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk th {\n  font-size: 0.875rem;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_packageTableRowColumn__36mtu {\n  vertical-align: middle !important;\n  padding: 0.25rem 1.125rem;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_packageTableRowColumnName__2Rxgw {\n  min-width: 10rem;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_packageColumn__1bRnp {\n  width: 25%;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_latestColumn__2AvnO {\n  width: 23%;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_lastColumn__3lQWx {\n  width: 23%;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_specificColumn__PZfv6 {\n  width: 29%;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_versionTextbox__2H8IT {\n  width: 18rem !important;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_specificVersionDiv__2QhIq {\n  display: flex;\n  align-items: center;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_inlineDiv__1jaz2 {\n  display: inline-block;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_inlineDiv__1jaz2 .style_editVersionArea__1S1DB {\n  min-width: 6.875rem;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_radioButtonContainer__6ODeb {\n  margin-top: -0.6rem;\n}\n.style_packageTableContainer__2kgTf .style_packageTable__2_OTk .style_radioButtonContainer__6ODeb .style_myRadioButton__2zbux {\n  margin-right: 0.5rem;\n}\n.style_actionName__BntYL {\n  font-size: 0.875rem;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"packageTableContainer": "style_packageTableContainer__2kgTf",
	"packageTable": "style_packageTable__2_OTk",
	"packageTableRowColumn": "style_packageTableRowColumn__36mtu",
	"packageTableRowColumnName": "style_packageTableRowColumnName__2Rxgw",
	"packageColumn": "style_packageColumn__1bRnp",
	"latestColumn": "style_latestColumn__2AvnO",
	"lastColumn": "style_lastColumn__3lQWx",
	"specificColumn": "style_specificColumn__PZfv6",
	"versionTextbox": "style_versionTextbox__2H8IT",
	"specificVersionDiv": "style_specificVersionDiv__2QhIq",
	"inlineDiv": "style_inlineDiv__1jaz2",
	"editVersionArea": "style_editVersionArea__1S1DB",
	"radioButtonContainer": "style_radioButtonContainer__6ODeb",
	"myRadioButton": "style_myRadioButton__2zbux",
	"actionName": "style_actionName__BntYL"
};