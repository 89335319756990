import * as React from "react";
import {ProjectResource} from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import {ProjectChip} from "components/Chips/index";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";

interface ProjectMultiSelectProps extends FormFieldProps<string[]> {
    items: ProjectResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
}

const ProjectTypedMultiSelect = MultiSelect<ProjectResource>();
const LookupProjectChip = LookupResourceChipComponent<ProjectResource>();

const ProjectMultiSelect: React.StatelessComponent<ProjectMultiSelectProps> = props => {
    const chipRenderer = (r: ProjectResource, onRequestDelete: (event: object) => void) => {
        return <LookupProjectChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.Project}
            chipRender={item => <ProjectChip
                onRequestDelete={onRequestDelete}
                project={item}
            />}
        />;
    };
    return <ProjectTypedMultiSelect
        items={props.items}
        fieldName="projects"
        renderChip={chipRenderer}
        {...props} />;
};

export default ProjectMultiSelect;