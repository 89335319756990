import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {DateRange} from "react-date-range";
import * as moment from "moment";
import {
    white,
    primaryText,
    primary,
    primaryDark,
    primaryLight,
    divider,
} from "colors";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

export interface DateRange {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

interface DateRangePickerProps {
    startDate: moment.Moment;
    endDate: moment.Moment;
    format?: string;

    onChange?(range: DateRange): void;

    // Save handler (eg. for when used in dialogs).
    onSaveDone?(range: DateRange): void;
}

interface DateRangePickerState extends DataBaseComponentState {
    range: DateRange;
}

export default class DateRangePicker extends DataBaseComponent<DateRangePickerProps, DateRangePickerState> {

    constructor(props: DateRangePickerProps) {
        super(props);
        this.state = {
            range: {
                startDate: this.props.startDate.clone(),
                endDate: this.props.endDate.clone(),
            }
        };
    }

    render() {
        const ranges = {
            "Today": {startDate: (e: any) => e, endDate: (e: any) => e},
            "Yesterday": {startDate: (e: any) => e.subtract(1, "days"), endDate: (e: any) => e.subtract(1, "days")},
            "Last 7 days": {startDate: (e: any) => e.subtract(7, "days"), endDate: (e: any) => e},
            "Last 30 days": {startDate: (e: any) => e.subtract(30, "days"), endDate: (e: any) => e},
            "Last 90 days": {startDate: (e: any) => e.subtract(90, "days"), endDate: (e: any) => e},
        };
        return <SaveDialogLayout title="Date Range"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            <DateRange
                onChange={(x: any) => this.handleSelect(x)}
                format={this.props.format}
                startDate={this.state.range.startDate}
                endDate={this.state.range.endDate}
                ranges={ranges}
                theme={{
                    DateRange: {
                        background: white,
                    },
                    PredefinedRangesItem: {
                        background: white,
                    },
                    PredefinedRangesItemActive: {
                        color: primaryDark,
                    },
                    MonthButton: {
                        background: divider
                    },
                    MonthArrowPrev: {
                        borderRightColor: primaryDark,
                    },
                    MonthArrowNext: {
                        borderLeftColor: primaryDark,
                    },
                    DaySelected: {
                        background: primaryDark
                    },
                    DayActive: {
                        background: primary,
                        boxShadow: "none"
                    },
                    DayInRange: {
                        background: primary,
                        color: white
                    },
                    DayHover: {
                        background: white,
                        color: primaryDark,
                    },
                    /*
                    TODO: mark.siedle - A PR has been submitted with the author of this component @ https://github.com/Adphorus/react-date-range/pull/170.
                    Once this has been merged we can remove this width hack and re-test in Firefox.
                    */
                    Weekday: {
                        width: "2.313rem",
                    },
                    Day: {
                        width: "2.313rem",
                    },
                }}
            />
        </SaveDialogLayout>;
    }

    private async save() {
        if (this.props.onSaveDone) {
            this.props.onSaveDone(this.state.range);
        }
        return true;
    }

    private handleSelect(range: any) {
        range = {
            startDate: range.startDate.clone().startOf("day"), // https://github.com/Adphorus/react-date-range/issues/114
            endDate: range.endDate.clone().endOf("day")
        };

        this.setState({range});
        if (this.props.onChange) {
            this.props.onChange(range);
        }
    }
}