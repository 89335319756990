import * as React from "react";
import {
    Note,
} from "components/form";
import FormFieldProps from "components/form/FormFieldProps";
import BaseComponent from "components/BaseComponent";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import {TenantedDeploymentMode} from "client/resources";
import ExternalLink from "components/Navigation/ExternalLink";

interface TenantedDeploymentParticipationSelectorProps {
    tenantMode: TenantedDeploymentMode;
    resourceTypeLabel: string;
    onChange(newValue: string): void;
}

class TenantedDeploymentParticipationSelector extends BaseComponent<TenantedDeploymentParticipationSelectorProps, any> {
    render() {
        return <RadioButtonGroup value={this.props.tenantMode}
                    onChange={e => this.handleTenantModeChange(e)}>
            <RadioButton value={TenantedDeploymentMode.Untenanted}
                            isDefault={true}
                            label="Exclude from tenanted deployments"/>
            <Note>The {this.props.resourceTypeLabel} will never be included in tenanted deployments.</Note>

            <RadioButton value={TenantedDeploymentMode.Tenanted}
                            label="Include only in tenanted deployments"/>
            <Note>
                The {this.props.resourceTypeLabel} will only be included in deployments to the associated tenants.
                It will be excluded from untenanted deployments.
            </Note>

            <RadioButton value={TenantedDeploymentMode.TenantedOrUntenanted}
                            label="Include in both tenanted and untenanted deployments"/>
            <Note>
                The {this.props.resourceTypeLabel} will be included in untenanted deployments,
                and deployments to the associated tenants.
            </Note>

            <Note>
                For more information on designing a multi-tenant hosting model refer to
                our <ExternalLink href="MultiTenantHostingModel">documentation</ExternalLink>.
            </Note>
        </RadioButtonGroup>;
    }

    private handleTenantModeChange(value: any) {
        this.props.onChange(value);
    }
}

export default TenantedDeploymentParticipationSelector;