import * as React from "react";
import {repository} from "clientInstance";
import {LibraryLayout} from "../LibraryLayout/LibraryLayout";
import {Permission} from "client/resources";
import PermissionCheck, {isAllowed} from "components/PermissionCheck/PermissionCheck";
import PaperLayout from "components/PaperLayout";
import {OpenDeleteDialogButton} from "components/Button";
import InternalLink from "components/Navigation/InternalLink";
import {ResourceCollection} from "client/resources";
import PagingDataTable from "components/PagingDataTable";
import ByteSizeFormatter from "utils/ByteSizeFormatter";
import {RouteComponentProps} from "react-router";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
const styles = require("./style.less");
import {PackageVersionResource} from "../../../../client/resources/packageResource";
import * as moment from "moment";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import FeedResource from "client/resources/feedResource";

class PackageItemDataTable extends PagingDataTable<PackageVersionResource> {
}

interface PackageVersionsListState extends DataBaseComponentState {
    packagesResponse?: ResourceCollection<PackageVersionResource>;
    packagesToDelete: string[];
    feed?: FeedResource;
}

export class PackageVersionsList extends DataBaseComponent<RouteComponentProps<{ packageId: string }>, PackageVersionsListState> {

    private packageId: string;
    private isFeedAdmin = isAllowed({permission: Permission.BuiltInFeedAdminister, project: "*"});

    constructor(props: RouteComponentProps<{ packageId: string }>) {
        super(props);
        this.packageId = this.props.match.params.packageId;
        this.state = {
            packagesToDelete: []
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            await this.load();
        });
    }

    async load() {
        const feed = await repository.Feeds.getBuiltIn();
        const packagesResponse = await repository.Feeds.searchPackageVersions(feed, this.packageId, {take: 30});
        this.setState({ packagesResponse, feed});
    }

    async delete() {
        await this.doBusyTask(async () => {
            await repository.Packages.deleteMany(this.state.packagesToDelete);
            await this.load();
        });
        return true;
    }

    render() {
        if (this.state.packagesResponse && this.state.packagesResponse.Items.length === 0) {
            return <InternalRedirect to={routeLinks.library.builtInRepository.root}/>;
        }

        const sideBar = <p>
            If any projects depend on these packages, they will not be able to be deployed
            should you delete required versions from the repository.
        </p>;

        const table = this.state.packagesResponse && this.state.packagesResponse.Items.length > 0
            ? <PackageItemDataTable
                initialData={this.state.packagesResponse}
                onRow={this.buildRow}
                apiSearchParams={["filter"]}
                filterSearchEnabled={true}
                onFilter={this.filter}
                headerColumns={["Version", "Published", "Size"]}
                onEmpty={this.handleOnEmpty}
                rowColumnClassName={styles.packageVersionCell}
                onItemsChecked={this.isFeedAdmin ? (packagesToDelete: string[]) => this.setState({packagesToDelete}) : null} />
            : null;

        return <LibraryLayout {...this.props}>
            <PaperLayout title={this.packageId}
                         breadcrumbTitle={"Packages"}
                         breadcrumbPath={routeLinks.library.builtInRepository.root}
                         sectionControl={this.deleteSelectedButton()}
                         busy={this.state.busy}
                         errors={this.state.errors}>
                <SidebarLayout sideBar={sideBar}>{table}</SidebarLayout>
            </PaperLayout>
        </LibraryLayout>;
    }

    private filter(filter: string, resource: PackageVersionResource) {
        return !filter
            || filter.length === 0
            || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false)
            || (resource.Title ? resource.Title.toLowerCase().includes(filter.toLowerCase()) : false)
            || (resource.Version ? resource.Version.toLowerCase().includes(filter.toLowerCase()) : false);
    }

    private handleOnEmpty = () => {
        return (
            <div>No packages found</div>
        );
    }

    private deleteSelectedButton = () => {
        const message = this.state.packagesToDelete.length === 1 ? "Are you sure you want to delete this package?" :
            `Are you sure you want to delete these ${this.state.packagesToDelete.length} packages?`;
        return <PermissionCheck permission={Permission.BuiltInFeedAdminister} project="*">
            <OpenDeleteDialogButton
                label="Delete Selected"
                disabled={!this.state.packagesResponse || this.state.packagesToDelete.length === 0}
                dialogTitle={message}
                onDeleteClick={() => this.delete()}>
                <p>Deleting packages will permanently remove files from disk.</p>
                <p>Are you sure you want to delete these packages?</p>
            </OpenDeleteDialogButton>
        </PermissionCheck>;
    }

    private buildRow = (pkg: PackageVersionResource) => {
        return [
            <div className={styles.row}>
                <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                    <a href={pkg.Links["Raw"]}>
                        <em className="fa fa-download"/>
                    </a>
                    &nbsp;
                </PermissionCheck>
                <InternalLink to={routeLinks.library.builtInRepository.package(encodeURIComponent(pkg.Id))}>
                    {pkg.Version}
                </InternalLink>
            </div>,
            moment(pkg.Published).format("llll"),
            ByteSizeFormatter(pkg.SizeBytes)
        ].filter(c => !!c);
    }
}

export default PackageVersionsList;