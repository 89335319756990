import * as React from "react";
import { Permission, OctopusError } from "../../../../client/resources";
import DynamicForm from "components/DynamicForm/DynamicForm";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { RouteComponentProps } from "react-router";
import { repository } from "clientInstance";
import { SettingsMetadataItemResource } from "client/configuration/settingsMetadataResource";
import { MetadataTypeCollectionDescriptor, MetadataTypeDescriptor, MetadataTypeCollection } from "client/resources/dynamicFormResources";
import routeLinks from "../../../../routeLinks";
import MetadataTypeValidator from "components/DynamicForm/MetadataTypeValidator";
import PaperLayout from "components/PaperLayout/PaperLayout";

interface SettingsEditModel {
    id: string;
    name: string;
}

interface SettingsEditState extends OptionalFormBaseComponentState<SettingsEditModel> {
    settingsMetadata?: SettingsMetadataItemResource;
    types?: MetadataTypeCollection;
    values?: any;
    validator: MetadataTypeValidator;
}

export class SettingsEdit extends FormBaseComponent<RouteComponentProps<{ settingId: string }>, SettingsEditState, SettingsEditModel> {
    constructor(props: RouteComponentProps<{ settingId: string }>) {
        super(props);
        this.state = {
            settingsMetadata: null,
            types: null,
            values: null,
            validator: new MetadataTypeValidator()
        };
    }

    currentSettingsId(): string {
        return this.props.match.params.settingId;
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const settingsResponse = await repository.Settings.getById(this.currentSettingsId());
            const metadata = await repository.Settings.getMetadata(settingsResponse);
            const values = await repository.Settings.getValues(settingsResponse);
            this.setState({ settingsMetadata: settingsResponse, types: metadata, values });
            this.setModel(values);
        });
    }

    handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            this.validate();
            const saveResponse = await repository.Settings.saveValues(this.state.settingsMetadata, this.state.values);
            this.setState({
                settingsMetadata: this.state.settingsMetadata,
                types: this.state.types,
                values: saveResponse });
            this.setModel(saveResponse);
            this.clearError();
        });
    }

    render() {
        const parentSection = this.state.types && <div>
             <DynamicForm description={this.state.types.Description} types={this.state.types.Types} values={this.state.values} onChange={(c) => this.setState({
                                                                                                    settingsMetadata: this.state.settingsMetadata,
                                                                                                    types: this.state.types,
                                                                                                    values: c
                                                                                                })}/>
        </div>;
        if (this.state.types && this.areAllTypesReadonly(this.state.types.Types)) {
            return <PaperLayout
                    breadcrumbTitle={"Settings"}
                    title={this.state.settingsMetadata && this.state.settingsMetadata.Name}
                    breadcrumbPath={routeLinks.configuration.settings.root}>
                    {parentSection}
                </PaperLayout>;
        } else {
            return <FormPaperLayout
                        breadcrumbTitle={"Settings"}
                        breadcrumbPath={routeLinks.configuration.settings.root}
                        model={this.state.model}
                        cleanModel={this.state.cleanModel}
                        busy={this.state.busy}
                        errors={this.state.errors}
                        saveText="Settings changed"
                        title={this.state.settingsMetadata && this.state.settingsMetadata.Name}
                        savePermission={{permission: Permission.ConfigureServer}}
                        onSaveClick={this.handleSaveClick}>
                        {parentSection}
                    </FormPaperLayout>;
        }
    }

    private validate() {
        const validations = this.state.validator.validateType(this.state.types.Types, this.state.values);
        if (validations && validations.length > 0) {
            const exception = new OctopusError(0, "There were validation errors:");
            exception.Errors = validations;
            throw exception;
        }
    }

    private areAllTypesReadonly(types: MetadataTypeCollectionDescriptor[]) {
        let isReadonly: boolean = true;
        types.map(t => isReadonly = isReadonly && this.areAllPropertiesReadonly(t));
        return isReadonly;
    }

    private areAllPropertiesReadonly(type: MetadataTypeCollectionDescriptor) {
        let isReadonly: boolean = true;
        type.Properties.map(p => isReadonly = isReadonly && this.isTypeReadonly(p));
        return isReadonly;
    }

    private isTypeReadonly(type: MetadataTypeDescriptor) {
        return type.DisplayInfo.ReadOnly;
    }
}
