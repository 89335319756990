import * as React from "react";
import {Callout, CalloutType} from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

const AzureServiceFabricSdkRequiredInfo = (props: {}) => {
    return  <Callout type={CalloutType.Information}>
            The <ExternalLink href="ServiceFabricSdkDownload">Service Fabric SDK</ExternalLink> must be installed
            on the <strong>Octopus Server</strong> for this step.  Additional information about this step can be found in
            the <ExternalLink href="ServiceFabricDeployingApps">Deploying to Service Fabric documentation</ExternalLink>.
        </Callout>;
};

export default AzureServiceFabricSdkRequiredInfo;