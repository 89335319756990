import * as React from "react";
import CenteredLayout from "components/CenteredLayout/CenteredLayout";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import {UnhandledError} from "globalState";

interface AuthenticationLayoutProps {
    unhandledError: UnhandledError;
}

export default class AuthenticationLayoutInternal extends React.Component<AuthenticationLayoutProps, any> {
    render() {
        return <CenteredLayout>
                    {this.props.unhandledError && <ErrorPanel message={this.props.unhandledError.message} details={this.props.unhandledError.details} />}
                    {this.props.children}
        </CenteredLayout>;
    }
}