import * as React from "react";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import ExternalLink from "../Navigation/ExternalLink/ExternalLink";
import {isInternalLinkPart, isTextPart, parseLinks} from "./parseLinks";
import { withRouter, RouteComponentProps } from "react-router";

interface InternalTextWithLinksProps {
    message: string;
    allowHtml: boolean;
}

type InternalLinkProps = InternalTextWithLinksProps & RouteComponentProps<{spaceId: string}>;

class TextWithLinks extends React.Component<InternalLinkProps> {
    render() {
        return parseLinks(this.props.message).map((part, index) => {
            if (isTextPart(part)) {

                return this.props.allowHtml
                    ? <span key={index} dangerouslySetInnerHTML={{__html: part.content}} />
                    : <span key={index}>{part.content}</span>;
            }

            if (isInternalLinkPart(part)) {
                return <InternalLink key={index} to={part.internalUrl}>{part.text}</InternalLink>;
            }

            return <ExternalLink key={index} href={part.externalUrl}>{part.text}</ExternalLink>;
        });
    }
}

export default withRouter(TextWithLinks);