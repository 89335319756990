import Client from "../client";
import {PermissionDescriptions} from "../resources/permissionDescriptions";

class PermissionDescriptionRepository  {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    all(): Promise<PermissionDescriptions> {
        return this.client.get(this.client.getLink("PermissionDescriptions"), null);
    }
}

export default PermissionDescriptionRepository;