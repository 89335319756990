exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".feedteststyle_headerColumn__2NcTA {\n  max-width: none !important;\n}\n.feedteststyle_headerColumn__2NcTA:first-child {\n  width: 18.75rem;\n}\n.feedteststyle_headerColumn__2NcTA:nth-child(2) {\n  width: 9.375rem;\n}\n.feedteststyle_rowColumn__1supW {\n  width: auto !important;\n  max-width: none !important;\n  word-break: break-all;\n  word-wrap: break-word;\n  white-space: normal;\n}\n", ""]);

// exports
exports.locals = {
	"headerColumn": "feedteststyle_headerColumn__2NcTA",
	"rowColumn": "feedteststyle_rowColumn__1supW"
};