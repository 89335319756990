import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";

export default function  matchesFilter(template: ActionTemplateSearchResource, filter: string) {
    if (!filter) {
        return true;
    }

    return  (template.Name && template.Name.toLowerCase().includes(filter)) ||
        (template.Description && template.Description.toLowerCase().includes(filter)) ||
        (template.Category && template.Category.toLowerCase().includes(filter));
}