import * as React from "react";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import { white, primaryText } from "../../colors";
const styles = require("./SectionStepLink.less");
import InternalLink, { FontWeight } from "../Navigation/InternalLink/InternalLink";

const popoverStyle = {
    backgroundColor: white,
    color: primaryText
};

const menuItemStyle = {
    color: primaryText
};

interface SectionStepLinkProps {
    text: string;
    weight?: FontWeight;
    path: string;
    items: Array<{
        label: string,
        link: string
    }>;
    onNavigating(): void;
}

interface SectionStepLinkState {
    open: boolean;
    anchor: EventTarget;
}

export class SectionStepLink extends React.Component<SectionStepLinkProps, SectionStepLinkState> {
    constructor(props: SectionStepLinkProps) {
        super(props);

        this.state = {
            open: false,
            anchor: null,
        };
    }

    render() {
        if (this.props.items) {
            return (
                <span>
                    <Popover
                        open={this.state.open}
                        style={popoverStyle}
                        canAutoPosition={true}
                        anchorEl={this.state.anchor as any}
                        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                        targetOrigin={{ horizontal: "left", vertical: "top" }}
                        onRequestClose={this.handleRequestClose}>
                        <Menu>
                            {this.props.items.map(item =>
                                <InternalLink key={item.label} to={item.link} weight={this.props.weight}>
                                    <MenuItem key={item.label} style={menuItemStyle} primaryText={item.label}
                                        onClick={this.handleNavigate} />
                                </InternalLink>
                            )}
                        </Menu>
                    </Popover>
                    <span className={styles.lookLikeALink} onClick={this.handleTouchTap}>{this.props.text}
                        {this.props.items && <em style={{ paddingLeft: "5px" }} className="fa fa-caret-down" />}
                    </span>
                </span>);
        }

        if (this.props.path) {
            return <InternalLink to={this.props.path} weight={this.props.weight}>
                <span onClick={() => this.handleNavigate()}>{this.props.text}</span>
            </InternalLink>;
        }

        return <span>{this.props.text}</span>;
    }

    private handleNavigate = () => {
        this.setState({
            open: false
        });

        if (this.props.onNavigating) {
            this.props.onNavigating();
        }
    }

    private handleTouchTap = (event: React.SyntheticEvent<{}>) => {
        event.preventDefault();

        this.setState({
            open: true,
            anchor: event.currentTarget
        });
    }

    private handleRequestClose = () => {
        this.setState({
            open: false
        });
    }
}