import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

export const OnboardingTextForFeeds = "Octopus can consume packages from TeamCity, VSTS, Docker Registry, MyGet, Maven, locally or hosted remotely.";

const Onboarding = () => {
  return (
    <OnboardingPage
      title="Add a feed to integrate with an external package repository"
      intro={<span>{OnboardingTextForFeeds}</span>}
      learnMore={<span><ExternalLink href="OnboardingPackageRepositoriesLearnMore">Learn more</ExternalLink>.</span>}
    />
  );
};

export default Onboarding;