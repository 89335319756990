import * as React from "react";
import {TaskDetailsResource} from "client/resources";
import TaskLogBlock, {UniqueActivityElement} from "components/TaskLogLines/TaskLogBlock";
import {ActivityLogEntryCategory, ActivityStatus} from "client/resources/taskDetailsResource";
import {flatten, concat, without} from "lodash";
import {Item, default as Select} from "components/form/Select/Select";
import {NavigationButton} from "components/Button/NavigationButton";
const styles = require("./style.less");
import {Section} from "components/Section/Section";
import {Callout, CalloutType} from "components/Callout/Callout";
import {RouteComponentProps, withRouter} from "react-router";
import TaskProgress from "../TaskProgress";
import routeLinks from "../../../../../routeLinks";

interface TaskLogComponentProps {
    details: TaskDetailsResource;
    activityElements: UniqueActivityElement[];
    verbose: boolean;
    tail: boolean;
    initialExpandedId?: string;
    showAdditional(): void;
    setVerbose(value: boolean): void;
    setTail(value: boolean): void;
}

type TaskLogProps = TaskLogComponentProps & RouteComponentProps<any>;

interface TaskDetailState {
    expandedIds: string[];
    expandMode: ExpandMode;
    reloadCount?: number;
}

enum ExpandMode {
    All = "All",
    Interesting = "Interesting",
    Errors = "Errors",
    None = "None",
    Custom = "Custom"
}

class TaskLog extends React.Component<TaskLogProps, TaskDetailState> {

    expandActions: Item[] = [ExpandMode.All, ExpandMode.Interesting, ExpandMode.Errors, ExpandMode.None, ExpandMode.Custom]
        .map(m => ({text: m, value: m}));

    reloadCount  = 0;

    constructor(props: TaskLogProps) {
        super(props);

        // noinspection TsLint
        this.state = {
            expandedIds: this.getInterestingIds(),
            expandMode: ExpandMode.Interesting,
        };
    }

    componentDidMount() {
        this.reloadCount++;
        if (this.props.initialExpandedId) {
            this.updateStateWithInitialExpandedId(this.props);
        }
    }

    componentWillReceiveProps(nextProps: TaskLogProps) {
        this.reloadCount++;
        if (this.props.initialExpandedId !== nextProps.initialExpandedId) {
            this.updateStateWithInitialExpandedId(nextProps);
        }
    }

    updateStateWithInitialExpandedId(props: TaskLogProps) {
        if (props.initialExpandedId) {
            this.setState({
                expandedIds: this.getParents(props.initialExpandedId), //this.getInterestingIds(),
                expandMode: ExpandMode.Custom,
                reloadCount: this.reloadCount
            });
        }
        //clearLogFromURI(this.props);
    }

    getParents(id: string) {
        const seperator = "/";
        const firstSection = id.indexOf(seperator);
        const ids = [id];
        if (firstSection === -1) { // No first section... weird..
            return ids;
        }

        let parentSection = id.lastIndexOf(seperator);
        while (parentSection !== -1) {
            const parentId = id.substring(0, parentSection);
            parentSection = parentId.lastIndexOf(seperator);
            ids.push(parentId);
        }
        return ids;
    }

    setExpanded = (id: string, expanded: boolean) => {
        //clearLogFromURI(this.props);
        this.setState(prevState => {
                const expandedIds = expanded
                    ? concat(prevState.expandedIds, id)
                    : without(prevState.expandedIds, id);
                return {expandedIds, expandMode: ExpandMode.Custom, focusId: null};
            }
        );
    }

    getElementIdsMatching(predicate: ((element: UniqueActivityElement) => boolean)) {
        return flatten(this.props.activityElements.map(e => this.getElementsMatchingRecursive(e, predicate)))
            .map(e => e.uniqueId);
    }

    getElementsMatchingRecursive(element: UniqueActivityElement, predicate: ((element: UniqueActivityElement) => boolean)): UniqueActivityElement[] {
        const ids = flatten(element.Children.map(l => this.getElementsMatchingRecursive(l as UniqueActivityElement, predicate)));
        if (ids.length > 0 || predicate(element)) {
            ids.push(element);
        }
        return ids;
    }

    expand(expandMode: ExpandMode) {
        this.setState({expandedIds: this.getIdsToExpand(expandMode), expandMode});
    }

    getIdsToExpand(mode: ExpandMode) {
        switch (mode) {
            case ExpandMode.All:
                return this.getElementIdsMatching(e => true);
            case ExpandMode.Interesting:
                return this.getInterestingIds();
            case ExpandMode.Errors:
                return this.getElementIdsMatching(e =>
                    e.LogElements.filter(l =>
                        l.Category === ActivityLogEntryCategory.Error ||
                        l.Category === ActivityLogEntryCategory.Fatal
                    ).length > 0
                );
            case ExpandMode.None:
                return [];
            default:
                return this.state.expandedIds;
        }
    }

    getInterestingIds() {
        return this.getElementIdsMatching(e =>
            e.ShowAtSummaryLevel ||
            e.Status === ActivityStatus.Pending ||
            e.Status === ActivityStatus.Running ||
            e.Status === ActivityStatus.SuccessWithWarning ||
            e.Status === ActivityStatus.Canceled ||
            e.LogElements.filter(l =>
                l.Category === ActivityLogEntryCategory.Error ||
                l.Category === ActivityLogEntryCategory.Fatal
            ).length > 0
        );
    }

    renderActivityLogs = (element: UniqueActivityElement) => {
        const focusId = this.state.reloadCount === this.reloadCount ? this.props.initialExpandedId : null;
        return <TaskLogBlock
            key={element.uniqueId}
            element={element}
            taskState={this.props.details.Task.State}
            collapsible={true}
            expandedIds={this.state.expandedIds}
            focusId={focusId}
            showRunTime={true}
            setExpanded={(id, expanded) => this.setExpanded(id, expanded)}
            showAdditional={this.props.showAdditional}
        />;
    }

    componentDidUpdate() {
        // Clear focus so that it can be re-focused if same log clicked again
        //if (this.state.focusId) {
        //    this.setState({focusId: null});
        //}
    }

    render() {
        const details = this.props.details;
        const hasWritten = details.ActivityLogs.filter(l => l.LogElements.length > 0 || l.Children.length > 0).length > 0;

        return <div>
            <Section bodyClassName={styles.filterActionContainer}>
                <div className={styles.filters}>
                    <div className={styles.filter}>
                        <Select value={this.state.expandMode}
                                onChange={mode => this.expand(mode as ExpandMode)}
                                items={this.expandActions}
                                label="Expand"/>
                    </div>
                    <div className={styles.filter}>
                        <Select value={this.props.verbose.toString()}
                                onChange={verbose => this.props.setVerbose(verbose === "true")}
                                items={[{text: "Info", value: "false"}, {text: "Verbose", value: "true"}]}
                                label="Log level"/>
                    </div>
                    <div className={styles.filter}>
                        <Select value={this.props.tail.toString()}
                                onChange={tail => this.props.setTail(tail === "true")}
                                items={[{text: "Last 20", value: "true"}, {text: "All", value: "false"}]}
                                label="Log tail"/>
                    </div>
                </div>
                <div className={styles.taskActions}>
                    <NavigationButton href={routeLinks.task(details.Task).raw} label="Raw"/>
                    <NavigationButton href={details.Links["Raw"]} label="Download" external={true}/>
                </div>
            </Section>
            <div style={{marginLeft: "1rem"}}>
            <TaskProgress details={details}/>
            </div>
            <Section>
                {hasWritten ?
                    <div>{this.props.activityElements.map(this.renderActivityLogs)}</div> :
                    <Callout type={CalloutType.Information} title="No Logs">
                        The task has not written any information to the log yet.
                    </Callout>}
            </Section>
        </div>;
    }
}

export default withRouter<TaskLogProps>(TaskLog);