exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .NoResults_hide-sm__3MRZv {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .NoResults_visible-sm__1_oB_ {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .NoResults_hide-md__1vaUY {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .NoResults_visible-md__356KH {\n    display: none !important;\n  }\n}\n.NoResults_container__2kup9 {\n  padding: 0.5rem 1rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n}\n.NoResults_onboardingImage__2B3nK {\n  margin: 1.5rem 0;\n  height: 7rem;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "NoResults_hide-sm__3MRZv",
	"hideSm": "NoResults_hide-sm__3MRZv",
	"visible-sm": "NoResults_visible-sm__1_oB_",
	"visibleSm": "NoResults_visible-sm__1_oB_",
	"hide-md": "NoResults_hide-md__1vaUY",
	"hideMd": "NoResults_hide-md__1vaUY",
	"visible-md": "NoResults_visible-md__356KH",
	"visibleMd": "NoResults_visible-md__356KH",
	"container": "NoResults_container__2kup9",
	"onboardingImage": "NoResults_onboardingImage__2B3nK"
};