exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_taskDetailsContainer__LrSRu {\n  display: flex;\n  flex-direction: row;\n  min-width: 180px;\n  padding: 0.5rem;\n  margin: 0.25rem 0;\n  justify-content: left;\n}\n.style_taskDetailsContainer__LrSRu .style_details__1fsJb {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding-left: 0.5rem;\n}\n.style_taskDetailsContainer__LrSRu .style_description__26x0p {\n  color: #333333;\n  font-weight: 500;\n  font-size: 0.875rem;\n  text-align: left;\n}\n.style_taskDetailsContainer__LrSRu .style_date__3EL-h {\n  color: #666666;\n  font-weight: 400;\n  font-size: 0.6875rem;\n  white-space: nowrap;\n}\n.style_stripTopBottomPadding__XDGZz {\n  padding: 0 0.5rem;\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"taskDetailsContainer": "style_taskDetailsContainer__LrSRu",
	"details": "style_details__1fsJb",
	"description": "style_description__26x0p",
	"date": "style_date__3EL-h",
	"stripTopBottomPadding": "style_stripTopBottomPadding__XDGZz"
};