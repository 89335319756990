import * as React from "react";
import { DragDropContext } from "react-dnd";
import SortableItem from "./SortableItem";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";

interface SortableItemModel {
    Id: string;
    Name: string;
}

interface SortableListProps<TItemType> {
    items: TItemType[];
    onOrderChanged(sortedItems: TItemType[]): void;
}

class SortableList<TItemType extends SortableItemModel> extends React.Component<SortableListProps<TItemType>> {
    render() {
        return <div>
            {this.props.items.map((item, index) => (
                <SortableItem
                    key={item.Id}
                    index={index}
                    id={item.Id}
                    name={item.Name}
                    onItemMoved={this.itemMoved}
                />
            ))}
        </div>;
    }

    private itemMoved = (dragIndex: number, hoverIndex: number) => {
        const orderedItems = this.props.items.slice();
        const dragItem = orderedItems[dragIndex];
        orderedItems.splice(dragIndex, 1);
        orderedItems.splice(hoverIndex, 0, dragItem);

        this.props.onOrderChanged(orderedItems);
    }
}

export default DragDropContext(MultiBackend(HTML5toTouch))(SortableList);
export {SortableItemModel};