exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_firstColumn__243iy {\n  width: 50%;\n  margin-right: 1rem;\n}\n.style_firstColumnFullWidth__3pnRz {\n  width: 100%;\n}\n.style_secondColumn__4E86I {\n  width: 50%;\n  margin-left: 1rem;\n}\n.style_scopeContainer__NG0pW {\n  display: flex;\n  flex-direction: row;\n}\n", ""]);

// exports
exports.locals = {
	"firstColumn": "style_firstColumn__243iy",
	"firstColumnFullWidth": "style_firstColumnFullWidth__3pnRz",
	"secondColumn": "style_secondColumn__4E86I",
	"scopeContainer": "style_scopeContainer__NG0pW"
};