import * as React from "react";
const NumericInput = require("react-numeric-input");
import FormFieldProps from "../FormFieldProps";
import {baseSizeInPx} from "fontWeights";
const styles = require("./style.less");

enum Meridiem {
    AM,
    PM
}

interface TimePickerStats {
    meridiem: Meridiem;
    hours: number;
    minutes: number;
}

export default class TimePicker extends React.Component<FormFieldProps<Date>, TimePickerStats> {
    constructor(props: FormFieldProps<Date>) {
        super(props);

        this.state = this.initState(this.props.value);
    }

    componentWillReceiveProps(nextProps: FormFieldProps<Date>) {
        if (nextProps.value !== this.props.value) {
            this.setState(this.initState(nextProps.value));
        }
    }

    render() {
        const imputStyle = {
            input: {
                border: "0",
                fontSize: baseSizeInPx,
                height: "100%"
            }
        };

        return (
            <div className={styles.container}>
                <NumericInput
                    onChange={this.updateHours}
                    value={this.state.hours}
                    min={1}
                    max={12}
                    step={1}
                    size={1}
                    mobile={true}
                    style={imputStyle}

                />
                <span className={styles.separator}>:</span>
                <NumericInput
                    onChange={this.updateMinutes}
                    value={this.state.minutes}
                    min={0}
                    max={45}
                    step={15}
                    size={1}
                    snap={true}
                    style={imputStyle}
                    mobile={true}
                    format={this.padding}
                    readOnly
                />
                <a className={styles.ampm} href="#" onClick={this.updateMeridiem}>
                    {this.state.meridiem === Meridiem.AM ? "AM" : "PM"}
                </a>
            </div>
        );
    }

    private initState(value?: Date) {
        const time = value || new Date();

        return {
            meridiem: this.getMeridiem(time),
            hours: this.getHours(time),
            minutes: this.getMinutes(time)
        };
    }

    private getMeridiem = (time: Date) => {
        const hour = time.getHours();
        return hour < 12 ? Meridiem.AM : Meridiem.PM;
    }

    private getMinutes = (time: Date) => {
        let n = time.getMinutes();

        if (n === 0) {
            return n;
        }

        n = Math.round(n / 15) * 15;

        if (n > 45) {
            n = 45;
        }

        return n;
    }

    private getHours = (time: Date) => {
        const hour = time.getHours();
        if (hour > 12) {
            return hour - 12;
        }
        if (hour === 0) {
            return 12;
        }

        return hour;
    }

    private raiseChange() {
        const date = new Date();
        let hours = this.state.hours;

        if (this.state.meridiem === Meridiem.PM) {
            if (hours === 12) {
                hours = 12;
            } else {
                hours += 12;
            }
        } else {
            if (hours === 12) {
                hours = 0;
            }
        }

        date.setHours(hours, this.state.minutes, 0, 0);
        this.props.onChange(date);
    }

    private updateMeridiem = (e: any) => {
        e.preventDefault();
        this.setState({
            meridiem: this.state.meridiem === Meridiem.AM ? Meridiem.PM : Meridiem.AM
        }, () => this.raiseChange());
    }

    private padding = (value: string) => {
        if (value === "0") {
            return "00";
        }

        return value.toString();
    }

    private updateHours = (value: number) => {
        this.setState({
            hours: value
        }, () => this.raiseChange());
    }

    private updateMinutes = (value: number) => {
        this.setState({
            minutes: value
        }, () => this.raiseChange());
    }
}