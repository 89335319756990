exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__33tvz {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n}\n.style_container__33tvz > * {\n  margin: 1.5rem 0 0 0;\n}\n.style_content__1LOJa {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background: #ffffff;\n  width: 23.5rem;\n  text-align: center;\n  border-radius: 0.25rem;\n  overflow: hidden;\n  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.12), 0 0 0.125rem 0 rgba(0, 0, 0, 0.14);\n}\n.style_description__27bFu {\n  padding: 0 1.5rem 1.5rem 1.5rem;\n  width: 100%;\n}\n.style_back__16GgO {\n  padding-top: 1.5rem;\n}\n.style_logo__1S36P {\n  background-color: #2f93e0;\n  color: #ffffff;\n  font-size: 2rem;\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n  padding: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__33tvz",
	"content": "style_content__1LOJa",
	"description": "style_description__27bFu",
	"back": "style_back__16GgO",
	"logo": "style_logo__1S36P"
};