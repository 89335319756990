exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".releaseFilter_container__2JHX3 {\n  min-width: 16rem;\n  margin-right: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "releaseFilter_container__2JHX3"
};