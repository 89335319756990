import Client from "../client";
import BasicRepository from "../repositories/basicRepository";
import {AuthenticationResource} from "./authenticationResource";
import {LoginInitiatedResource} from "./loginInitiatedResource";

class AuthenticationRepository extends BasicRepository<AuthenticationResource, AuthenticationResource> {
    constructor(client: Client) {
        super("Authentication", client);
    }
    get(): Promise<AuthenticationResource> {
        return this.client.get<AuthenticationResource>(this.client.getLink("Authentication"));
    }

    wasLoginInitiated(encodedQueryString: string): Promise<LoginInitiatedResource> {
        return this.client.post<LoginInitiatedResource>(this.client.getLink("LoginInitiated"), { EncodedQueryString: encodedQueryString });
    }
}

export default AuthenticationRepository;