exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_markdown__14Azh {\n  line-height: normal;\n}\n.style_markdown__14Azh ul {\n  list-style-type: disc;\n  padding-left: 2.5rem;\n}\n.style_markdown__14Azh ol {\n  list-style-type: decimal;\n  padding-left: 2.5rem;\n}\n.style_markdown__14Azh blockquote {\n  border-left: 0.25rem solid #cccccc;\n  padding-left: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"markdown": "style_markdown__14Azh"
};