exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_taskSummaryTopLevel__emQPF {\n  padding-top: 1rem;\n  padding-bottom: 1rem;\n}\n.style_taskSummaryChildren__1D2n0 {\n  margin-left: 1rem;\n}\n.style_taskSummaryLine__39ZbG {\n  display: flex;\n  margin: 0.5rem 0;\n  color: #333333;\n}\n.style_taskSummaryLineText__3av25 {\n  cursor: pointer;\n}\n.style_summaryIcon__3Hnna {\n  width: 1rem;\n  height: 1rem;\n  font-size: 1rem;\n  text-align: center;\n}\n.style_artifactsBlock__3jV6s {\n  margin-left: 1.25rem;\n}\n.style_highlightBlock__OIdZw {\n  color: #0878D1;\n  margin-left: 1.25rem;\n}\n.style_fatalBlock__3ikOs {\n  color: #db4437;\n  margin-left: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"taskSummaryTopLevel": "style_taskSummaryTopLevel__emQPF",
	"taskSummaryChildren": "style_taskSummaryChildren__1D2n0",
	"taskSummaryLine": "style_taskSummaryLine__39ZbG",
	"taskSummaryLineText": "style_taskSummaryLineText__3av25",
	"summaryIcon": "style_summaryIcon__3Hnna",
	"artifactsBlock": "style_artifactsBlock__3jV6s",
	"highlightBlock": "style_highlightBlock__OIdZw",
	"fatalBlock": "style_fatalBlock__3ikOs"
};