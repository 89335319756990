import * as React from "react";
import {repository} from "clientInstance";
import SortableList from "components/SortableList/SortableList";
import {EnvironmentResource} from "client/resources";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";

interface EnvironmentSorterFormProps {
    saveDone(): void;
}

interface EnvironmentSorterFormState extends DataBaseComponentState {
    environments: EnvironmentResource[];
}

class EnvironmentsSorter extends DataBaseComponent<EnvironmentSorterFormProps, EnvironmentSorterFormState> {
    constructor(props: EnvironmentSorterFormProps) {
        super(props);

        this.state = {
            environments: null
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const environments = await repository.Environments.all();
            this.setState({environments});
        });
    }

    async save() {
        const environmentIdsSorted = this.state.environments.map(environment => {
            return environment.Id;
        });
        return this.doBusyTask(async () => {
            await repository.Environments.sort(environmentIdsSorted);
            this.props.saveDone();
        });
    }

    render() {
        return <SaveDialogLayout title="Reorder Environments"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            {this.state.environments &&
            <SortableList items={this.state.environments}
                onOrderChanged={(x: any) => this.setState({environments: x})}/>}
        </SaveDialogLayout>;
    }
}

export default EnvironmentsSorter;