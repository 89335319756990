import * as React from "react";
import {CertificateResource} from "client/resources/certificateResource";
import CertificateSearch from "components/form/CertificateSearch/CertificateSearch";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";

interface CertificateSelectorProps {
    selectedCertificateId: string;
    tenantId?: string;

    onSelected(certificate: CertificateResource): void;
}

interface CertificateSelectorState extends DataBaseComponentState {
    certificate: CertificateResource;
}

export default class CertificateSelector extends DataBaseComponent<CertificateSelectorProps, CertificateSelectorState> {

    constructor(props: CertificateSelectorProps) {
        super(props);
        this.state = {
            certificate: null
        };
    }

    onOk() {
        this.props.onSelected(this.state.certificate);
        return true;
    }

    render() {
        return <OkDialogLayout title="Select Certificate"
                               busy={this.state.busy}
                               errors={this.state.errors}
                               onOkClick={() => this.onOk()}>
            <CertificateSearch
                selectedCertificateId={this.props.selectedCertificateId}
                tenantId={this.props.tenantId}
                onSelected={(certificate) => this.setState({certificate})}
                doBusyTask={this.doBusyTask} />
        </OkDialogLayout>;
    }
}