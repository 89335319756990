import { AccountType, WorkerPoolResource } from "../../../../../client/resources/index";
import * as React from "react";
import {
    AzureWebAppEndpointResource,
    AccountResource,
} from "client/resources";
import {
    ExpandableFormSection,
    Summary,
} from "components/form";
import Select from "components/form/Select/Select";
import AccountSelect from "components/form/AccountSelect/AccountSelect";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import AzureWebAppAndResourceGroupSelector from "./AzureWebAppAndResourceGroupSelector";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import AzureWebAppSlotSelector from "./AzureWebAppSlotSelector";
import AzureCategory from "./AzureCategoryDefinition";
import { CategorizedEndpointRegistration, CommunicationStyle } from "./endpointRegistry";
import { EndpointThumbnail } from "./EndpointThumbnail";
import EndpointCard from "./EndpointCard";

interface AzureWebAppEndpointProps {
    doBusyTask: DoBusyTask;
    busy: Promise<any> | boolean;
    endpoint: AzureWebAppEndpointResource;
    accounts: AccountResource[];
    workerPools: WorkerPoolResource[];
    refreshAccounts: () => Promise<any>;
    onChange(newValue: AzureWebAppEndpointResource): void;
    getFieldError(field: string): string;
}

// tslint:disable-next-line:no-empty-interface
interface AzureWebAppEndpointState {
}

class AzureWebAppEndpoint extends React.Component<AzureWebAppEndpointProps, AzureWebAppEndpointState> {

    constructor(props: AzureWebAppEndpointProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        return <div>
            <ExpandableFormSection
                errorKey="Account"
                title="Account"
                focusOnExpandAll
                summary={CommonSummaryHelper.resourceSummary(this.props.endpoint.AccountId, this.props.accounts, "account")}
                help="Select the account to use for the connection.">
                <AccountSelect
                    onRequestRefresh={this.props.refreshAccounts}
                    value={this.props.endpoint.AccountId}
                    type={[AccountType.AzureServicePrincipal]}
                    allowClear={true}
                    onChange={x => {
                        const endpoint = this.props.endpoint;
                        endpoint.AccountId = x;
                        this.props.onChange(endpoint);
                    }}
                    items={this.props.accounts}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey={"WebAppName"}
                title="Azure Web App"
                summary={this.props.endpoint.WebAppName ? Summary.summary(this.props.endpoint.WebAppName) : Summary.placeholder("No Web App selected")}
                help="Select the Azure Web App.">
                <AzureWebAppAndResourceGroupSelector
                    accountId={this.props.endpoint.AccountId}
                    doBusyTask={this.props.doBusyTask}
                    webAppName={this.props.endpoint.WebAppName}
                    webAppNameError={null}
                    onWebAppNameChanged={x => {
                        const endpoint = this.props.endpoint;
                        endpoint.WebAppName = x;
                        this.props.onChange(endpoint);
                    }}
                    onResourceGroupChanged={x => {
                        const endpoint = this.props.endpoint;
                        endpoint.ResourceGroupName = x;
                        this.props.onChange(endpoint);
                    }}
                />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey={"WebAppSlotName"}
                title={"Azure Web App Slot"}
                summary={this.props.endpoint.WebAppSlotName ? Summary.summary(this.props.endpoint.WebAppSlotName) : Summary.placeholder("No Web App Slot selected or none available")}
                help={"Optionally, select the Azure Web App Slot."}>
                <AzureWebAppSlotSelector
                    accountId={this.props.endpoint.AccountId}
                    webAppName={this.props.endpoint.WebAppName}
                    resourceGroupName={this.props.endpoint.ResourceGroupName}
                    webAppSlotName={this.props.endpoint.WebAppSlotName}
                    doBusyTask={this.props.doBusyTask}
                    webAppSlotNameError={null}
                    onWebAppSlotNameChanged={x => {
                        const endpoint = this.props.endpoint;
                        endpoint.WebAppSlotName = x;
                        this.props.onChange(endpoint);
                    }}
                />
            </ExpandableFormSection>

            {this.props.workerPools.length > 1 &&
                <ExpandableFormSection
                    errorKey={"DefaultWorkerPool"}
                    title="Worker Pool"
                    summary={this.props.endpoint.DefaultWorkerPoolId ? CommonSummaryHelper.resourceSummary(this.props.endpoint.DefaultWorkerPoolId, this.props.workerPools, "worker pool") : Summary.placeholder("No pool selected - default pool")}
                    help="Select a default pool for this target (optional).">
                    <Select
                        label={"Select a default pool"}
                        items={this.props.workerPools.map((e) => ({ value: e.Id, text: e.Name }))}
                        value={this.props.endpoint.DefaultWorkerPoolId}
                        allowFilter={true}
                        allowClear={true}
                        onChange={x => this.props.onChange({ ...this.props.endpoint, DefaultWorkerPoolId: x })}
                    />
                </ExpandableFormSection>}
        </div>;
    }
}

export default AzureWebAppEndpoint;

const azureWebAppEndpointImage = require("./azure-web-app.svg");
const azureWebAppEndpointRegistration: CategorizedEndpointRegistration = {
    displayOrder: 10,
    categories: [ AzureCategory ],
    name: "Azure Web App",
    type: CommunicationStyle.AzureWebApp,
    renderCard: ({registration, category, getNavigationProps}) => (
        <EndpointCard
            logo={<EndpointThumbnail src={azureWebAppEndpointImage} alt={azureWebAppEndpointImage} />}
            header={registration.name}
            description="Connect to an existing Azure Web App."
            {...getNavigationProps()}
        />
    )
};

export { azureWebAppEndpointRegistration };