exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_icon__27jsK {\n  width: 2rem;\n  height: 2rem;\n  padding-right: 0.5rem;\n}\n.style_link__25x0T {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.style_tooltipWrapper__1HuBF {\n  height: 100%;\n  width: 100%;\n  display: inline-block;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.style_text__3VM0o {\n  min-width: 0;\n  flex-basis: 0;\n}\n", ""]);

// exports
exports.locals = {
	"icon": "style_icon__27jsK",
	"link": "style_link__25x0T",
	"tooltipWrapper": "style_tooltipWrapper__1HuBF",
	"text": "style_text__3VM0o"
};