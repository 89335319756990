import * as React from "react";
import {CardMedia} from "material-ui/Card";
const styles = require("./style.less");
import {formSectionBodyClass, formSectionTitleClass} from "uiTestClasses";
import * as cn from "classnames";

export interface FormSectionBaseProps {
    error?: string;
    forceMobileBehaviour?: boolean;
}

export interface FormSectionBaseState {
    moreInformationExpanded: boolean;
    moreInformation?: React.ReactNode;
}

export abstract class FormSectionBase<TProps extends FormSectionBaseProps> extends React.Component<TProps, FormSectionBaseState> {
    protected alterChild: (child: any) => void;

    constructor(props: TProps) {
        super(props);
        this.state = {
            moreInformationExpanded: false,
            moreInformation: null
        };
    }

    toggleShowMoreInfo = (e: any) => {
        e.preventDefault();
        e.stopPropagation(); //prevent clicking the link toggling the panel
        this.setState(state => ({moreInformationExpanded: !state.moreInformationExpanded}));
    }

    handleMoreInfoClick = (content: React.ReactNode) => {
        this.setState({
            moreInformationExpanded: true,
            moreInformation: content
        });
    }

    hideMoreInfo = (e: any) => {
        e.preventDefault();
        this.setState({
            moreInformationExpanded: false
        });
    }

    isMoreInfo = (child: any) => {
        return child.props && child.props.hasOwnProperty("isMoreInfo");
    }

    renderChild = (child: any) => {
        if (!child) {
            return child;
        }
        const children: any = child.props && child.props.children
            ? {children: this.renderChildren(child.props.children)}
            : null;

        if (this.isMoreInfo(child)) {
            return React.cloneElement(child, {
                ...child.props,
                onClick: this.handleMoreInfoClick
            });
        }

        if (this.alterChild) {
            child = this.alterChild(child);
        }

        const props = children ? {...child.props, ...children} : child.props;
        return React.isValidElement(child) ? React.cloneElement(child, props) : child;
    }

    renderChildren = (children: any) => {
        return React.Children.map(children, this.renderChild);
    }

    abstract render(): any;

    protected title(title: React.ReactNode, subtitle: React.ReactNode) {
        return <div className={styles.cardTitleContainer}>
            <span className={`${formSectionTitleClass} ${styles.cardTitle}`}>{title}</span>
            {subtitle}
        </div>;
    }

    protected help(help: React.ReactNode) {
        return <span className={cn(styles.cardSubTitle, this.props.forceMobileBehaviour && styles.cardSubTitleForceMobile)}>{this.renderChild(help || <div/>)}</span>;
    }

    protected body(className: string, expandable: boolean) {
        return <CardMedia
            className={`${formSectionBodyClass} ${className}`}
            expandable={expandable}>
            {/*this div prevents material-ui from logging a warning https://github.com/callemall/material-ui/issues/4239#issuecomment-268054415*/}
            <div>
                {this.renderChildren(this.props.children)}
            </div>
        </CardMedia>;
    }

    protected moreInformation() {
        return this.state.moreInformationExpanded
            ? <div className={styles.moreInformation}>
                <div className={styles.hide}><a href="#" onClick={this.hideMoreInfo}>hide</a></div>
                {this.state.moreInformation}
            </div>
            : <span/>;
    }
}
