import * as React from "react";
import * as showdown from "showdown";
import * as xss from "xss";
import { renderToStaticMarkup } from "react-dom/server";
import { MemoryRouter } from "react-router-dom";
import { cloneDeep } from "lodash";
import TextWithLinks from "components/TextWithLinks/TextWithLinks";
const styles = require("./style.less");

interface MarkdownProps {
    markup: string;
}

const Markdown: React.StatelessComponent<MarkdownProps> = props => {
    showdown.extension("LinkRenderer", () => {
        return [{
            type: "lang",
            filter: (text, converter, options) => {
                const rendered = renderToStaticMarkup(<MemoryRouter><TextWithLinks message={text} allowHtml={true} /></MemoryRouter>);
                //this is less than ideal, but as we've had to render without the normal router available, we cant resolve links as well as we'd like.
                return rendered.replace("href=\"/", "href=\"#/");
            }
          }];
      });

    const markdownConverter = new showdown.Converter({
        tables: true,
        strikethrough: true,
        openLinksInNewWindow: true,
        disableForced4SpacesIndentedSublists: true,
        literalMidWordUnderscores: true
    });
    markdownConverter.useExtension("LinkRenderer");

    const potentiallyUnsafeHtml = markdownConverter.makeHtml(props.markup);

    const xssFilterWhitelist = cloneDeep(xss.whiteList);
    xssFilterWhitelist["em"] = ["style", "class"];

    const html = xss(potentiallyUnsafeHtml, { whiteList: xssFilterWhitelist });
    return <div className={styles.markdown} dangerouslySetInnerHTML={{__html: html}} />;
};

export default Markdown;