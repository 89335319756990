import * as React from "react";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {AzureWebSite} from "client/resources";
import Note from "components/form/Note/Note";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import { Select } from "components/form";
import BusyRefreshContainer from "components/BusyRefreshContainer";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import {Icon, default as IconButton} from "components/IconButton/IconButton";
import InputWithActions from "components/InputWithActions";

interface AzureWebAppAndResourceGroupSelectorProps {
    webAppName: string;
    accountId: string;
    webAppNameError: string;
    doBusyTask: DoBusyTask;
    onResourceGroupChanged(value: string): void;
    onWebAppNameChanged(value: string): void;
}

interface AzureWebAppAndResourceGroupSelectorState {
    reset: {
        name: string;
    };
    sites: AzureWebSite[];
    siteItems: Array<{ value: string, text: string }>;
    selectedSiteIndex: string;
    busy: boolean; //TODO: move busy back out into props and use a HOC/Render prop component to manage this state
}

const toggleBusy = (value?: boolean) => (prev: AzureWebAppAndResourceGroupSelectorState, props: AzureWebAppAndResourceGroupSelectorProps) => ({...prev, busy: value ? value : !prev.busy});

class AzureWebAppAndResourceGroupSelector extends BaseComponent<AzureWebAppAndResourceGroupSelectorProps, AzureWebAppAndResourceGroupSelectorState> {
    constructor(props: AzureWebAppAndResourceGroupSelectorProps) {
        super(props);
        this.state = {
            reset: {
                name: null
            },
            sites: [],
            siteItems: [],
            selectedSiteIndex: null,
            busy: false
        };
    }

    componentDidMount() {
        this.getWebSites(this.props.accountId);
    }

    componentWillReceiveProps(newprops: AzureWebAppAndResourceGroupSelectorProps) {
        if (newprops.accountId !== this.props.accountId) {
            this.getWebSites(newprops.accountId);
        }
    }

    render() {
        return <div>
            <BusyRefreshContainer busy={this.state.busy}>
                <InputWithActions input={ <Select
                    value={this.state.selectedSiteIndex}
                    onChange={this.handleSelectedSiteChanged}
                    items={this.state.siteItems}
                    label="Web app"
                />}
                actions={<IconButton disabled={this.state.busy} onClick={() => this.getWebSites(this.props.accountId)} toolTipContent="Refresh" icon={Icon.Refresh}/>}
                />
            </BusyRefreshContainer>
            <Note>The name of your Azure Web App.</Note>
        </div>;
    }

    private handleSelectedSiteChanged = (value: string) => {
        const index = ParseHelper.safeParseInt(value, null);
        const selectedSite = index !== null ? this.state.sites[index] : null;
        this.props.onResourceGroupChanged(selectedSite ? selectedSite.ResourceGroup : null);
        this.props.onWebAppNameChanged(selectedSite ? selectedSite.Name : null);
        this.setState({selectedSiteIndex: index !== null ? index.toString() : null});
    }

    private resetWebSites() {
        this.setState({sites: []});
    }

    private async getWebSites(accountId: string) {
        if (!accountId) {
            return;
        }

        this.setState(toggleBusy(true));

        try {
            await this.props.doBusyTask(async () => {
                if (!accountId) {
                    this.setState({
                        sites: [],
                        siteItems: [],
                        selectedSiteIndex: null
                    });
                    return;
                }
                const account = await repository.Accounts.get(accountId);
                const sites = await repository.Accounts.getWebSites(account);

                let selectedSiteIndex: string = null;
                if (this.props.webAppName) {
                    const selectedSite = sites.find(s => s.Name === this.props.webAppName);
                    if (selectedSite) {
                        const index = sites.indexOf(selectedSite);
                        selectedSiteIndex = index < 0 ? null : index.toString();
                    }
                }

                const siteItems = sites.map((site, index) => {
                    const details = site.ResourceGroup ? site.ResourceGroup + " " + site.Region : site.Region;
                    return {
                        value: index.toString(),
                        text: site.Name + " " + details
                    };
                });

                this.setState({
                    sites,
                    siteItems,
                    selectedSiteIndex
                });
            });
        } finally {
            this.setState(toggleBusy(false));
        }
    }
}

export default AzureWebAppAndResourceGroupSelector;