import Client from "../client";
import {LetsEncryptConfigurationResource} from "client/resources";
import ConfigurationRepository from "client/repositories/configurationRepository";

class LetsEncryptConfigurationRepository extends ConfigurationRepository<LetsEncryptConfigurationResource> {
    constructor(client: Client) {
        super("LetsEncryptConfiguration", client);
    }
}

export default LetsEncryptConfigurationRepository;