// We use our own AJAX function so that we"re not too coupled to jQuery
import Logger from "./logger";
import { session, client, repository } from "clientInstance";
import { Client } from "client";
import { OctopusError } from "client/resources";
import {serverVersionNotificationCheck} from "./http/serverVersionNotificationCheck";

interface AjaxOptions {
    url: string;
    client: Client;
    method?: string;
    success: (data: any) => void;
    error: (error: OctopusError) => void;
    raw?: boolean;
    requestBody?: any;
    nonStale?: boolean;
}

export default class Ajax {
    options: AjaxOptions;

    constructor(options: AjaxOptions = {
        method: "GET",
        nonStale: true,
        raw: false,
        url: null,
        client: null,
        error: e => undefined,
        requestBody: null,
        success: data => undefined
    }) {
        this.options = options;
    }

    execute() {
        const request = createXMLHTTPObject();
        if (!request) {
            return;
        }

        request.open(getMethod(this.options.method).method, this.options.url, true);
        request.withCredentials = true;

        this.setHeaders(request);

        const cachedResult = session.cache.setHeaderAndGetValue(request, this.options);

        request.onreadystatechange = () => {
            if (request.readyState !== XMLHttpRequest.DONE) {
                return;
            } else if (request.status < 100 || request.status >= 400) {
                this.handleError(request);
            } else {
                this.handleSuccess(request, cachedResult);
            }
        };

        if (request.readyState === XMLHttpRequest.DONE) {
            return;
        }

        if (!this.checkAuthentication()) {
            Logger.log("Canceled request", request);
            return;
        }
        this.sendRequest(request);
    }

    private checkAuthentication = () => {
        // If we don't have an anti-forgery token we need to get one by logging in again
        if (session.isAuthenticated() && !client.getAntiforgeryToken() && !isAnonymous(this.options.url)) {
            Logger.warn(`The expected anti-forgery cookie is missing or has expired. Redirecting to login page, but will return to ${window.location.href}. ` +
                "See http://g.octopushq.com/CSRF for more details and troubleshooting.");

            this.reAuthenticateAndReturn();

            return false;
        } else {
            return true;
        }
    }

    private reAuthenticateAndReturn = () => {
        repository.Users.signOut().then(() => {
            session.end();
            window.location.reload();
        });
    }

    private sendRequest(request: XMLHttpRequest) {
        const body = this.options.requestBody;
        if (typeof (body) === "undefined") {
            request.send();
        } else if (typeof (body) === "string" || isFormData(body)) {
            request.send(body);
        } else {
            const bodyJson = JSON.stringify(body);
            request.send(bodyJson);
        }
    }

    private setHeaders(request: XMLHttpRequest) {
        const {method, methodOverride} = getMethod(this.options.method);

        const serverInfo = this.options.client.tryGetServerInformation();
        const version = serverInfo ? serverInfo.version : undefined;
        request.setRequestHeader("X-Octopus-User-Agent", "OctopusClient-js/" + version);

        request.setRequestHeader("Accept", "application/json");

        if (this.options.requestBody && !isFormData(this.options.requestBody)) {
            request.setRequestHeader("Content-type", "application/json");
        }

        if (methodOverride) {
            request.setRequestHeader("X-HTTP-Method-Override", methodOverride);
        }

        // Set X-Octopus-Csrf-Token header if there is an antiforgery cookie available and it"s a mutating request
        if (method === "POST" || method === "PUT" || method === "DELETE") {
            const antiforgeryToken = this.options.client.getAntiforgeryToken();
            if (antiforgeryToken) {
                request.setRequestHeader("X-Octopus-Csrf-Token", antiforgeryToken);
            }
        }
    }

    private handleSuccess = (request: XMLHttpRequest, cachedResult: any) => {
        serverVersionNotificationCheck(request);

        let responseBody = cachedResult;
        if (!responseBody || !session.cache.canUseCachedValue(request)) {
            responseBody = request.responseText;
            session.cache.updateCache(request, this.options);
        }

        this.options.success(deserialize(responseBody, this.options.raw));
    }

    private handleError = (request: XMLHttpRequest) => {
        const err = generateOctopusError(request);
        if (err.StatusCode === 401 && !isAnonymous(this.options.url) && !isAuthenticationCheck(this.options.url)) {
            Logger.warn(`Session Auth Cookie has expired or is missing. Redirecting to login page, but will return to ${window.location.href}.`);
            this.reAuthenticateAndReturn();
        }

        this.options.error(err);
    }
}

const deserialize = (responseText: string, raw: boolean, forceJson = false) => {
    if (raw && !forceJson) {
        return responseText;
    }

    if (responseText && responseText.length) {
        return JSON.parse(responseText);
    }

    return null;
};

const getMethod = (method: string): {method: string, methodOverride: string} => {
    let methodOverride = null;
    if (method === "PUT") {
        methodOverride = "PUT";
        method = "POST";
    }
    if (method === "DELETE") {
        methodOverride = "DELETE";
        method = "POST";
    }
    return {method, methodOverride};
};

const isAnonymous = (url: string): boolean => {
    return url.includes("/users/register/") || url.endsWith("/users/login") || url.endsWith("/users/logout") || url.endsWith("/api");
};

const isAuthenticationCheck = (url: string): boolean => {
    return url.endsWith("/users/me");
};

const isFormData = (item: any): boolean => {
    return item instanceof FormData;
};

const generateOctopusError = (request: XMLHttpRequest) => {
    let error = new OctopusError(request.status);

    try {
        if (request.status === XMLHttpRequest.UNSENT) {
            error.ErrorMessage = "There was a problem with your request.";
            error.Errors = ["Unable to establish a connection to the Octopus Deploy server. " +
            "The server may be offline. Please check your network connection."];
        } else {
            error = OctopusError.create(request.status, deserialize(request.responseText, false, true));

            if (!error.ErrorMessage) {
                error.ErrorMessage = "There was a problem with your request.";
                error.Errors = ["The Octopus Deploy server returned a status of: " + request.status];
                error.FullException = request.response;
            }
        }
    } catch (err) {
        error.ErrorMessage = "There was a problem with your request.";
        error.Errors = ["Unhandled error when communicating with the Octopus Deploy server. " +
        "The server returned a status of: " + request.status];
        error.FullException = err.toString();
    }
    return error;
};

const XMLHttpFactories = [
    () => {
        return new XMLHttpRequest();
    },
    () => {
        return new ActiveXObject("Msxml2.XMLHTTP") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("Msxml3.XMLHTTP") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("MSXML2.XMLHTTP.3.0") as XMLHttpRequest;
    },
    () => {
        return new ActiveXObject("Microsoft.XMLHTTP") as XMLHttpRequest;
    }
];

const createXMLHTTPObject = () => {
    let xmlhttp: XMLHttpRequest = null;
    // tslint:disable-next-line
    for (let i = 0; i < XMLHttpFactories.length; i++) {
        try {
            xmlhttp = XMLHttpFactories[i]();
        } catch (e) {
            continue;
        }
        break;
    }
    return xmlhttp;
};