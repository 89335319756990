import InternalLink from "components/Navigation/InternalLink/InternalLink";
import * as React from "react";
const styles = require("./style.less");
import routeLinks from "routeLinks";
import { AccountIcon } from "areas/infrastructure/components/AccountsLayout/AccountIcons";
import AccountDisplay, { AccountProps } from "areas/infrastructure/components/AccountDisplay";

interface ReadonlyAccountProps {
    account: AccountProps;
    accountId: string;
    className?: string;
    renderIcon?: () => React.ReactElement<any>;
}

// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyAccount: React.SFC<ReadonlyAccountProps> = ({className, accountId, account, renderIcon}) => {
    return <div className={styles.container}>
        {renderIcon && <span className={styles.iconContainer}>{renderIcon()}</span>}
        <div className={`${className} ${styles.content}`} title={account ? account.name : accountId}>
            <InternalLink to={routeLinks.infrastructure.account(accountId)} >{account ? account.name : accountId}</InternalLink>
        </div>
    </div>;
};

interface ReadonlyAccountDisplayProps {
    accountId: string;
    renderIcon?: () => React.ReactElement<any>;
}

const ReadonlyAccountDisplay: React.SFC<ReadonlyAccountDisplayProps> = ({ accountId, renderIcon }) => (
    <AccountDisplay accountId={accountId} render={
        ({account}) => (
            <ReadonlyAccount
                account={account}
                accountId={accountId}
                renderIcon={renderIcon ? renderIcon : () => <AccountIcon accountType={account.accountType} />}
            />)}
    />
);

export default ReadonlyAccountDisplay;