import * as React from "react";
import { RefreshIndicator } from "material-ui";
import { secondaryText } from "colors";
import BusyFromPromise from "components/BusyFromPromise";
const styles = require("./style.less");
import BusyRefreshIndicator from "components/BusyRefreshIndicator";

interface BusyRefreshContainerProps {
    busy: Promise<any> | boolean;
}

const BusyRefreshContainer: React.StatelessComponent<BusyRefreshContainerProps> = props => {
    return <div className={styles.controlWithRefreshContainer}>
        {props.children}
        <div className={styles.refreshContainer}>
            <BusyFromPromise promise={props.busy}>
                {(busy: boolean) => <BusyRefreshIndicator show={busy} />}
            </BusyFromPromise>
        </div>
    </div>;
};

BusyRefreshContainer.displayName = "BusyRefreshContainer";

export default BusyRefreshContainer;