exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__2AD5- {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__3q7O_ {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__1g26n {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__1LqGy {\n    display: none !important;\n  }\n}\n.style_inputRow__6r78m {\n  flex: 3;\n  padding-left: 1rem;\n  padding-right: 2rem;\n}\n.style_detailsHighlight__1UVxq {\n  padding-top: 1rem;\n  background-color: rgba(153, 153, 153, 0.08);\n}\n.style_rightAlignedContainer__uBgUK {\n  display: flex;\n  flex-direction: row-reverse;\n}\n.style_textFormatSelector__OrJ3H {\n  margin-bottom: -32px;\n  width: 10rem;\n}\n.style_content__1O1YY {\n  display: flex;\n  height: 100%;\n}\n@media (max-width: 79.9rem) {\n  .style_content__1O1YY {\n    display: block;\n  }\n}\n.style_value__1Z-EW {\n  flex: 7 1 auto;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n.style_value__1Z-EW > * {\n  width: 100%;\n  flex: 1 1 auto;\n  display: flex;\n  flex-direction: column;\n}\n.style_editor__34pr8 {\n  flex: 1 1 auto;\n  display: flex;\n  flex-direction: column;\n}\n.style_editorContainer__YQmPf {\n  flex: 1 1 auto;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__2AD5-",
	"hideSm": "style_hide-sm__2AD5-",
	"visible-sm": "style_visible-sm__3q7O_",
	"visibleSm": "style_visible-sm__3q7O_",
	"hide-md": "style_hide-md__1g26n",
	"hideMd": "style_hide-md__1g26n",
	"visible-md": "style_visible-md__1LqGy",
	"visibleMd": "style_visible-md__1LqGy",
	"inputRow": "style_inputRow__6r78m",
	"detailsHighlight": "style_detailsHighlight__1UVxq",
	"rightAlignedContainer": "style_rightAlignedContainer__uBgUK",
	"textFormatSelector": "style_textFormatSelector__OrJ3H",
	"content": "style_content__1O1YY",
	"value": "style_value__1Z-EW",
	"editor": "style_editor__34pr8",
	"editorContainer": "style_editorContainer__YQmPf"
};