exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_menuIcon__gk7RU {\n  font-size: 1.37500rem;\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: #333333;\n}\n.style_menuIcon__gk7RU:visited {\n  color: #333333;\n}\n.style_menuIcon__gk7RU:hover {\n  opacity: 0.5;\n}\n.style_container__2DcBs {\n  display: flex;\n  flex-direction: row;\n}\n.style_container__2DcBs > :nth-child(2) {\n  margin-left: 1rem;\n}\n.style_buttons__36Ekq {\n  padding-top: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"menuIcon": "style_menuIcon__gk7RU",
	"container": "style_container__2DcBs",
	"buttons": "style_buttons__36Ekq"
};