import * as React from "react";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";

interface ThemeProps {
    theme: object;
}

const Theme: React.StatelessComponent<ThemeProps> = props => {

    return <MuiThemeProvider muiTheme={getMuiTheme(props.theme)}>
        {props.children}
    </MuiThemeProvider>;
};

export default Theme;