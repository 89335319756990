exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_when__1lHKf abbr[title] {\n  text-decoration: none;\n  cursor: help;\n  border-bottom: 0.05rem dotted #777777;\n}\n", ""]);

// exports
exports.locals = {
	"when": "style_when__1lHKf"
};