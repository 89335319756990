import {TenantResource} from "../../../client/resources/tenantResource";

export enum VariablesTab {
    CommonVariables = "commonVariables",
    ProjectVariables = "projectVariables"
}

export default (root: string) => ({
    tenants: `${root}/tenants`,
    tenant: (tenantId: string | TenantResource) => {
        if (typeof tenantId !== "string") {
            tenantId = tenantId.Id;
        }
        const specificRoot = `${root}/tenants/${tenantId}`;

        return {
            root: specificRoot,
            overview: `${specificRoot}/overview`,
            settings: `${specificRoot}/settings`,
            variables: (activeTab?: VariablesTab) => {
                return {
                    pathname: `${specificRoot}/variables`,
                    search: activeTab ? `?activeTab=${activeTab}` : ""
                };
            }
        };
    },
    v3tenantRoutes: {
        newTenant: `${root}/tenants/new`
    }
});
