import * as React from "react";
import * as cn from "classnames";
import { Link } from "react-router-dom";
import {History} from "history";
type LocationDescriptor = History.LocationDescriptor;

const styles = require("./style.less");
import InternalLink from "./InternalLink";

interface ListInternalLinkProps extends React.HTMLProps<HTMLElement> {
    size?: number;
    to: LocationDescriptor;
    className?: string;
    onClick?: () => void;
}

const ListInternalLink: React.SFC<ListInternalLinkProps> = (props: ListInternalLinkProps) => {
    return <InternalLink className={cn(styles.listInternalLink, props.className)}
        size={props.size}
        to={props.to}
        onClick={props.onClick}>
        {props.children}
    </InternalLink>;
};

export default ListInternalLink;
