import * as React from "react";
import ActionButton, { ActionButtonType } from "../../Button/ActionButton";
import { LargeDialogFrame, DialogFrameProp } from "./DialogFrames";
import { RenderProps } from "components/Dialog/CustomDialog";
import { TemplatedDialogLayout } from "./TemplatedDialogLayout";
import { DialogTitleBar } from "./DialogTitleBar";
import { DialogContent } from "./DialogContent";
import { DialogActions } from "./DialogActions";

export type InfoDialogLayoutProps  = RenderProps & DialogFrameProp & {
    title: string;
    closeButtonLabel?: string;
    additionalActions?: React.ReactElement<any>;
};

export const InfoDialogLayout: React.SFC<InfoDialogLayoutProps> = ({closeButtonLabel= "Close", children, title, close, open, frame = LargeDialogFrame, additionalActions}) => (
    <TemplatedDialogLayout open={open} close={close} frame={frame}
        renderTitle={() => <DialogTitleBar title={title}/>}
        renderContent={() => (
            <DialogContent>{children}</DialogContent>
        )}
        renderFooter={() => (
            <DialogActions actions={(
                <React.Fragment>
                    {additionalActions}
                    <ActionButton key="close" onClick={close} label={closeButtonLabel} type={ActionButtonType.Primary} />
                </React.Fragment>
            )}/>
        )}
    />
);

export default InfoDialogLayout;