import * as React from "react";
import BaseComponent from "components/BaseComponent";
import pluginRegistry, { ActionEditProps } from "components/Actions/pluginRegistry";
import { Summary, ExpandableFormSection, Note } from "components/form";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";
import DialogOpener from "components/Dialog/DialogOpener";
import RemoveItemsList from "../../RemoveItemsList";
import { isEqual, clone } from "lodash";
import LocationDialog from "./locationDialog";
import { VariableLookupText } from "components/form/VariableLookupText";
import ActionButton from "components/Button";
import {NginxLocation, nginxLocationsToString, nginxLocationsFromString, keyValuesFromString} from "./locationHelpers";
import { nginxBindingsToString, NginxBinding, nginxBindingsFromString } from "./bindingHelpers";
import NginxBindingDialog from "./bindingDialog";

class LocationList extends RemoveItemsList<NginxLocation> {
}

class BindingList extends RemoveItemsList<NginxBinding> {
}

interface NginxState {
    locations: NginxLocation[];
    editLocation?: NginxLocation;
    editLocationIndex?: number;
    bindings: NginxBinding[];
    editBinding?: NginxBinding;
    editBindingIndex?: number;
}
const StringProperties = {
    "Octopus.Action.Nginx.Server.HostName": "",
    "Octopus.Action.Nginx.Server.Bindings": "",
    "Octopus.Action.Nginx.Server.Locations": ""
};

type NginxProperties = {[P in keyof typeof StringProperties]: string};

class NginxEdit extends BaseComponent<ActionEditProps<NginxProperties>, NginxState> {
    constructor(props: ActionEditProps<NginxProperties>) {
        super(props);
        this.state = {
            locations: [],
            editLocation: null,
            editLocationIndex: null,
            bindings: [],
            editBinding: null,
            editBindingIndex: null
        };
    }
    shouldComponentUpdate(newProps: ActionEditProps<NginxProperties>, newState: NginxState) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, newProps, this.props) || !isEqual(newState, this.state);
    }

    async componentDidMount() {
        await this.props.doBusyTask(async () => {
            this.setState({
                locations: !!this.props.properties["Octopus.Action.Nginx.Server.Locations"] ? nginxLocationsFromString(this.props.properties["Octopus.Action.Nginx.Server.Locations"]) : [],
                bindings: !!this.props.properties["Octopus.Action.Nginx.Server.Bindings"] ? nginxBindingsFromString(this.props.properties["Octopus.Action.Nginx.Server.Bindings"]) : []
            });
        });
    }

    componentWillReceiveProps(nextProps: ActionEditProps<NginxProperties>) {
        if (this.props.properties["Octopus.Action.Nginx.Server.Locations"] !== nextProps.properties["Octopus.Action.Nginx.Server.Locations"] ||
            this.props.properties["Octopus.Action.Nginx.Server.Bindings"] !== nextProps.properties["Octopus.Action.Nginx.Server.Bindings"]) {
            this.setState({
                locations: !!nextProps.properties["Octopus.Action.Nginx.Server.Locations"] ? nginxLocationsFromString(nextProps.properties["Octopus.Action.Nginx.Server.Locations"]) : [],
                bindings: !!nextProps.properties["Octopus.Action.Nginx.Server.Bindings"] ? nginxBindingsFromString(nextProps.properties["Octopus.Action.Nginx.Server.Bindings"]) : []
            });
        }
    }

    summary() {
        return Summary.placeholder("");
    }

    render() {
        const properties = this.props.properties;

        const editLocationsDialog = <DialogOpener open={!!this.state.editLocation} onClose={this.resetSelectedLocation} wideDialog={true}>
            <LocationDialog
                location={this.state.editLocation}
                projectId={this.props.projectId}
                doBusyTask={this.props.doBusyTask}
                localNames={this.props.localNames}
                onAdd={location => this.saveLocation(location)}
            />
        </DialogOpener>;

        const editBindingsDialog = <DialogOpener open={!!this.state.editBinding} onClose={this.resetSelectedBinding} wideDialog={true}>
            <NginxBindingDialog
                binding={this.state.editBinding}
                projectId={this.props.projectId}
                doBusyTask={this.props.doBusyTask}
                localNames={this.props.localNames}
                onAdd={binding => this.saveBinding(binding)}
            />
        </DialogOpener>;

        return <div>
            {editLocationsDialog}
            {editBindingsDialog}
            <ExpandableFormSection
                errorKey="Octopus.Action.Nginx.Server.HostName"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Virtual Server"
                summary={this.serverHostNameConfiguration()}
                help="Configure the NGINX virtual server">
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.Nginx.Server.HostName"]}
                    onChange={x => {
                        this.props.setProperties({["Octopus.Action.Nginx.Server.HostName"]: x});
                    }}
                    error={this.props.getFieldError("Octopus.Action.Nginx.Server.HostName")}
                    label="Host Name" />
                <Note>
                    The <code>Host</code> header that this server will listen on.
                    The value can be a full (exact) name, a wildcard, or a regular expression.
                    A wildcard is a character string that includes the asterisk (<code>*</code>) at its beginning, end, or both; the asterisk matches any sequence of characters
                    Example:<code>www.contoso.com</code>. Leave empty to use any <code>Host</code> header.
                </Note>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Nginx.Server.Bindings"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Bindings"
                summary={this.bindingsSummary()}
                help="Configure the NGINX bindings">
                <BindingList
                    listActions={[<ActionButton key="add" label="Add binding" onClick={() => this.addBinding()}/>]}
                    data={this.state.bindings}
                    onRow={binding => <div>
                        {binding.protocol && <p>Protocol: <strong>{binding.protocol}</strong></p>}
                        {binding.port && <p>Port: <strong>{binding.port}</strong></p>}
                        {binding.ipAddress && <p>IP Address: <strong>{binding.ipAddress}</strong></p>}
                        {binding.certificateLocation && <p>Server certificate location: <strong>{binding.certificateLocation}</strong></p>}
                        {binding.certificateKeyLocation && <p>Server certificate private key location: <strong>{binding.certificateKeyLocation}</strong></p>}
                        {binding.certificateVariable && <p>SSL certificate variable: <strong>{binding.certificateVariable}</strong></p>}
                        {binding.securityProtocols && <p>Enabled security protocols: <strong>{binding.securityProtocols.join(", ")}</strong></p>}
                    </div>}
                    onRowTouch={binding => this.editBinding(binding)}
                    onRemoveRow={binding => this.removeBinding(binding)}/>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Nginx.Server.Locations"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Locations"
                summary={this.locationsSummary()}
                help="Configure the virtual server locations">
                <LocationList
                    listActions={[<ActionButton key="add" label="Add location" onClick={() => this.addLocation()}/>]}
                    data={this.state.locations}
                    onRow={location => <div>
                        {location.path && <p>Location: <strong>{location.path}</strong></p>}
                        {location.reverseProxy && <p>Proxied to: <strong>{location.reverseProxyUrl}</strong></p>}
                        {location.directives &&
                        <div>
                            <strong>Directives</strong>
                            {keyValuesFromString(location.directives).map((directive, idx) => {
                                return <p key={idx}>{directive.key}: <strong>{directive.value}</strong></p>;
                            })}
                        </div>}
                        {location.headers &&
                        <div>
                            <strong>Headers</strong>
                            {keyValuesFromString(location.headers).map((header, idx) => {
                                return <p key={idx}>{header.key}: <strong>{header.value}</strong></p>;
                            })}
                        </div>}
                    </div>}
                    onRowTouch={location => this.editLocation(location)}
                    onRemoveRow={location => this.removeLocation(location)}/>
            </ExpandableFormSection>
        </div>;
    }

    serverHostNameConfiguration = () => {
        const hostname = this.props.properties["Octopus.Action.Nginx.Server.HostName"];
        if (!hostname) {
            return Summary.placeholder("No hostname has been configured");
        }

        return Summary.summary(<span>This server will serve requests with a <code>Host</code> header field that matches <strong>{hostname}</strong></span>);
    }

    bindingsSummary = () => {
        const length = this.state.bindings.length;
        if (length === 0) {
            return Summary.placeholder("No bindings have been configured");
        }
        const first = this.state.bindings[0];
        const address = first.ipAddress || "*";
        const summary = first.protocol + "://" + address + ":" + first.port;
        const nodes = [];
        nodes.push(<span>The site will listen on <strong>{summary}</strong></span>);
        if (length === 2) {
            nodes.push(<span> and one other binding</span>);
        }
        if (length > 2) {
            nodes.push(<span> and {length - 1} other bindings</span>);
        }
        return Summary.summary(React.Children.toArray(nodes));
    }

    locationsSummary = () => {
        const length = this.state.locations.length;
        if (length === 0) {
            return Summary.placeholder("No locations have been configured");
        }

        const first = this.state.locations[0];
        const summary = first.reverseProxy
            ? <span>proxy requests for <strong>{first.path}</strong> to <strong>{first.reverseProxyUrl}</strong></span>
            : <span>serve requests for <strong>{first.path}</strong> by delivering files from the local file system</span>;
        const nodes = [];
        nodes.push(<span>The server will {summary}</span>);

        return Summary.summary(React.Children.toArray(nodes));
    }

    removeLocation = (location: any) => {
        const locations = [...this.state.locations];
        locations.splice(this.state.locations.indexOf(location), 1);
        this.props.setProperties({["Octopus.Action.Nginx.Server.Locations"]: nginxLocationsToString(locations)});
    }

    editLocation = (location: any) => {
        this.setState({
            editLocation: clone(location),
            editLocationIndex: this.state.locations.indexOf(location)
        });
    }

    addLocation = () => {
        const location = {
            path: "",
            directives: "",
            headers: "",
            reverseProxy: false,
            reverseProxyUrl: "",
            reverseProxyHeaders: "",
            reverseProxyDirectives: ""
        };
        this.setState({
            editLocation: location,
            editLocationIndex: null
        });
    }

    saveLocation(location: any) {
        const locations = [...this.state.locations];
        if (this.state.editLocationIndex === null) {
            locations.push(location);
        } else {
            locations[this.state.editLocationIndex] = location;
        }
        this.props.setProperties({["Octopus.Action.Nginx.Server.Locations"]: nginxLocationsToString(locations)});
        this.resetSelectedLocation();
        return true;
    }

    resetSelectedLocation = () => {
        this.setState({
            editLocation: null,
            editLocationIndex: null
        });
    }

    removeBinding = (binding: any) => {
        const bindings = [...this.state.bindings];
        bindings.splice(this.state.bindings.indexOf(binding), 1);
        this.props.setProperties({["Octopus.Action.Nginx.Server.Bindings"]: nginxBindingsToString(bindings)});
    }

    editBinding = (binding: any) => {
        this.setState({
            editBinding: clone(binding),
            editBindingIndex: this.state.bindings.indexOf(binding)
        });
    }

    addBinding = () => {
        const binding: NginxBinding = {
            protocol: "http",
            port: "80",
            ipAddress: "*",
            certificateLocation: null,
            certificateKeyLocation: null,
            securityProtocols: null,
            enabled: true
        };
        this.setState({
            editBinding: binding,
            editBindingIndex: null
        });
    }

    saveBinding(binding: any) {
        const bindings = [...this.state.bindings];
        if (this.state.editBindingIndex === null) {
            bindings.push(binding);
        } else {
            bindings[this.state.editBindingIndex] = binding;
        }
        this.props.setProperties({["Octopus.Action.Nginx.Server.Bindings"]: nginxBindingsToString(bindings)});
        this.resetSelectedBinding();
        return true;
    }

    resetSelectedBinding = () => {
        this.setState({
            editBinding: null,
            editBindingIndex: null
        });
    }

}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.Nginx",
    title: "NGINX Web Server",
    description: "Configures an NGINX web server",
    edit: NginxEdit,
    priority: 30,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.Nginx.Server.Bindings"] = nginxBindingsToString([
            {
                protocol: "http",
                port: "80",
                ipAddress: "*",
                certificateLocation: null,
                certificateKeyLocation: null,
                securityProtocols: null,
                enabled: true
            }
        ]);
    },
    disable: (properties: ActionProperties) => {
        Object.keys(properties).filter((name) => {
            return name.indexOf("Octopus.Action.Nginx.") === 0;
        }).forEach((name) => {
            delete properties[name];
        });
    }
});