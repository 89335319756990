import * as React from "react";
const styles = require("./style.less");
import FlatButton from "material-ui/FlatButton";
import { default as MaterialIconButton } from "material-ui/IconButton";
import CropOriginal from "material-ui/svg-icons/image/crop-original";
import ToolTip, { ToolTipContent } from "components/ToolTip/index";
import * as cn from "classnames";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import AddIcon from "material-ui/svg-icons/content/add";
import SearchIcon from "material-ui/svg-icons/action/search";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import VisibilityOffIcon from "material-ui/svg-icons/action/visibility-off";
import RemoveIcon from "material-ui/svg-icons/action/delete";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import CancelIcon from "material-ui/svg-icons/content/clear";
import UploadIcon from "material-ui/svg-icons/file/file-upload";
import ArrowDownIcon from "material-ui/svg-icons/navigation/arrow-drop-down";
import ArrowUpIcon from "material-ui/svg-icons/navigation/arrow-drop-up";
import {secondaryText} from "colors";

export enum Icon {
    UseDefaultImage,
    EnterFullScreen,
    ExitFullScreen,
    Refresh,
    Add,
    Search,
    Show,
    Hide,
    Edit,
    Remove,
    Cancel,
    ArrowDown,
    Upload,
    ArrowUp
}

interface IconButtonProps {
    icon?: Icon | string;
    customIcon?: JSX.Element;
    toolTipContent?: ToolTipContent;
    disabled?: boolean;
    tabIndex?: number;
    onClick: (event: any) => void;
    buttonRef?: (ref: FlatButton | MaterialIconButton) => void;
}

const IconButton: React.StatelessComponent<IconButtonProps> = props => {
    if (props.icon !== null) {
        if (typeof props.icon === "string") {
            return iconButton(<img className={styles.imageIcon} src={props.icon} alt="" />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
        }

        switch (props.icon) {
            case Icon.EnterFullScreen: return fontAwesomeIconButton("fa-expand", props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.ExitFullScreen: return fontAwesomeIconButton("fa-compress", props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.UseDefaultImage: return materialIconButton(<CropOriginal />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Refresh: return materialIconButton(<RefreshIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Add: return materialIconButton(<AddIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Search: return materialIconButton(<SearchIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Show: return materialIconButton(<VisibilityIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Hide: return materialIconButton(<VisibilityOffIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Edit: return materialIconButton(<EditIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Cancel: return materialIconButton(<CancelIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Remove: return materialIconButton(<RemoveIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.Upload: return materialIconButton(<UploadIcon />, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.ArrowDown: return materialIconButton(<ArrowDownIcon color={secondaryText}/>,
                props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
            case Icon.ArrowUp: return materialIconButton(<ArrowUpIcon color={secondaryText}/>,
                props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
        }
    }

    return materialIconButton(props.customIcon, props.onClick, props.toolTipContent, props.disabled, props.tabIndex, props.buttonRef);
};

function materialIconButton(icon: JSX.Element, onClick: (event: any) => void, toolTipContent: ToolTipContent, disabled: boolean, tabIndex: number | undefined,
                            buttonRef: ((ref: FlatButton | MaterialIconButton) => void) | undefined) {
    const elem = <MaterialIconButton className={styles.materialUIIcon}
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={onClick}
        ref={buttonRef}
    >
        {icon}
    </MaterialIconButton>;

    if (!toolTipContent) {
        return elem;
    }

    return withToolTip(elem, toolTipContent);
}

function fontAwesomeIconButton(iconClassName: string, onClick: (event: any) => void, toolTipContent: ToolTipContent, disabled: boolean, tabIndex: number | undefined,
                               buttonRef: ((ref: FlatButton | MaterialIconButton) => void) | undefined) {
    return iconButton(<em className={cn("fa", iconClassName, styles.fontAwesomeIcon)} aria-hidden="true" />, onClick, toolTipContent, disabled, tabIndex, buttonRef);
}

function iconButton(label: JSX.Element, onClick: (event: any) => void, toolTipContent: ToolTipContent, disabled: boolean, tabIndex: number | undefined,
                    buttonRef: ((ref: FlatButton | MaterialIconButton) => void) | undefined) {
    return withToolTip(<FlatButton className={styles.materialUIIcon}
        disabled={disabled}
        tabIndex={tabIndex}
        onClick={onClick}
        ref={buttonRef}
        label={label} />, toolTipContent);
}

function withToolTip(button: React.ReactNode, toolTipContent: ToolTipContent) {
    return <ToolTip content={toolTipContent}>
        {button}
    </ToolTip>;
}

IconButton.displayName = "IconButton";

export default IconButton;