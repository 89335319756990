exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_note__4cGKf {\n  font-family: Roboto;\n  font-size: 0.75rem;\n  text-align: left;\n  font-weight: 300;\n  margin-bottom: 0.5rem;\n  color: #666666;\n}\n.style_note__4cGKf a {\n  color: #2f93e0;\n}\n.style_note__4cGKf .style_checkboxNote__2U75j {\n  display: none;\n}\n", ""]);

// exports
exports.locals = {
	"note": "style_note__4cGKf",
	"checkboxNote": "style_checkboxNote__2U75j"
};