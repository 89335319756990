exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_thumbprint__EjJv1 {\n  font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"thumbprint": "style_thumbprint__EjJv1"
};