// Allows other parties to register 'plugins' that provide the view and edit experience for
// custom deployment actions and conventions.
//
//   var module = angular.module('myPlugin', [], function(pluginRegistry) {
//      pluginRegistry.registerDeploymentAction("Octopus.TentaclePackage", {
//        title: "Deploy a NuGet package",               // Display text for users adding the step
//        summary: "Deploys a NuGet package...",         // Help text to show
//        summaryTemplateUrl: "area/foo/bar.html",       // Template for a short summary on the process overview page
//        summaryLink: function (scope, element) {       // Angular directive-style link function
//          var action = scope.action;                   // Get the action we are rendering
//          var step = scope.step;                       // Get the step we are rendering
//          var jQueryElement = element;                 // The HTML element for the bar.html we just inserted
//        },
//        editTemplate: "area/bar/baz.html"              // Template for editing
//      });
//
//      pluginRegistry.registerFeature("Octopus.Features.CustomPackageDirectory", {
//        isEnabled: function(action, step) {  },
//        enable: function(action, step) {  },
//        disable: function(action, step) {  },
//        editTemplateUrl: "area/foo/bar.html",
//        editLink: function(scope, element) { }
//      });
//   });

import * as React from "react";
import {DeploymentStepResource} from "client/resources/deploymentStepResource";
import {ActionExecutionLocation, DeploymentActionResource} from "client/resources";
import ActionProperties from "client/resources/actionProperties";
import { Errors } from "components/DataBaseComponent";
import { RunOn, TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { PackageRequirement } from "client/resources";
import {PackageReference, PackageReferenceProperties} from "../../client/resources/packageReference";

interface Features {
    optional?: string[];
    initial?: string[];
    permanent?: string[];
}

export interface BoundFieldProps {
    localNames?: string[];
    projectId?: string;
}

// If you need access to the Step add a new child object here to scope
// the details you need. This won't be supplied in the Template area
interface AdditionalActions {
    packageAcquisition: {
        canRunBeforeAcquisition: boolean;
        stepPackageRequirement?: PackageRequirement;
        onStepPackageRequirementChanged(value: PackageRequirement): void;
        onCanRunBeforeAcquisitionChanged(value: boolean): void;
    };
    stepTargetRoles: string;
    actionType: string;
    workerPoolId?: string;
}

export interface ActionEditProps<T = ActionProperties, P = PackageReferenceProperties> extends BoundFieldProps {
    properties: T;
    packages: Array<PackageReference<P>>;
    plugin: ActionPlugin;
    runOn?: RunOn; // Will not be supplied if an action-template, as the execution location is not chosen
    additionalActions?: AdditionalActions;
    errors: Errors | undefined; // only used for shouldComponentUpdate
    busy: Promise<any> | boolean;
    expandedByDefault: boolean;
    getFieldError(field: string): string;
    setProperties(properties: Partial<T>, initialise?: boolean, callback?: () => void): void;
    setPackages(packages: Array<PackageReference<P>>, initialise?: boolean): void;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    refreshRunOn?(): void;
}

export interface ActionPlugin {
    actionType: string;
    summary: (action: ActionProperties, targetRolesAsCSV: string, packages: Array<PackageReference<{}>>, worker?: string) => React.ReactNode;
    canHaveChildren: (step: DeploymentStepResource) => boolean;
    canBeChild: boolean;
    executionLocation: ActionExecutionLocation;
    edit: any;
    canRunOnWorker?: boolean;
    hasPackages?: (action: DeploymentActionResource) => boolean;
    targetRoleOption: (action: DeploymentActionResource) => TargetRoles;
    disableAddTargetRoles?: boolean;
    features?: Features;
}

export interface FeaturePlugin {
    description: string;
    featureName: string;
    priority: number;
    title: string;
    edit: any;
    enable?(properties: ActionProperties): void;
    disable?(properties: ActionProperties): void;
    preSave?(properties: ActionProperties): void;
    validate?(properties: ActionProperties, errors: {}): void;
}

class PluginRegistry {
    private registeredActions: { [actionType: string]: ActionPlugin; } = {};
    private registeredFeatures: { [featureName: string]: FeaturePlugin; } = {};

    registerDeploymentAction(registration: ActionPlugin) {
        this.registeredActions[registration.actionType] = registration;
    }

    registerFeature(registration: FeaturePlugin) {
        this.registeredFeatures[registration.featureName] = registration;
    }

    resolve(container: any, key: string) {
        const result = container[key];
        //callback(result, key);
        return result;
    }

    resolveAll(container: any) {
        return Object.keys(container).map((key) => {
            return this.resolve(container, key);
        }).sort((item) => item.priority);
    }

    getDeploymentAction(actionType: string): ActionPlugin {
        const registration = this.resolve(this.registeredActions, actionType);
        if (!registration) {
            throw new Error(`There is no plugin registered for ${actionType} action type.`);
        }
        return registration;
    }

    getAllActions(): ActionPlugin[] {
        return this.resolveAll(this.registeredActions);
    }

    getFeature(featureName: string): FeaturePlugin {
        const feature = this.resolve(this.registeredFeatures, featureName);
        if (!feature) {
            throw new Error(`There is no plugin registered for ${featureName} feature type.`);
        }
        return feature;
    }

    getAllFeatures(): FeaturePlugin[] {
        return this.resolveAll(this.registeredFeatures);
    }

    hasFeaturesForAction(actionType: string): boolean {
        const actionPlugin = this.getDeploymentAction(actionType);
        const featuresForAction = this.getAllFeatures()
            .filter(f => actionPlugin.features && actionPlugin.features.optional && actionPlugin.features.optional.includes(f.featureName));
        return featuresForAction.length > 0;
    }
}

const pluginRegistry = new PluginRegistry();

export default pluginRegistry;