import * as React from "react";
import {PopoverPosition, PopoverWhenFocused} from "components/PopoverWhenFocused/PopoverWhenFocused";
const styles = require("./style.less");
import VariableCell from "areas/variables/VariableCell/VariableCell";

interface FocusActionsLayoutProps {
    isFocused: boolean;
    actions: JSX.Element[];
    position?: PopoverPosition;
    onClickOutside?: () => void;
}

const position = { top: 0, left: 0, right: 0 };

export default class FocusActionsLayout extends React.Component<FocusActionsLayoutProps> {
    render() {
        return <PopoverWhenFocused
            isFocused={this.props.isFocused}
            position={this.props.position ? this.props.position : position}
            onClickOutside={this.props.isFocused && this.props.onClickOutside ? this.props.onClickOutside : null}
        >
            {this.props.children}
            {this.props.isFocused &&
            <VariableCell className={styles.alignRightContainer}>
                <div className={styles.spacer} />
                <div className={styles.actionsContainer}>
                    {this.props.actions.map((a, index) => React.cloneElement(a, {key: index}))}
                </div>
            </VariableCell>}
        </PopoverWhenFocused>;
    }
}