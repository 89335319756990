import * as React from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
const styles = require("./style.less");

export enum ToolTipPosition {
    Right= "right",
    Left= "left",
    Top = "top",
    Bottom = "bottom"
}

export type ToolTipContent = string | (() => JSX.Element) | (() => string) | JSX.Element;

interface ToolTipProps {
    content: ToolTipContent;
    children?: React.ReactNode;
    position?: ToolTipPosition;
    open?: boolean;
    trigger?: "mouseenter" | "focus" | "click" | "manual";
    size?: "small" | "regular";
}

const ToolTip: React.StatelessComponent<ToolTipProps> = (props: ToolTipProps) => {
    let content = props.content;
    if (typeof content === "function") {
        content = content();
    }

    const position = props.position ? props.position : ToolTipPosition.Top;

    return <Tooltip open={props.open}
        trigger={props.trigger}
        arrow={true}
        position={position}
        html={<span className={styles.tooltipContent}>{content}</span>}
        size={"small"}>
        {props.children}
    </Tooltip>;
};

export default ToolTip;