import * as React from "react";
import AuthenticationLayoutInternal from "./AuthenticationLayoutInternal";
import GlobalState from "globalState";
import { connect } from "react-redux";

const AuthenticationLayout = connect(
    (state: GlobalState) => {
        return {
            unhandledError: state.unhandledError
        };
    }
)(AuthenticationLayoutInternal);

export default AuthenticationLayout;