import * as React from "react";
import AreaTitle from "../AreaTitle/index";
import {Route} from "react-router-dom";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../routeLinks";
import {EmptyPage} from "../EmptyPage/EmptyPage";

const RedirectAs404 = () => <Route render={({ location }) =>
    <InternalRedirect to={{ ...location, state: { is404: true } }} />} />;

const image = {
    src: require("./404-image.svg"),
    alt: "Page couldn't be found"
};

const NotFound = () =>
    <main id="maincontent">
        <AreaTitle
            link={routeLinks.root}
            title="Page not found"
        />
        <EmptyPage
            title="Sorry, the page you are looking for could not be found"
            image={image}
        />
    </main>;

export { NotFound, RedirectAs404 };