import * as React from "react";
import CloudIcon from "material-ui/svg-icons/file/cloud"; //https://material.io/icons/#ic_cloud
import LockIcon from "material-ui/svg-icons/action/lock"; //https://material.io/icons/#ic_lock
import PersonIcon from "material-ui/svg-icons/social/person"; //https://material.io/icons/#ic_person
import VPNKeyIcon from "material-ui/svg-icons/communication/vpn-key";
import SvgIcon from "material-ui/SvgIcon";
import {SvgIconProps} from "material-ui";
import { AccountType } from "client/resources";
import { secondaryText } from "colors";

const AmazonIcon: React.SFC<SvgIconProps> = (props) => (<SvgIcon {...props}>
    {/* tslint:disable-next-line */}
    <path d="M15.93,17.09C15.75,17.25 15.5,17.26 15.3,17.15C14.41,16.41 14.25,16.07 13.76,15.36C12.29,16.86 11.25,17.31 9.34,17.31C7.09,17.31 5.33,15.92 5.33,13.14C5.33,10.96 6.5,9.5 8.19,8.76C9.65,8.12 11.68,8 13.23,7.83V7.5C13.23,6.84 13.28,6.09 12.9,5.54C12.58,5.05 11.95,4.84 11.4,4.84C10.38,4.84 9.47,5.37 9.25,6.45C9.2,6.69 9,6.93 8.78,6.94L6.18,6.66C5.96,6.61 5.72,6.44 5.78,6.1C6.38,2.95 9.23,2 11.78,2C13.08,2 14.78,2.35 15.81,3.33C17.11,4.55 17,6.18 17,7.95V12.12C17,13.37 17.5,13.93 18,14.6C18.17,14.85 18.21,15.14 18,15.31L15.94,17.09H15.93M13.23,10.56V10C11.29,10 9.24,10.39 9.24,12.67C9.24,13.83 9.85,14.62 10.87,14.62C11.63,14.62 12.3,14.15 12.73,13.4C13.25,12.47 13.23,11.6 13.23,10.56M20.16,19.54C18,21.14 14.82,22 12.1,22C8.29,22 4.85,20.59 2.25,18.24C2.05,18.06 2.23,17.81 2.5,17.95C5.28,19.58 8.75,20.56 12.33,20.56C14.74,20.56 17.4,20.06 19.84,19.03C20.21,18.87 20.5,19.27 20.16,19.54M21.07,18.5C20.79,18.14 19.22,18.33 18.5,18.42C18.31,18.44 18.28,18.26 18.47,18.12C19.71,17.24 21.76,17.5 22,17.79C22.24,18.09 21.93,20.14 20.76,21.11C20.58,21.27 20.41,21.18 20.5,21C20.76,20.33 21.35,18.86 21.07,18.5Z" />
</SvgIcon>);

interface AccountIconProps extends SvgIconProps {
    accountType: AccountType;
}

const AccountIcon: React.SFC<AccountIconProps> = ({ accountType, ...rest }) => {
    const defaults = { color: secondaryText };
    switch (accountType) {
        case AccountType.AmazonWebServicesAccount:
            return <AmazonIcon {...defaults} {...rest} />;
        case AccountType.AzureServicePrincipal:
        case AccountType.AzureSubscription:
            return <CloudIcon {...defaults} {...rest} />;
        case AccountType.SshKeyPair:
            return <LockIcon {...defaults} {...rest} />;
        case AccountType.UsernamePassword:
            return <PersonIcon {...defaults} {...rest} />;
        case AccountType.Token:
            return <VPNKeyIcon {...defaults} {...rest} />;
        default: return null;
    }
};

export {
    CloudIcon as AzureIcon,
    LockIcon as SshKeyIcon,
    PersonIcon as UsernamePasswordIcon,
    AmazonIcon as AmazonWebServicesIcon,
    VPNKeyIcon as TokenIcon,
    AccountIcon,
    AccountIconProps
};