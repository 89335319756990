import Client from "../client";
import ConfigurationRepository from "./configurationRepository";
import {MaintenanceConfigurationResource} from "client/resources";

class MaintenanceConfigurationRepository extends ConfigurationRepository<MaintenanceConfigurationResource> {
    constructor(client: Client) {
        super("MaintenanceConfiguration", client);
    }
}

export default MaintenanceConfigurationRepository;