import Client from "../client";
import {NamedReferenceItem} from "../resources/namedReferenceItem";

class ExternalSecurityGroupRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    search(url: string, partialName: string): Promise<NamedReferenceItem[]> {
        return this.client.get(url, {partialName});
    }
}

export default ExternalSecurityGroupRepository;