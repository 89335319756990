import * as React from "react";
import { RouteComponentProps, withRouter, match as Match } from "react-router";
import { resolvePathWithSpaceId, isLocationDescriptorObject } from "./resolvePathWithSpaceId";
import { LocationDescriptor, LocationDescriptorObject, History } from "history";

export interface NavigateRenderProps {
    navigate(path: LocationDescriptor, state?: object): void;
    redirect(path: LocationDescriptor, state?: object): void;
}

interface NavigateProps {
    render: (renderProps: NavigateRenderProps) => React.ReactElement<any> | null;
}

function navigate(descriptor: LocationDescriptor, match: Match<{ spaceId: string }>, history: History, state?: any) {
    if (isLocationDescriptorObject(descriptor)) {
        history.push(resolvePathWithSpaceId(descriptor, match.params.spaceId) as LocationDescriptorObject);
    } else {
        history.push(resolvePathWithSpaceId(descriptor, match.params.spaceId) as string, state);
    }
}

function redirect(descriptor: LocationDescriptor, match: Match<{ spaceId: string }>, history: History, state?: any) {
    if (isLocationDescriptorObject(descriptor)) {
        history.replace(resolvePathWithSpaceId(descriptor, match.params.spaceId) as LocationDescriptorObject);
    } else {
        history.replace(resolvePathWithSpaceId(descriptor, match.params.spaceId) as string, state);
    }
}

export const Navigate: React.SFC<NavigateProps & RouteComponentProps<{ spaceId: string }>> = ({ children, render, match, location, history }) => (
    render({
        navigate: (path, state) => navigate(path, match, history, state),
        redirect: (path, state) => redirect(path, match, history, state)
    })
);

const EnhancedNavigate = withRouter(Navigate);

export function withNavigation<T>(Component: React.ComponentType<T & NavigateRenderProps>) {
    const WithNavigation: React.SFC<T> = (props: T) => {
        return (
            <EnhancedNavigate render={(renderProps) => (
                <Component {...renderProps} {...props} />
            )}/>
        );
    };

    return WithNavigation;
}

export default EnhancedNavigate;

export { EnhancedNavigate };