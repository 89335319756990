import {
    PagingBaseComponent,
    PagingBaseProps,
    PagingBaseState,
    PagingPage,
    HasId,
} from "./PagingBaseComponent";

export default PagingBaseComponent;
export {
    PagingBaseProps,
    PagingBaseState,
    PagingPage,
    HasId,
};