import {Resource} from "./resource";
import {TaskState} from "./taskState";
import { MixedSpaceResource } from "./mixedSpaceResource";
import { NamedResource } from ".";

export interface ConfigureLetsEncryptArguments {
    DnsName: string;
    RegistrationEmailAddress: string;
    AcceptLetsEncryptTermsOfService: boolean;
    HttpsPort: number;
    IPAddress: string;
    Path: string;
}

export interface UpdateCalamariTaskArguments {
    MachineIds: string[];
}

export interface UpgradeTaskArguments {
    EnvironmentId?: string;
    WorkerPoolId?: string;
    MachineIds?: string[];
    RestrictedTo?: TaskRestrictedTo;
}

export interface HealthCheckTaskArguments {
    EnvironmentId?: string;
    WorkerPoolId?: string;
    MachinePolicyId?: string;
    MachineIds?: string[];
    RestrictedTo?: TaskRestrictedTo;
    Timeout?: string;
    MachineTimeout?: string;
    OnlyTestConnection: boolean;
}

export enum TaskRestrictedTo {
    DeploymentTargets = "DeploymentTargets",
    Workers = "Workers",
    Policies = "Policies",
    Unrestricted = "Unrestricted",
}

// tslint:disable-next-line:no-empty-interface
export interface TaskTypeResource extends NamedResource {
}

// used when the client needs to create or do something with a specific task type
// note: this list is incomplete - the proper list should be obtained via the TaskTypes api endpoint
export enum TaskName {
    Health = "Health",
    AdHocScript = "AdHocScript",
    ConfigureLetsEncrypt = "ConfigureLetsEncrypt",
    Upgrade = "Upgrade",
    TestEmail = "TestEmail",
    TestAccount = "TestAccount",
    SystemIntegrityCheck = "SystemIntegrityCheck",
    SyncCommunityActionTemplates = "SyncCommunityActionTemplates",
    SynchronizeBuiltInPackageRepositoryIndex = "SynchronizeBuiltInPackageRepositoryIndex",
    UpdateCalamari = "UpdateCalamari",
}

export interface TaskResource<TArguments = any> extends MixedSpaceResource, Resource {
    Name: string;
    Description: string;
    Arguments: TArguments;
    State: TaskState;
    Completed?: string;
    QueueTime?: string;
    QueueTimeExpiry?: string;
    StartTime?: string;
    LastUpdatedTime?: string;
    CompletedTime?: string;
    ServerNode?: string;
    Duration?: string;
    ErrorMessage?: string;
    HasBeenPickedUpByProcessor?: boolean;
    IsCompleted: boolean;
    FinishedSuccessfully?: boolean;
    HasPendingInterruptions: boolean;
    CanRerun?: boolean;
    HasWarningsOrErrors: boolean;
}

export default TaskResource;