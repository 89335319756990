import * as React from "react";
import {AccountType} from "client/resources/index";
import { connect } from "react-redux";
import GlobalState from "globalState";
import { getAccountById } from "../../reducers/selectors";

interface RenderProps {
    accountId: string;
    account: AccountProps;
}

export interface AccountProps {
    accountId: string;
    name: string;
    description: string;
    accountType: AccountType;
}

interface AccountDisplayProps {
    accountId: string;
    account: AccountProps;
    render: (props: RenderProps) => React.ReactElement<any>;
}

interface ConnectedAccountDisplayProps {
    accountId: string;
}

interface InjectedProps {
    account: AccountProps;
}

const AccountDisplay: React.SFC<AccountDisplayProps> = ({ accountId, account, render}) => {
    return render({account, accountId});
};

const mapStateToProps = (state: GlobalState, props: ConnectedAccountDisplayProps): InjectedProps => {
    return {
        account: getAccountById(state, props)
    };
};

const ConnectedAccountDisplay = connect(mapStateToProps)(AccountDisplay);
export default ConnectedAccountDisplay;