import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import JavaCategory from "../../Definitions/JavaCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration, CommonTextForJavaSample } from "../CommonRegistrationText";

const BuildServerPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Java applications from build servers, we recommend using <i>Octo.exe</i>.
        </p>
        {CommonTextForJavaSample}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export const BuildServerPackagingRegistrationForJava: CategorizedPackagingRegistration = {
    displayOrder: 300,
    categories: [JavaCategory],
    name: "Build Server",
    type: "BuildServerForJava",
    renderCard: InfoRegistrationCards.basic(
        () => <BuildServerPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};