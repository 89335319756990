import { OverflowMenuNavLink, OverflowMenuRemoveItem } from "../../../components/Menu/OverflowMenu";
import * as React from "react";
const styles = require("./style.less");
import IconButton from "material-ui/IconButton";
import OpenIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import CloseIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import routeLinks from "../../../routeLinks";
import OverflowMenu from "components/Menu/OverflowMenu";
import { Permission } from "client/resources";
import BusyFromPromise from "components/BusyFromPromise/BusyFromPromise";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import CardTitle from "components/CardTitle";

interface VariableSetSectionHeadingProps {
    variableSetId: string;
    variableSetName: string;
    variableSetTab: string;
    isExpanded: boolean;
    busy?: Promise<void>;
    onRemoveVariableSet?: () => Promise<boolean>;
    onExpandedChanged: (expanded: boolean) => void;
}

class VariableSetSectionHeading extends BaseComponent<VariableSetSectionHeadingProps, {}> {
    render() {
        const linkTarget = routeLinks.library.variableSet(this.props.variableSetId, this.props.variableSetTab);
        const overflowMenuItems: Array<OverflowMenuRemoveItem | OverflowMenuNavLink> = [
            OverflowMenu.navItem("Edit in Library", linkTarget, null, {
                permission: Permission.ProjectEdit,
                wildcard: true
            })
        ];

        if (this.props.onRemoveVariableSet) {
            overflowMenuItems.push(OverflowMenu.removeItem("Remove", `Are you sure you want to remove ${this.props.variableSetName} set?`, this.props.onRemoveVariableSet, <div>
                <p>The values for any variable templates that are removed by this action will be permanently deleted from associated Tenants, there is no going back.</p>
                <p>Do you wish to continue?</p>
            </div>, {
                    permission: Permission.ProjectEdit,
                    wildcard: true
                })
            );
        }

        return <div>
            <div className={styles.header} onClick={() => this.props.onExpandedChanged(!this.props.isExpanded)}>
                <BusyFromPromise promise={this.props.busy}>
                    {(busy: boolean) => <div className={styles.busy}><BusyIndicator show={busy} /></div>}
                </BusyFromPromise>
                <div className={styles.textAndButtons}>
                    <div className={styles.variableSetLink}>
                        <CardTitle>{this.props.variableSetName}</CardTitle>
                    </div>
                    <OverflowMenu menuItems={overflowMenuItems} />
                    <IconButton>
                        {this.props.isExpanded ? <OpenIcon /> : <CloseIcon />}
                    </IconButton>
                </div>
            </div>
            {this.props.isExpanded && this.props.children}
        </div>;
    }
}

export default VariableSetSectionHeading;