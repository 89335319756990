import BasicRepository, { ListArgs } from "./basicRepository";
import Client from "../client";
import DeploymentResource from "../resources/deploymentResource";
import DeploymentTemplateResource from "../resources/deploymentTemplateResource";
import ReleaseResource from "../resources/releaseResource";
import ResourceCollection from "../resources/resourceCollection";
import LifecycleProgressionResource from "../resources/lifecycleProgressionResource";
import { DeploymentPromotionTarget } from "client/resources";
import { DeploymentPreviewResource } from "client/resources/deploymentPreviewResource";

type GetDeploymentArgs = ListArgs;

class ReleasesRepository extends BasicRepository<ReleaseResource, ReleaseResource> {
    constructor(client: Client) {
        super("Releases", client);
    }
    getDeployments(release: ReleaseResource, options?: GetDeploymentArgs): Promise<ResourceCollection<DeploymentResource>> {
        return this.client.get(release.Links["Deployments"], options);
    }
    getDeploymentTemplate(release: ReleaseResource): Promise<DeploymentTemplateResource> {
        return this.client.get(release.Links["DeploymentTemplate"]) as Promise<DeploymentTemplateResource>;
    }
    getDeploymentPreview(promotionTarget: DeploymentPromotionTarget) {
        return this.client.get<DeploymentPreviewResource>(promotionTarget.Links["Preview"], {includeDisabledSteps: true});
    }
    progression(release: ReleaseResource): Promise<LifecycleProgressionResource> {
        return this.client.get(release.Links["Progression"]);
    }
    snapshotVariables(release: ReleaseResource): Promise<ReleaseResource> {
        return this.client.post(release.Links["SnapshotVariables"]);
    }
}

export default ReleasesRepository;