import * as React from "react";
import { client } from "clientInstance";
import InputWithActions from "../../InputWithActions/InputWithActions";
import BusyIndicator from "../../BusyIndicator/BusyIndicator";
import {Icon, default as IconButton} from "components/IconButton/IconButton";
import IconButtonList from "components/IconButtonList/IconButtonList";
import Select, {SelectProps} from "../Select/Select";
import {withBoundField} from "../BoundField/BoundField";
import { resolveStringPathWithSpaceId } from "components/Navigation/resolvePathWithSpaceId";
const styles = require("./style.less");

interface SelectWithAddRefreshProps extends SelectProps {
    addUrl: string;
    onRequestRefresh(): Promise<any>;
}

interface SelectWithAddRefreshState {
    refreshing: boolean;
}

export default class SelectWithAddRefresh extends React.Component<SelectWithAddRefreshProps, SelectWithAddRefreshState> {

    constructor(props: SelectWithAddRefreshProps) {
        super(props);
        this.state = {
            refreshing: false
        };
    }

    render() {
        const {
            addUrl,
            onRequestRefresh,
            ...selectProps
        } = this.props;

        return <InputWithActions
            input={<Select
                {...selectProps}
            />}
            actions={<div className={styles.addRefreshButtons}>
                <IconButtonList buttons={this.buttons()}/>
            </div>}/>;
    }

    private onRequestRefresh = async () => {
        this.setState({ refreshing: true });
        try {
            await this.props.onRequestRefresh();
        } finally {
            this.setState({ refreshing: false });
        }
    }

    private buttons() {
        return [this.state.refreshing
            ? <BusyIndicator show={true} inline={true}/>
            : <IconButton onClick={this.onRequestRefresh} toolTipContent="Refresh" icon={Icon.Refresh}/>,
            <IconButton toolTipContent="Add" onClick={this.openAddWindow} icon={Icon.Add}/>];
    }

    private openAddWindow = () => {
        window.open(resolveStringPathWithSpaceId(this.props.addUrl, client.spaceId), "_blank");
    }
}

export const BoundSelectWithAddRefresh = withBoundField(SelectWithAddRefresh);