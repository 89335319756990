exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_markdownNote__1tewZ {\n  font-family: Roboto;\n  font-size: 0.75rem;\n  text-align: left;\n  font-weight: 300;\n  margin-bottom: 0.5rem;\n  color: #666666;\n}\n.style_markdownNote__1tewZ div {\n  font-size: 0.75rem;\n}\n", ""]);

// exports
exports.locals = {
	"markdownNote": "style_markdownNote__1tewZ"
};