exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_cell__rdZYd {\n  margin-left: 1rem;\n  margin-right: 1rem;\n  height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"cell": "style_cell__rdZYd"
};