import * as React from "react";
import {RemoveItemsList} from "components/RemoveItemsList/RemoveItemsList";
import {ActionTemplateParameterResource} from "client/resources/actionTemplateParameterResource";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import ActionTemplateParameterEditorDialog from "components/ActionTemplateParametersList/ActionTemplateParameterEditorDialog";
import DialogOpener from "components/Dialog/DialogOpener";
import {clone} from "lodash";
import ActionTemplateParameterResourceSummary from "components/ActionTemplateParametersList/ActionTemplateParameterResourceSummary";
import {ControlType} from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import {PermissionCheckProps} from "components/PermissionCheck/PermissionCheck";
import {ActionButtonType} from "../Button";
import {ActionTemplateParameterSorter} from "./ActionTemplateParameterSorter";

class ActionTemplateParameterRemoveItemsList extends RemoveItemsList<ActionTemplateParameterResource> { }

interface ActionTemplateParametersListProps {
    parameters: ActionTemplateParameterResource[];
    editPermission: PermissionCheckProps;
    excludedControlTypes?: ControlType[];
    name: "template" | "parameter";
    onParametersChanged(parameters: ActionTemplateParameterResource[]): void;
}

interface ActionTemplateParametersListState {
    parameterToEdit?: ActionTemplateParameterResource;
    parameterToEditIndex?: number;
}

export default class ActionTemplateParameterList extends React.Component<ActionTemplateParametersListProps, ActionTemplateParametersListState> {
    public static defaultProps: Partial<ActionTemplateParametersListProps> = {
        excludedControlTypes: []
    };

    constructor(props: ActionTemplateParametersListProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            <DialogOpener open={!!this.state.parameterToEdit} onClose={this.resetSelection}>
                <ActionTemplateParameterEditorDialog parameter={this.state.parameterToEdit}
                                                     excludedControlTypes={this.props.excludedControlTypes}
                                                     onOk={this.updateParameter}
                                                     name={this.props.name}
                                                     editPermission={this.props.editPermission}/>
            </DialogOpener>
            <ActionTemplateParameterRemoveItemsList
                empty={""}
                listActions={[this.reorderButton(), this.addParameterButton()].filter(b => !!b)}
                data={this.props.parameters}
                onRow={this.renderItem}
                onRemoveRow={(parameter) => this.props.onParametersChanged(this.props.parameters.filter(p => p !== parameter))}
                onRowTouch={this.editParameter}
            />
        </div>;
    }

    private resetSelection = () => {
        return this.setState({parameterToEdit: null, parameterToEditIndex: null});
    }

    private updateParameter = (parameter: ActionTemplateParameterResource) => {
        const before = this.props.parameters.slice(0, this.state.parameterToEditIndex);
        const after = this.props.parameters.slice(this.state.parameterToEditIndex + 1);
        this.setState({parameterToEditIndex: null, parameterToEdit: null});
        return this.props.onParametersChanged([...before, parameter, ...after]);
    }

    private editParameter = (parameter: ActionTemplateParameterResource) => {
        return this.setState({
            parameterToEdit: clone(parameter),
            parameterToEditIndex: this.props.parameters.indexOf(parameter)
        });
    }

    private renderItem = (parameter: ActionTemplateParameterResource) => {
        return <ActionTemplateParameterResourceSummary key={parameter.Name} parameter={parameter}/>;
    }

    private addParameterButton() {
        return <PermissionCheck
                key="AddParameterPermission"
                {...this.props.editPermission} >
            <OpenDialogButton key="AddParameter" label={`Add ${this.props.name}`} type={ActionButtonType.Primary}>
                <ActionTemplateParameterEditorDialog
                    onOk={parameter => this.props.onParametersChanged([...this.props.parameters, parameter])}
                    excludedControlTypes={this.props.excludedControlTypes}
                    name={this.props.name}
                    editPermission={this.props.editPermission} />
            </OpenDialogButton>
        </PermissionCheck>;
    }

    private reorderButton() {
        if (this.props.parameters.length <= 1) {
            return null;
        }

        return <PermissionCheck
            key="ReorderParametersPermission"
            {...this.props.editPermission} >
            <OpenDialogButton key="ReorderParameter" label={`Reorder ${this.props.name}s`} type={ActionButtonType.Secondary}>
                <ActionTemplateParameterSorter title={`Reorder ${this.props.name}s`}
                                      parameters={this.props.parameters}
                                      onOk={this.props.onParametersChanged} />
            </OpenDialogButton>
        </PermissionCheck>;
    }
}
