import BaseComponent from "../../BaseComponent";
import * as React from "react";
import * as _ from "lodash";
import FilterSearchBox from "../../FilterSearchBox";
import { List, ListItem } from "material-ui/List";
import { repository } from "clientInstance";
import Popover from "components/Popover/Popover";
import { ScriptingLanguage } from "components/scriptingLanguage";
const styles = require("./style.less");
import IconButton from "components/IconButton/IconButton";
const insertVariableIcon = require("./insert-variable.svg");

interface InsertVariableButtonProps {
    projectId?: string;
    projectEnvironmentsFilter?: string;
    localNames?: string[];
    syntax?: ScriptingLanguage;
    onSelected(value: string): void;
}

interface InsertVariableButtonState {
    serverNames: string[];
    filter: string;
    isInsertPopupOpen: boolean;
}

export default class InsertVariableButton extends BaseComponent<InsertVariableButtonProps, InsertVariableButtonState> {
    showInsertPopupButton: HTMLElement;

    constructor(props: InsertVariableButtonProps) {
        super(props);
        this.state = {
            serverNames: null,
            isInsertPopupOpen: false,
            filter: ""
        };
    }

    async componentDidMount() {
        this.setState({
            serverNames: await repository.Variables.names(this.props.projectId, this.props.projectEnvironmentsFilter),
        });
    }

    showInsertPopup = (event: any) => {
        event.preventDefault();

        this.setState({
            isInsertPopupOpen: true
        });
    }

    hideInsertPopup = () => {
        this.setState({
            isInsertPopupOpen: false
        });
    }

    handleSelected = (value: string, delimit: boolean) => {
        const delimited = delimit ? this.delimit(value) : value;
        this.props.onSelected(delimited);
        this.hideInsertPopup();
    }

    render() {
//TODO Insert variable icon to be added

        const allNames = _.concat(this.props.localNames ? this.props.localNames : [], this.state.serverNames);

        const lcFilter = this.state.filter.toLowerCase();
        const filterFunc = (item: string) => !lcFilter || item.toLowerCase().includes(lcFilter);

        const names = allNames
            .filter(filterFunc)
            .map((n, i) => <ListItem key={`item-${i}`} primaryText={n} onClick={() => this.handleSelected(n, true)} />);

        const functions = this.getFunctions()
            .filter(filterFunc)
            .map((n, i) => <ListItem key={`item-${i}`} primaryText={n} onClick={() => this.handleSelected(n, false)}/>);

        return <div ref={this.setRef}>
            <IconButton toolTipContent="Insert a variable"
                        onClick={this.showInsertPopup}
                        icon={insertVariableIcon}/>
            <Popover
                open={this.state.isInsertPopupOpen}
                anchorEl={this.showInsertPopupButton}
                onRequestClose={this.hideInsertPopup}
                anchorOrigin={{horizontal: "middle", vertical: "bottom"}}
                targetOrigin={{horizontal: "middle", vertical: "top"}}>
                <div className={styles.container} onKeyDown={this.onKeyDown}>
                    <div className={styles.filter}>
                        <FilterSearchBox autoFocus={true} value={this.state.filter} hintText="Find..." onChange={filter => this.setState({filter})}/>
                    </div>
                    {this.state.isInsertPopupOpen && <List className={styles.menuContainer}>
                        {names}
                        {functions}
                    </List>}
                </div>
            </Popover>
        </div>;
    }

    private onKeyDown = (event: any) => {
        if (event.keyCode === 27 /*esc*/) {
            this.setState({ isInsertPopupOpen: false });
        }
    }

    private setRef = (el: any) => {
        this.showInsertPopupButton = el;
    }

    private delimit = (varname: string) => {
        const syntax = this.props.syntax;
        if (!syntax) {
            return `#{${varname}}`;
        }

        // Some escaping is probably required here.
        if (syntax === ScriptingLanguage.PowerShell) {
            if (varname.match(/^[A-z0-9]+$/)) {
                return "$" + varname;
            } else {
                return `$OctopusParameters["${varname}"]`;
            }
        }

        if (syntax === ScriptingLanguage.CSharp) {
            return `Octopus.Parameters["${varname}"]`;
        }

        if (syntax === ScriptingLanguage.Bash) {
            return `get_octopusvariable "${varname}"`;
        }

        if (syntax === ScriptingLanguage.FSharp) {
            return `Octopus.findVariable "${varname}"`;
        }

        if (syntax === ScriptingLanguage.Python) {
            return `get_octopusvariable("${varname}")`;
        }

        return varname;
    }

    private getFunctions = () =>
        this.getLanguageFunctions()
            .concat([
                "#{if VariableName}value_if_true#{/if}",
                "#{unless VariableName}value_if_false#{/unless}",
                "#{each item in VariablePrefix}#{item.Postfix}#{/each}",
                "#{each number in NumberArrayVariable}#{number}#{/each}",
                "#{each item in JsonObjectVariable}#{item.Key}: #{item.Value.Property}#{/each}",
                "#{ VariableName | HtmlEscape }",
                "#{ VariableName | JsonEscape }",
                "#{ VariableName | XmlEscape }",
                "#{ VariableName | Markdown }",
                "#{ VariableName | ToLower }",
                "#{ VariableName | ToUpper }",
                "#{ | NowDate }",
                "#{ | NowDateUtc }",
                "#{ | NowDate \"HH dd-MMM-yyyy\" }",
                "#{ | NowDate zz }",
                "#{ | NowDate #{MyFormat} }",
                "#{ VariableName | Format C}"
            ])

    private getLanguageFunctions = () => {
        switch (this.props.syntax) {
            case ScriptingLanguage.PowerShell:
                return [
                    "New-OctopusArtifact",
                    "Set-OctopusVariable",
                    "Fail-Step",
                    "Write-Verbose",
                    "Write-Highlight",
                    "Write-Wait",
                    "Write-Warning",
                    "New-OctopusAzureServicePrincipalAccount",
                    "New-OctopusAzureWebAppTarget",
                    "New-OctopusAzureServiceFabricTarget",
                    "New-OctopusAzureCloudServiceTarget",
                    "Remove-OctopusTarget",
                ];
            case ScriptingLanguage.Bash:
                return [
                    "new_octopusartifact",
                    "set_octopusvariable",
                    "fail_step",
                    "write_verbose",
                    "write_highlight",
                    "write_wait",
                    "write_warning"
                ];
            case ScriptingLanguage.FSharp:
                return [
                    "createArtifact",
                    "setVariable",
                    "failStep",
                    "writeVerbose",
                    "writeHighlight",
                    "writeWait",
                    "writeWarning"
                ];
            case ScriptingLanguage.CSharp:
                return [
                    "Octopus.CreateArtifact",
                    "Octopus.SetVariable",
                    "Octopus.FailStep",
                    "Octopus.WriteVerbose",
                    "Octopus.WriteHighlight",
                    "Octopus.WriteWait",
                    "Octopus.WriteWarning"
                ];
            default:
                return [];
        }
    }

}