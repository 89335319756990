import * as React from "react";
import { WarningFormSection } from "components/form/Sections";
import TentacleDownloadButton from "areas/infrastructure/components/TentacleDownloadButton";
import {
    Note
} from "components/form";
import {Callout, CalloutType} from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink";
import { TentacleCalloutHeading } from "./TentacleCalloutHeading";

const styles = require("./styles.less");

export const ListeningTentacleCallout: React.SFC<{thumbprint: React.ReactNode}> = ({thumbprint}) => (
    <WarningFormSection>
        <Callout type={CalloutType.Information} title={"Installation Instructions"}>
            <TentacleCalloutHeading/>
            <div>
                <p>During setup, configure the Tentacle in <strong>listening</strong> mode, and ensure the
                    Tentacle is configured to trust an Octopus Server with the thumbprint.</p>
                <Note>
                    Learn more about <ExternalLink href="ListeningTentacle">configuring
                    Listening Tentacles</ExternalLink>.
                </Note>
                <TentacleDownloadButton className={styles.downloadButton}/>
                {thumbprint}
            </div>

        </Callout>
    </WarningFormSection>
);

export default ListeningTentacleCallout;