import * as React from "react";
import BaseComponent from "components/BaseComponent";
import {client, session} from "clientInstance";
import {
    EventCategoryResource,
    EventGroupResource,
    DocumentTypeResource,
    EnvironmentResource,
    ProjectResource,
    TenantResource,
    UserResource,
    Permission,
    EventAgentResource,
    SpaceResource
} from "client/resources";
import {
    EnvironmentMultiSelect,
    EventCategoryMultiSelect,
    EventGroupMultiSelect,
    DocumentTypeMultiSelect,
    ProjectMultiSelect,
    RoleMultiSelect,
    TenantMultiSelect,
    UserMultiSelect,
    TenantTagMultiSelect,
    MultiSelect,
    SpaceMultiSelect
} from "components/MultiSelect";
import Callout, { CalloutType } from "components/Callout/Callout";
import { filter, flatten, map, uniq, isEmpty } from "lodash";
import buildValueList from "components/EventFilter/buildValueList";
import {EventCategoryPreviewChip} from "components/Chips/index";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import { PermissionCheck } from "components/PermissionCheck";
import { FeatureToggle, Feature } from "components/FeatureToggle";
import EventAgentMultiSelect from "components/MultiSelect/EventAgentMultiSelect";
import Checkbox from "../form/Checkbox/Checkbox";

export interface EventFilterSelections {
    Environments: string[];
    Users: string[];
    Projects: string[];
    EventCategories: string[];
    EventGroups: string[];
    EventAgents: string[];
    DocumentTypes: string[];
    Tenants: string[];
    Tags: string[];
}

export interface EventFilterProps {
    doBusyTask: DoBusyTask;
    // Supply the collections of resources for each filter you wish to support
    eventCategories: EventCategoryResource[];
    eventGroups: EventGroupResource[];
    eventAgents?: EventAgentResource[];
    environments: EnvironmentResource[];
    spaces?: SpaceResource[];
    //  (leave it undefined and it will be hidden).
    documentTypes?: DocumentTypeResource[];
    projects?: ProjectResource[];
    roles?: string[];
    tenants?: TenantResource[];
    users?: UserResource[];
    includeSystem?: boolean;

    // Supply the selected values for each filter.
    selectedEventCategories: string[];
    selectedEventGroups: string[];
    selectedEventAgents?: string[];
    selectedEnvironments: string[];
    selectedSpaces?: string[];
    selectedDocumentTypes?: string[];
    selectedProjects?: string[];
    selectedRoles?: string[];
    canAddRoles?: boolean;
    selectedTenants?: string[];
    selectedTenantTags?: string[];
    selectedUsers?: string[];

    // Supply an onChange event for each filter.
    onChangeEventCategories(newValue: string[]): void;
    onChangeEventGroups(newValue: string[]): void;
    onChangeEventAgents?(newValue: string[]): void;
    onChangeEnvironments(newValue: string[]): void;
    onChangeSpaces?(newValue: string[]): void;
    onChangeDocumentTypes?(newValue: string[]): void;
    onChangeProjects?(newValue: string[]): void;
    onChangeRoles?(newValue: string[]): void;
    onChangeTenants?(newValue: string[]): void;
    onChangeTenantTags?(newValue: string[]): void;
    onChangeUsers?(newValue: string[]): void;
    onIncludeSystemChange?(newValue: boolean): void;
}

export class EventFilter extends BaseComponent<EventFilterProps, {}> {

    render() {

        const hasSystemEventView = session.currentPermissions.scopeToSystem().hasPermissionInAnyScope(Permission.EventView);

        return (
            <div>
                 {hasSystemEventView && this.props.includeSystem !== undefined && <Checkbox
                                            value={this.props.includeSystem}
                                            onChange={this.props.onIncludeSystemChange}
                                            label="Include system events"
                                        />}
                {this.spaceSelector()}
                <div>
                    <EventGroupMultiSelect
                        items={this.props.eventGroups}
                        onChange={this.props.onChangeEventGroups}
                        value={this.props.selectedEventGroups} />
                    {!isEmpty(this.props.selectedEventGroups) &&
                    <span>Events included:</span>}
                    {buildValueList(this.createEventGroupCategories(this.props.selectedEventGroups))}
                </div>

                <EventCategoryMultiSelect
                    items={this.props.eventCategories}
                    onChange={this.props.onChangeEventCategories}
                    value={this.props.selectedEventCategories} />

                {this.props.documentTypes && this.props.onChangeDocumentTypes && this.props.selectedDocumentTypes &&
                <DocumentTypeMultiSelect
                    items={this.props.documentTypes}
                    onChange={this.props.onChangeDocumentTypes}
                    value={this.props.selectedDocumentTypes} />}

                {this.props.users && this.props.users.length > 0 && this.props.onChangeUsers && this.props.selectedUsers &&
                <UserMultiSelect
                    items={this.props.users}
                    onChange={this.props.onChangeUsers}
                    value={this.props.selectedUsers} />}

                {this.props.eventAgents && this.props.onChangeEventAgents && this.props.selectedEventAgents &&
                    <EventAgentMultiSelect
                        items={this.props.eventAgents}
                        onChange={this.props.onChangeEventAgents}
                        value={this.props.selectedEventAgents} />}

                {this.spaceSpecificSelectors()}
            </div>
        );
    }

    private spaceSpecificSelectors = () => {
        // These are linked to access in the current space, because that's where the data will come from
        // we need to revisit how these will work going forward to make the filtering easier to do cross-space
        const isWithinASpace = client.spaceId;

        return isWithinASpace && [
            this.props.projects && this.props.onChangeProjects && this.props.selectedProjects &&
            <ProjectMultiSelect
                items={this.props.projects}
                onChange={this.props.onChangeProjects}
                value={this.props.selectedProjects} />,

            <EnvironmentMultiSelect
                items={this.props.environments}
                onChange={this.props.onChangeEnvironments}
                value={this.props.selectedEnvironments} />,

            this.props.roles && this.props.onChangeRoles && this.props.selectedRoles &&
            <RoleMultiSelect
                items={this.props.roles}
                onChange={this.props.onChangeRoles}
                value={this.props.selectedRoles}
                canAdd={!!this.props.canAddRoles} />,

            <FeatureToggle feature={Feature.MultiTenancy}>
                <PermissionCheck permission={Permission.TenantView} tenant="*">
                    {this.props.tenants &&
                    this.props.onChangeTenants &&
                    this.props.selectedTenants && <TenantMultiSelect
                    items={this.props.tenants}
                    onChange={this.props.onChangeTenants}
                    value={this.props.selectedTenants} />}
                    {this.props.onChangeTenantTags
                    && this.props.selectedTenantTags && <TenantTagMultiSelect
                    onChange={this.props.onChangeTenantTags}
                    doBusyTask={this.props.doBusyTask}
                    value={this.props.selectedTenantTags} />}
                </PermissionCheck>
            </FeatureToggle>];
    }

    private spaceSelector(): JSX.Element {
        const hasEventViewInAnySpace = session.currentPermissions.hasPermissionInAnyScope(Permission.EventView);

        if (!hasEventViewInAnySpace) {
            return <div style={{margin: "1rem 0 0 0"}}>
                <Callout type={CalloutType.Information}>
                    You do not have {Permission.EventView} permission in any given Space.
                </Callout>
            </div>;
        }

        const ready = this.props.spaces && this.props.spaces.length > 0;

        return ready && <SpaceMultiSelect
            items={this.props.spaces}
            onChange={this.props.onChangeSpaces}
            value={this.props.selectedSpaces} />;
    }

    private createEventGroupCategories(selectedEventGroups: string[]) {
        const selectedGroups = filter(this.props.eventGroups, (eventGroup: EventGroupResource) => {
            return selectedEventGroups.includes(eventGroup.Id);
        });
        const selectedEventCategoryIds = uniq<string>(flatten<string>(map(selectedGroups, (selectedGroup: EventGroupResource) => {
            return selectedGroup.EventCategories;
        })));
        return this.props.eventCategories
            .filter(ec => selectedEventCategoryIds.includes(ec.Id))
            .map(ec => <EventCategoryPreviewChip key={ec.Id} eventCategory={ec} />);
    }
}