const getCurrentValue =  (id: string): string => {
    return localStorage.getItem(`NotificationItemValue-${id}`);
};

const setCurrentValue = (id: string, value: string = new Date() + "") => {
    localStorage.setItem(`NotificationItemValue-${id}`, value);
};

const clearValue = (id: string): void => {
    localStorage.removeItem(`NotificationItemValue-${id}`);
};

export {getCurrentValue, setCurrentValue, clearValue};