import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import {
    SummaryResource,
    MachineModelHealthStatus,
} from "client/resources";
import InternalLink from "components/Navigation/InternalLink";
const styles = require("./style.less");
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import MachineIconHelper from "utils/MachineIconHelper";
import BusyIndicator from "components/BusyIndicator";
import { Section } from "components/Section/Section";

interface HealthStatusCardProps {
    title: string;
    summary: SummaryResource;
    machineIconHelper: MachineIconHelper;
    link(obj: object): string;
}

type HealthStatusCardState = DataBaseComponentState;

export default class HealthStatusCard extends DataBaseComponent<HealthStatusCardProps, HealthStatusCardState> {
    constructor(props: HealthStatusCardProps) {
        super(props);
        this.state = ({
        });
    }

    render() {
        if (!this.props.summary) {
            return this.props.summary === undefined ? null : <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>{this.props.title}</div>
                </div>
                <Section><BusyIndicator inline={true} show={true} /></Section>
            </div>;
        }

        const healthStatusSummaries = this.props.summary.MachineHealthStatusSummaries;
        const healthStatusRows = Object.keys(healthStatusSummaries).map(k => {
            const totalMachines = (healthStatusSummaries as any)[k] || 0;
            if (!totalMachines || totalMachines === 0) {
                return null;
            }
            const healthStatus = k as MachineModelHealthStatus;
            const healthStatusName = MachineHealthStatusHelper.getFriendlyName(healthStatus);
            const healthStatusIcon = this.props.machineIconHelper.healthStatusIcons[healthStatus.toString()];
            const healthStatusIconImage = healthStatusIcon && <img src={healthStatusIcon} className={styles.healthStatusIcon} alt="Health status" />;
            return <div className={styles.healthStatusRowsContainer} key={healthStatus}>
                <div className={styles.healthStatusIcon}>{healthStatusIconImage}</div>
                <div className={styles.healthStatusName}>
                    <InternalLink to={this.props.link({healthStatuses: healthStatus})}>
                        {healthStatusName}
                    </InternalLink>
                </div>
                <div className={styles.healthStatusMachinesCount}>{totalMachines.toLocaleString()}</div>
            </div>;
        });

        const disabledComponentKey = "Disabled";
        const totalDisabledMachines = this.props.summary.TotalDisabledMachines;
        const disabledIcon = this.props.machineIconHelper.healthStatusIcons["Disabled"];
        const disabledIconImage = disabledIcon && <img src={disabledIcon} className={styles.healthStatusIcon} alt="Disabled" />;
        const disabledRow = <div className={styles.healthStatusRowsContainer} key={disabledComponentKey}>
            <div className={styles.healthStatusIcon}>{disabledIconImage}</div>
            <div className={styles.healthStatusName}>
                <InternalLink to={this.props.link({isDisabled: "true"})}>
                    Disabled
                </InternalLink>
            </div>
            <div className={styles.healthStatusMachinesCount}>{totalDisabledMachines.toLocaleString()}</div>
        </div>;

        return <div className={styles.card}>
            <div className={styles.cardTitleContainer}>
                <div className={styles.cardTitle}>{this.props.title}</div>
            </div>
            <div className={styles.cardContentContainer}>
                {healthStatusRows}
                {disabledRow}
            </div>
        </div>;
    }
}
