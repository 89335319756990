import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import NodeJsCategory from "../../Definitions/NodeJsCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import { CommonManualPackagingTextForNuGet, CommonManualPackagingTextForZip } from "../CommonRegistrationText";
import ExternalLink from "components/Navigation/ExternalLink";

const ManualPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications, we recommend using <ExternalLink href="ExternalToolOctopackJs">Octopack.js</ExternalLink>.
        </p>
        <p>
            However, if you wish to package your Node.js applications manually (on your build output),
            creating <i>NuGet</i> or <i>Zip</i> packages yourself is an easy way to get started.
        </p>
        {CommonManualPackagingTextForNuGet}
        {CommonManualPackagingTextForZip}
    </div>
);

export const ManualPackagingRegistrationForNodeJs: CategorizedPackagingRegistration = {
    displayOrder: 100,
    categories: [NodeJsCategory],
    name: "Manually",
    type: "ManualForNodeJs",
    renderCard: InfoRegistrationCards.basic(
        () => <ManualPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};