import {
    TriggerFilterResource,
    TriggerActionResource,
    TriggerFilterType,
    TriggerActionType
} from "./triggerResource";

export abstract class TriggerScheduleResource extends TriggerFilterResource {
    Timezone: string;
}

export enum TriggerScheduleIntervalType {
    OnceDaily = "OnceDaily",
    OnceHourly = "OnceHourly",
    OnceEveryMinute = "OnceEveryMinute"
}

export enum TriggerScheduleRunType {
    ScheduledTime = "ScheduledTime",
    Continuously = "Continuously"
}

export class DailyTriggerScheduleResource extends TriggerScheduleResource {
    StartTime?: Date;
    RunType: TriggerScheduleRunType;
    Interval: TriggerScheduleIntervalType = TriggerScheduleIntervalType.OnceDaily;
    HourInterval?: number;
    MinuteInterval?: number;

    constructor() {
        super();
        this.FilterType = TriggerFilterType.DailySchedule;
    }
}

export class TriggerScheduleIntervalResource {
    Interval: TriggerScheduleIntervalType = TriggerScheduleIntervalType.OnceDaily;
    HourInterval?: number;
    MinuteInterval?: number;
}

export enum MonthlyScheduleType {
    DateOfMonth = "DateOfMonth",
    DayOfMonth = "DayOfMonth"
}

export enum DayOfWeek {
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
    Sunday = "Sunday",
}

export class DaysPerMonthTriggerScheduleResource extends TriggerScheduleResource {
    StartTime: Date;
    MonthlyScheduleType: MonthlyScheduleType;
    DateOfMonth?: string;
    DayNumberOfMonth?: string;
    DayOfWeek: DayOfWeek;

    constructor() {
        super();
        this.FilterType = TriggerFilterType.DaysPerMonthSchedule;
    }
}

export class DaysPerWeekTriggerScheduleResource extends DailyTriggerScheduleResource {
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
    Sunday: boolean;

    constructor() {
        super();
        this.FilterType = TriggerFilterType.DaysPerWeekSchedule;
    }
}

export class CronTriggerScheduleResource extends TriggerScheduleResource {
    CronExpression: string;

    constructor() {
        super();
        this.FilterType = TriggerFilterType.CronExpressionSchedule;
    }
}

export abstract class ScopedDeploymentActionResource extends TriggerActionResource {
    ChannelId: string;
    TenantIds: string[] = [];
    TenantTags: string[] = [];
    Variables: string;
}

export class DeployLatestReleaseActionResource extends ScopedDeploymentActionResource {
    SourceEnvironmentId: string;
    DestinationEnvironmentId: string;

    constructor() {
        super();
        this.ActionType = TriggerActionType.DeployLatestRelease;
    }
}

export class DeployNewReleaseActionResource extends ScopedDeploymentActionResource {
    EnvironmentId: string;

    constructor() {
        super();
        this.ActionType = TriggerActionType.DeployNewRelease;
    }
}

export class ScheduleIntervalResource {
    IntervalType: ScheduleIntervalType;
    IntervalValue: number;
}

export enum ScheduleIntervalType {
    Once = "Once",
    Hour = "Hour",
    Minute = "Minute"
}
