import * as React from "react";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { repository, session } from "clientInstance";
import { required, Text } from "components/form";
import { DataBaseComponentState, DataBaseComponent } from "components/DataBaseComponent";
import routeLinks from "../../../routeLinks";
import { connect, MapStateToProps, MapDispatchToProps } from "react-redux";
import { Dispatch, Action } from "redux";
import GlobalState from "globalState";
import { bindActionCreators } from "redux";
import { MultiTenancyStatusResource } from "client/resources";
import InternalRedirect from "../../../components/Navigation/InternalRedirect/InternalRedirect";
import { configurationActions } from "../../configuration/reducers/configurationArea";

interface ConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface DispatchProps {
    onSpaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource) => void;
}

interface AddTenantDialogState extends DataBaseComponentState {
    name: string;
    newTenantId: string;
}

class AddTenantDialog extends DataBaseComponent<ConnectedProps & DispatchProps, AddTenantDialogState> {
    constructor(props: ConnectedProps & DispatchProps) {
        super(props);
        this.state = {
            name: "",
            newTenantId: ""
        };
    }

    save = async () => {
        return this.doBusyTask(async () => {
            const result = await repository.Tenants.create({
                Id: null,
                Name: this.state.name,
                TenantTags: [],
                ProjectEnvironments: {},
                Links: null
            }, {});

            this.setState({ newTenantId: result.Id });

            if (!this.props.isMultiTenancyEnabled) {
                const status = await repository.Tenants.status();
                this.props.onSpaceMultiTenancyStatusFetched(status);
            }
        });
    }

    render() {
        if (this.state.newTenantId) {
            return <InternalRedirect to={routeLinks.tenant(this.state.newTenantId).root} />;
        }

        return <SaveDialogLayout
            title="Add New Tenant"
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={this.save}>
            <Text
                label="New tenant name"
                value={this.state.name}
                onChange={value => this.setState({ name: value })}
                validate={required("Please enter a tenant name")}
                autoFocus={true}
            />
        </SaveDialogLayout>;
    }
}

const mapStateToProps = (state: GlobalState): ConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): DispatchProps =>
    bindActionCreators({ onSpaceMultiTenancyStatusFetched: configurationActions.spaceMultiTenancyStatusFetched }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddTenantDialog);