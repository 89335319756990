import * as React from "react";
import * as moment from "moment";
import { Moment } from "moment";
const styles = require("./style.less");
import { Text } from "components/form";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import DateRangeIcon from "material-ui/svg-icons/action/date-range";
import { Calendar } from "react-date-range";
import combineDateTime from "../combineDateTime";
import {
    white,
    primary,
    primaryDark,
    divider,
} from "colors";
import { Errors } from "components/DataBaseComponent";

interface DatePickerProps {
    date: Moment;
    onDateChanged(date: Moment): void;
}

interface DatePickerState {
    dateText: string;
    datePopupValue: Moment;
    busy?: Promise<void>;
    errors?: Errors;
}

const emptyError = "\t"; // this displays error state in controls but no error text is visible

export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props: DatePickerProps) {
        super(props);
        this.state = {
            dateText: props.date.format("YYYY-MM-DD"),
            datePopupValue: moment(props.date)
        };
    }

    componentWillReceiveProps(nextProps: DatePickerProps) {
        this.setState({
            dateText: nextProps.date.format("YYYY-MM-DD"),
            datePopupValue: moment(nextProps.date)
        });
    }

    handleDateChanged = (date: Moment) => {
        // don't change the time component
        const combined = combineDateTime(date, this.props.date);
        this.props.onDateChanged(date);
    }

    isValidDateString = (date: string) => {
        return date.length === 10 && moment(date, "YYYY-MM-DD").isValid();
    }

    render() {
        return <div className={styles.datePicker}>
            <Text value={this.state.dateText}
                validate={val => this.isValidDateString(val) ? "" : emptyError}
                onChange={value => {
                    this.setState({ dateText: value });
                    if (this.isValidDateString(value)) {
                        this.handleDateChanged(moment(value, "YYYY-MM-DD"));
                    }
                }} />
            <div className={styles.button}>
                <OpenDialogButton
                    icon={<DateRangeIcon />}
                    label={""}>
                    <SaveDialogLayout title="Date"
                        busy={this.state.busy}
                        errors={this.state.errors}
                        onSaveClick={async () => {
                            this.handleDateChanged(this.state.datePopupValue);
                            return true;
                        }}>
                        <Calendar
                            date={this.state.datePopupValue}
                            onChange={(value: Moment) => this.setState({ datePopupValue: value })}
                            theme={this.getCalendarTheme()}
                        /></SaveDialogLayout>
                </OpenDialogButton>
            </div>
        </div>;
    }

    private getCalendarTheme() {
        return {
            DateRange: {
                background: white,
            },
            PredefinedRangesItem: {
                background: white,
            },
            PredefinedRangesItemActive: {
                color: primaryDark,
            },
            MonthButton: {
                background: divider
            },
            MonthArrowPrev: {
                borderRightColor: primaryDark,
            },
            MonthArrowNext: {
                borderLeftColor: primaryDark,
            },
            DaySelected: {
                background: primaryDark
            },
            DayActive: {
                background: primary,
                boxShadow: "none"
            },
            DayInRange: {
                background: primary,
                color: white
            },
            DayHover: {
                background: white,
                color: primaryDark,
            },
            /*
            TODO: mark.siedle - A PR has been submitted with the author of this component @ https://github.com/Adphorus/react-date-range/pull/170.
            Once this has been merged we can remove this width hack and re-test in Firefox.
            */
            Weekday: {
                width: "2.313rem",
            },
            Day: {
                width: "2.313rem",
            },
        };
    }
}