import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import JavaCategory from "../../Definitions/JavaCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import { CommonCommandLineTextForOctoExe, CommonCommandLineTextForOctoExeCodeSample, CommonTextForJavaSample } from "../CommonRegistrationText";

const CommandLinePackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Java applications from command line, we recommend using <i>Octo.exe</i>.
        </p>
        {CommonTextForJavaSample}
        {CommonCommandLineTextForOctoExe}
        {CommonCommandLineTextForOctoExeCodeSample}
    </div>
);

export const CommandLinePackagingRegistrationForJava: CategorizedPackagingRegistration = {
    displayOrder: 200,
    categories: [JavaCategory],
    name: "Command Line",
    type: "CommandLineForJava",
    renderCard: InfoRegistrationCards.basic(
        () => <CommandLinePackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};