import * as React from "react";
import store from "store";
import {raiseUnhandledErrorAction, } from "./reducers";
import { clearUnhandledErrorAction } from "../StandardLayout/reducers";
import Logger from "client/logger";

class BaseComponent<Props, State> extends React.Component<Props, State> {
    private static methodsToCapture = ["componentDidMount", "componentWillMount", "componentDidUpdate", "componentWillUnmount",
        "shouldComponentUpdate", "componentWillUpdate", "componentWillReceiveProps"];

    protected unmounted = false;

    constructor(props: Props) {
        super(props);

        this.provideErrorHandlingToTopLevelMethods();
        this.addUnmountedHook();
    }

    // tslint:disable:unified-signatures
    setState<K extends keyof State>(f: (prevState: State, props: Props) => Pick<State, K>, callback?: () => any): void;
    setState<K extends keyof State>(state: Pick<State, K>, callback?: () => any): void;
    // tslint:enable:unified-signatures
    setState<K extends keyof State>(m: ((prevState: State, props: Props) => Pick<State, K> | Pick<State, K>) | Pick<State, K>, callback?: () => any): void {
        if (this.unmounted) {
            return;
        }

        super.setState(m, callback);
    }

    protected setChildState1<
        KeyOfState extends keyof State,
        Child extends State[KeyOfState],
        KeyOfChild extends keyof Child>(first: KeyOfState, state: Pick<Child, KeyOfChild>, callback?: () => void) {

        this.setState(prev => ({
            [first as any]: {...prev[first] as any, ...state as object}
        }), callback);
    }

    protected setChildState2<
        KeyOfState extends keyof State,
        Child extends State[KeyOfState],
        KeyOfChild extends keyof Child,
        GrandChild extends Child[KeyOfChild],
        KeyOfGrandChild extends keyof GrandChild>(first: KeyOfState, second: KeyOfChild, state: Pick<GrandChild, KeyOfGrandChild>, callback?: () => void) {

        this.setState(prev => ({
            [first as any]: {
                ...prev[first] as any,
                [second as any]: {
                    ...(prev[first] as any)[second],
                    ...state as object
                }
            }
        }), callback);
    }

    protected setChildState3<KeyOfState extends keyof State,
        Child extends State[KeyOfState],
        KeyOfChild extends keyof Child,
        GrandChild extends Child[KeyOfChild],
        KeyOfGrandChild extends keyof GrandChild,
        GreatGrandChild extends GrandChild[KeyOfGrandChild],
        KeyOfGreatGrandChild extends keyof GreatGrandChild>(
        first: KeyOfState, second: KeyOfChild, third: KeyOfGrandChild, state: Pick<GreatGrandChild, KeyOfGreatGrandChild>, callback?: () => void) {

        this.setState(prev => ({
            [first as any]: {
                ...prev[first] as any,
                [second as any]: {
                    ...(prev[first] as any)[second],
                    [third as any]: {
                        ...(prev[first] as any)[second][third],
                        ...state as object
                    }
                }
            }
        }), callback);
    }

    protected setChildState4<KeyOfState extends keyof State,
        Child extends State[KeyOfState],
        KeyOfChild extends keyof Child,
        GrandChild extends Child[KeyOfChild],
        KeyOfGrandChild extends keyof GrandChild,
        GreatGrandChild extends GrandChild[KeyOfGrandChild],
        KeyOfGreatGrandChild extends keyof GreatGrandChild,
        GreatGreatGrandChild extends GreatGrandChild[KeyOfGreatGrandChild],
        KeyOfGreatGreatGrandChild extends keyof GreatGreatGrandChild>(
        first: KeyOfState, second: KeyOfChild, third: KeyOfGrandChild, fourth: KeyOfGreatGrandChild, state: Pick<GreatGreatGrandChild, KeyOfGreatGreatGrandChild>, callback?: () => void) {

        this.setState(prev => ({
            [first as any]: {
                ...prev[first] as any,
                [second as any]: {
                    ...(prev[first] as any)[second],
                    [third as any]: {
                        ...(prev[first] as any)[second][third],
                        [fourth as any]: {
                            ...(prev[first] as any)[second][third][fourth],
                            ...state as object
                        }
                    }
                }
            }
        }), callback);
    }

    protected provideErrorHandling(func: (...args: any[]) => any) {
        const name = this.findNameOf(func);
        this.provideErrorHandlingByName(name);
    }

    protected clearError() {
        store.dispatch(clearUnhandledErrorAction());
    }

    private provideErrorHandlingByName(name: string) {
        const originalMethod = (this as any)[name].bind(this);
        const wrapper =  (...args: any[]) => {
            try {
                const result = originalMethod(...args);
                if (result instanceof Promise) {
                    return result.catch(error => {
                        this.handleError(error, name);
                    });
                } else {
                    return result;
                }
            } catch (error) {
                this.handleError(error, name);
                throw error;
            }
        };

        (this as any)[name] = wrapper;
    }

    private handleError(error: any, name: string) {
        Logger.error("Error occurred in " + name);
        Logger.error(error);
        store.dispatch(raiseUnhandledErrorAction(error));
        throw error;
    }

    private addUnmountedHook() {
        const componentWillUnmount = this["componentWillUnmount"];
        const originalMethod = componentWillUnmount && componentWillUnmount.bind(this);
        this["componentWillUnmount"] = async (...args: any[]) => {
            this.unmounted = true;
            if (originalMethod) {
                return await originalMethod(...args);
            }
        };
    }

    private findNameOf(func: (...args: any[]) => Promise<any>): string {
        return Object.getOwnPropertyNames(this).filter(name => (this as any)[name] === func)[0];
    }

    private provideErrorHandlingToTopLevelMethods() {
        Object.getOwnPropertyNames(Object.getPrototypeOf(this)).forEach(name => {
            if (BaseComponent.methodsToCapture.includes(name)) {
                this.provideErrorHandlingByName(name);
            }
        });
    }
}

export {BaseComponent};