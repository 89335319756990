import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import {
    ActionTemplateParameterResource,
    ActionUpdateOutcome,
    ActionUpdatePackageUsedBy,
    ActionUpdateRemovedPackageUsage
} from "../../../../client/resources";
import TabContainer from "components/Tabs/TabContainer";
import InternalLink from "components/Navigation/InternalLink";
import SimpleDataTable from "components/SimpleDataTable";
import * as React from "react";
import * as cn from "classnames";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {ActionTemplateResource, ActionTemplateUsageResource, ActionUpdateResultResource} from "client/resources";
import {groupBy, keyBy, uniq} from "lodash";
import TabItem from "components/Tabs/TabItem";
import {repository} from "clientInstance";
import routeLinks from "../../../../routeLinks";
import ActionTemplateParameterInputExpandableFormElement from "../../../../components/ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
const styles = require("./style.less");

type Tab = "auto" | "defaults" | "manual" | "packages";

type Default = ActionTemplateUsageResource & { NamesOfNewParametersMissingDefaultValue: string[] };
type Manual = ActionTemplateUsageResource & { ManualMergeRequiredReasonsByPropertyName: any };
type Packages = ActionTemplateUsageResource & { RemovedPackageUsages: ActionUpdateRemovedPackageUsage[]};

interface MergeConflictResolutionDialogState extends DataBaseComponentState {
    showAutoDetails: boolean;
    showDefaultsDetails: boolean;
    showManualDetails: boolean;
    showPackagesDetails: boolean;
    auto: ActionTemplateUsageResource[];
    defaults: Default[];
    manual: Manual[];
    packages: Packages[];
    defaultPropertyValues: any;
    appliesToSingleStep: boolean;
    newParametersMissingDefaultValue: ActionTemplateParameterResource[];
    tab: Tab;
}

interface MergeConflictResolutionDialogProps {
    usages: ActionTemplateUsageResource[];
    mergeResults: ActionUpdateResultResource[];
    actionTemplate: ActionTemplateResource;
}

export default class MergeConflictResolutionDialog extends DataBaseComponent<MergeConflictResolutionDialogProps, MergeConflictResolutionDialogState> {

    constructor(props: MergeConflictResolutionDialogProps) {
        super(props);

        const mergeResultsByActionId = keyBy(props.mergeResults, "Id");
        const auto = props.usages.filter(usage => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.Success);
        const defaults = props.usages.filter(usage => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.DefaultParamterValueMissing)
            .map(usage => {
                return {
                    ...usage,
                    NamesOfNewParametersMissingDefaultValue: mergeResultsByActionId[usage.ActionId].NamesOfNewParametersMissingDefaultValue
                };
            });

        const namesOfNewParameterMissingDefaultValue = uniq<string>([].concat.apply([], defaults.map(d => d.NamesOfNewParametersMissingDefaultValue)));
        const newParametersMissingDefaultValue = props.actionTemplate.Parameters.filter(p => namesOfNewParameterMissingDefaultValue.indexOf(p.Name) !== -1);
        const defaultPropertyValues = namesOfNewParameterMissingDefaultValue.reduce((acc: any, name) => {
            acc[name] = null;
            return acc;
        }, {});

        const manual = props.usages.filter(usage => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.ManualMergeRequired)
            .map(usage => {
                return {
                    ...usage,
                    ManualMergeRequiredReasonsByPropertyName: mergeResultsByActionId[usage.ActionId].ManualMergeRequiredReasonsByPropertyName
                };
            });

        const packages = props.usages.filter(usage => mergeResultsByActionId[usage.ActionId].Outcome === ActionUpdateOutcome.RemovedPackageInUse)
            .map(usage => {
                return {
                    ...usage,
                    RemovedPackageUsages: mergeResultsByActionId[usage.ActionId].RemovedPackageUsages
                };
            });

        const appliesToSingleStep = auto.length + manual.length + defaults.length === 1;
        const tab = auto.length ? "auto" : (defaults.length ? "defaults" : (manual.length ? "manual" : "packages"));

        this.state = {
            showAutoDetails: false,
            showDefaultsDetails: false,
            showManualDetails: false,
            showPackagesDetails: false,
            auto,
            defaults,
            manual,
            packages,
            defaultPropertyValues,
            appliesToSingleStep,
            newParametersMissingDefaultValue,
            tab
        };
    }

    render() {
        return (
            <SaveDialogLayout
                onSaveClick={this.onSave}
                hideSave={this.state.tab === "manual" || this.state.tab === "packages"}
                title={this.state.appliesToSingleStep ? "Update step" : "Update all projects"}
                saveButtonLabel={this.state.tab === "auto" ? "Update steps automatically" : "Update steps using default values"}
                errors={this.state.errors}
                busy={this.state.busy}>
                <div>
                    See <ExternalLink href="StepTemplateUpdate">our documentation</ExternalLink> for more information regarding possible reasons
                    why the update couldn't be done automatically.
                </div>
                {this.renderTabs()}
                {this.renderAuto()}
                {this.renderDefaults()}
                {this.renderManual()}
                {this.renderPackages()}
            </SaveDialogLayout >);
    }

    private renderTabs() {
        return (
            <ul className={styles.mergeTabs}>
                {this.renderAutoTab()}
                {this.renderDefaultsTab()}
                {this.renderManualTab()}
                {this.renderPackagesTab()}
            </ul>
        );
    }

    private renderAutoTab() {
        const autoLength = this.state.auto.length;
        if (autoLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "auto" })}>
                <div className={cn(styles.navTabs, styles.green)}>
                    <p>
                        <strong>{autoLength} {this.step(autoLength)}</strong><br />
                        {this.have(autoLength)} all the information to update automatically.
                        </p>
                    <a href="#" className={styles.showDetails} onClick={e => this.openTab(e, "auto")}>View details</a>
                </div>
            </li>
        );
    }

    private renderDefaultsTab() {
        const defaultsLength = this.state.defaults.length;
        if (defaultsLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "defaults" })}>
                <div className={styles.navTabs}>
                    <p>
                        <strong>{defaultsLength} {this.step(defaultsLength)}</strong><br />
                        {this.have(defaultsLength)} default values to be updated automatically.
                        </p>
                    <a href="#" className={styles.showDetails} onClick={e => this.openTab(e, "defaults")}>View parameters and steps</a>
                </div>
            </li>
        );
    }

    private renderManualTab() {
        const manualLength = this.state.manual.length;
        if (manualLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "manual" })}>
                <div className={styles.navTabs}>
                    <p>
                        <strong>{manualLength} {this.step(manualLength)}</strong><br />
                        {this.have(manualLength)} more detail and must be updated manually.
                        </p>
                    <a href="#" className={styles.showDetails} onClick={e => this.openTab(e, "manual")}>View steps</a>
                </div>
            </li>
        );
    }

    private renderPackagesTab() {
        const packagesLength = this.state.packages.length;
        if (packagesLength === 0) {
            return null;
        }
        return (
            <li className={cn({ [styles.active]: this.state.tab === "packages" })}>
                <div className={styles.navTabs}>
                    <p>
                        <strong>{packagesLength} {this.step(packagesLength)}</strong><br />
                        {this.is(packagesLength)} in a project which reference a package which has been removed from the template.
                    </p>
                    <a href="#" className={styles.showDetails} onClick={e => this.openTab(e, "packages")}>View steps</a>
                </div>
            </li>
        );
    }

    private renderAuto() {
        if (this.state.tab !== "auto") {
            return null;
        }

        return (
            <SimpleDataTable
                data={this.state.auto}
                headerColumns={["Project", "Step", "Version"]}
                onRow={(u: ActionTemplateUsageResource) => [
                    <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>{u.ProjectName}</InternalLink>,
                    <InternalLink to={routeLinks.project(u.ProjectSlug).process.step(u.ActionId)} openInSelf={false}>{u.ActionName}</InternalLink>,
                    u.Version
                ]}
            />);
    }

    private renderDefaults() {
        if (this.state.tab !== "defaults") {
            return null;
        }

        return <div>
            <p>
                Please provide default values for the following parameters. Default values will be used to update all steps automatically. If you wish
                to provide different values for each step, please update each step manually. Empty values are accepted as default values.
            </p>
            <TabContainer defaultValue="parameters">
                <TabItem label="Parameters" value="parameters">
                    <ExpansionButtons />
                    {this.state.newParametersMissingDefaultValue.map(param => {
                        return <ActionTemplateParameterInputExpandableFormElement
                            parameter={param}
                            key={param.Name}
                            sourceItems={{}}
                            doBusyTask={this.doBusyTask}
                            value={this.state.defaultPropertyValues[param.Name]}
                            onChange={(x) => this.setState(state => ({defaultPropertyValues: {...state.defaultPropertyValues, [param.Name]: x}}))}
                            customHelpText={(label) => `Provide a default value for ${label}`}
                        />;
                    })}
                </TabItem>
                <TabItem label="Steps" value="steps">
                    <SimpleDataTable
                        data={this.state.defaults}
                        headerColumns={["Project", "Step", "Parameters", "Version"]}
                        onRow={(u: Default) => [
                            <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>{u.ProjectName}</InternalLink>,
                            <InternalLink to={routeLinks.project(u.ProjectSlug).process.step(u.ActionId)} openInSelf={false}>{u.ActionName}</InternalLink>,
                            <ul className={styles.reasons}>
                                {u.NamesOfNewParametersMissingDefaultValue.map((nameOfNewParameterMissingDefaultValue, index) =>
                                    <li key={`defaultValue-${index}`}>nameOfNewParameterMissingDefaultValue</li>)
                                }
                            </ul>,
                            u.Version
                        ]}
                    />
                </TabItem>
            </TabContainer>
        </div>;
    }

    private renderPackages() {
        if (this.state.tab !== "packages") {
            return null;
        }

        return <div>
           <p>
               {this.state.packages.length} {this.step(this.state.packages.length)} {this.is(this.state.packages.length)} in a project which reference a package on the step which has been removed in the
               latest version of the step template.  These references must be removed before the step can be updated.  The details are provided below:
           </p>
            <SimpleDataTable
                data={this.state.packages}
                headerColumns={["Project", "Step", "Referenced By", "Version"]}
                onRow={(u: Packages) => [
                    <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>{u.ProjectName}</InternalLink>,
                    <InternalLink to={routeLinks.project(u.ProjectSlug).process.step(u.ActionId)} openInSelf={false}>{u.ActionName}</InternalLink>,
                    <ul className={styles.reasons}>
                        {u.RemovedPackageUsages
                            .map((pkg, index) => <li key={`packageConflicts-${index}`}>{this.renderRemovedPackageUsageReason(pkg)}</li>)}
                    </ul>,
                    u.Version
                ]}
            />
        </div>;
    }

    private renderManual() {
        if (this.state.tab !== "manual") {
            return null;
        }

        return <div>
            <p>
                {this.state.manual.length} {this.step(this.state.manual.length)} must be updated manually because we don't have enough information to do it automatically.
                This might be a bit of work but in most cases once the update is completed we will have all required data for future updates to be automated.
            </p>
            <SimpleDataTable
                data={this.state.manual}
                headerColumns={["Project", "Step", "Reasons", "Version", ""]}
                onRow={(u: Manual) => [
                    <InternalLink to={routeLinks.project(u.ProjectSlug).root} openInSelf={false}>{u.ProjectName}</InternalLink>,
                    <InternalLink to={routeLinks.project(u.ProjectSlug).process.step(u.ActionId)} openInSelf={false}>{u.ActionName}</InternalLink>,
                    <ul className={styles.reasons}>
                        {Object.keys(u.ManualMergeRequiredReasonsByPropertyName)
                            .map((property, index) => <li key={`manualMerge-${index}`}>{property}: {u.ManualMergeRequiredReasonsByPropertyName[property].join()}</li>)}
                    </ul>,
                    u.Version,
                    <InternalLink to={routeLinks.project(u.ProjectSlug).process.step(u.ActionId)} openInSelf={false}>Update</InternalLink>,
                ]}
            />
        </div>;
    }

    private openTab = (e: any, tab: Tab) => {
        // do through doBusyTask so we trigger our dialog resize fix
        this.doBusyTask(async () => {
            e.preventDefault();
            this.setState({ tab });
        });
    }

    private onSave = async () => {
        return this.doBusyTask(async (): Promise<boolean> => {
            switch (this.state.tab) {
                case "auto": {
                    await this.updateActions(this.props.actionTemplate, this.state.auto);
                    this.setState({ auto: [], tab: this.nextTab() });
                    const allDone = this.state.manual.length === 0 && this.state.defaults.length === 0;
                    return allDone ? true : false;
                }
                case "defaults": {
                    await this.updateActions(this.props.actionTemplate, this.state.defaults, this.state.defaultPropertyValues);
                    this.setState({ defaults: [], tab: this.nextTab() });
                    const allDone = this.state.manual.length === 0 && this.state.auto.length === 0;
                    return allDone ? true : false;
                }
            }
            return false;
        });
    }

    private updateActions = async (actionTemplate: any, usages: ActionTemplateUsageResource[], defaults: any = null) => {
        const actionsByProcessId = groupBy(usages, "DeploymentProcessId");
        const actionIdsByProcessId = Object.keys(actionsByProcessId).reduce((acc: any, propertyName) => {
            acc[propertyName] = actionsByProcessId[propertyName].map(usage => usage.ActionId);
            return acc;
        }, {});

        await repository.ActionTemplates.updateActions(actionTemplate, actionIdsByProcessId, defaults);
    }

    private have = (n: number) => {
        return n === 1 ? "has" : "have";
    }

    private need = (n: number) => {
        return n === 1 ? "needs" : "need";
    }

    private step = (n: number) => {
        return n === 1 ? "step" : "steps";
    }

    private is = (n: number) => {
        return n === 1 ? "is" : "are";
    }

    private nextTab = () => {
        return this.state.auto.length ? "auto" : (this.state.defaults.length ? "defaults" : "manual");
    }

    private renderRemovedPackageUsageReason = (pkgUsage: ActionUpdateRemovedPackageUsage) => {
        switch (pkgUsage.UsedBy) {
            case ActionUpdatePackageUsedBy.ProjectVersionStrategy:
                return <React.Fragment>Versioning strategy in project <InternalLink to={routeLinks.project(pkgUsage.UsedById).settings} openInSelf={false}>{pkgUsage.UsedByName}</InternalLink></React.Fragment>;
            case ActionUpdatePackageUsedBy.ProjectReleaseCreationStrategy:
                return <React.Fragment>Release creation strategy in project <InternalLink to={routeLinks.project(pkgUsage.UsedById).triggers} openInSelf={false}>{pkgUsage.UsedByName}</InternalLink></React.Fragment>;
            case ActionUpdatePackageUsedBy.ChannelRule:
                return <React.Fragment>Rule in channel <InternalLink to={routeLinks.channel(pkgUsage.UsedById)} openInSelf={false}>{pkgUsage.UsedByName}</InternalLink></React.Fragment>;
        }
    }
}