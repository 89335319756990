exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_narrow__1UeHE {\n  max-width: 8rem;\n}\n.style_summaryMap__1oUg_ {\n  top: -0.85rem;\n}\n.style_project__2ZzKu {\n  font-size: 1rem;\n  display: flex;\n  justify-content: left;\n  align-items: center;\n}\n.style_project__2ZzKu div {\n  padding-right: .5rem;\n}\n.style_phasesEditHeader__Bj-_R {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n.style_addPhaseActionContainer__1uJkR {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"narrow": "style_narrow__1UeHE",
	"summaryMap": "style_summaryMap__1oUg_",
	"project": "style_project__2ZzKu",
	"phasesEditHeader": "style_phasesEditHeader__Bj-_R",
	"addPhaseActionContainer": "style_addPhaseActionContainer__1uJkR"
};