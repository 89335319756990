import * as React from "react";
import dialogIdGenerator from "./dialogIdGenerator";

interface SequenceProps {
    sequence: string;
}

export function withDialogIdentifier<TInner extends Partial<SequenceProps>>(name?: string) {
    return (component: React.ComponentType<TInner & SequenceProps>) => {
        class WithDialogIdentifier extends React.Component<TInner, {sequence: string}> {
            constructor(props: TInner) {
                super(props);
                const sequence = this.props.sequence || name || dialogIdGenerator.next();
                this.state = {sequence};
            }

            render() {
                const Component = component;
                return <Component sequence={this.state.sequence} {...this.props} />;
            }
        }
        return WithDialogIdentifier;
    };
}

export default withDialogIdentifier;