import * as React from "react";
import {SFC} from "react";
import {DeploymentMachineInfo, DeploymentStepsDetails} from "../Preview";
import {EnvironmentResource} from "client/resources/environmentResource";
import {TenantResource} from "client/resources/tenantResource";
import {DataTable} from "components/DataTable/DataTable";
import {DataTableHeader} from "components/DataTable/DataTableHeader";
import {DataTableRow} from "components/DataTable/DataTableRow";
import {DataTableHeaderColumn} from "components/DataTable/DataTableHeaderColumn";
import DeploymentResultItem from "./DeploymentResultItem";
import * as _ from "lodash";
import {DeploymentRequestModel} from "../deploymentRequestModel";
import Form from "client/resources/form";
import {DeploymentProcessResource} from "client/resources/deploymentProcessResource";

interface DeploymentResultListProps {
    deployments: DeploymentRequestModel[];
    stepActionIdsToSkip: string[];
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    promptVariableForm: Form;
    tenantsWithMissingVariables: string[];
    process: DeploymentProcessResource;
    deploymentsAndSteps: DeploymentStepsDetails[];
    onIncludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onExcludeSpecificMachinesSelected(deployment: DeploymentMachineInfo): void;
    onAllTargetsSelected(deployment: DeploymentMachineInfo): void;
}

const DeploymentResults: SFC<DeploymentResultListProps> = props => {

    const isTenanted = _.some(props.deployments, deployment => deployment.tenantId);

    const renderHeaderRow = () => {
        return (
            <DataTableRow>
                <DataTableHeaderColumn>
                    {isTenanted ? "Tenant" : "Environment"}
                </DataTableHeaderColumn>
                <DataTableHeaderColumn>
                    Current Version
                </DataTableHeaderColumn>
                <DataTableHeaderColumn>
                   Targets
                </DataTableHeaderColumn>
                <DataTableHeaderColumn>
                    Deployment Process
                </DataTableHeaderColumn>
                <DataTableHeaderColumn/>
                <DataTableHeaderColumn/>
            </DataTableRow>
        );
    };

    const renderDeploymentRows = () => {
        if (props.deploymentsAndSteps) {
            return props.deploymentsAndSteps.map((d) => <DeploymentResultItem
                isMissingVariable={props.tenantsWithMissingVariables.indexOf(d.deployment.tenantId) !== -1}
                key={d.deployment.tenantId ? d.deployment.tenantId : d.deployment.environmentId}
                deployment={d.deployment}
                tenant={d.deployment.tenantId ? props.tenants.find(t => t.Id === d.deployment.tenantId) : null}
                stepsForSelectedDeployment={d.stepsForSelectedDeployment}
                actions={d.actions}
                process={props.process}
                environment={props.environments.find(e => e.Id === d.deployment.environmentId)}
                {...props}
            />);
        }
    };
    return <DataTable>
        <DataTableHeader>
            {renderHeaderRow()}
        </DataTableHeader>
        {renderDeploymentRows()}
    </DataTable>;
};
DeploymentResults.displayName = "DeploymentResults";

export default DeploymentResults;