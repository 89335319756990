import * as React from "react";
import * as _ from "lodash";
import { repository } from "clientInstance";
import {
    ProjectResource,
    ChannelResource,
    ResourceCollection,
    DeploymentProcessResource,
    LifecycleResource
} from "client/resources";
import { ProjectRouteParams } from "../ProjectLayout";
import List from "components/List";
import { NavigationButton, NavigationButtonType } from "components/Button";
import PaperLayout from "components/PaperLayout/index";
import { RouteComponentProps } from "react-router";
import Channel from "./Channel";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import ExternalLink from "components/Navigation/ExternalLink";
import SectionNote from "components/SectionNote/SectionNote";

interface ChannelsState extends DataBaseComponentState {
    project: ProjectResource;
    channelsResponse: ResourceCollection<ChannelResource>;
    deploymentProcesses: DeploymentProcessResource;
    lifecycles: Lifecycles;
    tagIndex: TagIndex;
}

export interface Lifecycles {
    [name: string]: LifecycleResource;
}

class ChannelsList extends List<ChannelResource> { }

export class Channels extends DataBaseComponent<RouteComponentProps<ProjectRouteParams>, ChannelsState> {
    private match: any = null;

    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.match = this.props.match;
        this.state = ({
            project: null,
            channelsResponse: null,
            deploymentProcesses: null,
            lifecycles: {},
            tagIndex: null
        });
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);

            const [channelsResponse, deploymentProcesses, lifecycleResources, tagIndex, octopusProjectFeed] =
                await Promise.all([
                    repository.Projects.getChannels(project, 0, 30),
                    repository.DeploymentProcesses.get(project.DeploymentProcessId),
                    repository.Lifecycles.all(),
                    tenantTagsets.getTagIndex(),
                    repository.Feeds.getOctopusProject()
                ]);

            const lifecycles: Lifecycles = {};
            lifecycleResources.forEach(lifecycle => {
                lifecycles[lifecycle.Id] = lifecycle;
            });

            this.setState({
                channelsResponse,
                deploymentProcesses,
                lifecycles,
                tagIndex,
                project
            });
        });
    }

    render() {
        const addChannelButton = <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project && this.state.project.Id} tenant="*">
            <NavigationButton type={NavigationButtonType.Primary} label="Add Channel" href={`${this.props.match.url}/create`} />
        </PermissionCheck>;
        return (
            <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title="Channels"
                sectionControl={addChannelButton}>
                <SectionNote>
                    Channels allow you to dynamically change the deployment logic and lifecycle of a project based on the release
                    being deployed. Read our <ExternalLink href="Channels">documentation</ExternalLink> to learn more about working with channels.
                </SectionNote>
                {this.state.channelsResponse && <ChannelsList
                    initialData={this.state.channelsResponse}
                    onRow={(item: any) => this.buildRow(item)}
                    match={this.match}
                    onRowRedirectUrl={(channel: ChannelResource) => `${this.match.url}/edit/${channel.Id}`}
                    onFilter={this.filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["partialName"]}
                    filterHintText="Filter by name..."
                />}
            </PaperLayout>
        );
    }

    private filter(filter: string, resource: ChannelResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildRow(channel: ChannelResource) {
        return (
            <Channel key={channel.Id}
                channel={channel}
                project={this.state.project}
                lifecycles={this.state.lifecycles}
                tagIndex={this.state.tagIndex} />
        );
    }
}