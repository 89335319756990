import * as React from "react";
import {ScriptingLanguage} from "components/scriptingLanguage";
import {Language, TextFormat} from "components/CodeEditor/CodeEditor";
import {Item, default as Select} from "components/form/Select/Select";

interface TextFormatSelectorProps {
    value: ScriptingLanguage | Language | TextFormat;
    onChange(value: ScriptingLanguage | Language | TextFormat): void;
}

const editorModeOptionsInternal: Item[] = [
    { value: TextFormat.PlainText, text: "Plain text"},
    { value: TextFormat.XML, text: "XML"},
    { value: TextFormat.JSON, text: "JSON"},
    { value: TextFormat.YAML, text: "YAML"},
    { value: Language.HTML, text: "HTML"},
    { value: Language.CSS, text: "CSS"},
    { value: Language.Markdown, text: "Markdown"},
    { value: Language.DockerFile, text: "DockerFile"},
    { value: ScriptingLanguage.PowerShell, text: "PowerShell"},
    { value: ScriptingLanguage.Bash, text: "Bash"},
    { value: Language.INI, text: "INI"},
    { value: Language.CoffeeScript, text: "CoffeeScript"},
    { value: ScriptingLanguage.CSharp, text: "C#"},
    { value: ScriptingLanguage.FSharp, text: "F#"}
];
export const editorModeOptions: ReadonlyArray<Item> = editorModeOptionsInternal;

export default class TextFormatSelector extends React.Component<TextFormatSelectorProps> {
    render() {
        return <Select
            value={this.props.value}
            items={editorModeOptionsInternal}
            onChange={(mode: ScriptingLanguage | Language | TextFormat) => this.props.onChange(mode)}
        />;
    }
}