exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__2SfjI {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__3TTQq {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__3iKkk {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__36G3j {\n    display: none !important;\n  }\n}\n.style_healthStatusCardTitleContainer__2wqng {\n  display: flex;\n  align-items: center;\n  font-size: 0.8125rem;\n  padding: 0;\n  margin-left: 1.5rem;\n}\n@media (max-width: 37.4rem) {\n  .style_healthStatusCardTitleContainer__2wqng {\n    margin-left: 0.75rem;\n  }\n}\n.style_healthStatusCardTitleContainer__2wqng .style_healthStatusIconContainer__3oe6h {\n  display: flex;\n  min-width: 1rem;\n  padding-right: 0.4rem;\n}\n.style_healthStatusCardTitleContainer__2wqng .style_healthStatusName__2jWw9 {\n  color: #333333;\n  font-weight: 400;\n  margin: 0.25rem 0;\n  padding-right: 0.4rem;\n  text-transform: uppercase;\n}\n.style_healthStatusCardTitleContainer__2wqng .style_healthStatusMachinesCount__32b7P {\n  color: #9e9e9e;\n  margin: 0.25rem 0;\n  padding-right: 0.4rem;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__2SfjI",
	"hideSm": "style_hide-sm__2SfjI",
	"visible-sm": "style_visible-sm__3TTQq",
	"visibleSm": "style_visible-sm__3TTQq",
	"hide-md": "style_hide-md__3iKkk",
	"hideMd": "style_hide-md__3iKkk",
	"visible-md": "style_visible-md__36G3j",
	"visibleMd": "style_visible-md__36G3j",
	"healthStatusCardTitleContainer": "style_healthStatusCardTitleContainer__2wqng",
	"healthStatusIconContainer": "style_healthStatusIconContainer__3oe6h",
	"healthStatusName": "style_healthStatusName__2jWw9",
	"healthStatusMachinesCount": "style_healthStatusMachinesCount__32b7P"
};