import * as React from "react";
const styles = require("./style.less");
import * as cn from "classnames";

interface Image {
    src: string;
    alt: string;
}

interface EmptyPageProps {
    title: string;
    description?: React.ReactNode | string;
    image: Image;
    panels?: Array<{
        description: React.ReactNode,
        imageSrc: string,
        imageWidth: number,
        imageHeight: number, // Needed to ensure image dimensions are set on page load (to stop the content jumping around as images load).
        imageAlt: string
    }>;
    learnMore?: React.ReactNode;
}

export class EmptyPage extends React.Component<EmptyPageProps, never> {
    render() {
        const props = this.props;
        return <div className={styles.onboardingOuterContainer}>
            <div className={cn(styles.onboardingContainer)}>
                {props.title && <div>
                    <h2 className={styles.header}>{props.title}</h2>
                    {props.description && <div className={styles.intro}>{props.description}</div>}
                    {props.learnMore && <div className={styles.learnMore}>{props.learnMore}</div>}
                    <img className={styles.onboardingImage}
                         src={this.props.image.src}
                         alt={this.props.image.alt}
                         title={this.props.image.alt}
                         style={{
                             height: "7rem", // Don't use width, use height to avoid screen jumping on slow-loading connections.
                         }}
                    />
                </div>}
            </div>
        </div>;
    }
}