import * as React from "react";
import {repository} from "clientInstance";
import {TaskResource, TaskDetailsResource, ResourceCollection} from "client/resources";
import {DataBaseComponent, DataBaseComponentState, Refresh} from "components/DataBaseComponent/DataBaseComponent";
import InterruptionResource from "client/resources/interruptionResource";
import ArtifactResource from "client/resources/artifactResource";
import {EventResource} from "client/resources/eventResource";
import History from "../History";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import {RouteComponentProps, withRouter} from "react-router";
import {Section} from "../../../../../components/Section/Section";
import Interruption from "../Interruption/Interruption";
import {UniqueActivityElement} from "../../../../../components/TaskLogLines/TaskLogBlock";
import {goToLog} from "../TaskLog/TaskLogUtil";
import TaskSummaryLine from "../TaskSummary/TaskSummaryLine";
import TaskProgress from "../TaskProgress";
import TaskQueue from "../TaskQueue";
import styles = require("./style.less");
import Callout, {CalloutType} from "components/Callout/Callout";
import {SimpleList} from "../../../../../components/List";
import ArtifactLink from "../Artifacts/ArtifactLink";
import ExternalLink from "../../../../../components/Navigation/ExternalLink/ExternalLink";

interface TaskSummaryComponentProps {
    task?: TaskResource<any>;
    projectId?: string;
    environmentId?: string;
    tenantId?: string;
    artifacts?: ArtifactResource[];
    interruptions?: ResourceCollection<InterruptionResource>;
    deploymentHistory?: ResourceCollection<EventResource>;
    additionalSidebar?: React.ReactNode;
    activityElements?: UniqueActivityElement[];
    taskDetails?: TaskDetailsResource;
    doRefresh: Refresh;
}

class ArtifactsList extends SimpleList<ArtifactResource> {
}

type TaskSummaryProps = TaskSummaryComponentProps & RouteComponentProps<any>;

class TaskSummary extends DataBaseComponent<TaskSummaryProps, DataBaseComponentState> {
    renderInterruptions = () => {
        if (this.props.task.IsCompleted || !this.props.interruptions || !this.props.interruptions.Items) {
            return null;
        }

        return this.props.interruptions.Items
            .filter(interruption => interruption.IsPending)
            .map((interruption) => <div key={interruption.Id} style={{marginBottom: "1rem"}}>
                <Interruption interruption={interruption} takeResponsibility={async () => {
                    await this.doBusyTask(async () => await repository.Interruptions.takeResponsibility(interruption));
                    await this.props.doRefresh();
                }} submitInterruption={async (values: {[name: string]: any}) => {
                    await this.doBusyTask(async () => await repository.Interruptions.submit(interruption, values));
                    await this.props.doRefresh();
                }}/>
            </div>);
    }
    render() {
        const task = this.props.task;

        const sidebar = <div>
                {task.ServerNode && <div><h4>Ran on</h4>{task.ServerNode}</div>}
                <PermissionCheck permission={Permission.ArtifactView}
                                 project={this.props.projectId}
                                 environment={this.props.environmentId}
                                 tenant={this.props.tenantId}
                                 alternate={<Callout type={CalloutType.Information}>
                                     The {Permission.ArtifactView} permission is required to view artifacts
                                 </Callout>}>
                                 <div>
                                    <h4>Artifacts</h4>
                                    <ArtifactsList items={this.props.artifacts}
                                                   pageSize={10}
                                                   onRow={a => <ArtifactLink artifact={a}/>}
                                                   empty={<span>No artifacts have been added. Learn more
                                                       about <ExternalLink href="Artifacts">collecting artifacts</ExternalLink>.</span>}
                                    />
                                 </div>
                </PermissionCheck>
                <PermissionCheck permission={Permission.EventView}
                                 project={this.props.projectId}
                                 environment={this.props.environmentId}
                                 tenant={this.props.tenantId}
                                 alternate={<Callout type={CalloutType.Information}>
                                     The {Permission.EventView} permission is required to view the history
                                 </Callout>}>
                    <History history={this.props.deploymentHistory} />
                </PermissionCheck>
                {this.props.additionalSidebar}
            </div>;

        const showFatalsInline = this.props.activityElements.length !== 1;

        return <SidebarLayout sideBar={sidebar} extendContentToEdges={true} flexBasis="25rem">
                    <Section>
                        {this.renderInterruptions()}
                    </Section>
                    <Section sectionHeader="Task Progress">
                        <div className={styles.taskProgress}>
                            <TaskProgress details={this.props.taskDetails}/>
                        </div>
                        {this.props.activityElements.map(e => <TaskSummaryLine key={e.uniqueId}
                                                                                element={e}
                                                                                artifacts={this.props.artifacts}
                                                                                onLogNavigation={(taskLogLineId) => goToLog(this.props, taskLogLineId)}
                                                                                showFatalsInline={showFatalsInline} />)}
                        {!task.HasBeenPickedUpByProcessor && <TaskQueue task={task}/>}
                    </Section>
                </SidebarLayout>;
    }
}

export default withRouter(TaskSummary);