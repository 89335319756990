import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "components/GettingStarted/OnboardingPage";

export const OnboardingTextForWorkers = "Workers allows delegating work to a machine other than the Octopus server for increased speed, scale and security.";

const WorkersOnboarding = () => {
    return (
        <OnboardingPage
            title="Configure workers to execute deployment tasks on behalf of the Octopus Server"
            intro={<span>{OnboardingTextForWorkers}</span>}
            learnMore={<span><ExternalLink href="OnboardingWorkersLearnMore">Learn more</ExternalLink>.</span>}
        />
    );
};

export default WorkersOnboarding;