exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_logo__M4qZr {\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n}\n.style_logo__M4qZr img {\n  height: auto;\n  width: auto;\n}\n.style_logo__M4qZr .style_disabled__LoIvK {\n  -webkit-filter: grayscale(1);\n  filter: grayscale(1);\n  /* filter doesn't work for IE11, known issue, we fallback to opacity only in this case */\n  opacity: 0.4;\n}\n", ""]);

// exports
exports.locals = {
	"logo": "style_logo__M4qZr",
	"disabled": "style_disabled__LoIvK"
};