import Onboarding from "./Onboarding";
import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import { NavigationButton, NavigationButtonType } from "components/Button/NavigationButton";
import { repository } from "clientInstance";
import { TagSetResource } from "client/resources/tagSetResource";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import { Permission } from "client/resources/permission";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { List } from "components/List/List";
import { sortBy } from "lodash";
import { ResourceCollection } from "client/resources/resourceCollection";
import Tag from "components/Tag/index";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import MarkdownDescription from "components/MarkdownDescription";
const styles = require("./style.less");
import ListTitle from "components/ListTitle/ListTitle";
import { tagSetClass } from "uiTestClasses";
import OpenDialogButton from "../../../../components/Dialog/OpenDialogButton";
import ActionList from "../../../../components/ActionList/ActionList";
import TagSetsSorter from "./TagSetsSorter";
import { RouteComponentProps } from "react-router";
import routeLinks from "../../../../routeLinks";
import GlobalState from "globalState";
import { connect, MapStateToProps } from "react-redux";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import SectionNote from "components/SectionNote/SectionNote";

interface ConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface TagSetListState extends DataBaseComponentState {
    tagSetsResponse?: ResourceCollection<TagSetResource>;
}

class TagSetsResourceList extends List<TagSetResource> { }

type Props = ConnectedProps & RouteComponentProps<any>;

class TagSetsList extends DataBaseComponent<Props, TagSetListState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.loadData();
    }

    renderOnBoarding() {
        return <Onboarding />;
    }

    renderTagSets() {
        return <div>
            <SectionNote>
                Tags let you classify and deal with tenants as groups throughout Octopus. If you would like more information,
                or hints on getting the most benefit from your tags, take a look at our <ExternalLink href="MultiTenantTags">documentation</ExternalLink>.
            </SectionNote>
            <TagSetsResourceList
                initialData={this.state.tagSetsResponse}
                onRow={(tagSet: any) => this.buildTagSetsRow(tagSet)}
                match={this.props.match}
                onFilter={this.filterTagSets}
                filterSearchEnabled={true}
                apiSearchParams={["partialName"]}
                filterHintText="Filter by name..."
            />
        </div>;
    }

    render() {
        if (!this.props.isMultiTenancyEnabled) {
            return <InternalRedirect to={routeLinks.library.root} />;
        }

        return <LibraryLayout {...this.props}>
            <PaperLayout title="Tenant Tag Sets"
                sectionControl={this.buildSectionControl()}
                busy={this.state.busy}
                errors={this.state.errors}>
                {!this.state.tagSetsResponse ? <div /> : (this.state.tagSetsResponse.TotalResults > 0 ? this.renderTagSets() : this.renderOnBoarding())}
            </PaperLayout>
        </LibraryLayout>;
    }

    private buildTagSetsRow(tagSet: TagSetResource) {
        return (<div className={tagSetClass}>
            <ListTitle>{tagSet.Name}</ListTitle>
            <div className={styles.helpText}>
                <MarkdownDescription markup={tagSet.Description} />
            </div>
            <div>
                {sortBy(tagSet.Tags, (t) => t.SortOrder)
                    .map(t => <Tag tagName={t.Name} tagColor={t.Color} key={t.Id} description={t.Description} />)}
            </div>
        </div>);
    }

    private filterTagSets(filter: string, resource: TagSetResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private buildSortDialog = () => {
        return this.state.tagSetsResponse && this.state.tagSetsResponse.Items.length > 0
            && <OpenDialogButton label="Reorder">
                <TagSetsSorter onSaved={this.loadData} />
            </OpenDialogButton>;
    }

    private buildSectionControl = () => {
        const actions = [this.buildSortDialog(),
        <NavigationButton type={NavigationButtonType.Primary}
            label="Add Tag Set"
            href={`${this.props.match.url}/create`} />];
        return <PermissionCheck permission={Permission.TagSetCreate}>
            <ActionList actions={actions} />
        </PermissionCheck>;
    }

    private loadData = async () => {
        await this.doBusyTask(async () => {
            this.setState({ tagSetsResponse: await repository.TagSets.list() });
        });
    }
}

const mapStateToProps = (state: GlobalState, props: RouteComponentProps<any>): ConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled
    };
};

export default connect(mapStateToProps)(TagSetsList);