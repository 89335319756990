import Caching from "./client/caching";
import Logger from "./client/logger";
import {FeaturesConfigurationResource, UserPermissionSetResource, UserResource} from "./client/resources";
import {UserPermissions} from "./client/userPermissions";
import store from "./store";
import {configurationActions} from "areas/configuration/reducers/configurationArea";

class Session {
    currentUser: UserResource;
    currentPermissions: UserPermissions;
    cache: Caching;

    constructor() {
        this.cache = new Caching();
    }

    start(user: UserResource, features: FeaturesConfigurationResource) {
        Logger.info(`Starting session for ${user.DisplayName} user.`);
        this.currentUser = user;
        this.setFeatures(features);
    }

    end() {
        if (this.currentUser) {
            Logger.info(`Ending session for ${this.currentUser.DisplayName} user.`);
        }
        this.currentUser = null;
        this.currentPermissions = null;
        this.setFeatures(null);
        this.cache.clearAll();
    }

    refreshPermissions(userPermission: UserPermissionSetResource) {
        this.currentPermissions = UserPermissions.Create(userPermission.SpacePermissions, userPermission.SystemPermissions, userPermission.Teams);
    }

    isAuthenticated() {
        return this.currentUser != null;
    }

    private setFeatures(features: FeaturesConfigurationResource) {
        store.dispatch(configurationActions.featuresFetched(features));
    }
}

export default Session;
