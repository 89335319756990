import * as React from "react";
import Select from "components/form/Select/Select";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import { RadioButton, StringRadioButtonGroup } from "components/form";
import { repository } from "clientInstance";
import { ProjectResource } from "client/resources";
import FormComponent from "components/FormComponent/FormComponent";
import { DialogLayout, DialogLayoutCommonProps, DialogLayoutDispatchProps } from "components/DialogLayout/DialogLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import {onDialogClose} from "components/Dialog/reducers/dialog";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import Callout, { CalloutType } from "components/Callout";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "routeLinks";

interface CloneStepProps {
    onCloneTargetSelected: (cloneTarget: CloneStepTarget, selectedProjectId: string) => void;
    actionName: string;
    currentProjectId: string;
}

interface CloneStepState extends DataBaseComponentState {
    selectedProjectId: string;
    selectedTarget: CloneStepTarget;
    projects: ProjectResource[];
    dialogStatus: CloneStatus;
}

enum CloneStepTarget {
    ThisProject = "ThisProject",
    AnotherProject = "AnotherProject"
}

enum CloneStatus {
    AskingForTarget = "AskingForTarget",
    ClonedToCurrentProject = "ClonedToCurrentProject",
    ClonedToDifferentProject = "ClonedToDifferentProject"
}

interface CloneStepDialogLayoutProps extends DialogLayoutCommonProps {
    onOkClick(): any;
}

class CloneStepDialogLayoutInternal extends React.Component<CloneStepDialogLayoutProps & DialogLayoutDispatchProps> {
    okClick = async () => {
        const result = await this.props.onOkClick();
        if (result) {
            this.props.close();
        }
    }

    render() {
        const {
            children,
            ...other
        } = this.props;

        const ok = <ActionButton key="Ok" label="Ok" onClick={this.okClick} type={ActionButtonType.Primary}/>;
        const cancel = <ActionButton key="Cancel" label="Cancel" onClick={() => this.props.close()}/>;
        const actions = [cancel, ok];

        return <DialogLayout actions={actions} closeDialog={this.props.close} {...other}>
            <FormComponent onFormSubmit={this.okClick}>{children}</FormComponent>
        </DialogLayout>;
    }
}

const CloneStepDialogLayout = DialogLayoutConnect.to<CloneStepDialogLayoutProps>(CloneStepDialogLayoutInternal);
CloneStepDialogLayout.displayName = "CloneDialogLayout";

class CloneStep extends DataBaseComponent<CloneStepProps, CloneStepState> {
    constructor(props: CloneStepProps) {
        super(props);

        this.state = {
            selectedProjectId: null,
            selectedTarget: CloneStepTarget.ThisProject,
            projects: [],
            dialogStatus: CloneStatus.AskingForTarget
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const projects = await repository.Projects.all();
            this.setState({projects});
        });
    }

    okClick = async () => {
        await this.onOk();
        return onDialogClose();
    }

    renderFirstStageDialog() {
        return <CloneStepDialogLayout title="Clone Step"
                               busy={this.state.busy}
                               errors={this.state.errors}
                               onOkClick={() => this.onOk()}>
            <p>Clone the step <strong>{this.props.actionName}</strong> to:</p>

            <StringRadioButtonGroup value={this.state.selectedTarget} onChange={selectedTarget => this.setState({ selectedTarget: selectedTarget as CloneStepTarget })} >
                <RadioButton value={CloneStepTarget.ThisProject} label="This project" isDefault={true} />
                <RadioButton value={CloneStepTarget.AnotherProject} label="Another project" isDefault={false} />
            </StringRadioButtonGroup>
            {this.state.selectedTarget === CloneStepTarget.AnotherProject && <Select
                        label="Select project"
                        value={this.state.selectedProjectId}
                        error={this.state.errors && this.state.errors.fieldErrors.selectedProjectId}
                        items={this.state.projects.filter(project => project.Id !== this.props.currentProjectId).map(s => ({value: s.Id, text: s.Name}))}
                        onChange={projectId => this.setState({selectedProjectId: projectId, errors: null})}
                    />}
        </CloneStepDialogLayout>;
    }

    renderClonedToDifferentProjectDialog() {
        const project = this.state.projects.filter(p => p.Id === this.state.selectedProjectId)[0];

        return <InfoDialogLayout title="Clone Successful"
                                 busy={this.state.busy}
                                 errors={this.state.errors}>
            <p>
                Step <strong>{this.props.actionName}</strong> has been successfully cloned to <InternalLink to={routeLinks.project(project.Slug).process.root}>{project.Name}</InternalLink>.
            </p>
            <Callout type={CalloutType.Warning} title="Variables">
                No variables were copied - consider reviewing the cloned step and manually copy any required variables.
            </Callout>
        </InfoDialogLayout>;
    }

    render() {
        return this.state.dialogStatus === CloneStatus.AskingForTarget
            ? this.renderFirstStageDialog()
            : this.renderClonedToDifferentProjectDialog();
    }

    private onOk() {
        if (this.state.selectedTarget === CloneStepTarget.AnotherProject) {
            if (!this.state.selectedProjectId) {
                this.setState({
                    errors: {
                        message: "Please select a project",
                        details: null,
                        fieldErrors: {selectedProjectId: "Select a project"}
                    }
                });
            } else {
                this.props.onCloneTargetSelected(this.state.selectedTarget, this.state.selectedProjectId);
                this.setState({ dialogStatus: CloneStatus.ClonedToDifferentProject});
            }
            return false;
        } else {
            this.props.onCloneTargetSelected(this.state.selectedTarget, this.state.selectedProjectId);
            this.setState({ dialogStatus: CloneStatus.ClonedToCurrentProject });
            return true;
        }
    }
}

export default CloneStep;
export { CloneStepTarget };
