import * as React from "react";
import {UserResource} from "client/resources/userResource";
import {RouteComponentProps} from "react-router";
import { client, repository} from "clientInstance";
import OverflowMenu from "components/Menu/OverflowMenu";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent/FormBaseComponent";
import {AuthenticationProviderElement, IdentityMetadataResource} from "client/authentication/authenticationProviderElement";
import {ClaimsBasedIdentity} from "client/resources/identityResource";
import FormPaperLayout from "components/FormPaperLayout";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import {Section} from "components/Section/Section";
import {ProviderGroups} from "./ProviderGroups";
import UserChangePasswordDialog from "./UserChangePasswordDialog";
import {required} from "components/form/Validators";
import UserApiKeysList from "areas/users/UserApiKeys/UserApiKeysList";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import {
    Text,
    Checkbox,
    FormSectionHeading,
    Summary,
    ExpandableFormSection
} from "components/form";
import Permission from "client/resources/permission";
import routeLinks from "../../../../routeLinks";
import { Avatar } from "components/Avatar/Avatar";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface UserEditModel {
    displayName: string;
    username: string;
    emailAddress: string;
    isActive: boolean;
    isService: boolean;
    original: UserResource;
    identities: ClaimsBasedIdentity[];

    password?: string;
    confirmPassword?: string;
}

interface UserEditState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
    deleted: boolean;
    newId?: string;
    enabledAuthenticationProviders: AuthenticationProviderElement[] | null;
    enabledProvidersMetadata?: IdentityMetadataResource[] | null;
    canCurrentUserEditIdentitiesForUser?: boolean;
}

export default class UserEdit extends FormBaseComponent<RouteComponentProps<{ userId: string }>, UserEditState, UserEditModel> {

    constructor(props: RouteComponentProps<{ userId: string }>) {
        super(props);

        this.state = {
            user: null,
            model: null,
            deleted: false,
            cleanModel: null,
            canCurrentUserEditIdentitiesForUser: true,
            enabledAuthenticationProviders: null, //start as null so we can only show no providers once it loads
            enabledProvidersMetadata: null
        };
    }

    currentUserId(): string {
        return this.props.match.params.userId;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;

            const configDoc = await repository.UserIdentityMetadata.authenticationConfiguration(this.currentUserId());
            const metadataDoc = await repository.UserIdentityMetadata.all();

            this.setState({
                user,
                model: this.buildModel(user),
                cleanModel: this.buildModel(user),
                enabledAuthenticationProviders: configDoc.AuthenticationProviders || [],
                canCurrentUserEditIdentitiesForUser: configDoc.CanCurrentUserEditIdentitiesForUser,
                enabledProvidersMetadata: metadataDoc.Providers
            });
        });
    }

    buildModel(user: UserResource): UserEditModel {
        return user
            ? {
                displayName: user.DisplayName,
                username: user.Username,
                emailAddress: user.EmailAddress,
                isActive: user.IsActive,
                isService: user.IsService,
                original: user,
                identities: user.Identities
            }
            : {
                displayName: null,
                username: null,
                emailAddress: null,
                isActive: true,
                isService: false,
                original: null,
                identities: []
            };
    }

    handleSaveClick = async () => {

        const user: UserResource = {
            ...this.state.user,
            DisplayName: this.state.model.displayName,
            EmailAddress: this.state.model.emailAddress,
            Username: this.state.model.username,
            IsService: this.state.model.isService,
            IsActive: this.state.model.isActive,
            // Password cannot be supplied for service accounts, better UX to just drop it here
            // in case their password manager populated a field
            Password: this.state.model.isService ? null : this.state.model.password,
            Identities: this.state.model.identities
        };

        if (!this.state.model.isService && !this.isConfirmPasswordCorrect()) {
            this.setState({
                errors: {
                    message: "The passwords do not match",
                    details: ["Please retype the password"],
                    fieldErrors: {Matching: "confirmPassword"}
                }
            });

            return false;
        }

        if (this.state.model.username === null || this.state.model.username.trim().length === 0) {
            this.setState({
                errors: {
                    message: "There was no username given",
                    details: ["Please provide a username"],
                    fieldErrors: {Matching: "username"}
                }
            });

            return false;
        }

        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);

            this.setState({
                cleanModel: this.buildModel(result),
                model: this.buildModel(result),
                newId: this.currentUserId() ? null : result.Id
            });
        });
    }

    render() {
        return <FormPaperLayout
            title={this.determineTitle()}
            titleLogo={<Avatar avatarLink={this.state.user && this.state.user.Links && this.state.user.Links.Avatar}
                isService={this.state.user && this.state.user.IsService}
                size={40}
            />}
            breadcrumbTitle={"Users"}
            breadcrumbPath={routeLinks.configuration.users.root}
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            savePermission={{permission: [Permission.AdministerSystem, Permission.UserEdit]}}
            expandAllOnMount={this.isCreateUser()}
            onSaveClick={this.handleSaveClick}
            overFlowActions={this.createOverflowMenuItems()}
            secondaryAction={this.changePasswordButton()}>

            {this.state.deleted && <InternalRedirect to={routeLinks.configuration.users.root}/>}
            {this.state.newId && <InternalRedirect to={routeLinks.configuration.user(this.state.newId)}/>}

            {this.state.model && <TransitionAnimation>

                {this.renderUserNameAndDisplayName()}

                {this.renderServiceAccountOption()}

                {this.renderEmail()}

                {this.renderIsActive()}

                {/* Passwords, then API keys, then external logins */}
                <FormSectionHeading title="Logins"/>

                {this.renderPasswordSection()}

                {this.renderApiSection()}

                {this.renderLoginOptions()}

            </TransitionAnimation>}
        </FormPaperLayout>;
    }

    determineTitle() {
        return this.isCreateUser()
            ? "New User"
            : (this.state.model && this.state.model.displayName) || "User Details";
    }

    renderUserNameAndDisplayName() {
        return [
            // Note there's a bug in the old portal that let you create a user without a username, some of the extra checks/messages here are to show that's the case
            <ExpandableFormSection
                key="username"
                errorKey={"username"}
                title="Username"
                focusOnExpandAll
                summary={Summary.summary(this.state.model.username ? this.state.model.username : "No username specified yet.")}
                help={this.isCreateUser() ? "Enter a username the user authenticates with." : "The username that the user authenticates with."}>

                {this.state.model.original === null
                    ? <Text
                        value={this.state.model.username}
                        onChange={username => this.setModelState({username})}
                        label="Username"
                        validate={required("Please enter a username")}
                        autoFocus={true}
                    />
                    : this.state.model.username || "no user name"}
            </ExpandableFormSection>
            ,
            <ExpandableFormSection
                key="displayName"
                errorKey="displayName"
                title="Display Name"
                summary={Summary.summary(this.state.model.displayName ? this.state.model.displayName : "No user display name specified yet.")}
                help="Enter a display name for the user. This does not need to be unique.">
                <Text
                    value={this.state.model.displayName}
                    onChange={displayName => this.setModelState({displayName})}
                    label="Display name"
                    validate={required("Please enter a display name")}
                />
            </ExpandableFormSection>];
    }

    renderPasswordSection() {
        return this.isCreateUser() && !this.state.model.isService &&
            <ExpandableFormSection
                errorKey={"setPassword"}
                title={"Password"}
                summary={Summary.summary(this.state.model.password ? "The user's password." : "Optional. Set a password for this user.")}
                help={this.state.model.password ? "The user's password." : "Optional. Set a password for this user."}>
                <Section>
                    <Text
                        value={this.state.model.password}
                        type={"password"}
                        onChange={password => this.setModelState({password})}
                        label="Password"
                    />
                </Section>
                <Section>
                    <Text
                        value={this.state.model.confirmPassword}
                        type={"password"}
                        onChange={confirmPassword => this.setModelState({confirmPassword})}
                        label="Confirm password"
                        validate={this.isConfirmRequired()}
                    />
                </Section>
            </ExpandableFormSection>;
    }

    changePasswordButton() {
        const usernamePasswordProviderExists = this.state.enabledAuthenticationProviders && this.state.enabledAuthenticationProviders.find(p => p.Name === "Octopus") !== null;

        return this.state.model && this.state.model.original && this.state.model.original.CanPasswordBeEdited && usernamePasswordProviderExists &&
            <OpenDialogButton
                label="Change Password">
                <UserChangePasswordDialog
                    userId={this.state.model.original.Id}/>
            </OpenDialogButton>;
    }

    handleDeleteConfirm = async () => {
        const result = await repository.Users.del(this.state.model.original);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }

    renderServiceAccountOption() {
        return this.isCreateUser()
            && <ExpandableFormSection
                errorKey={"isService"}
                title="Service Account"
                summary={Summary.summary(this.state.model.isService ? "This is a service account" : "This is not a service account.")}
                help={"A service account can log in using API keys only. After creating the user you'll need to add some API keys before the account can be used."}>

                <Checkbox
                    value={this.state.model.isService}
                    onChange={isService => this.setModelState({isService})}
                    label="The user is a service account"
                />
            </ExpandableFormSection>;
    }

    renderEmail() {
        return !this.state.model.isService &&
            <ExpandableFormSection
                errorKey="EmailAddress"
                title="Email Address"
                summary={Summary.summary(this.state.model.emailAddress ? this.state.model.emailAddress : "No user email specified yet.")}
                help={this.state.model.emailAddress ? "The user's email address." : "Enter an email address."}>
                <Text
                    value={this.state.model.emailAddress}
                    onChange={emailAddress => this.setModelState({emailAddress})}
                    label="Email address"
                />
            </ExpandableFormSection>;
    }

    renderLoginOptions() {
        return <ProviderGroups
                userIdentities={this.state.model.identities}
                enabledAuthenticationProviders={this.state.enabledAuthenticationProviders}
                canCurrentUserEditIdentitiesForUser={this.state.canCurrentUserEditIdentitiesForUser}
                enabledProvidersMetadata={this.state.enabledProvidersMetadata}
                isServiceAccount={this.state.model.isService}
                onChange={identities => this.setModelState({identities})}/>;
    }

    renderApiSection() {
        return this.state.model.original &&
            <ExpandableFormSection
                errorKey="ApiKeys"
                title="Api Keys"
                summary={Summary.summary("The user's API keys")}
                help="API keys can be used to access the Octopus Deploy REST API.">
                <UserApiKeysList user={this.state.model.original}/>
            </ExpandableFormSection>;
    }

    renderIsActive() {
        return !this.isCreateUser() &&
            <ExpandableFormSection
                errorKey="isActive"
                title="Is Active"
                summary={Summary.summary(this.state.model.isActive ? "This user is active and can log in" : "This user has been deactivated and cannot use Octopus Server")}
                help="Inactive users remain in the database but cannot use the Octopus Server.">
                <Checkbox
                    value={this.state.model.isActive}
                    onChange={isActive => this.setModelState({isActive})}
                    label="Is active"
                />
            </ExpandableFormSection>;
    }

    private createOverflowMenuItems() {
        const items = [];

        if (!this.isCreateUser() && this.state.model) {
            items.push(OverflowMenu.deleteItemDefault(
                "user",
                this.handleDeleteConfirm,
                null,
                null,
                <div>
                    Have a look at the Octopus <ExternalLink href="PrivacyPolicy">Privacy Policy</ExternalLink> for
                    the full details of categories of data that will remain.
                </div>));

            items.push(OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsForUser(this.currentUserId()), null, {
                    permission: Permission.EventView,
                    wildcard: true
                }));
        }

        if (this.state.model && this.state.user && this.state.user.Id) {
            items.push(OverflowMenu.navItem("Test Permissions",
                routeLinks.configuration.testPermission(this.state.user.Id), null, {
                permission: [Permission.TeamEdit, Permission.UserView]
            }));
            items.push(OverflowMenu.downloadItem(
                    "Download user data",
                    this.state.model.username + "-user.json",
                    client.resolveLinkTemplate("Users", {id: this.state.user.Id})
                ));
        }

        return items;
    }

    private isCreateUser() {
        return this.state.model
            && this.state.model.original === null;
    }

    private isConfirmPasswordCorrect() {
        if (!this.state.model.password) {
            return true;
        }

        return this.isCreateUser()
            && this.state.model.password
            && this.state.model.password.localeCompare(this.state.model.confirmPassword) === 0;
    }

    private isConfirmRequired() {
        return this.isConfirmPasswordCorrect() ? null : required("Passwords don't match");
    }
}
