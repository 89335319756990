import * as React from "react";
import { repository } from "../../clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/index";
import routeLinks from "../../routeLinks";
import PaperLayout from "components/PaperLayout/index";
import AreaTitle from "components/AreaTitle/index";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import {Repository} from "../../client";

interface ProjectChildRedirectState extends DataBaseComponentState {
    redirectTo: string;
}

interface ProjectChildRedirectProps {
    getProjectId: (repository: Repository) => Promise<string>;
    getRedirectPath: (projectId: string) => string;
}

class ProjectChildRedirect extends DataBaseComponent<ProjectChildRedirectProps, ProjectChildRedirectState> {
    constructor(props: ProjectChildRedirectProps) {
        super(props);
        this.state = {
            redirectTo: null
        };
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            const projectId = await this.props.getProjectId(repository);
            const redirectTo = this.props.getRedirectPath(projectId);
            this.setState({
                redirectTo
            });
        });
    }

    render() {
        const redirectTo = this.state.redirectTo;
        if (!redirectTo) {
            return <main id="maincontent">
                <AreaTitle link={routeLinks.projects.root} title="Projects" />
                <PaperLayout busy={this.state.busy} fullWidth={true} errors={this.state.errors} />
            </main>;
        }

        return <InternalRedirect push={false} to={{pathname: redirectTo}}/>;
    }
}

export const ReleaseRedirect: React.SFC<{releaseId: string}> = (props) => {
    return <ProjectChildRedirect getProjectId={async r => (await r.Releases.get(props.releaseId)).ProjectId}
                                getRedirectPath={(projectId: string) => routeLinks.project(projectId).release(props.releaseId).root} />;
};

export const TriggerRedirect: React.SFC<{triggerId: string}> = (props) => {
    return <ProjectChildRedirect getProjectId={async r => (await r.ProjectTriggers.get(props.triggerId)).ProjectId}
                                 getRedirectPath={(projectId: string) => routeLinks.project(projectId).trigger(props.triggerId)} />;
};

export const ChannelRedirect: React.SFC<{channelId: string}> = (props) => {
    return <ProjectChildRedirect getProjectId={async r => (await r.Channels.get(props.channelId)).ProjectId}
                                 getRedirectPath={(projectId: string) => routeLinks.project(projectId).channel(props.channelId)} />;
};