import * as React from "react";
import Dialog from "material-ui/Dialog";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { GettingStartedDetails } from "components/GettingStarted/GettingStartedDetails";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";

interface GettingStartedDialogProps {
    open: boolean;
    highlightSection?: string;
    onClose(): void;
    onNavigating(): void;
}

export class GettingStartedDialog extends BaseComponent<GettingStartedDialogProps, {}> {
    styles = {
        dialogRoot: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 0,
        },
        dialogContent: {
            position: "relative",
            width: "100%",
            maxWidth: "1300px",
            transform: "",
            marginRight: "1rem", // To allow room for the Close/X button in the top right.
        },
        dialogBody: {
            paddingBottom: 0,
        }
    };

    render() {
        const close = <Toolbar style={{ backgroundColor: "initial", height: 0 }} noGutter={true}>
            <ToolbarGroup firstChild={true} />
            <ToolbarGroup lastChild={true}>
                <IconButton
                    tooltip="Close"
                    onClick={this.props.onClose}>
                    <ClearIcon />
                </IconButton>
            </ToolbarGroup>
        </Toolbar>;

        return <Dialog
                title={null}
                open={this.props.open}
                onRequestClose={this.props.onClose}
                contentStyle={this.styles.dialogContent as any}
                bodyStyle={this.styles.dialogBody as any}
                style={this.styles.dialogRoot as any}
                repositionOnUpdate={false}
                autoScrollBodyContent={true}>
                {close}
                <GettingStartedDetails showIntroHeading={true} onNavigating={() => this.props.onNavigating()} highlightSection={this.props.highlightSection} />
            </Dialog>;
    }
}