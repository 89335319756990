exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1aUeT {\n  display: flex;\n  width: 7rem;\n  position: relative;\n  flex: 0 0 7rem;\n}\n.style_colorSwatch__2rOye {\n  width: 1.5rem;\n  height: 1.5rem;\n  border-radius: 0.25em;\n  position: absolute;\n  bottom: 1rem;\n  right: 0.5rem;\n  cursor: pointer;\n}\n.style_colorPickItem__249hZ {\n  width: 2.5rem;\n  height: 2.5rem;\n  cursor: pointer;\n  transition: background-color 0.1s linear;\n  border-radius: 0;\n}\n.style_colorAllOptions__2ExLA {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1aUeT",
	"colorSwatch": "style_colorSwatch__2rOye",
	"colorPickItem": "style_colorPickItem__249hZ",
	"colorAllOptions": "style_colorAllOptions__2ExLA"
};