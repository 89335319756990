import * as React from "react";
import { client, repository } from "clientInstance";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { UserResource } from "client/resources/userResource";
import { Permission } from "client/resources/permission";
import OverflowMenu from "components/Menu/OverflowMenu";
import {session} from "clientInstance";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import routeLinks from "../../../routeLinks";
import UserChangePasswordDialog from "areas/configuration/components/Users/UserChangePasswordDialog";
import {
    Text,
    Summary,
    ExpandableFormSection
} from "components/form";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface UserEditModel {
    displayName: string;
    username: string;
    emailAddress: string;
    password?: string;
    confirmPassword?: string;
}

interface UserProfileState extends OptionalFormBaseComponentState<UserEditModel> {
    user: UserResource;
}

export default class UserProfile extends FormBaseComponent<any, UserProfileState, UserEditModel> {
    tenantId: string = null;
    constructor(props: any) {
        super(props);

        this.state = {
            user: null,
            model: null,
            cleanModel: null,
        };
    }

    buildModel(user: UserResource): UserEditModel {
        return user
        ? {
            displayName: user.DisplayName,
            username: user.Username,
            emailAddress: user.EmailAddress,
        }
        : {
            displayName: "",
            username: "",
            emailAddress: "",
        };
    }

    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;

            this.setState({
                user,
                model: this.buildModel(user),
                cleanModel: this.buildModel(user),
            });
        });
    }

    handleSaveClick = async () => {
        const userDisplaNameChanged = this.state.cleanModel.displayName !== this.state.model.displayName;
        const user: UserResource = {
            ...this.state.user,
            DisplayName: this.state.model.displayName,
            EmailAddress: this.state.model.emailAddress,
            Username: this.state.model.username,
        };

        await this.doBusyTask(async () => {
            const result = await repository.Users.save(user);

            this.setState({
                cleanModel: this.buildModel(user),
                model: this.buildModel(user)
            });

            if (userDisplaNameChanged) {
                // we did this in old portal, and we do it for the feature toggles
                window.location.reload();
            }
        });
    }

    render() {
        return <FormPaperLayout
            title="My Profile"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            onSaveClick={this.handleSaveClick}
            overFlowActions={this.createOverflowMenuItems()}
            secondaryAction={this.changePassword()}>
            {this.state.model && <TransitionAnimation>
                <ExpandableFormSection
                    errorKey="displayName"
                    title="Display Name"
                    focusOnExpandAll
                    summary={Summary.summary(this.state.model.displayName ? this.state.model.displayName : "No display name specified yet.")}
                    help="Your name. This does not need to be unique.">
                    <Text
                        value={this.state.model.displayName}
                        onChange={displayName => this.setModelState({displayName})}
                        label="Display name"
                        autoFocus={true}
                    />
                </ExpandableFormSection>

                <ExpandableFormSection
                    errorKey="emailAddress"
                    title="Email Address"
                    summary={Summary.summary(this.state.model.emailAddress ? this.state.model.emailAddress : "Your email is not specified yet.")}
                    help={this.state.model.emailAddress ? "Your email address." : "Enter an email address."}>
                    <Text
                        value={this.state.model.emailAddress}
                        onChange={emailAddress => this.setModelState({emailAddress})}
                        label="Email address"
                    />
                </ExpandableFormSection>

            </TransitionAnimation>}
        </FormPaperLayout>;
    }

    createOverflowMenuItems() {

        const items = [];

        if (this.state.user && this.state.user.Id) {
            items.push(OverflowMenu.downloadItem(
                    "Download my personal details",
                    this.state.model.username + "-user.json",
                    client.resolveLinkTemplate("Users", {id: `${this.state.user.Id}`})
                ));
            items.push(OverflowMenu.navItem("Test Permissions",
                routeLinks.configuration.testPermission(this.state.user.Id), null, {
                permission: [Permission.TeamEdit, Permission.UserView]
            }));
        }

        return items;
    }

    changePassword() {
        return this.state.model &&
        <OpenDialogButton
            label="Change Password">
            <UserChangePasswordDialog
                userId={this.currentUserId()}/>
        </OpenDialogButton>;
    }
}