import * as React from "react";
import { CategoryDefinition } from "../Registry/packagingRegistry";

const category: CategoryDefinition = {
    category: "ASP.NET",
    title: <React.Fragment>How would you like to package this <strong>ASP.NET</strong> application?</React.Fragment>,
    help: null,
    displayOrder: 10,
 };

export default category;
