import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/index";

interface FormPageProps<TData> {
    title: string;
    load(): Promise<TData>;
    render(data: TData): React.ReactNode;
}

interface FormPageState<TData> extends DataBaseComponentState {
    loadedData?: TData;
}

// TODO: Consolidate Page and FormPage into a single component. These are doing similar-but-different things.
export default function FormPage<TData>() {
    return class LoadInner extends DataBaseComponent<FormPageProps<TData>, FormPageState<TData>> {
        constructor(props: FormPageProps<TData>) {
            super(props);
            this.state = {};
        }

        componentDidMount() {
            this.doBusyTask(async () => {
                this.setState({
                    loadedData: await this.props.load()
                });
            });
        }

        render() {
            return this.state.loadedData
                ? this.props.render(this.state.loadedData)
                : <PaperLayout
                    title={this.props.title}
                    busy={this.state.busy}
                    errors={this.state.errors}
                />;
        }
    };
}
