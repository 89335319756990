exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_filterSearchBoxContainer__2em71 {\n  width: 320px;\n}\n", ""]);

// exports
exports.locals = {
	"filterSearchBoxContainer": "style_filterSearchBoxContainer__2em71"
};