import * as React from "react";
import { DataContext, MetadataTypeCollectionDescriptor, MetadataTypeDescriptor } from "client/resources/dynamicFormResources";
import { ControlType} from "client/resources";
import { Checkbox, ExpandableFormSection, FormSectionHeading, Sensitive, Summary, Text } from "../form";
import { VariableLookupText } from "../form/VariableLookupText";
import { Options } from "../form/Select/Options";
import { required } from "../form/Validators";
import MetadataTypeHelpers from "./MetadataTypeHelpers";
import { BoundSelect, default as Select } from "../form/Select/Select";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { BoundFieldProps } from "../Actions/pluginRegistry";
import Markdown from "components/Markdown";
import Section from "../Section";
const styles = require("./style.less");

export interface DynamicFormProps {
    description?: string;
    types?: MetadataTypeCollectionDescriptor[];
    values?: DataContext;
    onChange?: (context: DataContext) => void;
    isBindable?: boolean;
    getBoundFieldProps?: () => BoundFieldProps;
}

interface EitherProps {
    flag: any;
    renderLeft: () => React.ReactElement<any>;
    renderRight: () => React.ReactElement<any>;
}
const Either: React.SFC<EitherProps> = ({flag, renderLeft, renderRight}) => (!!flag === true) ? renderRight() : renderLeft();

const DynamicForm: React.StatelessComponent<DynamicFormProps> = props => {
    const noValueMessage: string = "No value provided";

    const getControlType = (property: MetadataTypeDescriptor): ControlType => {
        if (property.DisplayInfo.ListApi || property.DisplayInfo.Options) {
            return ControlType.Select;
        }

        switch (property.Type) {
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
                return ControlType.SingleLineText;
            case "bool":
            case "bool?":
            case "boolean":
                return ControlType.Checkbox;
            case "string[]":
            case "raw_map":
            case "raw_list":
                return ControlType.MultiLineText;
            case "SensitiveValue":
                return ControlType.Sensitive;
        }
    };

    const getBooleanDisplayValue = (value: any): string => {
        return value === null || value === false ? "Disabled" : "Enabled";
    };

    const createSummary = (property: MetadataTypeDescriptor, value: any) => {
        switch (property.Type) {
            case "bool":
            case "boolean":
            case "bool?":
                return Summary.summary(getBooleanDisplayValue(value));
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
            case "raw_map":
            case "raw_list":
                return value === null || value === "" ? Summary.placeholder(noValueMessage) : Summary.summary(value);
            case "string[]":
                return Summary.summary(value && value.join ? value.join(", ") : value);
            case "SensitiveValue":
                return value.HasValue === false ?  Summary.placeholder(noValueMessage) : Summary.summary("Value set");
        }
    };

    const getReadonlyValue = (property: MetadataTypeDescriptor, value: any) => {
        switch (property.Type) {
            case "bool":
            case "boolean":
            case "bool?":
                return getBooleanDisplayValue(value);
            case "string":
            case "text":
            case "long":
            case "long?":
            case "int":
            case "int?":
            case "string[]":
            case "raw_map":
            case "raw_list":
                return value === null || value === "" ? noValueMessage : value.toString();
            case "SensitiveValue":
                return value.HasValue === false ? noValueMessage : "Value set";
        }
    };

    const getSelectOptions = (property: MetadataTypeDescriptor): Options => {
        if (property.DisplayInfo.Options && property.DisplayInfo.Options.Values) {
            const objectKeys = Object.getOwnPropertyNames(property.DisplayInfo.Options.Values);
            const options =  objectKeys.map(key => ({ value: key.toString(), text: property.DisplayInfo.Options.Values[key].toString() }));
            return options;
        } else if (property.DisplayInfo.ListApi) {
            return []; // TODO: load from api
        } else {
            return [];
        }
    };

    const getValidation = (property: MetadataTypeDescriptor) => {
        const controlType = getControlType(property);
        if (property.DisplayInfo.Required && controlType !== ControlType.Checkbox) {
            return required(MetadataTypeHelpers.getRequiredMessage(property));
        } else {
            return null;
        }
    };

    const getInputControl = (property: MetadataTypeDescriptor, dataContext: DataContext, getBoundFieldProps?: () => BoundFieldProps) => {
        let value = dataContext[property.Name];
        if (value && property.Type === "string[]") {
            // We might have a string value that was changed to an array, so don't
            // assume join is a valid method.
            if (value.join) {
                value = value.join("\n");
            }
        }

        if (property.DisplayInfo.ReadOnly) {
            const displayValue = getReadonlyValue(property, value);
            return <span>{displayValue}</span>;
        }

        const inputType = getControlType(property);
        const formProps = {
            label: property.DisplayInfo.Label,
            value: value || "",
            onChange: (newValue: any) => onChange(property, dataContext, newValue)
        };
        const boundFieldProps: BoundFieldProps = getBoundFieldProps ? getBoundFieldProps() : {};

        switch (inputType) {
            case ControlType.SingleLineText:
                return <Either flag={props.isBindable}
                    renderLeft={() => <Text id={property.Name} {...formProps} />}
                    renderRight={() =>  <VariableLookupText id={property.Name} {...formProps} {...boundFieldProps}/>}
                />;
            case ControlType.MultiLineText:
                return <Either flag={props.isBindable}
                    renderLeft={() => <Text id={property.Name} {...formProps}   multiLine={true} />}
                    renderRight={() =>  <VariableLookupText id={property.Name} {...formProps}  multiLine={true} {...boundFieldProps}/>}
                />;
            case ControlType.Select:
                return (
                    <Either flag={props.isBindable}
                            renderLeft={() => (
                                <Select items={getSelectOptions(property)}
                                        allowClear={false}
                                        {...formProps}/>
                            )}
                            renderRight={() => (
                                <BoundSelect items={getSelectOptions(property)}
                                             allowClear={false}
                                             resetValue={""}
                                             {...formProps}
                                             {...boundFieldProps}/>
                            )}
                />);
            case ControlType.Checkbox: {
                return <Either flag={props.isBindable}
                               renderLeft={() => <Checkbox {...formProps} />}
                               renderRight={() => <BoundStringCheckbox resetValue={""}{...formProps}{...boundFieldProps} />}
                        />;
            }
            case ControlType.Sensitive:
                return <Either flag={props.isBindable}
                               renderLeft={() => <Sensitive {...formProps} />}
                               renderRight={() => (
                                    <BoundSensitive label={property.DisplayInfo.Label}
                                                    resetValue={""}
                                                    {...formProps}
                                                    {...boundFieldProps} />
                                )}
                        />;
            default:
                return <Text id={property.Name}
                            {...formProps} />;
        }
    };

    const renderProperty = (property: MetadataTypeDescriptor, dataContext: DataContext): React.ReactNode => {
        if (MetadataTypeHelpers.isCompositeType(property)) {
            const compositeType = props.types.filter(t => t.Name === property.Type)[0];
            return renderSection(compositeType, dataContext[property.Name], property.Name);
        }
        const controlType = getControlType(property);
        const selectOptions = getSelectOptions(property);
        const description = property.DisplayInfo && property.DisplayInfo.Description
                                ? property.DisplayInfo.Description
                                :  `Provide a value for ${property.DisplayInfo.Label}`;

        const controlToRender = getInputControl(property, dataContext, props.getBoundFieldProps);
        return <ExpandableFormSection errorKey={property.Name}
                    title={property.DisplayInfo.Label}
                    help={description}
                    key={property.Name}
                    isExpandedByDefault={false}
                    summary={createSummary(property, dataContext[property.Name])}>
                        {controlToRender}
                </ExpandableFormSection>;
    };

    const renderSection = (compositeType: MetadataTypeCollectionDescriptor, dataContext: DataContext, sectionName: string) => {
        const types = compositeType && compositeType.Properties && compositeType.Properties.map((t) => renderProperty(t, dataContext));
        const sectionHeading = sectionName && <FormSectionHeading title={sectionName} key={sectionName}/>;
        return <div key={sectionName}>
             {sectionHeading}
             <div>
                {types}
             </div>
         </div>;
    };

    const onChange = (property: MetadataTypeDescriptor, dataContext: DataContext, value: any) => {
        let boundValue = value;
        if (property.Type === "string[]") {
            boundValue = value.split("\n");
        }
        // mutate state and trigger UI refresh
        // it would be really nice to replace this
        dataContext[property.Name] = boundValue;
        if (props.onChange) {
            props.onChange(props.values);
        }
    };

    if (props && props.types && props.types.length > 0) {

        return <div>
            {props.description && <Section className={styles.markdownNote}><Markdown markup={props.description} /></Section>}
            {renderSection(props.types[0], props.values, null)}
        </div>;
    } else {
        console.error("no types provided");
    }

    return null;
};

export default DynamicForm;