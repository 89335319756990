exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_preWrapped__2WHzi {\n  padding: 6.5pt;\n  margin: 0 0 7pt;\n  font-size: 9pt;\n  word-break: break-all;\n  word-wrap: break-word;\n  background-color: white;\n  border: 0.0625rem dashed #cccccc;\n  border-radius: 0.125rem;\n}\n", ""]);

// exports
exports.locals = {
	"preWrapped": "style_preWrapped__2WHzi"
};