import * as React from "react";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import Text from "components/form/Text/Text";
import {sortBy, maxBy} from "lodash";
import {SortableItemModel} from "components/SortableList/SortableList";
import SortTags from "./SortTags";
import TagItemEdit from "./TagItemEdit";
import TagModel from "./TagModel";
import {ColorPicker} from "components/form";
const styles = require("./style.less");
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import {Permission} from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { getTagIndexForTagSets } from "components/tenantTagsets";

interface TagListEditProps {
    tags: TagModel[];
    currentTag?: Partial<TagModel>;
    onChange(tags: TagModel[]): void;
    onClose(currentTag: Partial<TagModel>): void;
}

interface TagListEditState {
    name: string;
    description: string;
    color: string;
    sortedTags: SortableItemModel[];
}

export default class TagListEdit extends React.Component<TagListEditProps, TagListEditState> {
    constructor(props: TagListEditProps) {
        super(props);

        const items = sortBy(this.props.tags, t => t.sortOrder).map(t => ({
            Id: t.localId,
            Name: t.name
        }));

        this.state = {
            name: this.props.currentTag ? this.props.currentTag.name : "",
            description: this.props.currentTag ? this.props.currentTag.description : "",
            color: this.props.currentTag ? this.props.currentTag.color : DEFAULT_COLOR,
            sortedTags: items
        };
    }

    getTagSetIncludingAnyNewTag(): TagModel[] {
        if (this.state.name) {
            const tags = this.getTagsWithNewItem();
            this.setState({
                name: "",
                description: "",
                color: DEFAULT_COLOR
            });
            return tags;
        }
        return this.props.tags;
    }

    handleTagEdit = (originalTag: TagModel, newTag: TagModel) => {
        const tags = [...this.props.tags];
        tags.splice(tags.indexOf(originalTag), 1);
        tags.push(newTag);
        this.props.onChange(tags);
    }

    getTagsWithNewItem = () => {
        const tags = [...this.props.tags];
        const maxSortOrder = tags.length > 0 ? maxBy(tags, t => t.sortOrder).sortOrder : 1;
        tags.push({
            originalId: null,
            localId: (Math.random() + ""),
            name: this.state.name,
            description: this.state.description,
            sortOrder: maxSortOrder + 1,
            color: this.state.color,
            deleted: false,
        });
        return tags;
    }

    handleAdd = () => {
        const tags = this.getTagsWithNewItem();
        this.props.onChange(tags);
        this.setState({
            name: "",
            description: "",
            color: DEFAULT_COLOR
        });
    }

    componentWillReceiveProps(newProps: TagListEditProps) {
        const items = sortBy(newProps.tags, t => t.sortOrder).map(t => ({
            Id: t.localId,
            Name: t.name
        }));
        this.setState({sortedTags: items});
    }

    componentWillUnmount() {
        const saveTag = {
            name: this.state.name,
            description: this.state.description,
            color: this.state.color,
        };
        this.props.onClose(saveTag);
    }

    render() {
        return <div>
            <PermissionCheck permission={[Permission.TagSetCreate, Permission.TagSetEdit]}>
                <div className={styles.reorder}>
                    <OpenDialogButton label="Reorder Tags">
                        <SortTags tags={this.props.tags}
                                onTagsSorted={(sortedTags) => {
                                    const newOrder = this.props.tags
                                        .map(tag => ({...tag, sortOrder: sortedTags.indexOf(tag.localId)}));
                                    this.props.onChange(newOrder);
                                }}/>
                    </OpenDialogButton>
                </div>
            </PermissionCheck>
            <div className={styles.newTagContainer}>
                <div>
                    <Text value={this.state.name}
                      label="New tag name"
                      onChange={(name) => this.setState({name})}/>
                    <Text value={this.state.description}
                      label="Optional description"
                      onChange={(description) => this.setState({description})}/>
                </div>
                <div>
                    <ColorPicker value={this.state.color}
                                 defaultColor="#6e6e6e"
                                 label="Color"
                                 onChange={(color) => this.setState({color})}/>

                    <ActionButton type={ActionButtonType.Secondary}
                                  disabled={this.state.name === ""}
                                  label="Add"
                                  onClick={this.handleAdd}/>
                </div>
            </div>
            {sortBy(this.props.tags, t => t.sortOrder)
                .map(t => <TagItemEdit tag={t}
                                       onChange={(newTag) => this.handleTagEdit(t, newTag)}
                                       key={t.localId}/>)}
        </div>;
    }
}

export const DEFAULT_COLOR: string = "#6e6e6e";
