exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_listItem__2xFzu {\n  padding: 1.25rem;\n  word-wrap: break-word;\n  font-size: 0.875rem !important;\n}\n.style_listItem__2xFzu:hover {\n  background-color: rgba(153, 153, 153, 0.08) !important;\n}\n.style_listItem__2xFzu > div:first-child {\n  padding: 0!important;\n}\n.style_listItem__2xFzu > div:first-child > div:first-child {\n  padding: 0.875rem 1rem!important;\n}\n.style_emptyList__3wyfm {\n  padding: 0.5rem 1rem;\n  font-size: 0.875rem;\n  color: #9e9e9e;\n}\n.style_loadMore__1aFgx {\n  text-align: center;\n}\n.style_pagingControlContainer__HVBB1 {\n  margin: 1.5rem 1rem;\n}\n.style_disablePagingEvents__1U2Kg {\n  pointer-events: none;\n}\n", ""]);

// exports
exports.locals = {
	"listItem": "style_listItem__2xFzu",
	"emptyList": "style_emptyList__3wyfm",
	"loadMore": "style_loadMore__1aFgx",
	"pagingControlContainer": "style_pagingControlContainer__HVBB1",
	"disablePagingEvents": "style_disablePagingEvents__1U2Kg"
};