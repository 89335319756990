import * as React from "react";
import { CronTriggerScheduleResource, TriggerScheduleIntervalResource, TriggerScheduleResource, ServerTimezoneResource } from "client/resources";
import ScheduleStart from "./ScheduleStart";
import { ExpandableFormSection, Summary, Note, Text, Select } from "components/form";
import cronstrue from "cronstrue";
import ExternalLink from "../../../../../../components/Navigation/ExternalLink/ExternalLink";

interface CronExpressionScheduledTriggerEditorProps {
    schedule: CronTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: CronTriggerScheduleResource): void;
}

interface CronExpressionScheduledTriggerEditorState {
    scheduleCronExpression: string;
    scheduleTimezone: string;
}

export class CronExpressionScheduledTriggerEditor extends React.Component<CronExpressionScheduledTriggerEditorProps, CronExpressionScheduledTriggerEditorState> {
    constructor(props: CronExpressionScheduledTriggerEditorProps) {
        super(props);
        this.state = this.initState(this.props.schedule);
    }

    componentWillReceiveProps(newProps: CronExpressionScheduledTriggerEditorProps) {
        if (newProps.schedule.CronExpression !== this.props.schedule.CronExpression ||
            newProps.schedule.Timezone !== this.props.schedule.Timezone) {
            this.setState(this.initState(newProps.schedule));
        }
    }

    render() {
        return (
            <div>
                <ExpandableFormSection
                    errorKey="ScheduleCronExpression"
                    title="Cron Expression"
                    summary={this.getCronExpressionSummary()}
                    help="Enter the cron expression to configure when the trigger should execute">
                    <Text
                        label="Cron expression"
                        value={this.state.scheduleCronExpression}
                        onChange={this.onScheduleCronExpressionChange} />
                    <Note>For information about cron expressions, see our docs:
                        <ExternalLink href="CronExpressions"> Constructing a cron expression in Octopus</ExternalLink>.
                    </Note>
                </ExpandableFormSection>
                <ScheduleStart
                    timezone={this.state.scheduleTimezone}
                    timezones={this.props.timezones}
                    onTimezoneChanged={this.onScheduleTimezoneChange} />
            </div>
        );
    }

    initState(value?: CronTriggerScheduleResource) {
        const schedule = value || new CronTriggerScheduleResource();
        return {
            scheduleCronExpression: schedule.CronExpression,
            scheduleTimezone: schedule.Timezone
        };
    }

    getCronExpressionSummary() {
        if (this.state.scheduleCronExpression && this.state.scheduleCronExpression.trim().split(" ").length >= 5) {
            return Summary.summary(<span><strong>{cronstrue.toString(this.state.scheduleCronExpression)}</strong></span>);
        }
        return Summary.placeholder("Enter the cron expression to configure when the trigger should execute");
    }

    onScheduleCronExpressionChange = (cronExpression: string) => {
        this.setState({
            scheduleCronExpression: cronExpression
        }, () => this.raiseChange());
    }

    onScheduleTimezoneChange = (timezone: string) => {
        this.setState({
            scheduleTimezone: timezone
        }, () => this.raiseChange());
    }

    raiseChange() {
        this.props.onScheduleChange({
            ...this.props.schedule,
            CronExpression: this.state.scheduleCronExpression,
            Timezone: this.state.scheduleTimezone
        });
    }
}