exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_hoverlessContainer__15gBS:hover {\n  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12) !important;\n}\n.style_logo__2SdWG {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"hoverlessContainer": "style_hoverlessContainer__15gBS",
	"logo": "style_logo__2SdWG"
};