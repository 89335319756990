import * as React from "react";
import {default as Summary} from "components/form/Sections/Summary";
import {PropertyValueResource, ControlType} from "client/resources";
import selectOptionsToItems, { Options } from "components/form/Select/Options";
import ActionTemplateParameterInput, {ActionTemplateParameterInputProps} from "./ActionTemplateParameterInput";
import ExpandableFormSection from "../form/Sections/ExpandableFormSection";
import {getHelpText, getLabel, hasParameterChanged} from "./ActionTemplateParameterInputRenderer";

type HelpTextMarkupProvider = (label: string) => string;

export default class ActionTemplateParameterInputExpandableFormElement extends React.Component<ActionTemplateParameterInputProps & {
    isExpandedByDefault?: boolean
    customHelpText?: HelpTextMarkupProvider
}, never> {
    shouldComponentUpdate(newProps: ActionTemplateParameterInputProps) {
        return hasParameterChanged(newProps, this.props);
    }
    render() {
        const {isExpandedByDefault, customHelpText, ...inputProps} = this.props;
        const parameter = inputProps.parameter;
        const options = selectOptionsToItems(parameter.DisplaySettings["Octopus.SelectOptions"]);
        const type = parameter.DisplaySettings["Octopus.ControlType"];
        const label = getLabel(parameter);
        return <ExpandableFormSection
            errorKey={parameter.Name}
            title={label}
            isExpandedByDefault={isExpandedByDefault}
            help={getHelpText(parameter, customHelpText)}
            summary={createSummaryFor(label, type, options)(this.props.value)}>
            <ActionTemplateParameterInput {...inputProps}/>
        </ExpandableFormSection>;
    }
}

function createSummaryFor(label: string, type: ControlType, options: Options) {
    return (value: PropertyValueResource) => {
        if (value === undefined || value === null) {
            return Summary.placeholder("Provide " + label);
        }

        if (typeof value === "object") {
            return Summary.summary("******");
        }

        if (type === ControlType.Select) {
            const option = options.find(o => o.value === value);
            if (option) {
                return Summary.summary(option.text);
            }
        }

        return Summary.summary(value);
    };
}