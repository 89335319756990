import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink";
import { ActionButton } from "components/Button";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { Callout, CalloutType } from "components/Callout/Callout";
import { Permission } from "client/resources/permission";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import routeLinks from "routeLinks";
import Note from "components/form/Note/Note";
const styles = require("./style.less");
import { danger, success } from "colors";
import { semibold } from "../../../../fontWeights";
import DateFormatter from "utils/DateFormatter/DateFormatter";

interface BuiltInRepositoryIndexingProps {
    totalPackages: number;
    lastSyncStatus?: string;
    lastSyncId?: string;
    lastSyncCompletedTime?: string;
    isBuiltInRepoSyncEnabled: boolean;
    busy: Promise<any>;
    onReindexClick(): Promise<any>;
    setIsBuiltInRepoSyncEnabled(value: boolean): Promise<any>;
}

class BuiltInRepositoryIndexing extends BaseComponent<BuiltInRepositoryIndexingProps, DataBaseComponentState> {
    constructor(props: BuiltInRepositoryIndexingProps) {
        super(props);
        this.state = {};
    }

    toggleReindexEnabled() {
        return this.props.setIsBuiltInRepoSyncEnabled(!this.props.isBuiltInRepoSyncEnabled);
    }

    onReindexClick() {
        return this.props.onReindexClick();
    }

    getLastSyncStatus(): JSX.Element {
        const statusText = this.props.lastSyncStatus || "Unknown";
        const statusLink = this.props.lastSyncId
            ? <InternalLink to={routeLinks.task(this.props.lastSyncId).root} weight={semibold}>
                {this.props.lastSyncCompletedTime
                    ? DateFormatter.momentAgo(this.props.lastSyncCompletedTime)
                    : statusText}
            </InternalLink>
            : statusText;

        let statusTextHighlightElement: JSX.Element = null;
        if (this.props.lastSyncStatus === "Succeeded") {
            statusTextHighlightElement = <span className={styles.taskSucceeded}><em className="fa fa-check" style={{ color: success }} /> Last re-index: <strong>{statusText} </strong></span>;
        } else if (this.props.lastSyncStatus === "Failed") {
            statusTextHighlightElement = <span className={styles.taskFailed}><em className="fa fa-exclamation-triangle" style={{ color: danger }} /> Last re-index: <strong>{statusText}</strong></span>;
        } else {
            statusTextHighlightElement = <span>Last re-index: <strong>{statusText}</strong></span>;
        }

        return <Note style={{margin: "1rem 0"}}>
            <div>{statusTextHighlightElement}</div>
            <div style={{paddingTop: "0.5rem"}}>{statusLink}</div>
        </Note>;
    }

    render() {
        const message = this.props.isBuiltInRepoSyncEnabled
            ? <span>The built-in package repository <strong>will</strong> be re-indexed at startup.</span>
            : <span>The built-in package repository <strong>will not</strong> be re-indexed at startup.</span>;

        return <div>
            <h4>Package Indexing</h4>
            <p>Number of packages: <strong>{this.props.totalPackages}</strong></p>
            {this.getLastSyncStatus()}
            <p>{message}</p>
            <PermissionCheck permission={Permission.ConfigureServer}
                alternate={<Callout type={CalloutType.Information}>
                    The {Permission.ConfigureServer} permission is required to change the repository indexing status
                </Callout>}>
                <div>
                    <ActionButton label={this.props.isBuiltInRepoSyncEnabled ? "Disable" : "Enable"}
                        busyLabel={this.props.isBuiltInRepoSyncEnabled ? "Disabling..." : "Enabling..."}
                        onClick={() => this.toggleReindexEnabled()}
                        disabled={this.state.busy} />
                    <ActionButton label="Re-index now"
                        onClick={() => this.onReindexClick()}
                        busyLabel="Queuing task..."
                        disabled={this.state.busy}
                    />
                </div>
            </PermissionCheck>
            <Note>Octopus can automatically re-index the built-in repository at startup to ensure that it is in sync. <ExternalLink href="ReIndexBuiltInRepository">Learn more.</ExternalLink></Note>
        </div>;
    }
}

export default BuiltInRepositoryIndexing;