import * as React from "react";
import RadioButtonImpl from "material-ui/RadioButton";
import {primary, secondaryText} from "../../../colors";
const styles = require("./style.less");
import ToggleRadioButtonChecked from "material-ui/svg-icons/toggle/radio-button-checked";

interface RadioButtonProps {
    isDefault: boolean;
    [x: string]: any;
}

class RadioButton extends React.Component<any, any> {
    radioButtonImpl: RadioButtonImpl;

    componentDidMount() {
        if (this.props.autoFocus) {
            // this digs into the RadioButton implementation to set focus
            const internalSwitch = this.radioButtonImpl.refs.enhancedSwitch as any;
            internalSwitch.refs.checkbox.focus();
            internalSwitch.tabPressed = true;
            internalSwitch.handleFocus();
        }
    }

    render() {

        const {
            isDefault,
            label,
            image,
            ...otherProps
        } = this.props;
        const newlabel = isDefault
            ? <span className={styles.isDefault}>{label}</span>
            : label;

        return <RadioButtonImpl
            ref={ref => this.radioButtonImpl = ref}
            label={newlabel}
            labelStyle={this.props.disabled ? {color: secondaryText} : {}}
            checkedIcon={<ToggleRadioButtonChecked style={{fill: primary}} />}
            iconStyle={{ marginRight: "0.5rem" }}
            {...otherProps}
        />;
    }
}

(RadioButton as any).defaultProps = {
    checked: false,
    isDefault: false
};

export default RadioButton;