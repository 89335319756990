import * as React from "react";
import Chip from "./Chip";
import { UserResource } from "client/resources";
import { CommonChipProps, matchesToChips } from "components/Chips";

const UserChip: React.StatelessComponent<{user: UserResource} & CommonChipProps> = props => {
    const { user, ...rest } = props;
    const name = userDisplayName(user);
    const description = "User: " + name;
    return <Chip description={description} {...rest}>{name}</Chip>;
};

function userDisplayName(resource: UserResource) {
    return resource.DisplayName !== resource.Username
        ? `${resource.DisplayName} (${resource.Username})`
        : resource.DisplayName;
}

function userList(set: UserResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <UserChip user={x} key={x.Id} />);
}

export {
    UserChip,
    userDisplayName,
    userList
};