import * as React from "react";
import { DaysPerWeekTriggerScheduleResource } from "client/resources";
import { Summary } from "components/form";

export default class DaysPerWeekDescriptionHelper {
    constructor(
        private readonly schedule: DaysPerWeekTriggerScheduleResource
    ) {}

    public getSummary(emptyText: string) {
        if (this.noDaysSelected()) {
            return emptyText || "";
        }

        let summary = "";
        if (this.runsOnWeekdays()) {
            summary = "weekdays";
        } else if (this.runsOnWeekends()) {
            summary = "weekends";
        } else {
            summary = this.getDaysScheduledToRunOn();
        }

        return (
            <span> on <strong>{summary}</strong></span>
        );
    }

    private noDaysSelected() {
        return !this.schedule.Monday &&
            !this.schedule.Tuesday &&
            !this.schedule.Wednesday &&
            !this.schedule.Thursday &&
            !this.schedule.Friday &&
            !this.schedule.Saturday &&
            !this.schedule.Sunday;
    }
    private runsOnWeekdays(): boolean {
        return this.schedule.Monday &&
            this.schedule.Tuesday &&
            this.schedule.Wednesday &&
            this.schedule.Thursday &&
            this.schedule.Friday &&
            !this.schedule.Saturday &&
            !this.schedule.Sunday;
    }

    private runsOnWeekends(): boolean {
        return this.schedule.Saturday &&
            this.schedule.Sunday &&
            !this.schedule.Monday &&
            !this.schedule.Tuesday &&
            !this.schedule.Wednesday &&
            !this.schedule.Thursday &&
            !this.schedule.Friday;
    }

    private getDaysScheduledToRunOn(): string {
        const daysToRunOn = [];
        if (this.schedule.Monday) {
            daysToRunOn.push("Monday");
        }
        if (this.schedule.Tuesday) {
            daysToRunOn.push("Tuesday");
        }
        if (this.schedule.Wednesday) {
            daysToRunOn.push("Wednesday");
        }
        if (this.schedule.Thursday) {
            daysToRunOn.push("Thursday");
        }
        if (this.schedule.Friday) {
            daysToRunOn.push("Friday");
        }
        if (this.schedule.Saturday) {
            daysToRunOn.push("Saturday");
        }
        if (this.schedule.Sunday) {
            daysToRunOn.push("Sunday");
        }
        return daysToRunOn.join(", ");
    }
}