exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1dNz- {\n  display: flex;\n  height: 48px;\n  width: 100%;\n  max-width: 26.5rem;\n  /* iPad friendly */\n  min-width: 0;\n  align-items: center;\n}\n.style_content__2poiA {\n  font-family: Roboto, sans-serif;\n  font-size: 1rem;\n  line-height: 1.5rem;\n  color: rgba(0, 0, 0, 0.87);\n  letter-spacing: normal;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.style_iconContainer__2SUoy {\n  padding-right: 0.5rem;\n  padding-top: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1dNz-",
	"content": "style_content__2poiA",
	"iconContainer": "style_iconContainer__2SUoy"
};