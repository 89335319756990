import * as React from "react";
import { session } from "clientInstance";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import routeLinks from "../../routeLinks";

export default class SecureRoute extends React.Component<any, any> {
    render() {
        const { component: Component, ...rest } = this.props;
        return (<ReloadableRoute {...rest} render={(props: any) => {
            return session.isAuthenticated() ?
                <Component {...rest} /> :
                <InternalRedirect to={{ pathname: routeLinks.currentUser.signIn, state: { from: props.location } }} />;
        }
        } />);
    }
}