import Client from "../client";
import ConfigurationRepository from "./configurationRepository";
import DashboardConfigurationResource from "../resources/dashboardConfigurationResource";

class DashboardConfigurationRepository extends ConfigurationRepository<DashboardConfigurationResource> {
    constructor(client: Client) {
        super("DashboardConfiguration", client);
    }
}

export default DashboardConfigurationRepository;