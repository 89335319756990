import BasicRepository from "./basicRepository";
import Client from "../client";
import {CertificateResource} from "../resources/certificateResource";

type CertificateRepositoryListArgs = {
    archived?: boolean;
    skip?: number;
    take?: number;
    search?: string;
    tenant?: string;
    firstResult?: string;
    orderBy?: string;
};

class CertificateRepository extends BasicRepository<CertificateResource, CertificateResource, CertificateRepositoryListArgs> {
    constructor(client: Client) {
        super("Certificates", client);
    }

    names(projectId: string, projectEnvironmentsFilter: any) {
        return this.client.get(this.client.getLink("VariableNames"), {
            project: projectId,
            projectEnvironmentsFilter: projectEnvironmentsFilter ?
                projectEnvironmentsFilter.join(",") : projectEnvironmentsFilter
        });
    }
    nonPrintableChars(variableSet: any) {
        return this.client.post(this.client.getLink("VariablesNonPrintableChars"), variableSet);
    }
}

export default CertificateRepository;