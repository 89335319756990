import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "../../DataBaseComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import { VariableLookupText } from "components/form/VariableLookupText";
import PackageSelector from "../../PackageSelector/PackageSelector";
import FeedResource, {FeedType} from "../../../client/resources/feedResource";
import {PackageReference} from "../../../client/resources/packageReference";
import PackageDownloadOptions from "../../PackageDownloadOptions/PackageDownloadOptions";
import * as _ from "lodash";
import Note from "../../form/Note/Note";
import {PackageAcquisitionLocation} from "../../../client/resources/packageAcquisitionLocation";
import {RunOn} from "../../../areas/projects/components/DeploymentProcess/ActionDetails";

const DefaultYamlFileLocation = "values.yaml";
interface PackageReferenceProps {
    packageReference: PackagedHelmValuesReference;
    runOn?: RunOn;
    feeds: FeedResource[];
    localNames: string[];
    projectId: string;
    onChange(PackageReference: PackagedHelmValuesReference): boolean;
    refreshFeeds(): Promise<any>;
}

interface ScriptPackageReferenceState extends DataBaseComponentState {
    packageReferenceId: string;
    packageId: string;
    feedId: string;
    valuesFilePath: string;
    acquisitionLocation: string;
}

export interface PackagedHelmValuesReference extends PackageReference<PackagedHelmValuesProperties> {
}

export interface PackagedHelmValuesProperties {
   "ValuesFilePath": string;
}

export class PackagedHelmValuesDialog extends DataBaseComponent<PackageReferenceProps, ScriptPackageReferenceState> {

    constructor(props: PackageReferenceProps) {
        super(props);

        this.state = {
            packageReferenceId: this.props.packageReference.Id,
            packageId: this.props.packageReference.PackageId,
            feedId: this.props.packageReference.FeedId,
            acquisitionLocation: this.props.packageReference.AcquisitionLocation,
            valuesFilePath: this.props.packageReference.Properties["ValuesFilePath"]
        };
    }

    render() {
        const feed = _.find(this.props.feeds, f => f.Id === this.state.feedId);

        return <OkDialogLayout
            onOkClick={this.save}
            busy={this.state.busy}
            errors={this.state.errors}
            title="Reference a Package">
            {this.props.packageReference && <div>
                {/*<VariableLookupText*/}
                    {/*label="Name"*/}
                    {/*localNames={this.props.localNames}*/}
                    {/*projectId={this.props.projectId}*/}
                    {/*value={this.state.name}*/}
                    {/*onChange={name => this.setState({name})}*/}
                {/*/>*/}
                <PackageSelector
                    packageId={this.state.packageId}
                    feedId={this.state.feedId}
                    onPackageIdChange={packageId => this.setState({packageId})}
                    onFeedIdChange={feedId => this.setState({feedId})}
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    feeds={this.props.feeds}
                    feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.GitHub]}
                    refreshFeeds={this.props.refreshFeeds} />
                <PackageDownloadOptions
                    packageAcquisitionLocation={this.state.acquisitionLocation}
                    onPackageAcquisitionLocationChanged={acquisitionLocation => this.setState({acquisitionLocation})}
                    runOn={this.props.runOn}
                    showNotAcquiredOption={true}
                    feed={feed}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames}/>
                <div style={{paddingTop: "1rem"}}>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.state.valuesFilePath}
                        onChange={(x) => this.setState({valuesFilePath: x})}
                        hintText={DefaultYamlFileLocation}
                        label="Relative path to values file" />
                    <Note>
                        A newline-separated list of file names, relative to the package root to be included as additional <code>--values</code> files. Variable replacement will be run on these files before used.
                        Extended template and wildcard syntax is supported. E.g., <em>values.{`#{Octopus.Environment.Name}`}.yaml</em>, <em>**\specific-folder\*.yaml</em>.
                    </Note>
                </div>
            </div>}
        </OkDialogLayout>;
    }

    save = () => {
        const packageReference = {
            Id: this.state.packageReferenceId,
            PackageId: this.state.packageId,
            FeedId: this.state.feedId,
            Properties: {
                ValuesFilePath: this.state.valuesFilePath || DefaultYamlFileLocation
            },
            AcquisitionLocation: PackageAcquisitionLocation.ExecutionTarget
        };

        return this.props.onChange(packageReference);
    }
}
