import * as React from "react";
import { OverflowMenu } from "components/Menu";
import { MenuItem } from "components/Menu/OverflowMenu";
const styles = require("./style.less");

const ListItem: React.StatelessComponent<{children: any, overflowMenuItems: Array<MenuItem | MenuItem[]>}> = props => {
   return <span className={styles.menuItem}>
      <span className={styles.content}>
         {props.children}
      </span>
      <span>
         <OverflowMenu menuItems={props.overflowMenuItems} />
      </span>
   </span>;
};

ListItem.displayName = "ListItem";

export default ListItem;
