exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_taskDetailsCell__dEHne {\n  display: flex;\n}\n.style_blockIcon__36wOe {\n  opacity: 0.2;\n  font-size: 2.5rem;\n}\n.style_cell__357lZ {\n  padding: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"taskDetailsCell": "style_taskDetailsCell__dEHne",
	"blockIcon": "style_blockIcon__36wOe",
	"cell": "style_cell__357lZ"
};