exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_downloadButton__3I7-K {\n  margin: 1rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"downloadButton": "styles_downloadButton__3I7-K"
};