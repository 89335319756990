exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_overviewRow__2BWpC {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"overviewRow": "style_overviewRow__2BWpC"
};