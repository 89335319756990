import { Resource, NamedResource, SpaceResource } from "client/resources";
import Chip from "./Chip";
import {
    EnvironmentIcon,
    ExcludedEnvironmentIcon,
    ProjectIcon,
    MachineIcon,
    WorkerPoolIcon,
    ExcludedMachineIcon
} from "../Icon";
import * as React from "react";
import Avatar from "material-ui/Avatar";
import SvgTenantIcon from "material-ui/svg-icons/action/account-circle";
import SvgTeamIcon from "material-ui/svg-icons/social/group";
import SvgChannelIcon from "material-ui/svg-icons/communication/call-split";
import SvgRoleIcon from "material-ui/svg-icons/maps/local-offer";
import SvgSpaceIcon from "material-ui/svg-icons/action/group-work";

import {
    ProjectResource,
    TenantResource,
    CertificateResource,
    MachineModelHealthStatusResource,
    CommunicationStyleResource,
} from "client/resources";
import { TeamResource } from "client/resources/teamResource";
import { ProjectGroupResource } from "client/resources/projectGroupResource";
import {
    success, danger, dangerHighlight, alert, disable, white, secondaryText,
    secondaryBackground
} from "colors";
import * as moment from "moment";
import { primaryText } from "colors";
import { UserRoleResource } from "client/resources/userRoleResource";
import NamedReferenceItem from "client/resources/namedReferenceItem";
import { DocumentTypeResource, EventCategoryResource, EventGroupResource, EventAgentResource } from "client/resources/eventResource";
import { LookupResourceChipComponent } from "../LookupResourceChip/LookupResourceChip";
import { EnvironmentResource } from "../../client/resources";
import InternalLink from "../Navigation/InternalLink/InternalLink";
import routeLinks from "../../routeLinks";
import {DeploymentActionPackageResource, displayName} from "../../client/resources/deploymentActionPackageResource";
import { LocationDescriptor } from "history";
const styles = require("./styles.less");

interface ExtraCommonProps {
    description?: string;
    backgroundColor?: string;
    markAsRemoved?: boolean;
    labelColor?: string;
    children?: any;
}

export interface CommonChipProps {
    tabIndex?: number;
    onRequestDelete?: (event: object) => void;
    fullWidth?: boolean;
    noMargin?: boolean;
    to?: LocationDescriptor;
    descriptionPostfix?: string;
}

interface IconStyles {
    margin: number;
    fill: string;
    width: number;
    height: number;
    backgroundColor?: string;
}

const iconStyles: IconStyles = {
    margin: 5,
    fill: primaryText,
    width: 14,
    height: 14
};

export const avatarStyles = {
    width: 24,
    height: 24,
};

const chipWithAvatar = (
        text: string,
        SvgIcon: any,
        props: CommonChipProps & ExtraCommonProps,
        customAvatar?: any,
        description?: string,
        labelColor?: string,
        customIconStyles?: IconStyles,
        fullWidth = false) => {
    const theChip = <Chip backgroundColor={secondaryBackground}
                 fullWidth={fullWidth}
                 labelColor={labelColor || primaryText}
                 description={description || text}
                 avatar={<Avatar style={customAvatar ? customAvatar : avatarStyles}
                                 size={avatarStyles.width}
            icon={<SvgIcon style={customIconStyles || iconStyles} />} />}
          {...props}>
          {text}
    </Chip>;
    if (props.to) {
        return <InternalLink to={props.to} className={styles.clickableChip}>{theChip}</InternalLink>;
    }
    return theChip;
};

export enum ChipIcon {
    Project,
    Machine,
    ExcludedMachine,
    Environment,
    ExcludedEnvironment,
    Role,
    Tenant,
    Channel,
    Team,
    Step,
    StepAction,
    ProjectGroup,
    CommunicationStyle,
    EventCategory,
    EventGroup,
    EventAgent,
    MachineModelHealthStatus,
    DocumentType,
    LibraryVariableSet,
    Space
}

// NOTE:
// This is used to show the user only what they can see, it may be filtered away by permissions
export function matchesToChips<T extends Resource>(set: T[], selected: string[], createChip: (x: T) => JSX.Element): JSX.Element[] {
    return set
        .filter(item => selected && selected.indexOf(item.Id) !== -1)
        .map(item => createChip(item));
}

export function channelChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <ChannelChip channelName={x.Name} key={x.Id} />);
}

export function environmentChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <EnvironmentChip environmentName={x.Name} key={x.Id} />);
}

export function workerPoolChipList(set: NamedResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <WorkerPoolChip workerPoolName={x.Name} key={x.Id} />);
}

// NOTE:
// We have some inconsistency in the application where on summaries we filter away what the user cannot see due to permissions
// eg environments, but if they expand the multiselect we show them missing chips
// we should aim to move to this approach in `environmentChipListIncludingMissing` and SHOW them Ids and Names
// it will require a new API (as not to break other things)
// the objective is to  drive people to Spaces for isolation instead of using the permissions system awkwardly
export function environmentChipListIncludingMissing(set: EnvironmentResource[], selectedIds: string[]) {
    const EnvironmentLookupChipInternal = LookupResourceChipComponent<EnvironmentResource>();

    return selectedIds.map(id => <EnvironmentLookupChipInternal
        lookupCollection={set}
        key={id}
        lookupId={id}
        type={ChipIcon.Environment}
        chipRender={item => <EnvironmentChip environmentName={item.Name} />}
    />);
}

export function tenantChipList(set: TenantResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <TenantChip tenant={x} key={x.Id} />);
}

// Same applies environmentChipListIncludingMissing
// future goal to replace all the variants with this approach
export function tenantChipListIncludingMissing(set: TenantResource[], selectedIds: string[]) {
    const TenantLookupChipInternal = LookupResourceChipComponent<TenantResource>();

    return selectedIds.map(id => <TenantLookupChipInternal
        lookupCollection={set}
        key={id}
        lookupId={id}
        type={ChipIcon.Tenant}
        chipRender={item => <TenantChip tenant={item} />}
    />);
}

export function projectGroupChipList(set: ProjectGroupResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <ProjectGroupChip projectGroup={x} key={x.Id} />);
}

export function projectChipList(set: ProjectResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <ProjectChip project={x} key={x.Id} />);
}

export function documentChipList(set: DocumentTypeResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <DocumentTypeChip documentType={x} key={x.Id} />);
}

export function eventCategoryList(set: EventCategoryResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <EventCategoryChip eventCategory={x} key={x.Id} />);
}

export function eventGroupList(set: EventGroupResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <EventGroupChip eventGroup={x} key={x.Id} />);
}

export function eventAgentList(set: EventAgentResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <EventAgentChip eventAgent={x} key={x.Id} />);
}

export function spaceChipList(set: SpaceResource[], selectedIds: string[]) {
    return matchesToChips(set, selectedIds, x => <SpaceChip space={x} key={x.Id} />);
}

const MissingChip: React.StatelessComponent<{lookupId: string, type: ChipIcon} & CommonChipProps> = props => {

    const { lookupId, type, ...rest } = props;
    const text = "Missing Resource";

    // There are cases where this isn't right, but will just have 1 message to simplify
    // e.g. Variable Snapshots will show this for deleted environments, it's not an issue in that case, but good to show this.
    const description = `The ${ChipIcon[type]} document '${lookupId}' referenced by this record is no longer available or `
        + "you do not have permissions to see this resource. Please check with you Octopus Administrator regarding your "
        + "permissions. If you believe the resource is missing (and this is not permissions-related), please let Octopus "
        + "support know so that we can prevent this from happening in the future.";

    const customIconStyles: IconStyles = {
        ...iconStyles,
        fill: white,
        backgroundColor: danger
    };

    const chip = (svg: any) => chipWithAvatar(
        text,
        svg,
        { ...rest, backgroundColor: dangerHighlight },
        { ...avatarStyles, backgroundColor: danger },
        description,
        danger,
        customIconStyles);

    switch (type) {
        case ChipIcon.Project:
            return chip(ProjectIcon);
        case ChipIcon.Machine:
            return chip(MachineIcon);
        case ChipIcon.ExcludedMachine:
            return chip(ExcludedMachineIcon);
        case ChipIcon.Environment:
            return chip(EnvironmentIcon);
        case ChipIcon.ExcludedEnvironment:
            return chip(ExcludedEnvironmentIcon);
        case ChipIcon.Role:
            return chip(SvgRoleIcon);
        case ChipIcon.Tenant:
            return chip(SvgTenantIcon);
        case ChipIcon.Channel:
            return chip(SvgChannelIcon);
        case ChipIcon.Team:
            return chip(SvgTeamIcon);
        case ChipIcon.Space:
            return chip(SvgSpaceIcon);
        case ChipIcon.Step:
        case ChipIcon.StepAction:
        case ChipIcon.ProjectGroup:
        case ChipIcon.CommunicationStyle:
        case ChipIcon.EventCategory:
        case ChipIcon.EventGroup:
        case ChipIcon.EventAgent:
        case ChipIcon.MachineModelHealthStatus:
        case ChipIcon.DocumentType:
            return <Chip backgroundColor={dangerHighlight} labelColor={danger} description={description} {...rest}>{text}</Chip>;
        default:
            return <Chip backgroundColor={dangerHighlight} labelColor={danger} description={description} {...rest}>{text}</Chip>;
    }
};

const ProjectChip: React.StatelessComponent<{ project: ProjectResource; } & CommonChipProps> = props => {
    const { project, ...rest } = props;
    return chipWithAvatar(project.Name, ProjectIcon, { description: "Project: " + project.Name, ...rest });
};

const ProjectGroupChip: React.StatelessComponent<{ projectGroup: ProjectGroupResource; } & CommonChipProps> = props => {
    const { projectGroup, ...rest } = props;
    const description = "Project group: " + projectGroup.Name;
    return <Chip description={description} {...rest}>{projectGroup.Name}</Chip>;
};

const MachineModelHealthStatusChip: React.StatelessComponent<{
    healthStatus: MachineModelHealthStatusResource
} & CommonChipProps> = props => {
    const { healthStatus, ...rest } = props;
    const description = "Machine health: " + healthStatus.Name;
    return <Chip description={description} {...rest}>{healthStatus.Name}</Chip>;
};

const EndpointCommunicationStyleChip: React.StatelessComponent<{
    commStyle: CommunicationStyleResource;
} & CommonChipProps> = props => {
    const { commStyle, ...rest } = props;
    const description = "Communications style: " + commStyle.Name;
    return <Chip description={description} {...rest}>{commStyle.Name}</Chip>;
};

const EnvironmentChip: React.StatelessComponent<{
    environmentName: string;
    isExcluded?: boolean;
} & CommonChipProps> = props => {
    const { environmentName, isExcluded, ...rest } = props;
    return chipWithAvatar(environmentName, isExcluded ? ExcludedEnvironmentIcon : EnvironmentIcon, {
        description: "Environment: " + environmentName,
        ...rest,
        markAsRemoved: isExcluded
    });
};

const WorkerPoolChip: React.StatelessComponent<{
    workerPoolName: string;
    isExcluded?: boolean;
} & CommonChipProps> = props => {
    const { workerPoolName, isExcluded, ...rest } = props;
    return chipWithAvatar(workerPoolName, isExcluded ? ExcludedEnvironmentIcon : WorkerPoolIcon, {
        description: "Worker pool: " + workerPoolName,
        ...rest,
        markAsRemoved: isExcluded
    });
};

const MachineChip: React.StatelessComponent<{
    machineName: string;
    isExcluded?: boolean;
    isDisable?: boolean;
} & CommonChipProps> = props => {
    const { machineName, isExcluded, isDisable, ...rest } = props;
    return chipWithAvatar(machineName, (isExcluded || isDisable) ? ExcludedMachineIcon : MachineIcon,
        {
            description: "Machine: " + machineName,
            ...rest,
            markAsRemoved: isExcluded,
            backgroundColor: isDisable ? disable : secondaryBackground,
            labelColor: isDisable ? danger : primaryText
        },
        isDisable ? { ...avatarStyles, backgroundColor: danger } : avatarStyles);
};

const TenantChip: React.StatelessComponent<{ tenant: TenantResource } & CommonChipProps> = props => {
    const { tenant, ...rest } = props;
    return chipWithAvatar(tenant.Name, SvgTenantIcon, { description: "Tenant: " + tenant.Name, ...rest });
};

const TeamChip: React.StatelessComponent<{ team: TeamResource } & CommonChipProps> = props => {
    const { team, ...rest } = props;

    const desc = !rest.descriptionPostfix  ? "Team: " + team.Name : "Team: " + team.Name + rest.descriptionPostfix;
    return chipWithAvatar(team.Name, SvgTeamIcon, {description: desc, ...rest});
};

const ChannelChip: React.StatelessComponent<{ channelName: string } & CommonChipProps> = props => {
    const { channelName, ...rest } = props;
    return chipWithAvatar(channelName, SvgChannelIcon, { description: "Channel: " + channelName, ...rest });
};

const RoleChip: React.StatelessComponent<{ role: string } & CommonChipProps> = props => {
    const { role, ...rest } = props;
    return chipWithAvatar(role, SvgRoleIcon, { description: "Role: " + role, ...rest });
};

const CertificateExpiryChip: React.StatelessComponent<{ certificate: CertificateResource } & CommonChipProps> = props => {
    const { certificate, ...rest } = props;
    const now = moment();
    const certificateExpiry = moment(certificate.NotAfter);
    const prefix = certificateExpiry.isAfter(now)
        ? "Expires "
        : "Expired ";
    const expiry = prefix + certificateExpiry.fromNow();
    let color = success;
    if (certificateExpiry.isBefore(now)) {
        color = danger;
    } else if (certificateExpiry.isBefore(now.add(20, "days"))) {
        color = alert;
    }
    const description = "Certificate expiry: " + expiry;
    return <Chip description={description} backgroundColor={"rgba(0,0,0,0)"} bordercolor={color} labelColor={color} {...rest}>{expiry}</Chip>;
};

const StepChip: React.StatelessComponent<{ stepName: string, suffix?: React.ReactNode } & CommonChipProps> = props => {
    const { stepName, suffix, ...rest } = props;
    const description = "Step: " + stepName;
    return <Chip description={description} {...rest}>{stepName}{suffix}</Chip>;
};

const DeploymentActionPackageChip: React.StatelessComponent<{ actionPackage: DeploymentActionPackageResource } & CommonChipProps> = props => {
    const { actionPackage, ...rest } = props;
    const description = !actionPackage.PackageReference
        ? `Step ${actionPackage.DeploymentAction}`
        : `Package ${actionPackage.PackageReference} from step ${actionPackage.DeploymentAction}`;
    return <Chip description={description} {...rest}>{displayName(actionPackage)}</Chip>;
};

const UserRoleChip: React.StatelessComponent<{ userRole: UserRoleResource } & CommonChipProps> = props => {
    const { userRole, ...rest } = props;
    const description = "User role: " + userRole.Name;
    return <Chip {...rest}>{userRole.Name}</Chip>;
};

const ExternalSecurityGroupChip: React.StatelessComponent<{ group: NamedReferenceItem } & CommonChipProps> = props => {
    const { group, ...rest } = props;
    const text = group.DisplayIdAndName ? `${group.DisplayName} (${group.Id})` : group.DisplayName;
    return <Chip {...rest}>{text}</Chip>;
};

const ExternalSecurityGroupNameChip: React.StatelessComponent<{ groupName: string } & CommonChipProps> = props => {
    const { groupName, ...rest } = props;
    return <Chip description={`Indirectly assigned via ${groupName}`} {...rest}>{groupName}</Chip>;
};

const FilterTextChip: React.StatelessComponent<{ filterText: string } & CommonChipProps> = props => {
    const { filterText, ...rest } = props;
    return <Chip {...rest}>{filterText}</Chip>;
};

const EventCategoryChip: React.StatelessComponent<{ eventCategory: EventCategoryResource } & CommonChipProps> = props => {
    const { eventCategory, ...rest } = props;
    const description = "Event category: " + eventCategory.Name;
    return <Chip description={description} {...rest}>{eventCategory.Name}</Chip>;
};

const EventCategoryPreviewChip: React.StatelessComponent<{ eventCategory: EventCategoryResource } & CommonChipProps> = props => {
    const { eventCategory, ...rest } = props;
    const description = "Event category: " + eventCategory.Name;
    return <Chip description={description} {...rest} >{eventCategory.Name}</Chip>;
};

const EventAgentChip: React.StatelessComponent<{ eventAgent: EventAgentResource } & CommonChipProps> = props => {
    const { eventAgent, ...rest } = props;
    const description = "Event agent: " + eventAgent.Name;
    return <Chip description={description} {...rest}>{eventAgent.Name}</Chip>;
};

const DocumentTypeChip: React.StatelessComponent<{ documentType: DocumentTypeResource } & CommonChipProps> = props => {
    const { documentType, ...rest } = props;
    const description = "Document type: " + documentType.Name;
    return <Chip description={description} {...rest}>{documentType.Name}</Chip>;
};

const EventGroupChip: React.StatelessComponent<{ eventGroup: EventGroupResource } & CommonChipProps> = props => {
    const { eventGroup, ...rest } = props;
    const description = "Event group: " + eventGroup.Name;
    return <Chip description={description} {...rest}>{eventGroup.Name}</Chip>;
};

const DisabledChip: React.StatelessComponent<CommonChipProps> = props => {
    return <Chip {...props}
        bordercolor={secondaryText}
        labelColor={secondaryText}
        backgroundColor={"rgba(0,0,0,0)"}
    >Disabled</Chip>;
};

const DefaultOptionChip: React.StatelessComponent<CommonChipProps> = props => {
    return <Chip {...props}>Default</Chip>;
};

const LookupTenantChip: React.StatelessComponent<{ lookupTenants: TenantResource[], id: string } & CommonChipProps> = props => {
    const TenantLookupChipInternal = LookupResourceChipComponent<TenantResource>();
    return <TenantLookupChipInternal
        lookupCollection={props.lookupTenants}
        key={props.id}
        lookupId={props.id}
        type={ChipIcon.Tenant}
        chipRender={item => <TenantChip tenant={item} to={props.to} />} />;
};

const SpaceChip: React.StatelessComponent<{ space: SpaceResource; description?: string; } & CommonChipProps> = props => {
    const { space, description, ...rest } = props;
    return chipWithAvatar(space.Name, SvgSpaceIcon, {description: description || space.Description, ...rest});
};

const DefaultSpaceChip: React.StatelessComponent<CommonChipProps> = props => {
    return <Chip {...props} description="Default Space">Default</Chip>;
};

export {
    MissingChip,
    ProjectChip,
    ProjectGroupChip,
    EnvironmentChip,
    WorkerPoolChip,
    RoleChip,
    TenantChip,
    TeamChip,
    ChannelChip,
    MachineChip,
    CertificateExpiryChip,
    MachineModelHealthStatusChip,
    EndpointCommunicationStyleChip,
    StepChip,
    DeploymentActionPackageChip,
    UserRoleChip,
    ExternalSecurityGroupChip,
    ExternalSecurityGroupNameChip,
    FilterTextChip,
    EventCategoryChip,
    EventCategoryPreviewChip,
    EventAgentChip,
    DocumentTypeChip,
    EventGroupChip,
    DisabledChip,
    DefaultOptionChip,
    LookupTenantChip,
    SpaceChip,
    DefaultSpaceChip
};
