exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VirtualListWithKeyboard_menuContainer__3RXtt {\n  max-height: 25rem;\n}\n.VirtualListWithKeyboard_menuItem__3DSuA {\n  overflow-wrap: break-word;\n}\n.VirtualListWithKeyboard_empty__qv1G4 {\n  padding: 0.625rem;\n}\n", ""]);

// exports
exports.locals = {
	"menuContainer": "VirtualListWithKeyboard_menuContainer__3RXtt",
	"menuItem": "VirtualListWithKeyboard_menuItem__3DSuA",
	"empty": "VirtualListWithKeyboard_empty__qv1G4"
};