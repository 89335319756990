import * as React from "react";
import {Popover as PopoverImpl} from "material-ui";

type Horizontal = "left" | "middle" | "right";
type Vertical = "top" | "center" | "bottom";

interface Origin {
    horizontal: Horizontal;
    vertical: Vertical;
}

interface PopoverAnimationProps {
    open: boolean;
    style?: React.CSSProperties;
}

type ClickAwayReason = "clickAway" | "offScreen";

interface PopoverProps {
    anchorEl?: React.ReactInstance;
    anchorOrigin?: Origin;
    animated?: boolean;
    animation?: React.ComponentClass<PopoverAnimationProps>;
    autoCloseWhenOffScreen?: boolean;
    canAutoPosition?: boolean;
    className?: string;
    onRequestClose?: (reason: ClickAwayReason) => void;
    open?: boolean;
    style?: React.CSSProperties;
    targetOrigin?: Origin;
    zDepth?: number;
    useLayerForClickAway?: boolean;
}

export default class Popover extends React.Component<PopoverProps, any> {
    render() {
        return <PopoverImpl
            scrollableContainer={"window"}
            {...this.props}>
            {this.props.children}
        </PopoverImpl>;
    }
}