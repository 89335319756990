import * as React from "react";
import RaisedButton from "material-ui/RaisedButton";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import {white, success} from "../../../../colors";
import { Permission, EnvironmentResource } from "client/resources";
import routeLinks from "../../../../routeLinks";
import { DeploymentCreateGoal } from "./ReleasesRoutes/releaseRouteLinks";
import { ResourcesById } from "client/repositories/basicRepository";
import {repository, session} from "../../../../clientInstance";
import TenantedDeploymentMode from "client/resources/tenantedDeploymentMode";
import FilterSearchBox from "components/FilterSearchBox";
const styles = require("./style.less");
import Divider from "components/Divider/Divider";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import {baseSizeInPx} from "fontWeights";
import { RaisedButtonProps } from "material-ui";

const deployButtonStyle: Partial<RaisedButtonProps> = {
    labelColor: white,
    backgroundColor: success,
    labelStyle: {
        fontSize: "0.8125rem",
        whiteSpace: "nowrap"
    },
};
const popoverStyle = {
    backgroundColor: success,
    color: white
};
const menuItemStyle = {
    color: white
};

interface DeployButtonProps {
    projectSlug: string;
    projectId: string;
    releaseVersion: string;
    nextDeployments: string[];
    environmentsById: ResourcesById<EnvironmentResource>;
    tenantedDeploymentMode: TenantedDeploymentMode;
}

interface DeployButtonState {
    open: boolean;
    anchor?: any;
    filterText?: string;
}

export class DeployButton extends React.Component<DeployButtonProps, DeployButtonState> {
    constructor(props: DeployButtonProps) {
        super(props);
        this.state = {
            open: false
        };
    }

    handleTouchTap = (event: any) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget
        });
    }

    handleRequestClose = () => {
        this.setState({
            open: false
        });
    }

    render() {
        const deploymentLinks = routeLinks.project(this.props.projectSlug)
            .release(this.props.releaseVersion).deployments;
        const createToLink = (environments?: string) => deploymentLinks.create(environments ? DeploymentCreateGoal.To : null, environments);
        const canDeployToMultipleEnvironments = this.props.tenantedDeploymentMode !== TenantedDeploymentMode.Tenanted;

        if (this.props.nextDeployments) {
            const nextDeploymentEnvironments = this.props.nextDeployments
                .filter(e => this.props.environmentsById.hasOwnProperty(e))
                .filter(e => session.currentPermissions.scopeToSpace(repository.spaceId).isAuthorized({
                    permission: Permission.DeploymentCreate,
                    environmentId: e,
                    projectId: this.props.projectId,
                    tenantId: "*",
                }));
            if (nextDeploymentEnvironments.length > 1) {
                const filteredEnvironments = this.state.filterText ? nextDeploymentEnvironments.filter(envId => {
                    return this.props.environmentsById[envId].Name.toLowerCase().indexOf(this.state.filterText) !== -1;
                    }) : nextDeploymentEnvironments;
                const popover = (
                    <Popover
                        open={this.state.open}
                        style={popoverStyle}
                        canAutoPosition={true} // This is needed for mobile, so the menu repositions correctly.
                        anchorEl={this.state.anchor}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        targetOrigin={{ horizontal: "right", vertical: "top" }}
                        onRequestClose={this.handleRequestClose}>
                        <Menu maxHeight={baseSizeInPx * 40}>
                            <div>
                            {canDeployToMultipleEnvironments && nextDeploymentEnvironments.length === 2 && <InternalLink
                                key="deploy-to-both"
                                to={createToLink(nextDeploymentEnvironments.join(","))}>
                                <MenuItem primaryText={"Deploy to both environments..."} style={menuItemStyle} />
                            </InternalLink>}
                            {canDeployToMultipleEnvironments && nextDeploymentEnvironments.length > 2 && <InternalLink
                                key="deploy-to-all"
                                to={createToLink(nextDeploymentEnvironments.join(","))}>
                                <MenuItem primaryText={`Deploy to all ${nextDeploymentEnvironments.length} environments...`} style={menuItemStyle} />
                            </InternalLink>}
                            {canDeployToMultipleEnvironments &&
                            <div>
                                <Divider />
                                <div className={styles.deployButtonFilterContainer}>
                                    <FilterSearchBox
                                        autoFocus={true}
                                        value={this.state.filterText}
                                        hintText="Filter..."
                                        onChange={(value: string) => {
                                            this.setState({ filterText: value.toLowerCase()});
                                        }}
                                        fullWidth={true}
                                        containerClassName={styles.filterFieldContainer}
                                        iconColor={white}
                                        iconStyle={{width: "20px", height: "20px", top: "14px"}}
                                    />
                                </div>
                            </div>}
                            {filteredEnvironments.map((e: any, i: number) => {
                                const environment = this.props.environmentsById[e];
                                return <InternalLink key={environment.Name} to={createToLink(e)}>
                                    <MenuItem primaryText={environment.Name} style={menuItemStyle} />
                                </InternalLink>;
                            })}
                            </div>
                        </Menu>
                    </Popover>
                );

                return (
                    <div>
                        <RaisedButton
                            {...deployButtonStyle}
                            label="Deploy to..."
                            labelPosition="before"
                            icon={<em className="fa fa-caret-down" style={{ color: white }} />}
                            onClick={this.handleTouchTap}
                        />
                        {popover}
                    </div>
                );
            }

            if (nextDeploymentEnvironments.length === 0) {
                return null;
            }

            return <InternalLink to={createToLink(nextDeploymentEnvironments[0])}>
                <RaisedButton
                    {...deployButtonStyle}
                    label={`Deploy to ${this.props.environmentsById[nextDeploymentEnvironments[0]]
                        ? this.props.environmentsById[nextDeploymentEnvironments[0]].Name
                        : ""}...`}
                />
            </InternalLink>;
        }

        return null;
    }
}