exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_cellWrapper__1cRZB {\n  display: flex;\n  flex-direction: row;\n  flex: 0 0 auto;\n}\n.style_cellContentWrapper__j1ZIl {\n  flex-grow: 1;\n  min-width: 0;\n  flex-basis: 0;\n}\n.style_alignedCellsWrapper__3w0tV {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"cellWrapper": "style_cellWrapper__1cRZB",
	"cellContentWrapper": "style_cellContentWrapper__j1ZIl",
	"alignedCellsWrapper": "style_alignedCellsWrapper__3w0tV"
};