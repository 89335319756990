exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__3mLNm {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__29OiG {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__VcqWJ {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__3sC4w {\n    display: none !important;\n  }\n}\n.style_summaryText__1xXit {\n  text-align: left;\n  font-weight: 400;\n  margin: 0.25rem 0;\n  font-size: 0.875rem;\n}\n.style_summarySection__215pp {\n  margin-top: 0.5rem;\n  color: #333333;\n  font-size: 0.875rem;\n}\n.style_summarySectionKey__1VPCl {\n  text-align: left;\n  font-weight: 600;\n  margin: 0.75rem 0 0.25rem 0;\n}\n.style_summarySectionValue__3McjF {\n  text-align: left;\n  font-weight: 400;\n  margin: 0.25rem 0;\n}\n.style_summaryTable__No5KO {\n  margin-top: 0.5rem;\n  padding: 0 1rem;\n  color: #333333;\n  font-size: 0.875rem;\n}\n.style_summaryTableKey__1J0wb {\n  text-align: left;\n  font-weight: 600;\n  width: 25%;\n}\n@media (max-width: 37.4rem) {\n  .style_summaryTableKey__1J0wb {\n    width: unset;\n    text-align: initial;\n  }\n}\n.style_summaryTableValue__21EGb {\n  text-align: left;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  .style_summaryTableValue__21EGb {\n    text-align: initial;\n  }\n}\n.style_summaryTable__No5KO table {\n  background-color: transparent;\n}\n.style_summaryTable__No5KO tr:hover {\n  background-color: transparent !important;\n}\n.style_summaryTable__No5KO tr {\n  border: 0 !important;\n}\n.style_summaryTable__No5KO td {\n  padding: 0.125rem 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__3mLNm",
	"hideSm": "style_hide-sm__3mLNm",
	"visible-sm": "style_visible-sm__29OiG",
	"visibleSm": "style_visible-sm__29OiG",
	"hide-md": "style_hide-md__VcqWJ",
	"hideMd": "style_hide-md__VcqWJ",
	"visible-md": "style_visible-md__3sC4w",
	"visibleMd": "style_visible-md__3sC4w",
	"summaryText": "style_summaryText__1xXit",
	"summarySection": "style_summarySection__215pp",
	"summarySectionKey": "style_summarySectionKey__1VPCl",
	"summarySectionValue": "style_summarySectionValue__3McjF",
	"summaryTable": "style_summaryTable__No5KO",
	"summaryTableKey": "style_summaryTableKey__1J0wb",
	"summaryTableValue": "style_summaryTableValue__21EGb"
};