import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../components/GettingStarted/OnboardingPage";

const Onboarding = () => {
  const panels = [
    {
      imageSrc: require("./tenant-manage.svg"),
      imageWidth: 690,
      imageHeight: 345,
      imageAlt: "Manage tenants",
      description: <div>
        Manage thousands of tenants easily all without leaving Octopus Deploy
      </div>
    },
    {
      imageSrc: require("./tenant-connect.svg"),
      imageAlt: "Connect tenants to projects/environments",
      imageWidth: 690,
      imageHeight: 345,
      description: <div>
        Connect each tenant to a combination of projects and environments
      </div>
    },
    {
      imageSrc: require("./tenant-dashboard.svg"),
      imageWidth: 690,
      imageHeight: 345,
      imageAlt: "Dashboard overview",
      description: <div>
        The built-in dashboard provides an overview of the status of your tenants and deployments
      </div>
    },
    {
      imageSrc: require("./tenant-promote.svg"),
      imageWidth: 750,
      imageHeight: 375,
      imageAlt: "Promotions",
      description: <div>
        See the status of your tenant deployments and upgrade with the click of a button
      </div>
    },
    {
      imageSrc: require("./tenant-variables.svg"),
      imageWidth: 690,
      imageHeight: 345,
      imageAlt: "Variables",
      description: <div>
        Manage tenant-specific configuration with an interface that adapts to your situation
      </div>
    },
    {
      imageSrc: require("./tenant-tagsets.svg"),
      imageWidth: 690,
      imageHeight: 345,
      imageAlt: "Tag sets",
      description: <div>
        Conveniently work with your tenants as groups instead of individuals using tags
      </div>
    }
  ];

  return (
    <OnboardingPage
      title="Add Tenants to start deploying your software to multiple end-customers"
      intro="Use Tenants to deploy your projects to multiple end-customers, with the software tailored to each specific customer."
      learnMore={<span><ExternalLink href="MultiTenantDeployments">Learn more</ExternalLink>.</span>} />
  );
};

export default Onboarding;