import InternalLink from "components/Navigation/InternalLink/InternalLink";
import * as React from "react";
import {CertificateIndex} from "components/certificates";
const styles = require("./style.less");
import HttpsIcon from "material-ui/svg-icons/action/https";
import {secondaryText} from "colors";
import routeLinks from "routeLinks";

interface ReadonlyCertificateProps {
    certificateIndex: CertificateIndex;
    certificate: string;
    className?: string;
}

// This component is supposed to be styled the same as a <Text /> component.
// This idea being that if they were side by side and the text component was not focused and did not have an underline,
// then they would look identical
const ReadonlyCertificate: React.SFC<ReadonlyCertificateProps> = (props) => {

    const certName = props.certificateIndex && props.certificateIndex[props.certificate]
        ? props.certificateIndex[props.certificate]
        : props.certificate;

    return <div className={styles.container}>
        <span className={styles.iconContainer}><HttpsIcon color={secondaryText}/></span>
        <div className={`${props.className} ${styles.content}`} title={certName}>
            <InternalLink to={routeLinks.library.certificate(props.certificate)} >{certName}</InternalLink>
        </div>
    </div>;
};

export default ReadonlyCertificate;