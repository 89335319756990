exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".history_row__L5DhC {\n  font-size: 0.75rem;\n  padding: 0.5rem !important;\n}\n.history_container__3Qdpn {\n  margin-bottom: 2rem;\n}\n", ""]);

// exports
exports.locals = {
	"row": "history_row__L5DhC",
	"container": "history_container__3Qdpn"
};