import * as React from "react";
import { ProjectResource, ControlType, ProjectVariableSetUsage } from "client/resources";
import { VariableType } from "client/resources/variableResource";
import { repository } from "clientInstance";
import { Omit } from "recompose";
import { DoBusyTask } from "components/DataBaseComponent/DataBaseComponent";
import { ReferenceCallback, VariableCallbacks, collateProjectVariables, UsedProjectResourceProps } from "utils/ProjectVariableCollation";
import { Control } from "client/resources/form";

interface WithProjectVariablesExternalProps {
    projectId: string;
    doBusyTask: DoBusyTask;
}

interface WithProjectVariablesInjectedProps {
    variables: string[];
    isRefreshing: boolean;
    projectId: string;
    error?: string;
    onRequestRefresh: () => Promise<any>;
}

interface WithProjectVariablesState {
    variables: string[];
    isRefreshing: boolean;
    error?: string;
    isDataLoaded: boolean;
}

interface Callbacks {
    fetchProjectVariables: (id: string) => Promise<string[]>;
}

interface WithProjectVariableOptions extends Partial<Callbacks> {
    controlType: ControlType;
    variableType: VariableType;
}

const getDefaultVariableFetcher = (controlType: ControlType, variableType: VariableType) => {
    const callbacks: VariableCallbacks = {
        fetchLibraryVariableSet: (id) => repository.LibraryVariableSets.get(id),
        fetchVariableSet:  (id) => repository.Variables.get(id)
    };

    return async (id: string) => {
        const project = await repository.Projects.get(id);
        return collateProjectVariables(callbacks, controlType, variableType, project);
    };
};

const withProjectVariables = ({
    controlType,
    variableType,
    fetchProjectVariables
}: WithProjectVariableOptions) =>
    <TOriginalProps extends WithProjectVariablesInjectedProps>(
        Component: (React.ComponentType<TOriginalProps>)
    ) => {
        type ResultProps = Omit<TOriginalProps, keyof WithProjectVariablesInjectedProps> & WithProjectVariablesExternalProps;
        fetchProjectVariables = fetchProjectVariables || getDefaultVariableFetcher(controlType, variableType);
        class WithProjectVariables extends React.Component<ResultProps & { forwardedRef: any}, WithProjectVariablesState> {
            static displayName = `WithProjectVariables(${Component.displayName || Component.name})`;

            constructor(props: ResultProps & { forwardedRef: any}) {
                super(props);
                this.state = {
                    error: null,
                    isRefreshing: true,
                    variables: [],
                    isDataLoaded: false
                };
            }

            async componentDidMount() {
                this.props.doBusyTask(async () => {
                    const variables = await fetchProjectVariables(this.props.projectId);
                    this.setState({variables, isDataLoaded: true, isRefreshing: false});
                });
            }

            render(): JSX.Element {
                const { isDataLoaded, ...state } = this.state;
                //TODO: Remove the need to use any here. Please note, this may result in involuntary fits.
                const WrappedComponent: any = Component;
                const {forwardedRef, ...rest} = this.props;
                return (<WrappedComponent {...rest} {...state} ref={forwardedRef} onRequestRefresh={this.onRequestRefresh} />);
            }

            private onRequestRefresh = async () => {
                this.setState({ isRefreshing: true });

                try {
                    this.props.doBusyTask(async () => {
                        const variables = await fetchProjectVariables(this.props.projectId);
                        this.setState({variables});
                    });
                } finally {
                    this.setState({ isRefreshing: false });
                }
            }
        }

        return React.forwardRef((props: ResultProps, ref) => (
            <WithProjectVariables {...props} forwardedRef={ref}/>
        ));
    };

export default withProjectVariables;
export {
    withProjectVariables,
    WithProjectVariablesInjectedProps,
    WithProjectVariableOptions,
    WithProjectVariablesExternalProps,
    WithProjectVariablesState
};