import * as React from "react";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ActionSummaryProps} from "components/Actions/actionSummaryProps";
import {ActionEditProps, default as pluginRegistry} from "components/Actions/pluginRegistry";
import ProjectResource from "client/resources/projectResource";
import {repository} from "clientInstance";
import ActionExecutionLocation from "client/resources/actionExecutionLocation";
import WildflyConnectionDetailsComponent from "components/Actions/wildfly/wildflyConnectionDetailsComponent";
import JavaKeystoreComponent from "components/Actions/javaKeystore/javaKeystoreComponent";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import Note from "components/form/Note/Note";
import { VariableLookupText } from "components/form/VariableLookupText";
import Summary from "components/form/Sections/Summary";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import WildflyRelativeLocationComponent from "./wildflyRelativeLocationComponent";
import { StringRadioButtonGroup, BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import {RadioButton} from "material-ui";
import {WildflyCertificateProperties} from "./wildflyCertificateProperties";
import Roles from "components/Actions/Roles";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";

class DeployCertificateToTomcatActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Configure a certificate in WildFly 10+ or JBoss EAP 6+ standalone server {this.props.targetRolesAsCSV && <React.Fragment>to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /></React.Fragment>}
        </div>;
    }
}

interface CertificateImportActionEditState {
    project: ProjectResource;
}

export class DeployCertificateToTomcatActionEdit extends BaseComponent<ActionEditProps<WildflyCertificateProperties>, CertificateImportActionEditState> {
    constructor(props: ActionEditProps<WildflyCertificateProperties>) {
        super(props);
        this.state = {
            project: null,
        };
    }

    async componentDidMount() {
        this.props.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectId);
            this.setState({project});
        });

        const properties = this.props.properties;
        if (properties["WildFly.Deploy.DeployCertificate"] !== "True" &&
            properties["WildFly.Deploy.DeployCertificate"] !== "False") {
            properties["WildFly.Deploy.DeployCertificate"] = "True";
        }
        if (properties["WildFly.Deploy.ServerType"] !== "Standalone" &&
            properties["WildFly.Deploy.ServerType"] !== "Domain") {
            properties["WildFly.Deploy.ServerType"] = "Standalone";
        }
        if (!properties["WildFly.Deploy.CertificateRelativeTo"]) {
            properties["WildFly.Deploy.CertificateRelativeTo"] = "NONE";
        }
        if (!properties["WildFly.Deploy.CertificateProfiles"]) {
            properties["WildFly.Deploy.CertificateProfiles"] = "default";
        }
        this.props.setProperties(properties);
    }

    render() {
        const properties = this.props.properties;
        const serverDetailsErrorKey = "WildFly.Deploy.ServerType|" +
            "Java.Certificate.KeystoreFilename|" +
            "WildFly.Deploy.CertificateRelativeTo|" +
            "Java.Certificate.Password|" +
            "Java.Certificate.KeystoreAlias|" +
            "Java.Certificate.Variable";

        return <div>
            <ExpanderSectionHeading title="Application Server Details"/>
            <WildflyConnectionDetailsComponent
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                getFieldError={this.props.getFieldError}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                errors={this.props.errors}
                projectId={this.props.projectId}
                applicationServerHostnameNote={
                    <span>
                        This is the hostname or IP address of the application server that the certificate will be configured in.
                    </span>}
                actionDescription={
                    <span>
                        Deploy a certificate via the management interface listening on
                    </span>}
                expandedByDefault={this.props.expandedByDefault} />
            <ExpandableFormSection
                errorKey={serverDetailsErrorKey}
                isExpandedByDefault={this.props.expandedByDefault}
                title="Certificate Configuration"
                summary={this.keystoreSummary()}
                help="Specify the certificate details">
                <StringRadioButtonGroup
                    value={properties["WildFly.Deploy.ServerType"]}
                    onChange={(x) => this.props.setProperties({["WildFly.Deploy.ServerType"]: x})}
                    label="Standalone or domain server">
                    <RadioButton value={"Standalone"}
                                 label="Standalone"/>
                    <RadioButton value={"Domain"}
                                 label="Domain"/>
                </StringRadioButtonGroup>
                <Note>
                    Select the kind of server you are deploying to.
                </Note>
                {properties["WildFly.Deploy.ServerType"] === "Standalone" &&
                <div>
                    <BoundStringRadioButtonGroup
                        variableLookup={{
                            localNames: this.props.localNames,
                            projectId: this.props.projectId
                        }}
                        resetValue={"NONE"}
                        value={properties["WildFly.Deploy.DeployCertificate"]}
                        onChange={(x) => this.props.setProperties({["WildFly.Deploy.DeployCertificate"]: x})}
                        label="Keystore creation">
                        <RadioButton
                            value={"True"}
                            label="Create a new keystore"/>
                        <RadioButton
                            value={"False"}
                            label="Reference an existing keystore"/>
                    </BoundStringRadioButtonGroup>
                    {properties["WildFly.Deploy.DeployCertificate"] === "True" &&
                    <JavaKeystoreComponent
                        projectId={this.props.projectId}
                        properties={this.props.properties}
                        packages={this.props.packages}
                        plugin={this.props.plugin}
                        setProperties={this.props.setProperties}
                        setPackages={this.props.setPackages}
                        doBusyTask={this.props.doBusyTask}
                        busy={this.props.busy}
                        getFieldError={this.props.getFieldError}
                        errors={this.props.errors}
                        keystoreFilenameMessage={<span><p>
                            The optional path of the keystore file to create. If defined, this must be an absolute path, the parent directory
                            must exist, and the path must include the keystore filename e.g. <em>C:\Keys\my.keystore</em> or <em>/opt/server/conf/keys.store</em>.
                        </p>
                        <p>
                            If the file exists, it will be overwritten.
                        </p>
                        <p>
                            If this value is not defined a new certificate will be created in the application server's <em>configuration</em> directory.
                        </p></span>}
                        expandedByDefault={this.props.expandedByDefault}
                    />}
                </div>}
                {properties["WildFly.Deploy.ServerType"] === "Domain" &&
                <div>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={properties["WildFly.Deploy.CertificateProfiles"]}
                        onChange={(x) => this.props.setProperties({["WildFly.Deploy.CertificateProfiles"]: x})}
                        label="Domain profiles" />
                    <Note>
                        A comma separated list that defines the domain server groups where the certificate will be
                        configured.
                    </Note>
                </div>}
                {(properties["WildFly.Deploy.ServerType"] === "Domain" ||
                    properties["WildFly.Deploy.DeployCertificate"] !== "True") &&
                <WildflyRelativeLocationComponent
                    properties={this.props.properties}
                    packages={this.props.packages}
                    plugin={this.props.plugin}
                    getFieldError={this.props.getFieldError}
                    setProperties={this.props.setProperties}
                    setPackages={this.props.setPackages}
                    doBusyTask={this.props.doBusyTask}
                    busy={this.props.busy}
                    errors={this.props.errors}
                    expandedByDefault={this.props.expandedByDefault}
                />}
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey={"AdvancedOptions"}
                isExpandedByDefault={this.props.expandedByDefault}
                title="Advanced Options"
                summary={this.advancedSummary()}
                help="Specify optional advanced security options">
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["WildFly.Deploy.HTTPSPortBindingName"]}
                    onChange={(x) => this.props.setProperties({["WildFly.Deploy.HTTPSPortBindingName"]: x})}
                    label="HTTPS socket binding name" />
                <Note>
                    This field defines the port binding that is used to listen to HTTPS requests in the
                    application server. If left blank, it will default to <em>https</em>.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["WildFly.Deploy.SecurityRealmName"]}
                    onChange={(x) => this.props.setProperties({["WildFly.Deploy.SecurityRealmName"]: x})}
                    label="Legacy security realm name" />
                <Note>
                    This field defines the name of the security realm that will be created in applicable application
                    servers. If left blank, this value will default to <em>OctopusHttps</em>.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["WildFly.Deploy.ElytronKeystoreName"]}
                    onChange={(x) => this.props.setProperties({["WildFly.Deploy.ElytronKeystoreName"]: x})}
                    label="Elytron key store name" />
                <Note>
                    This field defines the name of the the Elytron key store. If left blank, this value will default
                    to <em>OctopusHttpsKS</em>.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["WildFly.Deploy.ElytronKeymanagerName"]}
                    onChange={(x) => this.props.setProperties({["WildFly.Deploy.ElytronKeymanagerName"]: x})}
                    label="Elytron key manager name" />
                <Note>
                    This field defines the name of the the Elytron key manager. If left blank, this value will default
                    to <em>OctopusHttpsKM</em>.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["WildFly.Deploy.ElytronSSLContextName"]}
                    onChange={(x) => this.props.setProperties({["WildFly.Deploy.ElytronSSLContextName"]: x})}
                    label="Elytron server SSL context name" />
                <Note>
                    This field defines the name of the the Elytron server SSL context. If left blank, this value will default
                    to <em>OctopusHttpsSSC</em>.
                </Note>
            </ExpandableFormSection>
        </div>;
    }

    private advancedSummary() {
        const properties = this.props.properties;
        const customPortBinding = properties["WildFly.Deploy.HTTPSPortBindingName"] && properties["WildFly.Deploy.HTTPSPortBindingName"] !== "https";
        const customSecurityRealm = properties["WildFly.Deploy.SecurityRealmName"] && properties["WildFly.Deploy.SecurityRealmName"] !== "OctopusHTTPS";
        const customKeystore = properties["WildFly.Deploy.ElytronKeystoreName"] && properties["WildFly.Deploy.ElytronKeystoreName"] !== "octopusHttpsKS";
        const customKeymanager = properties["WildFly.Deploy.ElytronKeymanagerName"] && properties["WildFly.Deploy.ElytronKeymanagerName"] !== "octopusHttpsKM";
        const customSSLContext = properties["WildFly.Deploy.ElytronSSLContextName"] && properties["WildFly.Deploy.ElytronSSLContextName"] !== "octopusHttpsSSC";

        if (customPortBinding ||
            customSecurityRealm ||
            customKeystore ||
            customKeymanager ||
            customSSLContext) {
            return Summary.summary(<span>Configuring HTTPS with
                {customPortBinding &&
                <span>&nbsp;a port binding called <strong>{properties["WildFly.Deploy.HTTPSPortBindingName"]}</strong></span>}
                {customPortBinding &&
                <span>,</span>}
                {customPortBinding && !(customKeystore || customKeymanager || customSSLContext) &&
                <span> and</span>}
                {customSecurityRealm &&
                <span>&nbsp;a security realm called <strong>{properties["WildFly.Deploy.SecurityRealmName"]}</strong></span>}
                {(customPortBinding || customSecurityRealm) &&
                <span>,</span>}
                {customKeystore && !(customKeymanager || customSSLContext) &&
                <span> and</span>}
                {customKeystore &&
                <span>&nbsp;an Elytron KeyStore called <strong>{properties["WildFly.Deploy.ElytronKeystoreName"]}</strong></span>}
                {(customPortBinding || customSecurityRealm || customKeystore) &&
                <span>,</span>}
                {customKeymanager && !customSSLContext &&
                <span> and</span>}
                {customKeymanager &&
                <span>&nbsp;an Elyton KeyManager called <strong>{properties["WildFly.Deploy.ElytronKeymanagerName"]}</strong></span>}
                {(customPortBinding || customSecurityRealm || customKeystore || customKeymanager) &&
                <span>, and</span>}
                {customSSLContext &&
                <span>&nbsp;an Elytron SSL Context called <strong>{properties["WildFly.Deploy.ElytronSSLContextName"]}</strong></span>}
            </span>);
        }

        return Summary.placeholder(<span>Define optional advanced security settings.</span>);
    }

    private keystoreSummary() {
        const properties = this.props.properties;
        return Summary.summary(<span>
            Deploying to a {properties["WildFly.Deploy.ServerType"]} server
            {properties["WildFly.Deploy.ServerType"] === "Standalone" &&
            properties["WildFly.Deploy.DeployCertificate"] === "True" &&
            <span>
                &nbsp;creating a new keystore
                {properties["Java.Certificate.KeystoreFilename"] &&
                <span>&nbsp;at <strong>{properties["Java.Certificate.KeystoreFilename"]}</strong></span>}
                {!properties["Java.Certificate.KeystoreFilename"] &&
                <span>&nbsp;in the default location</span>}
                {properties["Java.Certificate.Password"] &&
                <span>&nbsp;with a custom password
                    {properties["Java.Certificate.KeystoreAlias"] &&
                    <span>&nbsp;and</span>}
                    </span>}
                {properties["Java.Certificate.KeystoreAlias"] &&
                <span>&nbsp;with a custom alias <strong>{properties["Java.Certificate.KeystoreAlias"]}</strong></span>}
            </span>}
            {(properties["WildFly.Deploy.ServerType"] === "Domain" ||
                properties["WildFly.Deploy.DeployCertificate"] !== "True") &&
            <span>
                &nbsp;referencing the existing keystore
                {properties["Java.Certificate.KeystoreFilename"] &&
                <span>&nbsp;at&nbsp;
                    {properties["WildFly.Deploy.CertificateRelativeTo"] !== "NONE" &&
                    <strong>${"{"}{properties["WildFly.Deploy.CertificateRelativeTo"]}{"}"}/</strong>}
                    <strong>{properties["Java.Certificate.KeystoreFilename"]}</strong>
                </span>}
            </span>}
        </span>);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.WildFlyCertificateDeploy",
    summary: (properties, targetRolesAsCSV) => <DeployCertificateToTomcatActionSummary properties={properties}
                                                                                       targetRolesAsCSV={targetRolesAsCSV}/>,
    edit: DeployCertificateToTomcatActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => false,
});