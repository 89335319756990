import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {KubernetesConfigMapProperties} from "components/Actions/kubernetes/kubernetesProperties";
import {ActionEditProps} from "components/Actions/pluginRegistry";
import {default as ExpandableFormSection} from "components/form/Sections/ExpandableFormSection";
import * as React from "react";
import Summary from "components/form/Sections/Summary";
import {VariableLookupText} from "components/form/VariableLookupText";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import {ScriptPackageProperties, ScriptPackageReference, ScriptPackageReferenceDialog} from "components/Actions/script/ScriptPackageReferenceDialog";
import * as _ from "lodash";
import DialogOpener from "components/Dialog/DialogOpener";
import FeedResource from "client/resources/feedResource";
import {GetNamedPackageReferences, PackageRequirement} from "client/resources";
import {repository} from "clientInstance";
import {JsonUtils} from "utils/jsonUtils";

interface ScriptActionEditState {
    feeds: FeedResource[];
    originalCanRunBeforeAcquisition: boolean;
    originalStepPackageRequirement: PackageRequirement;
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
}
export class KubernetesConfigMapComponent extends BaseComponent<ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, ScriptActionEditState> {
    constructor(props: ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>) {
        super(props);
        this.state = {
            feeds: [],
            originalCanRunBeforeAcquisition: false,
            originalStepPackageRequirement: PackageRequirement.LetOctopusDecide,
            editPackageReference: null,
            editPackageReferenceIndex: null
        };
    }

    async componentDidMount() {
        await this.loadFeeds();
    }

    render() {
        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());

        const editPackageReferenceDialog = <DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
            <ScriptPackageReferenceDialog
                packageReference={this.state.editPackageReference}
                runOn={this.props.runOn}
                feeds={this.state.feeds}
                localNames={localNames}
                projectId={this.props.projectId}
                onChange={packageReference => this.savePackageReference(packageReference)}
                refreshFeeds={this.loadFeeds} />
        </DialogOpener>;

        return <div>
            {editPackageReferenceDialog}
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.ConfigMapName"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Config Map Name"
                summary={this.configMapSummary()}
                help={"The name of the config map resource"}>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapName"]: x })}
                    error={this.props.getFieldError("Octopus.Action.KubernetesContainers.ConfigMapName")}
                    label="Config Map name" />
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.ConfigMapValues"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Config Map Items"
                summary={this.configMapLiteralSummary()}
                help={"The config map resource values"}>
                <StringKeyValueEditList
                    items={this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapValues"]}
                    name="Config map item"
                    separator=""
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.ConfigMapValues"]: val })}
                    valueLabel="Value"
                    valueMultiline={true}
                    valueRowsMax={5}
                    keyLabel="Key"
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                />
            </ExpandableFormSection>
        </div>;
    }

    private savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        } else {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }

        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }

    private resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null,
            editPackageReferenceIndex: null
        });
    }

    private packageVariableNames = (): string[] => {
        return _.flatten(
            GetNamedPackageReferences(this.props.packages)
                .map(pkg => [
                    `Octopus.Action.Package[${pkg.Name}].PackageId`,
                    `Octopus.Action.Package[${pkg.Name}].FeedId`,
                    `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
                    `Octopus.Action.Package[${pkg.Name}].Path`]));
    }

    private configMapSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"]) {
            return Summary.summary(<span>Create a config map called <strong>{this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapName"]}</strong></span>);
        }
        return Summary.placeholder(<span>Define the config map name</span>);
    }

    private configMapLiteralSummary() {
        const items = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.ConfigMapValues"], {}));
        if (items.length !== 0) {
            return Summary.summary(
                <span>
                <span>Create a config map with the values </span>
                {_.chain(items)
                    .flatMap(item => [<strong>{item[0]}: {item[1].toString().replace(/\n[\S\s]*/, " ...")}</strong>, <span>, </span>])
                    .slice(0, -1)
                    .value()}
                </span>);
        }

        return Summary.placeholder(<span>Define the config map values</span>);
    }

    private loadFeeds = () => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() });
        });
    }
}