import * as React from "react";
import { Card } from "material-ui/Card";
const styles = require("./style.less");
import { FormSectionBase, FormSectionBaseProps } from "components/form/Sections/FormSectionBase";

// like Unstructured form section with no margin so warnings fill the panel
class WarningFormSection extends FormSectionBase<FormSectionBaseProps> {
    render() {
        return <Card
            className={styles.warningFormExpander}
            expanded={true}>
            {this.body(styles.warningFormSection, false)}
        </Card>;
    }
}

export default WarningFormSection;