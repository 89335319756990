import * as React from "react";
import {Card, CardActions, CardHeader, CardText, CardMedia, CardTitle} from "material-ui/Card";
const styles = require("./style.less");
import {FormSectionBase, FormSectionBaseProps} from "components/form/Sections/FormSectionBase";
import {formSectionClass} from "uiTestClasses";

interface FormSectionProps extends FormSectionBaseProps {
    title: React.ReactNode;
    help?: React.ReactNode;
}

export default class FormSection extends FormSectionBase<FormSectionProps> {
    constructor(props: FormSectionProps) {
        super(props);
    }

    render() {
        return <Card
            className={`${formSectionClass} ${styles.formSection}`}
            expanded={true}>
            <CardTitle title={this.title(this.props.title, this.help(this.props.help))} />
            {this.body(styles.cardMedia, false)}
            {this.moreInformation()}
        </Card>;
    }
}
