import * as React from "react";
import {Text} from "components/form";
import {ActionButton, ActionButtonType} from "components/Button";
import StringHelper from "utils/StringHelper";
import ToolTipMessages from "components/ToolTipMessages";
import FocusActionsLayout from "components/FocusActionsLayout/FocusActionsLayout";
const styles = require("./style.less");
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import VariableCell from "areas/variables/VariableCell/VariableCell";
import DebounceValue from "components/DebounceValue/DebounceValue";
import {TextInput} from "components/form/Text/Text";

interface VariableNameCellProps {
    id: string;
    name: string;
    deleted?: boolean;
    hintText: string;
    onNameChanged: (name: string) => void;
    onOpenEditorClicked: () => void;
    onMergeClicked?: (id: string) => void;
    onDontMergeClicked?: (id: string) => void;
    isFocused: boolean;
    onFocus: () => void;
    onBlur: () => void;
    isDuplicate: boolean;
    warningMessages: ReadonlyArray<string>;
}

const DebouncedText = DebounceValue(Text);

export default class VariableNameCell extends React.Component<VariableNameCellProps, {}> {
    private nameInput: TextInput | null = null;
    private selectInputAfterNextUpdate: boolean = false;
    private readonly onBlur: () => void;

    constructor(props: VariableNameCellProps) {
        super(props);
        this.onBlur = () => this.props.onBlur();
    }

    componentWillReceiveProps(nextProps: VariableNameCellProps) {
        if (nextProps.isFocused && !this.props.isFocused) {
            this.selectInputAfterNextUpdate = true;
        }
    }

    componentDidUpdate() {
        if (this.selectInputAfterNextUpdate) {
            if (this.nameInput && !this.nameInput.isFocused()) {
                this.nameInput.select();
            }
            this.selectInputAfterNextUpdate = false;
        }
    }

    render() {
        const actions = [
            <ActionButton
                tabIndex={-1}
                type={ActionButtonType.Ternary}
                label="Open Editor"
                onClick={this.props.onOpenEditorClicked} />,
            ...(this.props.isDuplicate ? [
                <ActionButton
                    tabIndex={-1}
                    type={ActionButtonType.Ternary}
                    label="Merge"
                    onClick={() => {
                        this.props.onBlur();
                        this.props.onMergeClicked(this.props.id);
                    }}
                />,
                <ActionButton
                    tabIndex={-1} type={ActionButtonType.Ternary}
                    label="Rename"
                    onClick={() => {
                        this.props.onBlur();
                        this.props.onDontMergeClicked(this.props.id);
                    }}
                />
            ] : [])
        ];

        return <div
            className={styles.nameCell}
            onFocus={this.props.onFocus}>
            {this.props.deleted ?
                <VariableCell className={styles.nameCellContentReadonly}>
                    <ReadonlyText text={this.props.name} className={styles.deletedContent} monoSpacedFont={true}/>
                </VariableCell>
                : <FocusActionsLayout
                    isFocused={this.props.isFocused}
                    actions={actions}
                    onClickOutside={this.onBlur}
                >
                    <VariableCell className={`${styles.inputContainer}`}>
                        <DebouncedText
                            textInputRef={nameInput => this.nameInput = nameInput}
                            id={this.props.id}
                            value={this.props.name}
                            showValueAsTitleAttribute={true}
                            hintText={this.props.hintText}
                            underlineShow={this.props.isFocused}
                            applyMaxWidth={false}
                            onChange={this.props.onNameChanged}
                            monoSpacedFont={true}
                        />
                        {!this.props.isFocused && <ToolTipMessages
                            onClick={this.props.onFocus}
                            warningMessages={this.props.warningMessages} />}
                    </VariableCell>
                </FocusActionsLayout>
            }
        </div>;
    }
}
