exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_tabContainer__28Lr_ {\n  width: 100%;\n}\n.style_tabWarningIcon__2Vf_5 {\n  color: #fb8c00;\n  padding-left: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"tabContainer": "style_tabContainer__28Lr_",
	"tabWarningIcon": "style_tabWarningIcon__2Vf_5"
};