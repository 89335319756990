import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { UserResource } from "client/resources";
import { repository } from "clientInstance";
import { session } from "clientInstance";
import UserApiKeysList from "./UserApiKeysList";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import SectionNote from "components/SectionNote/SectionNote";

interface UserApiKeysState extends DataBaseComponentState {
    user: UserResource;
}

export default class UserApiKeys extends DataBaseComponent<{}, UserApiKeysState> {
    constructor(props: {}) {
        super(props);

        this.state = ({
            user: null
        });
    }

    currentUserId(): string {
        return session && session.currentUser ? session.currentUser.Id : null;
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const user = this.currentUserId() ? await repository.Users.get(this.currentUserId()) : null;

            this.setState({
                user
            });
        });
    }

    render() {
        return <PaperLayout title="My API Keys"
            busy={this.state.busy}
            errors={this.state.errors}>
            <SectionNote>
                You can use an API key to connect <ExternalLink href="ExternalToolOctoTools">automated tools</ExternalLink> to
                Octopus, in place of a username and password. API keys are useful because they
                avoid the need to record your personal password in configuration
                files and scripts. A unique API key can be created for each use, and then
                individually revoked when no longer needed.
            </SectionNote>

            {this.state.user && <TransitionAnimation><UserApiKeysList user={this.state.user} /></TransitionAnimation>}

        </PaperLayout>;
    }
}
