// These class names are all used by UI E2E tests to identify certain parts of the page.
// They same values are used in the UI E2E tests project in the "Selectors" class.
// So if you change something here, you will probably need to change it in that file as well, and then update the tests
export const tagSetClass = "tag-set";
export const deploymentPartNameClass = "deployment-part-name";
export const packageRowClass = "package-row";
export const releaseVersionClass = "release-version";
export const stepCardClass = "add-step-card";
export const dialogClass = "octopus-dialog";
export const busyIndicatorClass = "busy-indicator";
export const octopusErrorClass = "octopus-error";
export const formSectionClass = "form-section-container";
export const formSectionTitleClass = "form-section-title";
export const formSectionBodyClass = "form-section-body";
export const overflowMenuClass = "overflow-menu";
export const navLinksClass = "secondary-navigation";
export const linkMenuClassName = "links-menu-popover";
export const tagClass = "tag-set-tag";
