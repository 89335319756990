import * as React from "react";

interface Props<TValue> {
    value: TValue;
    onChange(value: TValue): void;
}

interface CancelableProps<TValue> {
    cancelableValidate(value: TValue): { error: string, rejectChange: boolean };
}

interface State {
    error: string;
}

// A HOC that expands validation so that instead of just returning a string you can
// return a structure that allows you to block the call to onChange for the update
// that would fail validation
export default function CancelInvalidUpdate<TProps extends Props<TValue>, TValue>(Comp: React.ComponentClass<TProps>) {

    type InternalProps = TProps & CancelableProps<TValue>;

    return class DebounceValueInternal extends React.Component<InternalProps, State> {
        constructor(props: InternalProps) {
            super(props);
            this.state = {
                error: ""
            };
        }

        onChange = (value: TValue) => {
            const result = this.props.cancelableValidate(value);
            this.setError(result.error);
            if (result.rejectChange) {
                this.props.onChange(this.props.value);
            } else {
                this.props.onChange(value);
            }
        }

        setError = (error: string) => {
            // The Text control doesn't show externally provided errors after a change event
            // since in normal Forms external errors are usually from the server and usually
            // about a missing required field, so when you enter something usually we want it
            // cleared. But here we always want to show the error. So rather than add yet
            // another option to Text, just write a different error and then the error we want
            // this will cause the error to persist on change if validation is still failing
            this.setState({ error: "\t\t" }, () => this.setState({error}));
        }

        render() {
            const {
                error,
                onChange,
                cancelableValidate,
                ...otherProps
            } = this.props as any; // `as any` because object rest not support in generics yet, tracked by https://github.com/Microsoft/TypeScript/issues/10727
            return <Comp {...otherProps}
                error={this.state.error}
                onChange={this.onChange}
            />;
        }
    };
}