import * as React from "react";
const styles = require("./style.less");

interface TenantDeploymentCountProps {
    expected: number;
    progress: number;
}

const TenantDeploymentCount: React.StatelessComponent<TenantDeploymentCountProps> = props => {
    return <span className={styles.container}><span className={styles.progress}>{props.progress}</span><span  className={styles.expected}>/{props.expected}</span>    </span>;
};

export default TenantDeploymentCount;
