exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_selectContainer__2iXoK {\n  max-width: 20rem;\n  margin: 0 auto;\n}\n", ""]);

// exports
exports.locals = {
	"selectContainer": "style_selectContainer__2iXoK"
};