import { uniqBy } from "lodash";
import {ScopeValues} from "client/resources/variableSetResource";
import {ReferenceDataItem} from "client/resources/referenceDataItem";

export default function mergeScopeValues(allScopeValues: ReadonlyArray<ScopeValues>): ScopeValues {
    const initialScopeValues: ScopeValues = {
        Actions: [],
        TenantTags: [],
        Roles: [],
        Channels: [],
        Machines: [],
        Environments: []
    };

    const combinedScopeValues = allScopeValues.reduce((acc, curr) => {
        acc.Actions.push(...curr.Actions);
        acc.Channels.push(...curr.Channels);
        acc.Environments.push(...curr.Environments);
        acc.Machines.push(...curr.Machines);
        acc.Roles.push(...curr.Roles);
        acc.TenantTags.push(...curr.TenantTags);
        return acc;
    }, initialScopeValues);

    return {
        Actions: uniqById(combinedScopeValues.Actions),
        Channels: uniqById(combinedScopeValues.Channels),
        Environments: uniqById(combinedScopeValues.Environments),
        Machines: uniqById(combinedScopeValues.Machines),
        Roles: uniqById(combinedScopeValues.Roles),
        TenantTags: uniqById(combinedScopeValues.TenantTags),
    };

    function uniqById(referenceDataItems: ReferenceDataItem[]): ReferenceDataItem[] {
        return uniqBy(referenceDataItems, i => i.Id);
    }
}