exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_status__1M01p {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 2.25rem;\n  height: 2.25rem;\n  border-radius: 0.125rem;\n  font-size: 1.25rem;\n  color: #ffffff;\n}\n.style_small__1y0Fs {\n  width: 1.25rem;\n  height: 1.25rem;\n  font-size: 0.875rem;\n}\n.style_Queued__h1K9m {\n  background-color: #0878D1;\n}\n.style_Executing__dnX0l {\n  background-color: #0A67B1;\n}\n.style_Paused__34Pyd {\n  background-color: #fb8c00;\n}\n.style_TimedOut__l4mEw {\n  background-color: #fb8c00;\n}\n.style_Failed__3c1N0 {\n  background-color: #db4437;\n}\n.style_Canceled__37cqN {\n  background-color: #333333;\n}\n.style_Cancelling__3c8CE {\n  background-color: #333333;\n}\n.style_Success__2Lz42 {\n  background-color: #48B350;\n}\n.style_SuccessWithWarnings__39_yv {\n  background-color: #48B350;\n}\n", ""]);

// exports
exports.locals = {
	"status": "style_status__1M01p",
	"small": "style_small__1y0Fs",
	"Queued": "style_Queued__h1K9m",
	"queued": "style_Queued__h1K9m",
	"Executing": "style_Executing__dnX0l",
	"executing": "style_Executing__dnX0l",
	"Paused": "style_Paused__34Pyd",
	"paused": "style_Paused__34Pyd",
	"TimedOut": "style_TimedOut__l4mEw",
	"timedOut": "style_TimedOut__l4mEw",
	"Failed": "style_Failed__3c1N0",
	"failed": "style_Failed__3c1N0",
	"Canceled": "style_Canceled__37cqN",
	"canceled": "style_Canceled__37cqN",
	"Cancelling": "style_Cancelling__3c8CE",
	"cancelling": "style_Cancelling__3c8CE",
	"Success": "style_Success__2Lz42",
	"success": "style_Success__2Lz42",
	"SuccessWithWarnings": "style_SuccessWithWarnings__39_yv",
	"successWithWarnings": "style_SuccessWithWarnings__39_yv"
};