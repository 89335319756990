import pluginRegistry, {ActionEditProps} from "components/Actions/pluginRegistry";
import getUploadS3Registration from "./awsUploadS3Action";
import getDeleteCloudFormationRegistration from "./awsDeleteCloudFormationAction";
import getDeployCloudFormationRegistration from "./awsDeployCloudFormationAction";
import getAwsRunScriptRegistration from "./awsRunScriptAction";
import getApplyChangesetRegistration from "./awsApplyCloudFormationAction";
import getChangesetsFeature from "./awsCloudFormationChangesetFeature";

pluginRegistry.registerDeploymentAction(getAwsRunScriptRegistration());
pluginRegistry.registerDeploymentAction(getDeleteCloudFormationRegistration());
pluginRegistry.registerDeploymentAction(getDeployCloudFormationRegistration());
pluginRegistry.registerDeploymentAction(getUploadS3Registration());
pluginRegistry.registerDeploymentAction(getApplyChangesetRegistration());

pluginRegistry.registerFeature(getChangesetsFeature());