exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".projectSwitcher_container__3P1x6 {\n  display: flex;\n  flex-direction: column;\n  width: 25rem;\n}\n.projectSwitcher_menuContainer__1daZ3 {\n  overflow-y: auto;\n  height: 25rem;\n}\n.projectSwitcher_filterFieldContainer__2yWyi {\n  width: 100%;\n}\n.projectSwitcher_groupName__QFcSJ {\n  background-color: rgba(153, 153, 153, 0.08);\n  padding: 0.5rem 1.125rem;\n  font-size: 0.9375rem;\n}\n.projectSwitcher_empty__3bdIj {\n  padding: 0.625rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "projectSwitcher_container__3P1x6",
	"menuContainer": "projectSwitcher_menuContainer__1daZ3",
	"filterFieldContainer": "projectSwitcher_filterFieldContainer__2yWyi",
	"groupName": "projectSwitcher_groupName__QFcSJ",
	"empty": "projectSwitcher_empty__3bdIj"
};