exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__2uk7P {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__16hOQ {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__24DbY {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__cJgM7 {\n    display: none !important;\n  }\n}\n.style_machineIcon__15Gkb {\n  width: 2.438rem;\n  height: 2.563rem;\n}\n.style_machineRow__1ts9h {\n  display: flex;\n  align-items: center;\n  font-size: 0.8125rem;\n  margin-left: 1.5rem;\n}\n@media (max-width: 37.4rem) {\n  .style_machineRow__1ts9h {\n    margin-left: 0.75rem;\n  }\n}\n.style_machineRow__1ts9h .style_machineImage__1BNvF {\n  padding-right: 0.5rem;\n}\n.style_machineRow__1ts9h .style_machineNameContainer__1Xh_j {\n  width: 13.44rem;\n}\n.style_machineRow__1ts9h .style_machineNameContainer__1Xh_j .style_machineName__1L_gt {\n  font-weight: 500;\n  color: #333333;\n  padding-right: 0.5rem;\n}\n.style_machineRow__1ts9h .style_machineNameContainer__1Xh_j .style_machineSummary__EBMxT {\n  font-size: 0.6875rem;\n  color: #9e9e9e;\n  padding-right: 1rem;\n}\n.style_machineRow__1ts9h .style_chipContainer__3aJ0N {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__2uk7P",
	"hideSm": "style_hide-sm__2uk7P",
	"visible-sm": "style_visible-sm__16hOQ",
	"visibleSm": "style_visible-sm__16hOQ",
	"hide-md": "style_hide-md__24DbY",
	"hideMd": "style_hide-md__24DbY",
	"visible-md": "style_visible-md__cJgM7",
	"visibleMd": "style_visible-md__cJgM7",
	"machineIcon": "style_machineIcon__15Gkb",
	"machineRow": "style_machineRow__1ts9h",
	"machineImage": "style_machineImage__1BNvF",
	"machineNameContainer": "style_machineNameContainer__1Xh_j",
	"machineName": "style_machineName__1L_gt",
	"machineSummary": "style_machineSummary__EBMxT",
	"chipContainer": "style_chipContainer__3aJ0N"
};