import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";
import {ProjectResource} from "../../../../client/resources/projectResource";

const Onboarding = (props: { project: ProjectResource }) => {

  return (
    <OnboardingPage
      title="Create a release of your software to deploy"
      intro="Releases allow you to capture everything required to deploy a project in a repeatable and reliable manner."
      learnMore={<span><ExternalLink href="OnboardingReleasesLearnMore">Learn more</ExternalLink>.
      </span>}
       />
  );
};

export default Onboarding;