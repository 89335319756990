import * as React from "react";
import InfrastructureLayout from "../InfrastructureLayout";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import {
    EnvironmentsSummaryResource,
    TenantResource,
    TagSetResource,
    WorkerPoolsSummaryResource,
} from "client/resources";
import { repository } from "clientInstance";
const styles = require("./style.less");
import MachineIconHelper from "utils/MachineIconHelper";
import * as tenantTagsets from "components/tenantTagsets";
import EnvironmentsCard from "./EnvironmentsCard";
import WorkersCard from "./WorkersCard";
import MachinesCard from "./MachinesCard";
import HealthStatusCard from "./HealthStatusCard";
import RolesCard from "./RolesCard";
import TagSetsCard from "./TagSetsCard";
import TenantsCard from "./TenantsCard";
import Onboarding from "./Onboarding";
import { NavigationButton, NavigationButtonType } from "components/Button";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import Permission from "../../../../client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import GlobalState from "globalState";
import { connect, MapStateToProps } from "react-redux";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface InfrastructureDashboardState extends DataBaseComponentState {
    environmentsSummary: EnvironmentsSummaryResource;
    workerpoolsSummary: WorkerPoolsSummaryResource;
    machineRoles: string[];
    tenants: TenantResource[];
    tagSets: TagSetResource[];
    filterPartialName?: string;
    isSearching: boolean;
    showOnboarding?: boolean; // Nullable so we know when we have a yes/no answer on this question.
}

interface ConnectedProps {
    isMultiTenancyEnabled: boolean;
}

type InfrastructureDashboardProps = ConnectedProps;

class InfrastructureDashboard extends DataBaseComponent<InfrastructureDashboardProps, InfrastructureDashboardState> {
    private machineIconHelper = new MachineIconHelper();

    constructor(props: InfrastructureDashboardProps) {
        super(props);
        this.state = ({
            isSearching: false,
            environmentsSummary: null,
            workerpoolsSummary: null,
            machineRoles: null,
            tenants: null,
            tagSets: null,
            showOnboarding: null,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadData());
    }

    render() {
        const actions: JSX.Element[] = [
            this.state.environmentsSummary && this.state.environmentsSummary.EnvironmentSummaries && this.state.environmentsSummary.EnvironmentSummaries.length === 0 &&
            <NavigationButton label="Add Environments"
                href={routeLinks.infrastructure.environments.root}
                type={NavigationButtonType.Primary}
            />
        ];
        const actionSection = <ActionList actions={actions} />;

        return <InfrastructureLayout {...this.props}>
            <PaperLayout
                title="Overview"
                busy={this.state.busy}
                errors={this.state.errors}
                sectionControl={actionSection}
            >
                {this.state.showOnboarding === true && <Onboarding />}
                {this.state.showOnboarding === false && <div className={styles.cardsContainer}>
                    <EnvironmentsCard key="environmentsCard" environmentSummary={this.state.environmentsSummary} />
                    <MachinesCard key="machinesCard" title="Deployment Targets"
                        link={filter => routeLinks.infrastructure.machines.filtered(filter)}
                        summary={this.state.environmentsSummary}
                        machineIconHelper={this.machineIconHelper} />
                    <HealthStatusCard
                        key="healthStatusCard"
                        title="Target Status"
                        link={filter => routeLinks.infrastructure.machines.filtered(filter)}
                        summary={this.state.environmentsSummary}
                        machineIconHelper={this.machineIconHelper} />
                    <RolesCard key="rolesCard" machineRoles={this.state.machineRoles} />
                    <WorkersCard key="workersCard" workerSummary={this.state.workerpoolsSummary} />

                    {/* These are wrapped in animations so they fade in when the data is available */}
                    {this.state.workerpoolsSummary && this.state.workerpoolsSummary.TotalMachines > 0 && <TransitionAnimation>
                        <MachinesCard
                            key="workersMachinesCard"
                            title="Workers"
                            link={filter => routeLinks.infrastructure.workerMachines.filtered(filter)}
                            summary={this.state.workerpoolsSummary}
                            machineIconHelper={this.machineIconHelper} />
                    </TransitionAnimation>}
                    {this.state.workerpoolsSummary && this.state.workerpoolsSummary.TotalMachines > 0 && <TransitionAnimation>
                        <HealthStatusCard
                            key="healthStatusCard"
                            title="Worker Status"
                            link={filter => routeLinks.infrastructure.workerMachines.filtered(filter)}
                            summary={this.state.workerpoolsSummary}
                            machineIconHelper={this.machineIconHelper} />
                    </TransitionAnimation>}
                    {this.state.environmentsSummary && this.state.tagSets && this.props.isMultiTenancyEnabled && isAllowed({
                        permission: Permission.TenantView,
                        tenant: "*",
                    }) && <TransitionAnimation>
                            <TagSetsCard key="tagSetsCard"
                                environmentSummary={this.state.environmentsSummary}
                                tagSets={this.state.tagSets}
                            />
                        </TransitionAnimation>}
                    {this.state.environmentsSummary && this.state.tenants && this.props.isMultiTenancyEnabled && isAllowed({
                        permission: Permission.TenantView,
                        tenant: "*",
                    }) && <TransitionAnimation>
                            <TenantsCard key="tenantsCard"
                                environmentSummary={this.state.environmentsSummary}
                                tenants={this.state.tenants}
                            />
                        </TransitionAnimation>}
                </div>}
            </PaperLayout>
        </InfrastructureLayout>;
    }

    private async loadData() {
        const machineRolesPromise = (isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.MachineRoles.all() : Promise.resolve<string[]>(undefined))
            .then(v => this.setState({ machineRoles: v }));
        const tenantsPromise = repository.Tenants.all().then(v => this.setState({ tenants: v }));
        const tagSetsPromise = tenantTagsets.getAll().then(v => this.setState({ tagSets: v }));

        const environmentOnboarding = isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.Environments.list({ take: 0 }).then(v => v.TotalResults === 0) : Promise.resolve(false);
        const workersOnboarding = isAllowed({ permission: Permission.WorkerView }) ? repository.Workers.list({ take: 0 }).then(v => v.TotalResults === 0) : Promise.resolve(false);

        const onboarding = Promise.all([environmentOnboarding, workersOnboarding]).then(v => this.setState({ showOnboarding: v[0] && v[1] }));

        const environmentsSummaryPromise = (isAllowed({ permission: Permission.EnvironmentView, wildcard: true }) ? repository.Environments.summary() : Promise.resolve<EnvironmentsSummaryResource>(undefined))
            .then(v => this.setState({ environmentsSummary: v }));
        const workerpoolsSummaryPromise = (isAllowed({ permission: Permission.WorkerView }) ? repository.WorkerPools.summary() : Promise.resolve<WorkerPoolsSummaryResource>(undefined))
            .then(v => this.setState({ workerpoolsSummary: v }));

        await Promise.all([machineRolesPromise, tenantsPromise, tagSetsPromise, onboarding, environmentsSummaryPromise, workerpoolsSummaryPromise]);
    }
}

const mapStateToProps = (state: GlobalState, props: InfrastructureDashboardProps): ConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled,
    };
};

export default connect(mapStateToProps)(InfrastructureDashboard);