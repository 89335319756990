import * as React from "react";
import {ExpandableFormSection, Summary, FormSectionHeading} from "components/form";
import Text from "components/form/Text/Text";
import {AccountType} from "client/resources/accountResource";
import AccountEditBase, {AccountEditModel} from "./AccountEditBase";
import {SensitiveValue} from "client/resources/propertyValueResource";
import Sensitive, {ObfuscatedPlaceholder} from "components/form/Sensitive/Sensitive";
import { AmazonWebServicesAccountResource } from "client/resources";
import {ActionButton} from "components/Button/ActionButton";
import { SaveAndTestAmazonWebServicesAccountDialog } from "./SaveAndTestAccountDialog";
import Dialog from "components/Dialog/Dialog";
import AccountUsage from "areas/infrastructure/components/AccountEdit/AccountUsage";
import Note from "components/form/Note/Note";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

interface AmazonWebServicesAccountModel extends AccountEditModel {
    accessKey: string;
    secretKey: SensitiveValue;
}

class AmazonWebServicesAccountEdit extends AccountEditBase<AmazonWebServicesAccountResource, AmazonWebServicesAccountModel> {

    getPartialModel(account?: AmazonWebServicesAccountResource): Partial<AmazonWebServicesAccountModel> {
        if (!account) {
            return {
                accessKey: null,
                secretKey: null
            };
        }

        return {
            accessKey: account.AccessKey,
            secretKey: account.SecretKey
        };
    }

    getPartialResource(): Partial<AmazonWebServicesAccountResource> & {AccountType: AccountType} {
        return {
            AccountType: AccountType.AmazonWebServicesAccount,
            AccessKey: this.state.model.accessKey,
            SecretKey: this.state.model.secretKey
        };
    }

    accessKeySummary() {
        return this.state.model.accessKey ?
            Summary.summary(this.state.model.accessKey) :
            Summary.placeholder("No access key provided");
    }

    secretKeySummary() {
        return this.state.model.secretKey && this.state.model.secretKey.HasValue ?
            Summary.summary(ObfuscatedPlaceholder) :
            Summary.placeholder("No secret key provided");
    }

    customSecondaryAction(): JSX.Element {
        return <div>
            <ActionButton onClick={() => this.handleSaveClick(true)} label="Save and Test"/>
            {this.state.accountId && <Dialog open={this.state.showTestDialog}>
                <SaveAndTestAmazonWebServicesAccountDialog
                    onOkClick={() => this.testDone()}
                    accountId={this.state.accountId}
                />
            </Dialog>}
        </div>;
    }

    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];

        if (this.state.account) {
            baseElements.push(<AccountUsage doBusyTask={this.doBusyTask} busy={this.state.busy} account={this.state.account} accountType={this.state.account.AccountType} key={"accountUsage"}/>);
        }

        baseElements.push(
            <FormSectionHeading title="Credentials" key={"header"}/>,
            <ExpandableFormSection
                key="accessKey"
                errorKey="accessKey"
                title="Access Key"
                summary={this.accessKeySummary()}
                help="The AWS access key to use when authenticating against Amazon Web Services.">
                <Note>
                    Refer to the <ExternalLink href="AwsDocsManagingAccessKeys">AWS documentation</ExternalLink> for
                    information on generating access and secret keys.
                </Note>
                <Text
                    value={this.state.model.accessKey}
                    onChange={accessKey => this.setModelState({accessKey})}
                    label="Access Key"
                    error={this.getFieldError("AccessKey")}/>
            </ExpandableFormSection>,
            <ExpandableFormSection
                key="secretKey"
                errorKey="secretKey"
                title="Secret Key"
                summary={this.secretKeySummary()}
                help="The AWS secret key to use when authenticating against Amazon Web Services.">
                <Sensitive
                    value={this.state.model.secretKey}
                    onChange={secretKey => this.setModelState({secretKey})}
                    label="Secret Key"
                    error={this.getFieldError("SecretKey")}/>
            </ExpandableFormSection>);

        return baseElements;

    }
}

export default AmazonWebServicesAccountEdit;
