import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {ActionSummaryProps} from "../actionSummaryProps";
import {ActionExecutionLocation} from "../../../client/resources/actionExecutionLocation";
import JavaKeystoreComponent, {JavaKeystoreProperties, JavaKeystoreActionEditState} from "./javaKeystoreComponent";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import Summary from "components/form/Sections/Summary";
import Roles from "components/Actions/Roles";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";

class JavaKeystoreActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Deploy a Java keystore to the target filesystem {this.props.targetRolesAsCSV && <React.Fragment>to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/></React.Fragment>}
        </div>;
    }
}

export class JavaKeystoreActionEdit extends BaseComponent<ActionEditProps<JavaKeystoreProperties>, JavaKeystoreActionEditState> {
    constructor(props: ActionEditProps<JavaKeystoreProperties>) {
        super(props);
        this.state = {
            project: null
        };
    }

    componentDidMount() {
        this.props.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.projectId);
            this.setState({project});
        });
    }

    render() {
        return <div>
            <ExpanderSectionHeading title="Keystore details"/>
            <ExpandableFormSection
                errorKey="Java.Certificate.Variable|Java.Certificate.KeystoreFilename"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Keystore Details"
                summary={this.keystoreSummary()}
                help="Specify the keystore details">
            <JavaKeystoreComponent
                projectId={this.props.projectId}
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                getFieldError={this.props.getFieldError}
                errors={this.props.errors}
                keystoreFilenameMessage={<span><p>
                            The path of the keystore file to create. This must be an absolute path, the parent directory
                            must exist, and the path must include the keystore filename e.g. <em>C:\Keys\my.keystore</em> or <em>/opt/server/conf/keys.store</em>.
                        </p>
                        <p>
                            If the file exists, it will be overwritten.
                        </p></span>}
                expandedByDefault={this.props.expandedByDefault}
            />
            </ExpandableFormSection>
        </div>;
    }

    keystoreSummary() {
        const properties = this.props.properties;
        if (properties["Java.Certificate.Variable"]) {
            return Summary.summary(<span>
                Deploying certificate referenced by
                the <strong>{properties["Java.Certificate.Variable"]}</strong> variable
                {properties["Java.Certificate.KeystoreFilename"] &&
                <span>
                    &nbsp;to the file <strong>{properties["Java.Certificate.KeystoreFilename"]}</strong>
                </span>}
                {properties["Java.Certificate.Password"] &&
                <span>
                    &nbsp;with a custom password
                </span>}
                {!properties["Java.Certificate.Password"] &&
                <span>
                    &nbsp;with the default password
                </span>}
                {properties["Java.Certificate.KeystoreAlias"] &&
                <span>
                    &nbsp;and with the alias <strong>{properties["Java.Certificate.KeystoreAlias"]}</strong>
                </span>}
                {!properties["Java.Certificate.KeystoreAlias"] &&
                <span>
                    &nbsp;and with the default alias
                </span>}
            </span>);
        }

        return Summary.placeholder(<span>Define the keystore details</span>);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.JavaDeployCertificate",
    summary: (properties, targetRolesAsCSV) => <JavaKeystoreActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: JavaKeystoreActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
});