import * as React from "react";
import AreaTitle from "components/AreaTitle";
import routeLinks from "../../../routeLinks";

const DashboardLayout: React.StatelessComponent<{}> = props => {
    return (
        <main id="maincontent">
            <AreaTitle
                link={routeLinks.root}
                title="Dashboard"
            />
            {props.children}
        </main>);
};

export default DashboardLayout;