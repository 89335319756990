import * as React from "react";
import * as cn from "classnames";

const styles = require("./style.less");

export class DataTableFooterColumn extends React.Component<any, any> {
    render() {
        const {
            className,
            ...otherProps
        } = this.props;
        return (
            <td className={cn(className, styles.dataTableFooterColumn)} {...otherProps}>
                {this.props.children}
            </td>
        );
    }
}