exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_scopeCell__3iqxM {\n  height: 100%;\n}\n.style_defineScope__kRs1_ {\n  cursor: inherit;\n  overflow: hidden;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"scopeCell": "style_scopeCell__3iqxM",
	"defineScope": "style_defineScope__kRs1_"
};