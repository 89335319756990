import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

export const OnboardingTextForDeploymentTargets = "Deployment targets represent the servers, machines and cloud services where your software and services will be deployed.";

const OnboardingDeploymentTargets = () => {
  return (
    <OnboardingPage
      title="Configure your deployment targets"
      intro={<span>{OnboardingTextForDeploymentTargets}</span>}
      learnMore={<span><ExternalLink href="OnboardingDeploymentTargetsLearnMore">Learn more</ExternalLink>.</span>}
    />
  );
};

export default OnboardingDeploymentTargets;