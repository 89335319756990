import BasicRepository, { ListArgs } from "./basicRepository";
import Client from "../client";
import { ChannelResource, ResourceCollection, ReleaseResource } from "client/resources";
import { FeedType } from "client/resources/feedResource";

export type ReleasesListArgs = {
    searchByVersion?: string;
} & ListArgs;

export interface VersionRuleTestResponse {
    Errors: string[];
    SatisfiesVersionRange: boolean;
    SatisfiesPreReleaseTag: boolean;
}

class ChannelRepository extends BasicRepository<ChannelResource, ChannelResource> {
    constructor(client: Client) {
        super("Channels", client);
    }
    ruleTest(version: string, searchOptions: {
        versionRange: string,
        preReleaseTag: string,
        feedType: FeedType
    }) {
        return this.client.post<VersionRuleTestResponse>(this.client.getLink("VersionRuleTest"), {
            preReleaseTag: searchOptions.preReleaseTag,
            version,
            versionRange: searchOptions.versionRange,
            feedType: searchOptions.feedType
        });
    }
    getReleases(channel: ChannelResource, options?: ReleasesListArgs): Promise<ResourceCollection<ReleaseResource>>  {
        return this.client.get(channel.Links["Releases"], options);
    }
}

export default ChannelRepository;