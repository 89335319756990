exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_popover__18YFW {\n  position: absolute;\n}\n.style_popoverPositionContainer__2-qCU {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"popover": "style_popover__18YFW",
	"popoverPositionContainer": "style_popoverPositionContainer__2-qCU"
};