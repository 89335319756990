import * as React from "react";
import { session, repository } from "clientInstance";
import { Text, MarkdownEditor, Select, required } from "components/form";
import { LifecycleResource, ProjectGroupResource, TenantedDeploymentMode, ProjectResource, GuidedFailureMode, EnvironmentResource } from "client/resources";
import { ActionButton, ActionButtonType } from "components/Button";
import Note from "../../../../components/form/Note/Note";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap";
const styles = require("./style.less");
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, { CalloutType } from "components/Callout";
import { ResourcesById } from "client/repositories/basicRepository";

interface AddProjectDialogProps {
    title: string;
    cloneId?: string;
    groupId?: string;
    hasProjects: boolean;
    lifecycleId?: string;
    projectCreated(project: ProjectResource): any;
}

interface AddProjectDialogState extends DataBaseComponentState {
    name: string;
    description: string;
    newProjectId: string;
    projectGroupId: string;
    selectedLifecycle: LifecycleResource;
    cloneGroupId: string;
    projectGroups: ProjectGroupResource[];
    lifecycles: LifecycleResource[];
    environmentsById: ResourcesById<EnvironmentResource>;
    selectedCloneId: string;
    otherProjects: ProjectResource[];
    dialogResizeKey: string;
    showLifecycleMap: boolean;
    showAdvanced: boolean;
}

export default class AddProject extends DataBaseComponent<AddProjectDialogProps, AddProjectDialogState> {

    constructor(props: AddProjectDialogProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            projectGroupId: "",
            selectedLifecycle: null,
            cloneGroupId: null,
            projectGroups: [],
            lifecycles: [],
            environmentsById: {},
            newProjectId: null,
            selectedCloneId: this.props.cloneId,
            otherProjects: [],
            dialogResizeKey: "",
            showLifecycleMap: false,
            showAdvanced: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            let allProjects;
            if (this.props.cloneId) {
                allProjects = repository.Projects.all();
            }

            const [projectGroups, lifecycles, environmentsById, otherProjects] =
                await Promise.all<ProjectGroupResource[], LifecycleResource[], ResourcesById<EnvironmentResource>, ProjectResource[]>([
                    repository.ProjectGroups.all(),
                    repository.Lifecycles.all(),
                    repository.Environments.allById(),
                    allProjects
                ]);

            const lifecyclePreviews = await Promise.all(lifecycles.map(l => repository.Lifecycles.preview(l)));

            let predicate = (x: any) => x.Name === "Default Lifecycle";
            if (this.props.lifecycleId) {
                predicate = x => x.Id === this.props.lifecycleId;
            }

            const lifecycle = lifecyclePreviews.find(predicate);
            const projectGroup = projectGroups.find(x => x.Name === "All Projects" || x.Name === "Default Project Group");

            const projectGroupId = this.props.groupId || (projectGroup
                ? projectGroup.Id
                : projectGroups[0].Id);

            this.setState({
                lifecycles: lifecyclePreviews,
                projectGroups,
                environmentsById,
                selectedLifecycle: lifecycle || lifecyclePreviews[0],
                projectGroupId,
                cloneGroupId: this.props.groupId,
                otherProjects
            });
        });
    }

    async save() {
        await this.doBusyTask(async () => {
            const args = { clone: this.state.selectedCloneId };
            const result = await repository.Projects.create({
                Id: null,
                VariableSetId: null,
                DeploymentProcessId: null,
                DiscreteChannelRelease: false,
                IncludedLibraryVariableSetIds: null,
                DefaultToSkipIfAlreadyInstalled: false,
                TenantedDeploymentMode: TenantedDeploymentMode.Untenanted,
                VersioningStrategy: { Template: "#{Octopus.Version.LastMajor}.#{Octopus.Version.LastMinor}.#{Octopus.Version.NextPatch}" },
                ReleaseCreationStrategy: null,
                Templates: [],
                AutoDeployReleaseOverrides: [],
                Name: this.state.name,
                Slug: null,
                Description: this.state.description,
                IsDisabled: false,
                ProjectGroupId: this.state.projectGroupId,
                LifecycleId: this.state.selectedLifecycle.Id,
                AutoCreateRelease: false,
                DefaultGuidedFailureMode: GuidedFailureMode.EnvironmentDefault,
                ProjectConnectivityPolicy: {
                    SkipMachineBehavior: "None",
                    TargetRoles: [],
                    AllowDeploymentsToNoTargets: false,
                    ExcludeUnhealthyTargets: false
                },
                logo: null,
                ClonedFromProjectId: null,
                Links: null
            }, args);

            // refresh permissions to include the new project
            const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
            session.refreshPermissions(permissionSet);

            this.props.projectCreated(result);
            return true;
        });

        return false;
    }

    render() {
        if (!this.state.lifecycles || this.state.lifecycles.length < 1) {
            // No lifecycles = fail.
            return null;
        }
        const showAdvancedButton = this.state.lifecycles && this.state.lifecycles.length <= 1;
        return <SaveDialogLayout
            title={this.props.title}
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => this.save()}>
            <div>
                {!this.state.busy && Object.keys(this.state.environmentsById).length === 0 && <Callout type={CalloutType.Warning} title="No Environments Configured">
                    Please consider <InternalLink to={routeLinks.infrastructure.overview}>configuring your infrastructure</InternalLink> before setting up a project.
                </Callout>}
                <Text
                    label="New project name"
                    value={this.state.name}
                    onChange={name => this.setState({ name })}
                    validate={required("Please enter a project name")}
                    autoFocus={true}
                />
                {/* <div style={{ marginTop: "1rem" }} /> */}
                {showAdvancedButton && !this.state.showAdvanced && <ActionButton label={"Show Advanced"}
                    type={ActionButtonType.Secondary}
                    onClick={() => {
                        // Perform in doBusy to trigger our dialog resize fix #dialogHack
                        this.doBusyTask(async () => {
                            this.setState({ showAdvanced: !this.state.showAdvanced });
                        });
                    }} />}
                {(!showAdvancedButton || this.state.showAdvanced) && <React.Fragment>
                    <MarkdownEditor
                        label="Project description"
                        value={this.state.description}
                        onChange={this.handleDescriptionChanged}
                    />
                    <Select
                        value={this.state.projectGroupId}
                        onChange={projectGroupId => this.setState({ projectGroupId })}
                        items={this.state.projectGroups.map(pg => ({ value: pg.Id, text: pg.Name }))}
                        label="Project group"
                    />
                    <Select
                        value={this.state.selectedLifecycle ? this.state.selectedLifecycle.Id : ""}
                        onChange={this.handleLifeCycleChange}
                        items={this.state.lifecycles.map(l => ({ value: l.Id, text: l.Name }))}
                        label="Lifecycle"
                    />
                    <Note>
                        Lifecycles determine which environments the project can be deployed to, and the promotion rules between those
                        environments. <InternalLink to={routeLinks.library.lifecycles} openInSelf={false}>Create or modify lifecycles</InternalLink>
                    </Note>
                    {Object.keys(this.state.environmentsById).length > 0 && <ActionButton
                        label={this.state.showLifecycleMap ? "Hide lifecycle" : "Show lifecycle"}
                        onClick={() => {
                            // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
                            this.doBusyTask(async () => {
                                this.setState({ showLifecycleMap: !this.state.showLifecycleMap });
                            });
                        }}
                    />}
                    {this.state.showLifecycleMap && <div>
                        {this.state.selectedLifecycle && <div className={styles.lifecycleMap}>
                            <LifecycleMap environmentsById={this.state.environmentsById}
                                lifecyclePreview={this.state.selectedLifecycle} />
                        </div>}
                    </div>}
                    {this.props.cloneId && [<Select
                        allowClear={true}
                        allowFilter={true}
                        value={this.state.selectedCloneId}
                        onChange={cloneId => this.setState({ selectedCloneId: cloneId.length > 0 ? cloneId : null })}
                        items={this.state.otherProjects.map(pg => ({ value: pg.Id, text: pg.Name }))}
                        label="Clone from" />,
                    <Note>Select an existing project to clone.
                    The deployment process and variables set in the other project will be copied into the new project when it is created.</Note>]}
                </React.Fragment>}
            </div>
        </SaveDialogLayout>;
    }

    private handleLifeCycleChange = (value: any) => {
        // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
        this.doBusyTask(async () => {
            const lifecycles = this.state.lifecycles.filter(l => l.Id === value);
            this.setState({ selectedLifecycle: lifecycles.length > 0 ? lifecycles[0] : null });
        });
    }

    private handleDescriptionChanged = (description: string) => {
        // in dobusy so the dialog resizes if the markdowneditor grows
        this.doBusyTask(async () => {
            this.setState({ description });
        });
    }
}