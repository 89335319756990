import Resource from "./resource";

export enum UpgradeNotificationMode {
    AlwaysShow = "AlwaysShow",
    ShowOnlyMajorMinor = "ShowOnlyMajorMinor",
    NeverShow = "NeverShow"
}

export class UpgradeConfigurationResource extends Resource {
    NotificationMode: UpgradeNotificationMode;
}

export default UpgradeConfigurationResource;
