import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ActionSummaryProps} from "../actionSummaryProps";
import {ActionExecutionLocation} from "client/resources/actionExecutionLocation";
import TomcatFeatureTemplate, {TomcatDeployProperties} from "./tomcatFeatureTemplate";
import Roles from "components/Actions/Roles";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";

class ChangeStateInTomcatActionSummary extends BaseComponent<ActionSummaryProps, any> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Change the state of an application in Tomcat 7+ {this.props.targetRolesAsCSV &&
        <span> on deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV}/> </span>}
        </div>;
    }
}

export class ChangeStateInTomcatActionEdit extends BaseComponent<ActionEditProps<TomcatDeployProperties>, {}> {
    render() {
        return <TomcatFeatureTemplate
            properties={this.props.properties}
            packages={this.props.packages}
            plugin={this.props.plugin}
            setProperties={this.props.setProperties}
            setPackages={this.props.setPackages}
            doBusyTask={this.props.doBusyTask}
            busy={this.props.busy}
            getFieldError={this.props.getFieldError}
            errors={this.props.errors}
            expandedByDefault={this.props.expandedByDefault}
            projectId={this.props.projectId}
        />;
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.TomcatState",
    summary: (properties, targetRolesAsCSV) => <ChangeStateInTomcatActionSummary properties={properties}
                                                                                 targetRolesAsCSV={targetRolesAsCSV}/>,
    edit: ChangeStateInTomcatActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
});