import ExternalLink from "../../../../components/Navigation/ExternalLink/index";
import * as React from "react";
import SimpleDataTable from "components/SimpleDataTable";
const styles = require("./style.less");
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import { Permission } from "client/resources";
import OverflowMenu from "components/Menu/OverflowMenu";
import routeLinks from "routeLinks";
import { ExternalFeedResource } from "client/resources/feedResource";
import { repository } from "clientInstance";

interface FeedListProps {
    feeds: ExternalFeedResource[];
    onDelete(feed: ExternalFeedResource): Promise<boolean>;
}

class ExternalFeedList extends React.Component<FeedListProps> {
    constructor(props: FeedListProps) {
        super(props);
    }

    render() {
        return <SimpleDataTable
            data={this.props.feeds}
            headerColumns={["Name", "URL", ""]}
            headerColumnClassNames={[styles.headerColumn, styles.headerColumn, styles.headerColumn]}
            rowColumnClassName={styles.rowColumn}
            onRowRedirectUrl={(feed: ExternalFeedResource) => routeLinks.library.feed(feed.Id).root}
            onRow={this.buildFeedRow} />;
    }

    private buildFeedRow = (feed: ExternalFeedResource) => {
        return [
            <div className={styles.feedName}>{feed.Name}</div>,
            <ExternalLink href={feed.FeedUri} openInSelf={false}>{feed.FeedUri}</ExternalLink>,
        <PermissionCheck permission={Permission.FeedEdit} wildcard={true} >
                <OverflowMenu menuItems={[
                    // normally, we wouldn't have an Edit/Delete menu item here, but we've had
                    // customers confused how to edit an item (due to the prominance of the feed url)
                    OverflowMenu.navItem("Edit", routeLinks.library.feed(feed.Id).root),
                    OverflowMenu.deleteItemDefault("feed", () => this.deleteFeed(feed), {permission: [Permission.AdministerSystem, Permission.FeedEdit]})
                ]} />
            </PermissionCheck>
        ];
    }

    private deleteFeed = async (feed: ExternalFeedResource): Promise<boolean> => {
        await repository.Feeds.del(feed);

        return this.props.onDelete(feed);
    }
}
export default ExternalFeedList;