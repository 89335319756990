exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_deletedContent__1aIQQ {\n  color: #9e9e9e !important;\n  text-decoration: line-through;\n}\n.style_nameCell__3Aq6h {\n  height: 100%;\n  overflow: \"hidden\";\n  font-weight: 600;\n}\n.style_nameCellContentReadonly__rVAMj {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.style_inputContainer__1F-VH {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"deletedContent": "style_deletedContent__1aIQQ",
	"nameCell": "style_nameCell__3Aq6h",
	"nameCellContentReadonly": "style_nameCellContentReadonly__rVAMj",
	"inputContainer": "style_inputContainer__1F-VH"
};