import * as React from "react";
import {Section} from "components/Section/Section";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import {RemoveItemsList} from "components/RemoveItemsList/RemoveItemsList";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {UserResource, ResourceCollection, ApiKeyResource} from "client/resources";
import {repository} from "clientInstance";
import AddApiKeyDialog from "./AddApiKeyDialog";
import {NothingToSeeHere} from "components/NothingToSeeHere";
import TimeFromNowLabel from "components/TimeLabels/TimeFromNowLabel";

const styles = require("./style.less");

interface UserApiKeysListProps {
    user: UserResource;
}

interface UserApiKeysState extends DataBaseComponentState {
    apiKeyCollection: ResourceCollection<ApiKeyResource>;
    purpose: string;
    lastApiKey: string;
}

class ApiKeyList extends RemoveItemsList<ApiKeyResource> {
}

export default class UserApiKeysList extends DataBaseComponent<UserApiKeysListProps, UserApiKeysState> {
    constructor(props: UserApiKeysListProps) {
        super(props);

        this.state = ({
            apiKeyCollection: null,
            purpose: null,
            lastApiKey: null
        });
    }
    async componentDidMount() {
        const apiKeyCollection = await repository.Users.listApiKeys(this.props.user);

        this.setState({
            apiKeyCollection
        });
    }

    render() {
        return <Section>
            {this.state.apiKeyCollection && this.renderApiKeys()}
            {this.state.apiKeyCollection
                && this.state.apiKeyCollection.Items.length === 0
                && <NothingToSeeHere content="There aren't currently any API keys to show."/>}
        </Section>;
    }

    renderApiKeys = () => {
        const actions =
        [<OpenDialogButton key="newKey" label="New API Key">
            <AddApiKeyDialog
                apiKey={this.state.lastApiKey}
                onAdd={(purpose) => this.handleKeyAdded(purpose)}
                onClose={() => this.setState({lastApiKey: null})}
                 />
        </OpenDialogButton>
        ];

        return <ApiKeyList
                    listActions={actions}
                    data={this.state.apiKeyCollection.Items}
                    onRow={(apiKey) => <div key={apiKey.Id}>
                        <h3 className={styles.apiKeyEntry}>{apiKey.Purpose || "General-purpose API key"}</h3>
                        <div>Created <TimeFromNowLabel time={apiKey.Created} /></div>
                    </div>}
                    onRemoveRow={(apiKey) => this.handleKeyRemoved(apiKey)}/>;
    }

    async handleKeyAdded(purpose: string): Promise<any> {
        await this.doBusyTask(async () => {
            const newKey = await repository.Users.createApiKey(this.props.user, purpose);

            this.setState(state => ({
                apiKeyCollection: {
                    ...state.apiKeyCollection,
                    Items: [newKey, ...state.apiKeyCollection.Items]
                },
                lastApiKey: newKey.ApiKey
            }));
        });
    }

    handleKeyRemoved = async (deleteThisApiKey: ApiKeyResource) => {
        await this.doBusyTask(async () => {

            const result = await repository.Users.revokeApiKey(deleteThisApiKey);

            this.setState(state => ({
                apiKeyCollection: {
                    ...state.apiKeyCollection,
                    Items: [...state.apiKeyCollection.Items.filter(i => i.Id !== deleteThisApiKey.Id)]
                }
            }));
        });
    }
}