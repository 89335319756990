import * as React from "react";
import * as cn from "classnames";
import {PhaseResource} from "client/resources/index";
const styles = require("./style.less");
import ExecutingIcon from "./ExecutingIcon";
import {LifecycleStatus} from "utils/MapProgressionToStatus/MapProgressionToStatus";
import {getSingleDeploymentTarget, getSkippedState} from "utils/PhaseHelper/phaseHelpers";

interface Props {
    phase: PhaseResource;
    status: LifecycleStatus;
    environmentId?: string;
}

const PhaseStatusIcon = (props: Props) => {
    const classes = getClasses(props.phase, props.status, props.environmentId);
    return showExecutingIcon(classes)
        ? <ExecutingIcon />
        : <span className={cn(styles.phaseIcon, classes)}/>;
};

const showExecutingIcon = (classes: { [className: string]: boolean; }): boolean => {
    return classes[styles.executing]
        && !classes[styles.queued]
        && !classes[styles.warning]
        && !classes[styles.skipped];
};

const getClasses = (phase: PhaseResource, status: LifecycleStatus, environmentId?: string) => {
    if (!environmentId) {
        return {
            [styles.optional]: phase.IsOptionalPhase,
            [styles.blocked]: status.progress[phase.Name] === "Blocked",
            [styles.current]: status.progress[phase.Name] === "Current",
            [styles.complete]: status.progress[phase.Name] === "Complete",
            [styles.automatic]: phase.AutomaticDeploymentTargets.length > 0,
            [styles.deployed]: status.deployed[getSingleDeploymentTarget(phase)],
            [styles.executing]: status.executing[getSingleDeploymentTarget(phase)],
            [styles.queued]: status.queued[getSingleDeploymentTarget(phase)],
            [styles.warning]: status.warning[getSingleDeploymentTarget(phase)],
            [styles.skipped]: getSkippedState(phase, status)
        };
    } else {
        return {
            [styles.blocked]: phase.AutomaticDeploymentTargets.length > 0 ? false : status.progress[phase.Name] === "Blocked",
            [styles.automatic]: status.deployed[environmentId] ? false : phase.AutomaticDeploymentTargets.some(envId => envId === environmentId),
            [styles.deployed]: status.deployed[environmentId],
            [styles.warning]: status.warning[environmentId],
            [styles.executing]: status.executing[environmentId],
            [styles.queued]: status.queued[environmentId],
            [styles.skipped]: phase.IsOptionalPhase && !status.deployed[environmentId] && status.progress[phase.Name] !== "Blocked"
        };
    }
};

export default PhaseStatusIcon;