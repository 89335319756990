import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../../Actions/pluginRegistry";
import {BaseComponent} from "../../BaseComponent/BaseComponent";
import {ExpandableFormSection, Summary} from "../../form";
import { BoundStringCheckbox } from "../../form/Checkbox/StringCheckbox";
import Note from "../../form/Note/Note";
import { VariableLookupText } from "../../form/VariableLookupText";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.Package.CustomInstallationDirectory": "",
    "Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment": "",
    "Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions": "",
    "Octopus.Action.Package.AdditionalXmlConfigurationTransforms": "",
};

type CustomDirectoryProperties = {[P in keyof typeof StringProperties]: string };

class CustomDirectoryEdit extends BaseComponent<ActionEditProps<CustomDirectoryProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<CustomDirectoryProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        if (this.props.properties["Octopus.Action.Package.CustomInstallationDirectory"]) {
            const nodes = [];
            nodes.push(<span>Package will be installed to <strong>{this.props.properties["Octopus.Action.Package.CustomInstallationDirectory"]}</strong></span>);
            if (this.props.properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"] === "True") {
                nodes.push(<span>, the directory will be purged before deployment</span>);
            }
            return Summary.summary(React.Children.toArray(nodes));
        }

        return Summary.placeholder(<span>Package will be installed to the standard location</span>);
    }

    render() {
        const properties = this.props.properties;
        const purge = properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"];
        const showExclude = purge && purge !== "False";

        return <ExpandableFormSection
            errorKey="Octopus.Action.Package.CustomInstallationDirectory|Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"
            isExpandedByDefault={this.props.expandedByDefault}
            title="Custom Install Directory"
            summary={this.summary()}
            help="Enter a custom installation directory.">
            <VariableLookupText
                localNames={this.props.localNames}
                projectId={this.props.projectId}
                value={properties["Octopus.Action.Package.CustomInstallationDirectory"]}
                onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectory"]: x})}
                error={this.props.getFieldError("Octopus.Action.Package.CustomInstallationDirectory")}
                label="Install to" />
            <Note>After the package is extracted, it will be copied to this location on the remote machine.</Note>
            <BoundStringCheckbox
                variableLookup={{
                    localNames: this.props.localNames,
                    projectId: this.props.projectId
                }}
                resetValue={"False"}
                value={properties["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"]}
                onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectoryShouldBePurgedBeforeDeployment"]: x})}
                label="Purge this directory before installation" />
            <Note>Before the contents of the extracted package is copied, all files in this location will be removed.</Note>
            {showExclude &&
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"]}
                    onChange={(x) => this.props.setProperties({["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"]: x})}
                    multiLine={true}
                    error={this.props.getFieldError("Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions")}
                    label="Exclude from purge" />
            }
            {showExclude && <Note>A newline-separated list of file or directory names, relative to the installation directory, to leave when it is purged.
                To exclude a whole directory, specify it by name without a wildcard.
                Extended wildcard syntax is supported. E.g., <em>appsettings.config</em>, <em>Config</em>, <em>Config\*.config</em>, <em>**\*.config</em></Note>}
        </ExpandableFormSection>;
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.CustomDirectory",
    title: "Custom Installation Directory",
    description: "Change where Tentacle extracts your package",
    edit: CustomDirectoryEdit,
    priority: 5,
    disable: (properties: ActionProperties) => {
        /*
            The java package deployment and this feature share the following properties. We can't
            delete them from the global properties object if UseCustomInstallationDirectory is defined.
         */
        if (properties["Octopus.Action.Package.UseCustomInstallationDirectory"] !== "True") {
            delete properties["Octopus.Action.Package.CustomInstallationDirectory"];
            delete properties["Octopus.Action.Package.CustomInstallationDirectoryPurgeExclusions"];
        }
    }
});
