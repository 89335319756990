import * as React from "react";
import MarkdownDescription from "components/MarkdownDescription";
import {SFC} from "react";
import {Callout, CalloutType} from "components/Callout/Callout";
const styles = require("./style.less");
import {PackageModel} from "../packageModel";
import Note from "../../../../../components/form/Note/Note";

interface PackagesListProps {
    packages: PackageModel[];
}

const PackagesList: SFC<PackagesListProps> = props => {
        return (
            <div>
                {props.packages && props.packages.length > 0
                ? <ul>
                    {props.packages.map((pkg, index) =>
                        <li key={index} className={pkg.Notes.Succeeded ? styles.ok : styles.error}>
                            <b>
                                <dfn title={pkg.PackageId + " from feed " + pkg.FeedName}>
                                    {renderPackageReference(pkg)} version {pkg.Version}
                                </dfn>
                            </b>
                            {pkg.Notes.Notes && <Note><MarkdownDescription markup={pkg.Notes.Notes}/></Note>}
                            {pkg.Notes.FailureReason && <div className={styles.container}>
                                <Callout title={pkg.Notes.FailureReason} type={CalloutType.Danger}/>
                            </div>}
                        </li>
                    )}
                </ul>
                : <span>There are no packages associated with any steps.</span>}
            </div>
        );
};

const renderPackageReference = (pkg: PackageModel) => {
   if (pkg.ProjectName) {
       return <React.Fragment>{pkg.ActionName}:{pkg.ProjectName}</React.Fragment>;
   }

   if (pkg.PackageReferenceName) {
       return <React.Fragment>{pkg.ActionName}:{pkg.PackageReferenceName}({pkg.PackageId})</React.Fragment>;
   }

   return <React.Fragment>{pkg.ActionName}:{pkg.PackageId}</React.Fragment>;
};

PackagesList.displayName = "PackagesList";

export default PackagesList;