exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_defaultIconButton__1I_W4 {\n  width: 2.25rem !important;\n  height: 2.25rem !important;\n  padding: 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"defaultIconButton": "style_defaultIconButton__1I_W4"
};