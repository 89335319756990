import * as React from "react";
import {repository} from "clientInstance";
import SortableList from "components/SortableList/SortableList";
import {DeploymentProcessResource} from "client/resources/deploymentProcessResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";

interface DeploymentPartSorterFormProps {
    title: string;
    deploymentProcessId: string;
    stepId?: string;

    saveDone(): void;
}

interface DeploymentPartSorterFormState extends DataBaseComponentState {
    deploymentProcess: DeploymentProcessResource;
}

class DeploymentPartSorter extends DataBaseComponent<DeploymentPartSorterFormProps, DeploymentPartSorterFormState> {
    constructor(props: DeploymentPartSorterFormProps) {
        super(props);

        this.state = {
            deploymentProcess: null
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const deploymentProcess = await repository.DeploymentProcesses.get(this.props.deploymentProcessId);
            this.setState({deploymentProcess});
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            await repository.DeploymentProcesses.modify(this.state.deploymentProcess);
            this.props.saveDone();
        });
    }

    render() {

        const deploymentProcess = this.state.deploymentProcess;

        const body = deploymentProcess &&
            <SortableList onOrderChanged={this.props.stepId ? this.actionsOrderChanged : this.stepsOrderChanged}
                          items={this.props.stepId
                              ? this.getStep(deploymentProcess.Steps, this.props.stepId).Actions
                              : deploymentProcess.Steps}/>;

        return <SaveDialogLayout title={this.props.title}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            {body}
        </SaveDialogLayout>;
    }

    private getStep(steps: any, stepId: string) {
        return steps.filter((s: any) => s.Id === stepId)[0];
    }

    private stepsOrderChanged = (Steps: any[]) => {
        this.setChildState1("deploymentProcess", {Steps});
    }

    private actionsOrderChanged = (actions: any[]) => {
        this.setState(state => {
            const steps = [...state.deploymentProcess.Steps];
            const index = steps.findIndex(s => s.Id === this.props.stepId);
            steps[index] = {...steps[index], Actions: actions};
            return {
                deploymentProcess: {
                    ...state.deploymentProcess,
                    Steps: steps
                }
            };
        });
    }
}

export default DeploymentPartSorter;