exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_scrollUpButton__DIhQr {\n  width: 30px;\n  height: 30px;\n  background-color: rgba(102, 102, 102, 0.5) !important;\n  padding: 1.5rem;\n  z-index: 100;\n  border-radius: 30px;\n  font-family: FontAwesome;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #ffffff;\n}\n.style_ScrollUpButton__Container__SY05A {\n  position: fixed;\n  right: -100px;\n  bottom: 20px;\n  transition: right 0.5s;\n  cursor: pointer;\n  font-size: 20px;\n  width: 30px;\n  height: 30px;\n  background-color: rgba(102, 102, 102, 0.5) !important;\n  padding: 1.5rem;\n  z-index: 100;\n  border-radius: 30px;\n  font-family: FontAwesome;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #ffffff;\n}\n.style_ScrollUpButton__Container__SY05A:hover {\n  background-color: #666666 !important;\n}\n.style_ScrollUpButton__Toggled__-2aQc {\n  right: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"scrollUpButton": "style_scrollUpButton__DIhQr",
	"ScrollUpButton__Container": "style_ScrollUpButton__Container__SY05A",
	"scrollUpButtonContainer": "style_ScrollUpButton__Container__SY05A",
	"ScrollUpButton__Toggled": "style_ScrollUpButton__Toggled__-2aQc",
	"scrollUpButtonToggled": "style_ScrollUpButton__Toggled__-2aQc"
};