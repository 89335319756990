export const white = "#ffffff";
export const primaryText = "#333333";
export const secondaryText = "#9e9e9e";
export const ternaryText = "#666666";

export const primary = "#2f93e0";
export const primaryDarkest = "#0F2535";
export const primaryDark = "#0A67B1";
export const primaryLight = "#74c0fb";

export const secondaryDark = "#053B62";

export const hover = "rgba(153, 153, 153, 0.08)";
export const divider = "#cccccc";
export const disabledBackground = "rgba(153, 153, 153, 0.7)";
export const secondaryBackground = "#e0e0e0";

export const info = "#0A67B1" ;
export const success = "#48B350";
export const danger = "#db4437";
export const alert = "#fb8c00";
export const disable = "#fbe1e9";

export const dangerHighlight = "#f8e8e6";

export const shadow40 = "rgba(0, 0, 0, 0.4)";
export const shadow60 = "rgba(0, 0, 0, 0.6)";

export const theme = {
    textField: {
        textColor: primaryText
    },
    paper: {
        textColor: primaryText,
        backgroundColor: white,
    },
    menuItem: {
        hoverColor: hover
    },
    stepper: {
        iconColor: success,
        hoverBackgroundColor: hover,
        inactiveIconColor: secondaryText,
        textColor: primaryText,
        disabledTextColor: secondaryText,
        connectorLineColor: secondaryText,
    },
    palette: {
        primary1Color: primary,
        // primary2Color: primaryText,
        // primary3Color: primaryText,
        // accent1Color: hover,
        // accent2Color: hover,
        // accent3Color: hover,
        textColor: primaryText,
        // alternateTextColor: hover,
        canvasColor: white,
        // borderColor: hover,
        // disabledColor: disable,
        // pickerHeaderColor: hover,
        // clockCircleColor: hover,
        // shadowColor: white,
      },
    datePicker: {
        headerColor: primary,
        color: primary,
        selectColor: primary,
    },
    flatButton: {
        primaryTextColor: primary,
    }
};