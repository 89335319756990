exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_clickableChip__tSjgX > div:first-child:after {\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background: rgba(0, 0, 0, 0.2);\n  opacity: 0;\n  transition: all 0.5s;\n  -webkit-transition: all 0.5s;\n  -moz-transition: all 0.5s;\n  border-radius: 1rem;\n  z-index: 0;\n}\n.styles_clickableChip__tSjgX > div:first-child:hover:after {\n  opacity: 1;\n}\n.styles_clickableChip__tSjgX > div span {\n  z-index: 1;\n}\n", ""]);

// exports
exports.locals = {
	"clickableChip": "styles_clickableChip__tSjgX"
};