export class AccountUsageResource {
    Targets: TargetUsageEntry[];
    DeploymentProcesses: StepUsage[];
    Releases: ReleaseUsage[];
    ProjectVariableSets: ProjectVariableSetUsage[];
    LibraryVariableSets: LibraryVariableSetUsageEntry[];
}

export class TargetUsageEntry {
    TargetName: string;
    TargetId: string;
}

export class ProjectVariableSetUsage {
    ProjectName: string;
    ProjectSlug: string;
    ProjectId: string;
    IsCurrentlyBeingUsedInProject: boolean;
    Releases: ReleaseUsageEntry[];
}

export class LibraryVariableSetUsageEntry {
    LibraryVariableSetId: string;
    LibraryVariableSetName: string;
}

export class ReleaseUsage {
    ProjectName: string;
    ProjectId: string;
    Releases: ReleaseUsageEntry[];
}

export class ReleaseUsageEntry {
    ReleaseId: string;
    ReleaseVersion: string;
}

export class StepUsage {
    ProjectName: string;
    ProjectSlug: string;
    ProjectId: string;
    Steps: StepUsageEntry[];
}

export class StepUsageEntry {
    StepName: string;
    StepId: string;
}

export default AccountUsageResource;