exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_listTitle__2-j_A {\n  font-size: 1.063rem;\n  line-height: 1.18rem;\n  color: #2f93e0;\n  margin: 0.25rem 0;\n  font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"listTitle": "style_listTitle__2-j_A"
};