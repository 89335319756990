import * as React from "react";
import BaseComponent from "components/BaseComponent";
import { FormFieldProps } from "components/form";
import { MultiSelect } from "components/MultiSelect";
import { MachineModelHealthStatusChip } from "components/Chips";
import {
    MachineModelHealthStatus,
    MachineModelHealthStatusResource,
} from "client/resources";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface MachineModelHealthStatusMultiSelectProps extends FormFieldProps<MachineModelHealthStatus[]> {
    items: MachineModelHealthStatusResource[];
    label?: string | JSX.Element;
    error?: string;
}

const MachineModelHealthStatusTypedMultiSelect = MultiSelect<MachineModelHealthStatusResource>();

const MachineModelHealthStatusMultiSelect: React.StatelessComponent<MachineModelHealthStatusMultiSelectProps> = props => {
    const chipRenderer = (r: MachineModelHealthStatusResource, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.MachineModelHealthStatus}
            chipRender={(item: MachineModelHealthStatusResource) => <MachineModelHealthStatusChip
                onRequestDelete={onRequestDelete}
                healthStatus={item} />}
        />;
    };

    return <MachineModelHealthStatusTypedMultiSelect
        items={props.items}
        fieldName="health statuses"
        renderChip={chipRenderer}
        {...props} />;
};

export default MachineModelHealthStatusMultiSelect;