exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_linkableItem__4eNnA {\n  cursor: pointer;\n}\n.style_tableContainer__3WlwJ {\n  overflow-x: auto;\n}\n", ""]);

// exports
exports.locals = {
	"linkableItem": "style_linkableItem__4eNnA",
	"tableContainer": "style_tableContainer__3WlwJ"
};