import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {repository} from "clientInstance";
import {some, find} from "lodash";
import {IdentityMetadataResource} from "client/authentication/authenticationProviderElement";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import {ClaimsBasedIdentity} from "client/resources/identityResource";
import Callout, {CalloutType} from "components/Callout/Callout";
import ListTitle from "components/ListTitle/ListTitle";
import {SimpleList} from "components/List";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import IdHelper from "utils/IdHelper";
import {
    ActionButton,
} from "components/Button";
import {
    Text,
} from "components/form";
import {required} from "components/form/Validators";
const styles = require("./style.less");

interface AddIdentityState extends DataBaseComponentState {
    identity: ClaimsBasedIdentity;
    query?: string;
    searchResults: ClaimsBasedIdentity[];
    supportsSearch: boolean;
    selectedSearchResultKey?: string;
}

interface AddIdentityProps {
    selectedProvider: IdentityMetadataResource;
    onAddIdentity: (identity: any) => boolean;
}

class IdentityResults extends SimpleList<ClaimsBasedIdentity> { }

class AddIdentityDialog extends DataBaseComponent<AddIdentityProps, AddIdentityState> {

    constructor(props: AddIdentityProps) {
        super(props);

        this.state = {
            identity: this.createIdentity(),
            supportsSearch: this.supportsSearch(),
            searchResults: null //when it's an empty list we can show message showing no results
        };
    }

    render() {
        return <OkDialogLayout
                    onOkClick={this.addIdentity}
                    okButtonDisabled={this.disableAddIdentity()}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    okButtonLabel={this.state.supportsSearch ? "Select" : "Add"}
                    title={"Add Login"}>
            <h4>External Login Details</h4>

            {this.state.identity
                ? (this.state.supportsSearch ? this.renderSearch() : this.renderControlsForClaim())
                : <div>There seems to be a problem with the properties on this identity provider</div>}

        </OkDialogLayout>;
    }

    addIdentity = () => {
        if (this.state.selectedSearchResultKey) {
            const identityToAdd = this.state.searchResults
                .find(i => i.Id === this.state.selectedSearchResultKey);

            if (identityToAdd) {
                return this.props.onAddIdentity(identityToAdd);
            }
        }

        return this.props.onAddIdentity(this.state.identity);
    }

    disableAddIdentity() {
        if (this.state.supportsSearch) {
            return !this.state.selectedSearchResultKey;
        }

        const claims = this.state.identity.Claims;
        const claimsValues = Object.keys(claims)
            .map((key, i) => ({
                identifying: claims[key].IsIdentifyingClaim,
                value: claims[key].Value })
            );

        return !claimsValues.some(c => c.identifying && !!c.value);
    }

    renderControlsForClaim() {
        const claims = this.state.identity.Claims;
        return Object.keys(claims).map((key, i) => <div key={i}>
                <Text
                    value={claims[key].Value}
                    onChange={v => this.setClaim(key, v)}
                    label={this.claimLabel(key)}
                    validate={claims[key].IsIdentifyingClaim && required(`Please enter ${this.claimDescription(key)}`)}/>
            </div>
        );
    }

    setClaim = (key: string, value: string) => {
        this.setState(state => {
            const Claims = {
                ...state.identity.Claims,
                [key]: {
                    ...state.identity.Claims[key],
                    Value: value
                }
            };
            return {identity: {...state.identity, Claims}};
        });
    }

    renderSearch() {
        return <div>
            <h4>Search</h4>
            <div className={styles.inlineSearch}>
                <Text
                    value={this.state.query}
                    onChange={(query) => this.setState({query})}
                    label={"Search"}/>

                <ActionButton
                    onClick={() => this.search()}
                    label="Search"
                    busyLabel="Searching..."
                    disabled={this.state.busy} />
            </div>

            {this.state.searchResults !== null &&
                (this.state.searchResults.length === 0
                ? <Callout title="No results were found" type={CalloutType.Information}>
                    Enter the start of a group name. For example, to find "Domain Users", type "Domain".
                </Callout>
                : <IdentityResults
                    items={this.state.searchResults}
                    onRow={this.buildSearchResultRow}
                    onRowRedirectUrl={() => null}
            />)}
        </div>;
    }

    buildSearchResultRow = (identity: ClaimsBasedIdentity) => {
        const claims = identity.Claims;
        const entries = Object.keys(claims).map((key, i) => {
            const keyAndValue = `${this.claimLabel(key)}: ${claims[key].Value}`;
            return claims[key].Value && // only show the ones with values
                <div key={i} onClick={() => this.setState({ selectedSearchResultKey: identity.Id })}>
                {claims[key].IsIdentifyingClaim
                ? <ListTitle>{keyAndValue}</ListTitle>
                : <span>{keyAndValue}</span>}
            </div>;
        });

        return <div className={styles.container}>
            <div className={styles.select}>
                <RadioButtonGroup
                    value={identity.Id === this.state.selectedSearchResultKey}
                    onChange={null}>
                    <RadioButton value={true} />
                </RadioButtonGroup>
            </div>
            <div className={styles.claimContent}>
                {entries}
            </div>
        </div>;
    }

    createIdentity() {
        if (!this.props.selectedProvider) {
            return null;
        }

        const identity = {
            // Id just to help in UI
            Id: IdHelper.newId(),
            // this structure to support saving
            IdentityProviderName: this.props.selectedProvider.IdentityProviderName,
            Claims: {} as any
        };

        this.props.selectedProvider.ClaimDescriptors.forEach((d: any) =>
            identity.Claims[d.Type] = {
                Value: "",
                IsIdentifyingClaim: d.IsIdentifyingClaim
            }
        );

        return identity;
    }

    supportsSearch() {
        return this.props.selectedProvider && this.props.selectedProvider.Links &&
            some(this.props.selectedProvider.Links, (v, key) => key === "UserSearch");
    }

    async search() {
        await this.doBusyTask(async () => {

            if (!this.props.selectedProvider.Links) {
                this.setState({ searchResults: [] });
                return;
            }

            const uri = find(this.props.selectedProvider.Links, (v, key) => key === "UserSearch");

            const partialName = this.state.query;
            const result = await repository.ExternalUsers.searchProvider(uri, partialName);

            this.setState({
                // add some Ids to help with selecting it for return from this popup
                searchResults: result.Identities.map((x: any) => ({...x, Id: IdHelper.newId()})) || [],
            });
        });
    }

    // if the claim object can just be built with these properties via createIdentity then they won't be needed
    claimLabel(key: string) {
        return this.findProviderByKey(key).Label;
    }

    claimDescription(key: string) {
        return this.findProviderByKey(key).Description;
    }

    findProviderByKey(key: string) {
        return this.props.selectedProvider && this.props.selectedProvider.ClaimDescriptors
            && this.props.selectedProvider.ClaimDescriptors.find((d: any) => d.Type === key);
    }
}

export {AddIdentityDialog};
