import {SensitiveValue} from "client/resources/propertyValueResource";
import * as React from "react";
import {
    ExpandableFormSection,
    Summary,
    Text,
    Sensitive,
    Note,
    RadioButtonGroup, SummaryNode, RadioButton
} from "components/form";

export enum AuthType {
    Anon,
    Basic,
    Token
}

interface FeedCredentialsState {
    authType: AuthType;
}

interface FeedCredentialsProps {
    isNew: boolean;
    model: {username: string, password: SensitiveValue};
    notes?: JSX.Element;
    onChanged(model: {username: string, password: SensitiveValue}): void;
}

export default class AdvancedFeedCredentialsFormSection extends React.Component<FeedCredentialsProps, FeedCredentialsState> {
    constructor(props: FeedCredentialsProps) {
        super(props);
        this.state = {
            authType: AuthType.Basic
        };
    }

    componentDidMount() {
        this.setState({authType: this.props.isNew ? AuthType.Basic : this.getAuthType(this.props.model.username, this.props.model.password)});
    }

    render() {
        return <ExpandableFormSection
            errorKey="Credentials"
            title="Credentials"
            summary={this.renderSummary(this.state.authType)}
            help="Leave the following blank if your feed does not require authentication.">
            <RadioButtonGroup value={this.state.authType}
                              onChange={this.handleAuthTypeChange}>
                <RadioButton value={AuthType.Anon} label={"Anonymous Access"}/>
                <RadioButton value={AuthType.Basic} label={"Basic Auth"} isDefault={true}/>
                <RadioButton value={AuthType.Token} label={"Personal Access Token"}/>
            </RadioButtonGroup>
            {this.renderFields(this.state.authType)}
            {this.props.notes && <Note>{this.props.notes}</Note>}
        </ExpandableFormSection>;
    }

    private handleAuthTypeChange = (authType: AuthType) => {
        this.setState({authType}, () => {
            if (this.state.authType === AuthType.Anon) {
                this.props.onChanged({username: null, password: null});
            } else if (this.state.authType === AuthType.Token) {
                this.props.onChanged({...this.props.model, username: null});
            }
        });
    }

    private renderSummary = (authType: AuthType): SummaryNode => {
        if (authType === AuthType.Anon) {
            return Summary.placeholder("Add authentication details if you feed requires authentication");
        }

        if (authType === AuthType.Basic) {
            return Summary.summary(`Credentials have been entered, username is ${this.props.model.username}`);
        }

        return Summary.summary(`Personal Access Token has been entered`);
    }

    private renderFields = (authType: AuthType) => {
        const model = this.props.model;
        if (authType === AuthType.Anon) {
            return null;
        }

        if (authType === AuthType.Token) {
            return <Sensitive
                value={model.password}
                onChange={password => this.props.onChanged({...model, password})}
                label={"Personal Access Token"}/>;
        }

        return <React.Fragment>
            <Text value={model.username}
                  onChange={username => this.props.onChanged({...model, username})}
                  label="Feed Username" />
            <Sensitive
                value={model.password}
                onChange={password => this.props.onChanged({...model, password})}
                label={"Feed Password"}/>
        </React.Fragment>;
    }

    private getAuthType(username: string, password: SensitiveValue) {
        return !!username ?
            AuthType.Basic :
            (password && password.HasValue ?
                AuthType.Token :
                AuthType.Anon);
    }
}