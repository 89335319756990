import * as React from "react";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import ConfigureFeaturesContent from "./ConfigureFeaturesContent";
import ActionProperties from "client/resources/actionProperties";

interface OpenFeatureDialogProps {
    actionType: string;
    properties: ActionProperties;
    saveDone(enabledFeatures: string): void;
}

const OpenFeatureDialog: React.StatelessComponent<OpenFeatureDialogProps> = (props: OpenFeatureDialogProps) => {
    return <OpenDialogButton label="Configure features" wideDialog={true}>
            <ConfigureFeaturesContent
                actionType={props.actionType}
                properties={props.properties}
                saveDone={props.saveDone}/>
        </OpenDialogButton>;
};

OpenFeatureDialog.displayName = "OpenFeatureDialog";

export default OpenFeatureDialog;