import * as React from "react";
import { MetadataTypeCollectionDescriptor, MetadataTypeDescriptor, MetadataTypeCollection, DataContext } from "client/resources/dynamicFormResources";
import { DynamicFormProps } from "./DynamicForm";
import MetadataTypeHelpers from "./MetadataTypeHelpers";

class MetadataTypeValidator {
    types: MetadataTypeCollectionDescriptor[];
    private validations: string[] = [];

    validateType = (types: MetadataTypeCollectionDescriptor[], dataContext: DataContext): string[] => {
        this.validations.length = 0;
        this.types = types;
        this.validateSubType(types[0], dataContext);
        return this.validations;
    }

    private validateSubType = (compositeType: MetadataTypeCollectionDescriptor, dataContext: DataContext) => {
        compositeType.Properties.map((property) => this.validateProperty(property, dataContext));
    }

    private validateProperty = (property: MetadataTypeDescriptor, dataContext: DataContext) => {
        if (MetadataTypeHelpers.isCompositeType(property)) {
            const compositeType = this.types.filter(t => t.Name === property.Type)[0];
            this.validateSubType(compositeType,  dataContext[property.Name]);
        } else {
            if (property.DisplayInfo.Required && MetadataTypeHelpers.isValidatableType(property)) {
                let value: string = dataContext[property.Name];
                if (property.Type === "SensitiveValue") {
                    value = dataContext[property.Name].NewValue;
                }
                if (!value || value.length === 0) {
                    this.validations.push(MetadataTypeHelpers.getRequiredMessage(property));
                }
            }
        }
    }
}

export default MetadataTypeValidator;