import { flatten, memoize } from "lodash";
import {AllVariableMessages} from "areas/variables/VariableMessages/VariableMessages";
import {VariableModel} from "areas/variables/VariablesModel/VariablesModel";
import ReadonlyVariableResource from "areas/variables/ReadonlyVariableResource/ReadonlyVariableResource";
import {VariableValueModel} from "../VariablesModel";
import {DuplicatedVariableName} from "../VariableMessages/VariableMessages";

export interface AllVariableSaveWarnings {
    readonly duplicateVariableNames: ReadonlyArray<DuplicatedVariableName>;
    readonly variableWarnings: ReadonlyArray<VariableSaveWarnings>;
    readonly variableValueMessages: ReadonlyArray<VariableValueSaveWarnings>;
}

export interface VariableSaveWarnings {
    readonly variableNameNonPrintableCharacterWarningMessages: ReadonlyArray<string>;
}

export interface VariableValueSaveWarnings {
    readonly variableValueNonPrintableCharacterWarningMessages: ReadonlyArray<string>;
    readonly variableSubtitutionSyntaxWarningMessage: string;
}

export default function getVariableSaveWarnings(variables: ReadonlyArray<VariableModel>,
                                                messages: AllVariableMessages,
                                                groupHasVariablesThatHaveModifiedName: (variable: VariableModel) => boolean,
                                                variableHasModifiedValue: (v: ReadonlyVariableResource) => boolean): AllVariableSaveWarnings {
    return new AllVariableSaveWarningsImpl(variables, messages, groupHasVariablesThatHaveModifiedName, variableHasModifiedValue);
}

class AllVariableSaveWarningsImpl implements AllVariableSaveWarnings {
    private readonly getDuplicateVariableNames: () => ReadonlyArray<DuplicatedVariableName>;
    private readonly getVariableWarnings: () => ReadonlyArray<VariableSaveWarnings>;
    private readonly getVariableValueMessages: () => ReadonlyArray<VariableValueSaveWarnings>;

    constructor(variables: ReadonlyArray<VariableModel>,
                messages: AllVariableMessages,
                variableHasVariablesThatHaveModifiedName: (variable: VariableModel) => boolean,
                variableHasModifiedValue: (v: VariableValueModel) => boolean) {
        this.getDuplicateVariableNames = memoize(() => messages.duplicateVariableNames);
        this.getVariableWarnings = memoize(() => getGroupWarnings(variables, messages, variableHasVariablesThatHaveModifiedName));
        this.getVariableValueMessages = memoize(() => getVariableMessages(variables, messages, variableHasModifiedValue));
    }

    get duplicateVariableNames() {
        return this.getDuplicateVariableNames();
    }

    get variableWarnings() {
        return this.getVariableWarnings();
    }

    get variableValueMessages() {
        return this.getVariableValueMessages();
    }
}

function getGroupWarnings(variables: ReadonlyArray<VariableModel>,
                          messages: AllVariableMessages,
                          groupHasVariablesThatHaveModifiedName: (g: VariableModel) => boolean): ReadonlyArray<VariableSaveWarnings> {
    return variables
        .map<string[] | null>((g, index) => {
            if (groupHasVariablesThatHaveModifiedName(g)) {
                return [...messages.variableMessages[index].variableNameNonPrintableCharacterWarningMessages];
            } else {
                return null;
            }
        })
        .filter(w => !!w)
        .map(g => ({
            variableNameNonPrintableCharacterWarningMessages: g
        }));
}

interface VariableMessagesWarning {
    valueNonPrintableCharacterWarningMessages: string[];
    variableSubtitutionSyntaxWarningMessage: string | null;
}

function getVariableMessages(variables: ReadonlyArray<VariableModel>,
                             messages: AllVariableMessages,
                             variableHasModifiedValue: (v: VariableValueModel) => boolean): ReadonlyArray<VariableValueSaveWarnings> {
    return flatten<VariableMessagesWarning>(variables.map((variable, variableIndex) => {

        // Draft variable does not have any messages associated with it. We should link messages with variables via Id, not via array index.
        if (variableIndex + 1 > messages.variableMessages.length) {
            return [];
        }

        const valuesMessages = messages.variableMessages[variableIndex].valuesMessages;
        return variable.values.map<VariableMessagesWarning | null>((v, vIndex) => {
            if (variableHasModifiedValue(v)) {
                return {
                    valueNonPrintableCharacterWarningMessages: [...valuesMessages[vIndex].valueNonPrintableCharacterWarningMessages],
                    variableSubtitutionSyntaxWarningMessage: valuesMessages[vIndex].variableSubtitutionSyntaxWarningMessage
                };
            }
            return null;
        }).filter(w => !!w);
    })).map(v => ({
        variableValueNonPrintableCharacterWarningMessages: v.valueNonPrintableCharacterWarningMessages,
        variableSubtitutionSyntaxWarningMessage: v.variableSubtitutionSyntaxWarningMessage
    }));
}