exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_menuIcon__2kpue {\n  font-size: 1.37500rem;\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: #333333;\n}\n.style_menuIcon__2kpue:visited {\n  color: #333333;\n}\n.style_menuIcon__2kpue:hover {\n  opacity: 0.5;\n}\n.style_container__3ABIN {\n  display: flex;\n  flex-direction: column;\n  width: 31.25rem;\n}\n.style_menuContainer__2R1aB {\n  overflow-y: auto;\n  height: 25rem;\n}\n.style_filter__251fd {\n  margin: 0 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"menuIcon": "style_menuIcon__2kpue",
	"container": "style_container__3ABIN",
	"menuContainer": "style_menuContainer__2R1aB",
	"filter": "style_filter__251fd"
};