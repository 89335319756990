import * as React from "react";
import Checkbox from "components/form/Checkbox/Checkbox";
const styles = require("./style.less");

interface AdvancedFilterCheckboxProps {
    label: string;
    value: boolean;
    icon?: React.ReactNode;
    onChange(value: boolean): void;
}

export const AdvancedFilterCheckbox: React.SFC<AdvancedFilterCheckboxProps> = (props: AdvancedFilterCheckboxProps) => {
    return <div className={styles.checkboxContainer}>
        <Checkbox
            className={styles.checkbox}
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            noMargin={true}
        />
        {props.icon && <div className={styles.checkboxIcon}>
            {props.icon}
        </div>}
    </div>;
};