enum ActionKeys {
    FORM_PAPER_LAYOUT_MOUNTED = "FORM_PAPER_LAYOUT_MOUNTED",
    OTHER_ACTION = "__any_other_action_type__"
}

interface IOnSaveClickAction {
    type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED;
    onSaveClick(): Promise<boolean>;
}

interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}

type ActionTypes = IOnSaveClickAction | IOtherAction;

export function createFormPaperLayoutMountedAction(onSaveClick: () => Promise<boolean>): IOnSaveClickAction {
    return { type: ActionKeys.FORM_PAPER_LAYOUT_MOUNTED, onSaveClick };
}

export interface FormPaperLayoutReducerState {
    onSaveClick(): Promise<boolean>;
}

const formPaperLayoutReducer = (state: FormPaperLayoutReducerState = { onSaveClick: null }, action: ActionTypes): FormPaperLayoutReducerState => {
    switch (action.type) {
        case ActionKeys.FORM_PAPER_LAYOUT_MOUNTED:
            return { onSaveClick: action.onSaveClick };
        default:
            return state;
    }
};
export default formPaperLayoutReducer;