import * as React from "react";
import {FeedType, HelmFeedResource} from "client/resources/feedResource";
import feedTypeRegistry, {FeedEditProps} from "./feedTypeRegistry";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";

class HelmFeed extends React.Component<FeedEditProps<HelmFeedResource>> {
    render() {
        return <React.Fragment>
                <BasicFeedCredentialsFormSection model={{username: this.props.feed.Username, password: this.props.feed.Password}}
                                                 onChanged={(model) => this.props.onChange({...this.props.feed, Password: model.password, Username: model.username})}/>
            </React.Fragment>;
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.Helm,
    edit: HelmFeed,
    text: "Helm Feed",
    uriNotes: <span>The uri to your helm repository. To use the standard public helm repository, set the url to <code>https://kubernetes-charts.storage.googleapis.com</code></span>,
    uriDefault: "https://kubernetes-charts.storage.googleapis.com"
});
