import BasicRepository from "./basicRepository";
import Client from "../client";
import {VariableSetResource} from "../resources/variableSetResource";

class VariableRepository extends BasicRepository<VariableSetResource, VariableSetResource> {
    constructor(client: Client) {
        super("Variables", client);
    }
    names(projectId: string, projectEnvironmentsFilter: any): Promise<string[]> {
        return this.client.get(this.client.getLink("VariableNames"), {
            project: projectId,
            projectEnvironmentsFilter: projectEnvironmentsFilter ?
                projectEnvironmentsFilter.join(",") : projectEnvironmentsFilter
        });
    }
    preview(projectId: string, actionId: string, environmentId: string, role: string, machineId: string, channelId: string, tenantId: string): Promise<VariableSetResource> {
        return this.client.get(this.client.getLink("VariablePreview"), {
            project: projectId,
            environment: environmentId,
            channel: channelId,
            tenant: tenantId,
            action: actionId,
            role,
            machine: machineId
        });
    }
    nonPrintableChars(variableSet: any) {
        return this.client.post(this.client.getLink("VariablesNonPrintableChars"), variableSet);
    }
}

export default VariableRepository;