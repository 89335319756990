exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_linkableItem__2Jc-m {\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"linkableItem": "style_linkableItem__2Jc-m"
};