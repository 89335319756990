import * as React from "react";
import {repository} from "clientInstance";
import {RouteComponentProps} from "react-router";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {ResourceCollection, TaskResource, DeploymentTargetResource} from "client/resources";
import List from "components/List";
import TaskDetails from "components/TaskDetails/TaskDetails";
import routeLinks from "../../../../routeLinks";

class DeploymentTasksList extends List<TaskResource<any>> {}

type MachineDeploymentsProps = RouteComponentProps<MachineDeploymentsParams>;

interface MachineDeploymentsParams {
    machineId: string;
}

interface MachineDeploymentsState extends DataBaseComponentState {
    machineId: string;
    machine: DeploymentTargetResource;
    tasksResponse: ResourceCollection<TaskResource<any>>;
}

export default class MachineDeploymentsLayout extends DataBaseComponent<MachineDeploymentsProps, MachineDeploymentsState> {
    constructor(props: MachineDeploymentsProps) {
        super(props);
        this.state = {
            tasksResponse: null,
            machineId: this.props.match && this.props.match.params ? this.props.match.params.machineId : null,
            machine: null,
        };
    }

    async componentDidMount() {
        this.doBusyTask(async () => {
            const machine = await repository.Machines.get(this.state.machineId);
            const tasksResponse = await repository.Machines.getTasks(machine, {skip: 0});
            this.setState({
                machine,
                tasksResponse,
            });
        });
    }

    render() {
        return <PaperLayout title="Deployments"
            busy={this.state.busy}
            errors={this.state.errors}>
            {this.state.tasksResponse && <DeploymentTasksList
             initialData={this.state.tasksResponse}
             onRow={(item: any) => this.buildDeploymentTaskRow(item)}
             onRowRedirectUrl={(task: TaskResource<any>) => routeLinks.task(task).root}
             showPagingInNumberedStyle={true}
             onPageSelected={async (skip: number, p: number) => {
                 await this.loadDeploymentTasks(skip);
             }}
             />}
        </PaperLayout>;
    }

    private async loadDeploymentTasks(skip: number) {
        const tasksResponse = await repository.Machines.getTasks(this.state.machine, { skip });
        this.setState({tasksResponse});
    }

    private buildDeploymentTaskRow(task: TaskResource<any>) {
        return (
            <TaskDetails task={task} stripTopBottomPadding={true} />
        );
    }
}
