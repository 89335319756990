import BasicRepository, {AllArgs, ListArgs} from "./basicRepository";
import Client from "../client";
import {LibraryVariableSetResource, VariableSetContentType} from "../resources/libraryVariableSetResource";

type LibraryVariableAllArgs = {
    contentType?: VariableSetContentType;
} & AllArgs;

type LibraryVariableListArgs = {
    contentType?: VariableSetContentType;
} & ListArgs;

class LibraryVariableRepository extends BasicRepository<LibraryVariableSetResource, LibraryVariableSetResource, LibraryVariableListArgs, LibraryVariableAllArgs> {
    constructor(client: Client) {
        super("LibraryVariables", client);
    }
}

export default LibraryVariableRepository;