import * as React from "react";
import {
    WorkerPoolResource,
    WorkerPoolsSummaryResource,
    MachineModelHealthStatus,
    TenantResource,
    Permission,
    CommunicationStyle,
} from "client/resources";
import { repository } from "clientInstance";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { NavigationButton } from "components/Button";
import { RouteComponentProps } from "react-router";
import InfrastructureLayout from "../../InfrastructureLayout";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import OverflowMenu from "components/Menu/OverflowMenu";
import { ActionButtonType } from "components/Button";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import WorkerPoolsSorter from "./WorkerPoolsSorter";
import WorkerPoolSummarySection from "./WorkerPoolSummarySection";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import * as tenantTagsets from "components/tenantTagsets";
import { TagIndex } from "components/tenantTagsets";
import AddWorkerPoolsDialog from "./AddWorkerPoolsDialog";
import AdvancedFilterLayout, { FilterSection, AdvancedFilterCheckbox } from "components/AdvancedFilterLayout";
import WorkerPoolSummaryMachineFilter from "./WorkerPoolSummaryMachineFilter";
import { isEqual } from "lodash";
import {
    RoleMultiSelect,
    WorkerPoolMultiSelect,
    MachineModelHealthStatusMultiSelect,
    EndpointCommunicationStyleMultiSelect,
} from "components/MultiSelect";
import { connect } from "react-redux";
import { machineActions } from "../../../reducers/machines";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
import { Section } from "components/Section/Section";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import ActionList from "components/ActionList/ActionList";
import routeLinks from "routeLinks";
import { WorkerPoolsSummaryArgs } from "client/repositories/workerpoolsRepository";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import { AdvancedFilterTextInput } from "components/AdvancedFilterLayout/Text/AdvancedFilterTextInput";
import { QueryStringFilters } from "components/QueryStringFilters/QueryStringFilters";
import { WorkerPoolSummaryMachineQuery } from "./WorkerPoolSummaryMachineQuery";
import InternalRedirect from "components/Navigation/InternalRedirect";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note } from "components/form";
import { NoResults } from "components/NoResults/NoResults";
import EndpointsHelper from "utils/EndpointsHelper/EndpointsHelper";
import MachineFilter from "../../MachinesLayout/MachineFilter";
import ConfirmTentacleUpgradePanel from "../../MachinesLayout/ConfirmTentacleUpgradePanel";

export interface WorkerPoolsRouteParams {
    ids: string;
}

interface WorkerPoolLayoutProps extends RouteComponentProps<WorkerPoolsRouteParams> {
    onClearMachine?(): void;
}

interface WorkerPoolLayoutState extends DataBaseComponentState {
    workerPoolsSummary: WorkerPoolsSummaryResource;
    filter: WorkerPoolSummaryMachineFilter;
    queryFilter?: WorkerPoolSummaryMachineFilter;
    isSearching?: boolean;
    workerPools: WorkerPoolResource[];
    machineRoles: string[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
    hasDeploymentTargets: boolean;
    redirectToTaskId?: string;
    redirectToWorkerPoolId?: string;
    currentPageIndex: number;
}

class FilterLayout extends AdvancedFilterLayout<WorkerPoolSummaryMachineFilter> { }

const WorkerPoolQueryStringFilters = QueryStringFilters.For<WorkerPoolSummaryMachineFilter, WorkerPoolSummaryMachineQuery>();

const PageSize = 20;

class WorkerPoolsLayoutInternal extends DataBaseComponent<WorkerPoolLayoutProps, WorkerPoolLayoutState> {
    private machineHealthStatuses = MachineHealthStatusHelper.getMachineModelHealthStatusResources();
    private communicationStyles = EndpointsHelper.getCommunicationStyleResources();
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: WorkerPoolLayoutProps) {
        super(props);

        this.state = ({
            workerPoolsSummary: null,
            filter: createEmptyFilter(),
            isSearching: false,
            machineRoles: null,
            workerPools: null,
            tenants: null,
            tagIndex: null,
            hasDeploymentTargets: false,
            currentPageIndex: 0,
        });
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadLookupData());
        await this.refreshWorkerPoolSummaryData();

        // Clear currentMachine (to avoid seeing old machine data when switching machines).
        this.props.onClearMachine();
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        if (this.state.redirectToWorkerPoolId) {
            return <InternalRedirect to={routeLinks.infrastructure.workerPool(this.state.redirectToWorkerPoolId)} push={true} />;
        }

        const actions = [
            this.state.workerPools && this.state.workerPools.length > 0 &&
            <PermissionCheck permission={Permission.WorkerEdit} >
                <NavigationButton href={routeLinks.infrastructure.workerMachines.new()} label="Add worker" />
            </PermissionCheck>,
            <PermissionCheck permission={Permission.WorkerEdit} >
                <OpenDialogButton label="Add Worker Pool" type={ActionButtonType.Primary}>
                    <AddWorkerPoolsDialog
                        saveDone={async workerPool => {
                            this.setState({ redirectToWorkerPoolId: workerPool.Id });
                        }} />
                </OpenDialogButton>
            </PermissionCheck>,
            this.state.workerPools && this.state.workerPools.length > 0 && <PermissionCheck permission={Permission.WorkerView} >
                <OverflowMenu menuItems={[
                    OverflowMenu.dialogItem("Reorder", <WorkerPoolsSorter saveDone={this.refreshWorkerPoolSummaryData} />)
                ]} />
            </PermissionCheck>
        ];

        const actionSection = <ActionList actions={actions} />;

        let workerPoolExpanders: React.ReactNode = null;
        const workerPoolSummaries = this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries;
        if (workerPoolSummaries) {
            const start = this.state.currentPageIndex === 0 ? 0 : this.state.currentPageIndex * PageSize;
            const end = start + PageSize;
            workerPoolExpanders = workerPoolSummaries
                .slice(start, end)
                .map((workerPoolsSummary) => {
                    // Do not confuse partialName and machinePartialName filters here or it breaks the name filtering.
                    const machineFilter: MachineFilter = {
                        partialName: this.state.filter.machinePartialName,
                        environmentIds: null,
                        workerPoolIds: this.state.filter.workerPoolIds,
                        roles: this.state.filter.roles,
                        isDisabled: this.state.filter.isDisabled,
                        healthStatuses: this.state.filter.healthStatuses,
                        commStyles: this.state.filter.commStyles,
                        tenantIds: null,
                        tenantTags: null,
                    };
                    return <WorkerPoolSummarySection
                        key={workerPoolsSummary.WorkerPool.Id}
                        workerPoolSummary={workerPoolsSummary}
                        filter={machineFilter}
                        tenants={this.state.tenants}
                        tagIndex={this.state.tagIndex} />;
                });
        }
        if (this.state.workerPoolsSummary && this.state.workerPoolsSummary.WorkerPoolSummaries.length === 0) {
            workerPoolExpanders = <Section><NoResults /></Section>;
        }

        const filterSections: FilterSection[] = [{
            render: <div>
                <AdvancedFilterCheckbox
                    label="Disabled only"
                    value={this.state.filter.isDisabled}
                    onChange={x => {
                        this.setFilterState({ isDisabled: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <AdvancedFilterCheckbox
                    label="Hide empty worker pools"
                    value={this.state.filter.hideEmptyWorkerPools}
                    onChange={x => {
                        this.setFilterState({ hideEmptyWorkerPools: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <AdvancedFilterTextInput
                    fieldName={"worker"}
                    value={this.state.filter.machinePartialName}
                    onChange={x => {
                        this.setFilterState({ machinePartialName: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <WorkerPoolMultiSelect
                    items={this.state.workerPools ? this.state.workerPools : []}
                    value={this.state.filter.workerPoolIds}
                    onChange={x => {
                        this.setFilterState({ workerPoolIds: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <RoleMultiSelect
                    items={this.state.machineRoles ? this.state.machineRoles : []}
                    value={this.state.filter.roles}
                    onChange={x => {
                        this.setFilterState({ roles: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <MachineModelHealthStatusMultiSelect
                    items={this.machineHealthStatuses}
                    value={this.state.filter.healthStatuses}
                    onChange={x => {
                        this.setFilterState({ healthStatuses: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
                <EndpointCommunicationStyleMultiSelect
                    items={this.communicationStyles}
                    value={this.state.filter.commStyles}
                    onChange={x => {
                        this.setFilterState({ commStyles: x }, () => {
                            this.onFilterChange();
                        });
                    }}
                />
            </div>
        }];

        const tentacleUpgradesRequiredWarning = workerPoolSummaries &&
            workerPoolSummaries.length > 0 &&
            workerPoolSummaries.find(x => x.TentacleUpgradesRequired === true) &&
            <PermissionCheck permission={Permission.WorkerEdit} >
                <ConfirmTentacleUpgradePanel doBusyTask={this.doBusyTask}
                    calloutDescriptionElement={<p>One or more workers are running old versions of the Tentacle agent and can be upgraded.</p>}
                    onTentacleUpgradeComplete={taskId => {
                        this.setState({ redirectToTaskId: taskId });
                    }}
                />
            </PermissionCheck>;

        return <InfrastructureLayout {...this.props}>
            <WorkerPoolQueryStringFilters
                filter={this.state.filter}
                getQuery={this.queryFromFilter}
                getFilter={this.getFilter}
                onFilterChange={filter => this.setState({ filter, queryFilter: filter }, () => this.onFilterChange())} />
            <PaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title="Worker pools"
                sectionControl={actionSection}>
                <Section>
                    <Note>
                        Worker pools allow you to organize workers into groups. One pool might be in a particular network security zone. Another pool might have a specific set of tools installed.<br />
                        <ExternalLink href="WorkerPools">Learn more</ExternalLink>.
                    </Note>
                </Section>
                {workerPoolSummaries && <div>
                    {tentacleUpgradesRequiredWarning}
                    <FilterLayout filterSections={filterSections}
                        filter={this.state.filter}
                        queryFilter={this.state.queryFilter}
                        defaultFilter={createEmptyFilter()}
                        initiallyShowFilter={this.isFiltering()}
                        additionalHeaderFilters={[<FilterSearchBox
                            hintText={"Search worker pools..."}
                            value={this.state.filter.partialName}
                            onChange={x => {
                                this.setFilterState({ partialName: x }, () => {
                                    this.onFilterChange();
                                });
                            }}
                            autoFocus={true}
                        />]}
                        onFilterReset={(filter: WorkerPoolSummaryMachineFilter) => {
                            this.setState({ filter }, () => {
                                this.onFilterChange();
                                const location = { ...this.props.history, search: null as any };
                                this.props.history.replace(location);
                            });
                        }}
                        renderContent={() => <div>
                            <ExpansionButtons />
                            {workerPoolExpanders}
                        </div>}
                    />
                </div>}
            </PaperLayout>
        </InfrastructureLayout>;
    }

    private setFilterState<K extends keyof WorkerPoolSummaryMachineFilter>(state: Pick<WorkerPoolSummaryMachineFilter, K>, callback?: () => void) {
        this.setState(prev => ({
            filter: { ...prev.filter as object, ...state as object }
        }), callback);
    }

    private isFiltering() {
        return !isEqual(this.state.filter, createEmptyFilter());
    }

    private onFilterChange() {
        this.setState({ isSearching: true }, async () => {
            await this.refreshWorkerPoolSummaryData();
            this.setState({ isSearching: false });
        });
    }

    private queryFromFilter = (filter: WorkerPoolSummaryMachineFilter): WorkerPoolSummaryMachineQuery => {
        const query: WorkerPoolSummaryMachineQuery = {
            partialName: filter.partialName,
            machinePartialName: filter.machinePartialName,
            workerPoolIds: filter.workerPoolIds,
            healthStatuses: filter.healthStatuses,
            commStyles: filter.commStyles,
            roles: filter.roles,
            isDisabled: filter.isDisabled ? "true" : undefined,
            hideEmptyWorkerPools: filter.hideEmptyWorkerPools ? "true" : undefined
        };

        return query;
    }

    private getFilter = (query: WorkerPoolSummaryMachineQuery): WorkerPoolSummaryMachineFilter => {
        const filter: WorkerPoolSummaryMachineFilter = {
            ...createEmptyFilter(),
            partialName: query.partialName,
            machinePartialName: query.machinePartialName,
            workerPoolIds: arrayValueFromQueryString(query.workerPoolIds) || [],
            healthStatuses: arrayValueFromQueryString(query.healthStatuses) as MachineModelHealthStatus[] || [],
            commStyles: arrayValueFromQueryString(query.commStyles) as CommunicationStyle[] || [],
            roles: arrayValueFromQueryString(query.roles) || [],
            isDisabled: query.isDisabled === "true",
            hideEmptyWorkerPools: query.hideEmptyWorkerPools === "true",
        };

        return filter;
    }

    private refreshWorkerPoolSummaryData = async () => {
        await this.doBusyTask(() => this.loadWorkerPoolSummaries());
    }

    private async loadWorkerPoolSummaries() {
        const filter = this.state.filter;
        const args: Partial<WorkerPoolsSummaryArgs> = {};
        if (filter.isDisabled) {
            args.isDisabled = true;
        }
        if (filter.workerPoolIds && filter.workerPoolIds.length) {
            args.ids = filter.workerPoolIds.join(",");
        }
        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }
        if (filter.commStyles && filter.commStyles.length) {
            args.commStyles = filter.commStyles.join(",");
        }
        if (filter.healthStatuses && filter.healthStatuses.length) {
            args.healthStatuses = filter.healthStatuses.join(",");
        }
        if (filter.partialName && filter.partialName !== "") {
            args.partialName = filter.partialName;
        }
        if (filter.machinePartialName && filter.machinePartialName !== "") {
            args.machinePartialName = filter.machinePartialName;
        }
        if (filter.hideEmptyWorkerPools) {
            args.hideEmptyWorkerPools = true;
        }
        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.WorkerPools.summary(args), (response) => {
            this.setState({
                workerPoolsSummary: response as WorkerPoolsSummaryResource,
            });
        });
    }

    private async loadLookupData() {
        const workerPoolsPromise = repository.WorkerPools.all();
        const machineRolesPromise = repository.MachineRoles.all();

        this.setState({
            workerPools: await workerPoolsPromise,
            machineRoles: await machineRolesPromise,
            tagIndex: await tenantTagsets.getTagIndex(),
            hasDeploymentTargets: (await repository.Machines.list({ take: 0 })).TotalResults > 0,
        });
    }
}

function createEmptyFilter(): WorkerPoolSummaryMachineFilter {
    return {
        partialName: undefined,
        workerPoolIds: [],
        machinePartialName: undefined,
        roles: [],
        isDisabled: false,
        healthStatuses: [],
        commStyles: [],
        hideEmptyWorkerPools: false,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onClearMachine: () => {
            dispatch(machineActions.machineCleared());
        }
    };
};

const WorkerPoolsLayout = connect<{}, {}, WorkerPoolLayoutProps>(
    null,
    mapDispatchToProps
)(WorkerPoolsLayoutInternal);

export default WorkerPoolsLayout;
