exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_controlCell__1sAGd {\n  height: 100%;\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n}\n.style_multiVariableCell__3hnIQ {\n  margin-right: 1rem;\n}\n.style_lastColumn__1xKbR {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n.style_scopeCell__19SQ_ {\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n.style_fullSizeCell__2gwQl {\n  height: 100%;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"controlCell": "style_controlCell__1sAGd",
	"multiVariableCell": "style_multiVariableCell__3hnIQ",
	"lastColumn": "style_lastColumn__1xKbR",
	"scopeCell": "style_scopeCell__19SQ_",
	"fullSizeCell": "style_fullSizeCell__2gwQl"
};