import * as React from "react";
import {ProjectResource} from "client/resources";
import Markdown from "components/Markdown";
import Card from "components/Card/Card";
import Logo from "components/Logo/Logo";
import routeLinks from "routeLinks";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";

interface ProjectCardProps {
    project: ProjectResource;
}

const ProjectCard: React.StatelessComponent<ProjectCardProps> = props => {
    const project = props.project;
    return <Card logo={<Logo url={props.project.Links.Logo} isDisabled={project.IsDisabled} />}
        link={<InternalLink to={{pathname: routeLinks.project(project).root, state: {project}}}/>}
        header={project.Name}
        content={<Markdown markup={project.Description}/>}
        includeContentFade={true}
        isDisabled={project.IsDisabled}
    />;
};

ProjectCard.displayName = "ProjectCard";

export default ProjectCard;