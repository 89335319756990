exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProjectedTeamsList_root__26g7X {\n  flex: 1 1 auto;\n  display: flex;\n  align-items: center;\n}\n.ProjectedTeamsList_detail__1mT0m {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  padding-left: 1rem;\n}\n.ProjectedTeamsList_icon__2Ib53 {\n  width: 32px;\n  height: 32px;\n  fill: #333333 !important;\n}\n.ProjectedTeamsList_externalGroups__3SYB8 {\n  color: #333333;\n}\n", ""]);

// exports
exports.locals = {
	"root": "ProjectedTeamsList_root__26g7X",
	"detail": "ProjectedTeamsList_detail__1mT0m",
	"icon": "ProjectedTeamsList_icon__2Ib53",
	"externalGroups": "ProjectedTeamsList_externalGroups__3SYB8"
};