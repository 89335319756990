exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1vPog {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n.style_container__1vPog > .style_wide__3iHkR {\n  flex-grow: 1;\n}\n.style_container__1vPog > .style_buttons__3RezA {\n  padding-top: 1rem;\n}\n.style_container__1vPog > :nth-child(2) {\n  margin-left: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1vPog",
	"wide": "style_wide__3iHkR",
	"buttons": "style_buttons__3RezA"
};