import ToolTip from "../ToolTip";
import ExternalLink from "../Navigation/ExternalLink/ExternalLink";
import * as React from "react";
const styles = require("./style.less");
import { repository } from "clientInstance";
import CloseButton from "components/Button/CloseButton";
import { connect } from "react-redux";
import GlobalState from "globalState";
import IPageWrapper from "utils/pageId";
import { pageConfigSelector } from "components/PageLayout/reducers/pageLayoutArea";

interface EAPBarState {
    version: string;
    isEarlyAccessProgram: boolean;
    isHidden: boolean;
}

interface ConnectedProps {
    page?: IPageWrapper;
}

interface EAPPropsInternal {
    onCloseClicked(): void;
}

type EAPProps = EAPPropsInternal & ConnectedProps;

class EAPBarInternal extends React.Component<EAPProps, EAPBarState> {

    constructor(props: EAPProps) {
        super(props);

        this.state = {
            isEarlyAccessProgram: false,
            isHidden: false,
            version: ""
        };
    }

    componentDidMount() {
        const serverInfo = repository.getServerInformation();

        this.setState({
            isEarlyAccessProgram: serverInfo.isEarlyAccessProgram || false,
            version: serverInfo.version
        });
    }

    onClose() {
        this.setState({ isHidden: true });
        this.props.onCloseClicked();
    }

    render() {
        if (!this.state.isEarlyAccessProgram || this.state.isHidden) {
            return null;
        }

        return <div className={styles.eapBanner}>
            <ToolTip content={<div>
                <p>Due to the unstable nature of EAP releases we do not recommend using them in production, or upgrading them to/from a production instance</p>
                <p>Current page: {this.props.page && this.props.page.Name} <i>({this.props.page && this.props.page.Id})</i></p>
            </div>}>
                <ExternalLink href="WhatIsEAP" showIcon={false}>
                    EAP Release - v{this.state.version} {this.props.page && `- ${this.props.page.Id}`}
                </ExternalLink>
            </ToolTip>
            <div className={styles.closeEAPBanner}>
                <CloseButton onClose={() => this.onClose()} />
            </div>
        </div>;
    }
}

const mapStateToProps = (state: GlobalState) => {
    return pageConfigSelector(state);
};

const EAPBar = connect<{}, {}, EAPProps>(
    mapStateToProps,
    null
)(EAPBarInternal);

export default EAPBar;