import * as React from "react";
import {SFC} from "react";
import ArtifactResource from "client/resources/artifactResource";
import {repository} from "clientInstance";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

const ArtifactLink: SFC<{artifact: ArtifactResource}> = props =>
        <ExternalLink href={repository.resolve(props.artifact.Links["Content"])}
                      showIcon={false}
                      key={props.artifact.Id}
                      openInSelf={true}>{props.artifact.Filename}</ExternalLink>;

ArtifactLink.displayName = "ArtifactLink";

export default ArtifactLink;