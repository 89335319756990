exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_regardingAnyContainer__2TXfX {\n  padding: 0.5rem;\n  border: 0.0625rem solid #e0e0e0;\n  border-radius: 0.125rem;\n}\n", ""]);

// exports
exports.locals = {
	"regardingAnyContainer": "style_regardingAnyContainer__2TXfX"
};