exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_textContainer__qxe7b {\n  position: relative;\n  display: flex;\n  align-items: flex-end;\n}\n.style_iconContainer__1baDV {\n  position: absolute;\n  right: 0;\n  cursor: pointer;\n  margin: 0.5625rem;\n  top: 18px;\n}\n.style_autoCompleteContainer__3JypX {\n  width: 100%;\n}\n.style_popover__smxSz > div {\n  overflow-x: hidden;\n}\n.style_popoverContent__3lIcb {\n  overflow-x: hidden;\n}\n.style_popoverInfoText__2oEe8 {\n  margin: 1rem;\n}\n.style_warning__3Z_HM {\n  margin: 0.5rem 1rem;\n  color: #fb8c00;\n  font-size: 0.75rem;\n}\n.style_buttons__30ybd {\n  padding-bottom: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"textContainer": "style_textContainer__qxe7b",
	"iconContainer": "style_iconContainer__1baDV",
	"autoCompleteContainer": "style_autoCompleteContainer__3JypX",
	"popover": "style_popover__smxSz",
	"popoverContent": "style_popoverContent__3lIcb",
	"popoverInfoText": "style_popoverInfoText__2oEe8",
	"warning": "style_warning__3Z_HM",
	"buttons": "style_buttons__30ybd"
};