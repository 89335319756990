exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_icon__CiZZq {\n  align-items: center;\n}\n.style_icon__CiZZq:hover {\n  opacity: 1;\n}\n.style_editIcon__vj64h {\n  align-items: center;\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n.style_editIcon__vj64h:hover {\n  opacity: 1;\n}\n.style_deletedIcon__2eB3M {\n  align-items: center;\n  color: #db4437;\n}\n.style_deletedIcon__2eB3M:hover {\n  opacity: 1;\n}\n.style_newIcon__VoKEA {\n  align-items: center;\n  color: #48B350;\n}\n.style_newIcon__VoKEA:hover {\n  opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"icon": "style_icon__CiZZq",
	"editIcon": "style_editIcon__vj64h",
	"deletedIcon": "style_deletedIcon__2eB3M",
	"newIcon": "style_newIcon__VoKEA"
};