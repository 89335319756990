import Client from "../client";
import {ActionTemplateResource} from "client/resources/actionTemplateResource";
import BasicRepository from "client/repositories/basicRepository";
import {CommunityActionTemplateResource} from "client/resources/communityActionTemplateResource";

class CommunityActionTemplateRepository  {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    get(id: string) {
        const allArgs = {...{}, ...{id}};
        return this.client.get<CommunityActionTemplateResource>(this.client.getLink("CommunityActionTemplates"), allArgs);
    }
    install(communityActionTemplate: CommunityActionTemplateResource) {
        return this.client.post<ActionTemplateResource>(communityActionTemplate.Links["Installation"]);
    }
    updateInstallation(communityActionTemplate: CommunityActionTemplateResource) {
        return this.client.put<ActionTemplateResource>(communityActionTemplate.Links["Installation"]);
    }
}

export default CommunityActionTemplateRepository;