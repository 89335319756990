exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .PackagingCard_hide-sm__64SOl {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .PackagingCard_visible-sm__1qtEn {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .PackagingCard_hide-md__3VTMD {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .PackagingCard_visible-md__3KDPu {\n    display: none !important;\n  }\n}\n.PackagingCard_card__3P3g5 {\n  flex: 1;\n  min-width: 16.25rem;\n  max-width: 18.75rem;\n}\n@media (max-width: 37.4rem) {\n  .PackagingCard_card__3P3g5 {\n    max-width: 100%;\n  }\n}\n.PackagingCard_card__3P3g5 .PackagingCard_description__3aDdz {\n  margin-top: 0.5rem;\n}\n.PackagingCard_card__3P3g5 div:nth-child(1) {\n  width: 100%;\n  text-align: center;\n}\n@media (max-width: 37.4rem) {\n  .PackagingCard_card__3P3g5 div:nth-child(1) {\n    width: auto;\n  }\n}\n.PackagingCard_card__3P3g5 div:nth-child(1) .PackagingCard_logo__iRkJv {\n  width: auto;\n  height: 5rem;\n  margin: 0 auto;\n}\n.PackagingCard_card__3P3g5 div:nth-child(1) .PackagingCard_logo__iRkJv img {\n  height: 5rem;\n}\n.PackagingCard_card__3P3g5 div:nth-child(1) div {\n  width: auto;\n}\n.PackagingCard_onHoverActions__1D3n0 {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "PackagingCard_hide-sm__64SOl",
	"hideSm": "PackagingCard_hide-sm__64SOl",
	"visible-sm": "PackagingCard_visible-sm__1qtEn",
	"visibleSm": "PackagingCard_visible-sm__1qtEn",
	"hide-md": "PackagingCard_hide-md__3VTMD",
	"hideMd": "PackagingCard_hide-md__3VTMD",
	"visible-md": "PackagingCard_visible-md__3KDPu",
	"visibleMd": "PackagingCard_visible-md__3KDPu",
	"card": "PackagingCard_card__3P3g5",
	"description": "PackagingCard_description__3aDdz",
	"logo": "PackagingCard_logo__iRkJv",
	"onHoverActions": "PackagingCard_onHoverActions__1D3n0"
};