import { NamedResource, TenantedDeploymentMode, ActionTemplateParameterResource } from "client/resources";
import {DeploymentActionPackageResource} from "./deploymentActionPackageResource";

export interface ProjectResource extends NamedResource {
    VariableSetId: string;
    DeploymentProcessId: string;
    DiscreteChannelRelease: boolean;
    IncludedLibraryVariableSetIds: string[];
    DefaultToSkipIfAlreadyInstalled: boolean;
    TenantedDeploymentMode: TenantedDeploymentMode;
    VersioningStrategy: VersioningStrategy;
    ReleaseCreationStrategy: ReleaseCreationStrategy;
    Templates: ActionTemplateParameterResource[];
    AutoDeployReleaseOverrides: any[];
    Slug: string;
    Description: string;
    IsDisabled: boolean;
    ProjectGroupId: string;
    LifecycleId: string;
    AutoCreateRelease: boolean;
    ProjectConnectivityPolicy: ProjectConnectivityPolicy;
    DefaultGuidedFailureMode: GuidedFailureMode;
    logo?: string;
    ClonedFromProjectId: string;
    SpaceId?: string;
}

export interface VersioningStrategy {
    Template: string;
    DonorPackage?: DeploymentActionPackageResource;
}

export interface ReleaseCreationStrategy {
    ReleaseCreationPackage: DeploymentActionPackageResource;
    ChannelId?: string;
}

export interface ProjectConnectivityPolicy {
    SkipMachineBehavior: string;
    TargetRoles: string[];
    AllowDeploymentsToNoTargets: boolean;
    ExcludeUnhealthyTargets: boolean;
}

export enum GuidedFailureMode {
    EnvironmentDefault = "EnvironmentDefault",
    Off = "Off",
    On = "On"
}

export default ProjectResource;