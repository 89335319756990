import * as React from "react";
import {DragLayer, XYCoord} from "react-dnd";
import {Identifier} from "dnd-core";
import {CSSProperties} from "react";
import {
    getBoundedDeltaX,
    ResizeHandleDragItem,
    resizeHandleType
} from "components/ScrollTable/DraggableResizeColumnHandle/DraggableResizeColumnHandle";
import ResizeColumnHandle from "components/ScrollTable/ResizeColumnHandle/ResizeColumnHandle";

// All props provided by `DragLayer` and should not be passed in from outside
interface ResizeColumnHandleDragLayerProps {
    item?: ResizeHandleDragItem; // Only one supported item type within this DragDropContext
    itemType?: Identifier;
    currentOffset?: XYCoord;
    initialOffset?: XYCoord;
    isDragging?: boolean;
}

const dragLayerStyles: CSSProperties = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%"
};

class ResizeColumnHandleDragLayerInternal extends React.Component<ResizeColumnHandleDragLayerProps> {
    render() {
        if (!this.props.isDragging || this.props.itemType !== resizeHandleType) {
            return null;
        }

        this.clearSelection();

        const boundedDeltaX = getBoundedDeltaX(this.props.currentOffset.x - this.props.initialOffset.x,
            this.props.item.measurements);

        const itemStyles = {
                transform: `translate(${this.props.initialOffset.x + boundedDeltaX}px, ${this.props.initialOffset.y}px)`, // Restrict movement to x axis
                // This particular elements needs a height set,
                // the item captures the height from the original element for us, so we can make it look the same
                height: `${this.props.item.height}px`
            };

        return <div style={dragLayerStyles}>
            <div style={itemStyles}>
                <ResizeColumnHandle isActive={true}/>
            </div>
        </div>;
    }

    private clearSelection() {
        // react-dnd-touch-backend should be doing a `preventDefault()` no the mousemove event
        // which *should* be prevent the browser from highlighting text as your drag.
        // However, this doesn't seem to work in FF for some reason.
        // As a workaround, we can just remove any selection every time you move the mouse.
        // I couldn't think of a better place to put this code (other than forking react-dnd-touch-backend and putting it there)

        if ((document.body as any).createTextRange) {
            // IE workaround. IE11 may fail with `removeAllRanges` depending on what you have selected
            // with a really obscure error message: "Could not complete the operation due to error 800a025e"
            const range = (document.body as any).createTextRange();
            range.collapse();
            range.select();
        } else {
            // Chrome/Edge/FF
            window.getSelection().removeAllRanges();
        }
    }
}

const ResizeColumnHandleDragLayer = DragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    isDragging: monitor.isDragging()
}))(ResizeColumnHandleDragLayerInternal);
export default ResizeColumnHandleDragLayer;