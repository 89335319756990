import * as React from "react";
import * as ReactDOM from "react-dom";
import { client } from "clientInstance";
import {AuthenticationProviderElement} from "client/authentication";
import Logger from "client/logger";
import {LoginState} from "client/resources";
import extensionManager from "extensionsManager";
const $script = require("scriptjs");

interface AuthProviderProps {
    provider: AuthenticationProviderElement;
    loginState: LoginState;
    shouldAutoSignIn: boolean;
    autoSignInProviderName: string;
    onError(error: any): void;
}

export default class AuthProvider extends React.Component<AuthProviderProps, any> {

    async componentDidMount() {
        const {
            provider,
            loginState,
            shouldAutoSignIn,
            autoSignInProviderName,
            onError,
        } = this.props;

        await Promise.all(this.loadCSS(provider.CSSLinks));

        $script(provider.JavascriptLinks.map(link => {
            Logger.log("Loading JS " + link);
            return client.resolve(link);
        }), provider.Name);

        $script.ready(provider.Name, () => {

            const extension = extensionManager.getExtension(provider.Name, "auth_provider");
            if (!extension) {
                Logger.error(`Could not find authentication ${provider.Name} extension`);
            }
            const authProvider = extension(client, provider, loginState, onError);

            if (!shouldAutoSignIn) {
                const node = ReactDOM.findDOMNode(this.refs.root) as Element;
                node.innerHTML = authProvider.LinkHtml;
                node.addEventListener("click", authProvider.SignIn);
            }

            if (shouldAutoSignIn && provider.Name === autoSignInProviderName) {
                authProvider.SignIn();
            }
        });
    }

    render() {
        return (
            // tslint:disable:jsx-no-string-ref
            <div ref="root"/>
        );
    }

    private loadCSS(urls: string[]) {
        return urls.map(url => {
            Logger.log("Loading CSS " + url);
            return new Promise((resolve, reject) => {
                const linkElement = document.createElement("link");
                linkElement.setAttribute("rel", "stylesheet");
                linkElement.setAttribute("type", "text/css");
                linkElement.setAttribute("href", client.resolve(url));
                document.getElementsByTagName("head")[0].appendChild(linkElement);
                resolve();
            });
        });
    }
}