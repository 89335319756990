import * as React from "react";
import { values } from "lodash";

type InformationalType = string;
export type PackagingType = InformationalType;

export interface DisplayOrder {
    displayOrder: number;
}

export interface CategoryDefinition extends DisplayOrder {
    category: string;
    title: React.ReactNode;
    help?: React.ReactNode;
}

export type CategorizedMachineRegistration = CategorizedPackagingRegistration;

export interface RenderRegistrationCardProps {
    category: CategoryDefinition;
    registration: CategorizedPackagingRegistration;
}

export interface CategorizedPackagingRegistration extends SimplePackagingRegistration {
    categories: CategoryDefinition[];
    renderCard: (props: RenderRegistrationCardProps) => React.ReactElement<any>;
}

export interface SimplePackagingRegistration extends DisplayOrder {
    name: string;
    type: PackagingType;
    renderDialogView?: (renderProps: { className: string }) => React.ReactElement<any>;
}

export interface CategorizedPackagingResult {
    category: CategoryDefinition;
    registrations: CategorizedPackagingRegistration[];
}

export type PackagingRegistration = CategorizedPackagingRegistration | SimplePackagingRegistration;

class PackagingRegistry {
    private registrations: Record<string, CategorizedMachineRegistration> = {};
    private categories: Record<string, CategoryDefinition> = {};

    public getRegistration(id: string) {
        return this.registrations[id];
    }

    public registerPackaging(registration: CategorizedMachineRegistration) {
        if (this.isCategorizedPackaging(registration)) {
            this.registerCategories(registration.categories);
        }
        this.registrations[registration.type] = registration;
    }

    public getAllRegistrations(): PackagingRegistration[] {
        return values(this.registrations);
    }

    public isCategorizedPackaging(item: PackagingRegistration): item is CategorizedPackagingRegistration {
        const endpoint = item as CategorizedPackagingRegistration;
        return endpoint.categories !== undefined;
    }

    public categorizePackagings(endpoints: PackagingRegistration[]): Record<string, CategorizedPackagingResult> {
        const categorizedEndoints = endpoints.filter(this.isCategorizedPackaging);

        return categorizedEndoints.reduce((prev: Record<string, CategorizedPackagingResult>, current: CategorizedPackagingRegistration) => {
            const result = { ...prev };
            current.categories.forEach(x => (
                result[x.category] = {
                    category: x,
                    registrations: [...((prev[x.category] && prev[x.category].registrations) || []), ...[current]]
                }));
            return result;
        }, {});
    }

    private registerCategory(category: CategoryDefinition) {
        if (!this.categories.hasOwnProperty(category.category)) {
            this.categories[category.category] = category;
        }
    }

    private registerCategories(categories: CategoryDefinition[]) {
        for (const category of categories) {
            this.registerCategory(category);
        }
    }
}

const registry = new PackagingRegistry();
export default registry;