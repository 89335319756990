import * as React from "react";
import {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import { AwsBoundAccountVariableSelect } from "components/form/AccountSelect/AccountVariableSelect";
import {ExpandableFormSection, Summary, Text, UnstructuredFormSection, Note, SummaryNode, RadioButton } from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import {AWSScriptProperties} from "./awsLoginComponent";
import {ValueInPropertiesOrErrorsHasChanged} from "utils/ShouldUpdate/ValueInPropertiesHasChanged";
import { VariableLookupText } from "components/form/VariableLookupText";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import Callout, {CalloutType} from "components/Callout/Callout";

const StringProperties = {
    "Octopus.Action.AwsAccount.Variable": "",
    "Octopus.Action.AwsAccount.UseInstanceRole": "",
    "Octopus.Action.Aws.AssumeRole": "",
    "Octopus.Action.Aws.AssumedRoleArn": "",
    "Octopus.Action.Aws.AssumedRoleSession": "",
    "Octopus.Action.Aws.Region": ""
};

const AllProperties = {
    ...StringProperties
};

export type AWSScriptProperties = {[P in keyof typeof StringProperties]: string};

/**
 * A component that displays the connection details for an AWS account
 */
export default abstract class AwsLoginComponent extends BaseComponent<ActionEditProps<AWSScriptProperties, any>, never> {
    shouldComponentUpdate(newProps: ActionEditProps<AWSScriptProperties>): boolean {
        return ValueInPropertiesOrErrorsHasChanged(AllProperties, newProps, this.props);
    }

    render() {
        return <div>
            <ExpandableFormSection
                errorKey="Octopus.Action.AwsAccount.Variable|Octopus.Action.Aws.AssumedRoleArn|Octopus.Action.Aws.AssumedRoleSession"
                isExpandedByDefault={this.props.expandedByDefault}
                title="AWS Account"
                help="The AWS account details"
                summary={this.awsAccountSummary()}>

                <BoundStringRadioButtonGroup
                    resetValue={"False"}
                    value={this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"]}
                    onChange={(x) => {
                            this.props.setProperties({["Octopus.Action.AwsAccount.UseInstanceRole"]: x});
                            this.props.setProperties({["Octopus.Action.AwsAccount.Variable"]: ""});
                     }}
                    label="Execute using the AWS service role for an EC2 instance">
                    <RadioButton value={"True"}
                                 label="Yes"/>
                    <RadioButton value={"False"}
                                 label="No"/>
                </BoundStringRadioButtonGroup>

                    {this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"] === "False" && (this.props.projectId
                        ? <AwsBoundAccountVariableSelect
                            projectId={this.props.projectId}
                            resetValue={""}
                            allowClear={true}
                            doBusyTask={this.props.doBusyTask}
                            value={this.props.properties["Octopus.Action.AwsAccount.Variable"] as string}
                            onChange={(val) => this.props.setProperties({["Octopus.Action.AwsAccount.Variable"]: val})}
                        />
                        : <VariableLookupText label="AWS Account variable"
                                              localNames={this.props.localNames}
                                              projectId={this.props.projectId}
                                              value={this.props.properties["Octopus.Action.AwsAccount.Variable"] as string}
                                              onChange={(val) => this.props.setProperties({["Octopus.Action.AwsAccount.Variable"]: val})}
                        />)
                    }
                    <BoundStringRadioButtonGroup
                        resetValue={"False"}
                        value={this.props.properties["Octopus.Action.Aws.AssumeRole"]}
                        onChange={(x) => this.props.setProperties({["Octopus.Action.Aws.AssumeRole"]: x})}
                        label="Assume a different AWS service role">
                        <RadioButton value={"True"}
                                     label="Yes"/>
                        <RadioButton value={"False"}
                                     label="No"/>
                    </BoundStringRadioButtonGroup>

                {this.props.properties["Octopus.Action.Aws.AssumeRole"] === "True" &&
                <div>
                    <VariableLookupText label="Assumed role ARN"
                                        localNames={this.props.localNames}
                                        projectId={this.props.projectId}
                                        value={this.props.properties["Octopus.Action.Aws.AssumedRoleArn"]}
                                        onChange={(val) => this.props.setProperties({["Octopus.Action.Aws.AssumedRoleArn"]: val})}
                                        error={this.props.getFieldError("Octopus.Action.Aws.AssumedRoleArn")}
                    />
                    <VariableLookupText label="Assumed role session name"
                                        localNames={this.props.localNames}
                                        projectId={this.props.projectId}
                                        value={this.props.properties["Octopus.Action.Aws.AssumedRoleSession"]}
                                        onChange={(val) => this.props.setProperties({["Octopus.Action.Aws.AssumedRoleSession"]: val})}
                                        error={this.props.getFieldError("Octopus.Action.Aws.AssumedRoleSession")}
                    />
                </div>}
            </ExpandableFormSection>
            <UnstructuredFormSection><
                Callout type={CalloutType.Information}>
                More information can be found regarding terminology for roles and assuming roles &nbsp;
                <ExternalLink href="AwsDocsRolesTermsAndConcepts">here</ExternalLink>.
                </Callout>
            </UnstructuredFormSection>
        </div>;
    }

    private awsAccountSummary(): SummaryNode {
        const accountVariable = this.props.properties["Octopus.Action.AwsAccount.Variable"];
        const assumedRoleArn = this.props.properties["Octopus.Action.Aws.AssumedRoleArn"];
        const assumedRole = this.props.properties["Octopus.Action.Aws.AssumeRole"] === "True";
        const useInstanceRole = this.props.properties["Octopus.Action.AwsAccount.UseInstanceRole"] === "True";

        if (useInstanceRole) {
            return Summary.summary(<span>
                The AWS service role for an EC2 instance will be used {assumedRole && assumedRoleArn &&
                    <span> to assume the AWS service role <strong>{assumedRoleArn}</strong></span>}
            </span>);
        }

        return accountVariable
            ? Summary.summary(<span>
                The AWS account <strong>{accountVariable}</strong> will be used
                {assumedRole && assumedRoleArn && <span> to assume the AWS service role <strong>{assumedRoleArn}</strong></span>}
            </span>)
            : Summary.placeholder("The account variable has not been provided");
    }
}
