import "./configurationTransforms/configurationTransforms";
import "./configurationVariables/configurationVariables";
import "./customDirectory/customDirectory";
import "./customScripts/customScripts";
import "./iisHome/iisHome";
import "./iisWebSite/iisWebSite";
import "./jsonConfigurationVariables/jsonConfigurationVariables";
import "./substituteInFiles/substituteInFiles";
import "./redgateDatabase/redgateDatabase";
import "./vhd/vhd";
import "./windowsService/windowsService";
import "./kubernetes/kubernetesIngressFeature";
import "./kubernetes/kubernetesServiceFeature";
import "./kubernetes/kubernetesConfigMapFeature";
import "./kubernetes/kubernetesSecretFeature";
import "./kubernetes/kubernetesCustomResourceFeature";
import "./nginx/nginx";