import * as React from "react";
import FingerPrint from "material-ui/svg-icons/action/fingerprint";
const styles = require("./style.less");
import CopyToClipboard from "components/CopyToClipboardButton/CopyToClipboardButton";

interface ServerThumbprintProps {
    thumbprint: string;
}

class ServerThumbprint extends React.PureComponent<ServerThumbprintProps> {
    render() {
        return <span className={styles.container}>
            <FingerPrint />
            <span className={styles.thumbprint}>{this.props.thumbprint}</span>
            <CopyToClipboard value={this.props.thumbprint} />
        </span>;
    }
}

export default ServerThumbprint;