import * as React from "react";
import PagingBaseComponent from "components/PagingBaseComponent";
import { PagingBaseProps, PagingBaseState, HasId, } from "components/PagingBaseComponent";
import ListInternalLink from "components/Navigation/InternalLink/ListInternalLink";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
const styles = require("./style.less");
import {getNavigationUrl} from "../PagingBaseComponent/PagingBaseComponent";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import { NoResults } from "components/NoResults/NoResults";

interface PagingDataGridProps<R extends HasId> extends PagingBaseProps<R> {
    empty?: React.ReactNode;
    match?: any;
}

interface PagingDataGridState<R extends HasId> extends PagingBaseState<R> {
    redirectTo: string;
}

export abstract class PagingDataGrid<R extends HasId> extends PagingBaseComponent<R, PagingDataGridProps<R>, PagingDataGridState<R>> {
    private empty: React.ReactNode = (<NoResults />);

    constructor(props: PagingDataGridProps<R>) {
        super(props);
        this.empty = props.empty || this.empty;
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        if (!this.state.data) {
            return <BusyIndicator show={true} />;
        }

        return (
            <TransitionAnimation>
                {this.renderFilterSearchComponents()}

                {this.state.data.Items.length === 0
                ? <div className={styles.emptyList}>{this.empty}</div>
                : <div>
                    <div className={styles.dataGridContainer}>
                        {this.state.data.Items.filter((item: R) => this.onFilter(this.state.filter, item))
                            .map((item: R) => this.buildItem(item))}
                    </div>
                    {
                        this.state.data.Items.length < this.state.data.TotalResults
                            ?
                            this.props.showPagingInNumberedStyle
                                ? this.showPagingInNumberedStyle()
                                : this.showPagingInLoadMoreStyle()
                            : null
                    }
                </div>}
            </TransitionAnimation>
        );
    }

    private buildItem(item: R) {
        const redirectUrl = getNavigationUrl((this.props as any), item);
        let nodeRow = this.props.onRow(item);
        if (redirectUrl) {
            // Make these actual href links, so people can still right-click > open new window (this is expected usability for things you can click).
            nodeRow = <ListInternalLink to={redirectUrl}>{nodeRow}</ListInternalLink>;
        }
        const nodes = [
            <div key={item.Id} className={styles.gridItem} onClick={e => this.navigate(item)}>
                {nodeRow}
            </div>
        ];
        return (
            React.Children.toArray(nodes)
        );
    }
}

(PagingDataGrid as any).defaultProps = {
    showFilterWithinSection: true,
};
