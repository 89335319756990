exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_tooltipContent__2UlX8 p {\n  color: #ffffff;\n  padding: 0.25rem 0;\n  margin: 0;\n}\n", ""]);

// exports
exports.locals = {
	"tooltipContent": "style_tooltipContent__2UlX8"
};