import * as React from "react";
import { FormFieldProps } from "components/form";
import ReferenceDataItem from "client/resources/referenceDataItem";
import {DeploymentActionPackageResource, displayName} from "../../client/resources/deploymentActionPackageResource";
import MultiSelect from "./MultiSelect";
import LookupReferenceDataItemChip from "../LookupReferenceDataItemChip/LookupReferenceDataItemChip";
import {ChipIcon, DeploymentActionPackageChip} from "components/Chips";

interface DeploymentActionPackageMultiSelectProps extends FormFieldProps<string[]> {
    items: DeploymentActionPackageReferenceDataItem[];
    customLabel?: string;
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

export class DeploymentActionPackageReferenceDataItem extends ReferenceDataItem {
    ActionPackage: DeploymentActionPackageResource;

    constructor(actionPackage: DeploymentActionPackageResource, id: string) {
       super();
       this.Id = id;
       this.Name = displayName(actionPackage);
       this.ActionPackage = actionPackage;
   }
}

const DeploymentActionPackageTypedMultiSelect = MultiSelect<DeploymentActionPackageReferenceDataItem>();

const DeploymentActionPackageMultiSelect: React.StatelessComponent<DeploymentActionPackageMultiSelectProps> = props => {
    const chipRenderer = (r: DeploymentActionPackageReferenceDataItem, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.StepAction}
            chipRender={(item: DeploymentActionPackageReferenceDataItem) => <DeploymentActionPackageChip
                onRequestDelete={onRequestDelete}
                actionPackage={item.ActionPackage}
            />}
        />;
    };

    return <DeploymentActionPackageTypedMultiSelect
        items={props.items}
        fieldName="package steps"
        renderChip={chipRenderer}
        {...props} />;
};

export default DeploymentActionPackageMultiSelect;
