import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../../Actions/pluginRegistry";
import {BaseComponent} from "../../BaseComponent/BaseComponent";
import {KubernetesConfigMapProperties} from "components/Actions/kubernetes/kubernetesProperties";
import {KubernetesConfigMapComponent} from "components/Actions/kubernetes/kubernetesConfigMapComponent";
import {ScriptPackageProperties, ScriptPackageReference} from "components/Actions/script/ScriptPackageReferenceDialog";

class KubernetesConfigMapFeature extends BaseComponent<ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, never> {
    render() {
        return <KubernetesConfigMapComponent
            properties={this.props.properties}
            packages={this.props.packages}
            plugin={this.props.plugin}
            getFieldError={this.props.getFieldError}
            setProperties={this.props.setProperties}
            setPackages={this.props.setPackages}
            doBusyTask={this.props.doBusyTask}
            busy={this.props.busy}
            errors={this.props.errors}
            projectId={this.props.projectId}
            expandedByDefault={true}/>;
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesConfigMap",
    title: "Config Map",
    description: "Defines a Kubernetes ConfigMap resource, which is a collection of key/value settings",
    edit: KubernetesConfigMapFeature,
    priority: 20
});