import * as React from "react";
import * as showdown from "showdown";
import * as xss from "xss";
import Markdown from "../Markdown";
const styles  = require("./style.less");

interface MarkdownDescriptionProps {
    markup: string;
}

// This is purely to give us a styling wrapper for all resource descriptions (eg. when shown in lists etc).
const MarkdownDescription: React.StatelessComponent<MarkdownDescriptionProps> = props => {
    return props.markup ? <div className={styles.description}><Markdown markup={props.markup}/></div> : null;
};

export default MarkdownDescription;