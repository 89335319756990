import * as React from "react";
import { repository, client } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import {
    EnvironmentResource,
    DeploymentTargetResource,
    MachineModelHealthStatus,
    MachineResource,
    TaskRestrictedTo,
    SummaryResource
} from "client/resources";
import { ResourceCollection, UpdateCalamariTaskArguments, HealthCheckTaskArguments, UpgradeTaskArguments } from "client/resources";
import { ReactNode } from "react";
import MachineIconHelper from "utils/MachineIconHelper";
import CloseIcon from "material-ui/svg-icons/content/clear";
import IconButton from "material-ui/IconButton";
import List from "components/List";
import {
    FormSectionHeading,
} from "components/form";
import { Section } from "components/Section/Section";
import MachineFilter from "../MachinesLayout/MachineFilter";
import { Dictionary } from "lodash";
import MachineHealthStatusHelper from "utils/MachineHealthStatusHelper";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import { TaskName, TaskResource } from "client/resources/taskResource";
import { TaskState } from "client/resources/taskState";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { NoResults } from "components/NoResults/NoResults";

export const blockingDeploymentConfirmationElement = <div>Please note: This is a blocking task and will prevent deployments during the upgrade.<br /><br />Are you sure?</div>;

interface SmallCloseButtonProps {
    onClose?: () => void;
}
export const SmallCloseButton: React.SFC<SmallCloseButtonProps> = (props) => {
    return <IconButton style={{
        verticalAlign: "middle",
        margin: "0",
        padding: "0",
        width: "1.5rem",
        height: "1.5rem",
    }} onClick={props.onClose}>
        <CloseIcon viewBox="-5 0 35 25" />
    </IconButton>;
};

class MachinesList extends List<MachineResource> { }

export interface BaseAllMachinesSummaryProps {
    filter: MachineFilter;
}

export interface BaseAllMachinesSummaryState extends DataBaseComponentState {
    machinesResponse: ResourceCollection<MachineResource>;
    machineHealthStatusFastLookup?: Dictionary<ResourceCollection<MachineResource>>;
    currentPageIndex: number; // This has a custom endpoint, so we manage our own paging implementation in List/onLoadMore.
    expanded: boolean; // Need to know if we're currently expanded so we can choose to reload when the filter changes or not.
    healthStatusFilter: MachineModelHealthStatus;
    isDisabledFilter: boolean;
    redirectToTaskId?: string;
}

abstract class BaseAllMachinesSummary<Props extends BaseAllMachinesSummaryProps, State extends BaseAllMachinesSummaryState>
    extends DataBaseComponent<Props, State> {
    protected machineListTakeSize = 30; // This generally should match the default page size you'd expect from a typical API call to keep this feeling consistent.
    protected machineIconHelper = new MachineIconHelper();

    constructor(props: Props) {
        super(props);
        this.state = this.initialState();
    }

    protected abstract initialState(): State;
    protected abstract async loadData(): Promise<any>;
    protected abstract renderMachine(machine: MachineResource): ReactNode;

    protected reloadDataAndCurrentPageIndex() {
        this.setState({ currentPageIndex: 0 }, async () => {
            await this.doBusyTask(async () => {
                await this.loadData();
            });
        });
    }

    protected makeMachineResourceCollection(machines: MachineResource[], takeSize: number) {
        // Emulate a paging response so this will work with our List easily.
        const totalResults = machines.length;
        const itemsPerPage = takeSize;
        const numberOfPages = Math.max(1, Math.ceil(totalResults / itemsPerPage));
        const lastPageNumber = numberOfPages - 1;
        const pageItems = machines.slice(0, itemsPerPage);
        const machinesCollection: ResourceCollection<MachineResource> = {
            ItemType: "Machine",
            TotalResults: totalResults,
            ItemsPerPage: itemsPerPage,
            NumberOfPages: numberOfPages,
            LastPageNumber: lastPageNumber,
            Items: pageItems,
            Links: null,
        };
        return machinesCollection;
    }

    protected async performHealthCheck(taskRestrictedTo: TaskRestrictedTo) {
        return this.doBusyTask(async () => {
            const taskResource: TaskResource<HealthCheckTaskArguments> = {
                Id: null,
                State: TaskState.Queued,
                Name: TaskName.Health,
                Description: taskRestrictedTo === TaskRestrictedTo.Workers ? "Check worker health" : "Check deployment target health",
                Arguments: {
                    Timeout: "00:05:00",
                    OnlyTestConnection: false
                },
                IsCompleted: false,
                HasPendingInterruptions: false,
                HasWarningsOrErrors: false,
                Links: null,
                SpaceId: client.spaceId
            };

            taskResource.Arguments.RestrictedTo = taskRestrictedTo;

            const task = await repository.Tasks.create(taskResource);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performTentacleUpgrade(taskRestrictedTo: TaskRestrictedTo) {
        return this.doBusyTask(async () => {
            const taskResource: TaskResource<UpgradeTaskArguments> = {
                Id: null,
                State: TaskState.Queued,
                Name: TaskName.Upgrade,
                Description: "Upgrade Tentacles",
                Arguments: {},
                IsCompleted: false,
                HasPendingInterruptions: false,
                HasWarningsOrErrors: false,
                Links: null,
                SpaceId: client.spaceId
            };
            taskResource.Arguments.RestrictedTo = taskRestrictedTo;

            const task = await repository.Tasks.create(taskResource);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected async performCalamariUpgrade(machineIds: string[], taskDescription: string) {
        return this.doBusyTask(async () => {
            const taskResource: TaskResource<UpdateCalamariTaskArguments> = {
                Id: null,
                State: TaskState.Queued,
                Name: TaskName.UpdateCalamari,
                Description: taskDescription,
                Arguments: {
                    MachineIds: machineIds
                },
                IsCompleted: false,
                HasPendingInterruptions: false,
                HasWarningsOrErrors: false,
                Links: null,
                SpaceId: client.spaceId
            };
            const task = await repository.Tasks.create(taskResource);
            this.setState({ redirectToTaskId: task.Id });
        });
    }

    protected renderMachinesList() {
        const componentKey = "allMachines";
        return <div key={componentKey}>
            {this.state.expanded && this.state.machinesResponse &&
                <div>
                    {this.renderMachinesListGroupedByHealthStatus()}
                </div>}
        </div>;
    }

    protected renderMachinesListGroupedByHealthStatus() {
        const allMachines = this.state.machinesResponse.Items;
        if (allMachines.length === 0) {
            return <Section><NoResults /></Section>;
        }
        const machinesHealthyList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Healthy, false, allMachines, true);
        const machinesUnavailableList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Unavailable, false, allMachines, true);
        const machinesUnknownList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Unknown, true, allMachines, true);
        const machinesHasWarningsList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.HasWarnings, false, allMachines, true);
        const machinesUnhealthyList = this.renderHealthStatusSectionHeading(MachineModelHealthStatus.Unhealthy, false, allMachines, true);
        const machinesDisabledList = this.renderDisabledSectionHeading(allMachines, true);
        return <div>
            {machinesHealthyList}
            {machinesUnavailableList}
            {machinesUnknownList}
            {machinesHasWarningsList}
            {machinesUnhealthyList}
            {machinesDisabledList}
        </div>;
    }

    protected renderHealthStatusSectionHeading(status: MachineModelHealthStatus,
                                               excludeDisabledMachines: boolean,
                                               allMachines: MachineResource[],
                                               showTitle: boolean): JSX.Element {
        let machines = allMachines.filter(x => x.HealthStatus === status);
        if (excludeDisabledMachines) {
            machines = machines.filter(x => !x.IsDisabled);
        }

        let machinesTitleIcon = null;
        let machinesTitle = null;
        if (showTitle) {
            machinesTitleIcon = this.machineIconHelper.healthStatusIcons[status];
            machinesTitle = <div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    {machinesTitleIcon && <img src={machinesTitleIcon} className={styles.healthStatusIcon} alt="Health status" />}
                </div>
                <div className={styles.healthStatusName}>{MachineHealthStatusHelper.getFriendlyName(status)}</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>;
        }

        const componentKey = status.toString();
        const machinesList = machines.length > 0 && <div>
            {showTitle && <FormSectionHeading title={machinesTitle} />}
            <MachinesList
                key={componentKey}
                initialData={this.state.machineHealthStatusFastLookup[componentKey]}
                onRow={(item: any) => this.renderMachine(item)}
                onRowRedirectUrl={(machine: MachineResource) => routeLinks.infrastructure.machine(machine).root}
                onLoadMore={async () => {
                    const newTakeSize = this.state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize;
                    const machineHealthStatusFastLookup = this.state.machineHealthStatusFastLookup;
                    const response = this.state.machinesResponse as ResourceCollection<DeploymentTargetResource>;
                    const filteredMachines = response.Items.filter(x => x.HealthStatus === status);
                    const machinesForHealthStatus = this.makeMachineResourceCollection(filteredMachines, newTakeSize);
                    machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                    this.setState({
                        machineHealthStatusFastLookup,
                    });
                }}
            />
        </div>;
        return machinesList;
    }

    protected renderDisabledSectionHeading(allMachines: MachineResource[], showTitle: boolean): JSX.Element {
        const machines = allMachines.filter(x => x.IsDisabled);

        let machinesTitleIcon = null;
        let machinesTitle = null;
        if (showTitle) {
            machinesTitleIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
            machinesTitle = <div className={styles.healthStatusCardTitleContainer} key={status}>
                <div className={styles.healthStatusIconContainer}>
                    {machinesTitleIcon && <img src={machinesTitleIcon} className={styles.healthStatusIcon} alt="Health status" />}
                </div>
                <div className={styles.healthStatusName}>Disabled</div>
                <div className={styles.healthStatusMachinesCount}>({machines.length})</div>
            </div>;
        }

        const componentKey = "Disabled";
        const machinesList = machines.length > 0 && <div>
            {showTitle && <FormSectionHeading title={machinesTitle} />}
            <MachinesList
                key={componentKey}
                initialData={this.state.machineHealthStatusFastLookup[componentKey]}
                onRow={(item: any) => this.renderMachine(item)}
                onRowRedirectUrl={(machine: MachineResource) => routeLinks.infrastructure.machine(machine).root}
                onLoadMore={async () => {
                    const newTakeSize = this.state.machineHealthStatusFastLookup[componentKey].ItemsPerPage + this.machineListTakeSize;
                    const machineHealthStatusFastLookup = this.state.machineHealthStatusFastLookup;
                    const response = this.state.machinesResponse as ResourceCollection<DeploymentTargetResource>;
                    const filteredMachines = response.Items.filter(x => x.IsDisabled);
                    const machinesForHealthStatus = this.makeMachineResourceCollection(filteredMachines, newTakeSize);
                    machineHealthStatusFastLookup[componentKey] = machinesForHealthStatus;
                    this.setState({
                        machineHealthStatusFastLookup,
                    });
                }}
            />
        </div>;
        return machinesList;
    }

    protected renderMachineSummaryLinks(summary: SummaryResource, healthStatus: MachineModelHealthStatus) {
        const healthStatusIcon = this.machineIconHelper.healthStatusIcons[healthStatus];
        const value = (summary.MachineHealthStatusSummaries as any)[healthStatus] as number;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering health statuses from the sidebar, just show the health statuses that they've chosen to filter (and don't show them as links).
        if (this.props.filter.healthStatuses && this.props.filter.healthStatuses.length > 0) {
            return <div key={healthStatus} className={styles.summaryCount}>
                {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt={healthStatus} />}
                {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
            </div>;
        }

        // Else show environment-specific health status actions.
        if (this.state.healthStatusFilter === healthStatus) {
            return <div key={healthStatus} className={styles.summaryCount}>
                {healthStatusIcon && <img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt={healthStatus} />}
                {value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                <SmallCloseButton onClose={() => {
                    this.setState({ healthStatusFilter: null }, () => {
                        if (this.state.expanded) {
                            this.reloadDataAndCurrentPageIndex();
                        }
                    });
                }} />
            </div>;
        } else {
            return <ActionButton
                key={healthStatus}
                icon={<img key={healthStatus} src={healthStatusIcon} className={styles.healthStatusIcon} alt={healthStatus} />}
                className={styles.summaryCount}
                type={ActionButtonType.Ternary}
                label={value.toLocaleString() + " " + MachineHealthStatusHelper.getFriendlyName(healthStatus as MachineModelHealthStatus).toLowerCase()}
                onClick={(e: any) => {
                    // The user may click a health status link to open an expander (but it shouldn't ever close it).
                    if (this.state.expanded) {
                        e.preventDefault();
                        e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                    }
                    // Clear any disabled filters when a healthStatus filter is clicked. You can't chain inline disabled and healthStatus
                    // filters together because they use different and/or logic at the API and it causes UI confusion.
                    this.setState({
                        healthStatusFilter: healthStatus,
                        isDisabledFilter: false,
                    }, () => {
                        if (this.state.expanded) {
                            this.reloadDataAndCurrentPageIndex();
                        }
                    });
                }}
            />;
        }
    }

    protected renderMachineDisabledSummaryLinks(summary: SummaryResource) {
        const disabledComponentKey = "Disabled";
        const disabledIcon = this.machineIconHelper.healthStatusIcons["Disabled"];
        const value = summary.TotalDisabledMachines;
        if (!value || value === 0) {
            // Don't show links if there's nothing to report.
            return null;
        }

        // If filtering from the sidebar, just show the disabled control (not as a link).
        if (this.props.filter.isDisabled) {
            return <div key={disabledComponentKey} className={styles.summaryCount}>
                {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt={"Disabled"} />}
                {value.toLocaleString() + " disabled"}
            </div>;
        }

        // Else show environment-specific disabled action.
        if (this.state.isDisabledFilter) {
            return <div key={disabledComponentKey} className={styles.summaryCount}>
                {disabledIcon && <img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt={"Disabled"} />}
                {value.toLocaleString() + " disabled"}
                <SmallCloseButton onClose={() => {
                    this.setState({ isDisabledFilter: false }, () => {
                        if (this.state.expanded) {
                            this.reloadDataAndCurrentPageIndex();
                        }
                    });
                }} />
            </div>;
        } else {
            return <ActionButton
                key={disabledComponentKey}
                icon={<img key={disabledComponentKey} src={disabledIcon} className={styles.healthStatusIcon} alt={"Disabled"} />}
                className={styles.summaryCount}
                type={ActionButtonType.Ternary}
                label={value.toLocaleString() + " disabled"}
                onClick={(e: any) => {
                    // The user may click a disabled link to open an expander (but it shouldn't ever close it).
                    if (this.state.expanded) {
                        e.preventDefault();
                        e.stopPropagation(); //prevent clicking the link toggling the panel/expander.
                    }
                    // Clear any healthStatus filters when disabled is clicked. You can't chain inline disabled and healthStatus
                    // filters together because they use different and/or logic at the API and it causes UI confusion.
                    this.setState({
                        isDisabledFilter: true,
                        healthStatusFilter: null,
                    }, () => {
                        if (this.state.expanded) {
                            this.reloadDataAndCurrentPageIndex();
                        }
                    });
                }}
            />;
        }
    }
}

export default BaseAllMachinesSummary;