exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .SaveDialogLayout_hide-sm__xakpY {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .SaveDialogLayout_visible-sm__1TaHc {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .SaveDialogLayout_hide-md__Jfwt2 {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .SaveDialogLayout_visible-md__qLvIy {\n    display: none !important;\n  }\n}\n.SaveDialogLayout_errorsContainer__UA1uK {\n  flex: none;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "SaveDialogLayout_hide-sm__xakpY",
	"hideSm": "SaveDialogLayout_hide-sm__xakpY",
	"visible-sm": "SaveDialogLayout_visible-sm__1TaHc",
	"visibleSm": "SaveDialogLayout_visible-sm__1TaHc",
	"hide-md": "SaveDialogLayout_hide-md__Jfwt2",
	"hideMd": "SaveDialogLayout_hide-md__Jfwt2",
	"visible-md": "SaveDialogLayout_visible-md__qLvIy",
	"visibleMd": "SaveDialogLayout_visible-md__qLvIy",
	"errorsContainer": "SaveDialogLayout_errorsContainer__UA1uK"
};