import { pageId } from "utils/pageId";

export default () => {
    return {
        root: pageId("Configuration"),
        userInvites: pageId("Configuration.UserInvites", "Invite Users"),
        auditBaseRoute: pageId("Configuration.Audit", "Audit"),
        audit: pageId("Configuration.Audit", "Audit"),
        machineEventsForUser: pageId("Configuration.AuditMachine", "Audit Machine"),
        eventsForProject: pageId("Configuration.AuditProject", "Audit Project"),
        eventsForTenant: pageId("Configuration.AuditTenant", "Audit Tenant"),
        eventsForEnvironment: pageId("Configuration.AuditEnvironment", "Audit Environment"),
        eventsForUser: pageId("Configuration.AuditUser", "Audit User"),
        eventsRegardingAny: pageId("Configuration.AuditRegardingAny", "Audit Any Document"),
        deploymentProcessEventsForProject: pageId("Configuration.AuditDeploymentProcess", "Audit Deployment Process"),
        variableSetEventsForProject: pageId("Configuration.AuditVariableSet", "Audit Variable Set"),
        libraryVariableSetEventsRegardingAny: pageId("Configuration.AuditLibraryVariableSet", "Audit Library Variable Set"),
        backup: pageId("Configuration.Backup", "Backup"),
        features: pageId("Configuration.Features", "Features"),
        license: pageId("Configuration.License", "License"),
        smtp: pageId("Configuration.Smtp", "SMTP"),
        nodes: {
            root: pageId("Configuration.Nodes", "Nodes"),
            serverSettings: pageId("Configuration.NodesServerSettings", "Node Server Settings"),
            config: pageId("Configuration.NodesConfig", "Nodes Configuration"),
        },
        thumbprint: pageId("Configuration.Thumbprint", "Thumbprint"),
        maintenance: pageId("Configuration.Maintenance", "Maintenance"),
        letsEncrypt: {
            root: pageId("Configuration.LetsEncrypt", "Let Encrypt"),
            configure: pageId("Configuration.LetsEncryptConfigure", "Configure Lets Encrypt"),
        },
        testPermissions: pageId("Configuration.TestPermissions", "Test Permissions"),
        testPermission: pageId("Configuration.TestPermission", "Test Permission"),
        subscriptions: {
            root: pageId("Configuration.Subscriptions", "Subscriptions"),
            create: pageId("Configuration.SubscriptionsNew", "New Subscription"),
        },
        subscription: pageId("Configuration.Subscription", "Subscription"),
        users: {
            root: pageId("Configuration.Users", "Users"),
            create: pageId("Configuration.UsersNew", "New User"),
        },
        user: pageId("Configuration.User", "User"),
        spaces: {
            root: pageId("Configuration.Spaces", "Spaces"),
            create: pageId("Configuration.SpacesNew", "New Space"),
        },
        space: pageId("Configuration.Space", "Space"),
        teams: {
            root: pageId("Configuration.Teams", "Teams"),
            redirect: pageId("Configuration.TeamsRedirect", "Teams Redirect"),
        },
        team: pageId("Configuration.Team", "Team"),
        roles: {
            root: pageId("Configuration.Roles", "User Roles"),
            create: pageId("Configuration.RolesNew", "New User Role"),
        },
        role: pageId("Configuration.Role", "User Role"),
        diagnostics: {
            root: pageId("Configuration.Diagnostics", "Diagnostics"),
            logs: {
                root: pageId("Configuration.DiagnosticLogs", "Diagnostic Logs"),
                autoDeploy: pageId("Configuration.DiagnosticLogsAutoDeploy", "Auto Deploy Diagnostic Logs"),
                scheduledDeploy: pageId("Configuration.DiagnosticLogsScheduledDeploy", "Scheduled Deploy Diagnostic Logs"),
                machineCleanup: pageId("Configuration.DiagnosticLogsMachineCleanup", "Machine Cleanup Diagnostic Logs"),
                subscription: pageId("Configuration.DiagnosticLogsSubscription", "Subscription Diagnostic Logs"),
            }
        },
        settings: {
            root: pageId("Configuration.Settings", "Settings"),
        },
        setting: (settingId: string) => {
            return {
                root: pageId(`Configuration.Setting.${settingId}`, "Setting")
            };
        },
        performance: pageId("Configuration.Performance", "Performance"),
    };
};
