import * as React from "react";
import { CategoryDefinition } from "../Registry/packagingRegistry";

const category: CategoryDefinition = {
    category: ".NET (other)",
    title: <React.Fragment>How would you like to package this <strong>.NET (other)</strong> application?</React.Fragment>,
    help: null,
    displayOrder: 30,
 };

export default category;
