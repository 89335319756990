import * as React from "react";
import {SFC} from "react";
import {SystemInfoResource} from "client/resources/systemInfoResource";
import {DataTable} from "components/DataTable/DataTable";
import {DataTableBody} from "components/DataTable/DataTableBody";
import {DataTableRowColumn} from "components/DataTable/DataTableRowColumn";
import {DataTableRowHeaderColumn} from "components/DataTable/DataTableRowHeaderColumn";
import {DataTableRow} from "components/DataTable/DataTableRow";
import {default as DurationLabel} from "components/TimeLabels/DurationLabel";
import ByteSizeFormatter from "utils/ByteSizeFormatter";
import {ActionButton} from "components/Button";

interface SystemInformationProps {
    systemInfo: SystemInfoResource;

    onCollectClicked(): void;
}

const SystemInformation: SFC<SystemInformationProps> = props => {
    const si = props.systemInfo;

    return <div>
        <h4>System Information</h4>
        <DataTable>
            <DataTableBody>
                <DataTableRow>
                    <DataTableRowHeaderColumn inSidebar={true}>OS Version</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{si.OSVersion}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn inSidebar={true}>CLR Version</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{si.ClrVersion}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn inSidebar={true}>Working Set</DataTableRowHeaderColumn>
                    <DataTableRowColumn>
                        {ByteSizeFormatter(si.WorkingSetBytes)}<br/>
                        <ActionButton label="Collect" onClick={() => props.onCollectClicked()}/>
                    </DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn inSidebar={true}>Threads</DataTableRowHeaderColumn>
                    <DataTableRowColumn>{si.ThreadCount}</DataTableRowColumn>
                </DataTableRow>
                <DataTableRow>
                    <DataTableRowHeaderColumn inSidebar={true}>Uptime</DataTableRowHeaderColumn>
                    <DataTableRowColumn><DurationLabel duration={si.Uptime}/></DataTableRowColumn>
                </DataTableRow>
            </DataTableBody>
        </DataTable>
    </div>;
};

SystemInformation.displayName = "SystemInformation";

export default SystemInformation;