import Client from "../client";
import ConfigurationRepository from "./configurationRepository";
import {UpgradeConfigurationResource} from "client/resources/upgradeConfigurationResource";

class UpgradeConfigurationRepository extends ConfigurationRepository<UpgradeConfigurationResource> {
    constructor(client: Client) {
        super("UpgradeConfiguration", client);
    }
}

export default UpgradeConfigurationRepository;