exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_typeName__3ZjBw {\n  color: #333333;\n  padding: 0;\n  margin: 0 0 0.5rem 0;\n}\n.style_typeHeader__2hV7t {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.style_typeHeaderTitleContainer__2J9sg {\n  margin: 0 1rem;\n  white-space: nowrap;\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n}\n.style_typeBody__12-cq {\n  visibility: visible;\n}\n.style_account__1ezNj p {\n  margin: 0.5rem 0;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"typeName": "style_typeName__3ZjBw",
	"typeHeader": "style_typeHeader__2hV7t",
	"typeHeaderTitleContainer": "style_typeHeaderTitleContainer__2J9sg",
	"typeBody": "style_typeBody__12-cq",
	"account": "style_account__1ezNj"
};