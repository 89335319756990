import * as React from "react";
import ParseHelper from "../../../../utils/ParseHelper/ParseHelper";
import {
    ExpandableFormSection,
    Summary,
    Text,
    Note,
} from "components/form";

interface RetryAttemptsFormSectionProps {
    attemptCount: number;
    backoffSeconds: number;
    onAttemptCountChanged(count: number): void;
    onBackoffSecondsChanged(seconds: number): void;
}
export default function RetryAttemptsFormSection(props: RetryAttemptsFormSectionProps) {
        return <ExpandableFormSection
            errorKey="Retry"
            title="Retry Attempts"
            summary={buildSummary(props)}
            help="Control how Octopus should deal with feed errors.">
            <Text type="number" min={1}
                  value={props.attemptCount ? props.attemptCount.toString() : ""}
                  onChange={(x) => props.onAttemptCountChanged(ParseHelper.safeParseInt(x, 1))}
                  label="Download attempts (attempts)" />
            <Note>The number of times a deployment should attempt to download a package from this feed before failing.</Note>

            <Text type="number" min={0} value={props.backoffSeconds ? props.backoffSeconds.toString() : ""}
                  onChange={(x) => props.onBackoffSecondsChanged(ParseHelper.safeParseInt(x, 0))}
                  label="Download retry backoff (seconds)" />
            <Note>The number of seconds to apply as a linear back off between download attempts.</Note>
        </ExpandableFormSection>;
    }

function buildSummary(model: RetryAttemptsFormSectionProps) {
    const secondsText = model.backoffSeconds === 1 ? "second" : "seconds";
    const attempts = `${model.attemptCount} retry attempts. Retry after ${model.backoffSeconds} ${secondsText}. `;
    return Summary.summary(attempts);
}
