import * as React from "react";
import {ActionButton} from "components/Button";
import {ActionButtonType} from "components/Button/ActionButton";
import { isAllowed, PermissionCheckProps } from "../PermissionCheck/PermissionCheck";
import Dialog from "components/Dialog/Dialog";
const styles = require("./style.less");
import DialogTrigger from "./DialogTrigger";

interface RenderDialogProps {
    open: boolean;
    sequence: string;
    closeDialog(): void;
    openDialog(): void;
}
interface OpenDialogButtonProps {
    label: any;
    disabled?: boolean | Promise<any>;
    permission?: PermissionCheckProps;
    icon?: JSX.Element;
    type?: ActionButtonType;
    wideDialog?: boolean;
    renderDialog?: (props: RenderDialogProps) => React.ReactElement<any>;
}

class OpenDialogButton extends React.Component<OpenDialogButtonProps, never> {

    constructor(props: OpenDialogButtonProps) {
        super(props);
    }

    render() {
        const disabledDueToPermission = !!this.props.permission ? !isAllowed(this.props.permission) : false;

        return <DialogTrigger render={({ open, openDialog, sequence, closeDialog }) => (
            <div className={styles.buttonDialogContainer}>
                <ActionButton
                    type={this.props.type}
                    icon={this.props.icon}
                    label={this.props.label}
                    disabled={disabledDueToPermission || this.props.disabled}
                    onClick={openDialog}
                />
                { this.props.renderDialog ? this.props.renderDialog({open, openDialog, sequence, closeDialog}) :
                    <Dialog open={open} wide={this.props.wideDialog}>
                        {this.props.children}
                    </Dialog>}
            </div>
        )}/>;
    }
}

export default OpenDialogButton;