exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../../../node_modules/css-loader/index.js?modules&camelCase&importLoaders=1&localIdentName=[name]_[local]__[hash:base64:5]!../../../../../../node_modules/less-loader/dist/cjs.js!../../../../../../node_modules/codemirror/lib/codemirror.css"), "");

// module
exports.push([module.id, ".style_ruleIconWrap__24Do4 {\n  display: flex;\n  justify-content: center;\n}\n.style_ruleIconWrap__24Do4 em {\n  line-height: 1.25rem;\n}\n.style_validRule__2H3SV {\n  color: green;\n}\n.style_validRuleIcon__XO7uU {\n  color: green;\n  display: flex;\n  justify-content: center;\n}\n.style_validRuleIcon__XO7uU em {\n  line-height: 1.25rem;\n}\n.style_invalidRule__Z_puA {\n  color: red;\n  text-decoration: line-through;\n}\n.style_invalidRuleIcon__6uRcf {\n  color: red;\n  display: flex;\n  justify-content: center;\n}\n.style_invalidRuleIcon__6uRcf em {\n  line-height: 1.25rem;\n}\n.style_badRuleIcon__1d3j9 {\n  color: orange;\n  display: flex;\n  justify-content: center;\n}\n.style_badRuleIcon__1d3j9 em {\n  line-height: 1.25rem;\n}\n.style_badRuleIcon__1d3j9 {\n  color: orange;\n  display: flex;\n  justify-content: center;\n}\n.style_badRuleIcon__1d3j9 em {\n  line-height: 1.25rem;\n}\n.style_badRule__1OSvp {\n  color: orange;\n}\n.style_codeGutter__bVpTr {\n  width: 1.375rem;\n}\n.style_tooltip__1bERT {\n  background-color: #666666;\n  border-color: #666666 transparent transparent transparent;\n  border-width: 0.3125rem;\n  border-style: solid;\n  border-radius: 0.25rem;\n  color: #ffffff;\n  font-family: monospace;\n  font-size: 10pt;\n  overflow: hidden;\n  padding: 0.125rem 0.3125rem;\n  position: fixed;\n  white-space: pre;\n  white-space: pre-wrap;\n  z-index: 2000;\n  max-width: 600px;\n  opacity: 0;\n  transition: opacity .4s;\n}\n", ""]);

// exports
exports.locals = {
	"ruleIconWrap": "style_ruleIconWrap__24Do4",
	"validRule": "style_validRule__2H3SV",
	"validRuleIcon": "style_validRuleIcon__XO7uU",
	"invalidRule": "style_invalidRule__Z_puA",
	"invalidRuleIcon": "style_invalidRuleIcon__6uRcf",
	"badRuleIcon": "style_badRuleIcon__1d3j9",
	"badRule": "style_badRule__1OSvp",
	"codeGutter": "style_codeGutter__bVpTr",
	"tooltip": "style_tooltip__1bERT"
};