import * as React from "react";
import { PollingTentacleEndpointResource } from "client/resources";
import { CategorizedMachineRegistration, CommunicationStyle } from "./endpointRegistry";
import WindowsCategory from "./WindowsCategoryDefinition";
import { PollingTentacleCallout } from "areas/infrastructure/components/TentacleCallout";
import NavigationButton, { NavigationButtonType } from "components/Button/NavigationButton";
import routeLinks from "routeLinks";
import { EndpointThumbnail } from "./EndpointThumbnail";
import { InfoRegistrationCards } from "./RegistrationCards";
import { EndpointSelectionScope } from "./endpointRegistry";
import TentacleThumbprint from "./TentacleThumbprint";
import { ExpandableFormSection, Summary, Text, Note } from "components/form";

interface TentacleActiveEndpointProps {
    endpoint: PollingTentacleEndpointResource;
    serverThumbprint: string;
    onChange(newValue: PollingTentacleEndpointResource): void;
}

export class TentacleActiveEndpoint extends React.Component<TentacleActiveEndpointProps, undefined> {
    render() {
        return <div>
            <TentacleThumbprint
                endpoint={this.props.endpoint}
                serverThumbprint={this.props.serverThumbprint}
                onChange={(endpoint) => { this.props.onChange(endpoint as PollingTentacleEndpointResource); }} />
            <ExpandableFormSection
                errorKey="Uri"
                title="Subscription ID"
                focusOnExpandAll
                summary={this.props.endpoint.Uri ? Summary.summary(this.props.endpoint.Uri) : Summary.placeholder("No uri specified")}
                help="The unique polling subscription id used by the Tentacle.">
                <Text label="Subscription ID"
                    hintText="poll://abcdefghijklmnopqrst/"
                    value={this.props.endpoint.Uri}
                    onChange={(x) => {
                        const endpoint = this.props.endpoint;
                        endpoint.Uri = x;
                        this.props.onChange(endpoint);
                    }}
                    type="url" />
                <Note>
                    The subscription id is a random 20 character id that is used to queue messages from the server
                    to the Polling Tentacle. This should match the value in the Tentacle config file.
                </Note>
            </ExpandableFormSection>
        </div>;
    }
}

const TentacleActiveDialogView: React.SFC<{}> = () => (<div>
    <PollingTentacleCallout/>
    <h3 style={{marginTop: "2rem"}}>After installation</h3>
    <p>The new Polling Tentacle will automatically show up in the Deployment Targets list.</p>
</div>);

const TentacleAdditionalActions: React.SFC<{scope: EndpointSelectionScope}> = ({scope}) => {
    const deploymentTargetLink = scope === EndpointSelectionScope.DeploymentTarget;
    const workerLink = scope === EndpointSelectionScope.Worker;

    return (
        <React.Fragment>
            { deploymentTargetLink && <NavigationButton key="deployment-targets"
                type={NavigationButtonType.Secondary}
                label="View Deployment Targets"
                href={routeLinks.infrastructure.machines.filtered({commStyles: [CommunicationStyle.TentacleActive]})}
            />}
            { workerLink && <NavigationButton key="workers"
                type={NavigationButtonType.Secondary}
                label="View Workers"
                href={routeLinks.infrastructure.workerMachines.filtered({commStyles: [CommunicationStyle.TentacleActive]})}
            />}
        </React.Fragment>
    );
};

export default TentacleActiveEndpoint;

const activeTentacleEndpointImage = require("./polling-target.svg");
const activeTentacleEndpointRegistration: CategorizedMachineRegistration = {
    displayOrder: 10,
    categories: [ WindowsCategory ],
    name: "Polling Tentacle",
    type: CommunicationStyle.TentacleActive,
    discoverable: false,
    renderCard: InfoRegistrationCards.basic(
        () => <TentacleActiveDialogView/>,
        ({registration}) => ({
            logo: (<EndpointThumbnail src={activeTentacleEndpointImage} alt={registration.name} />),
            header: registration.name,
            description: "A Polling target will actively poll the Octopus Server for tasks to perform."
        }),
        ({scope}) => <TentacleAdditionalActions scope={scope}/>
    )
};

export { activeTentacleEndpointRegistration };