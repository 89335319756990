import * as React from "react";
import {ActionToggleInfo} from "./deploymentStepsWorker";
const styles = require("./style.less");
import Logo from "components/Logo/Logo";
import {DeploymentProcessResource} from "client/resources/deploymentProcessResource";
import getActionLogoUrl from "areas/projects/components/getActionLogoUrl";
import * as cn from "classnames";

interface ActionSummaryProps {
    process: DeploymentProcessResource;
    action: ActionToggleInfo;
    stepActionIdsToSkip?: string[];
    actionIsExcluded?: boolean;
}

const ActionSummary: React.SFC<ActionSummaryProps> = (props) => {
    return <div className={styles.logo}>
        <Logo size="1.5rem" url={getLogoUrl(props.action, props.process)}/>
        <span className={props.actionIsExcluded ? styles.excludedAction : null}>
            {isActionDisabledOrSkipped(props.action)
                ? <span className={styles.skipStepPreviewTitle}>{props.action.details.ActionNumber} {props.action.details.ActionName}</span>
                : <span>{props.action.details.ActionNumber}. {props.action.details.ActionName}</span>
            }
        </span>
        {props.actionIsExcluded && <span className={styles.excludedActionPostfixLabel}> (excluded)</span>}
    </div>;

    function isActionDisabledOrSkipped(action: ActionToggleInfo) {
        return props.stepActionIdsToSkip && props.stepActionIdsToSkip.some(id => id === action.details.ActionId) || action.details.IsDisabled;
    }
};

export function getLogoUrl(action: ActionToggleInfo, process: DeploymentProcessResource) {
    const findAction = () => {
        for (const key of process.Steps) {
            for (const actionResource of key.Actions) {
                if (actionResource.Id === action.Id) {
                    return actionResource;
                }
            }
        }

        return null;
    };

    return getActionLogoUrl(findAction());
}

export default ActionSummary;