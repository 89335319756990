
import * as React from "react";
import {ExpandableFormSection, Summary} from "components/form";
import {StartTrigger} from "../../../../client/resources/deploymentStepResource";
import RadioButtonGroup from "../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../components/form/RadioButton/RadioButton";
import {Callout, CalloutType} from "../../../../components/Callout/Callout";
import ExternalLink from "../../../../components/Navigation/ExternalLink/ExternalLink";

const StartConditionText = {
    [StartTrigger.StartAfterPrevious]: "Wait for the previous step to complete, then start",
    [StartTrigger.StartWithPrevious]: "Run in parallel with the previous step"
};

interface StartTriggerExpandableFormSectionProps {
    startTrigger: StartTrigger;
    onChange(startTrigger: StartTrigger): void;
}

class StartTriggerRadioButtonGroup extends RadioButtonGroup<StartTrigger> {}

class StartTriggerExpandableFormSection extends React.Component<StartTriggerExpandableFormSectionProps> {
    shouldComponentUpdate(nextProps: StartTriggerExpandableFormSectionProps) {
        return nextProps.startTrigger !== this.props.startTrigger;
    }

    render() {
        return <ExpandableFormSection title="Start Trigger"
                help="Control whether this step waits for the previous step to complete, or runs in parallel with it."
                summary={this.props.startTrigger === StartTrigger.StartWithPrevious
                    ? Summary.summary(StartConditionText[this.props.startTrigger])
                    : Summary.default(StartConditionText[this.props.startTrigger])}
                errorKey="starttrigger">
            <StartTriggerRadioButtonGroup
                value={this.props.startTrigger}
                onChange={this.props.onChange}>
                {[StartTrigger.StartWithPrevious, StartTrigger.StartAfterPrevious]
                    .map(rc => <RadioButton value={rc} label={StartConditionText[rc]} key={rc}/>)}
            </StartTriggerRadioButtonGroup>
            {(this.props.startTrigger === StartTrigger.StartWithPrevious) &&
            <Callout type={CalloutType.Warning} title="Warning">
                Please note that even with this setting, each target (excluding Azure
                endpoints) will still be limited to only performing a single deployment
                activity at once for safety reasons. See how to <ExternalLink
                href="MultipleProcessesOnTentacle">override this behavior</ExternalLink>.
            </Callout>}
        </ExpandableFormSection>;
        }
}

export default StartTriggerExpandableFormSection;