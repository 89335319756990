import * as React from "react";
import {
    TriggerResource,
    MachineFilterResource,
    EnvironmentResource,
    EventCategoryResource,
    EventGroupResource,
} from "client/resources";
import TriggerDescription from "areas/projects/components/Triggers/TriggerDescription";

const styles = require("./style.less");
import ListTitle from "components/ListTitle/ListTitle";
import { OverflowMenuDeleteItem, OverflowMenuNavLink, OverflowMenuDialogItem } from "components/Menu/OverflowMenu";
import ListItem from "components/ListItem/ListItem";

type MenuItem = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDeleteItem;

interface TriggerProps {
    trigger: TriggerResource;
    environments: EnvironmentResource[];
    categories: EventCategoryResource[];
    groups: EventGroupResource[];
    menuItems?: Array<MenuItem | MenuItem[]>;
}

export default class Trigger extends React.PureComponent<TriggerProps, any> {
    render() {
        const filter = this.props.trigger.Filter as MachineFilterResource;

        return (
            <div className={this.props.trigger.IsDisabled ? styles.disabled : ""}>
                <ListItem overflowMenuItems={this.props.menuItems}>
                    <ListTitle>{this.props.trigger.Name}</ListTitle>
                    {this.buildListItemBody(filter)}
                </ListItem>
            </div>
        );
    }

    private buildListItemBody(filter: MachineFilterResource) {
        const description = new TriggerDescription(filter, this.props.environments, this.props.categories, this.props.groups);
        return <div className={styles.description}>{description.buildDescription()}</div>;
    }
}