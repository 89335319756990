import * as React from "react";
import DividerMaterialUI from "material-ui/Divider";
import {divider} from "colors";

interface DividerProps {
   fullHeight?: boolean;
}

export const Divider: React.StatelessComponent<DividerProps> = props => {
   const margin = props.fullHeight ? { marginTop: 0 } : {};
   return <DividerMaterialUI style={{backgroundColor: divider, ...margin}}/>;
};

export default Divider;