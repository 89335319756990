import {LifecycleProgressionResource} from "../../client/resources/lifecycleProgressionResource";
import {TaskState} from "../../client/resources/taskState";
export interface LifecycleStatus {
    deployed: {[key: string]: boolean};
    tasks: {[key: string]: string};
    warning: {[key: string]: boolean};
    executing: {[key: string]: boolean};
    queued: {[key: string]: boolean};
    progress: {[key: string]: string};
}
export function mapProgressionToStatus(progression?: LifecycleProgressionResource): LifecycleStatus {
    const p: LifecycleStatus = {
        deployed: {},
        tasks: {},
        warning: {},
        executing: {},
        queued: {},
        progress: {}
    };

    if (!progression) {
        return p;
    }

    progression.Phases.forEach((phase) => {
        let phaseStatus = phase.Progress.toString();
        if (phase.Blocked) {
            phaseStatus = "Blocked";
        }
        p.progress[phase.Name] = phaseStatus;

        phase.Deployments.forEach((deployment) => {
            p.deployed[deployment.Deployment.EnvironmentId] = true;
            p.tasks[deployment.Deployment.EnvironmentId] = deployment.Task.Id;
            p.warning[deployment.Deployment.EnvironmentId] = deployment.Task.HasWarningsOrErrors;
            p.executing[deployment.Deployment.EnvironmentId] = deployment.Task.State !== TaskState.Success;
            p.queued[deployment.Deployment.EnvironmentId] = deployment.Task.State === TaskState.Queued;
        });
    });

    return p;
}
