import * as React from "react";
import { EventResource } from "client/resources";
import TimeFromNowLabel from "components/TimeLabels/TimeFromNowLabel";
import Avatar from "components/Avatar";
import EventFormatter from "components/EventFormatter";
import SimpleExpander from "components/SimpleExpander/SimpleExpander";
import EventDetails from "components/EventDetails";
import ToolTip from "components/ToolTip";
const styles = require("./AuditListRow.less");

interface AuditListRowProps {
    event: EventResource;
}

interface AuditListRowState {
    test?: boolean;
}

export default class AuditListRow extends React.Component<AuditListRowProps, AuditListRowState> {

    constructor(props: AuditListRowProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { event } = this.props;
        const userAgentTooltipContent = <div className={styles.userAgentTooltipContainer}>
            <div>Established with: {this.identityEstablishedWithTextForEvent(event)}</div>
            <div>User agent: {this.userAgentTextForEvent(event)}</div>
        </div>;
        const title = <div className={styles.rowContainer}>
            <div className={styles.whenContainer}>
                <TimeFromNowLabel time={event.Occurred} useShortDateFormat={true} />
            </div>
            <div className={styles.whoContainer}>
                <Avatar isService={event.IsService}
                    avatarLink={event.Links && event.Links.Avatar}
                />
                <ToolTip content={userAgentTooltipContent}>
                    <div className={styles.usernameText}>{event.Username}</div>
                </ToolTip>
            </div>
            <div>
                <EventFormatter event={event} />
            </div>
        </div>;
        return <SimpleExpander
            errorKey={event.Id}
            key={event.Id}
            title={title}
        >
            <div className={styles.detailsContainer}>
                <div className={styles.keyValueGrid}>
                    <div className={styles.row}>
                        <div className={styles.key}>Established with:</div>
                        <div>{this.identityEstablishedWithTextForEvent(event)}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>User agent:</div>
                        <div>{this.userAgentTextForEvent(event)}</div>
                    </div>
                </div>
                {event.Details && <EventDetails eventDetails={event.Details} />}
            </div>
        </SimpleExpander>;
    }

    private identityEstablishedWithTextForEvent(event: EventResource) {
        if (!event.IdentityEstablishedWith) {
            return "Unknown";
        }
        return event.IdentityEstablishedWith;
    }

    private userAgentTextForEvent(event: EventResource) {
        if (!event.UserAgent) {
            return "Unknown";
        }
        if (event.UserAgent.startsWith("OctopusClient-js")) {
            return "Portal";
        }
        return event.UserAgent;
    }
}