import * as React from "react";
import * as cn from "classnames";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";

const styles = require("./DialogActions.less");

const continueIf = (predicate: () => Promise<boolean>, action: () => void) => {
    return () => predicate().then(x => {
        if (x) {
            action();
        }
        return x;
    });
};

function isButtonDisabled(flag: boolean, savePermission?: PermissionCheckProps) {
    const disabledDueToPermission = !!savePermission ? !isAllowed(savePermission) : false;
    return !!(flag || disabledDueToPermission);
}

export interface DialogActionsProps {
    actions?: React.ReactNode;
    additionalActions?: React.ReactNode;
    className?: string;
}

export const DialogActions: React.SFC<DialogActionsProps> = ({className, actions, additionalActions}) => {
    return <div className={cn(styles.actions, className)}>
        <div className={styles.additionalActions}>
            {additionalActions}
        </div>
        <div className={styles.primaryActions}>
           {actions}
        </div>
   </div>;
};

export interface SaveDialogActionsProps {
    saveButtonLabel?: string;
    cancelButtonLabel?: string;
    saveDisabled?: boolean;
    savePermission?: PermissionCheckProps;
    onSaveClick(): Promise<boolean>;
    close(): void;
}

export const SaveDialogActions: React.SFC<SaveDialogActionsProps> = ({cancelButtonLabel = "Cancel", saveButtonLabel = "Save", saveDisabled = false, ...rest}) => {
    return <React.Fragment>
        <ActionButton label={cancelButtonLabel} onClick={rest.close} type={ActionButtonType.Secondary} />
        <ActionButton label={saveButtonLabel} onClick={continueIf(rest.onSaveClick, rest.close)} type={ActionButtonType.Save} disabled={isButtonDisabled(saveDisabled, rest.savePermission)} />
    </React.Fragment>;
};

export interface OkDialogActionProps {
    okButtonLabel?: string;
    okButtonDisabled?: boolean;
    okButtonPermission?: PermissionCheckProps;
    hideCancel?: boolean;
    cancelButtonLabel?: string;
    onOkClick(): any;
}

export default DialogActions;