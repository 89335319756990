import * as React from "react";
import Section from "components/Section";
import { Note } from "components/form";

export interface SectionNoteProps {
    children: React.ReactNode;
}

export default class SectionNote extends React.PureComponent<SectionNoteProps> {
    render(): any {
        return <Section><Note>{this.props.children}</Note></Section>;
    }
}