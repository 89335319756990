import * as React from "react";
import { List as BaseList } from "components/List/List";
import { TeamResource, ResourceCollection, ScopedUserRoleResource, SpaceResource } from "client/resources";
import ListTitle from "components/ListTitle/ListTitle";
import styles = require("./style.less");
import routeLinks from "routeLinks";
import {SpaceChip, spaceChipList} from "components/Chips";
import buildValueList from "components/EventFilter/buildValueList";

class List extends BaseList<TeamResource> { }

interface TeamListProps {
    initialTeams: ResourceCollection<TeamResource>;
    initialScopedUserRolesLookup: Record<string, ScopedUserRoleResource[]>;
    spaces: SpaceResource[];
    additionalRequestParams?: Map<string, any>;
    loadScopedUserRolesForTeams(teams: TeamResource[]): Promise<Record<string, ScopedUserRoleResource[]>>;
}

class TeamListState {
    initialTeams: ResourceCollection<TeamResource>;
    scopedUserRolesLookup: Record<string, ScopedUserRoleResource[]>;
}

class TeamList extends React.Component<TeamListProps, TeamListState> {
    constructor(props: TeamListProps) {
        super(props);
        this.state = {
            initialTeams: props.initialTeams,
            scopedUserRolesLookup: props.initialScopedUserRolesLookup
        };
    }
    render() {
        return <div className={styles.list}>
            <List initialData={this.props.initialTeams}
                  onRow={(team: TeamResource) => buildRow(team, this.state.scopedUserRolesLookup, this.props.spaces)}
                  filterSearchEnabled={false}
                  additionalRequestParams={this.props.additionalRequestParams}
                  onRowRedirectUrl={this.redirectUrl}
                  onFilter={() => true}
                  showPagingInNumberedStyle={true}
                  onNewItems={async (teams: TeamResource[]) => {
                      await this.loadMoreScopedUserRoles(teams);
                      return teams;
                  }}
            />
        </div>;
    }

    private redirectUrl = (team: TeamResource) => {
        const root = team.SpaceId === null ? routeLinks : routeLinks.forSpace(team.SpaceId);

        return root.configuration.team(team.Id);
    }

    private async loadMoreScopedUserRoles(team: TeamResource[]) {
        const scopedUserRolesForNewTeams = await this.props.loadScopedUserRolesForTeams(team);
        this.setState(prev => ({
            scopedUserRolesLookup: {...prev.scopedUserRolesLookup, ...scopedUserRolesForNewTeams}
        }));
    }
}

const buildRow = (team: TeamResource, scopedUserRoleLookup: Record<string, ScopedUserRoleResource[]>, spaces: SpaceResource[]) => {
    const membersLength = team.MemberUserIds.length;
    const externalGroupLength = team.ExternalSecurityGroups.length;
    const scopedUserRoles = scopedUserRoleLookup[team.Id];

    return <div>
        {buildTeamTitle(team, spaces)}
        <div>
            This team has <strong>{membersLength} member{membersLength === 1 ? "" : "s"}</strong>
            {buildExternalGroupsSpan(externalGroupLength)}
            {buildPermissionsSpan(scopedUserRoles, team, spaces)}
        </div>
    </div>;
};

const buildTeamTitle = (team: TeamResource, spaces: SpaceResource[]) => {
    const teamSpace: SpaceResource = spaces.find(s => team.SpaceId === s.Id) || null;
    return <ListTitle>
        {team.Name}{teamSpace && <SpaceChip space={teamSpace} description={`This team belongs to the ${teamSpace.Name} space`} />}
    </ListTitle>;
};

const buildPermissionsSpan = (scopedUserRoles: ScopedUserRoleResource[], team: TeamResource, spaces: SpaceResource[]) => {
    if (scopedUserRoles && scopedUserRoles.length > 0) {
        const elements: JSX.Element[] = [];
        const hasUnspacedPermissions = scopedUserRoles.filter(x => x.SpaceId === null).length > 0;
        const hasSpacedPermissions = scopedUserRoles.filter(x => x.SpaceId !== null).length > 0;
        if (hasUnspacedPermissions) {
            elements.push(<span key="systempermissions">&nbsp;and <strong>system permissions</strong></span>);
        }
        if (hasSpacedPermissions) {
            const validSpaceIds = scopedUserRoles.filter(x => x.SpaceId !== null).map(x => x.SpaceId);
            const spaceChips = spaceChipList(spaces, validSpaceIds);
            const spaceChipsElement = <span key={`${team.Id}-spaces`}>{buildValueList(spaceChips)}</span>;
            elements.push(<span key="permissions">&nbsp;and permissions for the {spaceChipsElement} space{spaceChips.length > 1 ? "s" : ""}</span>);
        }
        return elements;
    }
    return null;
};

const buildExternalGroupsSpan = (externalGroupLength: number) => {
    if (externalGroupLength > 0) {
        return <span key="externalGroups">&nbsp;and <strong>{externalGroupLength} external group{externalGroupLength === 1 ? "" : "s"}</strong></span>;
    }
    return null;
};

export default TeamList;