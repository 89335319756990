import * as React from "react";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import Text from "components/form/Text/Text";
import {required} from "components/form/Validators";
import {Redirect} from "react-router";
import LibraryVariableSetResource from "client/resources/libraryVariableSetResource";
import {repository} from "clientInstance";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {Permission} from "client/resources";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
import {ActionButtonType} from "components/Button/ActionButton";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";

interface AddVariableSetDialogContentState extends DataBaseComponentState {
    name: string;
    description: string;
    newVariableSetId?: string;
}

class AddVariableSetButtonDialogContent extends DataBaseComponent<{}, AddVariableSetDialogContentState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            name: "",
            description: ""
        };
    }

    render() {
        return <SaveDialogLayout
            title="Add New Variable Set"
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => this.save()}>
            {this.state.newVariableSetId && <InternalRedirect to={routeLinks.library.variableSet(this.state.newVariableSetId)} />}
            <Text
                label="New variable set name"
                value={this.state.name}
                onChange={name => this.setState({name})}
                validate={required("Please enter a variable set name")}
                autoFocus={true}
            />
            <MarkdownEditor
                label="Variable set description"
                value={this.state.description}
                onChange={description => this.setState({description})}
                restrictHeight={true}
            />
        </SaveDialogLayout>;
    }

    private async save() {
        return this.doBusyTask(async () => {
            const libraryVariableSet: LibraryVariableSetResource = {
                Name: this.state.name,
                Description: this.state.description,
                VariableSetId: undefined,
                ContentType: undefined,
                Templates: undefined,
                Id: undefined,
                Links: undefined
            };
            const savedLibraryVariableSet = await repository.LibraryVariableSets.create(libraryVariableSet);
            this.setState({newVariableSetId: savedLibraryVariableSet.Id});
        });
    }
}

const AddVariableSetButton: React.SFC = () => {
    return <OpenDialogButton
            type={ActionButtonType.Primary}
            permission={{permission: Permission.LibraryVariableSetCreate}}
            label="Add new variable set">
        <AddVariableSetButtonDialogContent />
    </OpenDialogButton>;
};

export default AddVariableSetButton;