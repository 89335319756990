exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__3KLtI {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n}\n.style_separator__Ou1Nz {\n  margin: 0 0.425rem 0 0.125rem;\n}\n.style_error__35czb {\n  color: #db4437;\n  font-size: 0.75rem;\n  margin-top: 0.125rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__3KLtI",
	"separator": "style_separator__Ou1Nz",
	"error": "style_error__35czb"
};