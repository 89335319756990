import * as React from "react";
import {ActionPlugin, default as pluginRegistry, ActionEditProps} from "../Actions/pluginRegistry";

export interface ActionEditorProps extends ActionEditProps {
    isNew: boolean;
    localNames?: string[];
    projectId?: string;
    plugin: ActionPlugin;
    expandedByDefault: boolean;
}

export default class ActionEditor extends React.Component<ActionEditorProps, never> {
    componentDidMount() {
        const properties = {...this.props.properties};
        if (this.props.isNew) {

            // Enable any permanent or initial features
            if (this.props.plugin.features) {

                const enabledFeatures: any[] = [];

                if (this.props.plugin.features.permanent) {
                    this.props.plugin.features.permanent.forEach((feature) => {
                        const featurePlugin = pluginRegistry.getFeature(feature);
                        if (featurePlugin.enable) {
                            featurePlugin.enable(properties);
                        }
                        enabledFeatures.push(feature);
                    });
                }

                if (this.props.plugin.features.initial) {
                    this.props.plugin.features.initial.forEach((feature) => {
                        const featurePlugin = pluginRegistry.getFeature(feature);
                        if (featurePlugin.enable) {
                            featurePlugin.enable(properties);
                        }
                        enabledFeatures.push(feature);
                    });
                }

                properties["Octopus.Action.EnabledFeatures"] = enabledFeatures.join(",");
                this.props.setProperties(properties, true);
            }
        }
    }

    render() {
        if (!this.props.plugin.edit) {
            throw new Error(`Plugin ${this.props.plugin.actionType} is missing 'Edit' property`);
        }
        return <this.props.plugin.edit
            plugin={this.props.plugin}
            projectId={this.props.projectId}
            localNames={this.props.localNames}
            properties={this.props.properties}
            packages={this.props.packages}
            doBusyTask={this.props.doBusyTask}
            busy={this.props.busy}
            setProperties={this.props.setProperties}
            setPackages={this.props.setPackages}
            additionalActions={this.props.additionalActions}
            getFieldError={this.props.getFieldError}
            errors={this.props.errors}
            expandedByDefault={this.props.expandedByDefault}
            refreshRunOn={this.props.refreshRunOn}
        />;
    }
}
