import * as React from "react";
import {Tabs, Tab} from "material-ui/Tabs";
import {primaryText, primary} from "../../colors";
import {TabItemProps} from "components/Tabs/TabItem";
import * as URI from "urijs";
import ToolTip from "../ToolTip/index";
import * as cn from "classnames";

const styles = require("./style.less");

interface TabContainerProps {
    defaultValue: string; // The value of the tab you want selected by default.
}

interface TabContainerState {
    activeTabValue: string;
}

export default class TabContainer extends React.Component<TabContainerProps, TabContainerState> {
    constructor(props: TabContainerProps) {
        super(props);
        this.state = { activeTabValue: props.defaultValue };
    }
    getTabs(props = this.props): JSX.Element[] {
        const tabs: JSX.Element[] = [];

        React.Children.forEach(props.children, (tab) => {
            if (React.isValidElement(tab)) {
                tabs.push(tab);
            }
        });

        return tabs;
    }

    render() {
        const fullUrl = new URI(window.location);
        const relativeUrl = new URI(fullUrl.fragment());
        let activeTabValueFromUrl = null;
        relativeUrl.hasQuery("activeTab", (value: any) => {
            activeTabValueFromUrl = value;
        });

        const activeTabValue = activeTabValueFromUrl ? activeTabValueFromUrl : this.state.activeTabValue;

        const tabs = this.getTabs().map((tabItem, index) => {
            const tabItemProps: TabItemProps = tabItem.props;
            const warning = tabItemProps.warning;
            const tabValue = tabItemProps.value ? tabItemProps.value : index.toString();
            const label = warning ? <div>
                    <span>{tabItemProps.label}</span>
                    <ToolTip content={warning}><em className={cn("fa", "fa-warning", styles.tabWarningIcon)}/></ToolTip>&nbsp;
                </div> :
                <span>{tabItemProps.label}</span>;
            return <Tab label={label}
                style={{color: primaryText}}
                key={index}
                onActive={tabItemProps.onActive}
                value={tabValue}>
                {tabItemProps.children}
            </Tab>;
        });
        return <Tabs className={styles.tabContainer}
                     tabItemContainerStyle={{backgroundColor: "none"}}
                     inkBarStyle={{backgroundColor: primary}}
                     onChange={this.handleTabChange}
                     value={activeTabValue}
        >
            {tabs}
        </Tabs>;
    }

    private handleTabChange = (activeTabValue: string) => {
        const fullUrl = new URI(window.location);
        const relativeUrl = new URI(fullUrl.fragment());
        relativeUrl.setQuery("activeTab", activeTabValue);

        const newUrl = fullUrl.hash(relativeUrl.toString()).toString();

        // We don't want to perform a proper url change as this will trigger "You are navigating away" warning.
        window.history.pushState({path: newUrl}, "", newUrl);

        this.setState({activeTabValue});
    }
}