import * as React from "react";
import {repository} from "clientInstance";
import {
    ServerConfigurationSettingsSetResource,
    ServerConfigurationSettingsValueResource
} from "client/resources/serverConfigurationSettingsSetResource";
import {Section} from "components/Section/Section";
import {
    DataTable,
    DataTableBody,
    DataTableRowHeaderColumn,
    DataTableRow,
    DataTableRowColumn
} from "components/DataTable";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import routeLinks from "routeLinks";

interface ServerConfigurationSettingsState extends DataBaseComponentState {
    settings?: ServerConfigurationSettingsSetResource[];
}

export class ServerConfigurationSettings extends DataBaseComponent<{}, ServerConfigurationSettingsState> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const settings = await repository.ServerConfiguration.settings();
            this.setState({settings});
        });
    }

    render() {
        const valueMap = (value: ServerConfigurationSettingsValueResource) =>
            <DataTableRow key={value.Key}>
                <DataTableRowHeaderColumn>{value.Description}</DataTableRowHeaderColumn>
                <DataTableRowColumn>{value.Value}</DataTableRowColumn>
            </DataTableRow>;

        const setMap = (set: ServerConfigurationSettingsSetResource) =>
            <Section key={set.ConfigurationSet} sectionHeader={set.ConfigurationSet}>
                <DataTable>
                    <DataTableBody>
                        {set.ConfigurationValues.map(valueMap)}
                    </DataTableBody>
                </DataTable>
            </Section>;

        const body = this.state.settings &&
            <div>
                {this.state.settings.map(setMap)}
            </div>;

        return <PaperLayout title="Server Settings"
                            breadcrumbPath={routeLinks.configuration.nodes.root}
                            breadcrumbTitle="Nodes"
                            busy={this.state.busy}
                            errors={this.state.errors}>
            {body}
        </PaperLayout>;
    }
}