import * as React from "react";
import { WorkerPoolResource } from "client/resources";
import { repository } from "clientInstance";
import { RouteComponentProps } from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import { cloneDeep } from "lodash";
import Markdown from "components/Markdown";
import {
    Text,
    ExpandableFormSection,
    Summary,
    required,
    Checkbox,
} from "components/form";
import MarkdownEditor from "components/form/MarkdownEditor/MarkdownEditor";
import OverflowMenu from "components/Menu/OverflowMenu";
import InfrastructureLayout from "../../InfrastructureLayout";
import Permission from "client/resources/permission";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../../routeLinks";
import InternalRedirect from "components/Navigation/InternalRedirect";
import { PermissionCheck } from "components/PermissionCheck";
import { NavigationButton } from "components/Button";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface WorkerPoolRouteParams {
    workerPoolId: string;
}

interface WorkerPoolProps extends RouteComponentProps<WorkerPoolRouteParams> {
    create?: boolean;
}

interface WorkerPoolState extends OptionalFormBaseComponentState<WorkerPoolResource> {
    deleted: boolean;
    newId: string;
}

class WorkerPoolLayout extends FormBaseComponent<WorkerPoolProps, WorkerPoolState, WorkerPoolResource> {
    constructor(props: WorkerPoolProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const workerPoolPromise = repository.WorkerPools.get(this.props.match.params.workerPoolId);
            const workerPool = await workerPoolPromise;
            this.setState({
                model: workerPool,
                cleanModel: cloneDeep(workerPool)
            });
        });
    }

    descriptionSummary() {
        return this.state.model.Description ?
            Summary.summary(<Markdown markup={this.state.model.Description} />) :
            Summary.placeholder("No description provided");
    }

    render() {
        const title = this.props.create
            ? "Create worker pool"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(
                OverflowMenu.deleteItem(
                "Delete",
                "Are you sure you want to delete this worker pool?",
                this.handleDeleteConfirm,
                <div>
                    <p>Deleting this worker pool is permanent, there is no going back.</p>
                    <p>Do you wish to continue?</p>
                </div>,
                {permission: Permission.WorkerEdit}),
                [OverflowMenu.navItem("Audit Trail",
                    routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                        permission: Permission.EventView,
                        wildcard: true
                    })]
            );
        }

        const saveText: string = this.state.newId
            ? "Worker pool created"
            : "Worker pool details updated";

        return <InfrastructureLayout {...this.props}>
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"Worker pools"}
                breadcrumbPath={routeLinks.infrastructure.workerPools.root}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{permission: Permission.WorkerEdit}}
                onSaveClick={this.handleSaveClick}
                saveText={saveText}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions}
                secondaryAction={this.addWorkerButton()}
                >
                {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.workerPools.root} />}
                {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.workerPool(this.state.newId)} />}
                {this.state.model && <TransitionAnimation>

                    <ExpandableFormSection
                        errorKey="Name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your worker pool")}
                        help="A short, memorable, unique name for this worker pool. Example: Development.">
                        <Text
                            value={this.state.model.Name}
                            onChange={Name => this.setModelState({Name})}
                            label="Name"
                            validate={required("Please enter a worker pool name")}
                            autoFocus={true}
                        />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="IsDefault"
                        title="Default Worker Pool"
                        summary={this.state.model.IsDefault ? Summary.summary("Yes") : Summary.default("No")}
                        help={"Set this worker pool to be used as the default worker pool"}>
                        <Checkbox
                            value={this.state.model.IsDefault}
                            onChange={IsDefault => this.setModelState({IsDefault})}
                            label="Default"
                        />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="description"
                        title="Description"
                        summary={this.descriptionSummary()}
                        help="Enter a description for your worker pool.">
                        <MarkdownEditor
                            value={this.state.model.Description}
                            label="Worker pool description"
                            onChange={Description => this.setModelState({Description})}
                        />
                    </ExpandableFormSection>
                </TransitionAnimation>}
            </FormPaperLayout>
        </InfrastructureLayout>;
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.WorkerPools.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null
            });
        });
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.WorkerPools.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }

    private addWorkerButton() {
        return <PermissionCheck permission={Permission.WorkerEdit} >
            <NavigationButton href={routeLinks.infrastructure.workerMachines.new(this.state.model ? this.state.model.Id : null)} label="Add worker" />
        </PermissionCheck>;
    }
}

export default WorkerPoolLayout;