import * as React from "react";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import {LocationDescriptor} from "history";
import * as URI from "urijs";
import {white, primary, secondaryText, secondaryDark, success, disabledBackground} from "../../colors";
import { MouseEvent } from "react";
const styles = require("./style.less");
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";

interface NavigationButtonProps {
    label: string;
    titleAltText?: string;
    href: NavigationPath | string;
    icon?: JSX.Element;
    external?: boolean;
    disabled?: boolean;
    type?: NavigationButtonType;
}

interface NavigationPath {
    path: string;
    search: {[name: string]: string};
}

interface NavigationButtonState {
    navigate: boolean;
}

export enum NavigationButtonType {
    Primary,
    Secondary
}

export class NavigationButton extends React.Component<NavigationButtonProps, NavigationButtonState> {
    getPath(): LocationDescriptor {
        if (typeof this.props.href === "string") {
            return this.props.href;
        }

        const query = URI.buildQuery(this.props.href.search);
        return {
            pathname: this.props.href.path,
            search: `?${query}`
        };
    }

    handleOnClick = (e: MouseEvent<{}>): void => {
        e.preventDefault();
        this.setState({ navigate: true });
    }

    render() {
        if (this.state && this.state.navigate) {
            if (this.props.external) {
                this.setState({navigate: false});
                window.open(this.props.href as string);
            } else {
                return <InternalRedirect to={this.getPath()} push={true} />;
            }
        }

        switch (this.props.type) {

            case NavigationButtonType.Primary:
                const labelStylePrimary = {
                    fontSize: "0.8125rem",
                };
                return <RaisedButton
                    onClick={this.handleOnClick}
                    label={this.props.label}
                    title={this.props.titleAltText !== null ? this.props.titleAltText : this.props.label}
                    icon={this.props.icon}
                    disabled={this.props.disabled}
                    backgroundColor={success} // Primary's are the one thing that should stand out on the page.
                    labelColor={white}
                    labelStyle={labelStylePrimary}
                    disabledBackgroundColor={disabledBackground}
                    disabledLabelColor={white}
                    className={styles.globalActionButton}
                />;

            default:
                const labelStyle = {
                    color: this.props.disabled ? secondaryText : primary,
                    opacity: 1,
                    fontSize: "0.8125rem",
                };
                return <FlatButton
                    onClick={this.handleOnClick}
                    label={this.props.label}
                    title={this.props.titleAltText !== null ? this.props.titleAltText : this.props.label}
                    icon={this.props.icon}
                    labelStyle={labelStyle}
                    disabled={this.props.disabled}
                    className={styles.globalActionButton}
                />;
        }
    }
}

export default NavigationButton;
