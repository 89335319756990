import * as React from "react";
import ToolTip from "components/ToolTip";
import Onboarding from "./Onboarding";
import {DeploymentProcessResource, StartTrigger, TenantedDeploymentMode, WorkerPoolResource, TagSetResource, TagResource} from "client/resources";
import {client, repository} from "clientInstance";
import PaperLayout from "components/PaperLayout";
import {ProjectRouteParams} from "../ProjectLayout/ProjectLayout";
import SpecialVariables from "client/specialVariables";
import DeploymentPart from "./DeploymentPart";
import {DeploymentActionResource} from "client/resources/deploymentActionResource";
import Roles from "components/Actions/Roles";
import pluginRegistry from "components/Actions/pluginRegistry";
import {NavigationButton, NavigationButtonType} from "components/Button/NavigationButton";
import SideBar, {ScriptModule} from "./SideBar";
import {ProjectResource} from "client/resources/projectResource";
import {ActionButtonType} from "components/Button/ActionButton";
import ActionList from "components/ActionList/ActionList";
import DeploymentPartSorter from "./DeploymentPartSorter";
import OverflowMenu from "components/Menu/OverflowMenu";
import {DeploymentStepResource} from "client/resources/deploymentStepResource";
import SelectParentStep from "./SelectParentStep";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {VariableSetContentType} from "client/resources/libraryVariableSetResource";
import {EnvironmentResource} from "client/resources/environmentResource";
import {LifecycleResource} from "client/resources/lifecycleResource";
import {ResourcesById} from "client/repositories/basicRepository";
import {ChannelResource} from "client/resources/channelResource";
import {keyBy} from "lodash";
import * as tenantTagsets from "components/tenantTagsets";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import {Divider as MaterialDivider} from "material-ui";
import {divider} from "colors";
import SvgIconMaterial from "material-ui/SvgIcon";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import {projectStepsUpdated} from "../../reducers/projectsArea";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import routeLinks from "routeLinks";
import Permission from "client/resources/permission";
import PermissionCheck, {isAllowed} from "components/PermissionCheck/PermissionCheck";
import getActionLogoUrl from "../getActionLogoUrl";
const styles = require("./style.less");
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import CloneStep, { CloneStepTarget } from "./CloneStep";

const rollingStep = require("./step-rolling.svg");

interface DeploymentProcessOverviewState extends DataBaseComponentState {
    deploymentProcess?: DeploymentProcessResource;
    project: ProjectResource;
    redirectTo?: string;
    open: boolean;
    isLoaded: boolean;
    selectedParentStepId: string;
    includedScriptModules: ScriptModule[];
    lifecyclePreview: LifecycleResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
    channelsById?: ResourcesById<ChannelResource>;
    tagSets: TagSetResource[];
    wokerPoolsById: ResourcesById<WorkerPoolResource>;
}

interface DispatchProps {
    onProjectStepsUpdated(numberOfSteps: number): void;
}

type Props = RouteComponentProps<ProjectRouteParams> & DispatchProps;

const ParallelIcon = () => <SvgIconMaterial color="#9E9E9E" viewBox="0 0 24 24">
    <path d="M14.91466 15.012048V12L20 16l-5.08534 4v-3.012048H4v-1.975904M14.91466 7.012048V4L20 8l-5.08534 4V8.987952H4V7.012048" />
</SvgIconMaterial>;

const Divider = (props: { parallel: boolean }) => {
    if (!props.parallel) {
        return <MaterialDivider style={{ backgroundColor: divider, height: 1, width: "100%" }} />;
    }

    return <div className={styles.divider}>
        <MaterialDivider style={{ width: 20, backgroundColor: divider, height: 1 }} />
        <ToolTip content="run in parallel"><ParallelIcon /></ToolTip>
        <MaterialDivider style={{ backgroundColor: divider, height: 1, width: "100%" }} />
    </div>;
};

class DeploymentProcessOverviewInternal extends DataBaseComponent<Props, DeploymentProcessOverviewState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            deploymentProcess: null,
            redirectTo: null,
            project: null,
            open: false,
            isLoaded: false,
            selectedParentStepId: null,
            includedScriptModules: null,
            lifecyclePreview: null,
            tagSets: null,
            wokerPoolsById: null,
        };
    }

    async loadData() {
        const project = await repository.Projects.get(this.props.match.params.projectSlug);
        const [deploymentProcess, scriptModules, environmentsById, lifecycle, channels, tagSets, wokerPools] = await Promise.all([
            repository.DeploymentProcesses.get(project.DeploymentProcessId),
            isAllowed({permission: Permission.LibraryVariableSetView}) ? repository.LibraryVariableSets.all({ contentType: VariableSetContentType.ScriptModule }) : [],
            repository.Environments.allById(),
            isAllowed({permission: Permission.LifecycleView}) ? repository.Lifecycles.get(project.LifecycleId) : null,
            repository.Projects.getChannels(project),
            tenantTagsets.getAll(),
            repository.WorkerPools.all()
        ]);

        const lifecyclePreview = isAllowed({permission: Permission.LifecycleView}) ? await repository.Lifecycles.preview(lifecycle) : null;
        const channelsById = keyBy(channels.Items, ch => ch.Id);
        const wokerPoolsById = keyBy(wokerPools, wp => wp.Id);
        const includedScriptModules = scriptModules.filter(sm => project.IncludedLibraryVariableSetIds.includes(sm.Id));

        this.setState({
            deploymentProcess,
            environmentsById,
            project,
            includedScriptModules,
            lifecyclePreview,
            channelsById,
            tagSets,
            wokerPoolsById,
            isLoaded: true
        });
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.loadData());
    }

    render() {

        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const actions = [];
        if (this.state.isLoaded) {
            if (this.state.deploymentProcess.Steps && this.state.deploymentProcess.Steps.length > 1) {
                actions.push(this.reorderStepsButton());
            }
            actions.push(this.addStepButton());
            actions.push(<OverflowMenu menuItems={[
                OverflowMenu.downloadItem(
                    "Download as JSON",
                    this.state.project.Slug + "-process.json",
                    client.resolveLinkTemplate("DeploymentProcesses", {id: this.state.deploymentProcess.Id})),
                [OverflowMenu.navItem("Audit Trail",
                    routeLinks.configuration.eventsRegardingAny([this.state.deploymentProcess.Id]), null, {
                        permission: Permission.EventView,
                        wildcard: true
                    })]
                ]} />);
        }

        return <PaperLayout busy={this.state.busy}
            errors={this.state.errors}
            title="Process"
            sectionControl={this.state.isLoaded &&
                <ActionList actions={actions} />}>
            {this.state.isLoaded &&
                <SidebarLayout sideBar={<SideBar deploymentProcess={this.state.deploymentProcess}
                    includedScriptModules={this.state.includedScriptModules}
                    lifecyclePreview={this.state.lifecyclePreview}
                    environmentsById={this.state.environmentsById}
                    onDataChanged={() => this.refreshData()} />}>
                    <div>
                        {this.state.deploymentProcess.Steps.length === 0 && <Onboarding />}
                        <div className={styles.steps}>
                            {this.state.deploymentProcess.Steps.map((step, index) => step.Actions.length === 1 ?
                                this.buildAction(this.state.project, step, step.Actions[0], index + 1) :
                                this.buildStep(this.state.project, step, index + 1))}
                        </div>
                    </div>
                </SidebarLayout>}
        </PaperLayout>;
    }

    private reorderStepsButton() {
        return <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*" >
            <OpenDialogButton label="Reorder steps"
                type={ActionButtonType.Secondary}>
                <DeploymentPartSorter title="Reorder Steps"
                    deploymentProcessId={this.state.project.DeploymentProcessId}
                    saveDone={this.refreshData} />
            </OpenDialogButton>
        </PermissionCheck>;
    }

    private addStepButton() {
        return <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
            <NavigationButton type={NavigationButtonType.Primary} label="Add Step"
                href={routeLinks.project(this.state.project).steptemplates} />
        </PermissionCheck>;
    }

    private refreshData = async () => {
        await this.doBusyTask(() => this.loadData());
    }

    private buildStep(project: ProjectResource, step: DeploymentStepResource, stepIndex: number) {
        const allActionsOnThisStepAreDisabled = step.Actions && step.Actions.every(action => !!action.IsDisabled);
        return <div key={step.Id}>
            {stepIndex > 1 && <Divider parallel={step.StartTrigger === StartTrigger.StartWithPrevious} />}
            <DeploymentPart name={step.Name}
                logoUrl={rollingStep}
                index={stepIndex + "."}
                detailsUrl={this.calculateDetailsUrl(step.Id)}
                menuItems={this.getOverflowMenuItems(project, step) as any}
                isDisabled={allActionsOnThisStepAreDisabled}
                isPlaceholder={!step.Id}
                mainAction={
                <PermissionCheck permission={Permission.ProcessEdit} project={this.state.project.Id} tenant="*">
                    <NavigationButton type={NavigationButtonType.Secondary}
                        label="Add Child Step"
                        href={this.addChildStepUrl(step)} />
                </PermissionCheck>}>
                {step.Properties[SpecialVariables.Action.MaxParallelism] ? <span>Rolling deployment</span> :
                    <span>Multi-step deployment</span>}
                {" "}across deployment targets in <Roles rolesAsCSV={step.Properties[SpecialVariables.Action.TargetRoles] as string} />
            </DeploymentPart>
            <div className={styles.actions}>
                {step.Actions.map((action, index) => this.buildAction(project, step, action, index + 1, stepIndex))}
            </div>
        </div>;
    }

    private buildAction(project: ProjectResource, step: DeploymentStepResource, action: DeploymentActionResource, actionIndex: number, stepIndex?: number) {
        const isChildAction = !!stepIndex;

        const environmentsUserCanAccess = (environments: string[]) =>
            environments.filter(e => Object.keys(this.state.environmentsById).includes(e));

        return <div key={action.Id}>
            {(stepIndex || actionIndex > 1) && <Divider parallel={isChildAction === false && step.StartTrigger === StartTrigger.StartWithPrevious} />}
            <DeploymentPart name={action.Name}
                logoUrl={getActionLogoUrl(action)}
                environments={environmentsUserCanAccess(action.Environments).map(id => this.state.environmentsById[id])}
                excludedEnvironments={environmentsUserCanAccess(action.ExcludedEnvironments).map(id => this.state.environmentsById[id])}
                channelsLookup={action.Channels.map(id => ({Id: id, Channel: this.state.channelsById[id]}))}
                tags={this.getTags(action)}
                index={stepIndex ? `${stepIndex}.${actionIndex}.` : `${actionIndex}.`}
                isDisabled={action.IsDisabled}
                isPlaceholder={!action.Id}
                detailsUrl={this.calculateDetailsUrl(action.Id)}
                menuItems={this.getOverflowMenuItems(project, step, action, isChildAction) as any}>
                {pluginRegistry.getDeploymentAction(action.ActionType)
                    .summary(action.Properties, isChildAction ? null : step.Properties[SpecialVariables.Action.TargetRoles] as string, action.Packages, action.WorkerPoolId ? this.state.wokerPoolsById[action.WorkerPoolId].Name : null)}
            </DeploymentPart>
        </div>;
    }

    private getOverflowMenuItems(project: ProjectResource, step: DeploymentStepResource, action?: DeploymentActionResource, isChildAction?: boolean) {
        const menuItems = [];
        const processEditPermission = {permission: Permission.ProcessEdit, project: this.state.project.Id, tenant: "*"};
        const canEdit = isAllowed(processEditPermission);

        menuItems.push(OverflowMenu.navItem(canEdit ? "Edit" : "View Details", this.calculateDetailsUrl(action ? action.Id : step.Id)));

        if (!isChildAction) {
            if (!this.canHaveChildren(step)) {
                menuItems.push(OverflowMenu.disabledItem("Add child step", "This step type does not support child steps"));
            } else {
                menuItems.push(OverflowMenu.navItem("Add child step", this.addChildStepUrl(step), null, processEditPermission));
            }
        }

        if (action) {
            menuItems.push(OverflowMenu.item(
                action.IsDisabled ? "Enable" : "Disable",
                () => action.IsDisabled ? this.enable(action) : this.disable(action),
                processEditPermission));
        }

        if (!action && this.canHaveChildren(step)) {
            menuItems.push(OverflowMenu.item("Enable all", () => this.enableAll(step), processEditPermission));
            menuItems.push(OverflowMenu.item("Disable all", () => this.disableAll(step), processEditPermission));
        }

        if (isChildAction) {
            menuItems.push(OverflowMenu.item("Move out", () => this.moveOut(step, action), processEditPermission));
        }

        if (action) {
            if (!this.canMoveIn(step.Id)) {
                menuItems.push(OverflowMenu.disabledItem("Move into...", "No steps available that can have children or this step type cannot be a child step."));
            } else {
                const stepsToMoveInto = this.state.deploymentProcess.Steps.filter(s => s.Id !== step.Id && this.canHaveChildren(s));
                const selectParentStep = <SelectParentStep
                    steps={stepsToMoveInto}
                    actionName={action.Name}
                    currentlyTargetedRoles={step.Properties[SpecialVariables.Action.TargetRoles] as string}
                    onStepSelected={(parentStepId) => this.moveIn(step, action, parentStepId)} />;
                menuItems.push(OverflowMenu.dialogItem("Move into...", selectParentStep, processEditPermission));
            }
        }

        const cloneStep = <CloneStep
            actionName={action ? action.Name : step.Name}
            currentProjectId={project.Id}
            onCloneTargetSelected={async (cloneTarget, selectedProjectId) => await this.cloneStep(cloneTarget, step, action, selectedProjectId)} />;
        menuItems.push(OverflowMenu.dialogItem("Clone...", cloneStep, processEditPermission));

        if (action) {
            menuItems.push(OverflowMenu.deleteItemDefault("step", () => this.deleteStep(step, action), processEditPermission));
        } else {
            menuItems.push(OverflowMenu.deleteItemDefault("parent step", () => this.deleteStep(step), processEditPermission));
        }

        if (!action) {
            menuItems.push(OverflowMenu.dialogItem("Reorder child steps",
                <DeploymentPartSorter deploymentProcessId={this.state.project.DeploymentProcessId}
                    title="Reorder child steps"
                    stepId={step.Id}
                    saveDone={this.refreshData} />,
                processEditPermission),
            );
        }

        return menuItems;
    }

    private async deleteStep(step: DeploymentStepResource, action?: DeploymentActionResource) {
        let deleteStep = false;
        if (action) {
            const actionIndex = step.Actions.indexOf(action);
            step.Actions.splice(actionIndex, 1);
            deleteStep = step.Actions.length === 0;
        } else {
            deleteStep = true;
        }

        if (deleteStep) {
            const stepIndex = this.state.deploymentProcess.Steps.indexOf(step);
            this.state.deploymentProcess.Steps.splice(stepIndex, 1);
        } else {
            if (step.Actions.length === 1) {
                step.Name = step.Actions[0].Name;
            }
        }

        await this.saveChanges();
        return true;
    }

    private canHaveChildren(step: DeploymentStepResource) {
        return step.Actions.filter(a => {
            const actionDefinition = pluginRegistry.getDeploymentAction(a.ActionType);
            return actionDefinition.canHaveChildren(step);
        }).length > 0;
    }

    private disableAll(step: DeploymentStepResource) {
        step.Actions.forEach(a => a.IsDisabled = true);
        this.saveChanges();
    }

    private enableAll(step: DeploymentStepResource) {
        step.Actions.forEach(a => a.IsDisabled = false);
        this.saveChanges();
    }

    private newStepFromAction(step: DeploymentStepResource, action: DeploymentActionResource): DeploymentStepResource {
        return {
            Id: null as any,
            Name: action.Name,
            PackageRequirement: step.PackageRequirement,
            Properties: step.Properties,
            Condition: step.Condition,
            StartTrigger: step.StartTrigger,
            Actions: [
                action
            ]
        };
    }

    private moveOut(step: DeploymentStepResource, action: DeploymentActionResource) {
        const newStep = this.newStepFromAction(step, action);
        const name = newStep.Name;
        let i = 1;
        const isStep = (s: any) => s.Name === newStep.Name;

        while (true) {
            if (!this.state.deploymentProcess.Steps.some(isStep)) {
                break;
            }
            newStep.Name = name + " " + i;
            i++;
        }

        const actionIndex = step.Actions.indexOf(action);
        step.Actions.splice(actionIndex, 1);
        if (step.Actions.length === 1) {
            step.Name = step.Actions[0].Name;
        }

        const stepIndex = this.state.deploymentProcess.Steps.indexOf(step);
        this.state.deploymentProcess.Steps.splice(stepIndex + 1, 0, newStep);

        this.saveChanges();
    }

    private moveIn(step: DeploymentStepResource, action: DeploymentActionResource, parentStepId: string) {
        // Copy the action to the new step
        const newParent = this.state.deploymentProcess.Steps.find(s => s.Id === parentStepId);
        newParent.Actions.splice(newParent.Actions.length, 0, action);

        // Remove action from the old step
        const actionIndex = step.Actions.indexOf(action);
        step.Actions.splice(actionIndex, 1);

        // Remove parent step if no actions left
        if (step.Actions.length === 0) {
            const stepIndex = this.state.deploymentProcess.Steps.indexOf(step);
            this.state.deploymentProcess.Steps.splice(stepIndex, 1);
        }

        this.saveChanges();
    }

    private enable(action: DeploymentActionResource) {
        action.IsDisabled = false;
        this.saveChanges();
    }

    private disable(action: any) {
        action.IsDisabled = true;
        this.saveChanges();
    }

    private calculateDetailsUrl(id: string) {
        return routeLinks.project(this.state.project).process.step(id);
    }

    private canMoveIn(stepId: string) {
        const currentStep = this.state.deploymentProcess.Steps.find(step => step.Id === stepId);

        const isChildStep = this.canBeChildStep(currentStep);
        const hasParentStep = this.state.deploymentProcess.Steps.filter(step => step.Id !== stepId && this.canHaveChildren(step)).length > 0;

        return isChildStep && hasParentStep;
    }

    private canBeChildStep = (step: DeploymentStepResource) => step.Actions.every(action => pluginRegistry.getDeploymentAction(action.ActionType).canBeChild);

    private async cloneStep(cloneStepTarget: CloneStepTarget, step: DeploymentStepResource, action?: DeploymentActionResource, targetProjectId?: string) {

        const getDeploymentProcess = async (cloneTarget: CloneStepTarget, projectId: string) => {
            if (cloneTarget === CloneStepTarget.ThisProject) {
                return this.state.deploymentProcess;
            }
            const project = await repository.Projects.get(projectId);
            return await repository.DeploymentProcesses.get(project.DeploymentProcessId);
        };

        const nameOfActionExists = (steps: DeploymentStepResource[], actionName: string) => {
            return steps.filter(s => s.Name === actionName || (s.Actions.filter(a => a.Name === actionName).length > 0))
                .length > 0;
        };

        const getNewActionName = (steps: DeploymentStepResource[], clonedAction: DeploymentActionResource) => {
            let suffix = "";
            let counter = 1;
            while (nameOfActionExists(steps, clonedAction.Name + suffix)) {
                suffix = " - clone (" + counter + ")";
                counter++;
            }

            return clonedAction.Name + suffix;
        };

        const stepNameExists = (steps: DeploymentStepResource[], stepName: string) => {
            return steps.filter((s) => s.Name === stepName).length > 0;
        };

        const getNewStepName = (steps: DeploymentStepResource[], clonedStep: any) => {
            let suffix = "";
            let counter = 1;
            while (stepNameExists(steps, clonedStep.Name + suffix)) {
                suffix = " - clone (" + counter + ")";
                counter++;
            }

            return clonedStep.Name + suffix;
        };

        const deploymentProcess = await getDeploymentProcess(cloneStepTarget, targetProjectId);

        if (action && step.Actions.length > 1 && cloneStepTarget === CloneStepTarget.AnotherProject) {
            //its a child step (action) being cloned to a different project
            const newStep = this.newStepFromAction(step, action);
            newStep.Name = getNewStepName(deploymentProcess.Steps, newStep);
            newStep.Actions[0].Id = "";
            newStep.Actions[0].Name = getNewActionName(deploymentProcess.Steps, newStep.Actions[0]);
            newStep.Actions[0].Channels = [];
            deploymentProcess.Steps.splice(deploymentProcess.Steps.length, 0, newStep);
        } else if (action && step.Actions.length > 1) {
            //it's a child step being cloned within the same step
            const clonedAction = JSON.parse(JSON.stringify(action));
            clonedAction.Id = "";
            clonedAction.Name = getNewActionName(deploymentProcess.Steps, clonedAction);

            const actionIndex = step.Actions.indexOf(action);
            if (actionIndex === -1) {
                step.Actions.splice(step.Actions.length, 0, clonedAction);
            } else {
                step.Actions.splice(actionIndex + 1, 0, clonedAction);
            }
        } else {
            //its a step being cloned (either to the same project or a different project)
            const clonedStep = JSON.parse(JSON.stringify(step));
            clonedStep.Id = "";
            clonedStep.Name = getNewStepName(deploymentProcess.Steps, clonedStep);
            clonedStep.Actions.forEach((a: any, index: number) => {
                clonedStep.Actions[index].Id = "";
                clonedStep.Actions[index].Name = getNewActionName(deploymentProcess.Steps, clonedStep.Actions[index]);
                clonedStep.Actions[index].Channels = [];
            });

            const stepIndex = deploymentProcess.Steps.indexOf(step);
            if (stepIndex === -1) {
                deploymentProcess.Steps.splice(deploymentProcess.Steps.length, 0, clonedStep);
            } else {
                deploymentProcess.Steps.splice(stepIndex + 1, 0, clonedStep);
            }
        }

        if (cloneStepTarget === CloneStepTarget.ThisProject) {
            this.saveChanges();
        } else {
            await this.doBusyTask(async () => {
                await repository.DeploymentProcesses.modify(deploymentProcess);
            });
        }
    }

    private async saveChanges() {
        const success = await this.doBusyTask(async () => {
            const result = await repository.DeploymentProcesses.modify(this.state.deploymentProcess);
            this.props.onProjectStepsUpdated(result.Steps ? result.Steps.length : null);
            this.setState({ deploymentProcess: result });
            return true;
        });
        if (!success) {
            // since our changes mutate this.state.deploymentProcess then save, if we fail reload
            await this.doBusyTask(async () => {
                this.setState({ deploymentProcess: await repository.DeploymentProcesses.get(this.state.project.DeploymentProcessId) });
            }, false);
            return false;
        }
    }

    private getTags(action: DeploymentActionResource): string[] {
        if (this.state.project.TenantedDeploymentMode === TenantedDeploymentMode.Untenanted) {
            return [];
        }
        return action.TenantTags;
    }

    private addChildStepUrl(step: DeploymentStepResource) {
        return `${this.props.match.url}/childsteptemplates/${step.Id}`;
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        onProjectStepsUpdated: (numberOfSteps: number) => {
            dispatch(projectStepsUpdated(numberOfSteps));
        },
    };
};

const DeploymentProcessOverview = connect<void, DispatchProps, any>(
    null,
    mapDispatchToProps
)(DeploymentProcessOverviewInternal);

export default DeploymentProcessOverview;
