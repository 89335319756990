import * as React from "react";
import FormFieldProps from "components/form/FormFieldProps";
import Checkbox from "components/form/Checkbox/Checkbox";
import { withBoundField } from "components/form/BoundField/BoundField";

interface StringCheckBoxProps extends FormFieldProps<string> {
    trueValue?: string;
    falseValue?: string;
    title?: string | JSX.Element;
    note?: string | JSX.Element;
    disabled?: boolean;
    label?: string | JSX.Element;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
}

export default class StringCheckbox extends React.Component<StringCheckBoxProps, {}> {
    handleChange = (isChecked: boolean) => {
        const trueValue = this.props.trueValue || "True";
        const falseValue = this.props.falseValue || "False";
        const value = isChecked ? trueValue : falseValue;
        if (this.props.validate) {
            const validateResult = this.props.validate(value);
            this.props.onValidate(validateResult);
        }
        this.props.onChange(value);
    }

    render() {
        const {
            value,
            onChange,
            onValidate,
            validate,
            note,
            ...otherProps
        } = this.props;

        const val = (value || "").toLowerCase() === "true";

        return <Checkbox
            value={val}
            title={this.props.title}
            onChange={this.handleChange}
            note={this.props.note}
            {...otherProps}
        />;
    }
}

export const BoundStringCheckbox = withBoundField(StringCheckbox);