import * as React from "react";
import {client} from "clientInstance";
import BaseComponent from "components/BaseComponent";
import AreaTitle from "components/AreaTitle";
import routeLinks from "../../../routeLinks";

const styles = require("./style.less");

export default class TaskLayout extends BaseComponent<any, never> {
    render() {
        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.tasks.rootForSpace(client.spaceId)} title="Tasks" />
                <div className={styles.container}>
                    {this.props.children}
                </div>
            </main>);
    }
}