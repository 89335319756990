import * as React from "react";
import FormFieldProps from "../FormFieldProps";
const styles = require("./style.less");
import {DialogLayout} from "components/DialogLayout/DialogLayout";
import Dialog from "components/Dialog/Dialog";
import ActionButton from "components/Button/ActionButton";
import Text from "../Text/Text";

interface ColorPickerProps extends FormFieldProps<string> {
    defaultColor?: string;
    id?: string;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
}

interface ColorPickerState {
    error?: string;
    pickerOpen: boolean;
}

export default class ColorPicker  extends React.Component<ColorPickerProps, ColorPickerState> {
    constructor(props: ColorPickerProps) {
        super(props);
        this.state = {
            pickerOpen: false,
            error: null
        };
    }

    render() {
        const {
            id,
            label,
            validate,
            error,
            onChange,
            onValidate,
            defaultColor,
            value,
            warning,
        } = this.props;

        const err = this.state.error || error;
        const errorText =  err || warning;
        const val = value ? value : "";
        const colorSet1 = ["#983230", "#A77B22", "#227647", "#52818C", "#203A88", "#752BA5", "#6e6e6e"];
        const colorSet2 = ["#E8634F", "#ECAD3F", "#36A766", "#77B7C5", "#3156B3", "#9786A7", "#9d9d9d"];
        const cancel = <ActionButton key="CancelButton" label="Cancel" onClick={() => this.setState({pickerOpen: false})}/>;

        return (
            <div className={styles.container}>
                <Text
                    id={id}
                    hintText={defaultColor}
                    value={val}
                    onChange={this.handleChange}
                    error={errorText}
                    label={label}
                />
                <a className={styles.colorSwatch} style={{backgroundColor: val || defaultColor}} onClick={(e: any) => {
                    e.preventDefault();
                    this.setState({pickerOpen: true});
                }}/>
                <Dialog open={this.state.pickerOpen || false}>
                    <DialogLayout actions={[cancel]}
                                  title="Standard Palette"
                                  busy={null}
                                  closeDialog={() => this.setState({pickerOpen: false})}
                                  errors={null}>
                    <div>
                        <div className={styles.colorAllOptions}>
                            {colorSet1.map(this.renderPaletteSwatch)}
                        </div>
                        <div className={styles.colorAllOptions}>
                            {colorSet2.map(this.renderPaletteSwatch)}
                        </div>
                    </div>
                    </DialogLayout>
                </Dialog>
            </div>
        );
    }

    private handleChange = (value: string) => {
        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({error: result});
            if (this.props.onValidate) {
            this.props.onValidate(result);
            }
        }
        this.props.onChange(value);
    }

    private renderPaletteSwatch = (color: string) => {
        return <a key={color}
            className={styles.colorPickItem}
            style={{backgroundColor: color}}
            title={color}
            onClick={(e: any) => {
                e.preventDefault();
                this.handleChange(color);
                this.setState({pickerOpen: false});
            }}
        />;
    }
}