import * as React from "react";
import CustomDialog, { RenderProps } from "components/Dialog/CustomDialog";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import KeyBoardHandler, { Key } from "components/KeyboardHandler/KeyboardHandler";

interface BaseDialogLayoutProps<T> extends RenderProps {
    render: (props: BaseLayoutRenderProps) => React.ReactElement<any>;
}

type BaseLayoutRenderProps = RenderProps;

class BaseDialogLayout<T> extends React.Component<BaseDialogLayoutProps<T>> {
    constructor(props: BaseDialogLayoutProps<T>) {
        super(props);
    }

    render() {
        const {render, ...renderProps} = this.props;
        const keyboardRegistrations = [{key: Key.Esc, onKeyPressed: this.onEscKeyPressed.bind(this)}];

        return (<TransitionAnimation>
                <KeyBoardHandler registrations={keyboardRegistrations}>
                    {render(renderProps)};
                </KeyBoardHandler>
            </TransitionAnimation>
        );
    }

    onEscKeyPressed() {
        if (this.props.close) {
            this.props.close();
            return true;
        }
        return false;
    }
}

export default BaseDialogLayout;
export { BaseDialogLayout, BaseLayoutRenderProps, BaseDialogLayoutProps };
