import * as React from "react";
import { Note } from "components/form";
import { VariableLookupText } from "components/form/VariableLookupText";

interface MaxParallelismProps {
    projectId: string;
    value: string;
    onChange(newValue: string): void;
}

const MaxParallelism: React.StatelessComponent<MaxParallelismProps> = props =>
    <div>
        <VariableLookupText
            localNames={[]}
            projectId={props.projectId}
            value={props.value}
            onChange={props.onChange}
            label="Window size" />
        <Note>
            Octopus will execute the step as quickly as possible using this value as the maximum degree of parallelism.
        </Note>
    </div>;

export default MaxParallelism;