import * as React from "react";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import {StepChip} from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface StepReferenceDataItem extends ReferenceDataItem {
    suffix?: React.ReactNode;
}

interface StepMultiSelectProps extends FormFieldProps<string[]> {
    items: StepReferenceDataItem[];
    customLabel?: string;
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

const StepTypedMultiSelect = MultiSelect<StepReferenceDataItem>();

const StepMultiSelect: React.StatelessComponent<StepMultiSelectProps> = props => {
    const chipRenderer = (r: StepReferenceDataItem, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.Environment}
            chipRender={(item: StepReferenceDataItem) => <StepChip
                onRequestDelete={onRequestDelete}
                stepName={item.Name}
                suffix={item.suffix}
            />}
        />;
    };

    return <StepTypedMultiSelect
        items={props.items}
        fieldName="steps"
        renderChip={chipRenderer}
        {...props} />;
};

export default StepMultiSelect;