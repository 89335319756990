import * as React from "react";
const styles = require("./style.less");
import FormSectionHeading from "components/form/Sections/FormSectionHeading";

interface CodeGuideEntryProps {
    heading: string;
    context?: string;
}

export default class CodeGuideEntry extends React.Component<CodeGuideEntryProps, any> {
    render() {
        return <li className={styles.entry}  id={this.props.heading} >
            <FormSectionHeading title={this.props.heading} />
            {this.props.context && <span className={styles.context}>Context: {this.props.context}</span>}
            <div className={styles.content}>
                {this.props.children}
            </div>
        </li>;
    }
}