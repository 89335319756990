import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import ActionTemplateCard from "components/ActionTemplates/ActionTemplateCard";
import {NavigationButton, NavigationButtonType} from "components/Button/NavigationButton";
import matchesFilter from "components/ActionTemplates/matchesFilter";
import {Callout, CalloutType} from "components/Callout/Callout";
import {Permission} from "client/resources/permission";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import PageDivider from "components/PageDivider/PageDivider";
import ActionTemplateCardList from "components/ActionTemplates/ActionTemplateCardList";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";

interface InstalledActionTemplateListProps {
    templates: ActionTemplateSearchResource[];
    filter?: string;
    onPostSelectionUrlRequested?: (template: {Type: string, Id: string}) => string;
    onDetailsUrlRequested?: (template: ActionTemplateSearchResource) => string;
}

class InstalledActionTemplateList extends React.Component<InstalledActionTemplateListProps, never> {
    render() {

        const filteredResults = this.props.templates.filter(at => matchesFilter(at, this.props.filter));

        if (filteredResults.length === 0 && this.props.filter) {
            return <p>There are no installed step templates that match your filter criteria.</p>;
        }

        return <div>
                <PageDivider>Installed Step Templates</PageDivider>
                {!isAllowed({permission: Permission.ActionTemplateView}) &&
                <Callout type={CalloutType.Information}>
                    You can't see custom installed step templates because you don't have
                    <ExternalLink href="UserRoles"> {Permission.ActionTemplateView}</ExternalLink> permission.
                </Callout>}

                <ActionTemplateCardList>
                    {filteredResults.map(template => {
                        return <ActionTemplateCard key={template.Type + template.Id}
                                                   template={template}
                                                   primaryAction={<NavigationButton label="Add"
                                                                                    type={NavigationButtonType.Primary}
                                                                                    href={this.props.onPostSelectionUrlRequested(template)} />}
                                                   primaryRedirect={this.props.onPostSelectionUrlRequested(template)}
                                                   secondaryAction={template.HasUpdate &&
                                                                    <InternalLink size={0.75}
                                                                                  to={this.props.onDetailsUrlRequested(template)}>Update available</InternalLink>}
                        />;
                    })}
                </ActionTemplateCardList>
        </div>;
    }
}

export default InstalledActionTemplateList;