import * as React from "react";
import PaperLayout from "components/PaperLayout/PaperLayout";
import {ProjectRouteParams} from "../ProjectLayout/ProjectLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {repository} from "clientInstance";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import CommunityActionTemplateList from "components/ActionTemplates/CommunityActionTemplateList";
import InstalledActionTemplateList from "./InstalledActionTemplateList";
import pluginRegistry from "components/Actions/pluginRegistry";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
const styles = require("./style.less");
import {RouteComponentProps} from "react-router";
import routeLinks from "routeLinks";

interface ActionTemplateSelectorState extends DataBaseComponentState {
    isLoaded: boolean;
    templates: ActionTemplateSearchResource[];
    childActionTypes: string[];
    filterText?: string;
}

type ActionTemplateSelectorProps = RouteComponentProps<ProjectRouteParams & {parentStepId: string}>;

class ActionTemplateSelector extends DataBaseComponent<ActionTemplateSelectorProps, ActionTemplateSelectorState> {
    constructor(props: ActionTemplateSelectorProps) {
        super(props);

        this.state = {
            isLoaded: false,
            templates: [],
            childActionTypes: []
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async ()  => {
            const templates = await repository.ActionTemplates.search();
            const childActionTypes = pluginRegistry.getAllActions()
                                                    .filter(action => action.canBeChild)
                                                    .map(action => action.actionType);
            this.setState({isLoaded: true, templates, childActionTypes});
        });
    }

    render() {
        return <PaperLayout title="Choose Step Template"
                    breadcrumbTitle={"Process"}
                    breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).process.root}
                    busy={this.state.busy}
                    errors={this.state.errors}
                    fullWidth={true}
                    flatStyle={true}>
                    {this.state.isLoaded &&
                    <div>
                        <FilterSearchBox
                            hintText="Filter by name, category or description..."
                            onChange={(value) => this.setState({ filterText: value.toLowerCase()})}
                            fullWidth={true}
                            containerClassName={styles.filterSearchBoxContainer}
                            autoFocus={true}
                        />
                        <InstalledActionTemplateList templates={this.installedTemplates()}
                                                     onDetailsUrlRequested={this.getDetailsUrl}
                                                     onPostSelectionUrlRequested={this.getNewStepUrl}
                                                     filter={this.state.filterText} />
                        <CommunityActionTemplateList templates={this.state.templates.filter(at => !at.IsInstalled)}
                                            filter={this.state.filterText}
                                            installationActionName="Install and add"
                                            onDetailsUrlRequested={this.getDetailsUrl}
                                            onPostSelectionUrlRequested={this.getNewStepUrl} />
                    </div>
                }
                </PaperLayout>;
    }

    private installedTemplates() {
        const installed = this.state.templates.filter(at => at.IsInstalled);
        const parentStepId = this.props.match.params.parentStepId;

        if (!parentStepId) {
            return installed;
        }

        return installed.filter(at => this.state.childActionTypes.includes(at.Type));
    }

    private getDetailsUrl = (template: ActionTemplateSearchResource) => {
        return routeLinks.library.stepTemplates.communityTemplate(template, this.props.match.params.projectSlug).root;
    }

    private getNewStepUrl = (template: {Type: string, Id: string}) => {
        return routeLinks.project(this.props.match.params.projectSlug).process
            .stepNew(template.Type, this.props.match.params.parentStepId, template.Id);
    }
}

export default ActionTemplateSelector;