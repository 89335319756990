import * as React from "react";
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from "material-ui/Card";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {repository} from "clientInstance";
import {UpgradeConfigurationResource, UpgradeNotificationMode} from "client/resources/upgradeConfigurationResource";
import RadioButton from "components/form/RadioButton/RadioButton";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

interface UpdateNotificationsProps {
    onSaveDone(configuration: UpgradeConfigurationResource): void;
}

interface UpdateNotificationsState extends DataBaseComponentState {
    notificationMode?: UpgradeNotificationMode;
}

export class UpdateNotifications extends DataBaseComponent<UpdateNotificationsProps, UpdateNotificationsState> {
    constructor(props: UpdateNotificationsProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const configuration = await repository.UpgradeConfiguration.get();
            this.setState({notificationMode: configuration.NotificationMode});
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const configuration = await repository.UpgradeConfiguration.get();
            configuration.NotificationMode = this.state.notificationMode;
            await repository.UpgradeConfiguration.modify(configuration);
            this.props.onSaveDone(configuration);
        });
    }

    render() {
        const body = this.state.notificationMode &&
            <div>
                <p>This setting only affects the visibility of the update notification that is shown in the
                    top menu when Octopus updates are available.</p>
                <RadioButtonGroup
                    value={this.state.notificationMode}
                    onChange={(notificationMode: UpgradeNotificationMode) => this.setState({notificationMode})}
                    autoFocus>
                    <RadioButton value={UpgradeNotificationMode.AlwaysShow}
                                 label="Always show updates"/>
                    <RadioButton value={UpgradeNotificationMode.ShowOnlyMajorMinor}
                                 label="Only show major or minor version updates"/>
                    <RadioButton value={UpgradeNotificationMode.NeverShow}
                                 label="Never show updates"/>
                </RadioButtonGroup>
            </div>;

        return <SaveDialogLayout title="Upgrade Notifications"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            {body}
        </SaveDialogLayout>;
    }
}