import * as React from "react";
import Summary from "../../../../../components/form/Sections/Summary";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import Note from "../../../../../components/form/Note/Note";
import {BooleanRadioButtonGroup} from "../../../../../components/form/RadioButton/RadioButtonGroup";
import RadioButton from "../../../../../components/form/RadioButton/RadioButton";

interface PackageDeploymentOptionsProps {
    forcePackageRedeployment: boolean;
    onChange(value: boolean): void;
}

const PackageDeploymentOptions: React.StatelessComponent<PackageDeploymentOptionsProps> = props => {
    return  <ExpandableFormSection title="Package deployment" errorKey="packageDeployment"
                                          help="Choose whether to force re-deployment of packages"
                                          summary={buildSummary(props.forcePackageRedeployment)}>
        <div>
            <BooleanRadioButtonGroup value={props.forcePackageRedeployment} onChange={props.onChange}>
                <RadioButton value={false} label="Skip already installed packages" isDefault={true} />
                <RadioButton value={true} label="Re-deploy already installed packages" />
                <Note>
                    Packages with the same version already installed will be re-deployed regardless of whether the skip option is set in the project settings or project variables.
                </Note>
            </BooleanRadioButtonGroup>
        </div>
    </ExpandableFormSection >;

    function buildSummary(forcePackageRedeployment: boolean) {
        const summary = forcePackageRedeployment ? Summary.summary("Re-deploy already installed packages") :
            Summary.default("Skip already installed packages");
        return summary;
    }
};

export default PackageDeploymentOptions;