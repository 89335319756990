import OpenDialogButton from "../../../components/Dialog/OpenDialogButton";
import { isEqual } from "lodash";
import * as React from "react";
import AreaTitle from "components/AreaTitle";
import { repository } from "clientInstance";
import { TenantResource, ProjectResource, EnvironmentResource } from "client/resources";
import TenantCard from "../TenantCard/TenantCard";
import { FilterTextChip, EnvironmentChip, ProjectChip } from "../../../components/Chips/index";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import PermissionCheck, { hasPermission } from "../../../components/PermissionCheck/PermissionCheck";
import { Permission } from "../../../client/resources/permission";
import { ActionButtonType } from "../../../components/Button/ActionButton";
import AddTenantDialog from "areas/tenants/Tenants/AddTenantButton";
import PaperLayout from "components/PaperLayout/PaperLayout";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import { RouteComponentProps } from "react-router";
import Onboarding from "./Onboarding";
import * as tenantTagsets from "components/tenantTagsets";
import AdvancedFilterLayout from "components/AdvancedFilterLayout/AdvancedFilterLayout";
import { TagIndex } from "components/tenantTagsets";
import Tag from "components/Tag/Tag";
import * as MobileDetect from "mobile-detect";
const styles = require("./style.less");
import routeLinks from "../../../routeLinks";
import * as URI from "urijs";
import { AdvancedTenantTagsSelector } from "../../../components/AdvancedTenantSelector/AdvancedTenantSelector";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import LoadMoreCard from "components/LoadMoreCard/LoadMoreCard";
import { Select } from "components/form";

type TenantsProps = RouteComponentProps<any>;

interface TenantsState extends DataBaseComponentState {
    tenants: TenantResource[];
    missingVariables: { [tenantId: string]: boolean };
    searchTagMatches?: string[];
    openTenantFilters: boolean;
    filter: TenantsFilter;
    tagIndex: TagIndex;
    isDataLoaded: boolean;
    currentTakeSize: number;
    projects?: ProjectResource[];
    environments?: EnvironmentResource[];
}

interface TenantsFilter {
    searchText: string;
    selectedTags: string[];
    environment?: string;
    project?: string;
}

class FilterLayout extends AdvancedFilterLayout<TenantsFilter> { }

class Tenants extends DataBaseComponent<TenantsProps, TenantsState> {
    private pagingSize: number = 100;

    constructor(props: TenantsProps) {
        super(props);
        this.state = {
            openTenantFilters: false,
            filter: createDefaultFilter(),
            searchTagMatches: null,
            tenants: null,
            missingVariables: {},
            tagIndex: {},
            isDataLoaded: false,
            currentTakeSize: this.pagingSize,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const getTenants = repository.Tenants.all();
            const getMissingVariables = this.getMissingVariableIndex();
            const getTagIndex = tenantTagsets.getTagIndex();
            const getProjects = hasPermission(Permission.ProjectView) ? repository.Projects.all() : Promise.resolve<ProjectResource[]>([]);
            const getEnvironments = hasPermission(Permission.EnvironmentView) ? repository.Environments.all() : Promise.resolve<EnvironmentResource[]>([]);

            const [tenants, missingVariables, tagIndex, projects, environments] =
                await Promise.all([getTenants, getMissingVariables, getTagIndex, getProjects, getEnvironments]);

            this.setState({
                tenants,
                tagIndex,
                missingVariables,
                projects,
                environments,
                isDataLoaded: true,
                filter: this.filtersFromQueryString(this.props, this.state)
            }, async () => {
                await this.updateSearchTagMatches(this.state.filter.selectedTags);
            });
        });
    }

    async getMissingVariableIndex(): Promise<{ [tenantId: string]: boolean }> {
        const missingVariables = await repository.Tenants.missingVariables();
        return missingVariables.reduce((idx: any, missing) => {
            idx[missing.TenantId] = true;
            return idx;
        }, {});
    }

    async onUpdateFilter(filter: TenantsFilter) {
        const tenantsFilterChanged = !isEqual(filter.selectedTags, this.state.filter.selectedTags) ||
                                     !isEqual(filter.project, this.state.filter.project) ||
                                     !isEqual(filter.environment, this.state.filter.environment);
        if (tenantsFilterChanged) {
            await this.updateSearchTagMatches(filter.selectedTags);
        }
        this.setState({ filter }, () => this.queryStringFromFilters());
    }

    filtersFromQueryString = (props: TenantsProps, state: TenantsState): TenantsFilter => {
        const original = (new URI()).search(props.location.search).search(true);
        return {
            ...state.filter,
            searchText: original.search || "",
            selectedTags: ensureArray(original.tags) || [],
            environment: original.environment || "",
            project: original.project || "",
        };
    }

    componentWillReceiveProps(props: TenantsProps) {
        const newFilter = this.filtersFromQueryString(props, this.state);
        if (!isEqual(newFilter, this.state.filter)) {
            this.setState({ filter: newFilter });
        }
    }

    queryStringFromFilters = (): void => {
        const filter = this.state.filter;
        const search: any = {};
        if (filter.selectedTags.length > 0) { search.tags = filter.selectedTags; }
        if (filter.searchText !== "") { search.search = filter.searchText; }
        if (filter.project) { search.project = filter.project; }
        if (filter.environment) { search.environment = filter.environment; }

        const newQS = new URI().search(search).search();

        if (this.props.location.search !== newQS) {
            const location = { ...this.props.history, search: newQS };
            this.props.history.replace(location);
        }
    }

    async updateSearchTagMatches(selectedTags: string[]) {
        return this.doBusyTask(async () => {
            const searchTags = selectedTags.length === 0
                ? null
                : await repository.Tenants.tagTest([], selectedTags);

            let searchTagMatches = null;
            if (searchTags != null) {
                searchTagMatches = Object.keys(searchTags).filter(tenantId => searchTags[tenantId].IsMatched);
            }

            this.setState({ searchTagMatches });
        });
    }

    filteredTenants = (tenant: TenantResource) => {
        const filter = this.state.filter;
        const searchTagMatches = this.state.searchTagMatches;

        const searchTextMatches = (filter.searchText === "" || (filter.searchText !== "" && tenant.Name.toLowerCase().includes(filter.searchText.toLowerCase())));
        const tenantTagMatches = (!searchTagMatches || (!!searchTagMatches && searchTagMatches.indexOf(tenant.Id) !== -1));
        const environmentMatches = (filter.environment === "" || Object.keys(tenant.ProjectEnvironments).filter(projectId => tenant.ProjectEnvironments[projectId].includes(filter.environment)).length > 0);
        const projectMatches = (filter.project === "" || Object.keys(tenant.ProjectEnvironments).includes(filter.project));
        return searchTextMatches && tenantTagMatches && environmentMatches && projectMatches;
    }

    render() {
        const AddTenant = (props: { label: string }) => <PermissionCheck permission={Permission.TenantCreate}>
            <OpenDialogButton
                type={ActionButtonType.Primary}
                label={props.label}>
                <AddTenantDialog />
            </OpenDialogButton>
        </PermissionCheck>;

        const filterChanged = !isEqual(this.state.filter, createDefaultFilter());
        const matchCount = this.state.tenants && filterChanged &&
            this.state.tenants.filter(this.filteredTenants).length;
        const filterResult = filterChanged
            ? { numberOfMatches: matchCount, singleText: "tenant", pluralText: "tenants" }
            : null;
        // Disable autoFocus filtering for mobile (Android has issues and is annoying users).
        const md = new MobileDetect(window.navigator.userAgent);
        const autoFocus = md.isPhoneSized() ? false : true;
        return <main id="maincontent">
            <AreaTitle
                link={routeLinks.tenants}
                title="Tenants">
                <div className={styles.actionContainer}>
                    <AddTenant label="Add Tenant" />
                </div>
            </AreaTitle>
            <PaperLayout title={null}
                busy={this.state.busy}
                errors={this.state.errors}
                fullWidth={true}
                flatStyle={true}>
                {this.state.tenants && this.state.tenants.length === 0 && <Onboarding />}
                {this.state.isDataLoaded && this.state.tenants.length > 0 && <TransitionAnimation>
                    <FilterLayout
                        filter={this.state.filter}
                        defaultFilter={createDefaultFilter()}
                        hideDivider={true}
                        filterSections={[
                            {
                                render: <>
                                            <PermissionCheck permission={Permission.ProjectView} wildcard={true}>
                                                <Select value={this.state.filter.project}
                                                        onChange={project => this.onUpdateFilter({...this.state.filter, project})}
                                                        items={this.state.projects.map(p => ({value: p.Id, text: p.Name}))}
                                                        allowClear={true}
                                                        allowFilter={true}
                                                        fieldName="project"
                                                />
                                            </PermissionCheck>
                                            <PermissionCheck permission={Permission.EnvironmentView} wildcard={true}>
                                                <Select value={this.state.filter.environment}
                                                        onChange={environment => this.onUpdateFilter({...this.state.filter, environment})}
                                                        items={this.state.environments.map(e => ({value: e.Id, text: e.Name}))}
                                                        allowClear={true}
                                                        allowFilter={true}
                                                        fieldName="environment"
                                                />
                                            </PermissionCheck>
                                            <AdvancedTenantTagsSelector
                                                selectedTenantTags={this.state.filter.selectedTags}
                                                onChange={(selectedTags) => this.onUpdateFilter({ ...this.state.filter, selectedTags })}
                                                doBusyTask={this.doBusyTask}
                                                showPreviewButton={false}
                                            />
                                        </>
                            }
                        ]}
                        additionalHeaderFilters={[<FilterSearchBox
                            hintText="Filter by name..."
                            value={this.state.filter.searchText}
                            autoFocus={autoFocus}
                            onChange={(searchText) => this.onUpdateFilter({ ...this.state.filter, searchText })}
                        />]}
                        onFilterReset={filter => this.onUpdateFilter(filter)}
                        filterByChips={this.filterByChips()}
                        filterResult={filterResult}
                        renderContent={() => this.renderCards()}
                    />
                </TransitionAnimation>}
            </PaperLayout>
        </main>;
    }

    private renderCards() {
        if (!this.state.tenants) {
            return null;
        }

        const filteredTenants = this.state.tenants.filter(this.filteredTenants);
        const filteredTenantsPage = filteredTenants.slice(0, this.state.currentTakeSize);
        const cards = filteredTenantsPage.map((tenant, k) =>
            <TenantCard key={tenant.Id}
                tenant={tenant}
                hasMissingVariables={this.state.missingVariables[tenant.Id]}
                tagIndex={this.state.tagIndex} />);

        const showLoadMoreAction = this.state.currentTakeSize < filteredTenants.length;
        if (showLoadMoreAction) {
            cards.push(<LoadMoreCard key="lm" onLoadMore={() => {
                const newTakeSize = this.state.currentTakeSize + this.pagingSize;
                this.setState({ currentTakeSize: newTakeSize });
            }} onLoadAll={() => {
                const newTakeSize = repository.takeAll;
                this.setState({ currentTakeSize: newTakeSize });
            }} />);
        }

        return <div className={styles.cardListContainer}>
            {showLoadMoreAction && <div className={styles.loadMoreHeaderInfo}>
                {`Showing ${this.state.currentTakeSize} of ${filteredTenants.length} tenants.`}
            </div>}
            <div className={styles.cardList}>
                {cards}
            </div>
        </div>;
    }

    private filterByChips() {
        const groupedTagsets = tenantTagsets.groupByTagSet(this.state.filter.selectedTags);
        const tagsFilterByChips = groupedTagsets
            .map((group, index) => {
                return <div key={index}>
                    {group.tags
                        .map(t => this.state.tagIndex[t])
                        .map(t => <Tag
                            tagName={t.Name}
                            description={t.Description}
                            tagColor={t.Color}
                            key={t.Id}
                            small={false} />)}
                </div>;
            });

        const nameSearchChip = this.state.filter.searchText === "" ? null : <FilterTextChip key={"search-text"} filterText={this.state.filter.searchText} />;
        const environmentChip = this.state.filter.environment === "" ? null : <EnvironmentChip key={"search-environment"} environmentName={this.state.environments.find(x => x.Id === this.state.filter.environment).Name}/>;
        const projectChip = this.state.filter.project === "" ? null : <ProjectChip key={"search-project"} project={this.state.projects.find(x => x.Id === this.state.filter.project)} />;

        return (tagsFilterByChips.length || !!nameSearchChip || !!environmentChip || !!projectChip)
            && [nameSearchChip, tagsFilterByChips, environmentChip, projectChip];
    }
}

function createDefaultFilter(): TenantsFilter {
    return {
        searchText: "",
        selectedTags: [],
        environment: "",
        project: "",
    };
}

function ensureArray(obj: any) {
    if (!obj) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj;
    }
    return [obj];
}

export default Tenants;
