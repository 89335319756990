import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import AspNetCategory from "../../Definitions/AspNetCategoryDefinition";
import DotNetOtherCategory from "../../Definitions/DotNetOtherCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import { CommonManualPackagingTextForNuGet, CommonManualPackagingTextForZip } from "../CommonRegistrationText";

const ManualPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET applications manually, creating <i>NuGet</i> or <i>Zip</i> packages
            yourself is an easy way to get started.
        </p>
        {CommonManualPackagingTextForNuGet}
        {CommonManualPackagingTextForZip}
    </div>
);

export const ManualPackagingRegistrationForAspNet: CategorizedPackagingRegistration = {
    displayOrder: 100,
    categories: [AspNetCategory, DotNetOtherCategory],
    name: "Manually",
    type: "ManualForAspNet",
    renderCard: InfoRegistrationCards.basic(
        () => <ManualPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};