exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_codeEditorContainer__ZhfWq {\n  position: relative;\n  min-height: 15rem;\n  border: 0.0625rem solid #cccccc;\n}\n.styles_codeEditorContainerFullScreen__3zcZN {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: auto;\n  height: auto;\n  z-index: 999;\n  background: #fff;\n}\n.styles_enterFullScreen__3y4A- {\n  position: relative;\n  text-align: right;\n  float: right;\n  padding-right: 1.25rem;\n  z-index: 9;\n}\n.styles_exitFullScreen__1unSG {\n  text-align: right;\n  position: absolute;\n  z-index: 2;\n  top: 0;\n  right: 0;\n  left: 0;\n  padding: 0.5rem;\n}\n {\n  /* these need to not have their names mangled, as codemirror expects specific names */\n}\n.CodeMirror-foldmarker {\n  color: #333333;\n  text-shadow: #9e9e9e 1px 1px 2px, #9e9e9e -1px -1px 2px, #9e9e9e 1px -1px 2px, #9e9e9e -1px 1px 2px;\n  font-family: arial;\n  line-height: .3;\n  cursor: pointer;\n}\n.CodeMirror-foldgutter {\n  width: .7em;\n}\n.CodeMirror-foldgutter-open,\n.CodeMirror-foldgutter-folded {\n  cursor: pointer;\n}\n.CodeMirror-foldgutter-open:after {\n  content: \"\\25BE\";\n}\n.CodeMirror-foldgutter-folded:after {\n  content: \"\\25B8\";\n}\n", ""]);

// exports
exports.locals = {
	"codeEditorContainer": "styles_codeEditorContainer__ZhfWq",
	"codeEditorContainerFullScreen": "styles_codeEditorContainerFullScreen__3zcZN",
	"enterFullScreen": "styles_enterFullScreen__3y4A-",
	"exitFullScreen": "styles_exitFullScreen__1unSG"
};