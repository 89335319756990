import * as React from "react";
import MaterialDialog from "material-ui/Dialog";
import {SFC} from "react";
import { Provider } from "react-redux";
import store from "store";

const styles = require("./style.less");
import {dialogClass} from "uiTestClasses";

interface DialogProps {
    open?: boolean;
    wide?: boolean;
    repositionOnUpdate?: boolean;
    onRequestClose?: () => void;
}

const Dialog: SFC<DialogProps> = props => {

    const bodyStyle = {
        padding: "0",
        color: "inherit",
        fontSize: "inherit",
        display: "flex",
        overflow: "hidden"
    };

    const contentStyle = !props.wide ? {
        width: "75%",
        maxWidth: "550px"
    } : null;

    return (
        <MaterialDialog className={dialogClass}
                           open={props.open || false}
                           bodyStyle={bodyStyle as any}
                           contentStyle={contentStyle}
                           contentClassName={styles.materialDialogContent}
                           autoDetectWindowHeight={true}
                           repositionOnUpdate={props.repositionOnUpdate}>
            {/* Since we are relying on an old version of Material UI which isn't using a React Portal to manage things like Dialogs, we have to resort to setting up the redux context again*/}
            <Provider store={store}>
                {props.open && props.children}
            </Provider>
        </MaterialDialog>
    );
};

Dialog.displayName = "Dialog";

export default Dialog;