exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Registrations_codeContainer__10uri {\n  margin: 1rem 0;\n  color: #333333;\n}\n", ""]);

// exports
exports.locals = {
	"codeContainer": "Registrations_codeContainer__10uri"
};