import {Resource} from "./resource";
import {DeploymentResource} from "./deploymentResource";
import {TaskResource} from "./taskResource";
import {IPhasedResource} from "./phaseResource";

export interface LifecycleProgressionResource extends Resource {
    Phases: PhaseProgressionResource[];
    NextDeployments: string[];
    NextDeploymentsMinimumRequired: number;
}

export interface PhaseProgressionResource extends IPhasedResource {
    Blocked: boolean;
    Progress: PhaseProgress;
    Deployments: PhaseDeploymentResource[];
}

export enum PhaseProgress {
    Pending = "Pending",
    Current = "Current",
    Complete = "Complete"
}

export interface PhaseDeploymentResource {
    Task: TaskResource<any>;
    Deployment: DeploymentResource;
}

export default LifecycleProgressionResource;