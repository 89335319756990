import * as React from "react";
import {match} from "react-router";
import {ResourceCollection, PackageResource} from "client/resources";
import BaseComponent from "components/BaseComponent";
import InternalLink from "components/Navigation/InternalLink";
import PagingDataTable from "components/PagingDataTable";
import routeLinks from "../../../../routeLinks";
import {Permission} from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import {client} from "clientInstance";
const styles = require("./style.less");

class PackageResourceDataTable extends PagingDataTable<PackageResource> {}

interface LatestPackagesListProps {
    match: match<any>;
    initialPackages: ResourceCollection<PackageResource>;
}

export class LatestPackagesList extends BaseComponent<LatestPackagesListProps, any> {

    render() {
        return !!this.props.initialPackages &&
            <PackageResourceDataTable
                initialData={this.props.initialPackages}
                onRow={(item: any) => this.buildRow(item)}
                onFilter={this.filter}
                filterSearchEnabled={true}
                apiSearchParams={["filter"]}
                headerColumns={["ID", "Highest version", "Description"]}
                headerColumnClassNames={["", "", styles.descriptionColumn]}
                onEmpty={this.handleOnEmpty}
                />;
    }

    private handleOnEmpty = () => {
        return (
            <div>No packages found</div>
        );
    }

    private filter(filter: string, resource: PackageResource) {
        return !filter
            || filter.length === 0
            || (resource.PackageId ? resource.PackageId.toLowerCase().includes(filter.toLowerCase()) : false)
            || (resource.Title ? resource.Title.toLowerCase().includes(filter.toLowerCase()) : false);
    }

    private buildRow = (pkg: PackageResource) => {
        return [
            <InternalLink to={routeLinks.library.builtInRepository.versions(pkg.PackageId)}>
                {pkg.PackageId}
            </InternalLink>,
            <div className={styles.row}>
                <PermissionCheck permission={Permission.BuiltInFeedDownload} project="*">
                    <a href={client.resolve(pkg.Links["Raw"])}>
                        <em className="fa fa-download" />
                    </a>&nbsp;
                </PermissionCheck>
                <InternalLink to={routeLinks.library.builtInRepository.package(pkg.Id)}>{pkg.Version}</InternalLink>
            </div>,
            pkg.Description
        ];
    }

}

export default LatestPackagesList;
