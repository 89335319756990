import NamedResource, {NewNamedResource} from "./namedResource";

interface CommonTriggerResource {
    ProjectId: string;
    IsDisabled: boolean;
    Filter: TriggerFilterResource;
    Action: TriggerActionResource;
}

export interface TriggerResource extends NamedResource, CommonTriggerResource {
}

export interface NewTriggerResource extends NewNamedResource, CommonTriggerResource {
}

export interface TriggerResourceTyped<F extends TriggerFilterResource, A extends TriggerActionResource> extends TriggerResource {
    Filter: F;
    Action: A;
}

export interface NewTriggerResourceTyped<F extends TriggerFilterResource, A extends TriggerActionResource> extends NewTriggerResource {
    Filter: F;
    Action: A;
}

export abstract class TriggerFilterResource {
    FilterType: TriggerFilterType;
}

export abstract class TriggerActionResource {
    ActionType: TriggerActionType;
}

export class MachineFilterResource extends TriggerFilterResource {
    EnvironmentIds: string[];
    Roles: string[];
    EventGroups: string[];
    EventCategories: string[];

    constructor() {
        super();
        this.FilterType = TriggerFilterType.MachineFilter;
    }
}

export class AutoDeployActionResource extends TriggerActionResource {
    ShouldRedeployWhenMachineHasBeenDeployedTo: boolean;

    constructor() {
        super();
        this.ActionType = TriggerActionType.AutoDeploy;
    }
}

export enum TriggerFilterType {
    MachineFilter = "MachineFilter",
    DailySchedule = "DailySchedule",
    DaysPerWeekSchedule = "DaysPerWeekSchedule",
    DaysPerMonthSchedule = "DaysPerMonthSchedule",
    CronExpressionSchedule = "CronExpressionSchedule"
}

export enum TriggerActionType {
    AutoDeploy = "AutoDeploy",
    DeployLatestRelease = "DeployLatestRelease",
    DeployNewRelease = "DeployNewRelease"
}

export function isExistingTriggerResource(resource: TriggerResource | NewTriggerResource): resource is TriggerResource {
    return (resource as TriggerResource).Links !== undefined;
}

export default TriggerResource;