import * as React from "react";
import {Text} from "components/form";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {ServerConfigurationResource} from "client/resources/serverConfigurationResource";
import {repository} from "clientInstance";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

interface EditServerConfigurationProps {
    onSaveDone(node: ServerConfigurationResource): void;
}

interface EditServerConfigurationState extends DataBaseComponentState {
    loaded: boolean;
    serverUri?: string;
}

export class EditServerConfiguration extends DataBaseComponent<EditServerConfigurationProps, EditServerConfigurationState> {
    constructor(props: EditServerConfigurationProps) {
        super(props);
        this.state = {loaded: false};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const configuration = await repository.ServerConfiguration.get();
            this.setState({serverUri: configuration.ServerUri, loaded: true});
        });
    }

    async save() {
        return this.doBusyTask(async () => {
            const configuration = await repository.ServerConfiguration.get();
            configuration.ServerUri = this.state.serverUri;
            await repository.ServerConfiguration.modify(configuration);
            this.props.onSaveDone(configuration);
        });
    }

    render() {
        const body = this.state.loaded &&
            <Text label="Server Uri"
                  hintText="The publicly-accessible url of this server."
                  value={this.state.serverUri}
                  onChange={serverUri => this.setState({serverUri})}
                  autoFocus={true}
                  type="url"/>;

        return <SaveDialogLayout title="Edit Server Configuration"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            {body}
        </SaveDialogLayout>;
    }
}