import * as React from "react";
import { default as ProjectLayout, ProjectRouteParams } from "areas/projects/components/ProjectLayout/ProjectLayout";
import { RouteComponentProps } from "react-router";
import ActionTemplateParameterList from "components/ActionTemplateParametersList/ActionTemplateParametersList";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import { repository } from "clientInstance";
import { OptionalFormBaseComponentState } from "components/FormBaseComponent/FormBaseComponent";
import { Permission } from "client/resources";
import ProjectResource from "client/resources/projectResource";
import { FormBaseComponent } from "components/FormBaseComponent";
import { Section } from "components/Section/Section";
import ExternalLink from "components/Navigation/ExternalLink";
import { ControlType } from "../../../../../client/resources";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import SectionNote from "components/SectionNote/SectionNote";

export default class ProjectVariableTemplates extends FormBaseComponent<RouteComponentProps<ProjectRouteParams>, OptionalFormBaseComponentState<ProjectResource>, ProjectResource> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.doBusyTask(() => this.loadProject());
    }

    render() {
        return <FormPaperLayout
            title={"Project Variable Templates"}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={async () => await this.save()}>
            <SectionNote>
                Project variable templates define which variable values are required for each tenant connected to this project.
                They allow tenants to provide different variable values for each project/environment combination.
                    Learn more about <ExternalLink href="MultiTenantVariables">tenant-specific variables</ExternalLink>.
                </SectionNote>
            {this.state.model && <TransitionAnimation>
                <Section>
                    <ActionTemplateParameterList
                        parameters={this.state.model ? this.state.model.Templates : []}
                        name="template"
                        editPermission={{
                            permission: Permission.VariableEdit,
                            project: this.state.model.Id,
                            wildcard: true
                        }}
                        excludedControlTypes={[ControlType.StepName]}
                        onParametersChanged={Templates => this.setState({
                            model: {
                                ...this.state.model,
                                Templates
                            }
                        })}
                    />
                </Section></TransitionAnimation>}
        </FormPaperLayout>;
    }

    private async loadProject() {
        const project = await repository.Projects.get(this.props.match.params.projectSlug);
        this.setModel(project);
    }

    private save() {
        return this.doBusyTask(async () => {
            await repository.Projects.modify(this.state.model);
            await this.loadProject();
        });
    }
}
