exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_outOfFlowContainer__1RV3b {\n  position: fixed;\n  top: -10000px;\n  left: -10000px;\n  visibility: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"outOfFlowContainer": "style_outOfFlowContainer__1RV3b"
};