import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import { VariableLookupCodeEditor } from "../../CodeEditor/CodeEditor";
import Note from "../../form/Note/Note";
import { ScriptingLanguage } from "../../scriptingLanguage";
import ScriptingLanguageSelector from "../../ScriptingLanguageSelector/ScriptingLanguageSelector";
import ActionProperties from "client/resources/actionProperties";
import { CardFill } from "../../form/Sections/ExpandableFormSection";

type ScriptType = "PreDeploy" | "Deploy" | "PostDeploy";

interface Script {
    syntax: ScriptingLanguage;
    scriptBody: string;
}

type ScriptLanguageIndex<K extends string> = { [key in K]: ScriptingLanguage };
interface ActionEditState extends ScriptLanguageIndex<ScriptType> {}

class CustomScriptsEdit extends BaseComponent<ActionEditProps, ActionEditState> {
    syntaxExtensions = { [ScriptingLanguage.PowerShell]: "ps1", [ScriptingLanguage.CSharp]: "csx", [ScriptingLanguage.Bash]: "sh", [ScriptingLanguage.FSharp]: "fsx", [ScriptingLanguage.Python]: "py" };
    extensionSyntaxes = { ps1: ScriptingLanguage.PowerShell, csx: ScriptingLanguage.CSharp, sh: ScriptingLanguage.Bash, fsx: ScriptingLanguage.FSharp, py: ScriptingLanguage.Python };
    customScriptPrefix = "Octopus.Action.CustomScripts.";

    constructor(props: ActionEditProps) {
        super(props);
        this.state = {
            PreDeploy: ScriptingLanguage.PowerShell,
            Deploy: ScriptingLanguage.PowerShell,
            PostDeploy: ScriptingLanguage.PowerShell
        };
    }

    componentDidMount() {
        const PreDeploy = this.lookupScriptSyntax("PreDeploy");
        const Deploy = this.lookupScriptSyntax("Deploy");
        const PostDeploy = this.lookupScriptSyntax("PostDeploy");

        this.setState({
            PreDeploy,
            Deploy,
            PostDeploy
        });
    }

    lookupScriptSyntax(scriptType: ScriptType): ScriptingLanguage {
        // find the script variable, for Deploy it will look like Octopus.Action.CustomScripts.Deploy.{ps1|sh|csx|fsx}
        let syntax = ScriptingLanguage.PowerShell;
        Object.keys(this.extensionSyntaxes).forEach(k => {
            const name = this.customScriptPrefix + scriptType + "." + k;
            if (this.props.properties.hasOwnProperty(name)) {
                syntax = (this.extensionSyntaxes as any)[k];
            }
        });
        return syntax;
    }

    syntaxChanged(scriptType: ScriptType, syntax: ScriptingLanguage, scriptBody: string) {
        // if changing from say ps1 to sh we need to delete Octopus.Action.CustomScripts.Deploy.ps1 and add Octopus.Action.CustomScripts.Deploy.sh
        const props = this.props.properties;
        Object.keys(this.extensionSyntaxes).forEach(k => {
            const name = this.customScriptPrefix + scriptType + "." + k;
            if ((this.extensionSyntaxes as any)[k] !== syntax && this.props.properties.hasOwnProperty(name)) {
                delete props[name];
            }
        });
        props[this.customScriptPrefix + scriptType + "." + this.syntaxExtensions[syntax]] = scriptBody;
        this.props.setProperties(props);

        this.setState((prev) => ({ ...prev, [scriptType]: syntax }));
    }

    renderScriptSection(scriptType: ScriptType) {
        const syntax = this.state[scriptType];
        const scriptBody = this.getScriptBody(scriptType);
        return <div>
            <ScriptingLanguageSelector value={syntax as ScriptingLanguage}
                onChange={value => this.syntaxChanged(scriptType, value as ScriptingLanguage, scriptBody)} />
            <VariableLookupCodeEditor
                localNames={this.props.localNames}
                projectId={this.props.projectId}
                syntax={syntax}
                value={scriptBody}
                language={syntax}
                allowFullScreen={true}
                onChange={(x) => this.props.setProperties({ [this.customScriptPrefix + scriptType + "." + this.syntaxExtensions[syntax]]: x })} />
        </div>;
    }

    getScriptBody(scriptType: ScriptType) {
        return this.props.properties[this.customScriptPrefix + scriptType + "." + this.syntaxExtensions[this.state[scriptType]]] as string;
    }

    summary() {
        const preDeploy = this.getScriptBody("PreDeploy");
        const deploy = this.getScriptBody("Deploy");
        const postDeploy = this.getScriptBody("PostDeploy");

        const configured = [];
        if (preDeploy) {
            configured.push("Pre-deploy");
        }
        if (deploy) {
            configured.push("Deploy");
        }
        if (postDeploy) {
            configured.push("Post-deploy");
        }
        if (configured.length === 0) {
            return Summary.placeholder("No scripts have been configured");
        }
        if (configured.length === 1) {
            return Summary.summary("A script will be run as part of the " + configured[0] + " phase");
        }
        if (configured.length === 2) {
            return Summary.summary("Scripts will be run as part of the " + configured[0] + " and " + configured[1] + " phases");
        }
        if (configured.length === 3) {
            return Summary.summary("Scripts will be run as part of the " + configured[0] + ", " + configured[1] + " and " + configured[2] + " phases");
        }
    }

    render() {
        return <ExpandableFormSection
            errorKey="customScripts"
            isExpandedByDefault={this.props.expandedByDefault}
            title="Custom Deployment Scripts"
            fillCardWidth={CardFill.FillRight}
            summary={this.summary()}
            help="Configure scripts to run as part of this step.">
            <strong>Pre-deployment script</strong>
            <Note>This script will run after the package is extracted, but before any configuration changes are made.</Note>
            {this.renderScriptSection("PreDeploy")}
            <br />
            <strong>Deployment script</strong>
            <Note>This script will run after configuration changes and variable substitutions are made, but before any of the core deployment processes are made.</Note>
            {this.renderScriptSection("Deploy")}
            <br />
            <strong>Post-deployment script</strong>
            <Note>This script will run after  core deployment processes are made.</Note>
            {this.renderScriptSection("PostDeploy")}
        </ExpandableFormSection>;
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.CustomScripts",
    title: "Custom Deployment Scripts",
    description: "Execute scripts during the deployment, without embedding them in your package",
    edit: CustomScriptsEdit,
    priority: 10,
    disable: (properties: ActionProperties) => {
        Object.keys(properties).filter((name) => {
            return name.indexOf("Octopus.Action.CustomScripts.") === 0;
        }).forEach((name) => {
            delete properties[name];
        });
    },
});
