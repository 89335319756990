import * as React from "react";
import SortableList from "components/SortableList";
import {PhaseResource} from "client/resources";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import {Errors} from "components/DataBaseComponent/DataBaseComponent";

interface SortPhasesProps {
    phases: PhaseResource[];

    onPhasesSorted(sortedPhases: PhaseResource[]): void;
}

interface SortPhasesState {
    sortedPhases: PhaseResource[];
    errors: Errors;
    busy: Promise<void>;
}

export default class SortPhases extends React.Component<SortPhasesProps, SortPhasesState> {
    constructor(props: SortPhasesProps) {
        super(props);
        this.state = {
            sortedPhases: props.phases,
            errors: null,
            busy: null
        };
    }

    render() {
        return <OkDialogLayout title="Reorder Phases"
                               busy={this.state.busy}
                               errors={this.state.errors}
                               onOkClick={() => this.props.onPhasesSorted(this.state.sortedPhases)}>
            <SortableList items={this.state.sortedPhases}
                          onOrderChanged={(orderedItems: any) => this.setState({sortedPhases: orderedItems})}/>
        </OkDialogLayout>;
    }
}
