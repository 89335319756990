import * as React from "react";
import {TenantedDeploymentMode} from "../../../../../../client/resources/tenantedDeploymentMode";
import {Callout, CalloutType} from "../../../../../../components/Callout/Callout";
import Note from "../../../../../../components/form/Note/Note";
import {ChannelResource} from "../../../../../../client/resources/channelResource";
import {TenantResource} from "../../../../../../client/resources/tenantResource";
import {ChipIcon, EnvironmentChip, LookupTenantChip, TenantChip} from "../../../../../../components/Chips/index";
import {LookupResourceChipComponent} from "../../../../../../components/LookupResourceChip/LookupResourceChip";
import ExternalLink from "../../../../../../components/Navigation/ExternalLink/ExternalLink";
import {DeploymentPromotionTarget} from "../../../../../../client/resources/deploymentTemplateResource";

interface HelpTextProps {
    doAnyTenantsMatchProject: boolean;
    doAnyTenantsMatchChannel: boolean;
    tenantedDeploymentMode: TenantedDeploymentMode;
    channel: ChannelResource;
    availableTenants: TenantResource[];
    availableEnvironmentsForTenantPromotion: DeploymentPromotionTarget[];
    tenantsWithMissingVariables: string[];
    allTenants: TenantResource[];
}
const HelpText: React.StatelessComponent<HelpTextProps> = (props) => {
    if (!props.doAnyTenantsMatchProject) {
        if (props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted) {
            return <Callout type={CalloutType.Danger} title="There are no tenants connected to this project.">
                <p>Connect at least one tenant to this project to start performing tenanted deployments of this
                    project. To do this, find the tenant, and connect it to this project targeting the desired
                    environments.
                </p>
            </Callout>;
        } else if (props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            return <Note>There are no tenants connected to this project. Connect at least one tenant to this project
                to start performing tenanted deployments of this
                project. To do this, find the tenant, and connect it to this project targeting the desired
                environments.
            </Note>;
        }
    }

    if (!props.doAnyTenantsMatchChannel && props.channel.TenantTags.length !== 0) {
        const content = <p>Releases in the {props.channel.Name} channel can only be deployed to matching
            tenants. Either change the tenant filter on the channel, tag an existing tenant so they match the channel
            filter, or create a new tenant tagged to match the channel filter.</p>;
        if (props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted) {
            return <Callout type={CalloutType.Danger}
                            title={`There are no matching tenants for the ${props.channel.Name} channel.`}>
                {content}
            </Callout>;
        } else if (props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            return <Note>
                <p>There are no matching tenants for the {props.channel.Name} channel.</p>
                {content}
            </Note>;
        }
    }

    if (props.availableTenants.length === 0) {
        if (props.tenantedDeploymentMode === TenantedDeploymentMode.Tenanted) {
            return <Callout type={CalloutType.Danger}
                            title="Cannot deploy to any of the connected tenants in this phase of the lifecycle">
                <p>Releases of this project can only be deployed to tenants, but there are no tenants
                    connected to any environments in the current lifecycle phase.</p>
                {props.availableEnvironmentsForTenantPromotion && props.availableEnvironmentsForTenantPromotion.length > 0 &&
                <div>Available environments are
                    {props.availableEnvironmentsForTenantPromotion.map((env: DeploymentPromotionTarget) => <EnvironmentChip
                        environmentName={env.Name} key={env.Id}/>)}
                    Either connect an existing tenant to one of the environments in this lifecycle
                    phase,
                    or create a new tenant and connect them to one of the environments in this lifecycle
                    phase,
                    or modify the lifecycle, or configure the project to allow untenanted deployments.
                </div>}
            </Callout >;
        } else if (props.tenantedDeploymentMode === TenantedDeploymentMode.TenantedOrUntenanted) {
            return <Note>
                Cannot deploy to any of the connected tenants in this phase of the lifecycle.
                Either perform an untenanted deployment of this release to an environment in this lifecycle
                phase,
                or connect an existing tenant to one of the environments in this lifecycle phase,
                or create a new tenant and connect them to one of the environments in this lifecycle phase,
                or modify the lifecycle.
            </Note>;
        }
    }

    if (props.tenantsWithMissingVariables.length > 0) {

        return <Callout type={CalloutType.Danger} title="Tenants with missing variables">
            {props.tenantsWithMissingVariables.map(tId => {
                return <LookupTenantChip lookupTenants={props.allTenants} id={tId}/>;
            })}
            <p>Some of the selected tenants are missing variable values required for this deployment to
                succeed.
                Either provide a value for the tenant or configure a default value for the variable
                template.
                See <ExternalLink href="MultiTenantVariables" label="Multi tenant variables">Multi tenant
                    variables</ExternalLink> for more information.</p>
        </Callout>;
    }
    return null;
};

export default HelpText;