import * as moment from "moment";
import { Moment } from "moment";

export default function combineDateTime(date: Moment, time: Moment) {
    const combine = moment(date).utcOffset(moment().utcOffset());
    if (time) {
        const t = moment(time);
        combine.hour(t.hour());
        combine.minute(t.minute());
    }
    return combine;
}