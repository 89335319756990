import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../../Actions/pluginRegistry";
import {BaseComponent} from "../../BaseComponent/BaseComponent";
import {KubernetesCustomResourceComponent, KubernetesCustomResourceProps} from "components/Actions/kubernetes/kubernetesCustomResourceComponent";
import {DataBaseComponentState} from "components/DataBaseComponent";

class KubernetesCustomResourceFeature extends BaseComponent<ActionEditProps<KubernetesCustomResourceProps>, DataBaseComponentState> {
    render() {
        return <KubernetesCustomResourceComponent
            properties={this.props.properties}
            packages={this.props.packages}
            plugin={this.props.plugin}
            getFieldError={this.props.getFieldError}
            setProperties={this.props.setProperties}
            setPackages={this.props.setPackages}
            doBusyTask={this.props.doBusyTask}
            busy={this.props.busy}
            errors={this.props.errors}
            projectId={this.props.projectId}
            expandedByDefault={true}/>;
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesCustomResource",
    title: "Custom Resource",
    description: "Create Kubernetes resources from raw YAML",
    edit: KubernetesCustomResourceFeature,
    priority: 20
});