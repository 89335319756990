exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_formExpander__2bL6B,\n.style_formNonExpander__1Gaxi {\n  width: 100%;\n  box-shadow: none!important;\n  border-radius: 0!important;\n  border-width: 0!important;\n  margin: 0 0 -0.0625rem 0;\n  background-color: inherit !important;\n}\n.style_formExpander__2bL6B > div:first-child,\n.style_formNonExpander__1Gaxi > div:first-child {\n  padding: 0!important;\n}\n.style_formExpander__2bL6B > div:first-child > div:first-child,\n.style_formNonExpander__1Gaxi > div:first-child > div:first-child {\n  padding: 0!important;\n}\n.style_formExpander__2bL6B > div:first-child > div:first-child {\n  cursor: pointer;\n}\n.style_cardMedia__xmmZy {\n  margin: 0;\n  padding: 0;\n}\n", ""]);

// exports
exports.locals = {
	"formExpander": "style_formExpander__2bL6B",
	"formNonExpander": "style_formNonExpander__1Gaxi",
	"cardMedia": "style_cardMedia__xmmZy"
};