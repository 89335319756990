import * as React from "react";
import { DataTable } from "components/DataTable/DataTable";
import { DataTableBody } from "components/DataTable/DataTableBody";
import { DataTableRow } from "components/DataTable/DataTableRow";
import { DataTableRowColumn } from "components/DataTable/DataTableRowColumn";
import { DataTableHeader } from "components/DataTable/DataTableHeader";
import { DataTableHeaderColumn } from "components/DataTable/DataTableHeaderColumn";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import {CertificateUsageEntry} from "./certificateUsageSummary";
import {CertificateUsageResource} from "client/resources";

interface Props {
    certificateUsage: CertificateUsageEntry[];
}

const CertificateUsage = (props: Props) => {
    return props.certificateUsage && <div>
        {props.certificateUsage.length === 0 && <p>This certificate is not used anywhere</p>}
        {props.certificateUsage.length > 0 &&
        <DataTable>
            <DataTableHeader>
                <DataTableRow>
                    <DataTableHeaderColumn>Source</DataTableHeaderColumn>
                    <DataTableHeaderColumn>Type</DataTableHeaderColumn>
                </DataTableRow>
            </DataTableHeader>
            <DataTableBody>
            {props.certificateUsage.map((usage: CertificateUsageEntry) =>
                <DataTableRow key={usage.ownerName}>
                    <DataTableRowColumn><InternalLink to={usage.ownerLink}>{usage.ownerName}</InternalLink></DataTableRowColumn>
                    <DataTableRowColumn>{usage.ownerType}</DataTableRowColumn>
                </DataTableRow>
            )}
            </DataTableBody>
        </DataTable>
        }
        </div>;
};

export default CertificateUsage;