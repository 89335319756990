import * as React from "react";
import FocusListItem from "../FocusListItem/FocustListItem";
import { List as VirtualList, AutoSizer, CellMeasurerCache, CellMeasurer } from "react-virtualized";
const styles = require("./VirtualListWithKeyboard.less");
import { FocusableComponent } from "./FocusableComponent";
import { SelectItem } from "./SelectItem";
import {baseSizeInPx} from "fontWeights";
import * as _ from "lodash";

export interface Item {
    primaryText: React.ReactNode;
    secondaryText?: React.ReactNode;
    secondaryTextLines?: 1 | 2;
    leftIcon?: JSX.Element;
    containerElement?: JSX.Element;
}

interface VirtualListWithKeyboardProps<T extends SelectItem> {
    items: ReadonlyArray<T>;
    maxHeightInRem?: number;
    empty?: React.ReactNode;
    renderItem: (item: T) => Item;
    onSelected: (id: string) => void;
    containerElement?: (item: T) => JSX.Element;
    // The list will always render twice when it is first shown.
    // The height of the list during the first render could be calculated incorrectly since it doesn't know the item sizes yet
    // The second time it actually gets the height of the container correct, based on the measured item sizes from the first render
    // `onResized` here is called on the second render, in case the parent needs to respond to the size change
    onResized: () => void;
    addNewTemplate?: () => string;
    onBlur?: () => void;
    multiSelectRef?(component: FocusableComponent | null): void;
}

interface VirtualListWithKeyboardState {
    focusedRow: number;
    containerHeight: number;
}

export default function VirtualListWithKeyboard<T extends SelectItem>() {
    class VirtualListWithKeyboardInternal extends React.Component<VirtualListWithKeyboardProps<T>, VirtualListWithKeyboardState> {

        public static defaultProps: Partial<VirtualListWithKeyboardProps<T>> = {
            maxHeightInRem: 25
        };

        static defaultRowSize = 48;
        private readonly cellMeasurerCache: CellMeasurerCache;
        private virtualList: VirtualList;
        private unmounted = false;

        constructor(props: VirtualListWithKeyboardProps<T>) {
            super(props);

            this.state = {
                focusedRow: -1,
                containerHeight: VirtualListWithKeyboardInternal.defaultRowSize
            };

            this.cellMeasurerCache = new CellMeasurerCache({
                minHeight: VirtualListWithKeyboardInternal.defaultRowSize,
                defaultHeight: VirtualListWithKeyboardInternal.defaultRowSize,
                fixedWidth: true,
                keyMapper: (rowIndex: number) => this.props.items[rowIndex].Id
            });
        }

        focus() {
            if (this.props.items.length > 0) {
                this.virtualList.scrollToRow(0);
                this.setState({
                    focusedRow: 0
                });
            }
        }

        componentDidMount() {
            if (this.props.multiSelectRef) {
                this.props.multiSelectRef(this);
            }
        }

        componentWillUnmount() {
            this.unmounted = true;
            if (this.props.multiSelectRef) {
                this.props.multiSelectRef(null);
            }
        }

        render() {
            //clear the measurement cache as the VirtualList doesn't know the underlying data may have changed
            this.cellMeasurerCache.clearAll();
            setTimeout(() => this.adjustContainerHeight(), 0);
            return <div onKeyDown={(e) => this.onListKeyDown(e, this.props.items)}>
                {this.props.items.length === 0 && <div className={styles.empty}>
                    {this.props.empty || "No results found"}
                </div>}
                <div className={styles.menuContainer}>
                    <AutoSizer disableHeight={true}>
                        {({ width }) =>
                            <VirtualList
                                ref={el => this.virtualList = el}
                                tabIndex={-1}
                                height={this.state.containerHeight}
                                rowCount={this.props.items.length}
                                estimatedRowSize={VirtualListWithKeyboardInternal.defaultRowSize}
                                deferredMeasurementCache={this.cellMeasurerCache}
                                rowHeight={this.rowHeight}
                                rowRenderer={({ index, key, isVisible, style, parent }) =>
                                    this.renderRow(
                                        this.props.items,
                                        key,
                                        index,
                                        isVisible,
                                        style,
                                        parent
                                    )}
                                width={width}
                            />}
                    </AutoSizer>
                </div>
            </div>;
        }

        private adjustContainerHeight() {
            if (this.unmounted || this.props.items.length === 0) {
                return;
            }

            let height = VirtualListWithKeyboardInternal.defaultRowSize * this.props.items.length;
            const maxHeightInPx = baseSizeInPx * this.props.maxHeightInRem;
            if (height > maxHeightInPx) {
                height = maxHeightInPx;
            } else {
                height = 0;
                // Measure the first 10 to get a more accurate height
                for (let index = 0; index < this.props.items.length; index++) {
                    height += this.cellMeasurerCache.rowHeight({ index });

                    if (height > maxHeightInPx) {
                        height = maxHeightInPx;
                        break;
                    }
                }
            }

            if (this.state.containerHeight === height) {
                return;
            }

            this.setState({ containerHeight: height });
            this.props.onResized();
        }

        private onListKeyDown = (event: any, filteredList: ReadonlyArray<T>) => {
            if (event.key === "ArrowDown" || (!event.shiftKey && event.key === "Tab")) {
                const lastIndex = filteredList.length - 1;
                this.setState((prevState) => {
                    return { focusedRow: prevState.focusedRow <= lastIndex ? prevState.focusedRow + 1 : lastIndex };
                });
                event.preventDefault();
            }

            if (event.key === "ArrowUp" || (event.shiftKey && event.key === "Tab")) {

                if (this.state.focusedRow === 0) {
                    if (this.props.onBlur) {
                        this.props.onBlur();
                    }
                    this.setState({ focusedRow: -1 });
                } else {
                    this.setState((prevState) => {
                        return { focusedRow: prevState.focusedRow > 0 ? prevState.focusedRow - 1 : 0 };
                    });
                }
                event.preventDefault();
            }
        }

        private rowHeight = (params: { index: number }) => {
            return this.cellMeasurerCache.rowHeight(params);
        }

        private renderRow = (filteredList: ReadonlyArray<T>, key: string, index: number, isVisible: boolean, style: React.CSSProperties, parent: any) => {
            const item = filteredList[index];

            let primaryText: React.ReactNode = null;
            let secondaryText: React.ReactNode = null;
            let secondaryTextLines: 1 | 2 = 1;
            let leftIcon: JSX.Element = null;
            if (item.Id) {
                const result = this.props.renderItem(item);
                primaryText = result.primaryText;
                secondaryText = result.secondaryText;
                secondaryTextLines = result.secondaryTextLines;
                leftIcon = result.leftIcon;
            } else {
                primaryText = this.props.addNewTemplate();
            }

            return <CellMeasurer
                cache={this.cellMeasurerCache}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                <span className={styles.menuItem} style={style}>
                    {/*Wrapping in a span to avoid animations resulting from styles on `ListItem` */}
                    {/*when the cell measurer moves the cell into view*/}
                    <FocusListItem
                        isFocused={index === this.state.focusedRow}
                        containerElement={this.props.containerElement && this.props.containerElement(item)}
                        primaryText={primaryText}
                        secondaryText={secondaryText}
                        secondaryTextLines={secondaryTextLines}
                        leftIcon={leftIcon}
                        onRequestClose={() => {
                            this.props.onSelected(item.Id);
                        }} />
                </span>
            </CellMeasurer>;
        }
    }

    return VirtualListWithKeyboardInternal;
}
