import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import NodeJsCategory from "../../Definitions/NodeJsCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import {
    CommonBuildServerPackagingTextForOctopackJs,
    CommonBuildServerPackagingTextForIntegration,
    CommonBuildServerPackagingTextForOctoExe,
} from "../CommonRegistrationText";

const BuildServerPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications from build servers, we recommend using <i>Octopack.js</i>.
            Alternatively you can use <code>Octo.exe pack</code> on the output directory..
        </p>
        {CommonBuildServerPackagingTextForOctopackJs}
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export const BuildServerPackagingRegistrationForNodeJs: CategorizedPackagingRegistration = {
    displayOrder: 300,
    categories: [NodeJsCategory],
    name: "Build Server",
    type: "BuildServerForNodeJs",
    renderCard: InfoRegistrationCards.basic(
        () => <BuildServerPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};