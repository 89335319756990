import * as React from "react";
import { RouteComponentProps } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "../../../../../components/DataBaseComponent/DataBaseComponent";
import ProjectLayout, {ProjectRouteParams} from "../../ProjectLayout/ProjectLayout";
import Task from "../../../../tasks/components/Task/Task";
import { repository } from "../../../../../clientInstance";
import PermissionCheck from "../../../../../components/PermissionCheck/PermissionCheck";
import { Permission, TaskResource, DeploymentResource, ProjectResource, ReleaseResource } from "client/resources";
import { NavigationButton, NavigationButtonType } from "../../../../../components/Button/NavigationButton";
import {DeployButton} from "../DeployButton";
import {LifecycleProgressionResource} from "../../../../../client/resources/lifecycleProgressionResource";
import {EnvironmentResource} from "../../../../../client/resources/environmentResource";
import {ResourcesById} from "../../../../../client/repositories/basicRepository";
import TaskHistory from "../../../../tasks/components/Task/TaskHistory/TaskHistory";
import routeLinks from "../../../../../routeLinks";
import PaperLayout from "components/PaperLayout/PaperLayout";

interface DeploymentDetailsState extends DataBaseComponentState {
    project?: ProjectResource;
    deployment?: DeploymentResource;
    release?: ReleaseResource;
    progression?: LifecycleProgressionResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}

type DeploymentDetailsProps = RouteComponentProps<ProjectRouteParams & {deploymentId: string}>;

export class DeploymentDetails extends DataBaseComponent<DeploymentDetailsProps, DeploymentDetailsState> {

    constructor(props: DeploymentDetailsProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            const projectAsync = repository.Projects.get(this.props.match.params.projectSlug);
            const deployment = await repository.Deployments.get(this.props.match.params.deploymentId);
            const release = await repository.Releases.get(deployment.ReleaseId);
            this.setState({ deployment, release, project: await projectAsync });
        });
    }

    renderAdditionalActions = (task: TaskResource<any>) => {
        const actions = [];

        if (task.IsCompleted && !task.FinishedSuccessfully) {
            const tryAgainLink = routeLinks.project(this.state.project).release(this.state.release).deployments.retry(this.state.deployment.Id);
            actions.push(<PermissionCheck permission={Permission.DeploymentCreate}
                                          project={this.state.deployment.ProjectId}
                                          environment={this.state.deployment.EnvironmentId}
                                          tenant="*">
                <NavigationButton label="Try again..."
                                  href={tryAgainLink}
                                  type={NavigationButtonType.Primary}/>
            </PermissionCheck>);
        }

        if (task.FinishedSuccessfully && this.state.progression && this.state.project) {
            actions.push(<DeployButton releaseVersion={this.state.release.Version}
                                       projectSlug={this.state.project.Slug}
                                       projectId={this.state.project.Id}
                                       nextDeployments={this.state.progression.NextDeployments}
                                       environmentsById={this.state.environmentsById}
                                       tenantedDeploymentMode={this.state.project.TenantedDeploymentMode}
            />);
        }

        return actions;
    }

    additionalRefresh = async (task: TaskResource<any>) => {
        if (task.FinishedSuccessfully && !this.state.progression) {
            const progression = repository.Releases.progression(this.state.release);
            const environmentsById = repository.Environments.allById();
            this.setState({progression: await progression, environmentsById: await environmentsById});
        }
    }

    render() {
        const deployment = this.state.deployment;

        if (deployment) {

            const deployments = <div>
            <TaskHistory doBusyTask={this.doBusyTask}
                         header={<h4>Previous Deployments</h4>}
                         currentDeploymentId={this.props.match.params.deploymentId}
                         projectIds={[deployment.ProjectId]}
                         tenantIds={[deployment.TenantId]}
                         environmentIds={[deployment.EnvironmentId]}/>
            </div>;

            return <Task
                taskId={deployment.TaskId}
                delayRender={() => false}
                projectId={this.state.project.Id}
                environmentId={deployment.EnvironmentId}
                tenantId={deployment.TenantId}
                additionalSidebar={deployments}
                additionalActions={this.renderAdditionalActions}
                additionalRefresh={this.additionalRefresh}/>;
        }

        return <PaperLayout busy={this.state.busy} title="Task" errors={this.state.errors} />;
    }
}