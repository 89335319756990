exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_key__3W_uh {\n  font-weight: 600;\n}\n.style_row__34tqF {\n  padding-bottom: 1rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"key": "style_key__3W_uh",
	"row": "style_row__34tqF"
};