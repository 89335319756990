import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import OtherCategory from "../../Definitions/OtherCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration } from "../CommonRegistrationText";

const BuildServerPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging other applications from build servers, we recommend using <i>Octo.exe</i>.
        </p>
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export const BuildServerPackagingRegistrationForOther: CategorizedPackagingRegistration = {
    displayOrder: 300,
    categories: [OtherCategory],
    name: "Build Server",
    type: "BuildServerForOther",
    renderCard: InfoRegistrationCards.basic(
        () => <BuildServerPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};