import * as React from "react";
import {ActivityLogElement} from "client/resources/taskDetailsResource";
import {isEqual} from "lodash";
import TaskLogLine from "./TaskLogLine";

interface TaskLogLineChunkProps {
    lines: ActivityLogElement[];

    showAdditional(): void;
}

export default class TaskLogLineChunk extends React.Component<TaskLogLineChunkProps> {
    constructor(props: TaskLogLineChunkProps) {
        super(props);
    }

    shouldComponentUpdate(nextProps: TaskLogLineChunkProps) {
        return this.props.lines.length !== nextProps.lines.length ||
            !isEqual(this.props.lines, nextProps.lines);
    }

    render() {
        return <div>
                {this.props.lines.map(
                    (l, n) => <TaskLogLine key={n.toString()}
                                              line={l}
                                              showAdditional={this.props.showAdditional}/>
                )}
            </div>;
    }

}