exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_actionsContainer__1nT_A {\n  display: flex;\n  flex-direction: column;\n  text-align: right;\n  flex: 0 1 auto;\n}\n.style_alignRightContainer__1ScWG {\n  display: flex;\n  flex-direction: row;\n  margin-right: 0.5rem !important;\n}\n.style_spacer__1YrOf {\n  flex: 1 0 auto;\n}\n", ""]);

// exports
exports.locals = {
	"actionsContainer": "style_actionsContainer__1nT_A",
	"alignRightContainer": "style_alignRightContainer__1ScWG",
	"spacer": "style_spacer__1YrOf"
};