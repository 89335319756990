exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_description__3LtxT {\n  margin-left: 2.5rem;\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n  font-size: 0.875rem;\n}\n", ""]);

// exports
exports.locals = {
	"description": "style_description__3LtxT"
};