import "./iis/iisAction";
import "./script/scriptAction";
import "./healthCheck/healthCheckAction";
import "./tentaclePackage/tentaclePackageAction";
import "./windowsService/windowsServiceAction";
import "./manual/manualAction";
import "./email/emailAction";
import "./deployRelease/deployReleaseAction";
import "./azureCloudService/azureCloudServiceAction";
import "./azurePowerShell/azurePowerShellAction";
import "./azureResourceGroup/azureResourceGroupAction";
import "./azureServiceFabricApp/azureServiceFabricAppAction";
import "./azureServiceFabricPowerShell/azureServiceFabricPowerShellAction";
import "./azureWebApp/azureWebAppAction";
import "./certificateImport/certificateImportAction";
import "./dockerNetwork/dockerNetworkAction";
import "./dockerRun/dockerRunAction";
import "./dockerStop/dockerStopAction";
import "./helmChartUpgrade/helmChartUpgradeAction";
import "./transferPackage/transferPackageAction";
import "./wildfly/deployToWildflyAction";
import "./wildfly/changeStateInWildflyAction";
import "./wildfly/deployToWildflyFeature";
import "./wildfly/deployCertificate";
import "./tomcat/deployToTomcatAction";
import "./tomcat/changeStateInTomcatAction";
import "./tomcat/deployToTomcatFeature";
import "./tomcat/deployCertificate";
import "./javaPackage/javaPackageAction";
import "./javaKeystore/javaKeystoreAction";
import "./kubernetes/kubernetesRunScriptAction";
import "./kubernetes/kubernetesDeployContainersAction";
import "./kubernetes/kubernetesServiceAction";
import "./kubernetes/kubernetesIngressAction";
import "./kubernetes/kubernetesConfigMapAction";
import "./kubernetes/kubernetesSecretAction";
import "./kubernetes/kubernetesRawYamlAction";
import "./aws/register";
import "./terraform/terraformApplyAction";
import "./terraform/terraformDestroyAction";
import "./terraform/terraformPlanAction";
import "./terraform/terraformPlanDestroyAction";
import "./vhd/vhdAction";
import "./nginx/nginxAction";
import "../Features";