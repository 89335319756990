exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_busyIndicator__1Ka4K div:first-child {\n  background-color: #cccccc;\n}\n.style_busyIndicatorHiddenSpacer__3iMs6 {\n  height: 0.25rem;\n}\n.style_circularBusyIndicator__15KpB div:first-child {\n  vertical-align: middle;\n  background-color: transparent;\n}\n", ""]);

// exports
exports.locals = {
	"busyIndicator": "style_busyIndicator__1Ka4K",
	"busyIndicatorHiddenSpacer": "style_busyIndicatorHiddenSpacer__3iMs6",
	"circularBusyIndicator": "style_circularBusyIndicator__15KpB"
};