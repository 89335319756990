import * as React from "react";
import {ProxyResource} from "client/resources";
import {repository} from "clientInstance";
import {Redirect} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent";
import {cloneDeep} from "lodash";
import {
    Text,
    ExpandableFormSection,
    Summary,
    required,
    Sensitive
} from "components/form";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
import ParseHelper from "utils/ParseHelper";
import {Callout, CalloutType} from "components/Callout";
import OverflowMenu from "components/Menu/OverflowMenu";
import Permission from "client/resources/permission";
const styles = require("./style.less");
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface ProxyProps extends RouteComponentProps<ProxyRouteParams> {
    create?: boolean;
}

interface ProxyRouteParams {
    proxyId: string;
}

interface ProxyState extends OptionalFormBaseComponentState<ProxyResource> {
    deleted: boolean;
    newId: string;
}

class ProxyLayout extends FormBaseComponent<ProxyProps, ProxyState, ProxyResource> {
    constructor(props: ProxyProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null
        };
    }

    async componentDidMount() {
        let newProxy: ProxyResource = null;
        let proxyPromise: any = null;
        if (this.props.create) {
            newProxy = {
                Id: null as string,
                ProxyType: "HTTP",
                Name: "",
                Host: "",
                Port: 80,
                Username: "",
                Password: {
                    HasValue: false,
                    NewValue: null,
                },
                Links: null
            };
        } else {
            proxyPromise = repository.Proxies.get(this.props.match.params.proxyId);
        }

        await this.doBusyTask(async () => {
            const proxy = await proxyPromise;
            this.setState({
                model: newProxy || proxy,
                cleanModel: cloneDeep(newProxy || proxy)
            });
        });
    }

    render() {
        const title = this.props.create
            ? "Create Proxy"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenu.deleteItemDefault("proxy", this.handleDeleteConfirm, {permission: Permission.ProxyDelete}));
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
            routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                permission: Permission.EventView,
                wildcard: true
            })]);
        }

        const saveText: string = this.state.newId
            ? "Proxy created"
            : "Proxy details updated";

        return <InfrastructureLayout {...this.props}>
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"Proxies"}
                breadcrumbPath={routeLinks.infrastructure.proxies.root}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{permission: this.props.create ? Permission.ProxyCreate : Permission.ProxyEdit}}
                onSaveClick={this.handleSaveClick}
                saveText={saveText}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions}
            >
                {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.proxies.root}/>}
                {this.state.newId && <InternalRedirect to={routeLinks.infrastructure.proxy(this.state.newId)}/>}
                {this.state.model && <TransitionAnimation>

                    <ExpandableFormSection
                        errorKey="Name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your proxy")}
                        help="A short, memorable, unique name for this proxy. Example: DMZ Proxy.">
                        <Text
                            value={this.state.model.Name}
                            onChange={Name => this.setModelState({Name})}
                            label="Proxy name"
                            validate={required("Please enter a proxy name")}
                            error={this.getFieldError("Name")}
                            autoFocus={true}
                        />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="Host"
                        title="Proxy Host"
                        summary={this.state.model.Host ? Summary.summary(this.state.model.Host) : Summary.placeholder("Please enter the DNS hostname of the proxy server")}
                        help="DNS hostname of the proxy server.">
                        <Text
                            value={this.state.model.Host}
                            onChange={Host => this.setModelState({Host})}
                            label="Proxy host"
                            validate={required("Please enter a hostname")}
                            error={this.getFieldError("Host")}
                        />
                        <div>Examples:
                            <ul>
                                <li><code>10.0.0.1</code></li>
                                <li><code>web01.local</code></li>
                                <li><code>web01.mynetwork.com</code></li>
                            </ul>
                        </div>
                        <Callout title="Note" type={CalloutType.Information}>
                            Octopus only supports the use of HTTP proxies, there is currently no support for SOCKS
                            proxies.
                        </Callout>
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="Port"
                        title="Proxy Port"
                        summary={this.state.model.Port ? Summary.summary(this.state.model.Port) : Summary.placeholder("Please enter a port for your proxy")}
                        help="The TCP port of the proxy server.">
                        <Text
                            value={this.state.model.Port ? this.state.model.Port.toString() : ""}
                            onChange={(x) => this.setModelState({Port: ParseHelper.safeParseInt(x, null)})}
                            label="Proxy port"
                            validate={required("Please enter a port number")}
                            error={this.getFieldError("Port")}
                            type="number"
                        />
                    </ExpandableFormSection>

                    <ExpandableFormSection
                        errorKey="Credentials"
                        title="Credentials"
                        summary={this.state.model.Username ? Summary.summary(`Credentials have been entered, username is ${this.state.model.Username}`)
                            : Summary.placeholder("Add authentication details if your proxy server requires authentication")}
                        help="Leave blank if your proxy server does not require authentication.">
                        <Text
                            value={this.state.model.Username}
                            onChange={Username => this.setModelState({Username})}
                            label="Proxy login"
                        />
                        <Sensitive
                            value={this.state.model.Password}
                            onChange={Password => this.setModelState({Password})}
                            label="Proxy password"
                        />
                    </ExpandableFormSection>
                </TransitionAnimation>}
            </FormPaperLayout>
        </InfrastructureLayout>;
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.Proxies.save(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
                newId: isNew ? result.Id : null
            });
        });
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.Proxies.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }
}

export default ProxyLayout;
