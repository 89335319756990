import Resource from "./resource";
import DeploymentStepResource from "./deploymentStepResource";

export class DeploymentProcessResource extends Resource {
    Id: string;
    ProjectId: string;
    Steps: DeploymentStepResource[];
    Version: number;
    LastSnapshotId?: string;
}

export default DeploymentProcessResource;