exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_layout__3F9l_ {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n.style_headerContentAlignment__2DmFe {\n  padding-left: 1rem;\n  padding-right: 1rem;\n}\n.style_title__1aUJB {\n  padding: 1rem;\n  font-size: 1.063rem;\n  font-weight: 500;\n}\n.style_titleIcon__1WDUq {\n  margin-right: 0.5rem;\n  display: inline-block;\n}\n.style_body__3C98V {\n  margin-top: 0.5rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  padding-left: 1rem;\n  padding-right: 1rem;\n  padding-bottom: 1rem;\n  overflow-y: auto;\n  overflow-x: hidden;\n  flex-direction: column;\n}\n.style_actions__3f7mN {\n  padding: 1rem;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  flex-wrap: wrap;\n}\n.style_materialDialogBody__1XgjK {\n  padding: 0;\n}\n.style_primaryActions__2EV5A > * {\n  margin-left: 0.5rem;\n}\n.style_secondaryActions__1ZOtb > * {\n  margin-right: 0.5rem;\n}\n.style_dialogWrapper__11h1h {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"layout": "style_layout__3F9l_",
	"headerContentAlignment": "style_headerContentAlignment__2DmFe",
	"title": "style_title__1aUJB",
	"titleIcon": "style_titleIcon__1WDUq",
	"body": "style_body__3C98V",
	"actions": "style_actions__3f7mN",
	"materialDialogBody": "style_materialDialogBody__1XgjK",
	"primaryActions": "style_primaryActions__2EV5A",
	"secondaryActions": "style_secondaryActions__1ZOtb",
	"dialogWrapper": "style_dialogWrapper__11h1h"
};