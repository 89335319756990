import * as React from "react";
import { repository, client } from "clientInstance";
import { required, Text, RadioButtonGroup, RadioButton } from "components/form";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import {SpaceResource} from "../../../../client/resources";
import { Note } from "components/form";
import {SpaceChip, spaceChipList} from "components/Chips";

interface AddTeamProps {
    currentSpace: SpaceResource;
    onTeamCreated(id: string): void;
}

interface AddTeamState extends DataBaseComponentState {
    name: string;
    spaceId: string | null;
}

export default class AddTeam extends DataBaseComponent<AddTeamProps, AddTeamState> {
    constructor(props: AddTeamProps) {
        super(props);
        this.state = {
            name: "",
            spaceId: props.currentSpace ? props.currentSpace.Id : null,
        };
    }

    save() {
        return this.doBusyTask(async () => {
            const result = await repository.Teams.create({
                Id: null,
                Name: this.state.name,
                SpaceId: this.state.spaceId,
                ExternalSecurityGroups: [],
                MemberUserIds: [],
                CanBeDeleted: true,
                CanBeRenamed: true,
                CanChangeMembers: true,
                CanChangeRoles: true,
                Links: null,
            }, {});
            this.props.onTeamCreated(result.Id);
        });
    }

    render() {
        const canSeeSpace = !!client.spaceId;
        return <SaveDialogLayout
        title={canSeeSpace ? "Add New Team" : "Add New System Team"}
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => this.save()}>
            <Text
                label="New team name"
                value={this.state.name}
                onChange={name => this.setState({ name })}
                validate={required("Please enter a team name")}
                autoFocus={true}
            />
            {canSeeSpace
            ? <div>
                <h4>Make this team accessible in this space or all spaces.</h4>
                <RadioButtonGroup
                    value={this.state.spaceId}
                    onChange={(x: string) => this.setState({ spaceId: x })}>
                    <RadioButton
                        value={this.props.currentSpace.Id}
                        label={<span>Accessible in the {<SpaceChip space={this.props.currentSpace} />} space only</span>} />
                    <RadioButton value={null} label="Accessible in all spaces (system team)" />
                </RadioButtonGroup>
            </div>
            : <Note>This team will be accessible in all Spaces</Note>}
        </SaveDialogLayout>;
    }
}
