import BasicRepository, {ListArgs} from "./basicRepository";
import Client from "../client";
import {DeploymentResource} from "client/resources";
import {TaskState} from "../resources/taskState";

type DeploymentListArgs = {
    projects?: string[];
    environments?: string[];
    tenants?: string[];
    channels?: string[];
    taskState?: TaskState;
} & ListArgs;

class DeploymentRepository extends BasicRepository<DeploymentResource, DeploymentResource, DeploymentListArgs> {
    constructor(client: Client) {
        super("Deployments", client);
    }
}

export default DeploymentRepository;