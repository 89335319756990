import * as React from "react";
import {GitHubFeedResource} from "client/resources/feedResource";
import RetryAttemptsFormSection from "../RetryAttemptsFormSection";
import {FeedType} from "client/resources/feedResource";
import feedTypeRegistry, {FeedEditProps} from "./feedTypeRegistry";
import AdvancedFeedCredentialsFormSection from "../AdvancedFeedCredentialsFormSection";

class GitHubFeed extends React.Component<FeedEditProps<GitHubFeedResource>> {
    render() {
        return <React.Fragment>
            <RetryAttemptsFormSection backoffSeconds={this.props.feed.DownloadRetryBackoffSeconds}
                                      onBackoffSecondsChanged={(seconds) => this.props.onChange({...this.props.feed, DownloadRetryBackoffSeconds: seconds})}
                                      attemptCount={this.props.feed.DownloadAttempts}
                                      onAttemptCountChanged={(count) => this.props.onChange({...this.props.feed, DownloadAttempts: count})}/>
            <AdvancedFeedCredentialsFormSection model={{username: this.props.feed.Username, password: this.props.feed.Password}}
                                                isNew={!this.props.feed.Id}
                                                onChanged={(model) => this.props.onChange({...this.props.feed, Password: model.password, Username: model.username})}/>
        </React.Fragment>;
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.GitHub,
    edit: GitHubFeed,
    text: "GitHub Repository Feed",
    uriNotes: <span>To use the standard public github endpoint, set the url to <code>https://api.github.com</code></span>,
    uriDefault: "https://api.github.com"
});