import * as React from "react";
const StickyNode = require("react-stickynode");

// This is a monkey patch to fix this issue: https://github.com/yahoo/react-stickynode/issues/46
// We should go back and submit a PR to that repo when we have more time
// This is required for the variable editor headers
StickyNode.prototype.getTargetHeight = (target: any) => {
    return target && (target.offsetHeight + target.getBoundingClientRect().top) || 0;
};

// When you have multiple sticky components around the place that depend on each others positions,
// It looks like they need to be repositioned in a certain order so that they are all positioned correctly with respect to each other
// In fact, I think you even need to position a child sticky in a timeout after the parent/anchor sticky is positioned.
// This is a hack to help get around that issue... It doesn't seem to be entirely reliable but improves things considerably
// It repeatedly positions/renders the stickies until the position becomes stable. It will do this at least twice
const originalUpdateInitialDimension = StickyNode.prototype.updateInitialDimension;
function updateInitialDimension(this: any, options: any) {
    originalUpdateInitialDimension.call(this, options);
    let position: any = null;

    window.setTimeout(() => positionCheckingUpdateInitialDimension.call(this), 0);

    function positionCheckingUpdateInitialDimension(this: any) {
        const oldPosition = position;
        const stickyElement = this.refs.inner;
        const outerElement = this.refs.outer;
        if (stickyElement || outerElement) {
            position = stickyElement.getBoundingClientRect();
            if (oldPosition === null
                || oldPosition.bottom !== position.bottom
                || oldPosition.top !== position.top
                || oldPosition.left !== position.left
                || oldPosition.right !== position.right
                || oldPosition.height !== position.height
                || oldPosition.width !== position.width) {
                originalUpdateInitialDimension.call(this, options);
                this.update();
                window.setTimeout(() => positionCheckingUpdateInitialDimension.call(this), 0);
            }
        } else {
            window.setTimeout(() => positionCheckingUpdateInitialDimension.call(this), 0);
        }
    }
}
StickyNode.prototype.updateInitialDimension = updateInitialDimension;

export enum StickyStatus {
    STATUS_ORIGINAL = 0,
    STATUS_RELEASED = 1,
    STATUS_FIXED = 2
}

interface StickyState {
    status: StickyStatus;
}

interface StickyProps {
    top?: string | number;
    bottomBoundary?: string | number;
    innerZ?: number;
    onStateChange?(state: StickyState): void;
}

const Sticky: React.SFC<StickyProps> = (props: StickyProps) => {
    return <StickyNode {...props}/>;
};

export default Sticky;
