import packagingRegistry from "./packagingRegistry";

import { BuildServerPackagingRegistrationForAspNet } from "../Registrations/AspNet/BuildServerPackagingRegistration";
import { CommandLinePackagingRegistrationForAspNet } from "../Registrations/AspNet/CommandLinePackagingRegistration";
import { ManualPackagingRegistrationForAspNet } from "../Registrations/AspNet/ManualPackagingRegistration";

import { BuildServerPackagingRegistrationForAspNetCore } from "../Registrations/AspNetCore/BuildServerPackagingRegistration";
import { CommandLinePackagingRegistrationForAspNetCore } from "../Registrations/AspNetCore/CommandLinePackagingRegistration";
import { ManualPackagingRegistrationForAspNetCore } from "../Registrations/AspNetCore/ManualPackagingRegistration";

import { BuildServerPackagingRegistrationForJava } from "../Registrations/Java/BuildServerPackagingRegistration";
import { CommandLinePackagingRegistrationForJava } from "../Registrations/Java/CommandLinePackagingRegistration";
import { ManualPackagingRegistrationForJava } from "../Registrations/Java/ManualPackagingRegistration";

import { BuildServerPackagingRegistrationForNodeJs } from "../Registrations/NodeJs/BuildServerPackagingRegistration";
import { CommandLinePackagingRegistrationForNodeJs } from "../Registrations/NodeJs/CommandLinePackagingRegistration";
import { ManualPackagingRegistrationForNodeJs } from "../Registrations/NodeJs/ManualPackagingRegistration";

import { BuildServerPackagingRegistrationForOther } from "../Registrations/Other/BuildServerPackagingRegistration";
import { CommandLinePackagingRegistrationForOther } from "../Registrations/Other/CommandLinePackagingRegistration";
import { ManualPackagingRegistrationForOther } from "../Registrations/Other/ManualPackagingRegistration";

packagingRegistry.registerPackaging(BuildServerPackagingRegistrationForAspNet);
packagingRegistry.registerPackaging(CommandLinePackagingRegistrationForAspNet);
packagingRegistry.registerPackaging(ManualPackagingRegistrationForAspNet);

packagingRegistry.registerPackaging(BuildServerPackagingRegistrationForAspNetCore);
packagingRegistry.registerPackaging(CommandLinePackagingRegistrationForAspNetCore);
packagingRegistry.registerPackaging(ManualPackagingRegistrationForAspNetCore);

packagingRegistry.registerPackaging(BuildServerPackagingRegistrationForJava);
packagingRegistry.registerPackaging(CommandLinePackagingRegistrationForJava);
packagingRegistry.registerPackaging(ManualPackagingRegistrationForJava);

packagingRegistry.registerPackaging(BuildServerPackagingRegistrationForNodeJs);
packagingRegistry.registerPackaging(CommandLinePackagingRegistrationForNodeJs);
packagingRegistry.registerPackaging(ManualPackagingRegistrationForNodeJs);

packagingRegistry.registerPackaging(BuildServerPackagingRegistrationForOther);
packagingRegistry.registerPackaging(CommandLinePackagingRegistrationForOther);
packagingRegistry.registerPackaging(ManualPackagingRegistrationForOther);
