exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_description__2Gz-N {\n  font-size: 0.875rem;\n  color: #333333;\n}\n.style_information__ui36b {\n  font-size: 1rem;\n  margin: 0.9375rem;\n  line-height: 1.5rem;\n}\n.style_sidebar__3RNVp {\n  font-size: 0.875rem;\n}\n.style_callouts__3rkF6 li {\n  margin-right: 2.5rem;\n  margin-left: 2.5rem;\n  margin-bottom: 1.5rem;\n}\n.style_disabled__xVRsi * {\n  color: #9e9e9e;\n}\n.style_disabled__xVRsi * svg {\n  opacity: .5;\n}\n", ""]);

// exports
exports.locals = {
	"description": "style_description__2Gz-N",
	"information": "style_information__ui36b",
	"sidebar": "style_sidebar__3RNVp",
	"callouts": "style_callouts__3rkF6",
	"disabled": "style_disabled__xVRsi"
};