import * as React from "react";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";

const styles = require("./style.less");

// Inspired :) by https://github.com/react-dnd/react-dnd/tree/master/examples/04%20Sortable/Simple
const source = {
    beginDrag(props: any) {
        return {
            id: props.id,
            index: props.index,
        };
    },
};

const target = {
    hover(props: any, monitor: any, component: any) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(component) as Element).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.onItemMoved(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

const DropTargetInstance = DropTarget("SortableItem", target, connect => ({
    connectDropTarget: connect.dropTarget()
}));

const DragSourceInstance = DragSource("SortableItem", source, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}));

interface SortableItemProps {
    name: string;
    id: string;
    onItemMoved: (dragIndex: number, hoveIndex: number) => void;
}

class SortableItem extends React.Component<any, any>  {
    render() {
        const { name, isDragging, connectDragSource, connectDropTarget } = this.props;
        const opacity = isDragging ? 0 : 1;
        return connectDragSource(connectDropTarget(<div className={styles.sortableItem} style={{opacity }}>
            <em className="fa fa-sort" />
            <span className={styles.name}>{name}</span>
        </div>));
    }
}

export default DropTargetInstance(DragSourceInstance(SortableItem));