import * as React from "react";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import { client } from "clientInstance";
import ExternalLink from "components/Navigation/ExternalLink";

interface TentacleDownloadButtonProps {
    className?: string;
    style?: any;
}

const TentacleDownloadButton: React.SFC<TentacleDownloadButtonProps> = ({className, style}) => (
    <DropdownButton className={className} style={style} label={"Download Tentacle"} renderMenu={(renderProps) => (
        <DropdownButtonMenu>
            <ExternalLink showIcon={false} href="LatestTentacleWinX86">
                <DropdownButtonMenuItem {...renderProps.getMenuItemProps({ label: "Windows 32-bit/x86 installer" })}/>
            </ExternalLink>
            <ExternalLink showIcon={false} href="LatestTentacleWinX64">
                <DropdownButtonMenuItem {...renderProps.getMenuItemProps({ label: "Windows 64-bit/x64 installer" })}/>
            </ExternalLink>
            <ExternalLink showIcon={false} href="ProductDownloadPage">
                <DropdownButtonMenuItem {...renderProps.getMenuItemProps({ label: "Download a custom version" })}/>
            </ExternalLink>
        </DropdownButtonMenu>
    )}/>
);

export default TentacleDownloadButton;