import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ActionEditProps} from "components/Actions/pluginRegistry";
import {KubernetesIngressProperties, KubernetesServiceProperties} from "components/Actions/kubernetes/kubernetesProperties";
import {KubernetesServiceComponent} from "components/Actions/kubernetes/kubernetesServiceComponent";
import * as React from "react";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import {default as ExpandableFormSection} from "components/form/Sections/ExpandableFormSection";
import {JsonUtils} from "utils/jsonUtils";
import * as _ from "lodash";
import Summary from "components/form/Sections/Summary";
import pluginRegistry from "components/Actions/pluginRegistry";
import {ActionExecutionLocation} from "client/resources";
import {TargetRoles} from "areas/projects/components/DeploymentProcess/ActionDetails";
import {ActionSummaryProps} from "components/Actions/actionSummaryProps";
import {KubernetesIngressComponent, KubernetesIngressComponentProperties} from "components/Actions/kubernetes/kubernetesIngressComponent";
import {VariableLookupText} from "components/form/VariableLookupText";
import Note from "components/form/Note/Note";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

class KubernetesDeployIngressActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Deploy an ingress resource to Kubernetes
        </div>;
    }
}

class KubernetesDeployIngressActionEdit extends BaseComponent<ActionEditProps<KubernetesIngressProperties>, never> {
    constructor(props: ActionEditProps<KubernetesIngressProperties>) {
        super(props);
    }

    render() {
        return <div>
            <KubernetesIngressComponent
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                getFieldError={this.props.getFieldError}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                errors={this.props.errors}
                projectId={this.props.projectId}
                expandedByDefault={true}
                standAlone={true}/>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.DefaultRulePort|Octopus.Action.KubernetesContainers.DefaultRuleServiceName"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Default Rule"
                summary={this.defaultRuleSummary()}
                help={"The default rule applied when no other rule matches"}>
                <Note>
                    When there are no matching ingress rules, traffic can be sent to the service configured as the default rule.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-ingress#default-rule">default rules</ExternalLink>.
                </Note>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DefaultRulePort")}
                    value={this.props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"]}
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DefaultRulePort"]: val })}
                    label={"Port"}/>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    error={this.props.getFieldError("Octopus.Action.KubernetesContainers.DefaultRuleServiceName")}
                    value={this.props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]}
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]: val })}
                    label={"Service name"}/>
            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Ingress Labels"
                summary={this.labelsSummary()}
                help={"The labels applied to the ingress resource"}>
                <Note>
                    Labels are optional name/value pairs that are assigned to the ingress resource.
                </Note>
                <Note>
                    Learn more about <ExternalLink href="https://octopus.com/docs/deployment-examples/kubernetes-deployments/deploy-ingress#ingress-labels">ingress labels</ExternalLink>.
                </Note>
                <StringKeyValueEditList
                    items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]}
                    name="Label"
                    separator=""
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })}
                    valueLabel="Value"
                    keyLabel="Name"
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                />
            </ExpandableFormSection>
        </div>;
    }

    private defaultRuleSummary() {
        if (this.props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"] &&
            this.props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]) {
            return Summary.summary(<span>Directing unmatched traffic to <strong>
                {this.props.properties["Octopus.Action.KubernetesContainers.DefaultRuleServiceName"]}:
                {this.props.properties["Octopus.Action.KubernetesContainers.DefaultRulePort"]}</strong>
            </span>);
        }

        return Summary.placeholder(<span>Define the default service to recieve unmatched traffic</span>);
    }

    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));

        if (labels.length === 0) {
            return Summary.placeholder("The service resource labels");
        }

        return Summary.summary(<span>Add the label{labels.length > 1 && <span>s</span>} {
            _.chain(labels)
                .flatMap(pair => [<strong>{pair[0]}: {pair[1]}</strong>, <span>, </span>])
                .slice(0, -1)
                .value()
        }</span>);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployIngress",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployIngressActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeployIngressActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false
});