import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import {BaseComponent} from "../../BaseComponent/BaseComponent";
import {ExpandableFormSection, Summary} from "../../form";
import { VariableLookupText } from "../../form/VariableLookupText";
import Note from "../../form/Note/Note";
import { BoundStringCheckbox } from "../../form/Checkbox/StringCheckbox";
import FeedResource from "client/resources/feedResource";
import ActionProperties from "client/resources/actionProperties";
import { isEqual } from "lodash";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

interface ConfigurationTransformsEditState {
    feeds: FeedResource[];
}

const StringProperties = {
    "Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles": "",
    "Octopus.Action.Package.AdditionalXmlConfigurationTransforms": "",
};

type ConfigurationTransformProperties = {[P in keyof typeof StringProperties]: string };

class ConfigurationTransformsEdit extends BaseComponent<ActionEditProps<ConfigurationTransformProperties>, ConfigurationTransformsEditState> {
    shouldComponentUpdate(nextProps: ActionEditProps<ConfigurationTransformProperties>, nextState: ConfigurationTransformsEditState) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props) || !isEqual(nextState, this.state);
    }

    summary() {
        const auto = this.props.properties["Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles"] || "";
        const custom = this.props.properties["Octopus.Action.Package.AdditionalXmlConfigurationTransforms"] || "";
        const summary = [];
        if (auto.toLowerCase() !== "false") {
            summary.push(<span>Default transforms (*.Release.config and *.EnvironmentName.config)</span>);
        }
        if (custom && custom.length > 0) {
            if (summary.length === 0) {
                summary.push(<span>Custom transforms</span>);
            } else {
                summary.push(<span> and custom transforms</span>);
            }
        }
        if (summary.length === 0) {
            return Summary.placeholder("No transforms will be run");
        }
        summary.push(<span> will be run</span>);

        return Summary.summary(React.Children.toArray(summary));
    }

    render() {
        const properties = this.props.properties;

        return <ExpandableFormSection
            errorKey="Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles|Octopus.Action.Package.AdditionalXmlConfigurationTransforms"
            isExpandedByDefault={this.props.expandedByDefault}
            title="Configuration Transforms"
            summary={this.summary()}
            help="Configure XML configuration transforms.">
            <BoundStringCheckbox
                variableLookup={{
                    localNames: this.props.localNames,
                    projectId: this.props.projectId
                }}
                resetValue={"False"}
                value={properties["Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles"]}
                onChange={(x) => this.props.setProperties({["Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles"]: x})}
                label="Run default XML transforms"
                note={
                    <span>
                        Octopus can run configuration transformation files. By default Octopus looks for <em>*.Release.config</em> and <em>*.&lt;EnvironmentName&gt;.config</em>.
                    </span>
                } />
            <VariableLookupText
                localNames={this.props.localNames}
                projectId={this.props.projectId}
                value={properties["Octopus.Action.Package.AdditionalXmlConfigurationTransforms"]}
                onChange={(x) => this.props.setProperties({["Octopus.Action.Package.AdditionalXmlConfigurationTransforms"]: x})}
                multiLine={true}
                error={this.props.getFieldError("Octopus.Action.Package.AdditionalXmlConfigurationTransforms")}
                label="Additional transforms" />
            <Note checkboxNote={true}>
                <p>A comma- or newline-separated list of additional configuration transformation file rules. Example:</p>
                <pre>Web.Release.config => Web.config<br/>
*.Foo.config => *.config<br/>
crossdomainpolicy.#{"{"}Octopus.Environment.Name}.xml => crossdomainpolicy.xml</pre>
                <p>If your configuration file is named <em>Bar.xml</em>, and your transformation file is named <em>Foo.xml</em>, you should
                    enter <em>Foo.xml=>Bar.xml</em>. Wildcards are supported so if you have config files named <em>xyz.Bar.config</em> and <em>abc.Bar.config</em>, and
                    you have transform files named <em>xyz.Foo.config</em> and <em>abc.Foo.config</em>, you may enter <em>*.Foo.config=>*.Bar.config</em></p>
            </Note>
            </ExpandableFormSection>;
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.ConfigurationTransforms",
    title: "Configuration Transforms",
    description: "Runs configuration file transforms, such as _Web.Release.config_",
    edit: ConfigurationTransformsEdit,
    priority: 21,
    enable: (properties: ActionProperties) => {
        properties["Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles"] = "True";
    },
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.Package.AutomaticallyRunConfigurationTransformationFiles"];
        delete properties["Octopus.Action.Package.AdditionalXmlConfigurationTransforms"];
    }
});
