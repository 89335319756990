exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_headerRow__2ACTH {\n  align-items: center;\n  display: flex;\n  height: 2rem;\n  color: #333333;\n  font-size: 0.875rem;\n}\n", ""]);

// exports
exports.locals = {
	"headerRow": "style_headerRow__2ACTH"
};