import BasicRepository from "./basicRepository";
import Client from "../client";
import { LicenseResource, LicenseStatusResource } from "client/resources";

class LicenseRepository extends BasicRepository<LicenseResource, LicenseResource> {
    constructor(client: Client) {
        super("Licenses", client);
    }
    getCurrent() {
        return this.client.get<LicenseResource>(this.client.getLink("CurrentLicense"));
    }
    getCurrentStatus() {
        return this.client.get<LicenseStatusResource>(this.client.getLink("CurrentLicenseStatus"));
    }
}

export default LicenseRepository;