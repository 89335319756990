import * as React from "react";
import ActionButton, { ActionButtonType } from "../../Button/ActionButton";
import BaseLayout from "./BaseLayout";
import { LargeDialogFrame, DialogFrameProp } from "./DialogFrames";

import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import BusyIndicator from "components/BusyIndicator/BusyIndicator";
import BusyFromPromise, {BusyState} from "components/BusyFromPromise/BusyFromPromise";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
import {Errors} from "components/DataBaseComponent/DataBaseComponent";
import DialogTitleBar from "./DialogTitleBar";
import { RenderProps } from "components/Dialog/CustomDialog";
import * as cn from "classnames";
import { defaultProps } from "recompose";
import { SaveDialogActions } from "./DialogActions";
import { DialogContent } from "./DialogContent";

const styles = require("./SaveDialogLayout.less");

type CustomSaveDialogLayoutProps = RenderProps & DialogFrameProp & {
    errors: Errors;
    busy: BusyState;
    frame?: React.ComponentType<any>;
    renderTitle?: (props: ActionRenderProps) => React.ReactElement<any>;
    savePermission?: PermissionCheckProps;
    renderActions?: (props: ActionRenderProps) => React.ReactElement<any>;
    renderContent?: (props: ActionRenderProps) => React.ReactElement<any>;
    onSaveClick(): Promise<boolean>;
};

interface ActionRenderProps extends RenderProps {
    savePermission?: PermissionCheckProps;
    onSaveClick(): Promise<boolean>;
}

const RenderSaveDialogActions = (props: ActionRenderProps) => (
    <SaveDialogActions onSaveClick={props.onSaveClick} savePermission={props.savePermission} close={props.close} />
);

export const SaveDialogTitleBar = defaultProps({ title: "Edit Details"})(DialogTitleBar);

const CustomSaveDialogLayout: React.SFC<CustomSaveDialogLayoutProps> = ({
        frame: Frame = LargeDialogFrame,
        renderContent,
        busy,
        errors,
        renderTitle = (renderProps: ActionRenderProps) => (<SaveDialogTitleBar/>),
        renderActions,
        open,
        close,
        ...props
    }) => {
        return <BaseLayout
            open={open}
            close={close}
            render={(renderProps) => (
                <Frame>
                    {renderTitle && renderTitle({...renderProps, onSaveClick: props.onSaveClick, savePermission: props.savePermission})}
                    {busy && <BusyFromPromise promise={busy}>
                        {(isBusy: boolean) => <BusyIndicator show={isBusy}/>}
                    </BusyFromPromise>}

                    {errors && <ErrorPanel message={errors.message} details={errors.details}/>}
                    {renderContent && renderContent({...renderProps, onSaveClick: props.onSaveClick, savePermission: props.savePermission})}
                    {renderActions && renderActions({...renderProps, onSaveClick: props.onSaveClick, savePermission: props.savePermission})}
                </Frame>
            )}
        />;
};

export default CustomSaveDialogLayout;
export { CustomSaveDialogLayout, CustomSaveDialogLayoutProps };
