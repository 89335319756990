exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_materialUIIcon__PT3Bb {\n  width: 1.5rem !important;\n  height: 1.5rem !important;\n  min-width: 1.5rem !important;\n  line-height: 1.5rem !important;\n  padding: 0 !important;\n  opacity: 0.7;\n}\n.style_materialUIIcon__PT3Bb span {\n  padding: 0rem !important;\n}\n.style_materialUIIcon__PT3Bb:hover {\n  background-color: #ffffff !important;\n}\n.style_materialUIIcon__PT3Bb:hover {\n  opacity: 1;\n}\n.style_imageIcon__2Bet0 {\n  width: 1.5rem;\n  height: 1.5rem;\n  opacity: 0.7;\n}\n.style_imageIcon__2Bet0:hover {\n  opacity: 1;\n}\n.style_fontAwesomeIcon__24Xxz {\n  opacity: 0.7;\n}\n.style_fontAwesomeIcon__24Xxz:hover {\n  opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"materialUIIcon": "style_materialUIIcon__PT3Bb",
	"materialUiIcon": "style_materialUIIcon__PT3Bb",
	"imageIcon": "style_imageIcon__2Bet0",
	"fontAwesomeIcon": "style_fontAwesomeIcon__24Xxz"
};