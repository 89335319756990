const DialogActions = {
    DIALOG_OPEN: "DIALOG_OPEN",
    DIALOG_CLOSE: "DIALOG_CLOSE"
};

export function openDialog(key: string) {
    return {type: DialogActions.DIALOG_OPEN, key};
}

export function onDialogClose() {
    return {type: DialogActions.DIALOG_CLOSE};
}

const dialogs = (state = {}, action: any): {} => {
    switch (action.type) {
        case DialogActions.DIALOG_OPEN:
            const updated: any = {}; // only one open
            updated[action.key] = true;
            return updated;
        case DialogActions.DIALOG_CLOSE:
            return {}; // close all
    }
    return state;
};

export default dialogs;
