exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DialogTitleBar_titleBar__2KVye {\n  background-color: #ffffff;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  z-index: 1;\n  flex: 0 0 auto;\n  padding: 1rem;\n}\n.DialogTitleBar_title__29SiO {\n  flex-grow: 1;\n  font-size: 1.063rem;\n  font-weight: 500;\n}\n.DialogTitleBar_title__29SiO h1,\n.DialogTitleBar_title__29SiO h2 {\n  font-size: 1.25rem;\n  margin: 0;\n  padding: 0;\n}\n.DialogTitleBar_actions__22Xed {\n  padding: 0.5rem 1rem;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  flex-wrap: wrap;\n}\n.DialogTitleBar_actions__22Xed > * {\n  margin-left: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"titleBar": "DialogTitleBar_titleBar__2KVye",
	"title": "DialogTitleBar_title__29SiO",
	"actions": "DialogTitleBar_actions__22Xed"
};