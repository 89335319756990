exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_taskDetailsCell__qtmtm {\n  vertical-align: \"middle\";\n}\n.style_stats__MyEBG {\n  padding-left: 1rem;\n}\n.style_states__1MbFc {\n  width: 16rem;\n}\n", ""]);

// exports
exports.locals = {
	"taskDetailsCell": "style_taskDetailsCell__qtmtm",
	"stats": "style_stats__MyEBG",
	"states": "style_states__1MbFc"
};