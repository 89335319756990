exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_lifecycle-map__1YqE4 {\n  position: relative;\n  overflow: hidden;\n}\n.style_lifecycle-map__1YqE4 > ul:before {\n  content: \"\";\n  position: absolute;\n  top: 1.25rem;\n  bottom: 1.5rem;\n  left: 0.375rem;\n  width: 0.0625rem;\n  background: #9e9e9e;\n}\n.style_lifecycle-map__1YqE4 li {\n  position: relative;\n  list-style-type: none;\n  margin: 0.5rem 0.25rem 0.5rem 0;\n  font-size: 0.875rem;\n  font-weight: 400;\n  line-height: 1.36rem;\n  text-align: left;\n}\n.style_lifecycle-map__1YqE4 > ul > li.style_phase__sK0_L .style_phase-name__UOlFy {\n  font-weight: 500;\n}\n.style_lifecycle-map__1YqE4 li.style_phase__sK0_L ul {\n  list-style-type: none;\n  margin-left: 1.5rem;\n  padding-left: 0;\n}\n.style_lifecycle-map__1YqE4 li.style_phase__sK0_L li {\n  list-style-type: none;\n  margin-left: 0;\n  padding-left: 0;\n}\n.style_lifecycle-map__1YqE4 li.style_target__3FUvu:before {\n  content: \"\";\n  position: absolute;\n  top: 0.5rem;\n  left: -1.125rem;\n  width: 1.125rem;\n  height: 0.0625rem;\n  background: #9e9e9e;\n}\n.style_lifecycle-map__1YqE4 li.style_phase__sK0_L {\n  padding-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"lifecycle-map": "style_lifecycle-map__1YqE4",
	"lifecycleMap": "style_lifecycle-map__1YqE4",
	"phase": "style_phase__sK0_L",
	"phase-name": "style_phase-name__UOlFy",
	"phaseName": "style_phase-name__UOlFy",
	"target": "style_target__3FUvu"
};