exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_nameCellContent__3PeDp {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 100%;\n}\n.style_spacer__1gged {\n  flex-grow: 1;\n}\n.style_value__3TuOZ {\n  display: flex;\n}\n.style_promptedVariablePositionContainer__3NOnz {\n  display: flex;\n  height: 48px;\n  align-items: center;\n  justify-content: center;\n}\n.style_promptedVariableIconSizeContainer__1bf5B {\n  height: 1.5rem;\n  width: 1.5rem;\n  margin-right: 0.5rem;\n}\n.style_promptedVariableIcon__2JoIF {\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"nameCellContent": "style_nameCellContent__3PeDp",
	"spacer": "style_spacer__1gged",
	"value": "style_value__3TuOZ",
	"promptedVariablePositionContainer": "style_promptedVariablePositionContainer__3NOnz",
	"promptedVariableIconSizeContainer": "style_promptedVariableIconSizeContainer__1bf5B",
	"promptedVariableIcon": "style_promptedVariableIcon__2JoIF"
};