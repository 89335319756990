import * as React from "react";
import Select from "../form/Select/Select";
import Note from "../form/Note/Note";
import {ControlType} from "../../client/resources";
import Text from "../form/Text/Text";
import { dropdownOptionsValidator } from "components/form";

interface ControlTypeSelectorProps {
    controlType: ControlType;
    selectOptions: string;
    includedControlTypeOptions?: ControlType[];
    excludedControlTypeOptions?: ControlType[];
    onControlTypeChange(controlType: ControlType): void;
    onSelectOptionsChange(selectOptions: string): void;
}

export default class ControlTypeSelector extends React.Component<ControlTypeSelectorProps, null> {

    render() {
       return <div>
           <Select
               label="Control type"
               value={this.props.controlType}
               items={this.controlTypeItems()}
               onChange={(x: keyof typeof ControlType) => this.props.onControlTypeChange(ControlType[x])}
           />
           <Note>
               The type of control to display.
           </Note>

           {this.props.controlType === ControlType.Select && <div>
               <Text
                   multiLine={true}
                   rows={4}
                   label="Drop down options"
                   value={this.props.selectOptions}
                   validate={dropdownOptionsValidator()}
                   onChange={x => this.props.onSelectOptionsChange(x)}/>
               <Note>
                   <div>Enter each option on a new line. Use | to separate values and display text - e.g.:
                       <pre>Value1|Display text 1
                            <br />
                            Value2|Display text 2
                            </pre>
                   </div>
               </Note>
           </div>}
       </div>;
    }

    private controlTypeItems() {
        return [
            {value: ControlType.SingleLineText, text: "Single-line text box"},
            {value: ControlType.MultiLineText, text: "Multi-line text box"},
            {value: ControlType.Select, text: "Drop down"},
            {value: ControlType.Checkbox, text: "Checkbox"},
            {value: ControlType.Sensitive, text: "Sensitive/password box"},
            {value: ControlType.StepName, text: "A previous deployment step name"},
            {value: ControlType.AzureAccount, text: "Azure Account"},
            {value: ControlType.AmazonWebServicesAccount, text: "AWS Account"},
            {value: ControlType.Certificate, text: "Certificate"},
        ].filter(x => {
            if (this.props.includedControlTypeOptions) {
                return this.props.includedControlTypeOptions.indexOf(x.value) !== -1;
            }
            if (this.props.excludedControlTypeOptions) {
                return this.props.excludedControlTypeOptions.indexOf(x.value) === -1;
            }
        });
    }
}
