import * as React from "react";
import { LibraryLayout } from "areas/library/components/LibraryLayout/LibraryLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { repository } from "clientInstance";
import { LibraryVariableSetResource, VariableSetContentType } from "client/resources/libraryVariableSetResource";
import { List } from "components/List/List";
import { ResourceCollection } from "client/resources/resourceCollection";
import ListTitle from "components/ListTitle/ListTitle";
import AddVariableSetButton from "areas/library/components/VariableSets/AddVariableSetButton";
import MarkdownDescription from "components/MarkdownDescription";
import SectionNote from "components/SectionNote/SectionNote";

interface VariableSetsState extends DataBaseComponentState {
    variableSets?: ResourceCollection<LibraryVariableSetResource>;
}

export default class VariableSets extends DataBaseComponent<RouteComponentProps<{}>, VariableSetsState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.doBusyTask(() => this.loadVariableSets());
    }

    render() {
        return <LibraryLayout {...this.props}>
            <PaperLayout
                title="Variable sets"
                sectionControl={<AddVariableSetButton />}
                busy={this.state.busy}
                errors={this.state.errors}
            >
                <SectionNote>
                    Variable sets are collections of variables that can be be shared between multiple projects.
                </SectionNote>
                {this.state.variableSets && <VariableSetList
                    initialData={this.state.variableSets}
                    onRow={(variableSet: LibraryVariableSetResource) => this.buildRow(variableSet)}
                    onFilter={filterVariableSets}
                    filterHintText="Filter by name..."
                    filterSearchEnabled={true}
                    apiSearchParams={["partialName"]}
                    match={this.props.match}
                    additionalRequestParams={new Map([["contentType", ["Variables"]]])}
                />}
            </PaperLayout>
        </LibraryLayout>;
    }

    private buildRow(tagSet: LibraryVariableSetResource) {
        return (<div>
            <ListTitle>{tagSet.Name}</ListTitle>
            <MarkdownDescription markup={tagSet.Description} />
        </div>);
    }

    private async loadVariableSets() {
        this.setState({ variableSets: await repository.LibraryVariableSets.list({ contentType: VariableSetContentType.Variables }) });
    }
}

function filterVariableSets(filter: string, item: LibraryVariableSetResource) {
    return item.Name.toLowerCase().includes(filter.toLowerCase());
}

class VariableSetList extends List<LibraryVariableSetResource> { }