exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_components__16FVO {\n  margin-top: 0;\n}\n.style_components__16FVO li {\n  margin-top: 0.5rem;\n}\n.style_entry__1q-w2 {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n.style_entry__1q-w2 ul.style_bulletList__1pYCR {\n  margin-left: 3rem;\n}\n.style_entry__1q-w2 ul.style_bulletList__1pYCR li {\n  list-style: disc;\n}\n.style_entry__1q-w2 .style_content__3Zzse {\n  color: #333333;\n  font-size: 0.875rem;\n}\n.style_entry__1q-w2 .style_styleGuideExample__1wLAr {\n  margin-bottom: 0.5rem;\n}\n.style_styleGuide__Eupmo {\n  margin-top: 2rem;\n  margin-left: 2rem;\n  display: flex;\n  flex-direction: row;\n}\n.style_styleGuide__Eupmo > ul {\n  list-style: none;\n}\n.style_styleGuide__Eupmo > ul > li {\n  margin-bottom: 5px;\n}\n.style_heading__3Gim- {\n  text-align: left;\n}\n.style_content__3Zzse {\n  margin: 2rem 1rem;\n}\n.style_context__2s5Ib {\n  display: block;\n  margin-bottom: 1rem;\n  padding: 1rem;\n  background-color: rgba(153, 153, 153, 0.08);\n  font-size: 0.875rem;\n  color: #666666;\n}\n.style_context__2s5Ib p {\n  font-size: 0.875rem;\n  color: #666666;\n}\nthead {\n  font-weight: 600;\n}\n.style_swatch__2Kqps {\n  width: 1.5rem;\n  height: 1.5rem;\n  border: 0.0625rem solid #cccccc;\n}\n.style_white__24RBQ {\n  background: #ffffff;\n}\n.style_primaryText__px00q {\n  background: #333333;\n}\n.style_secondaryText__3tTsC {\n  background: #9e9e9e;\n}\n.style_primary__36X_d {\n  background: #2f93e0;\n}\n.style_primaryLight__m7fBj {\n  background: #74c0fb;\n}\n.style_primaryDark__3a_0N {\n  background: #0A67B1;\n}\n.style_readonlyTextWithStrikethrough__WT_pU > input {\n  text-decoration: line-through;\n  color: #9e9e9e !important;\n}\n.style_sidebarWithColor__3Q9dr {\n  background-color: grey;\n}\n.style_row__3Y6Tq {\n  display: flex;\n  flex-direction: row;\n}\n.style_buttons__1cn-V tr td {\n  padding: 0.5rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"components": "style_components__16FVO",
	"entry": "style_entry__1q-w2",
	"bulletList": "style_bulletList__1pYCR",
	"content": "style_content__3Zzse",
	"styleGuideExample": "style_styleGuideExample__1wLAr",
	"styleGuide": "style_styleGuide__Eupmo",
	"heading": "style_heading__3Gim-",
	"context": "style_context__2s5Ib",
	"swatch": "style_swatch__2Kqps",
	"white": "style_white__24RBQ",
	"primaryText": "style_primaryText__px00q",
	"secondaryText": "style_secondaryText__3tTsC",
	"primary": "style_primary__36X_d",
	"primaryLight": "style_primaryLight__m7fBj",
	"primaryDark": "style_primaryDark__3a_0N",
	"readonlyTextWithStrikethrough": "style_readonlyTextWithStrikethrough__WT_pU",
	"sidebarWithColor": "style_sidebarWithColor__3Q9dr",
	"row": "style_row__3Y6Tq",
	"buttons": "style_buttons__1cn-V"
};