import { PerformanceConfigurationResource, DashboardRenderMode } from "client/resources/performanceConfigurationResource";
import { OptionalFormBaseComponentState, FormBaseComponent } from "components/FormBaseComponent";
import {cloneDeep} from "lodash";
import {repository} from "clientInstance";
import * as React from "react";
import { ExpandableFormSection, Summary, Checkbox, RadioButtonGroup, RadioButton } from "components/form";
import FormPaperLayout from "components/FormPaperLayout";
import { Permission } from "client/resources";

interface PerformanceState extends OptionalFormBaseComponentState<PerformanceConfigurationResource> {
}

class DashboardRenderModeRadioButtonGroup extends RadioButtonGroup<DashboardRenderMode> { }

export class Performance extends FormBaseComponent<null, PerformanceState, PerformanceConfigurationResource> {
    constructor(props: null) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const configuration = await repository.PerformanceConfiguration.get();

            this.setState({
                model: configuration,
                cleanModel: cloneDeep(configuration)
            });
        });
    }

    render() {
        const body = this.state.model &&
            <div>
                <ExpandableFormSection
                    errorKey="DefaultDashboardRenderMode"
                    title="Project Overview Rendering"
                    focusOnExpandAll
                    summary={this.state.model.DefaultDashboardRenderMode === DashboardRenderMode.VirtualizeColumns
                        ? Summary.summary("Full project overview rendering")
                        : Summary.summary("Fast project overview rendering")}
                    help="Choose the default project overview rendering mode. Full rendering will render all rows, fast rendering will render only visible rows.">
                    <DashboardRenderModeRadioButtonGroup
                        value={this.state.model.DefaultDashboardRenderMode}
                        onChange={DefaultDashboardRenderMode => this.setModelState({DefaultDashboardRenderMode})}>
                        <RadioButton value={DashboardRenderMode.VirtualizeColumns}
                            label="Full render"
                            isDefault={true} />
                        <RadioButton value={DashboardRenderMode.VirtualizeRowsAndColumns} label="Fast render" />
                    </DashboardRenderModeRadioButtonGroup>
                </ExpandableFormSection>
            </div>;
        return <FormPaperLayout
            title="Performance"
            busy={this.state.busy}
            errors={this.state.errors}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            savePermission={{permission: [Permission.ConfigureServer]}}
            onSaveClick={() => this.handleSaveClick()}>
            {body}
        </FormPaperLayout>;
    }

    private handleSaveClick = async () => {
        await this.doBusyTask(async () => {
            const result = await repository.PerformanceConfiguration.modify(this.state.model);
            this.setState({
                model: result,
                cleanModel: cloneDeep(result),
            });
        });
    }
}

export default Performance;