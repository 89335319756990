exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__AgGSA {\n  display: flex;\n  align-items: center;\n}\n.style_thumbprint__E5amq {\n  word-break: break-all;\n  color: #333333;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__AgGSA",
	"thumbprint": "style_thumbprint__E5amq"
};