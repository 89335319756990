import * as React from "react";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import AreaTitle from "components/AreaTitle";
import { Permission } from "client/resources/permission";
import routeLinks from "routeLinks";

class InfrastructureLayout extends BaseComponent<{}, {}> {
    render() {
        const navLinks = [
            Navigation.navItem("Overview", routeLinks.infrastructure.overview, null, {permission: [Permission.EnvironmentView, Permission.MachineView, Permission.WorkerView], wildcard: true}),
            Navigation.navItem("Deployment Targets", routeLinks.infrastructure.machines.root, null,
                {permission: [Permission.MachineView], wildcard: true}),
            Navigation.navItem("Environments", routeLinks.infrastructure.environments.root, null,
                {permission: [Permission.EnvironmentView, Permission.MachineView], wildcard: true}),
            Navigation.navItem("Workers", routeLinks.infrastructure.workerMachines.root, null,
                {permission: [Permission.WorkerView], wildcard: true}),
            Navigation.navItem("Worker Pools", routeLinks.infrastructure.workerPools.root, null),
            Navigation.navItem("Machine Policies", routeLinks.infrastructure.machinePolicies.root, null, {permission: Permission.MachinePolicyView, wildcard: true}),
            Navigation.navItem("Proxies", routeLinks.infrastructure.proxies.root, null, {permission: Permission.ProxyView, wildcard: true}),
            Navigation.navItem("Accounts", routeLinks.infrastructure.accounts.root, null, {permission: Permission.AccountView, wildcard: true}),
        ];

        return (
            <main id="maincontent">
                <AreaTitle
                    link={routeLinks.infrastructure.root}
                    title="Infrastructure"
                />
                <NavigationSidebarLayout
                    navLinks={navLinks}
                    content={this.props.children}
                />
            </main>);
    }
}

export default InfrastructureLayout;