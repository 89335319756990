exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_previewHeader__29Ste {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n}\n", ""]);

// exports
exports.locals = {
	"previewHeader": "style_previewHeader__29Ste"
};