import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import AspNetCoreCategory from "../../Definitions/AspNetCoreCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import { CommonBuildServerPackagingTextForOctoExe, CommonBuildServerPackagingTextForIntegration } from "../CommonRegistrationText";

const BuildServerPackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET Core applications from build servers, we recommend using <code>dotnet publish</code> on
            the project followed by <code>octo.exe pack</code> on the output directory.
        </p>
        {CommonBuildServerPackagingTextForOctoExe}
        {CommonBuildServerPackagingTextForIntegration}
    </div>
);

export const BuildServerPackagingRegistrationForAspNetCore: CategorizedPackagingRegistration = {
    displayOrder: 300,
    categories: [AspNetCoreCategory],
    name: "Build Server",
    type: "BuildServerForAspNetCore",
    renderCard: InfoRegistrationCards.basic(
        () => <BuildServerPackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};