import * as React from "react";
import { connect } from "react-redux";
import GlobalState from "globalState";
import { ConfigurationFeaturesState, CurrentSpaceConfigurationState } from "areas/configuration/reducers/configurationArea";

export enum Feature {
    CommunityActionTemplates,
    MultiTenancy
}

interface ConnectedProps {
    features: ConfigurationFeaturesState;
    space: CurrentSpaceConfigurationState;
}

interface FeatureToggleProps {
    feature: Feature;
    enabled?: boolean;
}

const FeatureToggle: React.StatelessComponent<FeatureToggleProps & ConnectedProps> = props => {
    let featureStatus: boolean;

    switch (props.feature) {
        case Feature.CommunityActionTemplates:
            featureStatus = props.features.isCommunityActionTemplatesEnabled;
            break;
        case Feature.MultiTenancy:
            featureStatus = props.space ? props.space.isMultiTenancyEnabled : false;
            break;
    }

    if ((featureStatus && props.enabled) || (!featureStatus && !props.enabled)) {
        return (
            <span>
                {props.children}
            </span>
        );
    }

    return null;
};

FeatureToggle.defaultProps = { enabled: true };

const mapStateToProps = (state: GlobalState): ConnectedProps => {
    return {
        features: state.configurationArea.features,
        space: state.configurationArea.currentSpace
    };
};

export default connect(mapStateToProps)(FeatureToggle);