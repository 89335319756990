exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_headerColumn__2rA33:first-child {\n  width: 18.75rem;\n}\n.style_headerColumn__2rA33:nth-child(3) {\n  width: 1rem;\n}\n.style_rowColumn__1ZJ-u {\n  word-break: break-all;\n  word-wrap: break-word;\n  white-space: normal;\n  padding: 0.175rem 1.125rem!important;\n  vertical-align: middle!important;\n}\n.style_feedName__22Kme {\n  margin: 1rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"headerColumn": "style_headerColumn__2rA33",
	"rowColumn": "style_rowColumn__1ZJ-u",
	"feedName": "style_feedName__22Kme"
};