import * as React from "react";
import {Callout, CalloutType} from "components/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

interface AzurePowerShellScriptInfoProps {
    actionType: string;
}

const AzurePowerShellScriptInfo = (props: AzurePowerShellScriptInfoProps) => {
    const preamble = props.actionType === "Octopus.AzurePowerShell"
        ? "As this step is running a PowerShell script"
        : "If you are running a PowerShell script as part of this step";

    return <Callout type={CalloutType.Information}>
        {preamble} <ExternalLink href="WindowsManagementFramework5Download">Windows Management Framework 5</ExternalLink> (or newer) must be installed
        on the <strong>Octopus Server</strong>.
        </Callout>;
};

export default AzurePowerShellScriptInfo;