import * as React from "react";
import TaskResource from "client/resources/taskResource";
import {Section} from "components/Section/Section";
import CodeEditor from "components/CodeEditor/CodeEditor";
import PageDivider from "components/PageDivider/PageDivider";
import {NothingToSeeHere} from "components/NothingToSeeHere/NothingToSeeHere";
import {ScriptingLanguage} from "components/scriptingLanguage";

interface AdHocScriptTaskSummaryProps {
    task: TaskResource<any>;
}

export class AdHocScriptTaskSummary extends React.Component<AdHocScriptTaskSummaryProps, never> {
    render() {
        const task = this.props.task;
        const parameterValues = JSON.stringify(task.Arguments.Properties, null, 2);
        return <Section>
            {!task.Arguments.ActionTemplateId && <p>The task ran the script shown below.</p>}
            {task.Arguments.ActionTemplateId && <div>
                <p>This task ran the <strong>{task.Arguments.ActionTemplateName}</strong> step template, version <strong>{task.Arguments.ActionTemplateVersion}</strong>.</p>
                <PageDivider>Parameters</PageDivider>
                {parameterValues ? <pre>{parameterValues}</pre> : <NothingToSeeHere content="Template doesn't have any parameters" />}
            </div>}
            <PageDivider>Script</PageDivider>
            <CodeEditor allowFullScreen={true}
                        readOnly={true}
                        value={task.Arguments.ScriptBody}
                        language={task.Arguments.Syntax || ScriptingLanguage.PowerShell}
                        onChange={value => undefined}/>
        </Section>;
    }
}

export default AdHocScriptTaskSummary;