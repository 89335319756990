import * as React from "react";
import {repository} from "clientInstance";
import {required, Text} from "components/form";
import MarkdownEditor from "../../../../components/form/MarkdownEditor/MarkdownEditor";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";

interface AddProjectGroupProps {
    projectGroupCreated(id: string): void;
}

interface AddProjectGroupState extends DataBaseComponentState {
    name?: string;
    description?: string;
}

export default class AddProjectGroup extends DataBaseComponent<AddProjectGroupProps, AddProjectGroupState> {

    constructor(props: AddProjectGroupProps) {
        super(props);
        this.state = {};
    }

    async save() {
        await this.doBusyTask(async () => {
            const result = await repository.ProjectGroups.create({
                Id: null,
                Name: this.state.name,
                Description: this.state.description,
                EnvironmentIds: [],
                Links: null
            }, {});

            this.props.projectGroupCreated(result.Id);
            return true;
        });

        return false;
    }

    handleDescriptionChanged = (description: string) => {
        // in dobusy so the dialog resizes if the markdowneditor grows
        this.doBusyTask(async () => {
            this.setState({description});
        });
    }

    render() {
        return <SaveDialogLayout
                title="Add New Project Group"
                busy={this.state.busy}
                errors={this.state.errors}
                onSaveClick={() => this.save()}>
                <Text
                    label="New project group name"
                    value={this.state.name}
                    onChange={name => this.setState({name})}
                    validate={required("Please enter a project group name")}
                    autoFocus={true}
                />
                <MarkdownEditor
                    label="Project group description"
                    value={this.state.description}
                    onChange={this.handleDescriptionChanged}
                />
            </SaveDialogLayout>;
    }
}