import { repository } from "clientInstance";
import {
    DashboardResource,
    ProjectResource,
    ReleaseResource,
    DeploymentTemplateResource,
    TenantedDeploymentMode,
} from "client/resources";
import DashboardDataCube from "./DashboardDataCube";
import ProgressionDataCube from "./ProgressionDataCube";
import { DashboardFilters, DataCube, DimensionTypes } from "./DataCube";
import * as tenantTagsets from "components/tenantTagsets";
import { DataBaseComponent, DataBaseComponentState, Refresh } from "components/DataBaseComponent/DataBaseComponent";
import { ChannelResource } from "../../../../client/resources/channelResource";
import { LifecycleResource } from "../../../../client/resources/lifecycleResource";
import { DashboardFilter } from "client/repositories/dashboardRepository";
import { uniq } from "lodash";
import GlobalState from "globalState";
import { OnboardingState, onboardingStateUpdated } from "components/GettingStarted/reducers/onboardingArea";
import { connect } from "react-redux";

interface DashboardDataSourceProps {
    project?: ProjectResource;
    filters: DashboardFilters;
    render(props: DashboardDataSourceState): JSX.Element;
}

interface ConnectedProps {
    showFooterOnDashboard?: boolean;
}

interface DispatchProps {
    onOnboardingStateUpdated?(onboardingState: OnboardingState): void;
}

type DashboardDataSourceConnectedProps = DashboardDataSourceProps & ConnectedProps & DispatchProps;

interface DashboardDataSourceState extends DataBaseComponentState {
    projectLimit?: number;
    cube?: DataCube;
    hasInitialLoaded: boolean;
}

const refreshIntervalInMs = 6000;

class DashboardDataSourceInternal extends DataBaseComponent<DashboardDataSourceConnectedProps, DashboardDataSourceState> {
    private refreshFunction: () => Promise<DataCube>;
    private project: ProjectResource | undefined;
    private filters: DashboardFilters;
    private refresh: Refresh;

    constructor(props: DashboardDataSourceConnectedProps) {
        super(props);
        this.state = {
            hasInitialLoaded: false
        };
    }

    componentDidMount() {
        this.project = this.props.project;
        this.filters = this.props.filters;
        this.doBusyTask(async () => {
            this.setupDashboard();
            this.refresh = await this.startRefreshLoop(this.refreshCube, refreshIntervalInMs, true);
            this.setState({ hasInitialLoaded: true });
        });
    }

    componentWillReceiveProps(nextProps: DashboardDataSourceProps) {
        let shouldRefresh = false;

        if (nextProps.filters !== this.props.filters) {
            shouldRefresh = true;
        }

        if (nextProps.project !== this.props.project) {
            shouldRefresh = true;
        }

        if (!shouldRefresh) {
            return;
        }

        this.project = nextProps.project;
        this.filters = nextProps.filters;

        this.doBusyTask(async () => {
            this.setupDashboard();
            if (this.refresh) {
                await this.refresh();
                this.refreshOnboardingState();
            }
        });
    }

    render() {
        return this.props.render(this.state);
    }

    private refreshOnboardingState() {
        const onboardingState = {
            showFooterOnDashboard: false,
        };
        if (hasReachedMinimumThresholdForHidingOnboardingOnDashboard(this.state)) {
            onboardingState.showFooterOnDashboard = false;
            this.props.onOnboardingStateUpdated(onboardingState);
        }
        onboardingState.showFooterOnDashboard = true;
        this.props.onOnboardingStateUpdated(onboardingState);
    }

    private loadDashboardData(releaseId?: string): Promise<DashboardResource> {
        const args: DashboardFilter = this.project
            ? { projectId: this.project.Id, showAll: true }
            : { highestLatestVersionPerProjectAndEnvironment: true };

        if (releaseId) {
            args.releaseId = releaseId;
        }

        return repository.Dashboards.getDashboard(args);
    }

    private loadProgressionData() {
        return repository.Progression.getProgression(this.project);
    }

    private loadChannels(channelIds: string[]) {
        return repository.Channels.all({ ids: channelIds });
    }

    private async loadReleases(releaseId?: string): Promise<ReleaseResource[]> {
        if (releaseId) {
            return Promise.resolve([await repository.Releases.get(releaseId)]);
        }

        const result = await repository.Projects.getReleases(this.project);
        return result.Items;
    }

    private filteredRelease() {
        if (this.filters[DimensionTypes.Release]) {
            return Object.keys(this.filters[DimensionTypes.Release])[0];
        }
        return null;
    }

    private async loadPromotionData(release: ReleaseResource): Promise<DeploymentTemplateResource> {
        return repository.Releases.getDeploymentTemplate(release);
    }

    private async loadLifecycles(channelsAsync: Promise<ChannelResource[]>): Promise<LifecycleResource[]> {
        const channels = await channelsAsync;
        const lifecycleIds = channels.map(channel => channel.LifecycleId);
        const lifecycles = await repository.Lifecycles.allById(lifecycleIds);
        return Object.keys(lifecycles).map(id => lifecycles[id]);
    }

    private async listTenantsWithMissingVariables(): Promise<string[]> {
        const missingVariables = await repository.Tenants.missingVariables({ projectId: this.project.Id }, false);
        return missingVariables.map(mv => mv.TenantId);
    }

    private setupMainDashboard() {
        this.refreshFunction = async () => {
            const data = await this.loadDashboardData();
            this.setState({ projectLimit: data.ProjectLimit || 200 });
            return new DashboardDataCube(data, data.Tenants, [], [], null, [], []);
        };
    }

    private setupTenantedProjectDashboard() {
        this.refreshFunction = async () => {
            const releaseId = this.filteredRelease();
            const releases = await this.loadReleases(releaseId);
            const channeldIds = uniq(releases.map(r => r.ChannelId));
            const channelsLoader = this.loadChannels(channeldIds);
            const [data, channels, promotions, tagSets, lifecycles, missingVariableTenants] =
                await Promise.all([
                    this.loadDashboardData(releaseId),
                    channelsLoader,
                    releaseId ? this.loadPromotionData(releases[0]) : Promise.resolve(null),
                    tenantTagsets.getAll(),
                    this.loadLifecycles(channelsLoader),
                    this.listTenantsWithMissingVariables()]);
            return new DashboardDataCube(data, data.Tenants, releases, channels,
                promotions, tagSets, lifecycles, this.project, releaseId, missingVariableTenants);
        };
    }

    private setupProjectDashboard() {
        this.refreshFunction = async () => {
            const data = await this.loadProgressionData();
            const channeldIds = Object.keys(data.ChannelEnvironments);
            const channels = await this.loadChannels(channeldIds);
            return new ProgressionDataCube(data, channels, this.project);
        };
    }

    private setupDashboard() {
        if (!this.project) {
            this.setupMainDashboard();
        } else if (this.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted) {
            this.setupTenantedProjectDashboard();
        } else {
            this.setupProjectDashboard();
        }
    }

    private refreshCube = async () => {
        return { cube: await this.refreshFunction() };
    }
}

const mapStateToProps = (state: GlobalState) => {
    const currentOnboardingState = !state.onboardingArea.config ? {} : {
        showFooterOnDashboard: state.onboardingArea.config.showFooterOnDashboard,
    };
    return currentOnboardingState;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onOnboardingStateUpdated: (onboardingState: OnboardingState) => {
            dispatch(onboardingStateUpdated({
                showFooterOnDashboard: onboardingState.showFooterOnDashboard,
            }));
        }
    };
};

const DashboardDataSource = connect<{}, DispatchProps, DashboardDataSourceConnectedProps>(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDataSourceInternal);

export default DashboardDataSource;

export { DashboardDataSourceState };

export function hasReachedMinimumThresholdForHidingOnboardingOnDashboard(dataSourceState: DashboardDataSourceState): boolean {
    return (dataSourceState.hasInitialLoaded && dataSourceState.cube && Object.keys(dataSourceState.cube.projectIndex).length === 0);
}