exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".confirmationDialog_dialogHeader__3Axs1 {\n  background: #fcf1e2;\n}\n.confirmationDialog_warningIcon__1WYSq {\n  color: #fb8c00;\n}\n", ""]);

// exports
exports.locals = {
	"dialogHeader": "confirmationDialog_dialogHeader__3Axs1",
	"warningIcon": "confirmationDialog_warningIcon__1WYSq"
};