exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_filterField__2Hghd {\n  position: relative;\n  display: inline-block;\n}\n.style_filterField__2Hghd .style_searchIcon__xNH7f {\n  position: absolute;\n  right: 0;\n  top: 10px;\n  width: 1.5rem;\n  height: 1.5rem;\n}\n.style_filterTextBoxStandardWidth__1ZsgI {\n  width: 15.6rem !important;\n  /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */\n}\n.style_filterTextBoxFullWidth__x0qya {\n  width: 100% !important;\n}\n", ""]);

// exports
exports.locals = {
	"filterField": "style_filterField__2Hghd",
	"searchIcon": "style_searchIcon__xNH7f",
	"filterTextBoxStandardWidth": "style_filterTextBoxStandardWidth__1ZsgI",
	"filterTextBoxFullWidth": "style_filterTextBoxFullWidth__x0qya"
};