import * as React from "react";
import {
    TriggerResource,
    EnvironmentResource,
    ChannelResource,
    TriggerScheduleResource,
    ScopedDeploymentActionResource
} from "client/resources";
import ListTitle from "components/ListTitle/ListTitle";
import ScheduledTriggerDescriptionHelper from "utils/ScheduledTriggerDescriptionHelper/ScheduledTriggerDescriptionHelper";
import { OverflowMenuDeleteItem, OverflowMenuNavLink, OverflowMenuDialogItem } from "components/Menu/OverflowMenu";
import ListItem from "components/ListItem/ListItem";
type MenuItem = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDeleteItem;

const styles = require("./style.less");

interface ScheduledTriggerProps {
    trigger: TriggerResource;
    environments: EnvironmentResource[];
    channels: ChannelResource[];
    menuItems?: Array<MenuItem | MenuItem[]>;
}

export default class ScheduledTrigger extends React.PureComponent<ScheduledTriggerProps, any> {
    render() {
        return (
            <div className={this.props.trigger.IsDisabled ? styles.disabled : ""}>
                <ListItem overflowMenuItems={this.props.menuItems}>
                    <ListTitle>{this.props.trigger.Name}</ListTitle>
                    {this.getTriggerDescription()}
                </ListItem>
            </div>
        );
    }

    private getTriggerDescription() {
        const scheduleDescription = ScheduledTriggerDescriptionHelper.getScheduleDescription(this.props.trigger.Filter as TriggerScheduleResource);
        const actionDescription =
            ScheduledTriggerDescriptionHelper.getActionDescription(this.props.trigger.Action as ScopedDeploymentActionResource, this.props.environments, this.props.channels);

        return (
            <span>
            {actionDescription}{scheduleDescription}.
            </span>
        );
    }
}