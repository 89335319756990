exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .notifications_hide-sm__2ipZG {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .notifications_visible-sm__2BKM8 {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .notifications_hide-md___eL7A {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .notifications_visible-md__pp_fZ {\n    display: none !important;\n  }\n}\n.notifications_container__1gCNU {\n  display: flex;\n  flex-direction: column;\n  width: 18.75rem;\n}\n@media (max-width: 37.4rem) {\n  .notifications_container__1gCNU {\n    width: 16.25rem;\n  }\n}\n.notifications_grow__3dIkf {\n  flex-grow: 1;\n  padding-right: 0.625rem;\n}\n.notifications_notification__2xotL {\n  padding: 0.4375rem 0.75rem;\n  display: flex;\n}\n.notifications_title__ItsQn {\n  font-size: 0.875rem;\n  font-weight: 600;\n  padding-bottom: 0.0625rem;\n}\n.notifications_description__1DcYx {\n  font-size: 0.8125rem;\n  color: #666666;\n}\n.notifications_icon__3jaRG {\n  display: flex;\n  align-items: center;\n}\n.notifications_links__2erm- {\n  margin-top: 0.625rem;\n  font-size: 0.8125rem;\n}\n.notifications_notificationIcon__2I-wP {\n  width: 1.625rem;\n  height: 1.625rem;\n  line-height: 1.625rem !important;\n  text-align: center;\n  border-radius: 0.125rem;\n  font-size: 0.875rem;\n  color: #ffffff;\n}\n.notifications_textHappy__3uVq4 {\n  color: #74c0fb;\n}\n.notifications_bgHappy__1a-IF {\n  background-color: #74c0fb;\n}\n.notifications_textInfo__3VQbg {\n  color: #74c0fb;\n}\n.notifications_bgInfo__1wzjH {\n  background-color: #74c0fb;\n}\n.notifications_textWarn__-4KTH {\n  color: #fb8c00;\n}\n.notifications_bgWarn__NaFUU {\n  background-color: #fb8c00;\n}\n.notifications_textError__1Lb-D {\n  color: #db4437;\n}\n.notifications_bgError__1nmGI {\n  background-color: #db4437;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "notifications_hide-sm__2ipZG",
	"hideSm": "notifications_hide-sm__2ipZG",
	"visible-sm": "notifications_visible-sm__2BKM8",
	"visibleSm": "notifications_visible-sm__2BKM8",
	"hide-md": "notifications_hide-md___eL7A",
	"hideMd": "notifications_hide-md___eL7A",
	"visible-md": "notifications_visible-md__pp_fZ",
	"visibleMd": "notifications_visible-md__pp_fZ",
	"container": "notifications_container__1gCNU",
	"grow": "notifications_grow__3dIkf",
	"notification": "notifications_notification__2xotL",
	"title": "notifications_title__ItsQn",
	"description": "notifications_description__1DcYx",
	"icon": "notifications_icon__3jaRG",
	"links": "notifications_links__2erm-",
	"notificationIcon": "notifications_notificationIcon__2I-wP",
	"textHappy": "notifications_textHappy__3uVq4",
	"bgHappy": "notifications_bgHappy__1a-IF",
	"textInfo": "notifications_textInfo__3VQbg",
	"bgInfo": "notifications_bgInfo__1wzjH",
	"textWarn": "notifications_textWarn__-4KTH",
	"bgWarn": "notifications_bgWarn__NaFUU",
	"textError": "notifications_textError__1Lb-D",
	"bgError": "notifications_bgError__1nmGI"
};