import releasePageIds from "../Releases/ReleasesRoutes/releasePageIds";
import { pageId } from "utils/pageId";

export default () => ({
    projects: {
        root: pageId("Projects"),
    },
    project: () => {
        return {
            root: pageId("Project"),
            ...releasePageIds(),
            overview: pageId("Project.Overview", "Overview"),
            settings: pageId("Project.Settings", "Settings"),
            channels: pageId("Project.Channels", "Channels"),
            channel: pageId("Project.Channel", "Channel"),
            channelNew: pageId("Project.ChannelNew", "New Channels"),
            triggers: pageId("Project.Triggers", "Triggers"),
            deploymentTargetTrigger: pageId("Project.DeploymentTargetTrigger", "Deployment Target Trigger"),
            deploymentTargetTriggerNew: pageId("Project.DeploymentTargetTriggerNew", "New Deployment Target Trigger"),
            scheduledTrigger: pageId("Project.ScheduledTrigger", "Scheduled Trigger"),
            scheduledTriggerNew: pageId("Project.ScheduledTriggerNew", "New Scheduled Trigger"),
            variables: {
                root: pageId("Project.Variables", "Variables"),
                projectTemplates: pageId("Project.VariableTemplates", "Variable Templates"),
                commonTemplates: pageId("Project.VariableCommonTemplates", "Common Variable Templates"),
                library: pageId("Project.VariableLibrary", "Variables Library"),
                preview: pageId("Project.VariablePreview", "Variables Preview"),
                all: pageId("Project.VariableAll", "All Variables"),
            },
            process: {
                root: pageId("Project.Process", "Deployment Process"),
                stepTemplates: pageId("Project.ProcessStepTemplates", "Deployment Process Step Templates"),
                childStepTemplates: pageId("Project.ProcessChildStepTemplates", "Deployment Process Child Step Templates"),
                stepNew: pageId("Project.ProcessStepNew", "New Deployment Process Step"),
                step: pageId("Project.ProcessStep", "Deployment Process Step"),
            },
            childStepTemplates: pageId("Project.ChildStepTemplates", "Child Step Templates"),
            steptemplates: pageId("Project.StepTemplates", "Step Templates"),
        };
    }
});