exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_error__3zI1F {\n  padding: 1.5rem 1rem 1.5rem 1rem;\n  color: #db4437;\n  background-color: #f8e8e6;\n  text-align: left;\n}\n.style_error__3zI1F ul {\n  padding-left: 1rem;\n  margin-bottom: 0;\n  list-style-type: disc;\n  font-weight: 400;\n}\n.style_titleContainer__2bAHe {\n  display: flex;\n  justify-content: space-between;\n}\n.style_title__A5dbg {\n  font-weight: 500;\n}\n.style_detailLink__3naxR {\n  padding-top: 0.25rem;\n  padding-bottom: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"error": "style_error__3zI1F",
	"titleContainer": "style_titleContainer__2bAHe",
	"title": "style_title__A5dbg",
	"detailLink": "style_detailLink__3naxR"
};