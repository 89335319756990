import * as React from "react";
import ToolTip from "components/ToolTip/index";
const styles = require("./style.less");
import SmsIcon from "material-ui/svg-icons/notification/sms";

interface VariableDescriptionIconProps {
    description?: string;
    onClick?: () => void;
}

export default class VariableDescriptionIcon extends React.Component<VariableDescriptionIconProps> {
    render() {
        return <div
            className={this.props.onClick ? `${styles.iconContainer} ${styles.canClick}` : styles.iconContainer}
            onClick={this.props.onClick ? this.props.onClick : null}>
            {this.renderIcon()}
        </div>;
    }

    private renderIcon() {
        const icon = <SmsIcon className={this.props.onClick ? styles.highlightedDescriptionIcon : styles.descriptionIcon}/>;
        if (this.props.description) {
            return <div className={styles.iconSizing}><ToolTip content={this.props.description}>
                {icon}
            </ToolTip></div>;
        }
        return icon;
    }
}