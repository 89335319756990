import * as React from "react";
import TenantMultiSelect from "components/MultiSelect/TenantMultiSelect";
import TenantTagMultiSelect from "components/MultiSelect/TenantTagMultiSelect";
import TenantFilterButton from "components/TenantTagDesignDialog";
import TenantResource from "client/resources/tenantResource";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import {ResetLabelStrategy} from "components/LabelStrategy/LabelStrategy";
import {TagSetResource} from "../../client/resources";

interface AdvancedTenantSelectorProps {
    tagSets?: TagSetResource[];
    selectedTenantIds: string[];
    showTenants: boolean;
    tenants: TenantResource[];
    selectedTenantTags: string[];
    doBusyTask: DoBusyTask;
    showPreviewButton: boolean;
    onChange(tenantIds: string[], tenantTags: string[]): void;
}

const AdvancedTenantSelector: React.SFC<AdvancedTenantSelectorProps> = (props: AdvancedTenantSelectorProps) => {
    return <div>
        {props.showTenants && <TenantMultiSelect value={props.selectedTenantIds}
                           items={props.tenants}
                           onChange={tenantIds => props.onChange(tenantIds, props.selectedTenantTags)}/>}
        <TenantTagMultiSelect value={props.selectedTenantTags}
                              items={props.tagSets}
                              onChange={includedTenantTags => props.onChange(props.selectedTenantIds, includedTenantTags)}
                              doBusyTask={props.doBusyTask}
        />
        {props.showPreviewButton && <ResetLabelStrategy>
            <TenantFilterButton selectedTags={props.selectedTenantTags}
                                selectedTenants={props.selectedTenantIds}
                                showTenants={props.showTenants}
                                availableTenants={props.tenants ? props.tenants.map(t => t.Id) : null}
                                onUpdate={(selectedTenants, selectedTags) => props.onChange(selectedTenants, selectedTags)}/>
        </ResetLabelStrategy>}
    </div>;
};

interface AdvancedTenantsAndTenantTagsSelectorProps {
    selectedTenantIds: string[];
    tenants: TenantResource[];
    selectedTenantTags: string[];
    doBusyTask: DoBusyTask;
    showPreviewButton: boolean;
    onChange(tenantIds: string[], tenantTags: string[]): void;
}

export const AdvancedTenantsAndTenantTagsSelector: React.SFC<AdvancedTenantsAndTenantTagsSelectorProps>
    = (props: AdvancedTenantsAndTenantTagsSelectorProps) => {
    return <AdvancedTenantSelector
        selectedTenantIds={props.selectedTenantIds}
        showTenants={true}
        tenants={props.tenants}
        selectedTenantTags={props.selectedTenantTags}
        onChange={props.onChange}
        doBusyTask={props.doBusyTask}
        showPreviewButton={props.showPreviewButton}
    />;
};

interface AdvancedTenantTagsSelectorProps {
    selectedTenantTags: string[];
    tagSets?: TagSetResource[];
    doBusyTask: DoBusyTask;
    showPreviewButton: boolean;
    onChange(tenantTags: string[]): void;
}

export const AdvancedTenantTagsSelector: React.SFC<AdvancedTenantTagsSelectorProps> = (props: AdvancedTenantTagsSelectorProps) => {
    return <AdvancedTenantSelector
        tagSets={props.tagSets}
        selectedTenantIds={[]}
        showTenants={false}
        tenants={null} // Do NOT set this as an empty array, or stuff stops working :)
        selectedTenantTags={props.selectedTenantTags}
        onChange={(tenants, tags) => props.onChange(tags)}
        doBusyTask={props.doBusyTask}
        showPreviewButton={props.showPreviewButton}
    />;
};
