import FeedResource from "../../client/resources/feedResource";
import {
    NamedResource,
    TenantResource,
    TenantedDeploymentMode,
} from "client/resources";
import {tenantChipList, tenantChipListIncludingMissing} from "components/Chips/index";
import {Summary, SummaryNode} from "components/form";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";
import * as React from "react";
import {ScriptingLanguage} from "components/scriptingLanguage";
import {PackageReference} from "../../client/resources/packageReference";

export default class CommonSummaryHelper {

    public static resourceSummary(resourceId: string, resources: NamedResource[], resourceType: string): SummaryNode {
        if (resourceId) {
            const resource = resources.find(x => x.Id === resourceId);
            return resource
                ? Summary.summary(resource.Name)
                : Summary.placeholder("Using " + resourceType + " with id: " + resourceId); // Failed to find the resource.
        }
        return Summary.placeholder("No " + resourceType);
    }

    public static tenantDeploymentModeSummary(deploymentMode: TenantedDeploymentMode,
                                              tenantIds: string[],
                                              tenantTags: string[]): SummaryNode {

        const hasTags = tenantTags.length > 0;
        const hasTenants = tenantIds.length > 0;
        const hasTagOrTenant = hasTags || hasTenants;

        switch (deploymentMode) {
            case TenantedDeploymentMode.Untenanted:
                return Summary.default(<span>Only available for untenanted deployments</span>);

            case TenantedDeploymentMode.TenantedOrUntenanted:
                return hasTagOrTenant
                    ? Summary.summary(<span>Available for both untenanted deployments, and deployments to the associated tenants</span>)
                    : Summary.summary(<span><strong>Only available in untenanted deployments</strong> until you choose some tenants</span>);

            case TenantedDeploymentMode.Tenanted:
                return hasTagOrTenant
                    ? Summary.summary(<span>Only available for deployments to the associated tenants</span>)
                    : Summary.summary(<span><strong>Not available for any deployments</strong> until you choose some tenants</span>);
        }

        return Summary.placeholder(`Unknown tenant mode: ${deploymentMode}`);
    }

    public static tenantSummary(tenantIds: string[],
                                tenantTags: string[],
                                tenants: TenantResource[],
                                showMissing?: boolean): SummaryNode {

        const tenantChips = !!showMissing ? tenantChipListIncludingMissing(tenants, tenantIds) : tenantChipList(tenants, tenantIds);
        const hasTags = tenantTags.length > 0;
        const hasTenants = tenantIds.length > 0;
        const tenantDetails = <span>
            {hasTenants && <span>{tenantChips}</span>}
            {hasTags && <span>{hasTenants ? "plus any" : "Any"} tenants tagged with <TenantTagsList tags={tenantTags}/></span>}</span>;

        const hasTagOrTenant = hasTags || hasTenants;

        return hasTagOrTenant
            ? Summary.summary(<span>{tenantDetails}</span>)
            : Summary.summary("No tenants have been selected");
    }

    public static portSummary(port: number, textIfEmpty: string = null): SummaryNode {
        return port ? Summary.summary(port) : Summary.placeholder(textIfEmpty ? textIfEmpty : "No port");
    }

    public static packageSummary(pkg: PackageReference, feeds: FeedResource[], placeholder = "Choose a package to be deployed"): SummaryNode {
        const summary = [];
        if (pkg) {
            if (pkg.PackageId) {
                summary.push(<span>Package <strong>{pkg.PackageId}</strong></span>);
            } else {
                return Summary.placeholder(placeholder);
            }
            if (pkg.FeedId) {
                const feed = feeds.find(f => f.Id === pkg.FeedId);
                if (feed) {
                    summary.push(<span> from feed <strong>{feed.Name}</strong></span>);
                }
            }
        }
        if (summary.length > 0) {
            return Summary.summary(React.Children.toArray(summary));
        }
        return Summary.placeholder(placeholder);
    }

    public static scriptSummary(scriptBody: string, syntax: ScriptingLanguage) {
        if (!scriptBody) {
            return Summary.placeholder("The script body has not been defined");
        }
        if (syntax === ScriptingLanguage.FSharp) {
            return Summary.summary(<span>An <strong>F#</strong> script will be run</span>);
        } else if (syntax === ScriptingLanguage.CSharp) {
            return Summary.summary(<span>A <strong>C#</strong> script will be run</span>);
        }
        return Summary.summary(<span>A <strong>{syntax}</strong> script will be run</span>);
    }

}