import * as React from "react";
import {Text, Checkbox} from "components/form";
import {ExternalSecurityGroupProviderResource} from "../../../../client/resources/externalSecurityGroupProviderResource";
import {ActionButton} from "../../../../components/Button/ActionButton";
import {repository} from "../../../../clientInstance";
import {DataBaseComponent, DataBaseComponentState} from "../../../../components/DataBaseComponent/DataBaseComponent";
import {NamedReferenceItem} from "../../../../client/resources/namedReferenceItem";
import {SimpleList} from "../../../../components/List/SimpleList";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";

class NamedReferenceList extends SimpleList<NamedReferenceItem> {
}

type GroupSelectionState = { [key: string]: boolean };

interface AddExternalGroupDialogContentProps {
    lookupUri: string;

    onChange(selected: NamedReferenceItem[]): void;

    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface AddExternalGroupDialogContentState {
    selected: GroupSelectionState;
    searchText?: string;
    foundGroups?: NamedReferenceItem[];
}

class AddExternalGroupDialogContent extends React.Component<AddExternalGroupDialogContentProps, AddExternalGroupDialogContentState> {
    constructor(props: AddExternalGroupDialogContentProps) {
        super(props);
        this.state = {
            searchText: null,
            selected: {},
            foundGroups: null,
        };
    }

    handleSearch = () => {
        this.props.doBusyTask(async () => {
            const results = await repository.ExternalSecurityGroups
                .search(this.props.lookupUri, this.state.searchText);
            this.setState({foundGroups: results, selected: {}});
        });
    }

    renderRow = (group: NamedReferenceItem) => {
        const label = group.DisplayIdAndName ? `${group.DisplayName} (${group.Id})` : group.DisplayName;
        return <Checkbox value={this.state.selected[group.Id]}
                         onChange={this.handleSelected(group.Id)}
                         label={label}/>;
    }

    handleSelected = (groupId: string) => {
        return (e: any) => {
            const newState = {selected: {...this.state.selected, [groupId]: !this.state.selected[groupId]}};
            this.setState(newState);
            this.props.onChange(this.state.foundGroups.filter(g => !!newState.selected[g.Id]));
        };
    }

    render() {
        return <div>
            <div>
                <Text id="search"
                      value={this.state.searchText}
                      onChange={(val) => this.setState({searchText: val})}/>
                <ActionButton label="Search" onClick={this.handleSearch}/>
            </div>
            {this.state.foundGroups
                ? <NamedReferenceList items={this.state.foundGroups}
                                    onRow={this.renderRow}/>
                : <span>Search for and select an external group</span>}
        </div>;
    }
}

interface AddExternalGroupDialogButtonProps {
    securityGroupProvider: ExternalSecurityGroupProviderResource;

    onSelected(items: NamedReferenceItem[]): void;
}

interface AddExternalGroupDialogButtonState extends DataBaseComponentState {
    selected: NamedReferenceItem[];
}

class AddExternalGroup extends DataBaseComponent<AddExternalGroupDialogButtonProps, AddExternalGroupDialogButtonState> {
    constructor(props: AddExternalGroupDialogButtonProps) {
        super(props);
        this.state = {
            selected: []
        };
    }

    handleOkClick = () => {
        this.props.onSelected(this.state.selected);
        return true;
    }

    render() {
        const provider = this.props.securityGroupProvider;
        return <OkDialogLayout title={`Add ${provider.Name} Group`}
                               okButtonLabel="Add"
                               busy={this.state.busy}
                               errors={this.state.errors}
                               onOkClick={this.handleOkClick}>
            <AddExternalGroupDialogContent
                onChange={(selected: NamedReferenceItem[]) => this.setState({selected})}
                doBusyTask={this.doBusyTask}
                lookupUri={provider.LookupUri}/>
        </OkDialogLayout>;
    }
}

export default AddExternalGroup;