exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_variableSetLink__3vXSt {\n  margin-left: 1rem;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n}\n.style_header__-erwV {\n  cursor: pointer;\n  position: relative;\n  height: 100%;\n}\n.style_busy__154U- {\n  position: absolute;\n  z-index: 1;\n  width: 100%;\n}\n.style_textAndButtons__3qaS3 {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  align-items: center;\n}\n.style_editIcon__18-ze {\n  margin-left: 1rem;\n  align-items: center;\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n.style_editIcon__18-ze:hover {\n  opacity: 1;\n}\n", ""]);

// exports
exports.locals = {
	"variableSetLink": "style_variableSetLink__3vXSt",
	"header": "style_header__-erwV",
	"busy": "style_busy__154U-",
	"textAndButtons": "style_textAndButtons__3qaS3",
	"editIcon": "style_editIcon__18-ze"
};