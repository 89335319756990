import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { LinksMenu } from "components/LinksMenu/LinksMenu";
import { LinksMenuButton } from "components/LinksMenu/LinksMenuButton";
import {hasActiveDescendants, isMenuGroup, isMenuPopper, MenuNode} from "components/LinksMenu/MenuNode";
import { throttle } from "lodash";
import * as React from "react";
import EventListener, { withOptions } from "react-event-listener";
import {RouteComponentProps, withRouter} from "react-router";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
import {isUrlActive} from "../Navigation/isUrlActive";

interface PriorityNavigationProps {
    className: string;
    activeItemClassName: string;
    navigationItems: MenuNode[];
    maxNavigationItems: number;
    showHamburgerIcon?: boolean;
}

interface PriorityNavigationState {
    priorityItems: MenuNode[];
    moreItems: MenuNode[];
    isMoreMenuOpen: boolean;
    hasMeasurements: boolean;
    availableWidth?: number;
}

class PriorityNavigation extends BaseComponent<PriorityNavigationProps, PriorityNavigationState> {
    widthsArray: number[] = [];
    navigation: HTMLElement;
    onResizeThrottled: any;
    containerElement: HTMLElement;
    lastKnownContainerWidth: number;

    constructor(props: PriorityNavigationProps) {
        super(props);

        this.updateNavigation = this.updateNavigation.bind(this);
        this.howManyItemsInMenuArray = this.howManyItemsInMenuArray.bind(this);
        this.onResizeThrottled = throttle(this.onResize, 100);

        this.state = {
            hasMeasurements: false,
            isMoreMenuOpen: false,
            priorityItems: this.props.navigationItems,
            moreItems: [],
            availableWidth: null
        };
    }

    componentDidMount() {
        //Get width of all items in navigation menu
        this.widthsArray = Array.prototype.slice.call(this.navigation.children).map((item: HTMLElement) => item.getBoundingClientRect().width);
        this.setState({ hasMeasurements: true }, () => this.updateNavigation(this.props));
    }

    componentWillReceiveProps(nextProps: PriorityNavigationProps) {
        this.updateNavigation(nextProps);
    }

    render() {
        const { priorityItems, moreItems } = this.state;

        // Do first pass to measure all items
        if (!this.state.hasMeasurements) {
            return <nav className={this.props.className}>
                <ul ref={this.setNavigation}>
                    {priorityItems.map((item: any, index: number) => <RoutedNavItem key={`navItem-${index}`} item={item} activeItemClassName={this.props.activeItemClassName} />)}
                    <LinksMenuButton activeItemClassName={this.props.activeItemClassName} label="More" moreItems={this.state.moreItems} />
                </ul>
            </nav>;
        }

        // Measure the container to find out available width
        if (this.state.availableWidth === null) {
            // This div will live in the document for a brief moment, just long
            // enough for it to mount. We then use it to calculate its width, and
            // replace it immediately with the underlying component.
            return <div
                style={{ flexGrow: 1, width: "100%" }}
                ref={this.setMeasurementDiv}
            />;
        }

        return (
            <nav className={this.props.className}>
                <EventListener
                    target="window"
                    onResize={withOptions(this.onResizeThrottled, { passive: true })}
                />
                <ul>
                    {priorityItems.map((item: MenuNode, index: number) =>
                        <RoutedNavItem key={`navItem-${index}`} item={item} activeItemClassName={this.props.activeItemClassName} />)}
                    {moreItems.length > 0 && <LinksMenuButton
                        activeItemClassName={this.props.activeItemClassName}
                        icon={this.props.showHamburgerIcon && priorityItems.length === 0 ? "fa fa-bars" : null}
                        label="More"
                        moreItems={moreItems} />}
                </ul>
            </nav>
        );
    }

    private howManyItemsInMenuArray(array: number[], outerWidth: number, initialWidth: number, maximumNumberInNav: number) {
        let total = initialWidth;
        for (let i = 0; i < array.length; i++) {
            if (i > maximumNumberInNav) {
                return maximumNumberInNav;
            }

            total += array[i];

            if (total > outerWidth) {
                return i;
            }
        }

        return array.length;
    }

    private updateNavigation(props: PriorityNavigationProps) {
        if (!(this.state.hasMeasurements && this.state.availableWidth !== null)) {
            return;
        }

        const arrayAmount = this.howManyItemsInMenuArray(this.widthsArray, this.state.availableWidth, this.widthsArray[this.widthsArray.length - 1], props.maxNavigationItems);
        const navItemsCopy = props.navigationItems;
        const priorityItems = navItemsCopy.slice(0, arrayAmount);

        const moreItems = priorityItems.length !== navItemsCopy.length
            ? navItemsCopy.slice(arrayAmount, navItemsCopy.length).filter(item => !isMenuPopper(item))
            : [];

        this.setState({
            priorityItems,
            moreItems
        });
    }

    private onResize = () => {
        if (this.containerElement.offsetWidth === this.lastKnownContainerWidth) {
            return; // Nothing changed
        }

        this.setState({ availableWidth: null });
    }

    private showMoreMenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        this.setState({
            isMoreMenuOpen: !this.state.isMoreMenuOpen
        });
    }

    private hideMoreMenu = () => {
        this.setState({
            isMoreMenuOpen: false
        });
    }

    private setMeasurementDiv = (el: any) => {
        if (!el) {
            return;
        }
        this.containerElement = el.parentNode;
        this.lastKnownContainerWidth = this.containerElement.offsetWidth;

        this.setState({
            availableWidth: el.offsetWidth
        }, () => this.updateNavigation(this.props));
    }

    private setNavigation = (el: any) => {
        this.navigation = el;
    }
}

interface NavItemComponentProps {
    item: MenuNode;
    activeItemClassName: string;
}

type NavItemProps =  NavItemComponentProps & RouteComponentProps<{spaceId: string}>;

const NavItem: React.SFC<NavItemProps> = (props: NavItemProps) => {
    const item = props.item;

    if (isMenuGroup(item)) {
        const isActive = hasActiveDescendants(props.location.pathname, props.match.params.spaceId, item);
        return <li>
            <LinksMenu activeItemClassName={isActive ? props.activeItemClassName : null} items={item.children} label={item.label} />
        </li>;
    }
    if (isMenuPopper(item)) {
        return <MenuPopperLi
            class={isUrlActive(props.location.pathname, props.match.params.spaceId, item.url, false) ? props.activeItemClassName : null}
            icon={item.icon}
            text={item.text}
            title={item.title}
            onClick={(el: any) => item.onClick(el)} />;
    }
    return <li>
        <InternalNavLink activeClassName={props.activeItemClassName} to={item.url} exact={item.exact} title={item.title}>
            {item.icon}
            <span>{item.text}</span>
        </InternalNavLink>
    </li>;
};

const RoutedNavItem = withRouter<NavItemProps>(NavItem);

interface MenuPopperLiProps {
    icon: JSX.Element;
    class: string;
    text?: string;
    title?: string;
    display?: string;
    itemClass?: string;
    onClick: (e: any) => void;
}

class MenuPopperLi extends React.Component<MenuPopperLiProps> {
    anchorEl: HTMLElement;

    render() {
        return <li ref={this.setRef} className={this.props.itemClass}>
            <a className={this.props.class} onClick={this.onClick} href="#" title={this.props.title}>
                {this.props.icon}
            </a>
        </li>;
    }

    private onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        this.props.onClick(this.anchorEl);
    }

    private setRef = (el: HTMLElement) => {
        this.anchorEl = el;
    }
}

export default PriorityNavigation;