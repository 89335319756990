import * as React from "react";
import AreaTitle from "../AreaTitle";
import routeLinks from "../../routeLinks";
import {SpaceNotFoundContext} from "../StandardLayout/SpaceLoader";
import {EmptyPage} from "../EmptyPage/EmptyPage";
import {connect, MapStateToProps} from "react-redux";
import GlobalState from "../../globalState";
import {SpaceResource} from "../../client/resources";
import Select from "../form/Select/Select";
import {InternalRedirect} from "../Navigation/InternalRedirect";
const notFoundImage = require("../NotFound/404-image.svg");
const styles = require("./style.less");

interface SpaceNotFoundProps {
    spaceNotFoundContext: SpaceNotFoundContext;
}

interface ReduxStateProps {
    spaces?: SpaceResource[];
}

type Props = SpaceNotFoundProps & ReduxStateProps;

const image = {
    src: notFoundImage,
    alt: "Space couldn't be found"
};

interface SpaceNotFoundState {
    selectedSpaceId?: string;
}

class SpaceNotFoundInternal extends React.Component<Props, SpaceNotFoundState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const spaceItems = (this.props.spaces || []).map(space => ({
            value: space.Id,
            text: space.Name
        }));
        const title = `Sorry, the Space (${this.props.spaceNotFoundContext.missingSpaceId}) could not be found`;
        return <main id="maincontent">
            <AreaTitle
                link={routeLinks.withoutSpace().root}
                title="Space not found"
            />
            <EmptyPage
                title={title}
                image={image}
                description={<div>
                    <p>The space may not exist, or you may not have access to the Space. Contact the Space Manager to request access.</p>
                    {this.state.selectedSpaceId && <InternalRedirect to={routeLinks.forSpace(this.state.selectedSpaceId).root}/>}
                    {!this.state.selectedSpaceId && spaceItems.length > 0 && <div className={styles.selectContainer}><Select
                        items={spaceItems}
                        value={this.state.selectedSpaceId}
                        onChange={(selectedSpaceId: string) => this.setState({selectedSpaceId})}
                        label="Select another space"
                    /></div>}
                </div>}
            />
        </main>;
    }
}

const mapStateToProps: MapStateToProps<ReduxStateProps, SpaceNotFoundProps, GlobalState> = (state) => {
    return {
        spaces: state.configurationArea.spaces ? state.configurationArea.spaces.usersAccessibleSpaces : null
    };
};

export const SpaceNotFound = connect(mapStateToProps)(SpaceNotFoundInternal);