import * as React from "react";
import {
    ProjectedTeamReferenceDataItem
} from "client/resources";
import SvgTeamIcon from "material-ui/svg-icons/social/group";
import ListTitle from "components/ListTitle/ListTitle";
import {SimpleList} from "components/List";
import { ExternalSecurityGroupNameChip } from "components/Chips";

const styles = require("./ProjectedTeamsList.less");
const Children: React.SFC<any> = (props) => props.children;

class ProjectedTeamsList extends SimpleList<ProjectedTeamReferenceDataItem> { }

interface ProjectedTeamsListItemProps {
    projectedTeam: ProjectedTeamReferenceDataItem;
}

const ExternalGroupChips: React.SFC<{externalGroups?: string[]}> = ({externalGroups}) => {
    if (!externalGroups) {
        return null;
    }
    return <Children>
        {externalGroups.map(groupName => <ExternalSecurityGroupNameChip key={groupName} groupName={groupName}/>)}
    </Children>;
};

interface ProjectedTeamDescriptionProps {
    isDirectAssignment: boolean;
    externalGroups?: string[];
}

const ProjectedTeamDescription: React.SFC<ProjectedTeamDescriptionProps> = ({isDirectAssignment, externalGroups}) => {
    if (!externalGroups) {
        return null;
    }

    return <Children>
        {externalGroups && externalGroups.length > 0 && "Mapped from external source "}
        <ExternalGroupChips externalGroups={externalGroups}/>
        {isDirectAssignment && externalGroups && externalGroups.length > 0 &&  (<span> and is also <strong>directly assigned to team.</strong></span>)}
    </Children>;
};

const ProjectedTeamListItem: React.SFC<ProjectedTeamsListItemProps> = ({ projectedTeam }) => (
    <ListTitle>
        <div className={styles.root}>
            <SvgTeamIcon className={styles.icon} />
            <div className={styles.detail}>
                <div className={styles.description}>{projectedTeam.Name}</div>
                <div className={styles.externalGroups}>
                    <ProjectedTeamDescription isDirectAssignment={projectedTeam.IsDirectlyAssigned} externalGroups={projectedTeam.ExternalGroupNames} />
                </div>
            </div>
        </div>
    </ListTitle>
);

export {
    ProjectedTeamsList,
    ProjectedTeamListItem,
    ProjectedTeamsListItemProps,
    ProjectedTeamDescription,
    ProjectedTeamDescriptionProps,
    ExternalGroupChips,
    Children
};

export default ProjectedTeamsList;