import * as React from "react";
import { repository } from "clientInstance";
import { ProjectResource, NamedResource } from "client/resources";
import AutoComplete from "components/AutoComplete/AutoComplete";
import ReleaseResource from "client/resources/releaseResource";
import { ChannelResource } from "../../../../client/resources/channelResource";
import { ChannelChip } from "../../../../components/Chips/index";
const styles = require("./releaseFilter.less");

interface ReleaseFilterProps {
    releases: ReleaseResource[];
    channelIndex: { [channelId: string]: NamedResource };
    project: ProjectResource;
    value: string;
    filterUpdated: (value: string) => void;
}

interface ReleaseFilterState {
    value: string;
}

export default class ReleaseFilter extends React.Component<ReleaseFilterProps, ReleaseFilterState> {
    private releases: ReleaseResource[] = [];

    constructor(props: ReleaseFilterProps) {
        super(props);

        this.state = {
            value: ""
        };
    }

    async componentDidMount() {
        this.setState({
            value: await this.getValue(this.props.value)
        });
    }

    async componentWillReceiveProps(nextProps: ReleaseFilterProps) {
        if (nextProps.value === this.props.value) {
            return;
        }

        this.setState({
            value: await this.getValue(nextProps.value)
        });
    }

    render() {
        return <div className={styles.container}>
            <AutoComplete
                value={this.state.value}
                onChange={this.props.filterUpdated}
                getOptions={this.getOptions}
                fieldName={"release"}
                hintText="Enter release number"
            />
        </div>;
    }

    private getOptions = async (searchText: string) => {
        const hasMultipleChannels = Object.keys(this.props.channelIndex).length > 1;
        if (!searchText) {
            this.releases = this.props.releases;

            return {
                items: this.props.releases.map((release) => this.getItem(release, hasMultipleChannels)),
                containsAllResults: true
            };
        }
        const releases = await repository.Projects.getReleases(this.props.project,
            { searchByVersion: searchText, take: 5 });

        this.releases = releases.Items;

        return {
            items: releases.Items.map(release => this.getItem(release, hasMultipleChannels)),
            containsAllResults: releases.TotalResults === releases.Items.length
        };
    }

    private getItem(release: ReleaseResource, hasMultipleChannels: boolean) {
        const channel = this.props.channelIndex[release.ChannelId];
        const display = <span>{release.Version}
            {channel && hasMultipleChannels && <ChannelChip channelName={channel.Name} />}
        </span>;
        return {
            Name: release.Version,
            Id: release.Id,
            display
        };
    }

    private getValue = async (value: string) => {
        if (!value) {
            return Promise.resolve("");
        }

        let result = this.releases.find(release => release.Id === value);
        if (!result) {
            result = await repository.Releases.get(value);
        }

        return Promise.resolve(result.Version);
    }
}