exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_icon__2v2fK {\n  align-items: center;\n}\n.style_icon__2v2fK:hover {\n  opacity: 1;\n}\n.style_controlCell__Noi7B {\n  height: 100%;\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n}\n.style_lastColumn__Pva0n {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n.style_scopeCell__199T- {\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n.style_editIcon__22NHw {\n  align-items: center;\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n.style_editIcon__22NHw:hover {\n  opacity: 1;\n}\n.style_newIcon__1BSZs {\n  align-items: center;\n  color: #48B350;\n}\n.style_newIcon__1BSZs:hover {\n  opacity: 1;\n}\n.style_variablePlaceholderText__fpAJn {\n  height: 100%;\n  align-items: flex-end;\n  display: flex;\n  color: #9e9e9e;\n  font-size: 0.75rem;\n  padding: 0.25rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"icon": "style_icon__2v2fK",
	"controlCell": "style_controlCell__Noi7B",
	"lastColumn": "style_lastColumn__Pva0n",
	"scopeCell": "style_scopeCell__199T-",
	"editIcon": "style_editIcon__22NHw",
	"newIcon": "style_newIcon__1BSZs",
	"variablePlaceholderText": "style_variablePlaceholderText__fpAJn"
};