exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_tableContainer__18eku {\n  height: 60rem;\n}\n.style_sectionHeader__1AVRr {\n  background-color: rgba(153, 153, 153, 0.08);\n}\n", ""]);

// exports
exports.locals = {
	"tableContainer": "style_tableContainer__18eku",
	"sectionHeader": "style_sectionHeader__1AVRr"
};