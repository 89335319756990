import * as React from "react";
import { flatMap, isEqual, flatten, zipWith, keyBy, groupBy, Dictionary, memoize, findLast, sum } from "lodash";
import {ScopeSpecification as MutableScopeSpecification, VariableResource, VariableType} from "client/resources/variableResource";
import {CellFocus, FocusableCellType, RowType} from "areas/variables/CellFocus/CellFocus";
import ReadonlyVariableResource, {convertToFilterableValue} from "areas/variables/ReadonlyVariableResource";
import {VariableSetResource, ScopeValues} from "client/resources/variableSetResource";
import VariableEditorHeadings from "areas/variables/VariableEditorHeadings/VariableEditorHeadings";
import ScrollTable, {CellAligner, RenderArgs, RowRenderArgs} from "components/ScrollTable/ScrollTable";
import { EditVariableDialog, OpenVariableDialogArgs, OpenReferenceVariableDialogArgs, FocusField} from "areas/variables/EditVariableDialog/EditVariableDialog";
import {createEmptyFilter, FilterableValue, VariableFilter} from "areas/variables/VariableFilter/VariableFilter";
import VariableAdd from "areas/variables/VariableAdd/VariableAdd";
import {
    default as VariableFilterLayout,
    VariableFilterLayoutProps
} from "areas/variables/VariableFilterLayout/VariableFilterLayout";
import { FilteredVariableModel, VariableValueModel, VariableModel, VariablesModel} from "areas/variables/VariablesModel/VariablesModel";
import getVariablesMessages, {
    AllVariableMessages,
    ValueMessages
} from "areas/variables/VariableMessages/VariableMessages";
import getVariableRowRenderers, {VariableRowRenderer, VariableRowRenderProps} from "areas/variables/VariableRowRenderer/VariableRowRenderer";
import * as tenantTagsets from "components/tenantTagsets";
import {TagIndex} from "components/tenantTagsets";
import * as certificates from "components/certificates";
import {CertificateIndex} from "components/certificates";
import SensitiveFieldStates from "areas/variables/SensitiveFieldStates";
import {SensitiveState} from "components/form/Sensitive/Sensitive";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import {BorderCss} from "utils/BorderCss/BorderCss";
import {VariableStatus} from "areas/variables/VariableStatusIcon";
import { arrayValueFromQueryString } from "utils/ParseHelper/ParseHelper";
import {QueryStringFilters, QueryStringFiltersProps} from "components/QueryStringFilters/QueryStringFilters";
import { VariableQuery } from "areas/variables/VariableFilter";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch, Action } from "redux";
import { fetchAllAccounts } from "areas/infrastructure/reducers/accounts";
import GlobalState from "globalState";
import mergeScopeValues from "../MergeScopeValues";
import {getVariablesMessagesForEditor} from "./conversions";

interface VariableEditorProps {
    initialVariables: ReadonlyArray<VariableModel>;
    scopeValues: ScopeValues;
    isProjectScoped: boolean;
    isTenanted: boolean;
    doBusyTask: DoBusyTask;
    cellFocusResetKey: string;
    onVariablesChanged(variables: ReadonlyArray<VariableModel>): void;
    onLoad?(): void;
}

interface VariableEditorState {
    filter: VariableFilter;
    queryFilter?: VariableFilter;
    focus?: CellFocus;
    editVariableDialog?: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs;
    model?: VariableEditorModel;
    sensitiveFieldStates: SensitiveFieldStates;
    tagIndex?: TagIndex;
    certificateIndex?: CertificateIndex;
    measuredScopeCellWidth: number | undefined;
    relativeColumnWidths: ReadonlyArray<number>;
}

interface VariableEditorModel {
    readonly variables: VariablesModel;
    readonly deletedValueIds: ReadonlyArray<string>;
    readonly draftVariable: VariableModel;
}

const blankRowHeight = 48;
const defaultRelativeColumnWidths = [5, 8, 6];

const emptyTagIndex: TagIndex = {};

const FilterLayout: React.SFC<VariableFilterLayoutProps<VariableFilter>> = (props) => VariableFilterLayout<VariableFilter>(props);

const VariableQueryStringFilters = QueryStringFilters.For<VariableFilter, VariableQuery>();

class VariableEditor extends React.Component<VariableEditorProps, VariableEditorState> {
    private readonly getExistingVariableValuesMap: (variables: ReadonlyArray<VariableModel>) => Dictionary<VariableValueModel>;
    private readonly getMessages: (model: VariableEditorModel) => AllVariableMessages;
    private readonly getScopeValues: (scopeValues: ScopeValues) => ScopeValues;
    private readonly countValues: (variables: ReadonlyArray<VariableModel>) => number;

    constructor(props: VariableEditorProps) {
        super(props);

        this.getExistingVariableValuesMap = memoize((variables: ReadonlyArray<VariableModel>) => createExistingVariableValuesMap(variables));
        this.getScopeValues = memoize((scopeValues: ScopeValues) => createScopeValues(scopeValues));
        this.getMessages = memoize((model: VariableEditorModel) => createMessages(model));
        this.countValues = memoize((variables: ReadonlyArray<VariableModel>) => countValues(variables));

        const initialModel = getModel(this.availableScopes, this.props.initialVariables);
        this.state = {
            sensitiveFieldStates: {},
            filter: createEmptyFilter(),
            model: initialModel,
            focus: null,
            measuredScopeCellWidth: undefined,
            relativeColumnWidths: defaultRelativeColumnWidths
        };
    }

    componentDidMount() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }

        this.props.doBusyTask(async () => {
            const tagIndex =  tenantTagsets.getTagIndex();
            const certificateIndex = certificates.getCertificateIndex();
            this.setState({tagIndex: await tagIndex, certificateIndex: await certificateIndex});
        });
    }

    componentWillReceiveProps(nextProps: VariableEditorProps) {
        if (nextProps.cellFocusResetKey !== this.props.cellFocusResetKey) {
            this.setState({focus: null});
        }
        if (nextProps.initialVariables === this.props.initialVariables && nextProps.scopeValues === this.props.scopeValues) {
            return;
        }

        this.setState({
            sensitiveFieldStates: {},
            model: getModel(this.getScopeValues(nextProps.scopeValues), nextProps.initialVariables)
        });
    }
    render() {

        if (!this.state.model) {
            return null;
        }

        const messages = this.getMessages(this.state.model);

        const filteredVariables = this.getFilteredVariables();

        const rows = this.getVariableRowRenderers(filteredVariables, messages);

        return (
            this.state.model.variables && <React.Fragment>
                <VariableQueryStringFilters
                    key="queryStringFilters"
                    filter={this.state.filter}
                    getQuery={this.queryFromFilters}
                    getFilter={this.getFilter}
                    onFilterChange={filter => this.setState({ filter, queryFilter: filter })} />
                <FilterLayout
                    key="filterLayout"
                    filter={this.state.filter}
                    queryFilter={this.state.queryFilter}
                    availableScopes={this.availableScopes}
                    defaultFilter={createEmptyFilter()}
                    messages={messages}
                    isProjectScoped={this.props.isProjectScoped}
                    isTenanted={this.props.isTenanted}
                    onFilterChanged={filter => this.setState({filter})}
                    doBusyTask={this.props.doBusyTask}
                    renderContent={filterPanelIsVisible => <div>
                        <div tabIndex={0} onFocus={() => this.setState({focus: null})}/>
                        <ScrollTable
                            relativeColumnWidths={this.state.relativeColumnWidths}
                            onColumnWidthsChanged={relativeColumnWidths => this.setState({relativeColumnWidths})}
                            minimumColumnWidthsInPx={[200, 200, 230]}
                            overscanRowCount={10}
                            rowCount={rows.length}
                            shouldVirtualize={this.countValues(this.props.initialVariables) > 100}
                            rowHeight={index => {
                                const variableRowRenderer = rows[index];
                                return variableRowRenderer.height;
                            }}
                            headers={({cellAligner, columnWidthsInPercent, borderStyle}: RenderArgs) => {
                                return [
                                    <div style={{borderBottom: borderStyle.borderCssString, width: "100%"}}>
                                        <VariableEditorHeadings
                                            columnWidths={columnWidthsInPercent}
                                            isDisplayedFullWidth={!filterPanelIsVisible}
                                            cellAligner={cellAligner}
                                            onWidthMeasured={(index, width) => {
                                                if (index === 2) {
                                                    this.setState({measuredScopeCellWidth: width});
                                                }
                                            }}
                                            cells={[
                                                <span>Name</span>,
                                                <span>Value</span>,
                                                <span>Scope</span>
                                            ]}
                                        />
                                    </div>,
                                    this.renderNewVariableRow(cellAligner, borderStyle)
                                ];
                            }}
                            rowRenderer={({cellAligner, index, isVisible, columnWidthsInPercent, borderStyle}: RowRenderArgs): React.ReactNode => {
                                const variableRowRenderer = rows[index];
                                return variableRowRenderer.render(cellAligner, isVisible, !filterPanelIsVisible, borderStyle, columnWidthsInPercent);
                            }}
                        />
                        <div tabIndex={0} onFocus={() => this.setState({focus: null})}/>
                        <EditVariableDialog
                            title="Edit Variable"
                            openDialogArgs={this.state.editVariableDialog}
                            availableScopes={this.availableScopes}
                            isProjectScoped={this.props.isProjectScoped}
                            isTenanted={this.props.isTenanted}
                            onDone={(value, name) => this.updateVariablesState(prev =>  prev.updateValueAndName(value, name))}
                            onClosed={() => this.setState({editVariableDialog: null})}/>
                    </div>}
                />
            </React.Fragment>
        );
    }

    private findVariableByValue(value: VariableValueModel): VariableModel {
        return this.state.model.variables.variables.find(variable => variable.values.some(v => v.Id === value.Id));
    }

    private getVariableRowRenderers(filteredVariables: ReadonlyArray<FilteredVariableModel>,
                                    messages: AllVariableMessages): ReadonlyArray<VariableRowRenderer> {
        return flatMap<FilteredVariableModel, VariableRowRenderer>(filteredVariables, (variable, index) => {
            const variableMessages = messages.variableMessages[variable.originalIndex];
            const unfilteredVariable = getVariables(this.state.model).variables[variable.originalIndex];
            const filteredVariableMessages = zipWith(
                unfilteredVariable.values, variableMessages.valuesMessages,
                (value: VariableValueModel, valueMessages: ValueMessages) => {
                    return { value, messages: valueMessages };
                })
                .filter(z => variable.values.some(v => v.Id === z.value.Id))
                .map(z => z.messages);
            const variableRowRenderProps: VariableRowRenderProps = {
                variable: unfilteredVariable,
                variableIndex: index,
                values: variable.values,
                valueMessages: filteredVariableMessages,
                availableScopes: this.availableScopes,
                sensitiveFieldStates: this.state.sensitiveFieldStates,
                tagIndex: this.state.tagIndex ? this.state.tagIndex : emptyTagIndex,
                certificateIndex: this.state.certificateIndex,
                isProjectScoped: this.props.isProjectScoped,
                variableMessages,
                doBusyTask: this.props.doBusyTask,
                focus: this.state.focus && this.state.focus.rowType === RowType.Edit && { variableId: this.state.focus.variableId, cell: this.state.focus.cell},
                getValueStatus: (value: VariableValueModel) => this.getValueStatus(value, this.state.model),
                onDuplicateVariable: this.onDuplicateVariable,
                onDuplicateValue: this.onDuplicateValue,
                onAddValue: this.onAddValue,
                onResetChanges: this.onResetChanges,
                onDeleteValue: this.deleteValue,
                undoDeleteValue: this.undoDelete,
                openVariableEditor: this.openVariableEditor,
                changingToReferenceType: this.changingToReferenceType,
                onBlur: this.onBlur,
                onFocus: this.onFocus,
                onMergeClicked: this.onMergeClicked,
                rename: this.rename,
                onNameChanged: this.onNameChanged,
                onValueChanged: this.onValueChanged,
                onNavigateUp: this.onNavigateUp,
                onNavigateDown: this.onNavigateDown,
                onSensitiveStateChanged: this.onSensitiveStateChanged,
                getExistingVariable: this.getExistingVariable,
                scopeCellWidth: this.state.measuredScopeCellWidth

        };
            return [...getVariableRowRenderers(variableRowRenderProps)];
        });
    }

    private getExistingVariable = (value: VariableValueModel) => {
        return this.findExistingValue(value.Id);
    }

    private onDuplicateVariable = (variable: VariableModel) => {
        this.updateVariablesState(prev => prev.duplicateVariable(variable, (value: VariableValueModel) => !isValueDeleted(value, this.state.model.deletedValueIds)));
    }

    private onDuplicateValue = (value: VariableValueModel) => {
        this.updateVariablesState(prev => prev.duplicate(value));
    }

    private onAddValue = (variable: VariableModel, selectedValue: VariableValueModel) => {
        this.updateVariablesState(prev => prev.addValueToVariable(variable, selectedValue.Type));
    }

    private onResetChanges = (value: VariableValueModel) => {
        this.updateSensitiveState(value.Id, undefined);
        this.updateVariablesState(prev => prev.resetChanges(this.findExistingValue(value.Id)));
    }

    private undoDelete = (variable: VariableValueModel) => {
        this.updateDeletedVariableIds(prev => [...prev.filter(id => id !== variable.Id)]);
    }

    private openVariableEditor = (value: VariableValueModel, name: string, focus: FocusField) => {
        this.setState({editVariableDialog: { value, name, focus, referenceType: undefined}});
    }

    private changingToReferenceType = (value: VariableValueModel, name: string, referenceType: VariableType) => {
        this.setState({editVariableDialog: {value, name, focus: FocusField.Value, referenceType }});
    }

    private onMergeClicked = (variable: VariableModel, value: VariableValueModel) => {
        this.updateVariablesState(prev => prev.merge(variable));
    }

    private rename = (variable: VariableModel) => {
        this.updateVariablesState(prev => prev.automaticRenameToAvoidCollision(variable));
    }

    private onNameChanged = (variable: VariableModel, name: string) => {
        this.updateVariablesState(prev => prev.rename(variable, name));
    }

    private onValueChanged = (value: VariableValueModel) => {
        this.updateVariablesState(prev => prev.updateValue(value));
    }

    private onSensitiveStateChanged = (variable: VariableValueModel, state: SensitiveState) => {
        this.updateSensitiveState(variable.Id, state);
    }

    private onNavigateDown = (variable: VariableValueModel) => {
        if (!this.state.focus || this.state.focus.cell === FocusableCellType.ScopeEdit) {
            return; // do nothing, because you could be in an autocomplete in the scope cell
        }

        const nextVariable = getNextNavigateDownVariable(this.getFilteredVariables(), this.state.model, this.state.focus.cell, variable.Id);
        if (nextVariable) {
            this.setState(prev => ({focus: {...prev.focus, variableId: nextVariable.Id}}));
        }
    }

    private onNavigateUp = (value: VariableValueModel) => {
        if (!this.state.focus || this.state.focus.cell === FocusableCellType.ScopeEdit) {
            return; // do nothing, because you could be in an autocomplete in the scope cell
        }

        const previousVariable = getPreviousVariable(this.getFilteredVariables(), this.state.model, this.state.focus);
        if (previousVariable) {
            this.setState(prev => ({focus: {...prev.focus, variableId: previousVariable.Id}}));
        } else {
            this.setState(prev => {
                const addVariableIds = prev.model.draftVariable.values.map(v => v.Id);
                if (addVariableIds.length) {
                    const variableId = prev.focus.cell === FocusableCellType.Name
                        ? addVariableIds[0]
                        : addVariableIds[addVariableIds.length - 1];
                    return {focus: {
                        ...prev.focus,
                        rowType: RowType.Add,
                        variableId
                    }};
                }
                return {};
            });
        }

        function getPreviousVariable(filteredVariables: ReadonlyArray<FilteredVariableModel>,
                                     model: VariableEditorModel | undefined,
                                     focus: CellFocus): VariableValueModel | undefined {

            const containingGroupIndex = filteredVariables.findIndex(variable => variable.values.some(v => v.Id === value.Id));
            const precedingVariables = filteredVariables.slice(0, containingGroupIndex);

            if (focus.cell === FocusableCellType.Name) {
                const lastGroupWithANonDeletedVariable = findLast(precedingVariables, variable => variable.values.some(v => !isValueDeleted(v, model.deletedValueIds)));
                if (lastGroupWithANonDeletedVariable) {
                    return lastGroupWithANonDeletedVariable.values[0];
                }
            } else {
                const containingVariable = filteredVariables[containingGroupIndex];
                const valueIndex = containingVariable.values.findIndex(v => v.Id === value.Id);
                const precedingValues = containingVariable.values.slice(0, valueIndex);
                const allPrecedingValues = [...flatten<VariableValueModel>(precedingVariables.map(g => [...g.values])),
                    ...precedingValues];
                return findLast(allPrecedingValues, v => !isValueDeleted(v, model.deletedValueIds));
            }
        }
    }

    private onBlur = (variable: VariableValueModel, blurredFrom: FocusableCellType) => {
        this.setState((prev) => {
            if (blurredFrom === prev.focus.cell && prev.focus.variableId === variable.Id && prev.focus.rowType === RowType.Edit) {
                return {focus: null};
            }
            return {};
        });
    }

    private onFocus = (variable: VariableValueModel, focus: FocusableCellType) => {
        this.setState({focus: {cell: focus, variableId: variable.Id, rowType: RowType.Edit}});
    }

    private renderNewVariableRow(cellAligner: CellAligner,
                                 borderStyle: BorderCss) {
        return <VariableAdd
            availableScopes={this.availableScopes}
            borderStyle={borderStyle}
            sensitiveFieldStates={this.state.sensitiveFieldStates}
            tagIndex={this.state.tagIndex ? this.state.tagIndex : {}}
            isProjectScoped={this.props.isProjectScoped}
            isTenanted={this.props.isTenanted}
            cellAligner={cellAligner}
            variable={this.state.model.draftVariable}
            doBusyTask={this.props.doBusyTask}
            certificateIndex={this.state.certificateIndex}
            onAdded={this.handleNewVariableAdded}
            onChanged={draftVariable => this.updateModel(prev => ({...prev, draftVariable}))}
            focus={this.state.focus && this.state.focus.rowType === RowType.Add && {
                variableId: this.state.focus.variableId,
                cell: this.state.focus.cell
            }}
            onFocus={(focus) => {
                if (!focus) {
                    this.setState({focus: null});
                } else {
                    this.setState({focus: {cell: focus.cell, variableId: focus.variableId, rowType: RowType.Add}});
                }
            }}
            onBlur={(blurredFrom) => this.setState((prev) => {
                if (prev.focus.rowType === RowType.Add
                    && prev.focus.variableId === blurredFrom.variableId
                    && prev.focus.cell === blurredFrom.cell) {
                    return {focus: null};
                }
                return {};
            })}
            onNavigateDown={() => this.setState(prev => {
                const cellToFocus = prev.focus ? prev.focus.cell : FocusableCellType.Name;

                const nextVariable = getNextNavigateDownVariable(this.getFilteredVariables(), prev.model, cellToFocus, undefined);
                if (nextVariable) {
                    return {focus: {rowType: RowType.Edit, variableId: nextVariable.Id, cell: cellToFocus}};
                }
                return {};
            })}
            onSensitiveStateChanged={(id, state) => this.updateSensitiveState(id, state)}
            scopeCellWidth={this.state.measuredScopeCellWidth}
        />;
    }

    private handleNewVariableAdded = () => {
        this.updateModel(prev => ({
            ...prev,
            draftVariable: createEmptyDraftVariable(),
            variables: prev.variables.addVariable(prev.draftVariable)
        }));
    }

    private deleteValue = (value: VariableValueModel) => {
        this.updateModel(prev => {
            if (this.getValueStatus(value, prev) === VariableStatus.New) {
                return {
                    ...prev,
                    variables: prev.variables.delete(value)
                };
            } else {
                return {
                    ...prev,
                    deletedValueIds: [...prev.deletedValueIds, value.Id]
                };
            }
        });
    }

    private updateDeletedVariableIds(getUpdatedVariableIds: (previousDeletedVariableIds: ReadonlyArray<string>) => ReadonlyArray<string>) {
        this.updateModel(prev => ({
            ...prev,
            deletedValueIds: getUpdatedVariableIds(prev.deletedValueIds)
        }));
    }

    private updateVariablesState(getUpdatedVariables: (variables: VariablesModel) => VariablesModel) {
        this.updateModel(prev => ({
            ...prev,
            variables: getUpdatedVariables(prev.variables)
        }));
    }

    private updateModel(getUpdatedModel: (previousModel: VariableEditorModel) => VariableEditorModel) {
        this.setState(prevState => ({ model: getUpdatedModel(prevState.model)}), () => {

            const updatedModel = this.state.model;

            const nonDeletedVariables = getNonDeletedVariables(getAllVariables(updatedModel), updatedModel.deletedValueIds);
            this.props.onVariablesChanged(nonDeletedVariables);
        });
    }

    private updateSensitiveState(variableId: string, state: SensitiveState | undefined) {
        this.setState(prev => {
            return {sensitiveFieldStates: {...prev.sensitiveFieldStates, [variableId]: state}};
        });
    }

    private getFilteredVariables() {
        const messages = this.getMessages(this.state.model);
        return getVariables(this.state.model)
            .filterVariables(this.state.filter, messages, this.availableScopes, convertToFilterableValue);
    }

    private get availableScopes(): ScopeValues {
        return this.getScopeValues(this.props.scopeValues);
    }

    private getValueStatus(value: VariableValueModel, model: VariableEditorModel | undefined): VariableStatus {
        if (isValueDeleted(value, model.deletedValueIds)) {
            return VariableStatus.Deleted;
        }
        const existingValue: VariableValueModel = this.findExistingValue(value.Id);
        if (existingValue) {
            return valuesAreEquivalent(value, existingValue)
                ? VariableStatus.Existing
                : VariableStatus.Modified;
        }
        return VariableStatus.New;
    }

    private findExistingValue(valueId: string): VariableValueModel | undefined {
        const existingVariable = this.getExistingVariableValuesMap(this.props.initialVariables)[valueId];
        return !existingVariable ? undefined : existingVariable;
    }

    private queryFromFilters = (filter: VariableFilter): VariableQuery => {
        const query = {
            name: filter.name,
            value: filter.value,
            description: filter.description,
            filterEmptyValues: filter.filterEmptyValues ? "true" : undefined,
            filterDuplicateNames: filter.filterDuplicateNames ? "true" : undefined,
            filterNonPrintableCharacters: filter.filterNonPrintableCharacters ? "true" : undefined,
            filterVariableSubstitutionSyntax: filter.filterVariableSubstitutionSyntax ? "true" : undefined,
            environment: [...filter.scope.Environment],
            machine: [...filter.scope.Machine],
            role: [...filter.scope.Role],
            action: [...filter.scope.Action],
            channel: [...filter.scope.Channel],
            tenantTag: [...filter.scope.TenantTag]
        };

        return query;
    }

    private getFilter = (query: VariableQuery): VariableFilter => {
        const filter: VariableFilter = {
            name: query.name || "",
            value: query.value || "",
            description: query.description || "",
            filterEmptyValues: query.filterEmptyValues === "true",
            filterDuplicateNames: query.filterDuplicateNames === "true",
            filterNonPrintableCharacters: query.filterNonPrintableCharacters === "true",
            filterVariableSubstitutionSyntax: query.filterVariableSubstitutionSyntax === "true",
            scope: {
                Environment: arrayValueFromQueryString(query.environment),
                Machine: arrayValueFromQueryString(query.machine),
                Role: arrayValueFromQueryString(query.role),
                Action: arrayValueFromQueryString(query.action),
                Channel: arrayValueFromQueryString(query.channel),
                TenantTag: arrayValueFromQueryString(query.tenantTag)
            }
        };

        return filter;
    }
}

function getNonDeletedVariables(variables: ReadonlyArray<VariableModel>, deletedValueIds: ReadonlyArray<string>): ReadonlyArray<VariableModel> {
    return variables.map(variable => {
        const values = variable.values.filter(v => !isValueDeleted(v, deletedValueIds));

        if (values.length === 0) {
            return null;
        }

        return {
            name: variable.name,
            values
        };
    }).filter(v => !!v);
}

function countValues(variables: ReadonlyArray<VariableModel>): number {
    return sum(variables.map(v => v.values.length));
}

function createScopeValues(scopeValues: ScopeValues): ScopeValues {
    return mergeScopeValues([scopeValues]);
}

function createMessages(model: VariableEditorModel): AllVariableMessages {
    const nonDeletedVariables = model.variables.variables.map<VariableModel>(variable => {
            return {
                name: variable.name,
                values: variable.values.map(v => isValueDeleted(v, model.deletedValueIds) ? null : v)
            };
        });

    return getVariablesMessagesForEditor(nonDeletedVariables);
}

function isValueDeleted(value: VariableValueModel, deletedValueIds: ReadonlyArray<string>) {
    return deletedValueIds.find((id) => id === value.Id);
}

function getVariables(model?: VariableEditorModel): VariablesModel {
    return model ? model.variables : new VariablesModel([]);
}

function createExistingVariableValuesMap(variables: ReadonlyArray<VariableModel>): Dictionary<VariableValueModel> {
    return keyBy(getAllValues(variables), v => v.Id);
}

function getAllVariables(model: VariableEditorModel) {
    return isVariableEmpty(model.draftVariable) ?
        model.variables.variables :
        [...model.variables.variables, model.draftVariable];
}

function getModel(availableScopes: ScopeValues, variables: ReadonlyArray<VariableModel>): VariableEditorModel {
    const sortedVariables = new VariablesModel(variables).sort(availableScopes);
    const deletedValueIds: string[] = [];
    return {
        variables: sortedVariables,
        deletedValueIds,
        draftVariable: createEmptyDraftVariable(),
    };
}

function isVariableEmpty(variable: VariableModel) {
    return variable.name === "";
}

function createEmptyDraftVariable(): VariableModel {
    return {name: "", values: [new VariableValueModel()]};
}

function valuesAreEquivalent(left: VariableValueModel, right: VariableValueModel) {
    return isEqual(left, right);
}

function getAllValues(variables: ReadonlyArray<VariableModel>): ReadonlyArray<VariableValueModel> {
    return flatten(variables.map(variable => [...variable.values]));
}

function getNextNavigateDownVariable(filteredVariables: ReadonlyArray<FilteredVariableModel>,
                                     model: VariableEditorModel | undefined,
                                     focusedCell: FocusableCellType,
                                     currentVariableId: string | null): VariableValueModel | undefined {
    const containingVariableIndex = filteredVariables.findIndex(v => v.values.some(value => value.Id === currentVariableId));
    const subsequentVariables = filteredVariables.slice(containingVariableIndex + 1);

    if (focusedCell === FocusableCellType.Name) {
        const firstVariableWithANonDeletedValue = subsequentVariables
            .find(variable => variable.values.some(v => !isValueDeleted(v, model.deletedValueIds)));
        if (firstVariableWithANonDeletedValue) {
            return firstVariableWithANonDeletedValue.values[0];
        }
    } else {
        const containingVariable = filteredVariables[containingVariableIndex];
        const nextValuesInSameVariable = containingVariable
            ? containingVariable.values.slice(containingVariable.values.findIndex(v => v.Id === currentVariableId) + 1)
            : [];

        const allSubsequentVariables = [...nextValuesInSameVariable, ...flatten<VariableValueModel>(subsequentVariables.map(variable => [...variable.values]))];
        return allSubsequentVariables.find(v => !isValueDeleted(v, model.deletedValueIds));
    }
}

const mapStateToProps = (state: GlobalState, props: any) => ({ });
const mapDispatchToProps = (dispatch: Dispatch<Action<any>>) => bindActionCreators({onLoad: fetchAllAccounts}, dispatch);

const ConnectedVariableEditor = connect<{}, {}, VariableEditorProps>(mapStateToProps, mapDispatchToProps)(VariableEditor);

export default ConnectedVariableEditor;
