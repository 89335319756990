import * as React from "react";
import Tag from "components/Tag";
import ToolTip from "components/ToolTip/index";
import {TagIndex} from "components/tenantTagsets";

interface TagsListProps {
    tagIndex: TagIndex;
    canonicalNames: string[];
    small?: boolean;
}

const TagsList: React.StatelessComponent<TagsListProps> = (props: TagsListProps) => {
    const tags = props.canonicalNames.map(name => props.tagIndex[name]);
    return <span>
        {tags.map((tag) => props.small ?
                <ToolTip content={tag.Name} key={tag.Name}><Tag
                    tagName={tag.Name}
                    tagColor={tag.Color}
                    description={tag.Description}
                    small={true}
                /></ToolTip>
                :
                <Tag
                    key={tag.Name}
                    description={tag.Description}
                    tagName={tag.Name}
                    tagColor={tag.Color}
                />)}
        </span>;
};

export default TagsList;