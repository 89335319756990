import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {repository} from "clientInstance";
import {ActionSummaryProps} from "../actionSummaryProps";
import {ActionExecutionLocation} from "../../../client/resources";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import FeedResource, {FeedType} from "client/resources/feedResource";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import PackageSelector from "components/PackageSelector/PackageSelector";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import {WildflyStateProperties} from "./wildflyFeatureTemplate";
import routeLinks from "../../../routeLinks";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import {
    GetPrimaryPackageReference, InitialisePrimaryPackageReference,
    SetPrimaryPackageReference
} from "../../../client/resources";
import {DataBaseComponent, DataBaseComponentState} from "../../DataBaseComponent";

class DeployToWildflyActionSummary extends BaseComponent<ActionSummaryProps, any> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Deploy a Java application to Wildfly 10+ and Red Hat JBoss EAP 6+
        </div>;
    }
}

interface DeployToWildflyProperties extends WildflyStateProperties {
    "Octopus.Action.Package.TransferPath": string;
}

interface DeployToWildflyActionEditState extends DataBaseComponentState {
    feeds: FeedResource[];
}

export class DeployToWildflyActionEdit extends DataBaseComponent<ActionEditProps<DeployToWildflyProperties>, DeployToWildflyActionEditState> {
    constructor(props: ActionEditProps<DeployToWildflyProperties>) {
        super(props);

        this.state = {
            feeds: []
        };
    }

    async componentDidMount() {
        await this.loadFeeds((feeds) => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);
        const feed = _.find(this.state.feeds, f => f.Id === pkg.FeedId);

        const help = this.state.feeds.length > 0
            ? <span>
                This step is used to deploy a package to one or more machines which may be sourced from an external feed or the Octopus built-in feed.
                You can configure the remote machines to deploy to in the <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>Infrastructure</InternalLink> tab.
            </span>
            : <span>Choose the package you wish to deploy.</span>;

        return <div>
            <ExpanderSectionHeading title="Package Details"/>
            <ExpandableFormSection
                errorKey="Octopus.Action.Package.PackageId|Octopus.Action.Package.FeedId"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Package"
                summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                help={help}>
                <PackageSelector
                    packageId={pkg.PackageId}
                    feedId={pkg.FeedId}
                    onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({PackageId: packageId}, this.props.packages))}
                    onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({FeedId: feedId}, this.props.packages))}
                    packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                    feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                    projectId={this.props.projectId}
                    feeds={this.state.feeds}
                    localNames={this.props.localNames}
                    feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven]}
                    refreshFeeds={this.loadFeeds}
                />
                <PackageDownloadOptions
                    packageAcquisitionLocation={pkg.AcquisitionLocation}
                    onPackageAcquisitionLocationChanged={acquisitionLocation =>
                        this.props.setPackages(SetPrimaryPackageReference({AcquisitionLocation: acquisitionLocation}, this.props.packages))}
                    feed={feed}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames}/>
            </ExpandableFormSection>
        </div>;
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    actionType: "Octopus.WildFlyDeploy",
    summary: (properties, targetRolesAsCSV, packages) => <DeployToWildflyActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    edit: DeployToWildflyActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.CustomScripts",
            "Octopus.Features.JsonConfigurationVariables",
            "Octopus.Features.SubstituteInFiles"],
        permanent: ["Octopus.Features.WildflyDeployCLI"]
    }
});