exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_scopesContainer__IBW5t {\n  align-items: center;\n  display: inline-flex;\n  height: 100%;\n  width: 100%;\n}\n.style_clickable__10uIH {\n  cursor: pointer;\n}\n.style_scopeCell__2BUVR {\n  height: 100%;\n}\n.style_clickContainer__39K3I {\n  height: 100%;\n}\n.style_chipContainer__1mzTT {\n  display: inline-flex;\n  align-items: center;\n}\n.style_hidden__3Ejjl {\n  visibility: hidden;\n}\n.style_allChipsContainer__TPf5B {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: center;\n}\n.style_focusManagedWrapper__2rrtJ {\n  height: 100%;\n}\n.style_defineScope__SDFAX {\n  cursor: inherit;\n  overflow: hidden;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"scopesContainer": "style_scopesContainer__IBW5t",
	"clickable": "style_clickable__10uIH",
	"scopeCell": "style_scopeCell__2BUVR",
	"clickContainer": "style_clickContainer__39K3I",
	"chipContainer": "style_chipContainer__1mzTT",
	"hidden": "style_hidden__3Ejjl",
	"allChipsContainer": "style_allChipsContainer__TPf5B",
	"focusManagedWrapper": "style_focusManagedWrapper__2rrtJ",
	"defineScope": "style_defineScope__SDFAX"
};