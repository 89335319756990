import * as React from "react";
import FormFieldProps from "./FormFieldProps";
import { Omit } from "utils/omit";
interface FieldValidation {
    error?: string;
}
interface WithValidateOnChangeExternalProps<TField> extends FormFieldProps<TField> {
    validate?(value: TField): string;
    onValidate?(value: string): void;
}

interface WithValidateOnChangeInjectedProps<TField> {
    validation: FieldValidation;
    onChange: (value: TField) => void;
}

interface WithValidateOnChangeState {
    error: string;
}

const withValidateOnChange = (options: {} = {}) =>
    <TOriginalProps extends WithValidateOnChangeInjectedProps<TField>, TField>(
        Component: (React.ComponentType<TOriginalProps & FormFieldProps<TField>>)
    ) => {
        type ResultProps = Omit<TOriginalProps, keyof WithValidateOnChangeInjectedProps<TField>> & WithValidateOnChangeExternalProps<TField>;

        class WithValidateOnChange extends React.Component<ResultProps & { forwardedRef: any}, WithValidateOnChangeState> {
            static displayName = `WithValidateOnChange(${Component.displayName || Component.name})`;

            constructor(props: ResultProps & { forwardedRef: any}) {
                super(props);
                this.state = {
                    error: null
                };
            }

            handleChange = (fieldValue: TField) => {
                const {validate, onValidate, onChange} = this.props;
                const value = typeof fieldValue === "string" && fieldValue === "" ? null : fieldValue;
                if (validate) {
                    const result = validate(value);
                    this.setState({error: result});
                    if (onValidate) {
                        onValidate(result);
                    }
                }
                if (onChange) {
                    onChange(value);
                }
            }

            render(): JSX.Element {
                const validate = { validation: this.state};
                //TODO: Remove use of any here.
                //Try as I might, I could not get Typescript to understand that these types do indeed line up.
                //Convinced this is a bug in TS at this point. Please be aware this wasted a lot of time, so
                //try and change this at the risk of your own mental health.
                const WrappedComponent: any = Component;
                const {forwardedRef, ...rest} = this.props;
                return (<WrappedComponent {...rest} ref={forwardedRef} onChange={this.handleChange} {...validate} />);
            }
        }

        return React.forwardRef((props: ResultProps, ref) => (
            <WithValidateOnChange {...props} forwardedRef={ref}/>
        ));
    };

export default withValidateOnChange;
export { WithValidateOnChangeInjectedProps,  WithValidateOnChangeExternalProps,  WithValidateOnChangeState, withValidateOnChange };