import * as React from "react";
import { RenderRegistrationCardProps } from "./endpointRegistry";
import { CustomDialog as Dialog } from "components/Dialog/CustomDialog";
import { InfoDialogLayout, SmallDialogFrame } from "components/DialogLayout/Custom";
import { InformationalEndpointCard, EndpointCardDetailProps } from "./EndpointCard";

export class InfoRegistrationCards {
    static basic(
        renderView: (props: RenderRegistrationCardProps) => React.ReactElement<any>,
        getCardProps: (props: RenderRegistrationCardProps) => EndpointCardDetailProps,
        additionalActions?: (props: RenderRegistrationCardProps) => React.ReactElement<any>
    ) {
        return (renderProps: RenderRegistrationCardProps) => {
            const registration = renderProps.registration;

            return (<InformationalEndpointCard
                {...getCardProps(renderProps)}
                renderDialog={({closeDialog, open}) => (
                    <Dialog open={open} close={closeDialog} render={(dialogProps) => (
                        <InfoDialogLayout
                        {...dialogProps}
                        title={registration && registration.name}
                        frame={SmallDialogFrame}
                        additionalActions={additionalActions ? additionalActions(renderProps) : null}
                        >
                            {renderView(renderProps)}
                        </InfoDialogLayout>
                    )}/>
                )}
            />);
        };
    }
}