exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_buttonList__1GeoC {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.style_buttonList__1GeoC > * {\n  margin-right: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"buttonList": "style_buttonList__1GeoC"
};