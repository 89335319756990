import * as React from "react";
import { ChannelResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { ChannelChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface ChannelMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

const ChannelTypedMultiSelect = MultiSelect<ReferenceDataItem>();

const ChannelMultiSelect: React.StatelessComponent<ChannelMultiSelectProps> = props => {
    const chipRenderer = (r: ReferenceDataItem, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.Channel}
            chipRender={item => <ChannelChip
                onRequestDelete={onRequestDelete}
                channelName={item.Name} />}
        />;
    };

    return <ChannelTypedMultiSelect
        items={props.items}
        fieldName="channels"
        renderChip={chipRenderer}
        {...props} />;
};

export default ChannelMultiSelect;