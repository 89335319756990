import * as React from "react";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { Text, MarkdownEditor, Select, required } from "components/form";
import WizardDialogLayout from "components/DialogLayout/WizardDialogLayout";
import WizardPage from "components/DialogLayout/WizardPage";

interface DemoWizardDialogProps {
    title: string;
}

interface DemoWizardDialogState extends DataBaseComponentState {
    name: string;
    description: string;
    dialogResizeKey: string;
}

export class DemoWizard extends DataBaseComponent<DemoWizardDialogProps, DemoWizardDialogState> {

    constructor(props: DemoWizardDialogProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            dialogResizeKey: "",
        };
    }

    render() {

        return <WizardDialogLayout
            title={this.props.title}
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => null}
            close={() => { return; }}
            onPageChanged={() => {
                // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
                this.doBusyTask(async () => {
                    this.setState({ dialogResizeKey: new Date().getDate().toString() });
                });
            }}
        >
            <WizardPage>
                <Text
                    label="New document name"
                    value={this.state.name}
                    onChange={name => this.setState({ name })}
                    validate={required("Please enter a document name")}
                    autoFocus
                />
            </WizardPage>
            <WizardPage>
                <div>Name: {this.state.name}</div>
                <div style={{ marginTop: "1rem" }}>
                    <MarkdownEditor
                        label="Document description"
                        value={this.state.description}
                        onChange={this.handleDescriptionChanged}
                    />
                </div>
            </WizardPage>
            <WizardPage>
                <h1>Summary</h1>
                <div>Name: {this.state.name}</div>
                <div>Description: {this.state.description}</div>
            </WizardPage>
        </WizardDialogLayout>;
    }

    private handleDescriptionChanged = (description: string) => {
        // in dobusy so the dialog resizes if the markdowneditor grows
        this.doBusyTask(async () => {
            this.setState({ description });
        });
    }
}