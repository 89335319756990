import {FeaturesConfigurationResource, MultiTenancyStatusResource, SpaceResource} from "client/resources";
import { Reducer } from "redux";
import { combineReducers } from "redux";

enum ActionKeys {
    CONFIGURATION_FEATURES_FETCHED = "CONFIGURATION_FEATURES_FETCHED",
    SPACE_MULTI_TENANCY_STATUS_FETCHED = "SPACE_MULTI_TENANCY_STATUS_FETCHED",
    SPACES_USER_ACCESSIBLE_SPACES_FETCHED = "SPACES_USER_ACCESSIBLE_SPACES_FETCHED",
    OTHER_ACTION = "__any_other_action_type__"
}

interface IConfigurationFeaturesFetchedAction {
    type: ActionKeys.CONFIGURATION_FEATURES_FETCHED;
    features: FeaturesConfigurationResource;
}

interface ISpaceMultiTenancyStatusFetchedAction {
    type: ActionKeys.SPACE_MULTI_TENANCY_STATUS_FETCHED;
    status: MultiTenancyStatusResource;
}

interface IUsersAccessibleSpacesFetchedAction {
    type: ActionKeys.SPACES_USER_ACCESSIBLE_SPACES_FETCHED;
    spaces: SpaceResource[];
}

interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}

type ActionTypes =
    IConfigurationFeaturesFetchedAction |
    ISpaceMultiTenancyStatusFetchedAction |
    IUsersAccessibleSpacesFetchedAction |
    IOtherAction;

export const configurationActions = {
    featuresFetched: (features: FeaturesConfigurationResource): IConfigurationFeaturesFetchedAction => ({
        type: ActionKeys.CONFIGURATION_FEATURES_FETCHED,
        features
    }),
    spaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource): ISpaceMultiTenancyStatusFetchedAction => ({
        type: ActionKeys.SPACE_MULTI_TENANCY_STATUS_FETCHED,
        status
    }),
    userAccessibleSpacesFetched: (userAccessibleSpaces: SpaceResource[]): IUsersAccessibleSpacesFetchedAction => ({
        type: ActionKeys.SPACES_USER_ACCESSIBLE_SPACES_FETCHED,
        spaces: userAccessibleSpaces
    })
};

export interface ConfigurationAreaState {
    readonly features: ConfigurationFeaturesState;
    readonly currentSpace: CurrentSpaceConfigurationState;
    readonly spaces: SpacesConfigurationState;
}

export interface SpacesConfigurationState {
    readonly usersAccessibleSpaces: SpaceResource[];
}

export interface CurrentSpaceConfigurationState {
    readonly isMultiTenancyEnabled: boolean;
}

export interface ConfigurationFeaturesState {
    readonly isCommunityActionTemplatesEnabled: boolean;
    readonly isBuiltInRepoSyncEnabled: boolean;
    readonly isBuiltInWorkerEnabled: boolean;
}

const featuresReducer: Reducer<ConfigurationFeaturesState> = (state: ConfigurationFeaturesState = null, action: ActionTypes): ConfigurationFeaturesState => {
    switch (action.type) {
        case ActionKeys.CONFIGURATION_FEATURES_FETCHED:
            if (!action.features) {
                return null;
            }
            return {
                isBuiltInRepoSyncEnabled: action.features.IsBuiltInRepoSyncEnabled,
                isBuiltInWorkerEnabled: action.features.IsBuiltInWorkerEnabled,
                isCommunityActionTemplatesEnabled: action.features.IsCommunityActionTemplatesEnabled,
            };
        default:
            return state;
    }
};

const currentSpaceReducer: Reducer<CurrentSpaceConfigurationState> = (state: CurrentSpaceConfigurationState = null, action: ActionTypes): CurrentSpaceConfigurationState => {
    switch (action.type) {
        case ActionKeys.SPACE_MULTI_TENANCY_STATUS_FETCHED:
            if (!action.status) {
                return null;
            }

            return {
                isMultiTenancyEnabled: action.status.Enabled,
            };
        default:
            return state;
    }
};

const spacesReducer: Reducer<SpacesConfigurationState> = (state: SpacesConfigurationState = null, action: ActionTypes): SpacesConfigurationState => {
    switch (action.type) {
        case ActionKeys.SPACES_USER_ACCESSIBLE_SPACES_FETCHED:
            return {
                usersAccessibleSpaces: action.spaces
            };
        default:
            return state;
    }
};

export const configurationArea = combineReducers<ConfigurationAreaState>({
    features: featuresReducer,
    currentSpace: currentSpaceReducer,
    spaces: spacesReducer
});