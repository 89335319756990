import * as React from "react";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import Text, {TextInput} from "components/form/Text/Text";
import {VariablePromptOptions, VariableType} from "client/resources/variableResource";
import Select from "components/form/Select/Select";
import CodeEditor, {Language, TextFormat} from "components/CodeEditor/CodeEditor";
import ScopeSelector from "areas/variables/ScopeSelector/ScopeSelector";
import {ScopeValues} from "client/resources/variableSetResource";
import CertificateSearch from "components/form/CertificateSearch/CertificateSearch";
import AccountSearch from "components/form/AccountSearch";
const styles = require("./style.less");
import {ScriptingLanguage} from "components/scriptingLanguage";
import {editorModeOptions, default as TextFormatSelector} from "areas/variables/TextFormatSelector/TextFormatSelector";
import CopyToClipboard from "components/CopyToClipboardButton/CopyToClipboardButton";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import DisplayProperties from "components/DisplayProperties/DisplayProperties";
import {AccountType} from "client/resources/accountResource";
import {VariableValueModel} from "../VariablesModel";
import {convertToNewType} from "./convertToNewType";
import {isReferenceType} from "../isReferenceType";
import {filteredVariableTypes} from "../VariableDetails";
import EditorPrompt from "./PromptedVariableDetails";
import { CustomDialog as Dialog } from "components/Dialog/CustomDialog";
import { CustomSaveDialogLayout, DialogActions, DialogTitleBar, DialogContent, MediumDialogFrame, FullScreenDialogFrame, ExtraContent, ToggleFrame } from "components/DialogLayout/Custom";
import IconButton, {Icon} from "components/IconButton/IconButton";

export interface OpenVariableDialogArgs {
    value: VariableValueModel;
    name: string;
    focus: FocusField;
}

export type ReferenceType = VariableType.Certificate | VariableType.AmazonWebServicesAccount;

export interface WithReferenceTypeDialogArgs {
    referenceType: ReferenceType;
}

export type OpenReferenceVariableDialogArgs = OpenVariableDialogArgs & WithReferenceTypeDialogArgs;

export interface EditVariableDialogProps {
    title: string;
    openDialogArgs?: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs;
    availableScopes: ScopeValues;
    isProjectScoped: boolean;
    isTenanted: boolean;
    onDone: (value: VariableValueModel, name: string) => void;
    onClosed: () => void;
}

interface EditVariableState extends DataBaseComponentState {
    value: VariableValueModel;
    name: string;
    editorMode: (TextFormat | ScriptingLanguage | Language);
    page: Page;
}

enum Page {
    Value,
    Scope
}

export enum FocusField {
    Name,
    Description,
    Value,
    Scope
}

interface VariableCodeEditorViewProps {
    className?: string;
    onValueChange: (value: string) => void;
    value?: string;
    onEscPressed?(): void;
}

const updateValue = (update: Partial<VariableValueModel>) => {
    return (prevState: EditVariableState) => ({
        value: {
            ...prevState.value,
            ...update
        }
    });
};

const updatePrompt = (update: Partial<VariablePromptOptions> | null) => {
    return (prevState: EditVariableState) => ({
        value: {
            ...prevState.value,
            Prompt: {
                ...prevState.value.Prompt,
                ...update
            }
        }
    });
};

const resetPromptValues = (shouldPrompt: boolean) => {
    return (prevState: EditVariableState) => ({
        value: {
            ...prevState.value,
            Prompt: shouldPrompt ? {
                Label: "",
                Description: "",
                Required: false
            } : null
        }
    });
};

interface VariableCodeEditorViewState {
    editorMode: (TextFormat | ScriptingLanguage | Language);
}

class VariableCodeEditorView extends React.Component<VariableCodeEditorViewProps, VariableCodeEditorViewState> {
    private valueInput?: CodeEditor;

    constructor(props: VariableCodeEditorViewProps) {
        super(props);
        this.state = {
            editorMode: this.initialEditorModeValue()
        };
    }
    render() {
        const {value, onValueChange, onEscPressed, children} = this.props;

        return <div className={this.props.className}>
            <div className={styles.rightAlignedContainer}>
                <div className={styles.textFormatSelector}>
                    <TextFormatSelector
                        value={this.state.editorMode}
                        onChange={editorMode => this.setState({editorMode: editorMode as (TextFormat | ScriptingLanguage | Language)})}
                    />
                </div>
            </div>
            <div className={styles.editor}>
            <CodeEditor
                containerClassName={styles.editorContainer}
                ref={e => this.valueInput = e}
                value={value}
                allowFullScreen={false}
                label="Value"
                language={this.state.editorMode}
                onEscPressed={onEscPressed}
                onChange={onValueChange}/>
            </div>
            <div className={styles.rightAlignedContainer}>
                <CopyToClipboard value={value}/>
            </div>
            {children}
        </div>;
    }
    componentWillReceiveProps(nextProps: VariableCodeEditorViewProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                editorMode: this.initialEditorModeValue()
            });
        }
    }
    private initialEditorModeValue() {
        return editorModeOptions[0].value as (TextFormat | ScriptingLanguage | Language);
    }
}

export class EditVariableDialog extends React.Component<EditVariableDialogProps> {
    render() {
        const args = this.props.openDialogArgs;
        const open = !!args;

        return (
            <Dialog open={open} close={this.props.onClosed} render={(renderProps) => (
                <Inner {...this.props} open={open}/>
            )}/>
        );
    }
}

class Inner extends DataBaseComponent<EditVariableDialogProps & {open: boolean}, EditVariableState> {
    private nameInput: TextInput | null = null;
    private descriptionInput: TextInput | null = null;
    private valueInput?: CodeEditor;
    private nextFocus?: FocusField;

    constructor(props: EditVariableDialogProps & {open: boolean}) {
        super(props);
        this.state = getInitialState(props.openDialogArgs);
        this.nextFocus = this.props.openDialogArgs.focus;
    }

    componentDidMount() {
        this.selectInputIfRequired();
    }

    componentDidUpdate(prevProps: EditVariableDialogProps, prevState: EditVariableState) {
        this.selectInputIfRequired();
    }

    componentWillReceiveProps(nextProps: EditVariableDialogProps) {
        if (nextProps.openDialogArgs !== this.props.openDialogArgs) {
            this.setState(getInitialState(nextProps.openDialogArgs));
            this.nextFocus = nextProps.openDialogArgs.focus;
        }
    }

    render() {

        return (<ToggleFrame
            initialFrame={MediumDialogFrame}
            alternateFrame={FullScreenDialogFrame}
            render={({ frame, isInitialFrame, toggleFrame  }) => (
            <CustomSaveDialogLayout
                frame={frame}
                open={this.props.open}
                errors={this.state.errors}
                close={this.onDialogClose}
                busy={this.state.busy}
                onSaveClick={() => {
                    this.props.onDone(this.state.value, this.state.name);
                    this.props.onClosed();
                    return Promise.resolve(true);
                }}
                renderActions={(actionProps) => <DialogActions actions={this.getRightSideActions()} additionalActions={this.getLeftSideActions()}/>}
                renderTitle={() => <DialogTitleBar title={this.props.title} actions={
                    <IconButton
                        toolTipContent={`${isInitialFrame ? "Enter" : "Exit"} full screen`}
                        onClick={toggleFrame}
                        icon={isInitialFrame ? Icon.EnterFullScreen : Icon.ExitFullScreen}
                    />
                }/>}
                renderContent={(renderProps) => (
                    <React.Fragment>
                        {this.state.page === Page.Scope && (
                            <ExtraContent className={styles.detailsHighlight}>
                                <DisplayProperties properties={[
                                    {key: "Name", value: this.state.name},
                                    {key: "Value", value: this.state.value.Value}]}/>
                            </ExtraContent>
                        )}
                        <DialogContent>
                            {this.state.page === Page.Value && <div className={styles.content}>
                                <div className={styles.inputRow}>
                                    <Text
                                        textInputRef={e => this.nameInput = e}
                                        label={"Name"}
                                        value={this.state.name}
                                        onChange={(name) => this.setState({name})}
                                    />
                                    <Select
                                        label="Variable type"
                                        value={this.state.value.Type}
                                        items={filteredVariableTypes(this.state.value.Type)}
                                        onChange={(Type: VariableType) => this.setState(prevState => ({value: convertToNewType(prevState.value, Type)}))}
                                    />
                                    <Text
                                        textInputRef={e => this.descriptionInput = e}
                                        value={this.state.value.Description}
                                        label="Description"
                                        onChange={(Description) => this.setState(updateValue({Description}))}
                                    />
                                    <EditorPrompt variable={this.state.value}
                                        onPromptChange={(shouldPrompt) => this.setStateAndResizeDialog(resetPromptValues(shouldPrompt))}
                                        prompt={!!this.state.value.Prompt}
                                        description={this.state.value.Prompt ? this.state.value.Prompt.Description : null}
                                        label={this.state.value.Prompt ? this.state.value.Prompt.Label : null}
                                        displaySettings={this.state.value.Prompt && this.state.value.Prompt.DisplaySettings ? this.state.value.Prompt.DisplaySettings : {}}
                                        required={this.state.value.Prompt ? this.state.value.Prompt.Required : null}
                                        onDescriptionChange={(Description) => this.setState(updatePrompt({Description}))}
                                        onLabelChange={(Label) => this.setState(updatePrompt({Label}))}
                                        onRequiredChange={(Required) => this.setState(updatePrompt({Required}))}
                                        onDisplaySettingsChange={(DisplaySettings) =>  this.setStateAndResizeDialog(updatePrompt({DisplaySettings}))}/>
                                </div>
                                <div className={styles.value}>
                                    {this.state.value.Type === VariableType.Certificate &&
                                        <CertificateSearch
                                            selectedCertificateId={this.state.value.Value}
                                            onSelected={({Id : Value}) => this.setState(updateValue({Value}))}
                                            doBusyTask={this.doBusyTask}
                                        />}
                                    {this.state.value.Type === VariableType.AmazonWebServicesAccount &&
                                        <AccountSearch
                                            selectedAccountId={this.state.value.Value}
                                            onSelected={({Id : Value}) => this.setState(updateValue({Value}))}
                                            accountTypes={[AccountType.AmazonWebServicesAccount]}
                                            doBusyTask={this.doBusyTask}
                                        />}
                                {this.state.value.Type === VariableType.AzureAccount &&
                                <AccountSearch
                                    selectedAccountId={this.state.value.Value}
                                    onSelected={({Id : Value}) => this.setState(updateValue({Value}))}
                                    accountTypes={[AccountType.AzureServicePrincipal, AccountType.AzureSubscription]}
                                    doBusyTask={this.doBusyTask}
                                />}
                                    {(this.state.value.Type === VariableType.Sensitive || this.state.value.Type === VariableType.String) &&
                                    <VariableCodeEditorView value={this.state.value.Value} onValueChange={(Value) => this.setState(updateValue({Value}))}/>}
                                </div>
                            </div>}
                            {this.state.page === Page.Scope && <div>
                                <div>
                                    <ScopeSelector
                                        value={this.state.value.Scope}
                                        availableScopes={this.props.availableScopes}
                                        isProjectScoped={this.props.isProjectScoped}
                                        onScopeSelected={(Scope) => this.setState(updateValue({Scope}))}
                                        allowTenantTagSelection={this.props.isTenanted}
                                        useCompactControls={false}
                                        doBusyTask={this.doBusyTask}
                                    />
                                </div>
                            </div>}
                        </DialogContent>
                    </React.Fragment>
                )}
                />
            )}/>
        );
    }

    private selectInputIfRequired() {
        const focusedField = this.nextFocus;
        this.nextFocus = null;
        // Focus after a timeout to give the form time to display
        window.setTimeout(() => {
            if (this.state.value) {
                if (this.nameInput && focusedField === FocusField.Name) {
                    this.nameInput.select();
                }
                if (this.valueInput && focusedField === FocusField.Value) {
                    this.valueInput.focus();
                }
                if (this.descriptionInput && focusedField === FocusField.Description) {
                    this.descriptionInput.select();
                }
                // Don't highlight any inputs from the scope selector if this.props.focus === FocusField.Scope,
                // because a) the user probably wants to configure things like tenant tags, rather than the first input in scope selector
                // and b) because of the show popup animation, the autocomplete popup will probably be in the wrong position
            }
        }, 0);
    }

    private setStateAndResizeDialog = <K extends keyof EditVariableState>(state: Pick<EditVariableState, K>) => {
        // Perform in doBusy to trigger our dialog resize fix #dialogResizeHack
        this.doBusyTask(async () => {
            this.setState(state);
        });
    }

    private getLeftSideActions() {
        const actions = [];
        if (this.state.page === Page.Value) {
            const defineScope = <ActionButton
                key="define scope"
                label="Define Scope"
                type={ActionButtonType.Secondary}
                onClick={() => {
                    // doBusyTask forces the dialog to correct its size as the page changes
                    this.doBusyTask(async () => this.setState({page: Page.Scope}));
                }}
            />;
            actions.push(defineScope);
        } else {
            const previous = <ActionButton
                key="previous"
                label="Previous"
                type={ActionButtonType.Secondary}
                onClick={() => {
                    // doBusyTask forces the dialog to correct its size as the page changes
                    this.doBusyTask(async () => this.setState({page: Page.Value}));
                }}
            />;
            actions.push(previous);
        }
        return actions;
    }

    private getRightSideActions() {
        const cancel = <ActionButton
            key="cancel"
            label="Cancel"
            onClick={this.props.onClosed}
        />;
        return [cancel, this.createDoneAction()];
    }

    private createDoneAction() {
        return <ActionButton
            key="done"
            label="Done"
            type={ActionButtonType.Primary}
            disabled={this.isDisabled()}
            onClick={() => {
                this.props.onDone(this.state.value, this.state.name);
                this.props.onClosed();
            }}
        />;
    }

    private isDisabled() {
        const name = this.state.name ? this.state.name : "";
        // certificate type must have a value, as it's not supported for use in "prompt for a value"
        if (!name) {
            return true;
        }

        if (!this.state.value.Value && isReferenceType(this.state.value.Type)) {
            return true;
        }

        return false;
    }

    private onDialogClose = () => {
        this.props.onClosed();
    }
}

function isVariableReferenceDialogArgs(args: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs): args is OpenReferenceVariableDialogArgs {
    return (args as OpenReferenceVariableDialogArgs).referenceType !== undefined;
}

function getInitialState(openDialogArgs: OpenVariableDialogArgs | OpenReferenceVariableDialogArgs): EditVariableState {
    return {
        value: variableAdjustment(openDialogArgs),
        name: openDialogArgs.name,
        editorMode: initialEditorModeValue(),
        page: openDialogArgs.focus === FocusField.Scope ? Page.Scope : Page.Value,
    };
}

function variableAdjustment(openDialogArgs: OpenReferenceVariableDialogArgs | OpenVariableDialogArgs) {
    return !isVariableReferenceDialogArgs(openDialogArgs) ? openDialogArgs.value : convertToNewType(openDialogArgs.value, openDialogArgs.referenceType);
}

function initialEditorModeValue() {
    return editorModeOptions[0].value as (TextFormat | ScriptingLanguage | Language);
}