exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_timePicker__jK6e4 {\n  display: flex;\n  align-items: center;\n}\n.style_timePicker__jK6e4 .style_digit__1vE1N {\n  width: 2rem;\n  margin-right: 0.25rem;\n}\n.style_timePicker__jK6e4 .style_digit__1vE1N:first-of-type {\n  margin-right: 1rem;\n}\n.style_timePicker__jK6e4 input[type=number]::-webkit-inner-spin-button,\n.style_timePicker__jK6e4 input[type=number]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n.style_timePicker__jK6e4 input[type=number] {\n  -moz-appearance: textfield;\n}\n.style_timePicker__jK6e4 input {\n  text-align: center;\n}\n", ""]);

// exports
exports.locals = {
	"timePicker": "style_timePicker__jK6e4",
	"digit": "style_digit__1vE1N"
};