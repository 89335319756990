import * as React from "react";
import DataBaseComponent from "components/DataBaseComponent";
import {repository} from "clientInstance";
import {Checkbox, FileUpload, Note} from "components/form";
import ExternalLink from "components/Navigation/ExternalLink";
import {DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import { SupportedPackageTypes } from "./Onboarding";

interface UploadPackageProps {
    afterPackageUpload(packageId: string): void;
}

interface UploadPackageState extends DataBaseComponentState {
    file?: File;
    replace: boolean;
}

class UploadPackage extends DataBaseComponent<UploadPackageProps, UploadPackageState> {
    constructor(props: UploadPackageProps) {
        super(props);
        this.state = {replace: false};
    }

    async save() {
        return this.doBusyTask(async () => {
            const pkg = await repository.Packages.upload(this.state.file, this.state.replace);
            this.props.afterPackageUpload(pkg.Id);
        });
    }

    render() {
        return <SaveDialogLayout title="Upload a Package"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 saveButtonLabel="Upload"
                                 busyButtonLabel="Uploading..."
                                 onSaveClick={() => this.save()}>
            <h4>
                Upload a package containing all the files to deploy.
            </h4>
            <FileUpload onChange={file => this.setState({file})}
                        value={this.state.file}
                        label="Package file"
                        hintText="Select a package"/>
            <Note>
                Supported packages include {SupportedPackageTypes}.<br />
                Learn more about <ExternalLink href="SupportedPackages">supported packages</ExternalLink>.
            </Note>
            <Checkbox label="Replace this version if it exists" value={this.state.replace}
                        onChange={replace => this.setState({replace})}/>
        </SaveDialogLayout>;
    }
}

export default UploadPackage;
