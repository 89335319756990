import * as React from "react";
import AreaTitle from "components/AreaTitle";
import {repository, session, client} from "clientInstance";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import NavigationSidebarLayout, {Navigation} from "components/NavigationSidebarLayout";
import { Permission } from "client/resources/permission";
import {isAllowed} from "components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";

interface ConfigurationLayoutState {
    hideAnyAuthenticationProvidersSupportPasswordManagement: boolean;
}

class ConfigurationLayout extends BaseComponent<{}, ConfigurationLayoutState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            // flipped to 'hide' so it'll just dispear in the case where it's not supported
            // instead of showing up after the data fetch, and without delaying display of whole menu for data load
            hideAnyAuthenticationProvidersSupportPasswordManagement: false
        };
    }

    async componentDidMount() {
        const document = await repository.Authentication.get();
        this.setState({
            hideAnyAuthenticationProvidersSupportPasswordManagement:
                !document.AnyAuthenticationProvidersSupportPasswordManagement
        });
    }

    render() {
        const navLinks = [
            Navigation.navItem("Audit", routeLinks.configuration.audit(client.spaceId), null, {permission: [Permission.AdministerSystem, Permission.EventView], wildcard: true}),
            Navigation.navItem("Backup", routeLinks.configuration.backup, null, {permission: Permission.AdministerSystem}),
            Navigation.navItem("Diagnostics", routeLinks.configuration.diagnostics.root, null, {permission: Permission.AdministerSystem}),
            Navigation.navItem("Features", routeLinks.configuration.features, null, {permission: [Permission.AdministerSystem, Permission.ConfigureServer]}),
            Navigation.navItem("Let's Encrypt", routeLinks.configuration.letsEncrypt.root, null, {permission: Permission.AdministerSystem}),
            Navigation.navItem("License", routeLinks.configuration.license, null, {permission: Permission.AdministerSystem}),
            Navigation.navItem("Maintenance", routeLinks.configuration.maintenance, null, {permission: Permission.AdministerSystem}),
            Navigation.navItem("Nodes", routeLinks.configuration.nodes.root, null, {permission: Permission.AdministerSystem}),
            Navigation.navItem("Performance", routeLinks.configuration.performance, null, {permission: Permission.ConfigureServer}),
            Navigation.navItem("Settings", routeLinks.configuration.settings.root, null, {permission: [Permission.AdministerSystem, Permission.ConfigureServer]}),
            Navigation.navItem("SMTP", routeLinks.configuration.smtp, null, {permission: [Permission.AdministerSystem, Permission.ConfigureServer]}),
            Navigation.navItem("Subscriptions", routeLinks.configuration.subscriptions.root, null, {permission: [Permission.SubscriptionView]}),
            session.currentPermissions.scopeToSpace(repository.spaceId).hasAnyPermissions() ? Navigation.navItem("Spaces", routeLinks.configuration.spaces.root, null) :
                                                                  Navigation.navItem("Spaces", routeLinks.configuration.spaces.root, null, {permission: [Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete]}),
            Navigation.navItem("Teams", routeLinks.configuration.teams.root(), null, {permission: [Permission.TeamEdit, Permission.TeamView]}),
            Navigation.navItem("Test Permissions", routeLinks.configuration.testPermissions, null, {permission: [Permission.TeamEdit, Permission.UserView]}),
            Navigation.navItem("Thumbprint", routeLinks.configuration.thumbprint, null, {permission: [Permission.AdministerSystem, Permission.MachineEdit], wildcard: true}),
            Navigation.navItem("Users", routeLinks.configuration.users.root, null, {permission: [Permission.AdministerSystem, Permission.UserView]}),
            !this.state.hideAnyAuthenticationProvidersSupportPasswordManagement
                && isAllowed({permission: Permission.UserInvite})
                ? Navigation.navItem("User Invites", routeLinks.configuration.userInvites, null, {permission: [Permission.AdministerSystem, Permission.UserInvite]})
                : null,
            Navigation.navItem("User Roles", routeLinks.configuration.roles.root, null, {permission: [Permission.AdministerSystem, Permission.UserRoleView]}),
        ].filter(nav => !!nav);

        return (
            <main id="maincontent">
                <AreaTitle
                    link={routeLinks.configuration.root}
                    title="Configuration"
                />
                <NavigationSidebarLayout
                    navLinks={navLinks}
                    content={this.props.children} />
            </main>);
    }
}

export default ConfigurationLayout;