import * as React from "react";
import FormBaseComponent, {OptionalFormBaseComponentState} from "components/FormBaseComponent/FormBaseComponent";
import FormPaperLayout, {FormPaperLayoutProps} from "components/FormPaperLayout/FormPaperLayout";
import InfrastructureLayout from "../InfrastructureLayout/InfrastructureLayout";
import {ExpandableFormSection, Summary, Note} from "components/form";
import Text from "components/form/Text/Text";
import OverflowMenu from "components/Menu/OverflowMenu";
import {repository} from "clientInstance";
import {AccountResource, AccountType, AzureEnvironment} from "client/resources";
import {required} from "components/form/Validators";
import EnvironmentMultiSelect from "components/MultiSelect/EnvironmentMultiSelect";
import {EnvironmentResource} from "client/resources/environmentResource";
import { environmentChipList } from "components/Chips/index";
import {TenantedDeploymentMode} from "client/resources";
import {TenantResource} from "../../../../client/resources/tenantResource";
import ExpanderSectionHeading from "../../../../components/form/Sections/FormSectionHeading";
import MarkdownEditor from "../../../../components/form/MarkdownEditor/MarkdownEditor";
import Markdown from "../../../../components/Markdown/index";
import TenantedDeploymentParticipationSelector from "components/TenantedDeploymentParticipationSelector";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
const styles = require("./style.less");
import {AdvancedTenantsAndTenantTagsSelector} from "components/AdvancedTenantSelector";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import {isAllowed} from "components/PermissionCheck/PermissionCheck";
import {FeatureToggle, Feature} from "components/FeatureToggle";
import StringHelper from "utils/StringHelper";
import {cloneDeep} from "lodash";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

export type AccountLayoutOverFlowProps = FormPaperLayoutProps["overFlowActions"];

interface AccountDisplayProps {
    name: string;
    description: string;
}

interface AccountScopingProps {
    environmentIds: string[];
    tenantIds: string[];
    tenantTags: string[];
    tenantMode: TenantedDeploymentMode;
}

type AccountEditModel = AccountDisplayProps & AccountScopingProps;

interface AccountEditState<TAccountResource extends AccountResource, TModel extends AccountEditModel>
    extends OptionalFormBaseComponentState<TModel> {
    accountId?: string;
    account: TAccountResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    deleted?: boolean;
    newId?: string;
    showTestDialog?: boolean;
    usingAzureEnvironment: boolean;
    azureEnvironmentsList: AzureEnvironment[];
}

interface AccountEditProps<TAccountResource extends AccountResource> {
    account: TAccountResource;
}

abstract class AccountEdit<TAccountResource extends AccountResource, TModel extends AccountEditModel>
    extends FormBaseComponent<AccountEditProps<TAccountResource>, AccountEditState<TAccountResource, TModel>, TModel> {

    constructor(props: AccountEditProps<TAccountResource>) {
        super(props);

        this.state = {
            account: this.props.account,
            accountId: this.props.account ? this.props.account.Id : null,
            environments: null,
            tenants: null,
            usingAzureEnvironment: false,
            azureEnvironmentsList: null
        };
    }

    abstract customSecondaryAction(): JSX.Element;

    abstract customExpandableFormSections(): JSX.Element[];

    abstract getPartialResource(): Partial<TAccountResource> & { AccountType: AccountType };

    abstract getPartialModel(account?: TAccountResource): Partial<TModel>;

    getAccountSummary(): JSX.Element {
        return null;
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            const [environments, tenants] = await Promise.all<EnvironmentResource[], TenantResource[]>([
                repository.Environments.all(),
                repository.Tenants.all()
            ]);
            const model = this.buildModel(this.state.account, environments, tenants);
            const azureEnvironments = await repository.Accounts.getIsolatedAzureEnvironments();
            this.setState({
                model,
                cleanModel: cloneDeep(model),
                environments,
                tenants,
                azureEnvironmentsList: azureEnvironments
            });
        });
    }

    buildModel(account: TAccountResource, environments: EnvironmentResource[], tenants: TenantResource[]): any {
        const partial: object = this.getPartialModel(account);
        if (!account) {
            return {
                ...partial,
                name: "",
                description: "",
                environmentIds: [],
                tenantIds: [],
                tenantTags: [],
                tenantMode: TenantedDeploymentMode.Untenanted
            };
        }

        return {
            ...partial,
            name: account.Name,
            description: account.Description,
            environmentIds: account.EnvironmentIds,
            tenantIds: account.TenantIds,
            tenantTags: account.TenantTags,
            tenantMode: account.TenantedDeploymentParticipation
        };
    }

    handleDeleteConfirm = async () => {
        const result = await repository.Accounts.del(this.state.account);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }

    handleSaveClick = async (performTest: boolean) => {
        await this.doBusyTask(async () => {

            let model = this.state.model;
            let account: AccountResource = {
                ...(this.state.account as AccountResource),
                ...(this.getPartialResource() as { AccountType: any }),
                Name: model.name,
                Description: model.description,
                TenantedDeploymentParticipation: model.tenantMode || TenantedDeploymentMode.Untenanted,
                TenantTags: model.tenantTags,
                TenantIds: model.tenantIds,
                EnvironmentIds: model.environmentIds
            };
            account = await repository.Accounts.save(account);
            model = this.buildModel(account as TAccountResource, this.state.environments, this.state.tenants);

            this.setState({
                account: account as TAccountResource,
                accountId: account.Id,
                model,
                cleanModel: cloneDeep(model),
                showTestDialog: performTest,
                newId: this.state.accountId ? null : account.Id
            });
        });
    }

    nameSummary() {
        return this.state.model.name ?
            Summary.summary(this.state.model.name) :
            Summary.placeholder("Please enter a name for your account");
    }

    descriptionSummary() {
        return this.state.model.description ?
            Summary.summary(<Markdown markup={this.state.model.description}/>) :
            Summary.placeholder("No account description provided");
    }

    environmentsSummary() {
        return this.state.model.environmentIds.length >= 1 ?
            Summary.summary(<span>Only available for deployments to {environmentChipList(this.state.environments, this.state.model.environmentIds)}</span>) :
            Summary.default("Available for deployments to any environment");
    }

    tenantDeploymentModeSummary() {
        return CommonSummaryHelper.tenantDeploymentModeSummary(
            this.state.model.tenantMode,
            this.state.model.tenantIds,
            this.state.model.tenantTags);
    }

    tenantSummary() {
        return CommonSummaryHelper.tenantSummary(
            this.state.model.tenantIds,
            this.state.model.tenantTags,
            this.state.tenants);
    }

    testDone() {
        this.setState({
            showTestDialog: false,
        });
    }

    getOverFlowActions(): AccountLayoutOverFlowProps {
        return this.state.accountId
            ? [
                OverflowMenu.deleteItemDefault("account", this.handleDeleteConfirm, {
                    permission: Permission.AccountDelete,
                    environment: "*",
                    tenant: "*"
                }),
                [OverflowMenu.navItem("Audit Trail",
                    routeLinks.configuration.eventsRegardingAny([this.state.accountId]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]
            ]
            : [];
    }

    render() {
        const savePermission = {permission: !this.state.accountId ? Permission.AccountCreate : Permission.AccountEdit, environment: "*", tenant: "*"};

        const title = !this.state.accountId
            ? "Create Account"
            : this.state.account
                ? this.state.account.Name
                : StringHelper.ellipsis;

        return <InfrastructureLayout {...this.props}>
            <FormPaperLayout title={title}
                breadcrumbTitle={"Accounts"}
                breadcrumbPath={routeLinks.infrastructure.accounts.root}
                saveText="Account details changed"
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={savePermission}
                onSaveClick={() => this.handleSaveClick(false)}
                secondaryAction={isAllowed(savePermission) && this.customSecondaryAction()}
                expandAllOnMount={this.state.accountId === null}
                overFlowActions={this.getOverFlowActions()}
            >
                {this.getAccountSummary()}
                {this.state.deleted && <InternalRedirect to={routeLinks.infrastructure.accounts.root}/>}
                {this.state.newId && !this.state.showTestDialog && <InternalRedirect to={routeLinks.infrastructure.account(this.state.newId)}/>}
                {this.state.model && <TransitionAnimation className={styles.expanderContainer}>
                    <ExpandableFormSection
                        errorKey="name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.nameSummary()}
                        help="A short, memorable, unique name for this account.">
                        <Text
                            value={this.state.model.name}
                            onChange={name => this.setModelState({name})}
                            label="Account name"
                            validate={required("Please enter an account name")}
                            error={this.getFieldError("name")}
                            autoFocus={true}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        errorKey="description"
                        title="Description"
                        summary={this.descriptionSummary()}
                        help="A summary explaining the use of the account to other users.">
                        <MarkdownEditor
                            value={this.state.model.description}
                            label="Account description"
                            onChange={description => this.setModelState({description})}
                        />
                    </ExpandableFormSection>

                    {this.customExpandableFormSections()}

                    <ExpanderSectionHeading title="Restrictions" key={"header"}/>
                    <ExpandableFormSection
                        errorKey="environment"
                        title="Environments"
                        summary={this.environmentsSummary()}
                        help="Choose the environments that are allowed to use this account">
                        <Note>
                            If this field is left blank, the account can be used for deployments to any environment.
                            Specifying environment/s (especially for production accounts) is strongly
                            recommended.
                        </Note>
                        <EnvironmentMultiSelect
                            items={this.state.environments}
                            onChange={environmentIds => this.setModelState({environmentIds})}
                            value={this.state.model.environmentIds}/>
                    </ExpandableFormSection>

                    <FeatureToggle feature={Feature.MultiTenancy}>
                        <PermissionCheck permission={Permission.TenantView} tenant="*">
                            <ExpandableFormSection
                                errorKey="TenantedDeploymentMode"
                                title="Tenanted Deployments"
                                summary={this.tenantDeploymentModeSummary()}
                                help={"Choose the kind of deployments where this account should be included."}>
                                <TenantedDeploymentParticipationSelector
                                    tenantMode={this.state.model.tenantMode}
                                    resourceTypeLabel="account"
                                    onChange={x => this.setModelState({tenantMode: x as TenantedDeploymentMode})}
                                />
                            </ExpandableFormSection>
                            {this.state.model.tenantMode !== TenantedDeploymentMode.Untenanted &&
                            <ExpandableFormSection
                                errorKey="Tenants"
                                title="Associated Tenants"
                                summary={this.tenantSummary()}
                                help={"Choose tenants this account should be associated with."}>
                                <AdvancedTenantsAndTenantTagsSelector
                                    tenants={this.state.tenants}
                                    selectedTenantIds={this.state.model.tenantIds}
                                    selectedTenantTags={this.state.model.tenantTags}
                                    doBusyTask={this.doBusyTask}
                                    onChange={(tenantIds, tenantTags) => this.setModelState({tenantIds, tenantTags})}
                                    showPreviewButton={true}
                                />
                            </ExpandableFormSection>}
                        </PermissionCheck>
                    </FeatureToggle>
                </TransitionAnimation>}
            </FormPaperLayout>
        </InfrastructureLayout>;
    }
}

export {AccountEditModel, AccountEditState, AccountDisplayProps, AccountScopingProps, AccountEditProps};
export default AccountEdit;
