exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_cardTitle__1_pfE {\n  font-size: 1.063rem;\n  line-height: 1.18rem;\n  color: #333333;\n  margin: 0.25rem 0;\n  font-weight: 500;\n}\n", ""]);

// exports
exports.locals = {
	"cardTitle": "style_cardTitle__1_pfE"
};