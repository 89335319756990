import * as React from "react";
import {repository} from "clientInstance";
import SortableList from "components/SortableList/SortableList";
import {DeploymentProcessResource} from "client/resources/deploymentProcessResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {IngressRule} from "components/Actions/kubernetes/kubernetesIngressComponent";

interface IngressRuleSorterFormProps {
    title: string;
    ingressRules: IngressRule[];
    saveDone(ingressRules: IngressRule[]): void;
}

interface IngressRuleSorterFormState extends DataBaseComponentState {
    ingressRules: IngressRule[];
}

class IngressRuleSorter extends DataBaseComponent<IngressRuleSorterFormProps, IngressRuleSorterFormState> {
    constructor(props: IngressRuleSorterFormProps) {
        super(props);

        this.state = {
            ingressRules: props.ingressRules
        };
    }

    render() {
        const ingressRules = this.state.ingressRules;

        const body = ingressRules &&
            <SortableList onOrderChanged={this.rulesOrderChanged}
                          items={ingressRules}/>;

        return <SaveDialogLayout title={this.props.title}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={async () => {
                                     this.props.saveDone(this.state.ingressRules);
                                     return true;
                                 }}>
            {body}
        </SaveDialogLayout>;
    }

    private rulesOrderChanged = (ingressRules: IngressRule[]) => {
        this.setState({ingressRules});
    }
}

export default IngressRuleSorter;