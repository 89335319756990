import { Permission } from "client/resources/permission";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import {repository} from "clientInstance";

export async function getFeedName(id: string) {

    const feedName = isAllowed({permission: Permission.FeedView})
        ? (await repository.Feeds.get(id)).Name
        : `(Feed unknown: ${Permission.FeedView} permission required)`;

    return feedName;
}