import * as React from "react";
const styles = require("./style.less");
import * as cn from "classnames";
import { mapProps } from "recompose";

export function withClassNames(classNames: string) {
    return <T extends ClassNameProps>(Component: React.ComponentType<T>) => {
        class WithClassNames extends React.Component<T> {
            render() {
                return <Component {...this.props} className={cn(classNames, this.props.className)} />;
            }
        }

        return WithClassNames;
    };
}

export function withMappedClassNames<TInner extends ClassNameProps, TOuter extends SummaryNodeProps>(mapper: (props: TOuter) => string) {
    return mapProps<TInner, TOuter>((props) => {
        const { className, forceMobileBehaviour, ...rest } = props as any;
        return {
            className: mapper(props),
            ...rest
        };
    }
    );
}

export interface ClassNameProps {
    className?: string;
}

export type SummaryNode = React.ComponentType<SummaryNodeProps>;
export interface SummaryNodeProps extends ClassNameProps {
    forceMobileBehaviour?: boolean;
    overflow?: boolean;
}

const withSummaryClasses = withMappedClassNames((props: SummaryNodeProps) => cn(
    props.className,
    { [styles.cardSubTitleForceMobile]: props.forceMobileBehaviour }
));

const SummarySpan: React.SFC<SummaryNodeProps> = ({ className, children }) => <span className={className} children={children} />;
export const PlaceholderSummary = withSummaryClasses(withClassNames(styles.cardPlaceholderSubTitle)(SummarySpan));
export const DefaultSummary = withSummaryClasses(withClassNames(styles.cardDefaultSubTitle)(SummarySpan));
export const OverflowSummary = withSummaryClasses(withClassNames(styles.cardSubTitle)(SummarySpan));

export default class Summary {
    static placeholder(node: React.ReactNode): React.ComponentType<SummaryNodeProps> {
        return (props) => <PlaceholderSummary {...props}>{node}</PlaceholderSummary>;
    }

    static default(node: React.ReactNode): React.ComponentType<SummaryNodeProps> {
        return (props) => <DefaultSummary {...props}>{node}</DefaultSummary>;
    }

    static summary(node: React.ReactNode): React.ComponentType<SummaryNodeProps> {
        return (props) => <OverflowSummary className={cn({ [styles.cardSubTitleWithOverflowMenu]: props.overflow })} {...props}>{node}</OverflowSummary>;
    }
}