import * as React from "react";
import {SFC} from "react";
import StringHelper from "utils/StringHelper/StringHelper";
const styles = require("./style.less");

interface EventDetailsProps extends React.HTMLProps<HTMLElement> {
    eventDetails: string;
}

const EventDetails: SFC<EventDetailsProps> = props => {
    const { eventDetails, ...rest } = props;
    return <div {...(rest as any)}>
        <pre className={styles.preWrapped}>
            <div dangerouslySetInnerHTML={{__html: StringHelper.formatDiff(eventDetails)}} />
        </pre>
    </div>;
};

EventDetails.displayName = "EventDetails";

export default EventDetails;