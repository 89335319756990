import * as React from "react";
import { Route, Switch } from "react-router-dom";
import importAsync from "importAsync";
import { DashboardRoutes } from "areas/dashboard/DashboardRoutes";
import DashboardOverview from "areas/dashboard/DashboardOverview/DashboardOverview";
import UserProfileRoutes from "areas/users/UserProfileRoutes";
import ProjectGroupLayout from "areas/projects/components/ProjectGroupLayout";
import DeploymentRoutes from "areas/deployments/DeploymentRoutes";
import Environment from "environment";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import routeLinks from "../../routeLinks";
import { V3EnvironmentRoutes } from "areas/infrastructure/components/InfrastructureRoutes/V3EnvironmentRoutes";
import { V3AccountRoutes } from "areas/infrastructure/components/InfrastructureRoutes/V3AccountRoutes";
import { V3MachineRoutes } from "areas/infrastructure/components/InfrastructureRoutes/V3MachineRoutes";
import { NotFound, RedirectAs404 } from "components/NotFound/NotFound";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";
import { ChannelRedirect, ReleaseRedirect, TriggerRedirect } from "./ProjectChildRedirect";
import { RouteComponentProps, withRouter } from "react-router";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";
import {isSpaceNotFound, isSpecificSpaceContext, SpaceContext} from "../StandardLayout/SpaceLoader";
import AreaTitle from "../AreaTitle";
import {SpaceNotFound} from "../SpaceNotFound/SpaceNotFound";

const ProjectRoutes = importAsync(() => import(/* webpackChunkName: "projects" */ "areas/projects/components"), index => index.ProjectRoutes);
const LibraryRoutes = importAsync(() => import(/* webpackChunkName: "library" */ "areas/library/components"), index => index.LibraryRoutes);
const InfrastructureRoutes = importAsync(() => import(/* webpackChunkName: "infrastructure" */ "areas/infrastructure/components"), index => index.InfrastructureRoutes);
const TenantsRoutes = importAsync(() => import(/* webpackChunkName: "tenants" */ "areas/tenants"), index => index.TenantsRoutes);
const ConfigurationRoutes = importAsync(() => import(/* webpackChunkName: "configuration" */ "areas/configuration/components"), index => index.ConfigurationRoutes);
const TaskRoutes = importAsync(() => import(/* webpackChunkName: "tasks" */ "areas/tasks/components"), index => index.TaskRoutes);
const StyleGuide = importAsync(() => import(/* webpackChunkName: "styleGuide" */ "components/StyleGuide"), index => index.StyleGuide, !Environment.isInDevelopmentMode());
const CodeGuide = importAsync(() => import(/* webpackChunkName: "codeGuide" */ "components/CodeGuide"), index => index.CodeGuide, !Environment.isInDevelopmentMode());

const StyleGuidePage = withPage({ page: pageIds.styleGuide })(StyleGuide);
const CodeGuidePage = withPage({ page: pageIds.codeGuide })(CodeGuide);
const ProjectGroupPage = withPage({ page: pageIds.projectGroup.root })(ProjectGroupLayout);
const DashboardOverviewPage = withPage({ page: pageIds.dashboard.root })(DashboardOverview);

interface RootRoutesProps extends RouteComponentProps<{}> {
    spaceContext: SpaceContext;
}

class RootRoutes extends React.Component<RootRoutesProps> {
    render() {
        return <Route render={({ location }) => {
            if (location.state && location.state.is404) {
                return <NotFound />;
            }

            // some links out in the wild contain / encoded to %2F which the angular router handled but react-router does not
            if (location.pathname.toUpperCase().includes("%2F")) {
                return <InternalRedirect to={location.pathname.replace("%2F", "/").replace("%2f", "/").replace("//", "/")} />;
            }

            return <Switch>
                {this.defaultRoute()}
                {this.spaceRoutes()}
                <ReloadableRoute path={routeLinks.currentUser.me} component={UserProfileRoutes} />
                <ReloadableRoute path={routeLinks.styleGuide} component={StyleGuidePage} />
                <ReloadableRoute path={routeLinks.codeGuide} component={CodeGuidePage} />

                {this.systemOrMixedScopeRoutes()}
                {this.noMatch()}
            </Switch>;
        }} />;
    }

    noMatch = () => {
        const spaceContext = this.props.spaceContext;
        if (isSpaceNotFound(spaceContext)) {
            return <ReloadableRoute render={() => <SpaceNotFound spaceNotFoundContext={spaceContext} />}/>;
        }
        return <RedirectAs404 />;
    }

    systemOrMixedScopeRoutes = () => {
        if (isSpaceNotFound(this.props.spaceContext)) {
            // If the space was not found, we don't want to show routes that are available in the system context.
            // We want to direct people to go to either a space-less route, or to step into a space.
            // So we should only show the Space Switcher in the navbar and disallow other routing
            return [];
        }
        return [
            <ReloadableRoute key="tasks" path={routeLinks.tasks.root} component={TaskRoutes} />,
            <ReloadableRoute key="configuration" path={routeLinks.configuration.root} component={ConfigurationRoutes} />,
            <ReloadableRoute key="legacy" path={routeLinks.legacy.manage.console} render={(p: any) => <InternalRedirect to={{ ...p.location, pathname: routeLinks.tasks.console }} />} />
        ];
    }

    defaultRoute = () => {
        if (isSpaceNotFound(this.props.spaceContext)) {
            return [];
        }
        if (isSpecificSpaceContext(this.props.spaceContext)) {
            return [<ReloadableRoute key="dashboard default" exact path={routeLinks.root} component={DashboardOverviewPage} />];
        }
        return [<ReloadableRoute key="configuration redirect" exact path={routeLinks.root}
                                 render={() => {
                                     return <InternalRedirect push={false} to={routeLinks.configuration.root}/>;
                                 }} />];
    }

    // These routes are
    spaceRoutes = () => {
        if (!isSpecificSpaceContext(this.props.spaceContext)) {
            // If you are not in a space, then it does not make sense to render any space specific components
            // We are better off letting this fall through to a 404,
            // rather than try and fail to render a space specific component, and probably end up with undefined behaviour
            return [];
        }
        return [
            <ReloadableRoute key="dashboard" path={routeLinks.dashboard.root} component={DashboardRoutes} />,
            <ReloadableRoute key="projects" path={routeLinks.projects.root} component={ProjectRoutes} />,
            <ReloadableRoute key="project groups" path={routeLinks.projectGroup(":ProjectGroupId").root} component={ProjectGroupPage} />,
            <ReloadableRoute key="infrastructure" path={routeLinks.infrastructure.root} component={InfrastructureRoutes} />,
            <ReloadableRoute key="tenants" path={routeLinks.tenants} component={TenantsRoutes} />,
            <ReloadableRoute key="library" path={routeLinks.library.root} component={LibraryRoutes} />,
            <ReloadableRoute key="deployments" path={routeLinks.deployments} component={DeploymentRoutes} />,

            <ReloadableRoute key="release redirect" path={routeLinks.release(":releaseId")}
                             render={(p: RouteComponentProps<{ releaseId: string }>) => <ReleaseRedirect releaseId={p.match.params.releaseId} />} />,
            <ReloadableRoute key="trigger redirect" path={routeLinks.trigger(":triggerId")}
                             render={(p: RouteComponentProps<{ triggerId: string }>) => <TriggerRedirect triggerId={p.match.params.triggerId} />} />,
            <ReloadableRoute key="channel redirect" path={routeLinks.channel(":channelId")}
                             render={(p: RouteComponentProps<{ channelId: string }>) => <ChannelRedirect channelId={p.match.params.channelId} />} />,

            <ReloadableRoute key="legacy environments" path={routeLinks.legacy.environments} component={V3EnvironmentRoutes} />,
            <ReloadableRoute key="legacy accounts" path={routeLinks.legacy.accounts} component={V3AccountRoutes} />,
            <ReloadableRoute key="legacy machines" path={routeLinks.legacy.machines} component={V3MachineRoutes} />
        ];
    }
}

export default withRouter(RootRoutes);