import * as React from "react";
import {connect} from "react-redux";
import { Dispatch, Action } from "redux";
import GlobalState, {ExpanderContainer} from "globalState";
import {
    defaultContainerKey, onExpanderCreated,
    onExpanderStateChanged, onAllExpandersCreated
} from "components/form/Sections/reducers/expanders";
import {Omit} from "utils/omit";

interface StateProps {
    expanders: { [errorKey: string]: boolean | undefined };
}

interface DispatchProps {
    onExpandedChanged(key: string, isExpanded: boolean): void;
    registerAllExpanders(keys: string[]): void;
}

export type GroupedExpandableProps = StateProps & DispatchProps;

// HOC that hooks up a component that manages expansion for multiple child components
// to the redux store
export default function Expandable<Props extends any>(
    containerKey: string,
    Comp: React.ComponentClass<Props & GroupedExpandableProps>
) {
    type InternalProps = StateProps & DispatchProps & Props;
    type ExternalProps = Omit<InternalProps, keyof GroupedExpandableProps>;

    class ExpandableInternal extends React.Component<InternalProps> {
        render() {
            return <Comp {...this.props} />;
        }
    }

    function mapStateToProps(state: GlobalState, props: InternalProps): StateProps {
        const container: ExpanderContainer | null =
            state.expanders && state.expanders.hasOwnProperty(containerKey) ? state.expanders[containerKey] : null;
        const storedValues: { [errorKey: string]: boolean | undefined | null } = container ? container.expanderValues : {};
        const initialState = container ? container.initialState : false;
        const expanders = Object.keys(storedValues).reduce((p: any, c) => {
             p[c] = getValueForKey(c);
             return p;
        }, {});

        function getValueForKey(errorKey: string) {
            const storedValue = storedValues[errorKey];
            return storedValue === undefined ? false : storedValue === null ? initialState : storedValue;
        }
        return { expanders };
    }

    function mapDispatchToProps(dispatch: Dispatch<Action<any>>, props: InternalProps): DispatchProps {
        return {
            registerAllExpanders: (keys: string[]) => {
                dispatch(onAllExpandersCreated(containerKey, keys, false));
            },
            onExpandedChanged: (key: string, state: boolean) => {
                dispatch(onExpanderStateChanged(containerKey, key, state));
            }
        };
    }

    return connect<StateProps, DispatchProps, Props>(
        mapStateToProps,
        mapDispatchToProps
    )(ExpandableInternal) as React.ComponentType<ExternalProps>;
}
