exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__Ia6wX {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__a12PU {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__2a0Q- {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__2DeIf {\n    display: none !important;\n  }\n}\n.style_bodyWithSides__1xywl {\n  display: flex;\n  flex: 1 1 auto;\n  padding: 0;\n}\n@media (max-width: 37.4rem) {\n  .style_bodyWithSides__1xywl {\n    flex-direction: column;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_sideBarRight__2_Hml {\n    border-left: 0.0625rem solid #cccccc;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_sideBarLeft__V3RKB {\n    border-right: 0.0625rem solid #cccccc;\n  }\n}\n.style_sidebarContainer__3MmJB {\n  flex-basis: 17.6rem;\n  padding-bottom: 1rem;\n}\n.style_sideBar__3WzhS {\n  font-size: 0.875rem;\n}\n@media (max-width: 37.4rem) {\n  .style_sideBar__3WzhS {\n    border-top: 0.0625rem solid #cccccc;\n  }\n}\n.style_sideBarPadding__jE6Gd {\n  padding: 0 1rem;\n}\n.style_main__3txB4 {\n  flex-grow: 1;\n  flex-basis: 1rem;\n  min-width: 0;\n}\n@media (max-width: 37.4rem) {\n  .style_main__3txB4 {\n    flex-basis: auto;\n  }\n}\n.style_overflowXHidden__Cjipd {\n  overflow-x: hidden;\n}\n.style_mainContent__2tIQ4 {\n  padding-top: 0;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__Ia6wX",
	"hideSm": "style_hide-sm__Ia6wX",
	"visible-sm": "style_visible-sm__a12PU",
	"visibleSm": "style_visible-sm__a12PU",
	"hide-md": "style_hide-md__2a0Q-",
	"hideMd": "style_hide-md__2a0Q-",
	"visible-md": "style_visible-md__2DeIf",
	"visibleMd": "style_visible-md__2DeIf",
	"bodyWithSides": "style_bodyWithSides__1xywl",
	"sideBarRight": "style_sideBarRight__2_Hml",
	"sideBarLeft": "style_sideBarLeft__V3RKB",
	"sidebarContainer": "style_sidebarContainer__3MmJB",
	"sideBar": "style_sideBar__3WzhS",
	"sideBarPadding": "style_sideBarPadding__jE6Gd",
	"main": "style_main__3txB4",
	"overflowXHidden": "style_overflowXHidden__Cjipd",
	"mainContent": "style_mainContent__2tIQ4"
};