exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_avatarContainer__g7FH3 {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n}\n", ""]);

// exports
exports.locals = {
	"avatarContainer": "style_avatarContainer__g7FH3"
};