import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import NodeJsCategory from "../../Definitions/NodeJsCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import {
    CommonCommandLineTextForOctoExe,
    CommonCommandLineTextForOctoExeCodeSample,
    CommonCommandLineTextForOctopackJs,
    CommonCommandLineTextForOctopackJsCodeSample,
} from "../CommonRegistrationText";

const CommandLinePackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging Node.js applications from command line, we recommend using <i>Octopack.js</i>.
        </p>
        {CommonCommandLineTextForOctopackJs}
        {CommonCommandLineTextForOctopackJsCodeSample}
        <p>
            Alternatively, <i>Octo.exe</i> can also be used to package your build output.
        </p>
        {CommonCommandLineTextForOctoExe}
        {CommonCommandLineTextForOctoExeCodeSample}
    </div>
);

export const CommandLinePackagingRegistrationForNodeJs: CategorizedPackagingRegistration = {
    displayOrder: 200,
    categories: [NodeJsCategory],
    name: "Command Line",
    type: "CommandLineForNodeJs",
    renderCard: InfoRegistrationCards.basic(
        () => <CommandLinePackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};