exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1_0si {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 1.25rem;\n  color: #ffffff;\n  font-size: 0.69rem;\n  text-shadow: 0 -0.0625rem 0 rgba(0, 0, 0, 0.2);\n  padding: 0 0.25rem;\n  white-space: nowrap;\n  border-radius: 0.25em;\n  background: #9e9e9e;\n}\n.style_progress__jXOt- {\n  visibility: visible;\n}\n.style_expected__voO9P {\n  visibility: visible;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1_0si",
	"progress": "style_progress__jXOt-",
	"expected": "style_expected__voO9P"
};