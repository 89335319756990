exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__3DclO {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__2B7fM {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__3FI_s {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__37PkO {\n    display: none !important;\n  }\n}\n.style_permissions__1RSGh ul {\n  list-style-type: disc;\n  margin-left: 2rem;\n  color: #666666;\n  margin-top: 8px;\n}\n.style_permissions__1RSGh h5 {\n  color: #333333;\n  margin-bottom: 0;\n}\n.style_roleName__2gCND {\n  margin-top: 0rem;\n}\n.style_missingRoleName__1R5lT {\n  margin-top: 0rem;\n  color: #db4437;\n}\n.style_filterText__1C4ql {\n  display: flex;\n  align-items: center;\n  margin: 0 0.125rem;\n}\n.style_lists__D3qYL {\n  display: flex;\n}\n@media (max-width: 37.4rem) {\n  .style_lists__D3qYL {\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: wrap;\n  }\n}\n.style_list__3d77i {\n  flex: 50%;\n  margin-top: 1rem;\n}\n.style_listHeader__1wqyc {\n  margin-left: 1rem;\n  margin-right: 1rem;\n  display: flex;\n  justify-content: space-between;\n}\n.style_listHeader__1wqyc h4 {\n  margin: 0.5rem 0;\n}\n.style_row__3rg6p {\n  display: inline-flex;\n  flex-direction: row;\n  width: 100%;\n}\n.style_detailsHighlight__1_3kW {\n  padding: 1rem 0;\n  background-color: rgba(153, 153, 153, 0.08);\n}\n.style_permissionDescriptionsActionContainer__3mcN3 {\n  margin: 0.25rem 0;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__3DclO",
	"hideSm": "style_hide-sm__3DclO",
	"visible-sm": "style_visible-sm__2B7fM",
	"visibleSm": "style_visible-sm__2B7fM",
	"hide-md": "style_hide-md__3FI_s",
	"hideMd": "style_hide-md__3FI_s",
	"visible-md": "style_visible-md__37PkO",
	"visibleMd": "style_visible-md__37PkO",
	"permissions": "style_permissions__1RSGh",
	"roleName": "style_roleName__2gCND",
	"missingRoleName": "style_missingRoleName__1R5lT",
	"filterText": "style_filterText__1C4ql",
	"lists": "style_lists__D3qYL",
	"list": "style_list__3d77i",
	"listHeader": "style_listHeader__1wqyc",
	"row": "style_row__3rg6p",
	"detailsHighlight": "style_detailsHighlight__1_3kW",
	"permissionDescriptionsActionContainer": "style_permissionDescriptionsActionContainer__3mcN3"
};