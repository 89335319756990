import {ScopeSpecification as MutableScopeSpecification, VariableResource} from "../../../client/resources/variableResource";
import ReadonlyVariableResource, {convertToFilterableValue} from "../ReadonlyVariableResource";
import {Dictionary, flatten, groupBy} from "lodash";
import {ScopeSpecification} from "../ReadonlyVariableResource/ReadonlyVariableResource";
import {VariableValueModel} from "../VariablesModel/VariablesModel";
import {VariableModel} from "../VariablesModel";
import {default as getVariableSaveWarnings} from "../VariableSaveWarnings/VariableSaveWarnings";
import {AllVariableMessages} from "../VariableMessages/VariableMessages";
import getVariablesMessages from "../VariableMessages/VariableMessages";
import {FilterableValue} from "../VariableFilter/VariableFilter";
import {VariableSaveConfirmationContent} from "../VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";

export function createViewModel(groupedVariableResources: Dictionary<ReadonlyVariableResource[]>): ReadonlyArray<VariableModel> {
    return Object.keys(groupedVariableResources).map(name => {
        const values = groupedVariableResources[name];
        return {name, values: values.map(createVariableValueViewModel)};
    });
}

function createVariableValueViewModel(variableResource: ReadonlyVariableResource): VariableValueModel {
    const {Name, ...rest} = variableResource;
    return rest;
}

export function getVariableResources(variables: ReadonlyArray<VariableModel>, groupedVariableResources: Dictionary<ReadonlyVariableResource[]>): VariableResource[] {
    return flatten(variables.map(variable => {
        const variableResourcesForThisName = groupedVariableResources[variable.name];
        const variableResourceIdsForThisName = variableResourcesForThisName ? variableResourcesForThisName.map(vr => vr.Id) : undefined;
        // We are trying to return the data in the original order so when the data is read back the same variable resource is first on the list and
        // the name we display in the UI is always the same. This can be delete once the backend and the frontend models are unified.
        const values = variableResourceIdsForThisName ? [...variable.values].sort((v1, v2) => compareByPosition(v1, v2, variableResourceIdsForThisName)) : variable.values;
        return values.map(value => createVariableResource(value, variable.name, variableResourcesForThisName));
    }));
}

function compareByPosition(value1: VariableValueModel, value2: VariableValueModel, ids: string[]) {
    const value1Index = findIndex(value1.Id, ids);
    const value2Index = findIndex(value2.Id, ids);

    return value1Index - value2Index;
}

function findIndex(idToFind: string, ids: string[]) {
    const index = ids.indexOf(idToFind);
    return index === -1 ? ids.length + 1 : index;
}

function createVariableResource(value: VariableValueModel, variableName: string, variableResourcesForThisName: ReadonlyVariableResource[]): VariableResource {

    // When we convert data to VariableResource we need to make sure that we use the original name if the variable has not been renamed.
    const matchingResource = variableResourcesForThisName && variableResourcesForThisName.find(vr => vr.Id === value.Id);
    const name = matchingResource ? matchingResource.Name : variableName;

    return {
        ...value,
        Name: name,
        Scope: buildUpScope(value.Scope)
    };

    function buildUpScope(readonlyScopeSpecification: ScopeSpecification): MutableScopeSpecification {
        const scope: MutableScopeSpecification = {};
        const environments = toArray(readonlyScopeSpecification.Environment);
        const machines = toArray(readonlyScopeSpecification.Machine);
        const roles = toArray(readonlyScopeSpecification.Role);
        const actions = toArray(readonlyScopeSpecification.Action);
        const channels = toArray(readonlyScopeSpecification.Channel);
        const tenantTags = toArray(readonlyScopeSpecification.TenantTag);
        // We can't just set the values of the properties to undefined, they actually must not be defined properties on the scope type
        // Otherwise deep equality checking of variables will not work
        if (environments) {
            scope.Environment = environments;
        }
        if (machines) {
            scope.Machine = machines;
        }
        if (roles) {
            scope.Role = roles;
        }
        if (actions) {
            scope.Action = actions;
        }
        if (channels) {
            scope.Channel = channels;
        }
        if (tenantTags) {
            scope.TenantTag = tenantTags;
        }
        return scope;
    }

    function toArray(values: ReadonlyArray<string> | null): string[] | undefined {
        // this MUST continue to be undefined in some circumstances (eg Channels and Actions in Library variable sets)
        return values ? [...values] : undefined;
    }
}

export function getVariablesMessagesForEditor(variables: ReadonlyArray<VariableModel>): AllVariableMessages {
    return getVariablesMessages(variables,
        variable => variable.name,
        variable => variable.values.map<FilterableValue | null>(v => v ? convertToFilterableValue(v) : null));
}

export function createDialogContent(variables: ReadonlyArray<VariableModel>, initialVariables: ReadonlyArray<VariableModel>, initialVariableResources: VariableResource[]) {
    const messages = getVariablesMessagesForEditor(variables);
    const variableSaveWarnings = getVariableSaveWarnings(variables, messages,
                                                        variable => variableHasValuesThatModifiedName(variable, initialVariables),
                                                        value => variableHasModifiedValue(value, initialVariableResources));
    return new VariableSaveConfirmationContent(variableSaveWarnings);
}

function variableHasValuesThatModifiedName(variable: VariableModel, initialVariables: ReadonlyArray<VariableModel>) {
    return !initialVariables.some(iv => iv.name === variable.name);
}

function variableHasModifiedValue(value: VariableValueModel, initialVariableResources: VariableResource[]) {
    const originalResource = initialVariableResources.find(r => r.Id === value.Id);
    return !(!!originalResource) || originalResource.Value !== value.Value;
}