exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_actionList__37i_d {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  flex-wrap: wrap;\n}\n.style_actionList__37i_d > :not(:first-child) {\n  margin-left: 0.25rem !important;\n}\n", ""]);

// exports
exports.locals = {
	"actionList": "style_actionList__37i_d"
};