import * as React from "react";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import OnboardingPage from "../../../../components/GettingStarted/OnboardingPage";

import { CommonPackagingSelector } from "./PackagingOnboarding/PackagingSelector";

export const SupportedPackageTypes = "NuGet, zip, tar, tar gzip, tar bzip2, jar, war, ear and rar";
export const OnboardingTextForPackaging = "The built-in repository accepts " + SupportedPackageTypes + " packages.";
export const OnboardingTitleForPackaging = "Need help with packaging?";
export const OnboardingHeadingForPackaging = (
  <React.Fragment>
    What type of <strong>software application</strong> do you want to package?
  </React.Fragment>
);
const Onboarding = (props: { showExample: () => void }) => {
  return (
    <React.Fragment>
      <OnboardingPage
        title="Upload or push packaged applications to the built-in package repository"
        intro={<span>{OnboardingTextForPackaging}<br /><a style={{ cursor: "pointer" }} onClick={() => props.showExample()}>Show examples of how to push packages</a>.</span>}
        learnMore={<span><ExternalLink href="OnboardingPackagesLearnMore">Learn more</ExternalLink>.</span>}
      />
      <CommonPackagingSelector title={OnboardingTitleForPackaging} heading={OnboardingHeadingForPackaging} />
    </React.Fragment>
  );
};

export default Onboarding;