import * as React from "react";
import {SFC} from "react";
import CodeEditor, {TextFormat} from "components/CodeEditor/CodeEditor";
import {ActionTemplateResource} from "client/resources/actionTemplateResource";
import {repository} from "clientInstance";
import InfoDialogLayout from "components/DialogLayout/InfoDialogLayout";
import Note from "components/form/Note/Note";
const styles = require("./style.less");

interface ExportActionTemplateDialogProps {
    template: Partial<ActionTemplateResource>;
}

function toJson(template: Partial<ActionTemplateResource>) {
    const cloned: any =  {...template};
    delete cloned.Links;

    cloned.$Meta = {
        ExportedAt: new Date(),
        OctopusVersion: repository.getServerInformation().version,
        Type: "ActionTemplate"
    };

    return JSON.stringify(cloned, null, 2);
}

function noop() {
    // empty on purpose
}

const ExportActionTemplateDialog: SFC<ExportActionTemplateDialogProps> = (props) => <InfoDialogLayout title="Export" errors={null} busy={null}>
        <CodeEditor value={toJson(props.template)}
                    language={TextFormat.JSON}
                    onChange={noop} />
        <div className={styles.exportNote}>
            <Note>Copy the entire contents of the editor to share this template with other Octopus Deploy servers.</Note>
        </div>
</InfoDialogLayout>;

ExportActionTemplateDialog.displayName = "ExportActionTemplateDialog";

export default ExportActionTemplateDialog;