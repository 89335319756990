import { combineReducers } from "redux";
import { chainReducers } from "@shoutem/redux-composers";
import infrastructureArea from "areas/infrastructure/reducers/infrastructureArea";
import libraryArea from "areas/library/reducers/libraryArea";
import projectsArea from "areas/projects/reducers/projectsArea";
import raiseUnhandledError from "components/BaseComponent/reducers";
import clearUnhandledError from "components/StandardLayout/reducers";
import { tenantsArea } from "../areas/tenants/tenantsArea";
import expanders from "components/form/Sections/reducers/expanders";
import dialogs from "components/Dialog/reducers/dialog";
import { notificationReducer } from "../components/Navbar/notifications.redux";
import { configurationArea } from "areas/configuration/reducers/configurationArea";
import formPaperLayoutReducer from "../components/FormPaperLayout/reducers";
import overlayArea from "components/OverlaySteps/reducers/overlayArea";
import onboardingArea from "components/GettingStarted/reducers/onboardingArea";
import pageLayoutArea from "components/PageLayout/reducers/pageLayoutArea";

interface Action {
    type: string;
}

const reducers = combineReducers({
    libraryArea,
    infrastructureArea,
    projectsArea,
    tenantsArea,
    configurationArea,
    expanders,
    dialogs,
    notifications: notificationReducer,
    unhandledError: chainReducers([raiseUnhandledError, clearUnhandledError]),
    formPaperLayoutReducer,
    overlayArea,
    onboardingArea,
    pageLayoutArea,
});

export { reducers, Action };