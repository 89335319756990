import * as React from "react";
import {UserResource} from "client/resources/userResource";
import {RouteComponentProps} from "react-router";
import {repository} from "clientInstance";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import { ResourceCollection } from "client/resources";
import Avatar from "components/Avatar";
import {ReactNode} from "react";
import { NavigationButton, NavigationButtonType } from "components/Button";
import PaperLayout from "components/PaperLayout/PaperLayout";
import List from "components/List";
import ListTitle from "components/ListTitle/ListTitle";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import SectionNote from "components/SectionNote/SectionNote";
const styles = require("./style.less");

interface UsersState extends DataBaseComponentState {
    usersResponse?: ResourceCollection<UserResource>;
}

class UserList extends List<UserResource> { }

export class Users extends DataBaseComponent<RouteComponentProps<{}>, UsersState> {
    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return await this.doBusyTask(async () => {
            const usersResponse = await repository.Users.list();
            this.setState({usersResponse});
        });
    }

    render() {
        const createUserButton = <PermissionCheck permission={[Permission.AdministerSystem, Permission.UserEdit]}>
            <NavigationButton type={NavigationButtonType.Primary}
                label="Add user"
                href={`${this.props.match.url}/create`} />
        </PermissionCheck>;

        return <PaperLayout
            title="Users"
            busy={this.state.busy}
            errors={this.state.errors}
            sectionControl={createUserButton}>
                <SectionNote>
                    Users and service accounts.
                </SectionNote>
                {this.state.usersResponse &&
                <UserList
                    initialData={this.state.usersResponse}
                    onRow={(item: any) => this.buildUserRow(item)}
                    onFilter={this.filter}
                    filterSearchEnabled={true}
                    apiSearchParams={["filter"]}
                    match={this.props.match}
                    filterHintText="Filter by username or display name..."
                    />}
            </PaperLayout>;
        }

    private buildUserRow(user: UserResource): ReactNode {
        return <div className={styles.row}>
            <div className={styles.avatar}>
                <Avatar avatarLink={user && user.Links && user.Links.Avatar}
                    isService={user && user.IsService}
                    size={40}
                />
            </div>
            <div>
                <ListTitle>{user.DisplayName}</ListTitle>
                <div>{user.EmailAddress || user.Username}</div>
            </div>
        </div>;
    }

    private filter(filter: string, resource: UserResource) {
        return !filter || filter.length === 0 || !resource
            || resource.Username.toLowerCase().includes(filter.toLowerCase())
            || (resource.EmailAddress && resource.EmailAddress.toLowerCase().includes(filter.toLowerCase()))
            || resource.DisplayName.toLowerCase().includes(filter.toLowerCase());
    }
}

export default Users;