exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1LhW1 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: left;\n  min-width: 11.25rem;\n  padding: 0.5rem;\n}\n.style_container__1LhW1 .style_additionalDetails__q5-QQ {\n  margin-left: 0.5rem;\n}\n.style_task-previous__mHeho {\n  opacity: .65;\n}\n.style_task-archived__G1KfB {\n  opacity: .2;\n}\n.style_details__10UKL {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: flex-start;\n  padding-left: 0.5rem;\n  width: 100%;\n}\n.style_version__3y8GX,\n.style_taskName__z8Cch {\n  color: #333333;\n  font-weight: 500;\n  font-size: 0.9375rem;\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n.style_date__1SpQz {\n  color: #666666;\n  font-weight: 400;\n  font-size: 0.6875rem;\n}\n.style_taskLink__1_d2s:hover {\n  background-color: rgba(153, 153, 153, 0.08);\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1LhW1",
	"additionalDetails": "style_additionalDetails__q5-QQ",
	"task-previous": "style_task-previous__mHeho",
	"taskPrevious": "style_task-previous__mHeho",
	"task-archived": "style_task-archived__G1KfB",
	"taskArchived": "style_task-archived__G1KfB",
	"details": "style_details__10UKL",
	"version": "style_version__3y8GX",
	"taskName": "style_taskName__z8Cch",
	"date": "style_date__1SpQz",
	"taskLink": "style_taskLink__1_d2s"
};