import * as React from "react";
import { repository } from "clientInstance";
import {
    EnvironmentResource,
    EnvironmentsSummaryResource,
    MachineModelHealthStatus,
    DeploymentTargetResource,
    MachineResource,
    TenantResource,
    TaskRestrictedTo,
} from "client/resources";
import { MachineIcon } from "components/Icon";
import { secondaryDark } from "colors";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { isEqual, each } from "lodash";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import { Card, CardMedia, CardTitle } from "material-ui/Card";
import OverflowMenu from "components/Menu/OverflowMenu";
import Permission from "client/resources/permission";
import BaseAllMachinesSummary from "./BaseAllMachinesSummary";
import {
    BaseAllMachinesSummaryProps,
    BaseAllMachinesSummaryState,
} from "./BaseAllMachinesSummary";
import { ListMachinesArgs } from "client/repositories/machineRepository";
import RequestRaceConditioner from "utils/RequestRaceConditioner";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ReactNode } from "react";
import { TagIndex } from "components/tenantTagsets";
import MachineRow from "../MachineRow/MachineRow";

interface DeploymentTargetsSummarySectionProps extends BaseAllMachinesSummaryProps {
    environmentsSummary: EnvironmentsSummaryResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tagIndex: TagIndex;
}

// tslint:disable-next-line:no-empty-interface
interface DeploymentTargetsSummarySectionState extends BaseAllMachinesSummaryState {
}

class DeploymentTargetsSummarySection extends BaseAllMachinesSummary<DeploymentTargetsSummarySectionProps, DeploymentTargetsSummarySectionState> {
    private requestRaceConditioner = new RequestRaceConditioner();

    constructor(props: DeploymentTargetsSummarySectionProps) {
        super(props);
        this.state = this.initialState();
    }

    componentDidMount() {
        this.reloadDataAndCurrentPageIndex();
    }

    componentWillReceiveProps(nextProps: BaseAllMachinesSummaryProps) {
        if (!isEqual(this.props.filter, nextProps.filter)) {
            this.reloadDataAndCurrentPageIndex();
        }
    }

    render() {
        if (this.state.redirectToTaskId) {
            return <InternalRedirect to={routeLinks.task(this.state.redirectToTaskId).root} push={true} />;
        }
        const environmentsSummary = this.props.environmentsSummary;
        const machinesHealthyLinks = this.renderMachineSummaryLinks(environmentsSummary, MachineModelHealthStatus.Healthy);
        const machinesUnavailableLinks = this.renderMachineSummaryLinks(environmentsSummary, MachineModelHealthStatus.Unavailable);
        const machinesUnknownLinks = this.renderMachineSummaryLinks(environmentsSummary, MachineModelHealthStatus.Unknown);
        const machinesHasWarningsLinks = this.renderMachineSummaryLinks(environmentsSummary, MachineModelHealthStatus.HasWarnings);
        const machinesUnhealthyLinks = this.renderMachineSummaryLinks(environmentsSummary, MachineModelHealthStatus.Unhealthy);
        const machinesDisabledLinks = this.renderMachineDisabledSummaryLinks(environmentsSummary);
        const summaryComponents = [
            machinesHealthyLinks,
            machinesHasWarningsLinks,
            machinesUnhealthyLinks,
            machinesUnavailableLinks,
            machinesUnknownLinks,
            machinesDisabledLinks,
        ];

        const componentKey = "allMachines";
        const overflowMenuItems: any[] = [];

        // Only show machine-related actions if they actually have some machines in this environment.
        if (environmentsSummary.TotalMachines > 0) {
            overflowMenuItems.push(OverflowMenu.item("Check Health", () => this.performHealthCheck(TaskRestrictedTo.DeploymentTargets), {
                permission: Permission.MachineEdit,
                wildcard: true,
            }));
            overflowMenuItems.push(OverflowMenu.confirmUpgrade("Upgrade all Tentacles", () => this.performTentacleUpgrade(TaskRestrictedTo.DeploymentTargets), {
                permission: Permission.MachineEdit,
                wildcard: true,
            }));
            overflowMenuItems.push(OverflowMenu.confirmUpgrade("Upgrade Calamari on Deployment Targets", () => this.performCalamariUpgrade(environmentsSummary.MachineIdsForCalamariUpgrade, "Upgrade Calamari on Deployment Targets"), {
                permission: Permission.MachineEdit,
                wildcard: true,
            }));
        }
        const titleContainer = <div className={styles.cardTitleContainer}>
            <div className={styles.environmentIcon}><MachineIcon color={secondaryDark} /></div>
            <div className={styles.environmentName}>Deployment targets</div>
            <div className={styles.environmentMachinesCount}>({environmentsSummary.TotalMachines && environmentsSummary.TotalMachines.toLocaleString()})</div>
            <div className={styles.environmentSummaryCounts}>
                {summaryComponents}
            </div>
            <div className={styles.environmentOverflowActions}>
                <OverflowMenu menuItems={overflowMenuItems} />
            </div>
        </div>;

        return <PaperLayout
            key={componentKey}
            busy={this.state.busy}
            errors={this.state.errors}
            className={styles.paperLayoutOverride}
        >
            <Card
                className={styles.formExpander}
                expandable={false}>
                <CardTitle
                    title={titleContainer}
                    showExpandableButton={false}
                />
                <CardMedia
                    className={styles.cardMedia}
                    expandable={false}>
                    {/*this div prevents material-ui from logging a warning https://github.com/callemall/material-ui/issues/4239#issuecomment-268054415*/}
                    <div>
                        {this.renderMachinesList()}
                    </div>
                </CardMedia>
            </Card>
        </PaperLayout>;
    }

    protected initialState(): DeploymentTargetsSummarySectionState {
        return {
            machinesResponse: null,
            currentPageIndex: 0,
            expanded: true,
            healthStatusFilter: null,
            isDisabledFilter: false,
            machineHealthStatusFastLookup: {},
        };
    }

    protected async loadData() {
        // We need to load ALL machines for a given environment that match the filtering criteria because
        // the design groups machines by their health status.
        const rolesCsv = this.props.filter.roles ? this.props.filter.roles.join(",") : null;

        // We need to consider both health status filters from our sidebar filter AND the expander links. If the user
        // has clicked a health status filter from the sidebar, that takes precendence.
        const applicableHealthStatusFilters = this.props.filter.healthStatuses.length > 0 ? this.props.filter.healthStatuses : [this.state.healthStatusFilter];
        const healthStatusCsv = applicableHealthStatusFilters ? applicableHealthStatusFilters.join(",") : null;
        // Same precendence logic applies to the "Disabled" filter.
        const isDisabled = this.props.filter.isDisabled ? this.props.filter.isDisabled : this.state.isDisabledFilter;

        const commStyleCsv = this.props.filter.commStyles.length > 0 ? this.props.filter.commStyles.join(",") : null;
        const tenantIdsCsv = this.props.filter.tenantIds.length > 0 ? this.props.filter.tenantIds.join(",") : null;
        const tenantTagsCsv = this.props.filter.tenantTags.length > 0 ? this.props.filter.tenantTags.join(",") : null;
        const environmentIdsCsv = this.props.filter.environmentIds.length > 0 ? this.props.filter.environmentIds.join(",") : null;

        // mark.siedle - We do a TakeAll here because we need to group our response data by health status for this design, then page within each group.
        // Alternatively we could run separate paging queries for EACH health status, but this would increase the number of queries significantly
        // and adds complexity. I think for 80% of cases, minimising the number of requests is the preferred approach, happy to debate though, since this will cause
        // a massive response for users operating at scale.
        const args: ListMachinesArgs = {
            skip: 0, // Don't skip, just increase the take size.
            take: repository.takeAll, // No paging, just take all, because we need to group our response data by health status.
            partialName: this.props.filter.partialName,
            roles: rolesCsv,
            isDisabled,
            healthStatuses: healthStatusCsv,
            commStyles: commStyleCsv,
            tenantIds: tenantIdsCsv,
            tenantTags: tenantTagsCsv,
            environmentIds: environmentIdsCsv,
        };

        await this.requestRaceConditioner.avoidStaleResponsesForRequest(repository.Machines.list(args), (machinesResponse) => {
            // mark.siedle - Bit of trickery here to emulate paging for large collections of machines :)
            // This makes the render operation much faster when dealing with thousands of machines in a given environment.
            const machineHealthStatusFastLookup = this.state.machineHealthStatusFastLookup;
            const objValues = Object.keys(MachineModelHealthStatus).map(k => (MachineModelHealthStatus as any)[k]);
            const names = objValues.filter(v => typeof v === "string") as string[];
            each(names, (statusText) => {
                const status = statusText as MachineModelHealthStatus;
                const machines = machinesResponse.Items.filter(x => x.HealthStatus === status);
                const machinesForHealthStatus = this.makeMachineResourceCollection(machines, this.machineListTakeSize);
                machineHealthStatusFastLookup[status] = machinesForHealthStatus;
            });
            // Insert "Disabled" separately.
            const disabledMachines = machinesResponse.Items.filter(x => x.IsDisabled);
            const disabledMachinesResourceCollection = this.makeMachineResourceCollection(disabledMachines, this.machineListTakeSize);
            machineHealthStatusFastLookup["Disabled"] = disabledMachinesResourceCollection;

            this.setState({
                machinesResponse,
                machineHealthStatusFastLookup,
            });
        });
    }

    protected renderMachine(machine: MachineResource): ReactNode {
        return <MachineRow machine={machine as DeploymentTargetResource}
            environments={this.props.environments}
            tenants={this.props.tenants}
            tagIndex={this.props.tagIndex}
        />;
    }
}

export default DeploymentTargetsSummarySection;