import * as React from "react";
import { repository } from "clientInstance";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { RouteComponentProps } from "react-router";
import PaperLayout from "components/PaperLayout/PaperLayout";
import ActionList from "components/ActionList/ActionList";
import { TaskResource } from "client/resources";
import { ActionButton, ActionButtonType } from "components/Button/ActionButton";
import { NavigationButton } from "components/Button/NavigationButton";
import TaskLayout from "areas/tasks/TaskLayout/TaskLayout";
const styles = require("./style.less");
import routeLinks from "../../../../routeLinks";
import { TaskStatusIcon } from "areas/projects/components/TaskStatusIcon/TaskStatusIcon";

interface RawLogState extends DataBaseComponentState {
    task?: TaskResource<any>;
    raw?: string;
}

export class RawLog extends DataBaseComponent<RouteComponentProps<any>, RawLogState> {
    taskId: string;

    constructor(props: RouteComponentProps<any>) {
        super(props);
        this.taskId = this.props.match.params.taskId;
        this.state = {};
    }

    componentDidMount() {
        return this.refresh();
    }

    refresh() {
        return this.doBusyTask(async () => {
            const task = await repository.Tasks.get(this.taskId);
            const raw = await repository.Tasks.getRaw(task);
            this.setState({ task, raw });
        });
    }

    render() {
        const task = this.state.task;

        const actionList = task && <ActionList actions={[
            <NavigationButton
                key="raw"
                href={task.Links["Raw"]}
                label="Download"
                external={true} />,
            <ActionButton
                key="refresh"
                label="Refresh"
                type={ActionButtonType.Primary}
                disabled={this.state.busy}
                onClick={() => this.refresh()} />
        ]} />;

        return <TaskLayout>
            <PaperLayout title={"Raw Log"}
                titleLogo={task ? <TaskStatusIcon item={task}/> : null}
                breadcrumbTitle={task && task.Description}
                breadcrumbPath={routeLinks.task(task && task.Id).root}
                busy={this.state.busy}
                errors={this.state.errors}
                sectionControl={actionList}
                fullWidth={true}>
                {
                    task && <div className={styles.rawLogContent}>
                        {this.state.raw}
                    </div>}
            </PaperLayout>
        </TaskLayout>;
    }
}

export default RawLog;