exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .EndpointCard_hide-sm__t0oAS {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .EndpointCard_visible-sm__2rUCu {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .EndpointCard_hide-md__1Lq-r {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .EndpointCard_visible-md__36-hC {\n    display: none !important;\n  }\n}\n.EndpointCard_card__2Yqsr {\n  flex: 1;\n  min-width: 16.25rem;\n  max-width: 18.75rem;\n}\n@media (max-width: 37.4rem) {\n  .EndpointCard_card__2Yqsr {\n    max-width: 100%;\n  }\n}\n.EndpointCard_card__2Yqsr .EndpointCard_description__3PLB- {\n  margin-top: 0.5rem;\n}\n.EndpointCard_card__2Yqsr div:nth-child(1) {\n  width: 100%;\n  text-align: center;\n}\n@media (max-width: 37.4rem) {\n  .EndpointCard_card__2Yqsr div:nth-child(1) {\n    width: auto;\n  }\n}\n.EndpointCard_card__2Yqsr div:nth-child(1) .EndpointCard_logo__1T-M1 {\n  width: auto;\n  height: 5rem;\n  margin: 0 auto;\n}\n.EndpointCard_card__2Yqsr div:nth-child(1) .EndpointCard_logo__1T-M1 img {\n  height: 5rem;\n}\n.EndpointCard_card__2Yqsr div:nth-child(1) div {\n  width: auto;\n}\n.EndpointCard_onHoverActions__M8Nux {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "EndpointCard_hide-sm__t0oAS",
	"hideSm": "EndpointCard_hide-sm__t0oAS",
	"visible-sm": "EndpointCard_visible-sm__2rUCu",
	"visibleSm": "EndpointCard_visible-sm__2rUCu",
	"hide-md": "EndpointCard_hide-md__1Lq-r",
	"hideMd": "EndpointCard_hide-md__1Lq-r",
	"visible-md": "EndpointCard_visible-md__36-hC",
	"visibleMd": "EndpointCard_visible-md__36-hC",
	"card": "EndpointCard_card__2Yqsr",
	"description": "EndpointCard_description__3PLB-",
	"logo": "EndpointCard_logo__1T-M1",
	"onHoverActions": "EndpointCard_onHoverActions__M8Nux"
};