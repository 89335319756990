import * as React from "react";
import { repository } from "clientInstance";
import { FormBaseComponent, OptionalFormBaseComponentState } from "components/FormBaseComponent";
import {
    EnvironmentResource,
    ChannelResource,
    ResourceCollection,
    ProjectResource,
    Permission,
    CronTriggerScheduleResource,
    TriggerScheduleResource,
    ScopedDeploymentActionResource,
    ScheduleIntervalResource,
    DaysPerWeekTriggerScheduleResource,
    DaysPerMonthTriggerScheduleResource,
    DailyTriggerScheduleResource,
    DeployLatestReleaseActionResource,
    DeployNewReleaseActionResource,
    TriggerResourceTyped,
    TriggerFilterType,
    TriggerActionType,
    NewTriggerResourceTyped,
    TriggerResource,
    isExistingTriggerResource,
    TenantResource,
    TenantedDeploymentMode,
    ServerTimezoneResource,
    TriggerScheduleRunType,
    LifecycleResource,
} from "client/resources";
import { ProjectRouteParams } from "areas/projects/components/ProjectLayout/ProjectLayout";
import { RouteComponentProps } from "react-router";
import StringHelper from "utils/StringHelper/StringHelper";
import { OverflowMenu } from "components/Menu";
import FormPaperLayout from "components/FormPaperLayout";
import routeLinks from "../../../../../routeLinks";
import {
    Text,
    ExpansionButtons,
    ExpandableFormSection,
    Summary,
    Note,
    required,
    StringRadioButtonGroup,
    RadioButton,
    FormSectionHeading,
    Select
} from "components/form";
import {
    TenantMultiSelect
} from "components/MultiSelect";
import { cloneDeep } from "lodash";
import { Callout, CalloutType } from "components/Callout";
import { DailyScheduledTriggerEditor, DaysPerWeekScheduledTriggerEditor, DaysPerMonthScheduledTriggerEditor, CronExpressionScheduledTriggerEditor } from "./ScheduleEditors";
import { DeployLatestReleaseActionEditor, DeployNewReleaseActionEditor } from "./ActionEditors";
import WarningFormSection from "components/form/Sections/WarningFormSection";
import { PermissionCheck } from "components/PermissionCheck";
import GlobalState from "globalState";
import { MapStateToProps, connect } from "react-redux";
import CommonSummaryHelper from "utils/CommonSummaryHelper";
import InternalRedirect from "components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

type Model = TriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>
            | NewTriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>;

interface ConnectedProps {
    isMultiTenancyEnabled: boolean;
}

interface EditState extends OptionalFormBaseComponentState<Model> {
    environments: EnvironmentResource[];
    channels: ResourceCollection<ChannelResource>;
    tenants: TenantResource[];
    project: ProjectResource;
    channelId?: string;
    redirectTo?: string;
    interval: ScheduleIntervalResource;
    timezones: ServerTimezoneResource[];
    lifecycle: LifecycleResource;
}

export interface EditScheduledTriggerRouteProps extends RouteComponentProps<ProjectRouteParams & { triggerId: string }> {
}

export interface EditScheduledTriggerModeProps {
    create: boolean;
}

export type EditScheduledTriggerProps = EditScheduledTriggerModeProps  & EditScheduledTriggerRouteProps & ConnectedProps;

class EditScheduledTrigger extends FormBaseComponent<EditScheduledTriggerProps, EditState, Model> {
    channelNameMap: any = {};
    constructor(props: EditScheduledTriggerProps) {
        super(props);
        this.state = {
            model: null,
            cleanModel: null,
            environments: [],
            channels: null,
            tenants: [],
            project: null,
            interval: null,
            timezones: [],
            lifecycle: null
        };
    }

    async componentDidMount() {
        let newTrigger: NewTriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource> = null;
        let loadTrigger: any = null;

        const projectAsync = repository.Projects.get(this.props.match.params.projectSlug);

        let project: ProjectResource = null;
        let timezones: ServerTimezoneResource[] = [];
        await this.doBusyTask(async () => {
            project = await projectAsync;
            timezones = await repository.ServerStatus.getTimezones();
        });

        const serverLocalTimezones = timezones.filter((tz: any) => {
            return tz.IsLocal === true;
        });

        if (this.props.create) {
            const triggerScheduleResource = new DailyTriggerScheduleResource();
            triggerScheduleResource.Timezone = serverLocalTimezones.length > 0 ? serverLocalTimezones[0].Id : null;
            triggerScheduleResource.RunType = TriggerScheduleRunType.ScheduledTime;
            const currentDate = new Date();
            triggerScheduleResource.StartTime = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9, 0, 0));

            const triggerActionResource = new DeployLatestReleaseActionResource();

            newTrigger = {
                ProjectId: project.Id,
                Name: null,
                IsDisabled: false,
                Filter: triggerScheduleResource,
                Action: triggerActionResource,
            };
        } else {
            loadTrigger = repository.ProjectTriggers.get(this.props.match.params.triggerId);
        }

        await this.doBusyTask(async () => {
            const lookupData = Promise.all([
                repository.Environments.all(),
                repository.Projects.getChannels(project),
                this.props.isMultiTenancyEnabled ? repository.Tenants.all({projectId: project.Id}) : Promise.resolve([]),
            ]);
            const results = await Promise.all<[EnvironmentResource[], ResourceCollection<ChannelResource>, TenantResource[]],
                TriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>>([
                    lookupData,
                    loadTrigger
                ]);

            const [environments, channels, tenants] = results[0];

            const trigger = results[1];
            channels.Items.forEach(channel => {
                this.channelNameMap[channel.Id] = channel;
            });

            // Channel selection is not mandatory so an existing trigger when multiple channel options are available may not have one selected
            const triggerHasSelectedChannel = !!(trigger && trigger.Action && trigger.Action.ChannelId);
            const selectedChannelOnTrigger = triggerHasSelectedChannel && channels.Items.find(c => c.Id === trigger.Action.ChannelId);

            // even tho we have a selectedChannelOnTrigger but it may not have a lifecycle selection
            const lifecycleId = (selectedChannelOnTrigger && selectedChannelOnTrigger.LifecycleId) ||  project.LifecycleId;

            const lifecycle = await repository.Lifecycles.get(lifecycleId);
            const lifecyclePreview = await repository.Lifecycles.preview(lifecycle);

            this.setState({
                model: newTrigger || trigger,
                environments,
                channels,
                tenants,
                project,
                timezones,
                lifecycle: lifecyclePreview,
                cleanModel: cloneDeep(newTrigger || trigger)
            });
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const title = this.props.create
            ? "New Scheduled Trigger"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (this.state.model && isExistingTriggerResource(this.state.model)) {
            const model: TriggerResource = this.state.model;
            overFlowActions.push(
                OverflowMenu.item(
                    this.state.model && this.state.model.IsDisabled ? "Enable" : "Disable",
                    () => this.state.model && this.state.model.IsDisabled ? this.enableTrigger() : this.disableTrigger(),
                    {permission: Permission.TriggerEdit, project: this.state.project && this.state.project.Id}
                )
            );
            overFlowActions.push(
                OverflowMenu.deleteItemDefault(
                    "scheduled trigger",
                    () => this.deleteTrigger(model),
                    {permission: Permission.TriggerDelete, project: this.state.project && this.state.project.Id}
                )
            );
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);
        }

        const saveText: string = this.props.create
            ? "Scheduled trigger created"
            : "Scheduled trigger details updated";

        const inputStyle = {
            input: {
                border: "0",
                fontSize: 16,
                height: "100%"
            }
        };

        const componentRowSpanStyle = {
            width: "100%",
            marginTop: "2rem"
        };

        return (
            <FormPaperLayout
                busy={this.state.busy}
                errors={this.state.errors}
                title={title}
                breadcrumbTitle="Scheduled Triggers"
                breadcrumbPath={routeLinks.project(this.props.match.params.projectSlug).triggers}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{
                    permission: this.props.create ? Permission.TriggerCreate : Permission.TriggerEdit,
                    project: this.state.project && this.state.project.Id
                }}
                onSaveClick={() => this.saveTrigger()}
                overFlowActions={overFlowActions}
                hideExpandAll={true}
                saveText={saveText}>
                    {this.state.model && <TransitionAnimation>
                        <ExpansionButtons errors={this.state.errors} expandAllOnMount={this.props.create} />

                        {this.state.cleanModel && this.state.cleanModel.IsDisabled && <WarningFormSection>
                            <Callout type={CalloutType.Warning}>
                                This trigger is currently disabled.
                            </Callout>
                        </WarningFormSection>}

                        <ExpandableFormSection
                            errorKey="Name"
                            title="Name"
                            focusOnExpandAll
                            summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your scheduled trigger")}
                            help="Enter a name for your scheduled trigger.">
                            <Text
                                value={this.state.model.Name || ""}
                                onChange={Name => this.setModelState({Name})}
                                label="Scheduled trigger name"
                                validate={required("Please enter a scheduled trigger name")}
                                error={this.getFieldError("Name")}
                                autoFocus={true}
                            />
                            <Note>
                                A short, memorable, unique name for this scheduled trigger.
                                Example: <i>Promote latest release in Development to Staging Environment at 9am every day</i>
                            </Note>
                        </ExpandableFormSection>
                        <FormSectionHeading title="Trigger Schedule"/>
                        <ExpandableFormSection
                            errorKey="ScheduleType"
                            title="Schedule"
                            summary={this.getScheduleTypeSummary()}
                            help="Select the schedule that the trigger should run on.">
                            <StringRadioButtonGroup
                                value={this.state.model.Filter.FilterType}
                                onChange={(scheduleType: TriggerFilterType) => this.setChildState2("model", "Filter", {FilterType: scheduleType})}>
                                <RadioButton
                                    value={TriggerFilterType.DailySchedule}
                                    label="Daily"
                                    key={TriggerFilterType.DailySchedule}/>
                                <Note>
                                    Allows you to configure a trigger that will run once, hourly or minutely daily
                                </Note>
                                <RadioButton
                                    value={TriggerFilterType.DaysPerWeekSchedule}
                                    label="Days per week"
                                    key={TriggerFilterType.DaysPerWeekSchedule}/>
                                <Note>
                                    Allows you to configure a trigger that will run once, hourly or minutely on certain days of the week
                                </Note>
                                <RadioButton
                                    value={TriggerFilterType.DaysPerMonthSchedule}
                                    label="Days per month"
                                    key={TriggerFilterType.DaysPerMonthSchedule}/>
                                <Note>
                                    Allows you to configure a trigger that will run on a specific date of the month or specific day of week of every month
                                </Note>
                                <RadioButton
                                    value={TriggerFilterType.CronExpressionSchedule}
                                    label="Cron expression"
                                    key={TriggerFilterType.CronExpressionSchedule}/>
                                <Note>
                                    Allows you to configure a trigger that will run according to the specific CRON expression
                                </Note>
                            </StringRadioButtonGroup>
                        </ExpandableFormSection>
                        {this.state.model.Filter.FilterType === TriggerFilterType.DailySchedule &&
                            <DailyScheduledTriggerEditor
                                schedule={(this.state.model.Filter as DailyTriggerScheduleResource)}
                                timezones={this.state.timezones}
                                onScheduleChange={this.scheduledTriggerScheduleChanged}/>
                        }
                        {this.state.model.Filter.FilterType === TriggerFilterType.DaysPerWeekSchedule &&
                            <DaysPerWeekScheduledTriggerEditor
                                schedule={(this.state.model.Filter as DaysPerWeekTriggerScheduleResource)}
                                timezones={this.state.timezones}
                                onScheduleChange={this.scheduledTriggerScheduleChanged}/>
                        }
                        {this.state.model.Filter.FilterType === TriggerFilterType.DaysPerMonthSchedule &&
                            <DaysPerMonthScheduledTriggerEditor
                                schedule={(this.state.model.Filter as DaysPerMonthTriggerScheduleResource)}
                                timezones={this.state.timezones}
                                onScheduleChange={this.scheduledTriggerScheduleChanged}/>
                        }
                        {this.state.model.Filter.FilterType === TriggerFilterType.CronExpressionSchedule &&
                            <CronExpressionScheduledTriggerEditor
                                schedule={(this.state.model.Filter as CronTriggerScheduleResource)}
                                timezones={this.state.timezones}
                                onScheduleChange={this.scheduledTriggerScheduleChanged}/>
                        }
                        <FormSectionHeading title="Trigger Action"/>
                        <ExpandableFormSection
                            errorKey="ActionType"
                            title="Action"
                            summary={this.getActionTypeSummary()}
                            help="Select the action that the trigger should take when executed.">
                            <StringRadioButtonGroup
                                value={this.state.model.Action.ActionType}
                                onChange={this.onTriggerActionTypeChange}>
                                <RadioButton
                                    value={TriggerActionType.DeployLatestRelease}
                                    label="Deploy latest release"
                                    key={TriggerActionType.DeployLatestRelease}/>
                                <Note>
                                    If the same environment is selected for both source and destination the latest successfully deployed release in the source environment
                                    will be re-deployed to the destination environment, if different environments are selected the latest successfully deployed release in
                                    the source environment will be promoted to the destination environment
                                </Note>
                                <RadioButton
                                    value={TriggerActionType.DeployNewRelease}
                                    label="Deploy new release"
                                    key={TriggerActionType.DeployNewRelease}/>
                                <Note>
                                    A new release will be created and deployed to the selected environment
                                </Note>
                            </StringRadioButtonGroup>
                        </ExpandableFormSection>
                        {this.state.channels.Items.length > 1 &&
                        <ExpandableFormSection
                            errorKey="Channel"
                            title="Channel"
                            focusOnExpandAll
                            summary={this.buildChannelSummary()}
                            help={this.buildChannelHelp()}>
                            <Select
                                allowClear={true}
                                items={this.state.channels.Items.map((c) => {
                                    return {text: c.Name, value: c.Id};
                                })}
                                value={this.state.model.Action.ChannelId}
                                onChange={this.onChannelChange}
                            />
                        </ExpandableFormSection>
                        }
                        {this.props.isMultiTenancyEnabled && this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted && this.state.tenants.length > 0 &&
                            <PermissionCheck permission={Permission.TenantView} tenant="*">
                                <ExpandableFormSection
                                    errorKey="TenantSelector"
                                    title="Tenants"
                                    focusOnExpandAll
                                    summary={this.buildTenantSummary()}
                                    help={this.buildTenantHelp()}>
                                    {/* We don't support tenant tag permissions yet, so the decision was to only support tenants, we can change our mind in the future */}
                                    <TenantMultiSelect
                                        items={this.state.tenants}
                                        onChange={tenantIds => this.setChildState2("model", "Action", {TenantIds: tenantIds, TenantTags: []})}
                                        value={this.state.model.Action.TenantIds}/>
                                    {/* <AdvancedTenantsAndTenantTagsSelector
                                        tenants={this.state.tenants}
                                        doBusyTask={this.doBusyTask}
                                        selectedTenantIds={this.state.model.Action.TenantIds}
                                        selectedTenantTags={this.state.model.Action.TenantTags}
                                        onChange={(tenantIds, tenantTags) => this.setChildState2("model", "Action", {TenantIds: tenantIds, TenantTags: tenantTags})} /> */}
                                </ExpandableFormSection>
                            </PermissionCheck>
                        }

                        {this.state.model.Action.ActionType === TriggerActionType.DeployLatestRelease &&
                            <DeployLatestReleaseActionEditor
                                action={this.state.model.Action as DeployLatestReleaseActionResource}
                                allEnvironments={this.state.environments}
                                lifecycle={this.state.lifecycle}
                                onActionChange={this.scheduledTriggerActionChanged}/>
                        }
                        {this.state.model.Action.ActionType === TriggerActionType.DeployNewRelease &&
                            <DeployNewReleaseActionEditor
                                action={this.state.model.Action as DeployNewReleaseActionResource}
                                allEnvironments={this.state.environments}
                                lifecycle={this.state.lifecycle}
                                onActionChange={this.scheduledTriggerActionChanged}/>
                        }

                        {/* Disabling this as it needs more thought about usage of VariableView and VariableEdit permissions*/}
                        {/* <ExpandableFormSection errorKey="Variables"
                                               title="Variables"
                                               fillCardWidth={CardFill.FillRight}
                                               summary={this.buildVariableSummary()}
                                               help={this.buildVariableHelp()}>
                            <KeyValueEditList items={this.state.model.Action.Variables}
                                              name="Variable"
                                              separator="="
                                              onChange={val => this.setChildState2("model", "Action", {Variables: val})}
                                              valueLabel="Value"
                                              keyLabel="Variable name"
                                              hideBindOnKey={true}
                                              projectId={this.state.model.ProjectId}
                            />
                        </ExpandableFormSection> */}
                    </TransitionAnimation>
                }
           </FormPaperLayout>
        );
    }

    private buildChannelSummary = () => {
        switch (this.state.model.Action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                return this.state.model.Action.ChannelId
                    ? Summary.summary(<span>Latest release in channel <strong>{this.channelNameMap[this.state.model.Action.ChannelId].Name}</strong> will be promoted</span>)
                    : Summary.placeholder("Latest release in project will be deployed");
            case TriggerActionType.DeployNewRelease:
                return this.state.model.Action.ChannelId
                    ? Summary.summary(<span>New release will be created in the <strong>{this.channelNameMap[this.state.model.Action.ChannelId].Name}</strong> channel.</span>)
                    : Summary.summary(<span>New release will be created in the default channel.</span>);
        }
    }

    private buildChannelHelp = () => {
        let helpSummary = "";
        switch (this.state.model.Action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                //helpSummary = "The channel to use when selecting the release to promote, or leave blank to use the latest release for the project.";
                helpSummary = "The channel to use when selecting the release to deploy, or leave blank to use the latest release for the project.";
                break;
            case TriggerActionType.DeployNewRelease:
                helpSummary = "The channel to use when creating the release.";
                break;
            default:
                helpSummary = "Select the channel to use when selecting the release, or leave blank to use the latest release for the project.";
                break;
        }
        return helpSummary;
    }

    private buildVariableSummary = () => {
        return Summary.placeholder("Define variables");
    }

    private buildVariableHelp = () => {
        return "Define variables that will be overridden in the deployment or substituted for prompted variables.";
    }

    private buildTenantSummary = () => {
        const showMissingTenants = true;
        return CommonSummaryHelper.tenantSummary(
            this.state.model.Action.TenantIds,
            this.state.model.Action.TenantTags,
            this.state.tenants,
            showMissingTenants
        );
    }

    private buildTenantHelp = () => {
        return "Select the Tenants to deploy the release to";
    }

    private getScheduleTypeSummary() {
        let summary = "";
        switch (this.state.model.Filter.FilterType) {
            case TriggerFilterType.DailySchedule:
                summary = "Runs daily";
                break;
            case TriggerFilterType.DaysPerWeekSchedule:
                summary = "Runs on specific days of the week";
                break;
            case TriggerFilterType.DaysPerMonthSchedule:
                summary = "Runs on a specific day of the month";
                break;
            case TriggerFilterType.CronExpressionSchedule:
                summary = "Runs according to a cron expression";
                break;
            default:
                return Summary.placeholder("Please select the triggers schedule");
        }
        return Summary.summary(summary);
    }

    private getActionTypeSummary() {
        let summary = "";
        switch (this.state.model.Action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                summary = "Deploy the latest release";
                break;
            case TriggerActionType.DeployNewRelease:
                summary = "Create and deploy a new release";
                break;
            default:
                return Summary.placeholder("Please select the trigger Action");
        }
        return Summary.summary(summary);
    }

    private onChannelChange = async (channelId: string) => {
        this.setChildState2("model", "Action", {ChannelId: channelId}, async () => {
            await this.updateLifecycle(channelId);
        });
    }

    private onTriggerActionTypeChange = (triggerActionType: TriggerActionType) => {
        const newAction = triggerActionType === TriggerActionType.DeployLatestRelease ? new DeployLatestReleaseActionResource() : new DeployNewReleaseActionResource();
        if (triggerActionType === TriggerActionType.DeployNewRelease && this.state.channels.Items.length === 1) {
            newAction.ChannelId = this.state.channels.Items.find(c => c.IsDefault).Id;
        }
        this.setChildState1("model", {Action: newAction}, async () => {
            this.updateLifecycle();
        });
    }

    private scheduledTriggerScheduleChanged = (schedule: TriggerScheduleResource) => {
        this.setChildState1("model", {Filter: schedule});
    }

    private scheduledTriggerActionChanged = (action: ScopedDeploymentActionResource) => {
        this.setChildState1("model", {Action: action});
    }

    private updateLifecycle = async (channelId: string = null) => {
        const lifecycle = await repository.Lifecycles.get(channelId ? this.channelNameMap[channelId].LifecycleId : this.state.project.LifecycleId);
        const preview = await repository.Lifecycles.preview(lifecycle);
        this.setState({
            lifecycle: preview
        });
    }
    private async saveTrigger() {
        await this.doBusyTask(async () => {
            const result =
                await repository.ProjectTriggers.save(this.state.model) as TriggerResourceTyped<TriggerScheduleResource, ScopedDeploymentActionResource>;

            if (this.props.create) {
                this.setState({redirectTo: routeLinks.project(this.state.model.ProjectId).trigger(result, result.Action.ActionType)});
            } else {
                this.setState({
                    model: result,
                    cleanModel: cloneDeep(result)
                });
            }
        });
    }

    private async enableTrigger() {
        this.setChildState1("model", {IsDisabled: false}, () => this.saveTrigger());
    }

    private async disableTrigger() {
        this.setChildState1("model", {IsDisabled: true}, () => this.saveTrigger());
    }

    private async deleteTrigger(model: TriggerResource) {
        await this.doBusyTask(async () => {
            await repository.ProjectTriggers.del(model);

            this.setState({ redirectTo: routeLinks.project(this.state.project).triggers});
        });
        return true;
    }
}

const mapStateToProps = (state: GlobalState, props: EditScheduledTriggerModeProps & EditScheduledTriggerRouteProps): ConnectedProps => {
    return {
        isMultiTenancyEnabled: state.configurationArea.currentSpace.isMultiTenancyEnabled
    };
};

const EnhancedScheduledTrigger = connect(mapStateToProps)(EditScheduledTrigger);

export default EnhancedScheduledTrigger;