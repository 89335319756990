import NoActionDialog from "../../../../components/Dialog/NoActionDialog";
import * as React from "react";
import { repository, client } from "clientInstance";
import { LibraryLayout } from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import {
    PackageResource,
    ResourceCollection,
    TaskResource,
    TaskState,
    Permission,
    TaskName
} from "client/resources";
import ExternalLink from "components/Navigation/ExternalLink";
import { Callout, CalloutType } from "components/Callout";
import LatestPackagesList from "./LatestPackagesList";
import PackageUpload from "./UploadPackage";
import { RouteComponentProps } from "react-router";
import { Section } from "components/Section/Section";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import SidebarLayout from "components/SidebarLayout/SidebarLayout";
import Onboarding, { OnboardingTextForPackaging, OnboardingHeadingForPackaging, OnboardingTitleForPackaging } from "./Onboarding";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import routeLinks from "../../../../routeLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { getPackageEndpoint, getRawPackageEndpoint } from "./BuiltInRepositoryUtils";
import SectionNote from "components/SectionNote/SectionNote";
import { CommonPackagingSelector } from "./PackagingOnboarding/PackagingSelector";
import BuiltInRepositoryRetention from "./BuiltInRepositoryRetention";
import BuiltInRepositoryIndexing from "./BuiltInRepositoryIndexing";
import { BuiltInFeedResource } from "client/resources/feedResource";

interface BuiltInRepositoryOverviewState extends DataBaseComponentState {
    showPushExample: boolean;
    showPackagingExample: boolean;
    lastRepositorySyncStatus?: string;
    lastRepositorySyncId?: string;
    lastRepositorySyncCompletedTime?: string;
    isBuiltInRepoSyncEnabled?: boolean;
    redirectTo?: string;
    initialPackages?: ResourceCollection<PackageResource>;
    loaded: boolean;
    builtInResource?: BuiltInFeedResource;
    totalPackages: number;
    showOnboardingSidebar: boolean;
}

export class BuiltInRepositoryOverview extends DataBaseComponent<RouteComponentProps<{}>, BuiltInRepositoryOverviewState> {

    private defaultTake = 50;
    private canConfigureServer = isAllowed({ permission: Permission.ConfigureServer });

    constructor(props: RouteComponentProps<{}>) {
        super(props);
        this.state = {
            showPushExample: false,
            showPackagingExample: false,
            loaded: false,
            showOnboardingSidebar: false,
            totalPackages: 0
        };
    }

    async componentDidMount() {
        await this.doBusyTask(() => this.initialLoad());
    }

    async initialLoad() {
        const builtInResource = this.canConfigureServer ? await repository.Feeds.getBuiltIn() : null;
        const getTotalPackages = repository.Feeds.getBuiltInStatus();
        const getinitialPackages = this.getInitialPackages("", this.defaultTake);
        const lastRepositorySyncStatus = await (this.canConfigureServer ? this.getLastRepositorySync() : Promise.resolve(null));

        this.setState({
            totalPackages: (await getTotalPackages).TotalPackages,
            builtInResource,
            lastRepositorySyncStatus: lastRepositorySyncStatus && lastRepositorySyncStatus.status,
            lastRepositorySyncId: lastRepositorySyncStatus && lastRepositorySyncStatus.id,
            lastRepositorySyncCompletedTime: lastRepositorySyncStatus && lastRepositorySyncStatus.completedTime,
            initialPackages: await getinitialPackages,
            loaded: true
        });
    }

    async getLastRepositorySync() {
        const tasks = await repository.Tasks.list({ name: TaskName.SynchronizeBuiltInPackageRepositoryIndex, take: 1 });

        if (tasks.Items.length === 0) {
            return { status: "Never synced" };
        }

        const task = tasks.Items[0];
        switch (task.State) {
            case TaskState.Success:
                return { id: task.Id, status: "Succeeded", completedTime: task.CompletedTime };
            case TaskState.Executing:
                return { id: task.Id, status: "Running", completedTime: task.CompletedTime };
            default:
                return { id: task.Id, status: "Failed", completedTime: task.CompletedTime };
        }
    }

    async setIsBuiltInRepoSyncEnabled(value: boolean) {
        return this.doBusyTask(async () => {
            const newResource = {...this.state.builtInResource};
            newResource.IsBuiltInRepoSyncEnabled = value;
            await repository.Feeds.modify(newResource);
            const updated = await repository.Feeds.getBuiltIn();
            this.setState({builtInResource: updated});
        });
    }

    async onReindexClick() {
        return this.doBusyTask(async () => {
            const task: TaskResource<{}> = {
                Id: null,
                Name: TaskName.SynchronizeBuiltInPackageRepositoryIndex,
                Description: "Re-index built-in package repository",
                Arguments: {},
                State: TaskState.Queued,
                Links: null,
                SpaceId: client.spaceId
            } as any as TaskResource<{}>;
            const newTask = await repository.Tasks.create(task);

            this.setState({
                redirectTo: routeLinks.task(newTask).root
            });
        });
    }

    async afterPackageUpload(packageId: string) {
        this.setState({
            redirectTo: routeLinks.library.builtInRepository.package(packageId)
        });
    }

    async getInitialPackages(filter: string, take: number) {
        return await repository.Packages.list({ filter, latest: true, take });
    }

    render() {
        if (this.state && this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={true} />;
        }

        const loaded = this.state.loaded;

        const PushPackagesExampleDialog = () => {
            return <NoActionDialog title="How to Push Packages" open={this.state.showPushExample}
                onCloseClick={() => this.setState({ showPushExample: false })}>
                <Section>
                    Use the <code>Upload package</code> button to add a package to the library. Alternatively
                    use a command line to push packages using a NuGet client, Curl or Powershell.
                </Section>
                <Section sectionHeader="NuGet client">
                    <pre>
                        NuGet.exe push <em>YourApp.nupkg</em> -ApiKey <em>&lt;Your API Key&gt;</em> -Source
                        <strong> {getPackageEndpoint()}</strong>
                    </pre>
                </Section>
                <Section sectionHeader="PowerShell">
                    <pre>
                        $wc = new-object System.Net.WebClient <br />
                        $wc.UploadFile("<strong>{getRawPackageEndpoint()}</strong>?apiKey=<em>&lt;Your
                        API Key&gt;</em>", "<em>YourApp.1.0.0.zip</em>")
                    </pre>
                </Section>
                <Section sectionHeader="Curl">
                    <pre>
                        curl -X POST <strong>{getRawPackageEndpoint()}</strong> -H "X-Octopus-ApiKey: <em>&lt;
                        Your API Key&gt;</em>" -F "data=@Demo.1.0.0.zip"
                    </pre>
                </Section>
                <Section>
                    <ExternalLink href="OnboardingPackagingCommandLineLearnMore">Learn more about pushing packages to the built-in repository.</ExternalLink>
                </Section>
            </NoActionDialog>;
        };

        const sideBar = loaded && <div>
            {this.state.totalPackages === 0 && !this.state.showOnboardingSidebar
                ? <React.Fragment>
                    <br />
                    <ActionButton
                        type={ActionButtonType.Secondary}
                        label="Show Advanced Actions"
                        onClick={() => this.setState({ showOnboardingSidebar: true })}
                    />
                </React.Fragment>
                : <React.Fragment>
                    <BuiltInRepositoryRetention
                        config={this.state.builtInResource}
                        afterConfigurationUpdated={builtInResource => this.setState({ builtInResource })} />
                    <hr />
                    <BuiltInRepositoryIndexing
                        isBuiltInRepoSyncEnabled={this.state.builtInResource ? this.state.builtInResource.IsBuiltInRepoSyncEnabled : false}
                        lastSyncStatus={this.state.lastRepositorySyncStatus}
                        lastSyncId={this.state.lastRepositorySyncId}
                        lastSyncCompletedTime={this.state.lastRepositorySyncCompletedTime}
                        totalPackages={this.state.totalPackages}
                        onReindexClick={() => this.onReindexClick()}
                        setIsBuiltInRepoSyncEnabled={value => this.setIsBuiltInRepoSyncEnabled(value)}
                        busy={this.state.busy}
                    />
                </React.Fragment>}
        </div>;

        const uploadPackageButton = loaded &&
            <PermissionCheck permission={Permission.BuiltInFeedPush} project="*">
                <OpenDialogButton type={ActionButtonType.Primary} label="Upload package">
                    <PackageUpload afterPackageUpload={id => this.afterPackageUpload(id)} />
                </OpenDialogButton>
            </PermissionCheck>;

        return <LibraryLayout {...this.props}>
            <PaperLayout title="Built-in Package Repository"
                sectionControl={uploadPackageButton}
                busy={this.state.busy}
                errors={this.state.errors}>
                {loaded && <PushPackagesExampleDialog />}
                {loaded && <SidebarLayout sideBar={sideBar}>
                    <React.Fragment>
                        {loaded && this.state.totalPackages > 0 && <React.Fragment>
                            <SectionNote>
                                {OnboardingTextForPackaging}
                                &nbsp;<a style={{ cursor: "pointer" }} onClick={() => this.setState({ showPushExample: true })}>Show examples of how to push packages</a>
                                &nbsp;or&nbsp;&nbsp;
                                <a style={{ cursor: "pointer" }} onClick={() => this.setState({ showPackagingExample: !this.state.showPackagingExample })}>{this.state.showPackagingExample ? "Hide" : "Show"} packaging instructions</a>.
                            </SectionNote>
                            {this.state.showPackagingExample && <CommonPackagingSelector title={OnboardingTitleForPackaging} heading={OnboardingHeadingForPackaging} />}
                        </React.Fragment>}
                        {loaded && this.state.totalPackages === 0 &&
                            <Onboarding showExample={() => this.setState({ showPushExample: true })} />}
                        {loaded && this.state.totalPackages > 0 && <React.Fragment>
                            <Section>
                                <Callout type={CalloutType.Information}>
                                    Octopus does not allow NuGet clients to connect to this feed to retrieve packages; the feed only supports deployable packages, and doesn't allow NuGet packages to be consumed from Visual Studio and other tools.
                                </Callout>
                            </Section>
                            <LatestPackagesList
                                match={this.props.match}
                                initialPackages={this.state.initialPackages}
                            />
                        </React.Fragment>}
                    </React.Fragment>
                </SidebarLayout>}
            </PaperLayout>
        </LibraryLayout>;
    }
}

export default BuiltInRepositoryOverview;