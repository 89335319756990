exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_valueCell__3FfYD {\n  height: 100%;\n}\n.style_deletedText__30Ptv {\n  color: #9e9e9e !important;\n  text-decoration: line-through;\n}\n.style_deletedSensitive__27jFe {\n  color: #9e9e9e !important;\n}\n.style_valueCellContentReadonly__1kEyQ {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.style_promptedVariablePositionContainer__NYU26 {\n  padding-right: 1rem;\n}\n.style_promptedVariableIconSizeContainer__3nite {\n  height: 1.5rem;\n  width: 1.5rem;\n}\n.style_promptedVariableIcon__PeTZd {\n  opacity: 0.5;\n  color: #9e9e9e;\n}\n.style_value__P2rou {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.style_iconContainer__1Wckg {\n  height: inherit;\n  display: flex;\n  align-items: center;\n  padding-right: 0.5rem;\n}\n.style_certificate__2BiNP,\n.style_account__1M_wN {\n  height: inherit;\n  display: flex;\n  flex-direction: row;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n.style_certificate__2BiNP:focus,\n.style_account__1M_wN:focus {\n  outline: none;\n}\n.style_certificateLink__3v-cB,\n.style_accountLink__2DX34 {\n  height: inherit;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  color: #0A67B1;\n}\n", ""]);

// exports
exports.locals = {
	"valueCell": "style_valueCell__3FfYD",
	"deletedText": "style_deletedText__30Ptv",
	"deletedSensitive": "style_deletedSensitive__27jFe",
	"valueCellContentReadonly": "style_valueCellContentReadonly__1kEyQ",
	"promptedVariablePositionContainer": "style_promptedVariablePositionContainer__NYU26",
	"promptedVariableIconSizeContainer": "style_promptedVariableIconSizeContainer__3nite",
	"promptedVariableIcon": "style_promptedVariableIcon__PeTZd",
	"value": "style_value__P2rou",
	"iconContainer": "style_iconContainer__1Wckg",
	"certificate": "style_certificate__2BiNP",
	"account": "style_account__1M_wN",
	"certificateLink": "style_certificateLink__3v-cB",
	"accountLink": "style_accountLink__2DX34"
};