exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_label__n7Byj {\n  color: #9e9e9e;\n  font-size: 0.8125rem;\n  font-weight: 400;\n  margin-bottom: 0.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"label": "style_label__n7Byj"
};