import * as React from "react";
import Dialog from "material-ui/Dialog";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import EventListener from "react-event-listener";
import Callout, { CalloutType } from "../Callout";

interface Props {
    message: React.ReactNode;
    callback(navigate: boolean): void;
    onSaveClick(): Promise<boolean>;
}

interface State {
    show: boolean;
    success: boolean;
}

class ConfirmNavigate extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            show: true,
            success: true
        };
    }

    handleTouchTap = (navigate: boolean) => {
        this.setState({
            show: false
        });
        this.props.callback(navigate);
    }

    handleKeyDown = (event: any) => {
        if (event.keyCode === 27 /*esc*/) {
            this.handleTouchTap(false);
        }
    }

    render() {
        const actions = [
            <ActionButton
                style={{ marginRight: "5px" }}
                key="SaveChanges"
                label="Save Changes"
                onClick={this.onSaveClick}
            />,
            <ActionButton
                style={{ marginRight: "5px" }}
                key="discard"
                label="Discard Changes"
                onClick={() => this.handleTouchTap(true)}
            />,
            <ActionButton
                key="cancel"
                label="Cancel"
                type={ActionButtonType.Primary}
                keyboardFocused={true}
                onClick={() => this.handleTouchTap(false)}
            />
        ];
        return <Dialog
            title="Unsaved Changes"
            actions={actions}
            modal={true}
            open={this.state.show}
            autoScrollBodyContent={true}>
            <EventListener target="document" onKeyDown={this.handleKeyDown} />
            {this.props.message}
            {!this.state.success && <Callout title="Errors found" type={CalloutType.Danger}>
                Please cancel this dialog to review the errors (or discard your changes).
            </Callout>}
        </Dialog>;
    }

    private onSaveClick = async () => {
        const success: boolean = await this.props.onSaveClick();
        this.setState({success});
        if (!!success) {
            this.handleTouchTap(true);
        }
    }
}

export default ConfirmNavigate;