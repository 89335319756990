exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .text_hide-sm__VeULM {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .text_visible-sm__3dPuQ {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .text_hide-md__13VSa {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .text_visible-md__2rBoy {\n    display: none !important;\n  }\n}\nh1 {\n  font-size: 1.625rem;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  h1 {\n    font-size: 1.4375rem;\n  }\n}\nh2 {\n  font-size: 1.25rem;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  h2 {\n    font-size: 1.0625rem;\n  }\n}\nh3 {\n  font-size: 1.125rem;\n  font-weight: 400;\n}\n@media (max-width: 37.4rem) {\n  h3 {\n    font-size: 1.0625rem;\n  }\n}\nh4 {\n  font-size: 1rem;\n  font-weight: 500;\n  margin: 1rem 0 0.5rem 0;\n}\nh5 {\n  font-size: 0.8125rem;\n  font-weight: 400;\n}\nhr {\n  display: block;\n  height: 0.0625rem;\n  border: 0;\n  border-top: solid 0.0625rem #cccccc;\n  margin: 1em 0;\n  padding: 0;\n}\na {\n  color: #2f93e0;\n}\na:hover {\n  color: #0A67B1;\n}\np {\n  margin: 1rem 0;\n  color: #333333;\n}\ninput:-webkit-autofill {\n  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;\n}\ninput::-ms-clear {\n  width: 0;\n  height: 0;\n}\nsmall {\n  font-size: 0.875rem;\n  line-height: 1.1875rem;\n}\n.text_secondary__WVAh0 {\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "text_hide-sm__VeULM",
	"hideSm": "text_hide-sm__VeULM",
	"visible-sm": "text_visible-sm__3dPuQ",
	"visibleSm": "text_visible-sm__3dPuQ",
	"hide-md": "text_hide-md__13VSa",
	"hideMd": "text_hide-md__13VSa",
	"visible-md": "text_visible-md__2rBoy",
	"visibleMd": "text_visible-md__2rBoy",
	"secondary": "text_secondary__WVAh0"
};