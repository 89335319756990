import * as React from "react";
import { NamedResource, MachineFilterResource, EnvironmentResource, EventCategoryResource, EventGroupResource } from "client/resources";
import {RoleChip, EnvironmentChip, environmentChipList, EventCategoryChip} from "components/Chips";

export default class TriggerDescription {
    private readonly empty: string[] = [];

    constructor(
        private readonly filter: MachineFilterResource,
        private readonly environments: EnvironmentResource[],
        private readonly categories: EventCategoryResource[],
        private readonly groups: EventGroupResource[]) {
    }

    buildDescription(): JSX.Element {
        const environments = this.filter.EnvironmentIds.length === 0 ? []
             : environmentChipList(this.environments, this.filter.EnvironmentIds);
        const selectedEventGroups = this.filter.EventGroups || [];
        const selectedEventGroupCategories = this.getEventCategoriesForEventGroups(selectedEventGroups);
        const selectedEventCategories = this.filter.EventCategories || [];
        const allSelectedEventCategories = selectedEventGroupCategories
            .concat(selectedEventCategories)
            .filter((elem, index, self) => {
                return index === self.indexOf(elem);
            });
        const events = allSelectedEventCategories.length === 0 ? [] : allSelectedEventCategories.map(id => {
            const item = this.categories.find(e => e.Id === id);
            if (!item) { // MachineDeleted does not exit in the lookup so we need to handle that edge case
                return null;
            }
            return <EventCategoryChip key={item.Id} eventCategory={item} />;
        });
        const roles = this.filter.Roles.map(role => <RoleChip key={role} role={role} />);

        return (
            <span>
                Automatically deploy when {this.buildTagList(events)}
                {environments.length > 0 && <span> in the environments {this.buildTagList(environments)} </span>}
                {environments.length === 0 && <span> in any environment </span>}
                <span> for </span>
                {roles.length > 0 && <span> the roles {this.buildTagList(roles)} </span>}
                {roles.length === 0 && <span> any role</span>}
            </span>
        );
    }

    buildTagList(elements: Array<string | JSX.Element>): JSX.Element {
        return <span>
            {elements}
        </span>;
    }

    private getEventCategoriesForEventGroups(selectedEventGroups: string[]) {
        if (selectedEventGroups.length === 0) {
            return [];
        }

        const selectedGroups = this.groups.filter(eventGroup => {
            return selectedEventGroups.includes(eventGroup.Id);
        });

        return this.empty.concat(...selectedGroups.map(selectedGroup => {
            return selectedGroup.EventCategories;
        }));
    }
}