export default class ParseHelper {

    // Numbers
    public static safeParseInt(value: string, defaultValue = 0): number {
        // so we don't display NaN
        const result = parseInt(value, 10);
        return isNaN(result) ? defaultValue : result;
    }

    // Comma Seperated Values
    static encodeCSV(val: string[]): string {
        return val.join(",");
    }

    static parseCSV(val: string): string[] {
        if (!val || val === "") {
            return [];
        }
        return val.split(",");
    }
}

function arrayValueFromQueryString(param: string | string[] | undefined, canBeCommaSeparated: boolean = false): string[] {
    //URIjs in its infinite wisdom returns array if there are more than 1 parameter instance, or string if only one
    if (Array.isArray(param)) {
        return param;
    }

    if (typeof param === "string") {
        return canBeCommaSeparated ?
            param.split(",") :
            [param];
    }

    return [];
}

export {arrayValueFromQueryString};