import * as React from "react";
import { FeaturePlugin, ActionEditProps } from "components/Actions/pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ExpandableFormSection, Summary, StringCheckbox, SummaryNode, Note } from "components/form";
import { VariableLookupText } from "components/form/VariableLookupText";

interface FeatureProperties {
    "Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": string;
    "Octopus.Action.Aws.CloudFormation.ChangeSet.Name": string;
    "Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": string;
}

const getSummary = (getProps: (props: keyof FeatureProperties) => string): SummaryNode => {
    const generatedNamed = getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName") === "True";
    return generatedNamed ?
        Summary.summary("Change sets will have automatically generated names") :
        Summary.summary(<span>Change set named {getProps("Octopus.Action.Aws.CloudFormation.ChangeSet.Name")}</span>);
};

class CloudFormationChangesetFeature extends BaseComponent<ActionEditProps<FeatureProperties>, never> {
    componentDidMount() {
        if (!this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"]) {
            this.props.setProperties({"Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName" : "True"});
        }

        if (!this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Defer"]) {
            this.props.setProperties({"Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": "False"});
        }
    }

    render() {
        return <ExpandableFormSection
            summary={getSummary((prop: keyof FeatureProperties) => this.props.properties[prop])}
            title="Change Sets"
            errorKey="Changesets"
            isExpandedByDefault={this.props.expandedByDefault}
        >

        <StringCheckbox
            label="Automatically Generate Change Set Name"
            value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"]}
            onChange={(val: string) => this.props.setProperties({"Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName": val})}
        />

        { this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.GenerateName"] !== "True" && <React.Fragment>
            <VariableLookupText
                localNames={this.props.localNames}
                projectId={this.props.projectId}
                label={"Changeset Name"}
                error={this.props.getFieldError("Octopus.Action.Aws.CloudFormation.ChangeSet.Name")}
                value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Name"]}
                onChange={(val: string) => this.props.setProperties({"Octopus.Action.Aws.CloudFormation.ChangeSet.Name": val})}
            />
            <Note>Please note that change sets must be unique for a given stack.</Note>
            </React.Fragment>
        }

        <StringCheckbox
            label="Defer Change Set Execution"
            value={this.props.properties["Octopus.Action.Aws.CloudFormation.ChangeSet.Defer"]}
            onChange={(val: string) => this.props.setProperties({"Octopus.Action.Aws.CloudFormation.ChangeSet.Defer": val})}
        />

        </ExpandableFormSection>;
    }
}

export default (): FeaturePlugin => ({
    featureName: "Octopus.Features.CloudFormation.ChangeSet.Feature",
    title: "CloudFormation Change Sets",
    description: "Use Cloud Formation Change sets when updating or creating stacks.",
    edit: CloudFormationChangesetFeature,
    disable: (properties) => {
        Object.keys(properties).filter((name) => {
            return name.indexOf("Octopus.Action.Aws.CloudFormation.ChangeSet.") === 0;
        }).forEach((name) => {
            delete properties[name];
        });
    },
    priority: 1
});