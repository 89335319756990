exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_licenceBox__1pUuD {\n  margin-top: 1rem;\n}\n.style_list__3sWc3 {\n  list-style-position: inside;\n  list-style-type: disc;\n}\n", ""]);

// exports
exports.locals = {
	"licenceBox": "style_licenceBox__1pUuD",
	"list": "style_list__3sWc3"
};