import * as React from "react";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import {FeedType, NugetFeedResource} from "client/resources/feedResource";
import {Summary} from "components/form";
import {Checkbox} from "components/form";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import RetryAttemptsFormSection from "../RetryAttemptsFormSection";
import feedTypeRegistry, {FeedEditProps} from "./feedTypeRegistry";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";

class NuGetFeed extends React.Component<FeedEditProps<NugetFeedResource>> {
    render() {
        return <React.Fragment>
                <RetryAttemptsFormSection backoffSeconds={this.props.feed.DownloadRetryBackoffSeconds}
                                          onBackoffSecondsChanged={(seconds) => this.props.onChange({...this.props.feed, DownloadRetryBackoffSeconds: seconds})}
                                          attemptCount={this.props.feed.DownloadAttempts}
                                          onAttemptCountChanged={(count) => this.props.onChange({...this.props.feed, DownloadAttempts: count})}/>
                <BasicFeedCredentialsFormSection model={{username: this.props.feed.Username, password: this.props.feed.Password}}
                                                 onChanged={(model) => this.props.onChange({...this.props.feed, Password: model.password, Username: model.username})}/>
                <ExpandableFormSection
                    summary={Summary.summary(this.props.feed.EnhancedMode ? "Use the extended API" : "Don't use the extended API")}
                    title={"Extended API"}
                    errorKey={"ExtendedAPI"}>
                    <Checkbox
                        value={this.props.feed.EnhancedMode}
                        onChange={(x) => this.props.onChange({...this.props.feed, EnhancedMode: x})}
                        label="Make use of the extended API"
                        note={<span>This will improve performance of the NuGet feed but may not be supported by some older
                                        feeds. Disable if Create Release does not return the latest version for a package.</span>}
                    />
                </ExpandableFormSection>
            </React.Fragment>;
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.Nuget,
    edit: NuGetFeed,
    text: "NuGet Feed",
    uriNotes:  "The feed url can be a url or a folder path."
});