exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__3fN91 {\n  display: flex;\n  flex-direction: row;\n  text-align: center;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__3fN91"
};