import * as React from "react";
import {Callout, CalloutType} from "../../../../components/Callout/Callout";
import routeLinks from "../../../../routeLinks";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import { ProjectResource, ReleaseResource, ChannelResource, Permission } from "client/resources";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";

interface MissingProcessStepsMessageProps {
    project: ProjectResource;
}

const MissingProcessStepsMessage: React.StatelessComponent<MissingProcessStepsMessageProps> = props => {
    return <Callout title="Note" type={CalloutType.Information}>
            <div>The project's deployment process doesn't have any steps.
                <PermissionCheck permission={Permission.ProcessEdit} project={props.project.Id} tenant="*">
                    <span>Add steps under the <strong><InternalLink
                        to={routeLinks.project(props.project).process.root}>Process</InternalLink></strong> menu for this project.</span>
                </PermissionCheck>
            </div>
        </Callout>;
};

export default MissingProcessStepsMessage;