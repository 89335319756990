import * as React from "react";
import * as cn from "classnames";

const styles = require("./DialogContent.less");

interface DialogContentProps {
    className?: string;
}

const ExtraContent: React.SFC<DialogContentProps> = ({className, children}) => {
    return <div className={cn(styles.padded, className)}>
        {children}
    </div>;
};

const DialogContent: React.SFC<DialogContentProps> = ({className, children}) => {
    return <div className={cn(styles.content, styles.block, styles.padded, className)}>
        {children}
    </div>;
};

const FlexDialogContent: React.SFC<DialogContentProps> = ({className, children}) => {
    return <div className={cn(styles.content, styles.flex, className)}>
        {children}
    </div>;
};

export default DialogContent;

export { DialogContent, FlexDialogContent, ExtraContent };