import GlobalState from "../../../globalState";
import { repository } from "clientInstance";
import { ActionTemplateResource } from "client/resources";

const ActionTemplateActions = {
    ACTIONTEMPLATE_FETCHED: "ACTIONTEMPLATE_FETCHED",
    ACTIONTEMPLATE_NEWTEMPLATE_SELECTED: "ACTIONTEMPLATE_NEWTEMPLATE_SELECTED",
};

export interface LibraryAreaState {
    currentActionTemplate: ActionTemplateState;
}

export interface ActionTemplateState {
    id: string;
    name: string;
    logo: string;
    pendingUpdates: number;
}

export function actionTemplateFetch(actionTemplate: ActionTemplateResource) {
    return async (dispatch: any, getState: () => GlobalState) => {
        const usages = await repository.ActionTemplates.getUsage(actionTemplate);
        const pendingUpdates = usages.filter(u => u.Version.toString() !== actionTemplate.Version.toString()).length;
        dispatch(actionTemplateFetched({
            id: actionTemplate.Id,
            name: actionTemplate.Name,
            logo: actionTemplate.Links["Logo"],
            pendingUpdates
        }));
    };
}

export function actionTemplateFetched(state: ActionTemplateState) {
    return { type: ActionTemplateActions.ACTIONTEMPLATE_FETCHED, state};
}

export function newActionTemplateSelected() {
    return { type: ActionTemplateActions.ACTIONTEMPLATE_NEWTEMPLATE_SELECTED};
}

const libraryArea = (state: LibraryAreaState = {currentActionTemplate: null}, action: any): LibraryAreaState => {
    switch (action.type) {
        case ActionTemplateActions.ACTIONTEMPLATE_FETCHED:
            const actionTemplate = action.state as ActionTemplateState;
            return {
                currentActionTemplate: {
                    id: actionTemplate.id,
                    name: actionTemplate.name,
                    logo: actionTemplate.logo,
                    pendingUpdates: actionTemplate.pendingUpdates
                }
            };
        case ActionTemplateActions.ACTIONTEMPLATE_NEWTEMPLATE_SELECTED:
            return { currentActionTemplate: null };
        default:
            return state;
    }
};

export default libraryArea;