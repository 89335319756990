export default (root: string) => {
    const users = `${root}/users`;
    const me = `${users}/me`;

    return {
        me,
        logins: `${me}/logins`,
        details: `${me}/details`,
        apiKeys: `${me}/apiKeys`,
        activity: `${me}/activity`,
        register: (inviteCode: string) => `${users}/register/${inviteCode}`,
        signIn: `${users}/sign-in`,
        signOut: `${users}/sign-out`
    };
};
