import * as React from "react";
import * as FormResource from "client/resources/form";
const styles = require("./style.less");
import InterruptionResource from "client/resources/interruptionResource";
import { Callout, CalloutType } from "components/Callout/Callout";
import { ActionButton, ActionButtonType } from "components/Button";
import { repository } from "clientInstance";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import { isEqual } from "lodash";
import FormResourceInput from "components/FormResourceInput/FormResourceInput";
import { RouteComponentProps, withRouter } from "react-router";
import { goToLog } from "../TaskLog/TaskLogUtil";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import ActionList from "components/ActionList/ActionList";
import { Note } from "components/form";

interface InterruptionComponentProps {
    interruption: InterruptionResource;
    takeResponsibility(): Promise<void>;
    submitInterruption(values: { [name: string]: any }): void;
}

type InterruptionProps = InterruptionComponentProps & RouteComponentProps<any>;

interface InterruptionState {
    responsibleUser: string;
    isOpen: boolean;
    values: { [name: string]: any };
    isSubmitting: boolean;
}

class Interruption extends React.Component<InterruptionProps, InterruptionState> {
    constructor(props: InterruptionProps) {
        super(props);

        this.state = {
            isOpen: false,
            isSubmitting: false,
            values: {},
            responsibleUser: null
        };
    }

    async componentWillReceiveProps(newProps: InterruptionComponentProps) {
        if (this.props.interruption.ResponsibleUserId !== newProps.interruption.ResponsibleUserId &&
            newProps.interruption.HasResponsibility) {
            this.setState({ isOpen: true });
        }

        if (!isEqual(this.props.interruption, newProps.interruption)) {
            this.setState({ values: newProps.interruption.Form.Values });
        }

        if (!!newProps.interruption.ResponsibleUserId &&
            !this.props.interruption.HasResponsibility &&
            this.props.interruption.ResponsibleUserId !== newProps.interruption.ResponsibleUserId) {
            const user = await repository.Users.get(this.props.interruption.ResponsibleUserId);
            this.setState({ responsibleUser: user.DisplayName });
        }
    }

    async componentDidMount() {
        if (this.props.interruption.HasResponsibility) {
            this.setState({ isOpen: true, values: this.props.interruption.Form.Values });
        } else if (this.props.interruption.ResponsibleUserId) {
            const user = await repository.Users.get(this.props.interruption.ResponsibleUserId);
            this.setState({ responsibleUser: user.DisplayName });
        }
    }

    updateFormElementValue = (element: FormResource.FormElement) => {
        return (value: string) => {
            const newValues = { ...this.state.values, [element.Name]: value };
            this.setState({ values: newValues });
        };
    }

    submitInterrupt = (element: FormResource.FormElement) => {
        return async (value: string) => {
            const values = { ...this.state.values, [element.Name]: value };
            const elementsWithValues = [FormResource.ControlType.Checkbox, FormResource.ControlType.TextArea, FormResource.ControlType.VariableValue];
            this.props.interruption.Form.Elements.forEach(otherElements => {
                if (elementsWithValues.indexOf(otherElements.Control.Type) !== -1) {
                    values[otherElements.Name] = (values[otherElements.Name] || "");
                }
            });

            this.setState({ isSubmitting: true });
            try {
                await this.props.submitInterruption(values);
            } finally {
                this.setState({ isSubmitting: false });
            }
        };
    }

    renderAssignmentButton() {
        const interruption = this.props.interruption;
        if (!interruption.CanTakeResponsibility || interruption.HasResponsibility) {
            return null;
        }

        if (interruption.ResponsibleUserId) {
            return <OpenDialogButton label="Assign to me"
                type={ActionButtonType.Primary}
                disabled={this.state.isSubmitting}>
                <SaveDialogLayout title="Confirm Re-assignment"
                    saveButtonLabel="Yes"
                    cancelButtonLabel="No"
                    busy={this.state.isSubmitting}
                    errors={null}
                    onSaveClick={async () => {
                        this.setState({ isSubmitting: true });
                        try {
                            await this.props.takeResponsibility();
                        } finally {
                            this.setState({ isSubmitting: false });
                        }
                        return true;
                    }}>
                    <div>Someone else is already assigned to this interruption. Are you sure you wish to assign it to yourself?</div>
                </SaveDialogLayout>
            </OpenDialogButton>;
        }

        return <ActionButton label="Assign to me"
            type={ActionButtonType.Primary}
            onClick={() => this.props.takeResponsibility()} />;
    }

    render() {
        const interruption = this.props.interruption;
        const isAssignedToMe = interruption.HasResponsibility;
        const isSubmitting = this.state.isSubmitting;

        const who = interruption.ResponsibleUserId ?
            (isAssignedToMe ? <strong>you</strong> : <span>{this.state.responsibleUser}</span>) :
            <em>no one</em>;

        const styling = (controlType: FormResource.ControlType) => {
            if (controlType === FormResource.ControlType.TextArea) {
                return styles.notes;
            } else if (controlType === FormResource.ControlType.SubmitButtonGroup) {
                return styles.actions;
            }
            return null;
        };

        return <Callout title={interruption.Title} type={CalloutType.Warning}>
            {!interruption.IsLinkedToOtherInterruption && <div>
                {!interruption.ResponsibleUserId &&
                <div>This task is waiting for manual intervention and <strong>must be assigned</strong> before proceeding.</div>}
                {interruption.ResponsibleUserId &&
                <div>This task is waiting for manual intervention.</div>}
            </div>}
            <div className={styles.headerLinks}>
                <p>Assigned to: <strong>{who}</strong></p>
                <div className={styles.assignmentAction}>{this.renderAssignmentButton()}</div>
            </div>
            <div className={styles.headerLinks}>
                <ActionList actions={[
                    <ActionButton label={this.state.isOpen ? "Hide Details" : "Show Details"}
                        type={ActionButtonType.Ternary}
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })} />,
                    interruption.CorrelationId &&
                        <ActionButton label="View Log"
                            type={ActionButtonType.Ternary}
                            onClick={() => goToLog(this.props, interruption.CorrelationId)} />
                ]} />
            </div>
            {(this.state.isOpen || interruption.IsLinkedToOtherInterruption) && <div>
                {interruption.Form.Elements.map((element, idx) =>
                    <div className={styling(element.Control.Type)} key={idx}>
                        <FormResourceInput
                            element={element}
                            disabled={!isAssignedToMe || isSubmitting}
                            onButtonSelected={this.submitInterrupt(element)}
                            onChanged={this.updateFormElementValue(element)}
                            value={this.state.values[element.Name]} />
                    </div>)}
            </div>}

        </Callout>;
    }
}

export default withRouter<InterruptionProps>(Interruption);