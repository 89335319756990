import * as React from "react";
import {ExpandableFormSection, Summary, FormSectionHeading} from "components/form";
import {AccountType} from "client/resources/accountResource";
import AccountEditBase, {AccountEditModel} from "./AccountEditBase";
import {SensitiveValue} from "client/resources/propertyValueResource";
import Sensitive, {ObfuscatedPlaceholder} from "components/form/Sensitive/Sensitive";
import AccountUsage from "areas/infrastructure/components/AccountEdit/AccountUsage";
import {TokenAccountResource} from "../../../../client/resources";

interface TokenModel extends AccountEditModel {
    token: SensitiveValue;
}

class TokenAccountEdit extends AccountEditBase<TokenAccountResource, TokenModel> {

    getPartialModel(account?: TokenAccountResource): Partial<TokenModel> {
        if (!account) {
            return {
                token: null,
            };
        }

        return {
            token: account.Token
        };
    }

    getPartialResource(): Partial<TokenAccountResource> & {AccountType: AccountType} {
        return {
            AccountType: AccountType.Token,
            Token: this.state.model.token,
        };
    }

    tokenSummary() {
        return this.state.model.token && this.state.model.token.HasValue ?
            Summary.summary(ObfuscatedPlaceholder) :
            Summary.placeholder("No token yet provided");
    }

    customSecondaryAction(): JSX.Element {
        return;
    }

    customExpandableFormSections(): JSX.Element[] {
        const baseElements: JSX.Element[] = [];

        if (this.state.account) {
            baseElements.push(<AccountUsage
                doBusyTask={this.doBusyTask}
                busy={this.state.busy}
                account={this.state.account}
                accountType={this.state.account.AccountType} key={"accountUsage"}/>);
        }

        baseElements.push(<FormSectionHeading title="Credentials" key={"header"}/>,
        <ExpandableFormSection
            key="token"
            errorKey="token"
            title="Token"
            summary={this.tokenSummary()}
            help="The password to use to when authenticating against the remote host.">
            <Sensitive
                value={this.state.model.token}
                onChange={token => this.setModelState({token})}
                label="Token"/>
        </ExpandableFormSection>);

        return baseElements;
    }
}

export default TokenAccountEdit;