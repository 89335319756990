import * as React from "react";
const NumericInput = require("react-numeric-input");
import FormFieldProps from "../FormFieldProps";
import BaseComponent from "components/BaseComponent";
import TimeSpanHelper from "utils/TimeSpanHelper";
const styles = require("./style.less");
import {baseSizeInPx} from "fontWeights";

interface TimeSpanSelectorProps extends FormFieldProps<string> {
    granularity?: string;
    error?: string;
}

class TimeSpanSelector extends BaseComponent<TimeSpanSelectorProps, {}> {
    public static DayGranularity: string = "dd";
    public static HourGranularity: string = "hh";
    public static MinuteGranularity: string = "mm";
    public static SecondGranularity: string = "ss";

    public static DayHourMinuteGranularity: string = "dd:hh:mm";
    public static HourMinuteSecondGranularity: string = "hh:mm:ss";

    getGranularityOrDefault() {
        return this.props.granularity || TimeSpanSelector.DayHourMinuteGranularity;
    }

    render() {
        const {
            value,
        } = this.props;
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(value);
        const inputStyle = {
            input: {
                border: "0",
                fontSize: baseSizeInPx,
                height: "100%"
            }
        };

        const granularity = this.getGranularityOrDefault();

        return <>
            <div className={styles.container}>
                {granularity.includes(TimeSpanSelector.DayGranularity) &&
                [<NumericInput
                    onChange={this.handleDayChange}
                    value={timeSpan.Days}
                    min={0}
                    max={99}
                    step={1}
                    size={1}
                    mobile={true}
                    key="days"
                    style={inputStyle}
                />,
                <span className={styles.separator} key="dayslabel">days</span>]}
                {granularity.includes(TimeSpanSelector.HourGranularity) &&
                [<NumericInput
                    onChange={this.handleHourChange}
                    value={timeSpan.Hours}
                    min={0}
                    max={23}
                    step={1}
                    key="hours"
                    size={1}
                    style={inputStyle}
                    mobile={true}
                />,
                <span className={styles.separator} key="hourslabel">hours</span>]}
                {granularity.includes(TimeSpanSelector.MinuteGranularity) &&
                [<NumericInput
                    onChange={this.handleMinuteChange}
                    value={timeSpan.Minutes}
                    min={0}
                    max={59}
                    step={1}
                    size={1}
                    key="minutes"
                    style={inputStyle}
                    mobile={true}
                />,
                <span className={styles.separator} key="minuteslabel">minutes</span>]}
                {granularity.includes(TimeSpanSelector.SecondGranularity) &&
                [<NumericInput
                    onChange={this.handleSecondChange}
                    value={timeSpan.Seconds}
                    min={0}
                    max={59}
                    step={1}
                    key="seconds"
                    size={1}
                    style={inputStyle}
                    mobile={true}
                />,
                <span className={styles.separator} key="secondslabel">
                    seconds
                </span>]}
            </div>
            {this.props.error && <div className={styles.error}>
                {this.props.error}
            </div>}
        </>;
    }

    private handleDayChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan.Days = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.props.onChange(returnVal);
    }

    private handleHourChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan.Hours = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.props.onChange(returnVal);
    }

    private handleMinuteChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan.Minutes = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.props.onChange(returnVal);
    }

    private handleSecondChange = (value: number) => {
        const timeSpan = TimeSpanHelper.getTimeSpanFromText(this.props.value);
        timeSpan.Seconds = value;
        const returnVal = TimeSpanHelper.getDotNetFormattedTimeSpanTextFromTimeSpan(timeSpan);
        this.props.onChange(returnVal);
    }
}

export default TimeSpanSelector;
