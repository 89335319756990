import * as React from "react";
const styles = require("./style.less");

interface ResizeColumnHandleProps {
    isActive: boolean;
}

const ResizeColumnHandle: React.SFC<ResizeColumnHandleProps> = (props) => {
    return <div className={styles.dragHandlePositioningContainer}>
        <div className={styles.dragHandleDraggableArea}>
            <div className={`${styles.dragHandleIndicator} ${props.isActive ? styles.dragHandleIndicatorDark : styles.dragHandleIndicatorLight}`}/>
        </div>
    </div>;
};
export default ResizeColumnHandle;