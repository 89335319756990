import * as React from "react";
import ToolTipMessages from "components/ToolTipMessages/ToolTipMessages";
import VariableCell from "areas/variables/VariableCell/VariableCell";
const styles = require("./style.less");

interface VariableMessageCellProps {
    warningMessages?: ReadonlyArray<string>;
}

// This cell may appear in the same column as the the VariableNameCell, so the warnings for both of these cells should be aligned
const VariableMessageCell: React.SFC<VariableMessageCellProps> = (props: VariableMessageCellProps) => {
    return <VariableCell className={styles.container}>
        <div className={styles.spacer} />
        <ToolTipMessages warningMessages={props.warningMessages} />
    </VariableCell>;
};

export default VariableMessageCell;