import * as React from "react";
import {
    DailyTriggerScheduleResource,
    TriggerScheduleIntervalResource,
    TriggerScheduleResource,
    ServerTimezoneResource,
    TriggerScheduleIntervalType,
    TriggerScheduleRunType } from "client/resources";
import ScheduleInterval from "./ScheduleInterval";
import ScheduleStart from "./ScheduleStart";
import { Moment } from "moment";
import * as moment from "moment";
import DateFormatter from "utils/DateFormatter/DateFormatter";

interface DailyScheduledTriggerEditorProps {
    schedule: DailyTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: DailyTriggerScheduleResource): void;
}

interface DailyScheduledTriggerEditorState {
    scheduleInterval: TriggerScheduleIntervalResource;
    scheduleStartTime: Moment;
    scheduleRunType: TriggerScheduleRunType;
    scheduleTimezone: string;
}

export class DailyScheduledTriggerEditor extends React.Component<DailyScheduledTriggerEditorProps, DailyScheduledTriggerEditorState> {
    constructor(props: DailyScheduledTriggerEditorProps) {
        super(props);
        this.state = this.initState(this.props.schedule);
    }

    componentWillReceiveProps(newProps: DailyScheduledTriggerEditorProps) {
        if (newProps.schedule.Interval !== this.props.schedule.Interval ||
            newProps.schedule.HourInterval !== this.props.schedule.HourInterval ||
            newProps.schedule.MinuteInterval !== this.props.schedule.MinuteInterval ||
            newProps.schedule.StartTime !== this.props.schedule.StartTime ||
            newProps.schedule.Timezone !== this.props.schedule.Timezone ||
            newProps.schedule.RunType !== this.props.schedule.RunType) {
            this.setState(this.initState(newProps.schedule));
        }
    }

    render() {
        return (
            <div>
                <ScheduleInterval
                    interval={this.state.scheduleInterval}
                    onChange={this.onScheduleIntervalChange} />
                <ScheduleStart
                    startTime={this.state.scheduleStartTime}
                    runType={this.state.scheduleRunType}
                    showRunContinuously={this.props.schedule.Interval !== TriggerScheduleIntervalType.OnceDaily}
                    timezones={this.props.timezones}
                    timezone={this.state.scheduleTimezone}
                    onStartTimeChanged={this.onScheduleStartChange}
                    onTimezoneChanged={this.onScheduleTimezoneChange}
                    onRunTypeChanged={this.onScheduleRunTypeChange} />
            </div>
        );
    }

    initState(value?: DailyTriggerScheduleResource) {
        const schedule = value || new DailyTriggerScheduleResource();

        return {
            scheduleInterval: {
                Interval: schedule.Interval,
                HourInterval: schedule.HourInterval,
                MinuteInterval: schedule.MinuteInterval
            },
            scheduleStartTime: moment(schedule.StartTime).utc(),
            scheduleRunType: schedule.RunType,
            scheduleTimezone: schedule.Timezone
        };
    }

    onScheduleIntervalChange = (interval: TriggerScheduleIntervalResource) => {
        this.setState({
            scheduleInterval: interval,
            scheduleStartTime: (interval.Interval === TriggerScheduleIntervalType.OnceDaily && !this.state.scheduleStartTime.isValid())
                ? moment(this.getDefaultStartDate()).utc()
                : this.state.scheduleStartTime,
        }, () => this.raiseChange());
    }

    onScheduleStartChange = (startTime: Moment) => {
        this.setState({
            scheduleStartTime: startTime
        }, () => this.raiseChange());
    }

    onScheduleRunTypeChange = (runType: TriggerScheduleRunType) => {
        this.setState({
            scheduleRunType: runType,
            scheduleStartTime: runType === TriggerScheduleRunType.Continuously ? null : moment(new Date()).utc()
        }, () => this.raiseChange());
    }

    onScheduleTimezoneChange = (timezoneId: string) => {
        this.setState({
            scheduleTimezone: timezoneId
        }, () => this.raiseChange());
    }

    getDefaultStartDate() {
        const currentDate = new Date();
        return new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9, 0, 0));
    }

    raiseChange() {
        this.props.onScheduleChange({
            ...this.props.schedule,
            Interval: this.state.scheduleInterval.Interval,
            HourInterval: this.state.scheduleInterval.HourInterval,
            MinuteInterval: this.state.scheduleInterval.MinuteInterval,
            StartTime: this.state.scheduleStartTime ? this.state.scheduleStartTime.toDate() : null,
            RunType: this.state.scheduleRunType,
            Timezone: this.state.scheduleTimezone
        });
    }
}