import * as React from "react";
import * as cn from "classnames";
import VariableNameCell from "../VariableNameCell";
import VariableValueCell from "../VariableValueCell";
import VariableScopeCell from "../VariableScopeCell";
import {ScopeValues} from "client/resources/variableSetResource";
import OverflowMenu from "components/Menu/OverflowMenu";
import {FocusableCellType} from "areas/variables/CellFocus/CellFocus";
import {CellAligner} from "components/ScrollTable/ScrollTable";
import VariableDescriptionIcon from "areas/variables/VariableDescriptionIcon/VariableDescriptionIcon";
const styles = require("./style.less");
import {VariableMessages, ValueMessages} from "areas/variables/VariableMessages/VariableMessages";
import {TagIndex} from "components/tenantTagsets";
import {CertificateIndex} from "components/certificates";
import VariableMessageCell from "areas/variables/VariableMessageCell/VariableMessageCell";
import {FocusField} from "areas/variables/EditVariableDialog/EditVariableDialog";
import {VariableModel} from "areas/variables/VariablesModel/VariablesModel";
import VariableCell from "areas/variables/VariableCell/VariableCell";
import ReadonlyText from "components/ReadonlyText/ReadonlyText";
import {VariableType} from "client/resources/variableResource";
import {SensitiveState} from "components/form/Sensitive/Sensitive";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import VariableNameAndDescriptionCell from "areas/variables/VariableNameAndDescriptionCell/VariableNameAndDescriptionCell";
import {secondaryText} from "colors";
import VariableStatusIcon from "areas/variables/VariableStatusIcon/VariableStatusIcon";
import {VariableStatus} from "areas/variables/VariableStatusIcon";
import {MeasureWidthOutOfFlow} from "components/Measure/MeasureOutOfFlow";
import {VariableValueModel} from "../VariablesModel";
const keycode = require("keycode");

export const SingleVariableRowHeight = 48;

export interface SingleVariableRowProps {
    variable: VariableModel;
    value: VariableValueModel;
    status: VariableStatus;
    sensitiveState: SensitiveState | undefined;
    existingValue: VariableValueModel | undefined;
    isVariableDeleted: boolean;
    availableScopes: ScopeValues;
    tagIndex: TagIndex;
    certificateIndex: CertificateIndex;
    isProjectScoped: boolean;
    variableMessages: VariableMessages;
    valueMessages: ValueMessages;
    showNameCell: boolean;
    cellAligner: CellAligner;
    doBusyTask: DoBusyTask;
    focus?: FocusableCellType;
    scopeCellWidth: number | undefined;
    onNameChanged: (variable: VariableModel, name: string) => void;
    onVariableChanged: (updatedValue: VariableValueModel) => void;
    onMergeClicked: (variable: VariableModel, value: VariableValueModel) => void;
    onDontMergeClicked: (variable: VariableModel) => void;
    onResetChanges: (value: VariableValueModel) => void;
    onDuplicateVariable: (variable: VariableModel) => void;
    onDuplicate?: (value: VariableValueModel) => void;
    onAddValue: (value: VariableModel, selectedValue: VariableValueModel) => void;
    onDelete: (value: VariableValueModel) => void;
    undoDelete: (value: VariableValueModel) => void;
    openVariableEditor: (value: VariableValueModel, name: string, focus: FocusField) => void;
    changingToReferenceType: (value: VariableValueModel, name: string, referenceType: VariableType) => void;
    onBlur: (value: VariableValueModel, blurredFrom: FocusableCellType) => void;
    onFocus: (value: VariableValueModel, focus: FocusableCellType) => void;
    onNavigateUp: (value: VariableValueModel) => void;
    onNavigateDown: (value: VariableValueModel) => void;
    onSensitiveStateChanged: (value: VariableValueModel, state: SensitiveState) => void;
}

interface SingleVariableRowState {
    measuredControlCellWidth: number | undefined;
}

export default class SingleVariableRow extends React.Component<SingleVariableRowProps, SingleVariableRowState> {
    constructor(props: SingleVariableRowProps) {
        super(props);
        this.state = {
            measuredControlCellWidth: undefined
        };
    }

    shouldComponentUpdate(nextProps: SingleVariableRowProps, nextState: SingleVariableRowState) {
        return nextProps.variable !== this.props.variable
            || nextProps.value !== this.props.value
            || nextProps.status !== this.props.status
            || nextProps.sensitiveState !== this.props.sensitiveState
            || nextProps.existingValue !== this.props.existingValue
            || nextProps.isVariableDeleted !== this.props.isVariableDeleted
            || nextProps.availableScopes !== this.props.availableScopes
            || nextProps.tagIndex !== this.props.tagIndex
            || nextProps.certificateIndex !== this.props.certificateIndex
            || nextProps.isProjectScoped !== this.props.isProjectScoped
            || nextProps.variableMessages !== this.props.variableMessages
            || nextProps.valueMessages !== this.props.valueMessages
            || nextProps.showNameCell !== this.props.showNameCell
            || nextProps.cellAligner !== this.props.cellAligner
            || nextProps.focus !== this.props.focus
            || nextProps.scopeCellWidth !== this.props.scopeCellWidth
            || nextState.measuredControlCellWidth !== this.state.measuredControlCellWidth;
    }

    render() {
        const isNameCellFocused = this.props.focus === FocusableCellType.Name;
        const isValueCellFocused = this.props.focus === FocusableCellType.Value;
        const isScopeCellEditing = this.props.focus === FocusableCellType.ScopeEdit;
        const isScopeCellViewingAll = this.props.focus === FocusableCellType.ScopeViewAll;
        const allWarningMessages = this.props.variableMessages.variableWarningMessages;

        return <div onKeyDown={ev => {
            const code = keycode(ev);
            if (code === "down") {
                this.props.onNavigateDown(this.props.value);
                ev.preventDefault();
            } else if (code === "up") {
                this.props.onNavigateUp(this.props.value);
                ev.preventDefault();
            }
        }}>
            <div style={{
                height: SingleVariableRowHeight,
            }}>
                {this.props.cellAligner([
                    <VariableNameAndDescriptionCell
                        name={this.props.showNameCell
                            ? this.props.value.IsEditable
                                ? <VariableNameCell
                                    id={this.props.value.Id}
                                    name={this.props.variable.name}
                                    hintText={"Enter name"}
                                    isDuplicate={this.props.variableMessages.hasDuplicateName}
                                    warningMessages={allWarningMessages}
                                    onNameChanged={(name) => this.props.onNameChanged(this.props.variable, name)}
                                    deleted={this.props.isVariableDeleted}
                                    onOpenEditorClicked={() => this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Name)}
                                    onMergeClicked={() => this.props.onMergeClicked(this.props.variable, this.props.value)}
                                    onDontMergeClicked={() => this.props.onDontMergeClicked(this.props.variable)}
                                    isFocused={isNameCellFocused}
                                    onFocus={() => this.props.onFocus(this.props.value, FocusableCellType.Name)}
                                    onBlur={() => this.props.onBlur(this.props.value, FocusableCellType.Name)}
                                />
                                : <VariableCell><ReadonlyText text={this.props.variable.name}/></VariableCell>
                            : <VariableMessageCell warningMessages={allWarningMessages} />}
                            description={this.props.value.Description && <VariableDescriptionIcon
                                description={this.props.value.Description}
                                onClick={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Description)}
                            />}
                    />,
                    <div className={styles.fullSizeCell}>
                        {this.props.value.IsEditable
                        ? <VariableValueCell
                            id={this.props.value.Id}
                            value={this.props.value.Value}
                            type={this.props.value.Type}
                            sensitiveState={this.props.sensitiveState}
                            certificateIndex={this.props.certificateIndex}
                            isPromptedVariable={!!this.props.value.Prompt}
                            hintText={this.props.value.Prompt ? "Enter default value" : "Enter value"}
                            onValueChanged={(Value) => this.props.onVariableChanged({...this.props.value, Value})}
                            onVariableTypeChanged={(Type) => this.props.onVariableChanged({
                                ...this.props.value,
                                Type,
                                IsSensitive: Type === VariableType.Sensitive})}
                            deleted={this.props.status === VariableStatus.Deleted}
                            onOpenEditorClicked={() => this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Value)}
                            onChangeToReferenceType={(type) => this.props.changingToReferenceType(this.props.value, this.props.variable.name, type)}
                            isFocused={isValueCellFocused}
                            onSensitiveStateChanged={state => this.props.onSensitiveStateChanged(this.props.value, state)}
                            onFocus={() => this.props.onFocus(this.props.value, FocusableCellType.Value)}
                            onBlur={() => this.props.onBlur(this.props.value, FocusableCellType.Value)}
                            existingSensitiveValue={this.props.existingValue && this.props.existingValue.Type === VariableType.Sensitive
                                ? this.props.existingValue.Value : undefined}
                        />
                        : <VariableCell><ReadonlyText text={this.props.value.Value}/></VariableCell>}
                    </div>,
                <div className={styles.lastColumn}>
                    <div className={styles.scopeCell}>
                        <VariableScopeCell
                            scope={this.props.value.Scope}
                            onScopeChanged={(Scope) => this.props.value.IsEditable && this.props.onVariableChanged({...this.props.value, Scope})}
                            availableScopes={this.props.availableScopes}
                            tagIndex={this.props.tagIndex}
                            isProjectScoped={this.props.isProjectScoped}
                            deleted={this.props.status === VariableStatus.Deleted}
                            isEditing={isScopeCellEditing}
                            isViewingAll={isScopeCellViewingAll}
                            doBusyTask={this.props.doBusyTask}
                            onFocusEdit={() => this.props.value.IsEditable && this.props.onFocus(this.props.value, FocusableCellType.ScopeEdit)}
                            onBlurEdit={() => this.props.value.IsEditable && this.props.onBlur(this.props.value, FocusableCellType.ScopeEdit)}
                            onFocusViewAll={() => this.props.onFocus(this.props.value, FocusableCellType.ScopeViewAll)}
                            onBlurViewAll={() => this.props.onBlur(this.props.value, FocusableCellType.ScopeViewAll)}
                            cellHeight={SingleVariableRowHeight}
                            onOpenEditorClicked={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, this.props.variable.name, FocusField.Scope)}
                            containerWidth={this.props.scopeCellWidth !== undefined && this.state.measuredControlCellWidth !== undefined
                                ? this.props.scopeCellWidth - this.state.measuredControlCellWidth
                                : undefined}
                        />
                    </div>
                    <MeasureWidthOutOfFlow
                        onMeasured={width => this.setState({measuredControlCellWidth: width})}
                        // remeasure if the status changes, because this could toggle whether an icon appears or not
                        key={this.props.status}
                    >
                        <div className={cn(styles.controlCell)}>
                            <div onClick={() => this.props.value.IsEditable && this.props.openVariableEditor(this.props.value, this.props.variable.name, null)}>
                                <VariableStatusIcon status={this.props.status}/>
                            </div>
                            {this.props.value.IsEditable &&
                            <OverflowMenu
                                colorOverride={null}
                                menuItems={this.menuItems()}
                                tabIndex={-1}
                            />}
                        </div>
                    </MeasureWidthOutOfFlow>
                </div>
            ])}
            </div>
        </div>;
    }

    private menuItems() {
        if (this.props.status !== VariableStatus.Deleted) {
            const options = [];
            options.push(OverflowMenu.item("Add Value", () => this.props.onAddValue(this.props.variable, this.props.value)));
            options.push(...this.duplicateOptions());
            options.push(...this.modifiedOptions());
            options.push(OverflowMenu.item("Delete Variable", () => this.props.onDelete(this.props.value)));
            return options;
        } else {
            return [OverflowMenu.item("Undo Delete", () => this.props.undoDelete(this.props.value))];
        }
    }

    private modifiedOptions() {
        return this.props.status === VariableStatus.Modified
            ? [OverflowMenu.item("Reset Changes", () => this.props.onResetChanges(this.props.value))]
            : [];
    }

    private duplicateOptions() {
        return [OverflowMenu.item("Duplicate Variable", () => this.props.onDuplicateVariable(this.props.variable))];
    }
}