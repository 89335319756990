import { combineReducers } from "redux";
import IPageWrapper from "utils/pageId";
import GlobalState from "globalState";

export const PageLayoutActions = {
    PAGELAYOUT_UPDATED: "PAGELAYOUT_UPDATED",
};

export interface PageLayoutAreaState {
    config: PageLayoutState;
}

export interface PageLayoutState {
    page: IPageWrapper;
}

export function pageLayoutStateUpdated(state: PageLayoutState) {
    return { type: PageLayoutActions.PAGELAYOUT_UPDATED, state };
}

const updateConfig = (state: PageLayoutState, patch: Partial<PageLayoutState>) => state ? { ...state, ...patch } : state;
const INITIAL_STATE: PageLayoutState = { page: null };

const pageLayoutConfig = (state: PageLayoutState = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case PageLayoutActions.PAGELAYOUT_UPDATED: {
            const config = action.state as PageLayoutState;
            if (state.page && config.page.Id === state.page.Id) { // No point updating, it's already set.
                return state;
            }
            return updateConfig(state, config);

        }
        default:
            return state;
    }
};

const pageLayoutArea = combineReducers({ config: pageLayoutConfig });

export default pageLayoutArea;

export const pageConfigSelector = (state: GlobalState) => state.pageLayoutArea && state.pageLayoutArea.config;
export const pageIdSelector = (state: GlobalState) => pageConfigSelector(state).page.Id;
export const pageNameSelector = (state: GlobalState) => pageConfigSelector(state).page.Name;