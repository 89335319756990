import * as React from "react";
import {handleActions} from "redux-actions";
import {UnhandledError} from "globalState";

const ActionTypes = {
    UNHANDLED_ERROR_CLEARED: "UNHANDLED_ERROR_CLEARED",
};

export function clearUnhandledErrorAction() {
    return {type: ActionTypes.UNHANDLED_ERROR_CLEARED};
}

const clearUnhandledError = handleActions({
    [ActionTypes.UNHANDLED_ERROR_CLEARED]: (state: UnhandledError, action: any): any => {
        return null;
    }
}, null);

export default clearUnhandledError;