exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__2Piz7 {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__3aOb5 {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__31vRf {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__POqYH {\n    display: none !important;\n  }\n}\n.style_permissions__35Gv0 {\n  list-style-type: disc;\n  margin-left: 2rem;\n}\n.style_roleName__1kd0R {\n  margin-top: 0rem;\n}\n.style_user__1GVno {\n  display: flex;\n  align-items: center;\n}\n.style_userName__39IO_ {\n  margin-left: 0.5rem;\n}\n.style_nameContainer__2VUYg {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n.style_nameContainer__2VUYg .style_lockedCheck__DaN3W {\n  padding-right: 0.5rem;\n}\n.style_permissionColumn__3QfRC {\n  width: 30%;\n}\n@media (max-width: 37.4rem) {\n  .style_permissionColumn__3QfRC {\n    width: auto;\n  }\n}\n.style_descriptionColumn__2Zezt {\n  width: auto;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__2Piz7",
	"hideSm": "style_hide-sm__2Piz7",
	"visible-sm": "style_visible-sm__3aOb5",
	"visibleSm": "style_visible-sm__3aOb5",
	"hide-md": "style_hide-md__31vRf",
	"hideMd": "style_hide-md__31vRf",
	"visible-md": "style_visible-md__POqYH",
	"visibleMd": "style_visible-md__POqYH",
	"permissions": "style_permissions__35Gv0",
	"roleName": "style_roleName__1kd0R",
	"user": "style_user__1GVno",
	"userName": "style_userName__39IO_",
	"nameContainer": "style_nameContainer__2VUYg",
	"lockedCheck": "style_lockedCheck__DaN3W",
	"permissionColumn": "style_permissionColumn__3QfRC",
	"descriptionColumn": "style_descriptionColumn__2Zezt"
};