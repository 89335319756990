exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__12ocB {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__2Lb4d {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__2STES {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__1UVop {\n    display: none !important;\n  }\n}\n.style_textControl__2gK1t {\n  flex: 1 1 auto;\n}\n.style_textControlKey__2qUqO {\n  max-width: 12.5rem;\n}\n.style_separator__2h2-4 {\n  margin: 0 2rem;\n  font-weight: 600;\n  align-self: center;\n}\n.style_itemContainer__35UFk {\n  display: flex;\n  align-items: flex-start;\n}\n.style_threeItemContainer__1FGh6 {\n  flex-direction: column;\n}\n@media (max-width: 79.9rem) {\n  .style_textControl__2gK1t {\n    width: 100%;\n  }\n  .style_itemContainer__35UFk {\n    flex-direction: column;\n  }\n  .style_separator__2h2-4 {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__12ocB",
	"hideSm": "style_hide-sm__12ocB",
	"visible-sm": "style_visible-sm__2Lb4d",
	"visibleSm": "style_visible-sm__2Lb4d",
	"hide-md": "style_hide-md__2STES",
	"hideMd": "style_hide-md__2STES",
	"visible-md": "style_visible-md__1UVop",
	"visibleMd": "style_visible-md__1UVop",
	"textControl": "style_textControl__2gK1t",
	"textControlKey": "style_textControlKey__2qUqO",
	"separator": "style_separator__2h2-4",
	"itemContainer": "style_itemContainer__35UFk",
	"threeItemContainer": "style_threeItemContainer__1FGh6"
};