import {BaseComponent} from "components/BaseComponent/BaseComponent";
import pluginRegistry, {ActionEditProps} from "components/Actions/pluginRegistry";
import {KubernetesConfigMapProperties} from "components/Actions/kubernetes/kubernetesProperties";
import * as React from "react";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import {default as ExpandableFormSection} from "components/form/Sections/ExpandableFormSection";
import {JsonUtils} from "utils/jsonUtils";
import Summary from "components/form/Sections/Summary";
import {ActionExecutionLocation} from "client/resources";
import {TargetRoles} from "areas/projects/components/DeploymentProcess/ActionDetails";
import {ActionSummaryProps} from "components/Actions/actionSummaryProps";
import {KubernetesConfigMapComponent} from "components/Actions/kubernetes/kubernetesConfigMapComponent";
import * as _ from "lodash";
import {ScriptPackageProperties} from "components/Actions/script/ScriptPackageReferenceDialog";

class KubernetesDeployConfigMapActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        return <div>
            Deploy a config map resource to Kubernetes
        </div>;
    }
}

class KubernetesDeployConfigMapActionEdit extends BaseComponent<ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>, never> {
    constructor(props: ActionEditProps<KubernetesConfigMapProperties, ScriptPackageProperties>) {
        super(props);
    }

    render() {
        return <div>
            <KubernetesConfigMapComponent
                properties={this.props.properties}
                packages={this.props.packages}
                plugin={this.props.plugin}
                getFieldError={this.props.getFieldError}
                setProperties={this.props.setProperties}
                setPackages={this.props.setPackages}
                doBusyTask={this.props.doBusyTask}
                busy={this.props.busy}
                errors={this.props.errors}
                projectId={this.props.projectId}
                expandedByDefault={true}/>
            <ExpandableFormSection
                errorKey="Octopus.Action.KubernetesContainers.DeploymentLabels"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Config Map Labels"
                summary={this.labelsSummary()}
                help={"The labels applied to the config map resource"}>
                <StringKeyValueEditList
                    items={this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"]}
                    name="Label"
                    separator=""
                    onChange={val => this.props.setProperties({ ["Octopus.Action.KubernetesContainers.DeploymentLabels"]: val })}
                    valueLabel="Value"
                    keyLabel="Name"
                    hideBindOnKey={false}
                    projectId={this.props.projectId}
                />
            </ExpandableFormSection>
        </div>;
    }

    private labelsSummary() {
        const labels = _.toPairs(JsonUtils.tryParse(this.props.properties["Octopus.Action.KubernetesContainers.DeploymentLabels"], {}));

        if (labels.length === 0) {
            return Summary.placeholder("The config map resource labels");
        }

        return Summary.summary(<span>Add the label{labels.length > 1 && <span>s</span>} {
            _.chain(labels)
                .flatMap(pair => [<strong>{pair[0]}: {pair[1]}</strong>, <span>, </span>])
                .slice(0, -1)
                .value()
        }</span>);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    actionType: "Octopus.KubernetesDeployConfigMap",
    summary: (properties, targetRolesAsCSV) => <KubernetesDeployConfigMapActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    edit: KubernetesDeployConfigMapActionEdit,
    canHaveChildren: (step) => true,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.Required,
    hasPackages: (action) => false
});