import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../pluginRegistry";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {ActionSummaryProps} from "../actionSummaryProps";
import {repository} from "clientInstance";
import {TeamResource} from "client/resources/teamResource";
import {TeamChip} from "components/Chips/index";
import {
    ExpandableFormSection,
    Summary,
    FormSectionHeading,
    SummaryNode,
    required,
    Note
} from "components/form";
import TeamMultiSelect from "components/MultiSelect/TeamMultiSelect";
import ParseHelper from "utils/ParseHelper/ParseHelper";
import {ActionExecutionLocation} from "../../../client/resources/actionExecutionLocation";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { VariableLookupMarkdownEditor } from "components/form/MarkdownEditor/MarkdownEditor";

class ManualActionSummary extends BaseComponent<ActionSummaryProps, any> {
    render() {
        return <div>
            Manual intervention
        </div>;
    }
}

interface ManualActionProperties {
    "Octopus.Action.Manual.Instructions": string;
    "Octopus.Action.Manual.ResponsibleTeamIds": string;
}

interface ManualActionEditState {
    teams: TeamResource[];
}

class ManualActionEdit extends BaseComponent<ActionEditProps<ManualActionProperties>, ManualActionEditState> {
    constructor(props: ActionEditProps<ManualActionProperties>) {
        super(props);
        this.state = {
            teams: [],
        };
    }

    componentDidMount() {
        this.props.doBusyTask(async () => {
            const teams = await repository.Teams.all();
            this.setState({teams});
        });
    }

    render() {
        return <div>
            <FormSectionHeading title="Manual Intervention"  />
            <ExpandableFormSection
                errorKey="Octopus.Action.Manual.Instructions"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Instructions"
                help="These instructions will be presented to the user to follow."
                summary={this.instructionsSummary()}>

                <VariableLookupMarkdownEditor
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.props.properties["Octopus.Action.Manual.Instructions"]}
                    label="Instructions"
                    error={this.props.getFieldError("Octopus.Action.Manual.Instructions")}
                    onChange={(val: string) => this.props.setProperties({["Octopus.Action.Manual.Instructions"]: val})}
                    validate={required("Please provide instructions to display during the step")} />

            </ExpandableFormSection>
            <ExpandableFormSection
                errorKey="Octopus.Action.Manual.ResponsibleTeamIds"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Responsible Teams"
                summary={this.teamSummary()}
                help="Select the teams responsible for this manual step.">
                <TeamMultiSelect label="Responsible teams"
                                 items={this.state.teams}
                                 value={ParseHelper.parseCSV(this.props.properties["Octopus.Action.Manual.ResponsibleTeamIds"])}
                                 error={this.props.getFieldError("Octopus.Action.Manual.ResponsibleTeamIds")}
                                 onChange={val => this.props.setProperties({["Octopus.Action.Manual.ResponsibleTeamIds"]: ParseHelper.encodeCSV(val)})}/>
                <Note>If no teams are specified, all users who have permission to deploy the project will be able to perform the manual step.
                    When one or more teams are specified, as in the case of an approval workflow, then only members of those teams will be able to perform the step.</Note>
                <Note>Specifying responsible teams will make the step unable to be skipped.</Note>
            </ExpandableFormSection>
        </div>;
    }

    instructionsSummary(): SummaryNode {
        const instructions = this.props.properties["Octopus.Action.Manual.Instructions"];
        return instructions
            ? Summary.summary("Instructions have been provided")
            : Summary.placeholder("Instructions have not been provided");
    }

    teamSummary(): SummaryNode {
        const teams = ParseHelper.parseCSV(this.props.properties["Octopus.Action.Manual.ResponsibleTeamIds"]);
        if (teams.length === 0) {
            return Summary.placeholder("All authorized teams");
        }
        const chips = this.state.teams.filter(t => teams.indexOf(t.Id) !== -1).map(t => <TeamChip team={t} key={t.Id}/>);
        return Summary.summary(<div>{chips}</div>);
    }
}

pluginRegistry.registerDeploymentAction({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunOnWorker: false,
    actionType: "Octopus.Manual",
    summary: (properties, targetRolesAsCSV) => <ManualActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: (step) => false,
    canBeChild: true,
    targetRoleOption: (action) => TargetRoles.None,
    hasPackages: (action) => false,
    edit: ManualActionEdit
});