exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DialogContent_content__v2QBO {\n  flex: auto;\n  overflow-y: auto;\n}\n.DialogContent_block__1XK77 {\n  height: 100%;\n  padding-bottom: 1rem;\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n}\n.DialogContent_flex__nr3Ik {\n  display: flex;\n  flex-direction: column;\n}\n.DialogContent_flex__nr3Ik > * {\n  flex: auto;\n}\n.DialogContent_padded__2ueQj {\n  padding-left: 1rem;\n  padding-right: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"content": "DialogContent_content__v2QBO",
	"block": "DialogContent_block__1XK77",
	"flex": "DialogContent_flex__nr3Ik",
	"padded": "DialogContent_padded__2ueQj"
};