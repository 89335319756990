import * as React from "react";
import { EventGroupResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import {EventGroupChip} from "components/Chips/index";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface EventGroupMultiSelectProps extends FormFieldProps<string[]> {
    items: EventGroupResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EventGroupTypedMultiSelect = MultiSelect<EventGroupResource>();

const EventGroupMultiSelect: React.StatelessComponent<EventGroupMultiSelectProps> = props => {
    const chipRenderer = (r: EventGroupResource, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.EventGroup}
            chipRender={(item: EventGroupResource) => <EventGroupChip
                onRequestDelete={onRequestDelete}
                eventGroup={item}
            />}
        />;
    };

    return <EventGroupTypedMultiSelect
        items={props.items}
        fieldName="event groups"
        renderChip={chipRenderer}
        {...props} />;
};

export default EventGroupMultiSelect;