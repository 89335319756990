exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_headerColumn__22CvW:first-child {\n  width: 18.75rem !important;\n}\n.style_rowColumn__13kPZ {\n  word-break: normal;\n  white-space: normal;\n  padding: 0rem 1.125rem!important;\n  vertical-align: middle!important;\n}\n.style_rowColumn__13kPZ p {\n  margin: 0.5rem 0;\n}\n.style_center__2WdKe {\n  text-align: center;\n}\n", ""]);

// exports
exports.locals = {
	"headerColumn": "style_headerColumn__22CvW",
	"rowColumn": "style_rowColumn__13kPZ",
	"center": "style_center__2WdKe"
};