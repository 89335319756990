import * as React from "react";
const styles = require("./styles.less");

interface ActionTemplateCardListProps {
    children: any;
}

const ActionTemplateCardList: React.StatelessComponent<ActionTemplateCardListProps> = (props: ActionTemplateCardListProps) => {
    return <ol className={styles.templates}>
            {props.children}
        </ol>;
};

export default ActionTemplateCardList;