import {ValueWithSource} from "areas/variables/VariableDisplayer/VariableDisplayer";
import {ValueSource} from "areas/variables/SourceLink/SourceLink";
import {VariableResource} from "client/resources/variableResource";
import {VariableWithSource} from "./VariableDisplayer";
import groupVariableResourcesByName, {default as groupVariablesByName} from "./groupVariablesByName";

function convertVariableResourceToValueWithSource(variable: VariableResource, source: ValueSource): ValueWithSource {
    return {
        description: variable.Description,
        type: variable.Type,
        scope: variable.Scope,
        value: variable.Value,
        source,
        isPrompted: !!variable.Prompt
    };
}

export function convertVariableResourcesToVariablesWithSource(variableResources: VariableResource[], source: ValueSource): VariableWithSource[] {
    const groupedByName = groupVariablesByName(variableResources, v => v.Name);
    return Object.keys(groupedByName)
        .map(name => ({name, values: convertVariableResourcesToValuesWithSource(groupedByName[name], source)}));
}

function convertVariableResourcesToValuesWithSource(resources: VariableResource[], source: ValueSource): ReadonlyArray<ValueWithSource> {
    return resources.map(r => convertVariableResourceToValueWithSource(r, source));
}