import * as React from "react";
import BaseComponent from "components/BaseComponent";
import {Errors} from "components/DataBaseComponent/DataBaseComponent";
import CodeEditor, {TextFormat} from "components/CodeEditor/CodeEditor";
import {
  AzureTemplateHelper,
} from "./AzureTemplateHelper";
import SaveDialogLayout from "components/DialogLayout/Custom/SaveDialogLayout";
import CopyToClipboard from "components/CopyToClipboardButton";
import { SaveDialogActions, SaveDialogTitleBar, FlexDialogContent } from "components/DialogLayout/Custom";
import DialogActions from "components/DialogLayout/Custom/DialogActions";
import { CustomDialog as Dialog, RenderProps } from "components/Dialog/CustomDialog";

interface AzureResourceGroupSourceCodeDialogProps extends RenderProps {
  template: string;
  saveDone(newTemplate: string): any;
}

interface AzureResourceGroupSourceCodeDialogState {
  template: string;
  errors?: Errors;
}

class AzureResourceGroupSourceCodeDialog extends BaseComponent<AzureResourceGroupSourceCodeDialogProps, AzureResourceGroupSourceCodeDialogState> {
    private editor?: CodeEditor;

    constructor(props: AzureResourceGroupSourceCodeDialogProps) {
        super(props);
        this.state = {
          errors: null,
          template: this.props.template
        };
    }

    async save() {
      const result = AzureTemplateHelper.extractParameters(this.state.template);
      if (result.errors.length > 0) {
        this.setState({
          errors: {
            message: "The source code contains errors",
            details: [...result.errors],
            fieldErrors: {}
        }});
      } else {
        await this.props.saveDone(this.state.template);
        return true;
      }
    }

    onFocusedEditorEscapePressed() {
      if (this.editor) {
          this.editor.blur();
      }
    }

    render() {
        return (
          <Dialog open={this.props.open} close={this.props.close} render={(props) => (
            <SaveDialogLayout
            {...props}
            close={this.props.close}
            open={this.props.open}
            renderTitle={() => <SaveDialogTitleBar title="Edit ARM Template"/>}
            busy={null}
            errors={this.state.errors}
            onSaveClick={() => this.save()}
            renderActions={(renderProps) => (
              <DialogActions actions={
                <SaveDialogActions
                    close={renderProps.close}
                    onSaveClick={renderProps.onSaveClick}
                    savePermission={renderProps.savePermission}/>}
                additionalActions={<CopyToClipboard value={this.state.template}/>}
            />
            )}
            renderContent={(renderProps) => (
              <FlexDialogContent>
                <CodeEditor value={this.state.template}
                      ref={e => this.editor = e}
                      language={TextFormat.JSON}
                      allowFullScreen={false}
                      onChange={(template) => this.setState({template})}
                      autoFocus={this.props.template == null || this.props.template === undefined || this.props.template.length === 0}
                      onEscPressed={() => this.onFocusedEditorEscapePressed()}
                />
              </FlexDialogContent>
            )}
          />
        )}/>
      );
    }
}

export default AzureResourceGroupSourceCodeDialog;