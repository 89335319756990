import * as React from "react";
import {connect} from "react-redux";
import { Dispatch, Action } from "redux";
import GlobalState, {ExpanderContainer} from "globalState";
import {
    defaultContainerKey, onExpanderCreated,
    onExpanderStateChanged
} from "components/form/Sections/reducers/expanders";

export interface ExpandableProps {
    isExpanded: boolean;
    expandingAll?: boolean;
    onExpandedChanged(isExpanded: boolean): void;
}

export interface ExpandableContainerProps {
    containerKey?: string;
    errorKey: string;
    isExpandedByDefault?: boolean;
}

// HOC that hooks up a component that manages expansion to the redux store
export default function Expandable<Props extends any>(
    Comp: React.ComponentClass<Props & ExpandableProps>
) {
    type InternalProps = StateProps & DispatchProps & Props & ExpandableContainerProps;
    class ExpandableInternal extends React.Component<InternalProps> {
        componentDidMount() {
            this.props.onMount(this.props.errorKey, this.props.containerKey, this.props.isExpandedByDefault);
        }

        componentWillReceiveProps(nextProps: Readonly<InternalProps>) {
            if (nextProps.containerKey !== this.props.containerKey || nextProps.errorKey !== this.props.errorKey) {
                this.props.onMount(nextProps.errorKey, nextProps.containerKey, nextProps.isExpandedByDefault);
            }
        }

        render() {
            return <Comp {...this.props} />;
        }
    }

    function mapStateToProps(state: GlobalState, ownProps: ExpandableContainerProps): StateProps {
        const containerKey = ownProps.containerKey || defaultContainerKey;
        const parentState: ExpanderContainer | null = (state.expanders && state.expanders.hasOwnProperty(containerKey)) ? state.expanders[containerKey] : null;
        const expanderState: boolean | null | undefined = parentState && parentState.expanderValues[ownProps.errorKey];
        const initialState: boolean = (parentState && parentState.initialState);
        const isExpanded: boolean = expanderState === undefined  || expanderState === null ? initialState : expanderState;
        const expandingAll = parentState ? parentState.expandingAll : false;

        return { isExpanded, expandingAll };
    }

    function mapDispatchToProps(dispatch: Dispatch<Action<any>>, ownProps: ExpandableContainerProps): DispatchProps {
        return {
            onExpandedChanged: (state: boolean) => {
                dispatch(onExpanderStateChanged(ownProps.containerKey || defaultContainerKey, ownProps.errorKey, state));
            },
            onMount: (errorKey: string, containerKey?: string, expanded?: boolean) => {
                dispatch(onExpanderCreated(containerKey || defaultContainerKey, errorKey, expanded));
            }
        };
    }

    return connect<StateProps, DispatchProps, Props & ExpandableContainerProps>(
        mapStateToProps,
        mapDispatchToProps
    )(ExpandableInternal);
}

interface StateProps {
    isExpanded: boolean;
    expandingAll: boolean;
}

interface DispatchProps {
    onExpandedChanged(isExpanded: boolean): void;
    onMount(errorKey: string, containerKey?: string, expanded?: boolean): void;
}