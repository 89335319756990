exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_updateWarning__398Mh {\n  text-align: center;\n}\n", ""]);

// exports
exports.locals = {
	"updateWarning": "style_updateWarning__398Mh"
};