exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_narrow__3ZxEd {\n  max-width: 8rem;\n}\n", ""]);

// exports
exports.locals = {
	"narrow": "style_narrow__3ZxEd"
};