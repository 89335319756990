import * as React from "react";
import * as _ from "lodash";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { repository } from "clientInstance";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import {
    ActionExecutionLocation, GetPrimaryPackageReference, InitialisePrimaryPackageReference,
    SetPrimaryPackageReference
} from "client/resources";
import { ExpandableFormSection } from "components/form";
import PackageSelector from "components/PackageSelector/PackageSelector";
import PackageDownloadOptions from "components/PackageDownloadOptions/PackageDownloadOptions";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import FeedResource from "client/resources/feedResource";
import CommonSummaryHelper from "utils/CommonSummaryHelper/CommonSummaryHelper";
import routeLinks from "routeLinks";
import { getFeedName } from "../getFeedName";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { FeedType } from "client/resources/feedResource";
import Callout, { CalloutType } from "components/Callout";

interface TentaclePackageActionSummaryState {
    feedName: string;
}

class TentaclePackageAction extends BaseComponent<ActionSummaryProps, TentaclePackageActionSummaryState> {
    constructor(props: ActionSummaryProps) {
        super(props);
        this.state = { feedName: null };
    }

    async componentDidMount() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        if (pkg) {
            this.setState({ feedName: await getFeedName(pkg.FeedId) });
        }
    }

    render() {
        const pkg = GetPrimaryPackageReference(this.props.packages);
        return pkg
            ? <div>
                Deploy package <strong>{pkg.PackageId} </strong>
                from {this.state.feedName ? <strong>{this.state.feedName}</strong> : <em>{pkg.FeedId}</em>}
                {this.props.targetRolesAsCSV && <span> to deployment targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /> </span>}
            </div>
            : <Callout type={CalloutType.Warning} title="Misconfigured step">
                Package was not selected or cannot be found. Please review this step and ensure a valid package is selected.
            </Callout>;
    }
}

interface TentaclePackageActionEditState {
    feeds: FeedResource[];
}

export class TentaclePackageActionEdit extends BaseComponent<ActionEditProps<{}>, TentaclePackageActionEditState> {
    constructor(props: ActionEditProps<{}>) {
        super(props);

        this.state = {
            feeds: []
        };
    }

    async componentDidMount() {
        await this.loadFeeds((feeds) => this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds)));
    }

    render() {
        // The package is initialized in componentDidMount, but render gets called before the update is propagated
        if (!this.props.packages || this.props.packages.length === 0) {
            return null;
        }

        const pkg = GetPrimaryPackageReference(this.props.packages);
        const feed = _.find(this.state.feeds, f => f.Id === pkg.FeedId);

        const help = this.state.feeds.length > 0
            ? <span>
                This step is used to deploy the contents of a package to one or more machines. You
                can configure the remote machines to deploy to in the <InternalLink to={routeLinks.infrastructure.root} openInSelf={false}>Infrastructure</InternalLink> tab.
                The package that you wish to deploy should contain all the files needed to run your application. Learn
                more about <ExternalLink href="DocumentationPackaging">what your packages should contain, and how to create them</ExternalLink>.
            </span>
            : <span>Choose the package you which to deploy</span>;

        return <div>
            <ExpanderSectionHeading title="Package Details" />
            <ExpandableFormSection
                errorKey="package"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Package"
                summary={CommonSummaryHelper.packageSummary(pkg, this.state.feeds)}
                help={help}>
                <PackageSelector
                    packageId={pkg.PackageId}
                    feedId={pkg.FeedId}
                    onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                    onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                    packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                    feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                    projectId={this.props.projectId}
                    feeds={this.state.feeds}
                    localNames={this.props.localNames}
                    feedType={[FeedType.Nuget, FeedType.BuiltIn, FeedType.Maven, FeedType.GitHub]}
                    refreshFeeds={this.loadFeeds} />
                <PackageDownloadOptions
                    packageAcquisitionLocation={pkg.AcquisitionLocation}
                    onPackageAcquisitionLocationChanged={acquisitionLocation =>
                        this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                    feed={feed}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames} />
            </ExpandableFormSection>
        </div>;
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    }
}

pluginRegistry.registerDeploymentAction({
    actionType: "Octopus.TentaclePackage",
    summary: (properties, targetRolesAsCSV, packages) => <TentaclePackageAction properties={properties} targetRolesAsCSV={targetRolesAsCSV} packages={packages} />,
    canHaveChildren: (step) => true,
    canBeChild: true,
    edit: TentaclePackageActionEdit,
    executionLocation: ActionExecutionLocation.AlwaysOnTarget,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => true,
    features: {
        optional: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables", "Octopus.Features.CustomDirectory",
            "Octopus.Features.CustomScripts", "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles",
            "Octopus.Features.IisHome", "Octopus.Features.IISWebSite", "Octopus.Features.RedGateDatabase", "Octopus.Features.WindowsService", "Octopus.Features.Nginx"],
        initial: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables"]
    }
});