import * as React from "react";
import PaperLayout from "components/PaperLayout";
import { DeploymentTargetEndpointSelector } from "./Endpoints/EndpointSelector";
import routeLinks from "routeLinks";

import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";

const Heading = (
    <React.Fragment>
        What type of <strong>Deployment Target</strong> do you want to set up? Learn more about <ExternalLink href="DeploymentTargets">Deployment Targets</ExternalLink>
    </React.Fragment>
);

const MachineSettingsNew: React.SFC<{}> = () => {
    return (
        <PaperLayout title="Add Deployment Target"
            breadcrumbTitle={"Deployment Targets"}
            breadcrumbPath={routeLinks.infrastructure.machines.root}
            fullWidth={true}
            flatStyle={true}>
            <DeploymentTargetEndpointSelector heading={Heading} />
        </PaperLayout>
    );
};

export default MachineSettingsNew;