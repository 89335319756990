exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_headerColumn__1rN3h:nth-child(3) {\n  padding-left: 2.95rem;\n}\n.style_rowColumn__3VYV1 {\n  word-break: break-all;\n  word-wrap: break-word;\n  white-space: normal;\n  padding: 0.175rem 1.125rem!important;\n  vertical-align: middle!important;\n}\n.style_addRule__1-q6f {\n  margin: 1.0rem 0rem;\n}\n", ""]);

// exports
exports.locals = {
	"headerColumn": "style_headerColumn__1rN3h",
	"rowColumn": "style_rowColumn__3VYV1",
	"addRule": "style_addRule__1-q6f"
};