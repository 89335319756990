exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1tfR3 {\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n}\n.style_name__3gyhV {\n  min-width: 0;\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n.style_description__26OQB {\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1tfR3",
	"name": "style_name__3gyhV",
	"description": "style_description__26OQB"
};