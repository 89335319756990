import * as React from "react";
import {Text} from "components/form";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";

interface AddExternalRoleState extends DataBaseComponentState {
    roleId: string;
    displayName: string;
}

interface AddExternalRoleProps {
    onSelected(roleId: string, displayName: string): boolean;
}

class AddExternalRole extends DataBaseComponent<AddExternalRoleProps, AddExternalRoleState> {
    constructor(props: AddExternalRoleProps) {
        super(props);
        this.state = {
            roleId: null,
            displayName: null
        };
    }

    render() {
        return <OkDialogLayout title="Add External Group/Role"
                               okButtonLabel="Add"
                               busy={this.state.busy}
                               errors={this.state.errors}
                               onOkClick={() => this.props.onSelected(this.state.roleId, this.state.displayName)}>
            <Text label="Group/Role ID" value={this.state.roleId} onChange={roleId => this.setState({roleId})}/>
            <Text label="Display Name" value={this.state.displayName}
                  onChange={displayName => this.setState({displayName})}/>
        </OkDialogLayout>;
    }
}

export default AddExternalRole;