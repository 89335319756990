import * as React from "react";
import { TeamResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { TeamChip } from "components/Chips";
import LookupResourceChipComponent from "components/LookupResourceChip";
import { ChipIcon } from "components/Chips";

interface TeamMultiSelectProps extends FormFieldProps<string[]> {
    items: TeamResource[];
    canBeDeleted?: (team: TeamResource) => boolean;
    label?: string | JSX.Element;
    error?: string;
    descriptionPostfix?: (team: TeamResource) => string;
}

const TeamTypedMultiSelect = MultiSelect<TeamResource>();
const LookupTeamChip = LookupResourceChipComponent<TeamResource>();

const TeamMultiSelect: React.StatelessComponent<TeamMultiSelectProps> = props => {
    const chipRenderer = (r: TeamResource, onRequestDelete: (event: object) => void) => {
       return <LookupTeamChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.Team}
            chipRender={item => {
                const onRequestDeleteProp = props.canBeDeleted(item) ? {onRequestDelete} : {};
                return <TeamChip
                {...onRequestDeleteProp}
                team={item}
                descriptionPostfix={props.descriptionPostfix ? props.descriptionPostfix(item) : null} />; }
            }
        />;
    };

    return <TeamTypedMultiSelect
        items={props.items}
        label="Select teams"
        renderChip={chipRenderer}
        {...props} />
        ;
};

TeamMultiSelect.defaultProps = {
    canBeDeleted: team => true
};

export default TeamMultiSelect;