exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__9af8H {\n  display: flex;\n  align-items: center;\n}\n.style_separator__nIuvl {\n  margin: 0 0.125rem;\n}\n.style_ampm__3wJd2 {\n  margin-left: 0.1875rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__9af8H",
	"separator": "style_separator__nIuvl",
	"ampm": "style_ampm__3wJd2"
};