import * as React from "react";
import SelectField from "./SelectField";
import MenuItem from "material-ui/MenuItem";
import FormFieldProps from "../FormFieldProps";
import { alert, danger, primary, secondaryText } from "../../../colors";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import {normal} from "../../../fontWeights";

const styles = require("./style.less");
import UseLabelStrategy from "components/LabelStrategy/LabelStrategy";
import { withBoundField } from "components/form/BoundField/BoundField";

export interface Item {
    value: string;
    text: string;
    icon?: JSX.Element;
    style?: object;
}

export interface OtherSelectProps {
    fixedLabel?: boolean;
    items: Item[];
    hintText?: string;
    allowClear?: boolean;
    allowFilter?: boolean;
    autoFocus?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    selectionRenderer?: (value: string, menuItem: any) => any;
    validate?(value: string): string;
    onValidate?(value: string): void;
}

export type SelectProps = OtherSelectProps & FormFieldProps<string>;

interface SelectState {
    error?: string;
    filter?: string;
    filteredItems: Item[];
    showExternalError: boolean;
}

class SelectInternal extends React.Component<OtherSelectProps & FormFieldProps<string>, SelectState> {
    constructor(props: OtherSelectProps & FormFieldProps<string>) {
        super(props);
        this.state = {
            filter: null,
            filteredItems: null,
            showExternalError: true
        };
    }

    componentWillReceiveProps(nextProps: SelectProps) {
        const isNewExternalErrorAvailable = nextProps.error !== this.props.error;
        if (isNewExternalErrorAvailable) {
            this.setState({showExternalError: true});
        }
    }

    render() {
        const {
            value,
            label,
            fixedLabel,
            items,
            error,
            onChange,
            onValidate,
            validate,
            warning,
            hintText,
            allowClear,
            allowFilter,
            ...otherProps
        } = this.props;

        const err = this.state.error || (this.state.showExternalError && error);
        const errorText = err || warning;
        const errorStyle = { bottom: "0px", color: err ? danger : alert};

        // We need to hack the labelStyle to stop MaterialUI from overflowing other controls that may be sitting underneath this.
        const labelStyle = {
            height: "40px",
            lineHeight: "40px",
        };

        return (
            <div className={styles.container}>
                <SelectField
                    className={styles.select}
                    value={value}
                    allowClear={allowClear}
                    hintText={hintText}
                    labelStyle={labelStyle}
                    onChange={this.handleChange}
                    errorText={errorText}
                    errorStyle={errorStyle}
                    floatingLabelText={label}
                    floatingLabelStyle={{color: secondaryText, fontWeight: normal}}
                    floatingLabelFocusStyle={errorText ? errorStyle : {color: primary, fontWeight: normal}}
                    floatingLabelFixed={!!hintText}
                    selectedMenuItemStyle={{ color: primary }}
                    underlineFocusStyle={{ borderColor: primary }}
                    underlineStyle={{ borderColor: secondaryText, position: "relative"}}
                    dropDownMenuProps={{ onClose: this.handleOnClose }}
                    iconStyle={{ fill: secondaryText }}
                    filter={allowFilter && <div className={styles.filter}>
                        <FilterSearchBox
                            hintText={"Find..."}
                            autoFocus={true}
                            value={this.state.filter}
                            onChange={this.handleFilterChanged} />
                    </div>}
                    {...otherProps}>
                    {(this.state.filteredItems || items).map(item => {
                        return <MenuItem key={item.value} value={item.value} leftIcon={item.icon} primaryText={item.text} style={item.style} />;
                    })}
                </SelectField>
        </div>
        );
    }

    private handleChange = (_: any, __: any, value: any) => {

        if (this.props.validate) {
            const result = this.props.validate(value);
            this.setState({ error: result });
            if (this.props.onValidate) {
                this.props.onValidate(result);
            }
        }

        this.setState({
            filter: null,
            filteredItems: null,
            showExternalError: false
        });

        this.props.onChange(value);
    }

    private handleFilterChanged = (value: string) => {

        const filteredItems = this.props.items.filter((item) => {
            return item.text.toLowerCase().search(value.toLowerCase()) !== -1;
        });

        this.setState({
            filter: value,
            filteredItems
        });
    }

    private handleOnClose = () => {

        if (this.state.filter) {
            this.setState({
                filter: null,
                filteredItems: null
            });
        }

    }
}

const Select = UseLabelStrategy(SelectInternal, fieldName => `Select ${fieldName}`);
export default Select;
export {SelectInternal};
export const BoundSelect = withBoundField(Select);