import * as React from "react";
import { ProjectResource } from "client/resources";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { repository } from "clientInstance";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { VariableLookupText } from "components/form/VariableLookupText";
import {NginxLocation, keyValuesToString} from "./locationHelpers";
import StringKeyValueEditList from "components/EditList/KeyValueEditList";
import { BoundStringCheckbox } from "components/form/Checkbox/StringCheckbox";
import ExternalLink from "components/Navigation/ExternalLink";
import { Note, Checkbox } from "components/form";

interface LocationState extends DataBaseComponentState {
    location: NginxLocation;
    project?: ProjectResource;
}

interface LocationProps {
    location: NginxLocation;
    localNames: string[];
    projectId: string;
    onAdd(location: any): boolean;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

const defaultReverseProxyDirectives = [
    {key: "proxy_http_version", value: "1.1"},
    {key: "proxy_cache_bypass", value: "$http_upgrade"}
];
const defaultReverseProxyHeaders = [
    {key: "Upgrade", value: "$http_upgrade"},
    {key: "Connection", value: "keep-alive"},
    {key: "Host", value: "$host"},
    {key: "X-Forwarded-For", value: "$proxy_add_x_forwarded_for"},
    {key: "X-Forwarded-Proto", value: "$scheme"}
];

class LocationDialog extends DataBaseComponent<LocationProps, LocationState> {
    constructor(props: LocationProps) {
        super(props);
        this.state = {
            location: null,
            project: null
        };
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            const project = this.props.projectId ? (await repository.Projects.get(this.props.projectId)) : null;

            this.setState({
                location: this.props.location,
                project
            });
        });
    }

    save = () => {
        return this.props.onAdd(this.state.location);
    }

    render() {
        return <OkDialogLayout
            onOkClick={this.save}
            busy={this.state.busy}
            errors={this.state.errors}
            title={"Add location"}>
            {this.state.location && <div>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={this.state.location.path}
                    onChange={x => this.setLocationState({path: x})}
                    hintText="Location"
                    label={"Location"} />
                    <Note>
                        There are two types of parameter to the <code>location</code> directive: prefix strings (pathnames) and regular expressions.
                        See the <ExternalLink href="NginxConfigureLocations">NGINX documentation</ExternalLink> for more information on configuring locations.
                    </Note>
                <Checkbox
                    value={this.state.location.reverseProxy}
                    onChange={x => this.setLocationState({
                        reverseProxy: x,
                        reverseProxyUrl: !x ? "" : this.state.location.reverseProxyUrl,
                        reverseProxyHeaders: this.updateReverseProxyHeaders(defaultReverseProxyHeaders),
                        reverseProxyDirectives: this.updateReverseProxyDirectives(defaultReverseProxyDirectives)
                    })}
                    label="Reverse Proxy"
                    note={<span>Configure this location as a reverse proxy to pass request to another application</span>} />
                {this.state.location.reverseProxy && <div>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={this.state.location.reverseProxyUrl}
                        onChange={x =>
                            this.setLocationState({reverseProxyUrl: x})
                        }
                        hintText="Proxy requests to this URL"
                        label={"URL"} />
                    <h3>Reverse Proxy Headers</h3>
                    <StringKeyValueEditList
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        key="Headers"
                        name="Header"
                        items={this.state.location.headers}
                        onChange={x => this.setLocationState({headers: x})}
                        keyLabel="Header"
                        valueLabel="Value"
                        separator="=" />
                    </div>
                }
                <h3>Directives</h3>
                <StringKeyValueEditList
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    key="Directives"
                    name="Directive"
                    items={this.state.location.directives}
                    onChange={x => this.setLocationState({directives: x})}
                    keyLabel="Directive"
                    valueLabel="Value"
                    separator="=" />
            </div>}
        </OkDialogLayout>;
    }

    private setLocationState<K extends keyof NginxLocation>(state: Pick<NginxLocation, K>, callback?: () => void) {
        this.setChildState1("location", state);
    }

    private updateReverseProxyHeaders(reverseProxyHeaders: any): string {
        if (!this.state.location.reverseProxy) { return ""; }

        return keyValuesToString(reverseProxyHeaders);
    }

    private updateReverseProxyDirectives(reverseProxyDirectives: any): string {
        if (!this.state.location.reverseProxy) { return ""; }

        return keyValuesToString(reverseProxyDirectives);
    }
}

export default LocationDialog;