import * as React from "react";
import ScrollUpButton from "react-scroll-up-button";
const styles = require("./style.less");

interface ScrollUpButtonProps {
    StopPosition?: number;
    ShowAtPosition?: number;
    EasingType?: string;
    AnimationDuration?: string;
    ContainerClassName?: string;
    TransitionClassName?: string;
    style?: string;
    ToggledStyle?: string;
}

export default class ScrollToTop extends React.Component<ScrollUpButtonProps> {
    render() {
        return <ScrollUpButton  StopPosition={"0"}
                                ShowAtPosition={"150"}
                                EasingType="easeOutCubic"
                                AnimationDuration={"500"}
                                TransitionClassName={styles.ScrollUpButton__Toggled}
                                ContainerClassName={styles.ScrollUpButton__Container}
                                >
                   <span><i className="fa fa-chevron-up" /></span>
                </ScrollUpButton>;
    }
}