import * as React from "react";
import Lookup from "../Lookup/Lookup";
import NamedResource from "client/resources/namedResource";
import { MissingChip, ChipIcon} from "components/Chips";

interface LookupResourceChipProps<T extends NamedResource> {
    lookupCollection: T[];
    lookupId: string;
    type: ChipIcon;
    chipRender: (item: T) => JSX.Element;
}

type LookupResourceChip<T extends NamedResource> = React.StatelessComponent<LookupResourceChipProps<T>>;

export function LookupResourceChipComponent<T extends NamedResource>(): LookupResourceChip<T> {
    return (props) => {
        return <Lookup
            lookupCollection={props.lookupCollection}
            lookupId={props.lookupId}
            getIdFromElement={(element: NamedResource) => element.Id}
            render={item => props.chipRender(item)}
            renderFallback={<MissingChip lookupId={props.lookupId} type={props.type} />}
        />;
    };
}

export default LookupResourceChipComponent;