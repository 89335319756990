exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DialogActions_actions__1oT1D {\n  padding: 0.75rem 1rem;\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  flex-wrap: wrap;\n}\n.DialogActions_primaryActions__1i-Iz > * {\n  margin-left: 0.5rem;\n}\n.DialogActions_secondaryActions__1D1nP > * {\n  margin-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"actions": "DialogActions_actions__1oT1D",
	"primaryActions": "DialogActions_primaryActions__1i-Iz",
	"secondaryActions": "DialogActions_secondaryActions__1D1nP"
};