import * as React from "react";
import { ExpandableFormSection, RadioButton, StringRadioButtonGroup, Summary, TimePicker } from "components/form";
import { TriggerScheduleIntervalResource, TriggerScheduleIntervalType } from "client/resources";
const NumericInput = require("react-numeric-input");

interface ScheduleIntervalProps {
    interval: TriggerScheduleIntervalResource;
    onChange?(interval: TriggerScheduleIntervalResource): void;
}

interface ScheduleIntervalState {
    intervalType: TriggerScheduleIntervalType;
    intervalValue: number;
}

export default class ScheduleInterval extends React.Component<ScheduleIntervalProps, ScheduleIntervalState> {
    constructor(props: ScheduleIntervalProps) {
        super(props);

        this.state = this.initState(this.props.interval);
    }

    componentWillReceiveProps(newProps: ScheduleIntervalProps) {
        if (newProps.interval.Interval !== this.props.interval.Interval ||
            newProps.interval.HourInterval !== this.props.interval.HourInterval ||
            newProps.interval.MinuteInterval !== this.props.interval.MinuteInterval) {
            this.setState(this.initState(newProps.interval));
        }
    }

    render() {
        const inputStyle = {
            input: {
                border: "0",
                fontSize: 16,
                height: "100%",
                width: "100px"
            }
        };

        return (
            <ExpandableFormSection
                errorKey="ScheduleInterval"
                title="Interval"
                summary={this.getIntervalSummary()}
                help="Select at what interval the schedule should execute.">
                <StringRadioButtonGroup
                    value={this.state.intervalType}
                    onChange={this.updateIntervalType}>
                    {[
                        <RadioButton
                            value={TriggerScheduleIntervalType.OnceDaily}
                            label="Once"
                            key={TriggerScheduleIntervalType.OnceDaily}/>,
                        <RadioButton
                            value={TriggerScheduleIntervalType.OnceHourly}
                            label="Hour"
                            key={TriggerScheduleIntervalType.OnceHourly}/>,
                        <RadioButton
                            value={TriggerScheduleIntervalType.OnceEveryMinute}
                            label="Minute"
                            key={TriggerScheduleIntervalType.OnceEveryMinute}/>,
                    ]}
                </StringRadioButtonGroup>
                {(this.state.intervalType !== TriggerScheduleIntervalType.OnceDaily)
                    && <p>
                        <span>Every </span>
                        <NumericInput
                            strict={true}
                            onChange={this.updateIntervalValue}
                            value={this.state.intervalValue}
                            min={1}
                            max={this.getIntervalMaxValue()}
                            step={1}
                            size={1}
                            precision={0}
                            mobile={true}
                            style={inputStyle}/>
                        <span> {this.getIntervalDescription()}</span>
                </p>}
            </ExpandableFormSection>
        );
    }

    private initState(value?: TriggerScheduleIntervalResource) {
        const interval = value || new TriggerScheduleIntervalResource();
        return {
            intervalType: interval.Interval,
            intervalValue: interval.Interval === TriggerScheduleIntervalType.OnceHourly ? interval.HourInterval : interval.MinuteInterval
        };
    }

    private getIntervalSummary() {
        let summary = "";
        switch (this.props.interval.Interval) {
            case TriggerScheduleIntervalType.OnceDaily:
                summary = "Runs once";
                break;
            case TriggerScheduleIntervalType.OnceHourly:
                summary = `Runs every ${this.props.interval.HourInterval === 1 ? "hour" : `${this.props.interval.HourInterval} hours`}`;
                break;
            case TriggerScheduleIntervalType.OnceEveryMinute:
                summary = `Runs every ${this.props.interval.MinuteInterval === 1 ? "minute" : `${this.props.interval.MinuteInterval} minutes`}`;
                break;
            default:
                return Summary.placeholder("Please select the triggers schedule");
        }
        return Summary.summary(summary);
    }
    private getIntervalMaxValue() {
        return this.state.intervalType === TriggerScheduleIntervalType.OnceHourly
            ? 23
            : 59;
    }

    private getIntervalDescription() {
        const value = this.state.intervalType === TriggerScheduleIntervalType.OnceHourly
            ? "hour"
            : "minute";

        return this.state.intervalValue > 1
            ? `${value}s`
            : value;
    }

    private updateIntervalType = (type: TriggerScheduleIntervalType) => {
        const value = type === TriggerScheduleIntervalType.OnceDaily
            ? null
            : 1;
        this.setState({
            intervalType: type,
            intervalValue: value
        }, () => this.raiseChange());
    }

    private updateIntervalValue = (value: number) => {
        this.setState({
            intervalValue: value,
        }, () => this.raiseChange());
    }

    private raiseChange() {
        const newInterval = new TriggerScheduleIntervalResource();
        newInterval.Interval = this.state.intervalType;
        if (this.state.intervalType === TriggerScheduleIntervalType.OnceHourly) {
            newInterval.HourInterval = this.state.intervalValue;
        } else if (this.state.intervalType === TriggerScheduleIntervalType.OnceEveryMinute) {
            newInterval.MinuteInterval = this.state.intervalValue;
        }

        this.props.onChange(newInterval);
    }
}