import Client from "../client";
import ServerStatusResource from "../resources/serverStatusResource";
import ServerTimezoneResource from "../resources/serverTimezoneResource";
import {ExtensionsInfoResource} from "client/resources/extensionsInfoResource";
import {ActivityLogElement} from "client/resources/taskDetailsResource";
import {SystemInfoResource} from "client/resources/systemInfoResource";
import {ListArgs} from "client/repositories/basicRepository";
import {ServerStatusHealthResource} from "../resources/serverStatusHealthResource";

type LogsListArgs = {
    includeDetail: boolean;
} & ListArgs;

class ServerStatusRepository {
    private client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getServerStatus() {
        return this.client.get<ServerStatusResource>(this.client.getLink("ServerStatus"));
    }
    getLogs(status: ServerStatusResource, args?: LogsListArgs) {
        return this.client.get<ActivityLogElement[]>(status.Links["RecentLogs"], args);
    }

    getHealth(status: ServerStatusResource) {
        return this.client.get<ServerStatusHealthResource>(status.Links["Health"]);
    }

    getSystemInfo(status: ServerStatusResource) {
        return this.client.get<SystemInfoResource>(status.Links["SystemInfo"]);
    }
    gcCollect(status: ServerStatusResource) {
        return this.client.post(status.Links["GCCollect"], status);
    }
    getExtensionStats() {
        return this.client.get<ExtensionsInfoResource[]>(this.client.getLink("ExtensionStats"));
    }
    getTimezones() {
        return this.client.get<ServerTimezoneResource[]>(this.client.getLink("Timezones"));
    }
}

export default ServerStatusRepository;