import * as React from "react";
import Lookup from "../Lookup/Lookup";
import ReferenceDataItem from "client/resources/referenceDataItem";
import { MissingChip, ChipIcon} from "components/Chips";

interface LookupReferenceDataItemChipProps {
    lookupCollection: ReferenceDataItem[];
    lookupId: string;
    type: ChipIcon;
    chipRender: (item: ReferenceDataItem) => JSX.Element;
    allowMissingItem?: boolean;
}

const LookupReferenceDataItemChip: React.StatelessComponent<LookupReferenceDataItemChipProps> = props => {
    return <Lookup
        lookupCollection={props.lookupCollection}
        lookupId={props.lookupId}
        allowMissingItem={props.allowMissingItem}
        getIdFromElement={(element: ReferenceDataItem) => element.Id}
        render={(item: ReferenceDataItem) => props.chipRender(item)}
        renderFallback={<MissingChip lookupId={props.lookupId} type={props.type} />}
    />;
};

export default LookupReferenceDataItemChip;