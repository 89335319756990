import * as React from "react";
import StringHelper from "utils/StringHelper";
import ToolTip from "components/ToolTip/index";
import WarningIcon from "components/WarningIcon";
const styles = require("./style.less");

interface ToolTipWarningProps {
    warningMessages?: ReadonlyArray<string>;
    onClick?: () => void;
}

const ToolTipMessages: React.StatelessComponent<ToolTipWarningProps> = props => {

    const buildCompositeMessageFromItems = (items: ReadonlyArray<string>): string => {
        return items.map((text, index) => {
            if (index === 0) {
                return StringHelper.capitalizeFirstLetter(text);
            }
            return text;
        }).join(" and ");
    };

    const warningsDiv = props.warningMessages && props.warningMessages.length > 0 &&
        <div onClick={() => props.onClick && props.onClick()}>
            <ToolTip content={buildCompositeMessageFromItems(props.warningMessages)}>
                <WarningIcon />
            </ToolTip>
        </div>;

    return <div className={styles.container}>{warningsDiv}</div>;
};

export default ToolTipMessages;