import * as React from "react";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { MachineChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import LookupReferenceDataItemChip from "components/LookupReferenceDataItemChip";
import { ChipIcon } from "components/Chips";

interface MachineMultiSelectProps extends FormFieldProps<string[]> {
    items: ReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

const MachineTypedMultiSelect = MultiSelect<ReferenceDataItem>();

const MachineMultiSelect: React.StatelessComponent<MachineMultiSelectProps> = props => {
    const chipRenderer = (r: ReferenceDataItem, onRequestDelete: (event: object) => void) => {
        return <LookupReferenceDataItemChip
            lookupCollection={props.items}
            lookupId={r.Id}
            type={ChipIcon.Machine}
            chipRender={item => <MachineChip
                onRequestDelete={onRequestDelete}
                machineName={item.Name}
            />}
        />;
    };

    return <MachineTypedMultiSelect
        items={props.items}
        fieldName="targets"
        renderChip={chipRenderer}
        {...props} />;
};

export default MachineMultiSelect;