import * as React from "react";
import Summary from "../../../../../components/form/Sections/Summary";
import ExpandableFormSection from "../../../../../components/form/Sections/ExpandableFormSection";
import FormResourceInput from "../../../../../components/FormResourceInput/FormResourceInput";
import Form from "../../../../../client/resources/form";

interface PromptVariablesProps {
    form: Form;
    onParameterChanged(info: any): any;
}

const PromptVariables: React.StatelessComponent<PromptVariablesProps> = (props) => {

    return <div>
            {props.form && props.form.Elements.length > 0 &&
                <ExpandableFormSection
                    title="Parameters"
                    errorKey="parameters"
                    isExpandedByDefault={true}
                    help="Parameters will apply to all deployments"
                    summary={buildSummary()}>
                    <div>
                        {props.form.Elements.map((element, idx) => <FormResourceInput
                            key={idx}
                            element={element}
                            onButtonSelected={() => console.error("Buttons not expected here")}
                            onChanged={(value) => props.onParameterChanged({VariableName: element.Name, Value: value})}
                            value={props.form.Values[element.Name]}/>)
                        }
                    </div>
                </ExpandableFormSection>
            }
        </div>;

    function buildSummary() {
        const numOfParams = props.form.Elements ? props.form.Elements.length : 0;
        const count = props.form.Elements.filter(e => props.form.Values[e.Name]).length;
        return count > 0
            ? Summary.summary(<div>{numOfParams} {numOfParams > 1 ? "parameters" : "parameter"} in total, {count} provided.</div>)
            : Summary.placeholder("Enter the parameter values");
    }
};
export default PromptVariables;