import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import CodeEditor, {TextFormat} from "components/CodeEditor/CodeEditor";
import Note from "components/form/Note/Note";
const styles = require("./style.less");
import {repository} from "clientInstance";
import {ActionTemplateResource} from "client/resources/actionTemplateResource";

interface ImportActionTemplateDialogProps {
    onSaveDone(template: ActionTemplateResource): void;
}

interface ImportActionTemplateDialogState extends DataBaseComponentState {
    content?: string;
}

export default class ImportActionTemplateDialog extends DataBaseComponent<ImportActionTemplateDialogProps, ImportActionTemplateDialogState> {
    constructor(props: ImportActionTemplateDialogProps) {
        super(props);

        this.state = {
            content: null
        };
    }

    async save() {
        try {
            const data = JSON.parse(this.state.content);

            if (!data || !data.$Meta || data.$Meta.Type !== "ActionTemplate") {
                this.setError("The data is not a valid step template.");
                return false;
            }

            delete data.Id;
            delete data.$Meta;

            return this.doBusyTask(async () => {
                const template = await repository.ActionTemplates.create(data);
                await this.props.onSaveDone(template);
            });

        } catch (ex) {
            this.setError(ex.message);
            return false;
        }
    }

    render() {
        return <SaveDialogLayout title="Import"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            <CodeEditor value={this.state.content}
                        language={TextFormat.JSON}
                        onChange={value => this.setState({content: value})} />
            <div className={styles.importNote}>
                <Note>Paste the entire exported JSON object.</Note>
            </div>
        </SaveDialogLayout>;
    }
}
