import * as React from "react";
import ActionButton, { ActionButtonType, IconPosition } from "components/Button/ActionButton";

import { RaisedButton, Popover, Menu, MenuItem } from "material-ui";
import * as colors from "colors";
import InternalLink from "components/Navigation/InternalLink";
import { noOp } from "utils/noOp";
import * as cn from "classnames";

interface MenuItemProps {
    onClick?: () => void;
    style?: any;
}

interface DropdownButtonProps {
    style?: any;
    className?: string;
    open?: boolean;
    label: string;
    icon?: React.ReactElement<any>;
    type?: DropdownButtonType;
    renderMenu: (props: RenderMenuProps) => React.ReactNode;
}

interface RenderMenuProps {
    getMenuItemProps: <T>(props: T & MenuItemProps) => T & MenuItemProps;
}

interface DropdownButtonState {
    open: boolean;
    anchor: any;
}

export const DropdownButtonMenu: React.SFC<{}> = ({ children }) => {
    return (
        <Menu maxHeight={16 * 40}>
            {children}
        </Menu>
    );
};

function assertUnreachable(x: never): never {
    throw new Error("Didn't expect to get here");
}

type DropdownButtonType = ActionButtonType;

interface DropdownButtonMenuItemProps {
    label: string;
    className?: string;
    onClick?: () => void;
}

export const DropdownButtonMenuItem: React.SFC<DropdownButtonMenuItemProps> = ({label, ...rest}) => (
    <MenuItem {...rest} primaryText={label} />
);

export class DropdownButton extends React.Component<DropdownButtonProps, DropdownButtonState> {
    buttonElement: any;

    constructor(props: DropdownButtonProps) {
        super(props);
        this.state = {
            open: false,
            anchor: null
        };
    }

    render() {
        const {
            icon = (<i className="fa fa-caret-down" style={{ color: colors.white }} />)
        } = this.props;
        const type = this.getType(this.props.type);

        return (
            <ActionButton
                style={this.props.style}
                className={this.props.className}
                type={type}
                icon={icon}
                iconPosition={IconPosition.Right}
                label={this.props.label}
                onClick={this.handleTouchTap}>
                <Popover
                    style={this.getColors(type)}
                    canAutoPosition={false}
                    onRequestClose={this.handleRequestClose}
                    anchorEl={this.state.anchor}
                    open={this.state.open}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    targetOrigin={{ horizontal: "right", vertical: "top" }}>
                        {this.props.renderMenu({
                            getMenuItemProps: this.getMenuItemProps
                        })}
                </Popover>
            </ActionButton>
        );
    }

    private handleTouchTap = (event: any) => {
        event.preventDefault();
        this.setState({
            open: true,
            anchor: event.currentTarget
        });
    }

    private handleRequestClose = () => {
        this.setState({
            open: false
        });
    }

    private getMenuItemProps = <T extends {}>(props: MenuItemProps & T): MenuItemProps & T => {
        const {
            onClick,
            style = {},
            ...rest
        } = props as any;

        const type = this.getType(this.props.type);

        return {
            onClick: () => {
                if (onClick) {
                    onClick();
                }
                this.handleRequestClose();
            },
            style: { ...this.getColors(type), ...style },
            ...rest
        };
    }

    private getType(type?: ActionButtonType) {
        return type || ActionButtonType.Primary;
    }

    private getColors(type: DropdownButtonType) {
        switch (type) {
            case ActionButtonType.Primary:
                return { color: colors.white, backgroundColor: colors.success };
            case ActionButtonType.Secondary:
                return { color: colors.white, backgroundColor: colors.secondaryBackground };
            case ActionButtonType.Ternary:
                return { };
            case ActionButtonType.Save:
                return { color: colors.white, backgroundColor: colors.success };
            case ActionButtonType.Delete:
                return { color: colors.white, backgroundColor: colors.danger };
            case ActionButtonType.Category:
                return { backgroundColor: "#e9edf2" };
            case ActionButtonType.CreateRelease:
                return  { color: colors.white, backgroundColor: colors.secondaryDark};
        }

        return assertUnreachable(type);
    }
}

export default DropdownButton;