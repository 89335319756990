import * as React from "react";
import { Callout, CalloutType } from "components/Callout/Callout";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import PaperLayout from "components/PaperLayout/PaperLayout";
import { Section } from "components/Section/Section";
import SectionNote from "components/SectionNote/SectionNote";

const Backup: React.StatelessComponent<{}> = props => <PaperLayout title="Backup">
    <SectionNote>
        Your Octopus database can be backed up by configuring backups for SQL Server and the file system.
        For more information, please see the <ExternalLink href="BackupRestore">documentation
        on backups and restores</ExternalLink>.
    </SectionNote>
    <Section>
        <Callout type={CalloutType.Warning} title="Master Key">
            Certain data in your Octopus Deploy database, such as sensitive variables and passwords for NuGet
            feeds, is encrypted. You won't be able to restore this data without a <ExternalLink href="SaveMyMasterKey">copy of your Octopus master key</ExternalLink>
        </Callout>
    </Section>
</PaperLayout>;

export default Backup;