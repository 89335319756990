import * as React from "react";
import { CategoryDefinition } from "../Registry/packagingRegistry";

const category: CategoryDefinition = {
    category: "Other",
    title: <React.Fragment>How would you like to package this <strong>Other</strong> application?</React.Fragment>,
    help: null,
    displayOrder: 60,
 };

export default category;
