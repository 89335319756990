import * as React from "react";
import { ProjectResource } from "client/resources";
import { repository } from "clientInstance";
import Note from "components/form/Note/Note";
import ExpandableFormSection, { SummaryNode } from "components/form/Sections/ExpandableFormSection";
import { VariableLookupText } from "components/form/VariableLookupText";
import Summary from "components/form/Sections/Summary";
import { BoundStringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import { BoundCertificateVariableSelect } from "components/form/CertificateSelect/CertificateVariableSelect";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";
import { AzureServiceFabricSecurityMode, AADCredentialType } from "../../../client/resources/endpointResource";

export interface AzureServiceFabricAccountSelectorProperties {
    "Octopus.Action.ServiceFabric.IsLegacyMode": string;
    "Octopus.Action.ServiceFabric.ConnectionEndpoint": string;
    "Octopus.Action.ServiceFabric.SecurityMode": string;
    "Octopus.Action.ServiceFabric.ServerCertThumbprint": string;
    "Octopus.Action.ServiceFabric.ClientCertVariable": string;
    "Octopus.Action.ServiceFabric.AadCredentialType": string;
    "Octopus.Action.ServiceFabric.AadClientCredentialSecret": string;
    "Octopus.Action.ServiceFabric.AadUserCredentialUsername": string;
    "Octopus.Action.ServiceFabric.AadUserCredentialPassword": string;
}

interface AzureServiceFabricAccountSelectorProps {
    projectId: string;
    localNames: string[];
    properties: AzureServiceFabricAccountSelectorProperties;
    clientCertVariableResetValue: string;
    expandedByDefault: boolean;
    setProperties(properties: Partial<AzureServiceFabricAccountSelectorProperties>): void;
    getFieldError(field: string): string;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
}

interface AzureServiceFabricAccountSelectorState {
    project: ProjectResource;
}

export default class AzureServiceFabricAccountSelector extends React.Component<AzureServiceFabricAccountSelectorProps, AzureServiceFabricAccountSelectorState> {

    constructor(props: AzureServiceFabricAccountSelectorProps) {
        super(props);
        this.state = {
            project: null
        };
    }

    componentDidMount() {
        this.props.doBusyTask(async () => {
            const project = this.props.projectId ? await repository.Projects.get(this.props.projectId) : null;
            this.setState({
                project
            });
        });
    }

    render() {
        const properties = this.props.properties;
        const securityModeErrorKey = "Octopus.Action.ServiceFabric.SecurityMode|"
            + "Octopus.Action.ServiceFabric.ServerCertThumbprint|"
            + "Octopus.Action.ServiceFabric.ClientCertVariable|"
            + "Octopus.Action.ServiceFabric.AadClientCredentialSecret";

        return <div>
            <ExpandableFormSection
                errorKey="Octopus.Action.ServiceFabric.ConnectionEndpoint"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Connection Endpoint"
                summary={this.endpointSummary()}
                help={"Enter the connection endpoint of your Service Fabric cluster."}>
                <VariableLookupText
                    localNames={this.props.localNames}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"]}
                    onChange={(x) => {
                        this.props.setProperties({ ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: x });
                        if (!x) {
                            // This is a key field in determining legacy Azure steps, so if this is cleared, also
                            // clear related data that is now contributed by the target.
                            this.props.setProperties({
                                ["Octopus.Action.ServiceFabric.IsLegacyMode"]: null,
                                ["Octopus.Action.ServiceFabric.ConnectionEndpoint"]: null,
                                ["Octopus.Action.ServiceFabric.SecurityMode"]: null,
                                ["Octopus.Action.ServiceFabric.ServerCertThumbprint"]: null,
                                ["Octopus.Action.ServiceFabric.ClientCertVariable"]: null,
                                ["Octopus.Action.ServiceFabric.AadCredentialType"]: null,
                                ["Octopus.Action.ServiceFabric.AadClientCredentialSecret"]: null,
                                ["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]: null,
                                ["Octopus.Action.ServiceFabric.AadUserCredentialPassword"]: null,
                            });
                        }
                    }}
                    error={this.props.getFieldError("Octopus.Action.ServiceFabric.ConnectionEndpoint")}
                    label="Connection endpoint" />
            </ExpandableFormSection>

            <ExpandableFormSection
                errorKey={securityModeErrorKey}
                isExpandedByDefault={this.props.expandedByDefault}
                title="Security Mode"
                summary={this.securityModeSummary()}
                help={"Select the security mode use to connect to your Service Fabric cluster."}>
                <BoundStringRadioButtonGroup
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={AzureServiceFabricSecurityMode.Unsecure}
                    value={properties["Octopus.Action.ServiceFabric.SecurityMode"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.SecurityMode"]: x })}
                    label="Security mode">
                    <RadioButton value={AzureServiceFabricSecurityMode.Unsecure} label="Unsecure" isDefault />
                    <RadioButton value={AzureServiceFabricSecurityMode.SecureClientCertificate} label="Secure Client Certificate" />
                    <Note>To learn about client certificate authentication, please see the <ExternalLink href="ServiceFabricDeployingAppsAuthClientCerts">Connecting
                        Securely with Client Certificates documentation</ExternalLink>.</Note>
                    <RadioButton value={AzureServiceFabricSecurityMode.SecureAzureAD} label="Secure Azure Active Directory (AAD)" />
                    <Note>To learn about AAD authentication, please see the <ExternalLink href="ServiceFabricDeployingAppsAuthAzureAd">Connecting Securely with
                        Azure Active Directory documentation</ExternalLink>.</Note>
                </BoundStringRadioButtonGroup>

                {(properties["Octopus.Action.ServiceFabric.SecurityMode"] !== AzureServiceFabricSecurityMode.Unsecure) && <div>
                    <VariableLookupText
                        localNames={this.props.localNames}
                        projectId={this.props.projectId}
                        value={properties["Octopus.Action.ServiceFabric.ServerCertThumbprint"]}
                        onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.ServerCertThumbprint"]: x })}
                        error={this.props.getFieldError("Octopus.Action.ServiceFabric.ServerCertThumbprint")}
                        label="Server certificate thumbprint" />
                    <Note>The server certificate thumbprint used to communicate with the secure cluster.</Note>
                </div>}

                {(properties["Octopus.Action.ServiceFabric.SecurityMode"] === AzureServiceFabricSecurityMode.SecureClientCertificate) && <div>
                    {this.state.project
                        ? <BoundCertificateVariableSelect
                            variableLookup={{
                                localNames: this.props.localNames,
                                projectId: this.props.projectId
                            }}
                            resetValue={this.props.clientCertVariableResetValue}
                            projectId={this.props.projectId}
                            doBusyTask={this.props.doBusyTask}
                            value={properties["Octopus.Action.ServiceFabric.ClientCertVariable"]}
                            error={this.props.getFieldError("Octopus.Action.ServiceFabric.ClientCertVariable")}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.ClientCertVariable"]: x })} />
                        : <VariableLookupText
                            localNames={this.props.localNames}
                            projectId={this.props.projectId}
                            value={properties["Octopus.Action.ServiceFabric.ClientCertVariable"]}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.ClientCertVariable"]: x })}
                            error={this.props.getFieldError("Octopus.Action.ServiceFabric.ClientCertVariable")}
                            label="Client certificate" />
                    }
                    <Note>The client certificate used to communicate with the secure cluster.</Note>
                </div>}

                {(properties["Octopus.Action.ServiceFabric.SecurityMode"] === AzureServiceFabricSecurityMode.SecureAzureAD) && <div>
                    {/*NOTE: mark.siedle - ClientCredential is not currently supported on Azure. Uncomment this when they do add support for it.*/}
                    {false && <div>
                        <BoundStringRadioButtonGroup
                            variableLookup={{
                                localNames: this.props.localNames,
                                projectId: this.props.projectId
                            }}
                            resetValue={AADCredentialType.ClientCredential}
                            value={properties["Octopus.Action.ServiceFabric.AadCredentialType"]}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.AadCredentialType"]: x })}
                            label="Credential type">
                            <RadioButton value={AADCredentialType.ClientCredential} label="Client credential" isDefault />
                            <RadioButton value={AADCredentialType.UserCredential} label="User credential" />
                        </BoundStringRadioButtonGroup>
                        <Note>The credential type to use for AAD authentication.</Note>
                    </div>}

                    {(properties["Octopus.Action.ServiceFabric.AadCredentialType"] === AADCredentialType.ClientCredential) && <div>
                        <VariableLookupText
                            localNames={this.props.localNames}
                            projectId={this.props.projectId}
                            value={properties["Octopus.Action.ServiceFabric.AadClientCredentialSecret"]}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.AadClientCredentialSecret"]: x })}
                            error={this.props.getFieldError("Octopus.Action.ServiceFabric.AadClientCredentialSecret")}
                            label="Client application secret" />
                        <Note>The client application secret used to communicate with the secure cluster.</Note>
                    </div>}

                    {(properties["Octopus.Action.ServiceFabric.AadCredentialType"] === AADCredentialType.UserCredential) && <div>
                        <VariableLookupText
                            localNames={this.props.localNames}
                            projectId={this.props.projectId}
                            value={properties["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]: x })}
                            error={this.props.getFieldError("Octopus.Action.ServiceFabric.AadUserCredentialUsername")}
                            label="Username" />
                        <Note>The Azure AD user's username used to communicate with the secure cluster.</Note>
                        <BoundSensitive
                            variableLookup={{
                                localNames: this.props.localNames,
                                projectId: this.props.projectId
                            }}
                            resetValue={""}
                            value={properties["Octopus.Action.ServiceFabric.AadUserCredentialPassword"] as any}
                            onChange={(x) => this.props.setProperties({ ["Octopus.Action.ServiceFabric.AadUserCredentialPassword"]: x as any })}
                            error={this.props.getFieldError("Octopus.Action.ServiceFabric.AadUserCredentialPassword")}
                            label="Password" />
                        <Note>The Azure AD user's password used to communicate with the secure cluster.</Note>
                    </div>}
                </div>}

            </ExpandableFormSection>
        </div>;
    }

    private endpointSummary(): SummaryNode {
        if (this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"]) {
            return Summary.summary(this.props.properties["Octopus.Action.ServiceFabric.ConnectionEndpoint"]);
        }
        return Summary.placeholder("The connection endpoint has not been set");
    }

    private securityModeSummary(): SummaryNode {
        if (this.props.properties["Octopus.Action.ServiceFabric.SecurityMode"] === AzureServiceFabricSecurityMode.Unsecure) {
            return Summary.default("Unsecure");
        }
        const summary = [];
        const thumbprint = this.props.properties["Octopus.Action.ServiceFabric.ServerCertThumbprint"];
        if (this.props.properties["Octopus.Action.ServiceFabric.SecurityMode"] === AzureServiceFabricSecurityMode.SecureClientCertificate) {
            summary.push(<span>Security mode is <strong>Client Certificate</strong></span>);
            this.contributeCertificateThumbprintSummary(thumbprint, summary);
            if (!this.props.properties["Octopus.Action.ServiceFabric.ClientCertVariable"]) {
                summary.push(<span>,  the client certificate has <strong>not</strong> been set</span>);
            } else {
                summary.push(<span>,  the client certificate has been set</span>);
            }
            return Summary.summary(React.Children.toArray(summary));
        }
        if (this.props.properties["Octopus.Action.ServiceFabric.SecurityMode"] === AzureServiceFabricSecurityMode.SecureAzureAD) {
            summary.push(<span>Security mode is <strong>Azure Active Directory</strong></span>);
            this.contributeCertificateThumbprintSummary(thumbprint, summary);
            if (!this.props.properties["Octopus.Action.ServiceFabric.AadUserCredentialUsername"]) {
                summary.push(<span>,  the AAD credentials have <strong>not</strong> been set</span>);
            } else {
                summary.push(<span>,  the AAD credentials have been set</span>);
            }
            return Summary.summary(React.Children.toArray(summary));
        }
        return Summary.placeholder("Security mode has not been set");
    }

    private contributeCertificateThumbprintSummary(thumbprint: string, summary: any[]) {
        if (!thumbprint) {
            summary.push(<span>,  the certificate thumbprint has <strong>not</strong> been set</span>);
        } else {
            summary.push(<span>,  the certificate thumbprint has been set</span>);
        }
    }
}