import * as React from "react";
import {SFC} from "react";
const styles = require("./style.less");

const StepName: SFC<{name: string,  number: string}> = props =>
    /* mark.siedle - This is an exception to the usual ellipsis pattern because of the StepDetailsLoader. */
    <span><span className={styles.stepNumber}>{props.number}.</span>{props.name || "Step Details"}</span>;

StepName.displayName = "StepName";

export default StepName;