exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_sortableItem__1BSoO {\n  border: 0.0625rem solid #cccccc;\n  padding: 0.5rem 1rem;\n  margin-bottom: 0.5rem;\n  background-color: rgba(153, 153, 153, 0.08);\n  cursor: move;\n  color: black;\n}\n.style_name__-RgrS {\n  padding-left: 1rem;\n}\n", ""]);

// exports
exports.locals = {
	"sortableItem": "style_sortableItem__1BSoO",
	"name": "style_name__-RgrS"
};