export function pageId(id: string, name?: string): IPageWrapper {
    return {
        Id: id,
        Name: name ? name : id,
    };
}

// This helps us identify each page in a human-friendly way for our reporting/customer-feedback tools.
export default interface IPageWrapper {
    Id: string;   // Short ID representing a given page. E.g. LibraryTenantTagSetCreate"
    Name: string; // Human-readable name describing the page. E.g. "Add Tenant Tag Set"
}
