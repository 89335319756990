import * as React from "react";
import {RouteComponentProps} from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import PaperLayout from "components/PaperLayout";
import {ActionButton, ActionButtonType} from "components/Button";
import {repository} from "clientInstance";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FeedResource, {FeedType} from "../../../../client/resources/feedResource";
import {
    Text,
    Note,
} from "components/form";
import SimpleDataTable from "components/SimpleDataTable";
import PackageResource, {PackageDescriptionResource} from "../../../../client/resources/packageResource";
import {Section} from "components/Section/Section";
const styles = require("./feedteststyle.less");
import InputWithActions from "components/InputWithActions/InputWithActions";
import {DataBaseComponent, DataBaseComponentState} from "../../../../components/DataBaseComponent";

interface ExternalFeedTestParams {
    feedId: string;
}

class PackageTable extends SimpleDataTable<PackageDescriptionResource> {}

interface ExternalFeedTestState extends DataBaseComponentState {
    feed: FeedResource;
    searchTerm: string;
    searchResult: PackageDescriptionResource[];
}

export default class ExternalFeedTest extends DataBaseComponent<RouteComponentProps<ExternalFeedTestParams>, ExternalFeedTestState> {
    constructor(props: RouteComponentProps<ExternalFeedTestParams>) {
        super(props);
        this.state = {
            feed: null,
            searchTerm: "",
            searchResult: null
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const feed = await repository.Feeds.get(this.props.match.params.feedId);
            this.setState({feed});
        });
    }

    render() {
        const hasVersion = this.state.feed && ![FeedType.Docker, FeedType.AwsElasticContainerRegistry].includes(this.state.feed.FeedType);
        const columns = hasVersion ? ["Id", "Version", "Description"] : ["Id", "Description"];

        return <LibraryLayout {...this.props}>
            <PaperLayout
                title={this.state.feed ? "Test " + this.state.feed.Name : "Test external feed"}
                busy={this.state.busy}
                errors={this.state.errors}>
                {this.state.feed && <div>
                    <Section>
                        <p>Test the {this.state.feed.Name} repository using the search below. Up to ten results will be shown.</p>
                        <form onSubmit={this.handleSearchClick}>
                            <InputWithActions input={<Text value={this.state.searchTerm}
                                                           onChange={searchTerm => this.setState({searchTerm})}
                                                           autoFocus
                                                           label="Package name" />}
                                              actions={<ActionButton type={ActionButtonType.Primary}
                                                                     label="SEARCH"
                                                                     busyLabel="SEARCHING..."
                                                                     onClick={this.handleSearchClick}/>}
                            />
                        </form>
                        <Note>A package name to search for. Note that names on local folders and file shares are case-sensitive.</Note>
                    </Section>
                    {this.state.searchResult && <Section>
                        <h3>Matching packages</h3>
                        <PackageTable
                            data={this.state.searchResult}
                            headerColumns={columns}
                            headerColumnClassNames={[styles.headerColumn, styles.headerColumn, styles.headerColumn]}
                            rowColumnClassName={styles.rowColumn}
                            onRow={res => this.buildResultRow(res, hasVersion)} />
                    </Section>}
                </div>}
            </PaperLayout>
        </LibraryLayout>;
    }

    private handleSearchClick = async (e: any) => {
        e.preventDefault();
        if (this.state.searchTerm === "") {
            this.setError("Please enter a search term");
            return;
        }

        await this.doBusyTask(async () => {
            this.setState({searchResult: null});
            const result = await repository.Feeds.searchPackages(this.state.feed, {
                term: this.state.searchTerm,
                take: 10
            });

            this.setState({searchResult: result.Items});
        });
    }

    private buildResultRow = (packageResource: PackageDescriptionResource, hasVersion: boolean) => {
        return hasVersion
            ? [ packageResource.Id,
                packageResource.LatestVersion,
                packageResource.Description || packageResource.Name ]
            : [ packageResource.Id,
                packageResource.Description || packageResource.Name ];
    }
}
