import * as React from "react";
import {repository} from "clientInstance";
import SortableList from "components/SortableList/SortableList";
import {WorkerPoolResource} from "client/resources";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";

interface WorkerPoolSorterFormProps {
    saveDone(): void;
}

interface WorkerPoolSorterFormState extends DataBaseComponentState {
    workerPools: WorkerPoolResource[];
}

class WorkerPoolsSorter extends DataBaseComponent<WorkerPoolSorterFormProps, WorkerPoolSorterFormState> {
    constructor(props: WorkerPoolSorterFormProps) {
        super(props);

        this.state = {
            workerPools: null
        };
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const workerPools = await repository.WorkerPools.all();
            this.setState({workerPools});
        });
    }

    async save() {
        const workerPoolIdsSorted = this.state.workerPools.map(workerPool => {
            return workerPool.Id;
        });
        return this.doBusyTask(async () => {
            await repository.WorkerPools.sort(workerPoolIdsSorted);
            this.props.saveDone();
        });
    }

    render() {
        return <SaveDialogLayout title="Reorder Worker Pools"
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 onSaveClick={() => this.save()}>
            {this.state.workerPools &&
            <SortableList items={this.state.workerPools}
                onOrderChanged={(x: any) => this.setState({workerPools: x})}/>}
        </SaveDialogLayout>;
    }
}

export default WorkerPoolsSorter;