exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_alternativeGroup__2jW9_ {\n  background-color: rgba(153, 153, 153, 0.08);\n}\n", ""]);

// exports
exports.locals = {
	"alternativeGroup": "style_alternativeGroup__2jW9_"
};