exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_filterBox__aMSAr {\n  margin-left: 1rem;\n  margin-top: 2.6rem;\n}\n.style_filter__1ley2 {\n  width: 50rem;\n  align-items: flex-end;\n}\n.style_row__f-cUy {\n  display: flex;\n  flex-direction: row;\n}\n.style_logo__3sLhV {\n  vertical-align: middle;\n  justify-content: center;\n  margin-right: 1rem;\n  width: 50px;\n  height: 50px;\n}\n.style_linkWithCopy__17_U4 {\n  display: inline-flex;\n  flex-direction: row;\n}\n.style_linkWithCopy__17_U4 .style_copyAction__27B5R {\n  margin: -1rem 0;\n}\n.style_spaceName__2lvlo {\n  font-weight: 500;\n}\n.style_addTeamInstructions__1Mk09 {\n  margin-bottom: 0.1875rem;\n}\n.style_addTeamInstructionsList__32wwt {\n  list-style-type: decimal;\n  margin: 0;\n  padding-left: 1.5625rem;\n}\n.style_spaceLimitHit__1at8a {\n  text-align: center;\n}\n.style_spaceManagersCalloutContainer__1KlBl {\n  margin-bottom: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"filterBox": "style_filterBox__aMSAr",
	"filter": "style_filter__1ley2",
	"row": "style_row__f-cUy",
	"logo": "style_logo__3sLhV",
	"linkWithCopy": "style_linkWithCopy__17_U4",
	"copyAction": "style_copyAction__27B5R",
	"spaceName": "style_spaceName__2lvlo",
	"addTeamInstructions": "style_addTeamInstructions__1Mk09",
	"addTeamInstructionsList": "style_addTeamInstructionsList__32wwt",
	"spaceLimitHit": "style_spaceLimitHit__1at8a",
	"spaceManagersCalloutContainer": "style_spaceManagersCalloutContainer__1KlBl"
};