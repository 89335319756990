exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_disabled__3WLiq * {\n  color: #9e9e9e;\n}\n.style_disabled__3WLiq * svg {\n  opacity: .5;\n}\n", ""]);

// exports
exports.locals = {
	"disabled": "style_disabled__3WLiq"
};