exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_clickableTag__2VYzM > div:first-child:after {\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  background: rgba(255, 255, 255, 0.25);\n  opacity: 0;\n  transition: all 0.5s;\n  -webkit-transition: all 0.5s;\n  -moz-transition: all 0.5s;\n  border-radius: 1rem;\n  z-index: 0;\n}\n.styles_clickableTag__2VYzM > div:first-child:hover:after {\n  opacity: 1;\n}\n.styles_clickableTag__2VYzM > div span {\n  z-index: 1;\n}\n", ""]);

// exports
exports.locals = {
	"clickableTag": "styles_clickableTag__2VYzM"
};