import * as React from "react";
import {keyBy} from "lodash";

import {
    ChannelResource,
    DashboardResource,
    TenantResource,
    ReleaseResource,
    DeploymentTemplateResource,
    TagSetResource,
    DashboardItemResource,
    DashboardProjectResource,
    DashboardProjectGroupResource,
    DashboardTenantResource,
    ReferenceDataItem
} from "client/resources";

import { DashboardFilters, DataCube} from "./DataCube";
import {ProjectResource} from "../../../../client/resources/projectResource";
import {TenantedDeploymentMode} from "../../../../client/resources/tenantedDeploymentMode";
import {LifecycleResource} from "../../../../client/resources/lifecycleResource";

class DashboardDataCube implements DataCube {
    data: DashboardResource;
    blockedReleases: string[] = [];
    nextAvailableDeployments: {[releaseId: string]: { [environmentId: string]: string[] }} = {};
    lifecycleIndex: {[lifecycleId: string]: LifecycleResource};
    tagSetIndex: {[canonicalTagName: string]: TagSetResource} = {};
    environmentIndex: { [environmentId: string]: ReferenceDataItem } = {};
    channelIndex: {[channelId: string]: ChannelResource } = {};
    releaseIndex: {[releaseId: string]: ReleaseResource } = {};
    tenantIndex: { [tenantId: string]: DashboardTenantResource } = {};
    tenantTagIndex: { [tenantId: string]: string[] } = {};
    projectIndex: { [projectId: string]: DashboardProjectResource } = {};
    projectGroupIndex: { [projectGroupId: string]: DashboardProjectGroupResource } = {};
    missingVariableTenants: string[] = [];
    flattened: DashboardItemResource[];
    deployments: DashboardItemResource[];
    channelEnvironments: { [index: string]: string[] };

    constructor(data: DashboardResource,
                tenants: TenantResource[] | DashboardTenantResource[],
                releases: ReleaseResource[],
                channels: ChannelResource[],
                promotions: DeploymentTemplateResource,
                tagSets: TagSetResource[],
                lifeCycles: LifecycleResource[],
                project?: ProjectResource,
                releaseId?: string,
                missingVariableTenants?: string[]) {
        this.data = data;
        this.releaseIndex = keyBy(releases, (rel) => rel.Id);
        this.projectIndex = keyBy(data.Projects, (proj) => proj.Id);
        this.channelIndex = keyBy(channels, (ch) => ch.Id);
        this.environmentIndex = keyBy(this.data.Environments, (env) => env.Id);
        this.deployments =  data.Items;
        this.missingVariableTenants = missingVariableTenants;
        if (project) {
            this.projectIndex[project.Id] = {
                Slug: project.Slug,
                Name: project.Name,
                IsDisabled: project.IsDisabled,
                Id: project.Id,
                Links: project.Links,
                EnvironmentIds: null,
                ProjectGroupId: project.ProjectGroupId,
                CanPerformUntenantedDeployment: project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted
            };

            this.lifecycleIndex = keyBy(lifeCycles, (ch) => ch.Id);

            // We wont have a project object for main dashboard,
            // but then again we probably wont be grouping by channel or needing lifecycles!
            this.channelEnvironments = channels.reduce((b: {[channelId: string]: string[]}, ch) => {
                let lifecycle = this.lifecycleIndex[ch.LifecycleId];
                if (!lifecycle) {
                    lifecycle = this.lifecycleIndex[project.LifecycleId];
                }
                if (!lifecycle) {
                    b[ch.Id] = [];
                } else {
                    b[ch.Id] = lifecycle.Phases.reduce((a: string[], p) => {
                        return a.concat(p.AutomaticDeploymentTargets).concat(p.OptionalDeploymentTargets);
                    }, []);
                    if (b[ch.Id].length === 0) { // No environments selected, by default all environments are then used
                        b[ch.Id] = Object.keys(this.environmentIndex);
                    }
                }
                return b;
            }, {});
        }

        this.projectGroupIndex = keyBy(data.ProjectGroups, pj => pj.Id);

        if (releaseId) {
            this.nextAvailableDeployments = {
                [releaseId]: this.processReleasePromotion(promotions)
            };
        }

        this.tenantIndex = {};
        const tagIndex: {[tag: string]: boolean} = {};
        this.tenantTagIndex = (tenants as any[]).reduce((idx, tenant) => {
            idx[tenant.Id] = tenant.TenantTags;
            this.tenantIndex[tenant.Id] = tenant as DashboardTenantResource;

            tenant.TenantTags.forEach((tt: string) => {
                tagIndex[tt] = true;
            });
            return idx;
        }, {});

        tagSets.forEach(ts => {
            if (ts.Tags.find(t => tagIndex[t.CanonicalTagName])) {
                this.tagSetIndex[ts.Id] = ts;
            }
        });
    }

    processReleasePromotion(promotions: DeploymentTemplateResource) {
        const releasePromotions = promotions.PromoteTo.reduce<{ [environmentId: string]: string[] }>((promos: { [environmentId: string]: string[] }, environment) => {
            promos[environment.Id] = [null];
            return promos;
        }, {});

        promotions.TenantPromotions.forEach(tp => {
            tp.PromoteTo.forEach(environment => {
                releasePromotions[environment.Id] = (releasePromotions[environment.Id] || []);
                releasePromotions[environment.Id].push(tp.Id);
            });
        });

        return releasePromotions;
    }
}
export default DashboardDataCube;