import * as React from "react";
import SortableList, { SortableItemModel } from "components/SortableList/SortableList";
import { sortBy } from "lodash";
import TagModel from "./TagModel";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";

interface SortTagsProps {
    tags: TagModel[];
    onTagsSorted(sortIndex: string[]): void;
}

interface SortTagsState extends DataBaseComponentState {
    sortedTags: SortableItemModel[];
}

export default class SortTags extends DataBaseComponent<SortTagsProps, SortTagsState> {

    constructor(props: SortTagsProps) {
        super(props);

        const items = sortBy(this.props.tags, t => t.sortOrder).map(t => ({
            Id: t.localId,
            Name: t.name
        }));
        this.state = { sortedTags: items };
    }

    render() {
        return <OkDialogLayout title="Sort Tags"
            errors={this.state.errors}
            busy={this.state.busy}
            onOkClick={() => this.onOk()}>
            <SortableList items={this.state.sortedTags}
                onOrderChanged={orderedItems => this.setState({ sortedTags: orderedItems })} />
        </OkDialogLayout>;
    }

    onOk() {
        this.props.onTagsSorted(this.state.sortedTags.map(t => t.Id));
        return true;
    }
}
