import BasicRepository from "./basicRepository";
import Client from "../client";
import {
    NewTriggerResource,
    TriggerResource
} from "../resources";

class ProjectTriggerRepository extends BasicRepository<TriggerResource, NewTriggerResource> {
    constructor(client: Client) {
        super("ProjectTriggers", client);
    }
}

export default ProjectTriggerRepository;