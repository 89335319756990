import * as React from "react";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import Breadcrumb from "components/Breadcrumb";
import PageTitleHelper from "utils/PageTitleHelper";
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";
const styles = require("./style.less");

interface AreaTitleProps {
    title: string;
    link: string;
    breadcrumbTitle?: string; // If specified, this text will display above the title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
}

class AreaTitle extends React.Component<AreaTitleProps, never> {
    constructor(props: AreaTitleProps) {
        super(props);
        PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
    }

    render() {
        return <div className={styles.titleBar}>
            <div className={styles.title}>
                <Breadcrumb title={this.props.breadcrumbTitle} path={this.props.breadcrumbPath} isAreaLevelBreadcrumb={true} />
                <h1><InternalNavLink className={styles.pageTitleLink} to={this.props.link}>{this.props.title}</InternalNavLink></h1>
            </div>
            <div className={styles.actions}>
                {this.props.children}
            </div>
        </div>;
    }
}

export default AreaTitle;