import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import SpecialVariables from "client/specialVariables";
import Roles from "../Roles";
import { ActionSummaryProps } from "../actionSummaryProps";
import {
    ActionExecutionLocation, GetPrimaryPackageReference, GetNamedPackageReferences, InitialisePrimaryPackageReference,
    RemovePrimaryPackageReference, SetPrimaryPackageReference
} from "client/resources";
import { ExpandableFormSection, Summary, FormSectionHeading, MoreInfo } from "components/form";
import PackageSelector from "components/PackageSelector/PackageSelector";
import { StringRadioButtonGroup } from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import Note from "components/form/Note/Note";
import { VariableLookupCodeEditor } from "components/CodeEditor/CodeEditor";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import FeedResource, { FeedType } from "client/resources/feedResource";
import { repository } from "clientInstance";
import { WorkerPoolChip } from "components/Chips";
import { VariableLookupText } from "components/form/VariableLookupText";
import { default as PackageDownloadOptions } from "components/PackageDownloadOptions/PackageDownloadOptions";
import { ScriptingLanguage } from "components/scriptingLanguage";
import ScriptingLanguageSelector, { SupportedLanguage } from "components/ScriptingLanguageSelector/ScriptingLanguageSelector";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import { TargetRoles } from "areas/projects/components/DeploymentProcess/ActionDetails";
import { PackageRequirement } from "client/resources";
import { RemoveItemsList } from "../../RemoveItemsList/RemoveItemsList";
import ActionButton from "../../Button";
import DialogOpener from "../../Dialog/DialogOpener";
import {
    ScriptPackageReferenceDialog, ScriptPackageReference,
    ScriptPackageProperties
} from "./ScriptPackageReferenceDialog";
import * as _ from "lodash";
import ListTitle from "../../ListTitle/ListTitle";
import { PackageAcquisitionLocation } from "../../../client/resources/packageAcquisitionLocation";
import { getFeedName } from "../getFeedName";
import { PackageReference } from "../../../client/resources/packageReference";

export interface ScriptActionSummaryProps extends ActionSummaryProps {
    worker?: string;
}

interface ScriptActionSummaryState {
    feedNames: Map<string, string>;
}

class ScriptActionSummary extends BaseComponent<ScriptActionSummaryProps, ScriptActionSummaryState> {
    constructor(props: ScriptActionSummaryProps) {
        super(props);
        this.state = {
            feedNames: new Map<string, string>(),
        };
    }

    async componentDidMount() {
        const feedNames = new Map<string, string>();

        for (const feedId of this.props.packages.map(pkg => pkg.FeedId)) {
            if (feedId) {
                const feedName = await getFeedName(feedId);
                feedNames.set(feedId, feedName || feedId);
            }
        }

        this.setState({ feedNames });
    }

    render() {
        const runsOnServer = this.props.properties[SpecialVariables.Action.RunOnServer] === "true";

        let summary;
        if (this.props.worker) {
            summary = <React.Fragment>Run a script on a Worker from pool <WorkerPoolChip workerPoolName={this.props.worker} />{this.props.targetRolesAsCSV &&
                    <React.Fragment>on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /></React.Fragment>}</React.Fragment>
            ;
        } else {
            summary = runsOnServer
                    ? <React.Fragment>Run a script on the Octopus Server {this.props.targetRolesAsCSV &&
                        <React.Fragment>on behalf of targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /></React.Fragment>}</React.Fragment>
                    : <React.Fragment>Run a script {this.props.targetRolesAsCSV
                        ? <React.Fragment>across targets in <Roles rolesAsCSV={this.props.targetRolesAsCSV} /></React.Fragment>
                        : <React.Fragment>on the deployment target</React.Fragment>}</React.Fragment>
            ;
        }

        const packageContainingScript = GetPrimaryPackageReference(this.props.packages);
        const packageReferences = GetNamedPackageReferences(this.props.packages);

        if (!!packageContainingScript) {
            summary =
                <React.Fragment>{summary}
                    <div> Script is contained in
                        package {this.packageAndFeed(packageContainingScript)}
                        {packageReferences.length > 0
                            ? <span> and {this.packageReferenceSummary(packageReferences)}</span>
                            : ""
                        }
                    </div>
                </React.Fragment>;
        } else {
            if (packageReferences.length > 0) {
                summary = <div>{summary} Script {this.packageReferenceSummary(packageReferences)}</div>;
            }
        }

        return <div>
            {summary}
        </div>;
    }

    packageReferenceSummary(packageReferences: PackageReference[]) {
        if (packageReferences.length === 0) {
            return null;
        }

        if (packageReferences.length === 1) {
            return <span>references package {this.packageAndFeed(packageReferences[0])}</span>;
        }

        if (packageReferences.length < 3) {
            return <React.Fragment>
                <span>references packages:</span>
                {packageReferences.map(pkg =>
                    <div key={pkg.Id}>{this.packageAndFeed(pkg)}</div>)}
            </React.Fragment>;
        }

        return <span>references {packageReferences.length} packages</span>;
    }

    packageAndFeed(pkg: PackageReference) {
        return <span><strong>{pkg.PackageId}</strong> from <strong>{this.state.feedNames.get(pkg.FeedId)}</strong></span>;
    }
}

export interface ScriptProperties {
    "Octopus.Action.Script.ScriptSource": string;
    "Octopus.Action.Script.Syntax": ScriptingLanguage;
    "Octopus.Action.Script.ScriptBody": string;
    "Octopus.Action.Script.ScriptFileName": string;
    "Octopus.Action.Script.ScriptParameters": string;
    "Octopus.Action.RunOnServer": string;
}

interface ScriptActionEditState {
    feeds: FeedResource[];
    originalCanRunBeforeAcquisition: boolean;
    originalStepPackageRequirement: PackageRequirement;
    editPackageReference?: ScriptPackageReference;
    editPackageReferenceIndex?: number;
}

interface ScriptActionEditProps extends ActionEditProps<ScriptProperties, ScriptPackageProperties> {
    supportedLanguages?: SupportedLanguage;
}

class PackageReferenceList extends RemoveItemsList<ScriptPackageReference> {

}

// Note: this is also used in the Azure PowerShell step and the ServiceFabric PowerShell step
export class ScriptActionEdit extends BaseComponent<ScriptActionEditProps, ScriptActionEditState> {
    private static defaultProps: Partial<ScriptActionEditProps> = {
        supportedLanguages: SupportedLanguage.All
    };

    constructor(props: ScriptActionEditProps) {
        super(props);

        const packageAcquisition = props.additionalActions && props.additionalActions.packageAcquisition;

        this.state = {
            feeds: [],
            originalCanRunBeforeAcquisition: packageAcquisition ? packageAcquisition.canRunBeforeAcquisition : false,
            originalStepPackageRequirement: packageAcquisition ? packageAcquisition.stepPackageRequirement : PackageRequirement.LetOctopusDecide,
            editPackageReference: null,
            editPackageReferenceIndex: null,
        };
    }

    async componentDidMount() {
        await this.loadFeeds((feeds) => {
            if (!this.props.properties["Octopus.Action.Script.ScriptSource"]) {
                this.handleScriptSourceChanged("Inline", feeds, true);
            }
        });
    }

    handleScriptSourceChanged(val: string, feeds: FeedResource[], initialise: boolean = false) {
        const isRunFromPackage = val === "Package";
        const properties: any = { ["Octopus.Action.Script.ScriptSource"]: val };

        if (isRunFromPackage) {
            properties["Octopus.Action.Script.Syntax"] = null;
            properties["Octopus.Action.Script.ScriptBody"] = null;
            this.props.setPackages(InitialisePrimaryPackageReference(this.props.packages, feeds), initialise);
        } else {
            if (!this.props.properties["Octopus.Action.Script.Syntax"]) {
                properties["Octopus.Action.Script.Syntax"] = ScriptingLanguage.PowerShell;
            }
            properties["Octopus.Action.Script.ScriptFileName"] = null;
            this.props.setPackages(RemovePrimaryPackageReference(this.props.packages), initialise);
        }

        this.props.setProperties(properties, initialise);

        // if we are pulling the script from a package we might need to override things
        // if the user has set this step to run before package acquisition
        if (this.props.additionalActions) {
            if (isRunFromPackage) {
                this.props.additionalActions.packageAcquisition.onCanRunBeforeAcquisitionChanged(false);
            } else {
                this.props.additionalActions.packageAcquisition.onCanRunBeforeAcquisitionChanged(this.state.originalCanRunBeforeAcquisition);
            }
            if (this.state.originalStepPackageRequirement === PackageRequirement.BeforePackageAcquisition) {
                const newValue = isRunFromPackage
                    ? PackageRequirement.LetOctopusDecide
                    : this.state.originalStepPackageRequirement;

                this.props.additionalActions.packageAcquisition.onStepPackageRequirementChanged(newValue);
            }
        }
    }

    scriptSourceIsPackage() {
        return this.props.properties["Octopus.Action.Script.ScriptSource"] && this.props.properties["Octopus.Action.Script.ScriptSource"] === "Package";
    }

    scriptSourceSummary() {
        if (this.props.properties["Octopus.Action.Script.ScriptSource"] === "Inline") {
            return Summary.default("The script is defined below");
        } else {
            return Summary.summary("The script is contained in a package");
        }
    }

    scriptBodySummary() {
        if (!this.props.properties["Octopus.Action.Script.ScriptBody"]) {
            return Summary.placeholder("The script body has not been defined");
        }
        if (this.isPowershellOnly()) {
            return Summary.summary("The script body has been defined");
        }
        const syntax = this.props.properties["Octopus.Action.Script.Syntax"];
        if (syntax === ScriptingLanguage.FSharp) {
            return Summary.summary(<span>An <strong>F#</strong> script has been defined</span>);
        } else if (syntax === ScriptingLanguage.CSharp) {
            return Summary.summary(<span>A <strong>C#</strong> script has been defined</span>);
        }
        return Summary.summary(<span>A <strong>{syntax}</strong> script has been defined</span>);
    }

    scriptFileFromPackageSummary() {
        const packageContainingScript = GetPrimaryPackageReference(this.props.packages);
        const fileName = this.props.properties["Octopus.Action.Script.ScriptFileName"];

        if (!packageContainingScript || !packageContainingScript.PackageId) {
            return Summary.placeholder("Select the package containing the script");
        }

        if (!fileName) {
            return Summary.placeholder("Enter the path to the script file");
        }

        const feed = this.state.feeds.find(f => f.Id === packageContainingScript.FeedId);

        return Summary.summary(
            <span><strong>{fileName}</strong> in package <strong>{packageContainingScript.PackageId}</strong> from
                feed <strong>{!!feed ? feed.Name : packageContainingScript.FeedId}</strong></span>
        );
    }

    render() {
        const properties = this.props.properties;
        const runOnServer = this.props.properties
            && this.props.properties["Octopus.Action.RunOnServer"]
            && this.props.properties["Octopus.Action.RunOnServer"].toLowerCase() === "true";

        const localNames = _.concat(this.props.localNames ? this.props.localNames : [], this.packageVariableNames());

        const editPackageReferenceDialog = <DialogOpener open={!!this.state.editPackageReference} onClose={this.resetSelectedPackageReference}>
            <ScriptPackageReferenceDialog
                packageReference={this.state.editPackageReference}
                runOn={this.props.runOn}
                feeds={this.state.feeds}
                localNames={localNames}
                projectId={this.props.projectId}
                onChange={packageReference => this.savePackageReference(packageReference)}
                refreshFeeds={this.loadFeeds} />
        </DialogOpener>;

        return <div>
            {editPackageReferenceDialog}
            <FormSectionHeading title="Script" />
            <ExpandableFormSection
                errorKey="scriptsource"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Script Source"
                summary={this.scriptSourceSummary()}
                help="Select where the script is defined.">
                <StringRadioButtonGroup
                    value={properties["Octopus.Action.Script.ScriptSource"]}
                    onChange={val => this.handleScriptSourceChanged(val, this.state.feeds)}>
                    <RadioButton value="Inline" label="Inline source code (with optional package references)" />
                    <Note>The script body is defined in the Inline Source Code field below, Referenced Packages below this</Note>
                    <RadioButton value="Package" label="Script file inside a package" />
                    <Note>The script is contained in a package that will be deployed</Note>
                </StringRadioButtonGroup>
            </ExpandableFormSection>

            {properties["Octopus.Action.Script.ScriptSource"] === "Inline" && this.inlineScriptFormSections(runOnServer, localNames)}

            {properties["Octopus.Action.Script.ScriptSource"] === "Package" && this.packageScriptFormSections(localNames)}
        </div>;
    }

    inlineScriptFormSections = (runOnServer: boolean, localNames: string[]) => {
        const properties = this.props.properties;
        const packageReferences = GetNamedPackageReferences(this.props.packages) as ScriptPackageReference[];

        return <div>
            <ExpandableFormSection
                isExpandedByDefault={this.props.expandedByDefault}
                errorKey="Octopus.Action.Script.ScriptBody"
                title="Inline Source Code"
                fillCardWidth={CardFill.FillRight}
                summary={this.scriptBodySummary()}
                help={this.isPowershellOnly()
                    ? "Enter the body of the script that will be executed."
                    : "Select the script language and enter the body of the script that will be executed."}>
                {/* <Note>Click the <em>Insert a variable</em> button (to the right of the script editor) for a list of
                    available <ExternalLink href="DocumentationVariables">variables</ExternalLink>.</Note> */}
                <Note>Scripts can contain <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink> below and <ExternalLink href="DocumentationVariables">variables</ExternalLink> can
                be added by the <em>Insert a variable</em> button.</Note>
                {packageReferences.length > 0 && <Note>
                    There are referenced packages. See the <ExternalLink href="ScriptStepPackageReferencesFromCustomScripts">documentation</ExternalLink> for
                    information on consuming packages from your scripts.
                </Note>}
                {!this.isPowershellOnly() &&
                    <ScriptingLanguageSelector supportedLanguages={this.props.supportedLanguages}
                        value={properties["Octopus.Action.Script.Syntax"] as ScriptingLanguage}
                        onlyRunnableOnServer={runOnServer && (!this.props.additionalActions || !this.props.additionalActions.workerPoolId)}
                        onChange={syntax => this.props.setProperties({ ["Octopus.Action.Script.Syntax"]: syntax })} />
                }
                {this.isPowershellOnly() && <h4>PowerShell script</h4>}
                <VariableLookupCodeEditor
                    localNames={localNames}
                    projectId={this.props.projectId}
                    syntax={properties["Octopus.Action.Script.Syntax"]}
                    value={properties["Octopus.Action.Script.ScriptBody"]}
                    language={properties["Octopus.Action.Script.Syntax"]}
                    allowFullScreen={true}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptBody"]: x })} />
            </ExpandableFormSection>
            {this.packagesFormSection()}
        </div>;
    }

    packageScriptFormSections = (localNamesWithPackageVariables: string[]) => {
        const properties = this.props.properties;
        const primaryPackage = GetPrimaryPackageReference(this.props.packages);
        const primaryPackageFeed = primaryPackage ? _.find(this.state.feeds, f => f.Id === primaryPackage.FeedId) : null;

        return <div>
            <ExpandableFormSection
                errorKey="Octopus.Action.Package.FeedId|Octopus.Action.Package.PackageId|Octopus.Action.Script.ScriptFileName|Octopus.Action.Script.ScriptParameters"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Script File in Package"
                summary={this.scriptFileFromPackageSummary()}
                help={<span>Select the package containing your script</span>}>
                <Note>
                    <ExternalLink href="DocumentationPackaging">Learn more</ExternalLink> about what your packages should contain, and how to create them.
                </Note>
                <PackageSelector
                    packageId={primaryPackage.PackageId}
                    feedId={primaryPackage.FeedId}
                    onPackageIdChange={packageId => this.props.setPackages(SetPrimaryPackageReference({ PackageId: packageId }, this.props.packages))}
                    onFeedIdChange={feedId => this.props.setPackages(SetPrimaryPackageReference({ FeedId: feedId }, this.props.packages))}
                    packageIdError={this.props.getFieldError("Octopus.Action.Package.PackageId")}
                    feedIdError={this.props.getFieldError("Octopus.Action.Package.FeedId")}
                    projectId={this.props.projectId}
                    feeds={this.state.feeds}
                    localNames={this.props.localNames}
                    refreshFeeds={this.loadFeeds} />
                <PackageDownloadOptions
                    packageAcquisitionLocation={primaryPackage.AcquisitionLocation}
                    onPackageAcquisitionLocationChanged={acquisitionLocation =>
                        this.props.setPackages(SetPrimaryPackageReference({ AcquisitionLocation: acquisitionLocation }, this.props.packages))}
                    feed={primaryPackageFeed}
                    runOn={this.props.runOn}
                    projectId={this.props.projectId}
                    localNames={this.props.localNames} />
                <VariableLookupText
                    localNames={localNamesWithPackageVariables}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.Script.ScriptFileName"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptFileName"]: x })}
                    error={this.props.getFieldError("Octopus.Action.Script.ScriptFileName")}
                    label="Script file name" />
                <Note>
                    The relative path to the script file within the package.<br />
                    e.g. <code>MyScript.ps1</code> or <code>Scripts\MyScript.ps1</code>
                </Note>
                <VariableLookupText
                    localNames={localNamesWithPackageVariables}
                    projectId={this.props.projectId}
                    value={properties["Octopus.Action.Script.ScriptParameters"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.Script.ScriptParameters"]: x })}
                    error={this.props.getFieldError("Octopus.Action.Script.ScriptParameters")}
                    label="Script parameters" />
                {this.isPowershellOnly()
                    ? <Note>
                        Parameters expected by the script. Use platform specific calling convention.<br />
                        e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell
                        </Note>
                    : <Note>
                        Parameters expected by the script. Use platform specific calling convention.<br />
                        e.g. <code>-Path #{`{VariableStoringPath}`}</code> for PowerShell or <code>-- #{`{VariableStoringPath}`}</code> for ScriptCS
                        </Note>
                }
            </ExpandableFormSection>
            {this.packagesFormSection()}
        </div>;
    }

    packagesFormSection = () => {
        const packageReferences = GetNamedPackageReferences(this.props.packages) as ScriptPackageReference[];

        return <ExpandableFormSection
            title={this.scriptSourceIsPackage() ? "Additional Packages" : "Referenced Packages"}
            isExpandedByDefault={this.props.expandedByDefault}
            errorKey="Octopus.Action.Script.Packages"
            summary={this.packageReferenceSummary()}
            help={this.scriptSourceIsPackage()
                ? "Add additional package references"
                : "Add packages to be referenced by your scripts at execution-time"}>
            <Note>Learn more about <ExternalLink href="ScriptStepPackageReferences">package references</ExternalLink>.</Note>
            <PackageReferenceList
                listActions={[<ActionButton key="add" label="Add" onClick={() => this.addPackageReference()} />]}
                data={packageReferences}
                onRow={(p) => this.packageReferenceListItem(p)}
                onRowTouch={(pkg) => this.editPackageReference(pkg)}
                onRemoveRow={(pkg) => this.removePackageReference(pkg)}
            />
        </ExpandableFormSection>;
    }

    packageReferenceSummary = () => {
        const namedPackageReferences = GetNamedPackageReferences(this.props.packages);
        const scriptSourceIsPackage = this.scriptSourceIsPackage();

        if (namedPackageReferences.length === 0) {
            return Summary.placeholder(scriptSourceIsPackage ? "No additional packages referenced" : "No packages referenced");
        }

        return Summary.summary(`${namedPackageReferences.length} package references`);
    }

    addPackageReference = () => {
        const additionalPackage = {
            Id: null as string,
            Name: null as string,
            FeedId: null as string,
            PackageId: null as string,
            AcquisitionLocation: PackageAcquisitionLocation.Server,
            Properties: { Extract: "True" }
        };

        this.setState({ editPackageReference: additionalPackage, editPackageReferenceIndex: null });
    }

    editPackageReference = (pkg: ScriptPackageReference) => {
        this.setState({
            editPackageReference: _.clone(pkg),
            editPackageReferenceIndex: this.props.packages.indexOf(pkg)
        });
    }

    removePackageReference = (pkg: ScriptPackageReference) => {
        const packages = [...this.props.packages];
        packages.splice(packages.indexOf(pkg), 1);
        this.props.setPackages(packages);
    }

    packageReferenceListItem = (pkg: ScriptPackageReference) => {
        const feed = this.state.feeds.find(f => f.Id === pkg.FeedId);
        return <div>
            <ListTitle>{pkg.Name}</ListTitle>
            <div>
                <strong>{pkg.PackageId}</strong> from feed <strong>{!!feed ? feed.Name : pkg.FeedId}</strong>
                <Note>Extracted: <strong>{pkg.Properties.Extract}</strong></Note>
            </div>
        </div>;
    }

    savePackageReference(packageReference: ScriptPackageReference) {
        const packageReferences = [...this.props.packages];
        if (this.state.editPackageReferenceIndex === null) {
            packageReferences.push(packageReference);
        } else {
            packageReferences[this.state.editPackageReferenceIndex] = packageReference;
        }

        this.props.setPackages(packageReferences);
        this.resetSelectedPackageReference();
        return true;
    }

    resetSelectedPackageReference = () => {
        this.setState({
            editPackageReference: null,
            editPackageReferenceIndex: null
        });
    }

    packageVariableNames = (): string[] => {
        return _.flatten(
            GetNamedPackageReferences(this.props.packages)
                .map(pkg => [
                    `Octopus.Action.Package[${pkg.Name}].PackageId`,
                    `Octopus.Action.Package[${pkg.Name}].FeedId`,
                    `Octopus.Action.Package[${pkg.Name}].PackageVersion`,
                    `Octopus.Action.Package[${pkg.Name}].ExtractedPath`,
                    `Octopus.Action.Package[${pkg.Name}].PackageFilePath`,
                    `Octopus.Action.Package[${pkg.Name}].PackageFileName`,
                ]));
    }

    private isPowershellOnly = () => {
        return this.props.supportedLanguages === SupportedLanguage.PowerShell;
    }

    private loadFeeds = (callback?: (feeds: FeedResource[]) => void) => {
        return this.props.doBusyTask(async () => {
            this.setState({ feeds: await repository.Feeds.all() }, () => callback && callback(this.state.feeds));
        });
    }
}

pluginRegistry.registerDeploymentAction({
    actionType: "Octopus.Script",
    summary: (properties, targetRolesAsCSV, packages, worker) =>
        <ScriptActionSummary
            properties={properties}
            targetRolesAsCSV={targetRolesAsCSV}
            packages={packages}
            worker={worker} />,
    edit: ScriptActionEdit,
    canHaveChildren: step => !!step.Properties[SpecialVariables.Action.TargetRoles],
    canBeChild: true,
    executionLocation: ActionExecutionLocation.TargetOrServer,
    targetRoleOption: (action) => TargetRoles.Optional,
    hasPackages: (action) => {
        if (action.Properties && action.Properties["Octopus.Action.Script.ScriptSource"] === "Package") {
            return true;
        }
        return false;
    },
    features: {
        optional: ["Octopus.Features.ConfigurationTransforms", "Octopus.Features.ConfigurationVariables",
            "Octopus.Features.JsonConfigurationVariables", "Octopus.Features.SubstituteInFiles"]
    }
});