import * as React from "react";

interface NothingToSeeHereProps {
    content: string;
}

const styles = require("./style.less");
export class NothingToSeeHere extends React.Component<NothingToSeeHereProps, any> {
    render() {
        return <p className={styles.content}>{this.props.content}</p>;
    }
}