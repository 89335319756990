import * as React from "react";
import DebounceValue from "components/DebounceValue/DebounceValue";
import Text, {TextProps} from "components/form/Text/Text";

interface AdvancedFilterTextInputProps {
    fieldName: string;
    value: string;
    onChange(value: string): void;
}

const DebouncedText = DebounceValue<TextProps & {fieldName?: string}, string>(Text);

export const AdvancedFilterTextInput: React.SFC<AdvancedFilterTextInputProps> = (props: AdvancedFilterTextInputProps) => {
    return <DebouncedText fieldName={props.fieldName} onChange={props.onChange} value={props.value} />;
};