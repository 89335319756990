import * as React from "react";
import { client, repository} from "clientInstance";
import { TenantedDeploymentMode } from "client/resources/tenantedDeploymentMode";
import {ScopeValues, VariableSetResource} from "client/resources/variableSetResource";
import {ProjectRouteParams} from "areas/projects/components/ProjectLayout/ProjectLayout";
import {RouteComponentProps} from "react-router";
import {ProjectResource} from "client/resources/projectResource";
import {Permission} from "client/resources/permission";
import VariableEditor from "areas/variables/VariableEditor/VariableEditor";
import {Dictionary, flatten, groupBy, keyBy} from "lodash";
import OverflowMenu from "components/Menu/OverflowMenu";
import {
    default as VariableSaveConfirmationDialog
} from "areas/variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import FormPaperLayout from "components/FormPaperLayout/FormPaperLayout";
import {VariableModel, VariableValueModel} from "../../../../variables/VariablesModel/VariablesModel";
import ReadonlyVariableResource from "../../../../variables/ReadonlyVariableResource";
import FormBaseComponent from "../../../../../components/FormBaseComponent";
import {OptionalFormBaseComponentState} from "../../../../../components/FormBaseComponent/FormBaseComponent";
import {VariableSaveConfirmationContent} from "../../../../variables/VariableSaveConfirmationDialog/VariableSaveConfirmationDialog";
import {createDialogContent, createViewModel, getVariableResources} from "../../../../variables/VariableEditor/conversions";
import groupVariablesByName from "../../../../variables/groupVariablesByName";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface ProjectVariablesState extends OptionalFormBaseComponentState<ProjectVariablesModel> {
    variableSet?: VariableSetResource;
    project?: ProjectResource;
    model?: ProjectVariablesModel;
    dialogContent?: VariableSaveConfirmationContent;
    initialVariables?: ReadonlyArray<VariableModel>;
    groupedVariableResources?: Dictionary<ReadonlyVariableResource[]>;
    cellFocusResetKey: string;
}

interface ProjectVariablesModel {
    readonly variables: ReadonlyArray<VariableModel>;
}

export default class ProjectVariables extends FormBaseComponent<RouteComponentProps<ProjectRouteParams>, ProjectVariablesState, ProjectVariablesModel> {
    constructor(props: RouteComponentProps<ProjectRouteParams>) {
        super(props);
        this.state = {
            cellFocusResetKey: DateFormatter.timestamp(),
        };
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            const project = await repository.Projects.get(this.props.match.params.projectSlug);
            const variableSet = await repository.Variables.get(project.VariableSetId);
            this.resetState(project, variableSet);
        });
    }

    render() {
        const overFlowActions = this.state.project ? [
            OverflowMenu.downloadItem(
                "Download as JSON",
                this.state.project.Slug + "-variables.json",
                client.resolveLinkTemplate("Variables", {id: `variableset-${this.state.project.Id}`})
            )
        ] : null;

        return <FormPaperLayout
            busy={this.state.busy}
            errors={this.state.errors}
            fullWidth={true}
            model={this.state.model}
            cleanModel={this.state.cleanModel}
            title={"Project Variables"}
            overFlowActions={overFlowActions}
            onSaveClick={() => {

                const dialogContent = createDialogContent(this.state.model.variables, this.state.initialVariables, this.state.variableSet.Variables);

                if (dialogContent && dialogContent.hasContent) {
                    this.setState({dialogContent});
                } else {
                    return this.doBusyTask(() => this.saveVariables());
                }
            }}
            savePermission={{
                permission: Permission.VariableEdit,
                project: this.state.project && this.state.project.Id,
                wildcard: true,
            }}
        >
            {this.state.model && <TransitionAnimation>
                    <VariableEditor
                        initialVariables={this.state.initialVariables}
                        scopeValues={this.state.variableSet.ScopeValues}
                        isProjectScoped={true}
                        isTenanted={this.isProjectTenanted()}
                        doBusyTask={this.doBusyTask}
                        onVariablesChanged={(variables: any) => this.setState({model: {variables}})}
                        cellFocusResetKey={this.state.cellFocusResetKey}
                    />
                    <VariableSaveConfirmationDialog
                        key={"VariableEditorConfirmDialogs"}
                        content={this.state.dialogContent}
                        onClosed={() => this.setState({dialogContent: null})}
                        onSaveClick={() => this.saveVariables()}
                    />
                </TransitionAnimation>
            }
        </FormPaperLayout>;
    }

    private isProjectTenanted() {
        return this.state.project &&
            this.state.project.TenantedDeploymentMode !== TenantedDeploymentMode.Untenanted;
    }

    private resetState(project: ProjectResource, variableSet: VariableSetResource) {

        const groupedVariableResources = groupVariablesByName(variableSet.Variables, v => v.Name);
        const variables = createViewModel(groupedVariableResources);
        const model: ProjectVariablesModel = {variables};

        this.setState({
            project,
            variableSet,
            groupedVariableResources,
            initialVariables: [...variables],
            model,
            cleanModel: {...model},
            cellFocusResetKey: DateFormatter.timestamp() });
    }

    private async saveVariables() {
        const variableSet = await repository.Variables.modify({...this.state.variableSet, Variables: getVariableResources(this.state.model.variables, this.state.groupedVariableResources)});
        this.resetState(this.state.project, variableSet);
    }
}