import * as React from "react";
import {VariablePromptDisplaySettings, VariablePromptOptions, VariableType} from "client/resources/variableResource";
import {VariableValueModel} from "../VariablesModel";
import {ControlType} from "../../../client/resources";
import Checkbox from "../../../components/form/Checkbox/Checkbox";
import Text from "../../../components/form/Text/Text";
import Select from "../../../components/form/Select/Select";
import Note from "../../../components/form/Note/Note";
import ControlTypeSelector from "../../../components/ControlType/ControlTypeSelector";

interface PromptedVariableDetailsProps {
    variable: VariableValueModel;
    label: string;
    description: string;
    required: boolean;
    displaySettings: VariablePromptDisplaySettings;
    prompt: boolean;
    onLabelChange: (label: string) => void;
    onDescriptionChange: (description: string) => void;
    onRequiredChange: (value: boolean) => void;
    onPromptChange: (value: boolean) => void;
    onDisplaySettingsChange: (value: VariablePromptDisplaySettings) => void;
}

const controlTypes = [
    ControlType.SingleLineText,
    ControlType.MultiLineText,
    ControlType.Select,
    ControlType.Checkbox,
];

const PromptedVariableDetails: React.SFC<PromptedVariableDetailsProps> =
    ({variable, label, description, required, displaySettings, prompt, onPromptChange, onLabelChange, onDescriptionChange, onRequiredChange, onDisplaySettingsChange}) => {

        const onControlTypeChange = (controlType: ControlType) => {
            onDisplaySettingsChange({...displaySettings, "Octopus.ControlType": controlType});
        };

        const onSelectOptionsChange = (selectOptions: string) => {
            onDisplaySettingsChange({...displaySettings, "Octopus.SelectOptions": selectOptions});
        };

        return <div>
            <Checkbox
                label="Prompt for value"
                value={prompt}
                onChange={onPromptChange}
            />
            {prompt && <div>
                <Text
                    label="Label"
                    value={label}
                    onChange={onLabelChange}
                />
                <Text
                    label="Description"
                    value={description}
                    onChange={onDescriptionChange}
                />
                {variable.Type === VariableType.String && <ControlTypeSelector
                    includedControlTypeOptions={controlTypes}
                    controlType={displaySettings["Octopus.ControlType"]}
                    onControlTypeChange={onControlTypeChange}
                    selectOptions={displaySettings["Octopus.SelectOptions"]}
                    onSelectOptionsChange={onSelectOptionsChange}/>}

                <Checkbox
                    label="Required"
                    value={required}
                    onChange={onRequiredChange}/>
            </div>}
        </div>;
    };

export default PromptedVariableDetails;
