export default class WindowHelper {

    public static isElementInViewport(el: any) {
        const rect = el.getBoundingClientRect();
        const vWidth = window.innerWidth || document.documentElement.clientWidth;
        const vHeight = window.innerHeight || document.documentElement.clientHeight;

        return (rect.bottom > 0 &&
            rect.right > 0 &&
            rect.left < vWidth &&
            rect.top < vHeight);
    }

    public static isElementCenterTouchable(el: any) {
        const rect = el.getBoundingClientRect();
        return el.contains(document.elementFromPoint(rect.right - (rect.width / 2), rect.bottom - (rect.height / 2)));
    }
}