import { Permission } from "client/resources";
import { session, client, repository } from "clientInstance";
import { MenuNode } from "components/LinksMenu/MenuNode";
import EAPBar from "components/Navbar/EAPBar";
import ProjectSwitcher from "components/Navbar/ProjectSwitcher";
import UserAccountMenu from "components/Navbar/UserAccountMenu";
import { isAllowed } from "components/PermissionCheck/PermissionCheck";
import PriorityNavigation from "components/PriorityNavigation/PriorityNavigation";
import Environment from "environment";
import SvgIconMaterial from "material-ui/SvgIcon";
import * as React from "react";
import routeLinks from "../../routeLinks";
import LicenceNotificationPoller from "./NotificationPoller/LicenceNotificationPoller";
import UpdateAvailableNotificationPoller from "./NotificationPoller/UpdateAvailableNotificationPoller";
import NotificationsMenu from "./NotificationsMenu";
const styles = require("./style.less");
import { connect, MapStateToProps } from "react-redux";
import GlobalState from "globalState";
import {RouteComponentProps, withRouter} from "react-router";
import SpacesMenu from "./SpacesMenu";
import {isSpaceNotFound, isSpecificSpaceContext, SpaceContext} from "../StandardLayout/SpaceLoader";

const ProjectSwitchIcon = (props: any) => <SvgIconMaterial {...props} color="white" viewBox="0 0 24 24">
    {/* tslint:disable-next-line */}
    <path d="M16.2,6.4c-2.9,0-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3c1.1,0,2.1-0.3,3-0.9l2.6,2.6c0.2,0.2,0.4,0.3,0.7,0.3c0.6,0,1-0.4,1-1c0-0.3-0.1-0.5-0.3-0.7l-2.6-2.6c0.6-0.9,0.9-1.9,0.9-3C21.5,8.8,19.1,6.4,16.2,6.4z M16.3,15.3c-1.9,0-3.4-1.6-3.4-3.4c0-1.9,1.6-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4S18.2,15.3,16.3,15.3z M18.9,17.3c-0.8,0.6-1.7,0.7-2.7,0.7c-2.4,0-4.5-1.3-5.5-3.3l-0.1,0H7.3c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h3c-0.2-0.5-0.3-1-0.3-1.5H7.4C7.2,12.4,7,12.2,7,12s0.2-0.4,0.4-0.4h2.5c0,0,0,0,0,0c0-0.5,0.1-1,0.2-1.5H7.4C7.2,10.1,7,9.9,7,9.7s0.2-0.4,0.4-0.4h3c0.9-2.3,3.2-3.9,5.8-3.9c1.1,0,2.1,0.3,3,0.8V5.7c0-0.9-0.7-1.6-1.6-1.6H1.8c-0.9,0-1.6,0.7-1.6,1.6v12.7c0,0.9,0.7,1.6,1.6,1.6h15.9c0.9,0,1.6-0.7,1.6-1.6V17C19.3,17,19.2,17.2,18.9,17.3z M5.8,13.2h-4v-4h4V13.2z"/>
</SvgIconMaterial>;
interface NavbarState {
    openProjectSwitcher: boolean;
    projectSwitcherAnchorEl: any;
    eapIsHidden: boolean;
}

interface NavbarProps extends RouteComponentProps<void> {
    spaceContext: SpaceContext;
}

class Navbar extends React.Component<NavbarProps, NavbarState> {
    constructor(props: NavbarProps) {
        super(props);

        this.state = {
            openProjectSwitcher: false,
            projectSwitcherAnchorEl: null,
            eapIsHidden: false,
        };
    }

    render() {

        // The topnavbar id is used by E2E UI tests
        return <div id="topnavbar" className={styles.topnavbar}>
            <EAPBar onCloseClicked={() => this.setState({ eapIsHidden: true })}/>
            <div className={styles.navbar}>

                <SpacesMenu spaceContext={this.props.spaceContext} />

                <PriorityNavigation
                    navigationItems={this.getNavigationItems(this.state.eapIsHidden)}
                    maxNavigationItems={99}
                    activeItemClassName={styles.navItemActive}
                    className={styles.navbarCenter}
                    showHamburgerIcon={true} />
                {isAllowed({permission: Permission.ProjectView, wildcard: true}) &&
                <ProjectSwitcher
                    open={this.state.openProjectSwitcher}
                    onRequestClose={this.closeProjectSwitcher}
                    anchorEl={this.state.projectSwitcherAnchorEl} />}
                <nav className={styles.navbarRight}>
                    <UpdateAvailableNotificationPoller />
                    <LicenceNotificationPoller />
                    <NotificationsMenu />
                    <UserAccountMenu currentUser={session.currentUser} spaceContext={this.props.spaceContext}/>
                </nav>
            </div>
        </div>;
    }

    private scrollToMainContent = () => {
        document.getElementById("maincontent").scrollIntoView();
    }

    private getNavigationItems(eapIsHidden: boolean) {
        const navigationItems: MenuNode[] = [] ;

        if (isSpecificSpaceContext(this.props.spaceContext)) {
            navigationItems.push({url: routeLinks.root, text: "Dashboard", exact: true });

            if (session.currentPermissions.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.ProjectView)) {
                navigationItems.push({url: routeLinks.projects.root, text: "Projects"});
            }
            if (session.currentPermissions.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.ProjectView)) {
                navigationItems.push({url: null, icon: <ProjectSwitchIcon />, title: "Project switcher", onClick: this.toggleProjectSwitcher });
            }
            if (isAllowed({
                permission: [Permission.MachineView, Permission.EnvironmentView, Permission.WorkerView],
                wildcard: true
            })) {
                navigationItems.push({url: routeLinks.infrastructure.root, text: "Infrastructure"});
            }
            if (session.currentPermissions.scopeToSpace(client.spaceId).hasPermissionInAnyScope(Permission.TenantView)) {
                navigationItems.push({ url: routeLinks.tenants, text: "Tenants" });
            }
            if (isAllowed({
                permission: [Permission.FeedView, Permission.LifecycleEdit, Permission.VariableView, Permission.ActionTemplateView, Permission.CertificateView],
                wildcard: true
            })) {
                navigationItems.push({url: routeLinks.library.root, text: "Library"});
            }
        }

        if (!isSpaceNotFound(this.props.spaceContext) || this.props.spaceContext.isAlsoInSystemContext) {
            const rootRouteLinks = isSpaceNotFound(this.props.spaceContext) ? routeLinks.withoutSpace() : routeLinks;
            if (session.currentPermissions.hasPermissionInAnyScope(Permission.TaskView)) {
                navigationItems.push({url: rootRouteLinks.tasks.rootForSpace(client.spaceId), text: "Tasks"});
            }
            if (session.currentPermissions.scopeToSpace(repository.spaceId).hasAnyPermissions() ||
                isAllowed({permission: [Permission.AdministerSystem, Permission.EventView, Permission.TeamView, Permission.UserView, Permission.UserRoleView,
                        Permission.SpaceView, Permission.SpaceEdit, Permission.SpaceCreate, Permission.SpaceDelete],
                           wildcard: true})) {
                navigationItems.push({url: rootRouteLinks.configuration.root, text: "Configuration"});
            }
        }

        if (Environment.isInDevelopmentMode() && !eapIsHidden) {
            navigationItems.push({url: routeLinks.styleGuide, text: "Style Guide [dev]"});
            navigationItems.push({url: routeLinks.codeGuide, icon: <em className="fa fa-industry" />, text: "Code Guide [dev]" });
        }

        return navigationItems;
    }

    private closeProjectSwitcher = () => {
        this.setState({
            openProjectSwitcher: false
        });
    }

    private toggleProjectSwitcher = (e: any) => {
        this.setState({
            openProjectSwitcher: !this.state.openProjectSwitcher,
            projectSwitcherAnchorEl: e
        });
    }
}

// Unfortunately this component needs to be connected to the router purely so that it updates when the route changes.
// Otherwise, the connect() breaks all route-related updates.  This might be fixed in React 16.3 which should introduce the new context API
export default withRouter(Navbar);