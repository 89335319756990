import * as React from "react";
import {repository} from "clientInstance";
import {TaskResource, TaskDetailsResource, ResourceCollection, ChannelResource} from "client/resources";
import TaskLog from "areas/tasks/components/Task/TaskLog/TaskLog";
import {ActivityElement} from "client/resources/taskDetailsResource";
import {DataBaseComponent, DataBaseComponentState, Refresh} from "components/DataBaseComponent/DataBaseComponent";
import PaperLayout from "components/PaperLayout/PaperLayout";
import InterruptionResource from "client/resources/interruptionResource";
import ArtifactResource from "client/resources/artifactResource";
import {EventResource} from "client/resources/eventResource";
import TabContainer from "components/Tabs/TabContainer";
import TabItem from "components/Tabs/TabItem";
import {TaskName} from "client/resources/taskResource";
import PermissionCheck, {isAllowed} from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import ActionList from "components/ActionList/ActionList";
import {AdHocScriptTaskSummary} from "areas/tasks/components/Task/AdHocScriptTaskSummary";
import {TaskState as TaskStateEnum} from "../../../../client/resources/taskState";
import ModifyTaskStateDialog from "./ModifyTaskStateDialog";
import { RouteComponentProps, withRouter} from "react-router";
import {UniqueActivityElement} from "../../../../components/TaskLogLines/TaskLogBlock";
import {clearLogFromURI} from "./TaskLog/TaskLogUtil";
import * as URI from "urijs";
import routeLinks from "routeLinks";
import RaisedButton from "material-ui/RaisedButton";
import {white, alert} from "../../../../colors";
import { TaskStatusIcon } from "areas/projects/components/TaskStatusIcon/TaskStatusIcon";
import OverflowMenu from "../../../../components/Menu/OverflowMenu";
import {ReleaseResource} from "client/resources/releaseResource";
import {DeploymentCreateGoal} from "../../../projects/components/Releases/ReleasesRoutes/releaseRouteLinks";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import { ChannelChip } from "components/Chips";
import TaskSummary from "./TaskSummary/TaskSummary";

interface TaskState extends DataBaseComponentState {
    task?: TaskResource<any>;
    taskDetails?: TaskDetailsResource;
    artifacts?: ArtifactResource[];
    interruptions?: ResourceCollection<InterruptionResource>;
    history?: ResourceCollection<EventResource>;
    activityElements?: UniqueActivityElement[];
    verbose: boolean;
    tail: boolean;
    cancelPending: boolean;
    redirectTo?: string;
    breadcrumbTitle?: string;
    breadcrumbPath?: string;
    hasLoadedOnce?: boolean;
    release: ReleaseResource;
    channel: ChannelResource;
    channelCount: number;
}

interface TaskComponentProps {
    task?: TaskResource<any>;
    taskId: string;
    projectId?: string;
    tenantId?: string;
    environmentId?: string;
    additionalSidebar?: React.ReactNode;
    additionalActions?(Task: TaskResource<any>): React.ReactNode[];
    additionalRefresh?(Task: TaskResource<any>): Promise<void>;
    delayRender(): boolean;
}

type TaskProps = TaskComponentProps & RouteComponentProps<any>;

const statesThatCanBeModified = [TaskStateEnum.Success, TaskStateEnum.Failed, TaskStateEnum.Canceled];

class Task extends DataBaseComponent<TaskProps, TaskState> {
    constructor(props: TaskProps) {
        super(props);
        this.state = {verbose: false, tail: true, cancelPending: false, release: null, channel: null, channelCount: 0};
    }

    componentDidMount() {
        this.doBusyTask(async () => {
            this.setState({
                task: this.props.task ? this.props.task : await repository.Tasks.get(this.props.taskId)
            }, async () => {
                this.doRefresh = await this.startRefreshLoop(() => this.refresh(this.state.verbose, this.state.tail), 5000);
            });
        });
    }

    async refresh(verbose: boolean, tail: boolean) {
        this.setState({verbose, tail});
        const taskDetailArgs = {verbose, tail: tail ? 20 : null};
        const eventsRegarding = [this.props.taskId];

        const taskDetailsPromise = repository.Tasks.details(this.state.task, taskDetailArgs);
        const interruptionsPromise = isAllowed({
            permission: Permission.InterruptionViewSubmitResponsible,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: this.props.tenantId,
        }) ? repository.Interruptions.list({regarding: this.props.taskId}) : Promise.resolve([] as any as ResourceCollection<InterruptionResource>);
        const eventsPromise = isAllowed({
            permission: Permission.EventView,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: this.props.tenantId,
        }) ? repository.Events.list({regardingAny: eventsRegarding}) : Promise.resolve([] as any as ResourceCollection<EventResource>);

        const taskDetails = await taskDetailsPromise;
        const activityElements = taskDetails.ActivityLogs.map((e, n) => this.setIdPrefix(e, n));

        // Only supply breadcrumbs if this is a deployment task (as it will be redirected to the project area where it needs a breadcrumb).
        const deployment = (taskDetails.Task.Name === "Deploy" && taskDetails.Task.Arguments.DeploymentId)
            ? await repository.Deployments.get(this.props.match.params.deploymentId)
            : null;
        const release = deployment ? await repository.Releases.get(deployment.ReleaseId) : null;
        const project = deployment ? await repository.Projects.get(deployment.ProjectId) : null;
        const projectChannels = project ? await repository.Projects.getChannels(project) : null;
        const channelCount = projectChannels ? projectChannels.TotalResults : 0;
        const channel = release && projectChannels
            ? await repository.Channels.get(release.ChannelId)
            : null;
        const breadcrumbTitle = release ? `Release ${release.Version}` : null;
        const breadcrumbPath = release
            ? routeLinks.project(release.ProjectId).release(release).root
            : null;

        const artifacts = this.loadArtifactsPromise();

        const result = {
            taskDetails,
            activityElements,
            artifacts: await artifacts,
            interruptions: await interruptionsPromise,
            history: await eventsPromise,
            task: taskDetails.Task,
            breadcrumbTitle,
            breadcrumbPath,
            hasLoadedOnce: true,
            release,
            channel,
            channelCount
        };

        if (this.props.additionalRefresh) {
            await this.props.additionalRefresh(taskDetails.Task);
        }

        return result;
    }

    // This is a bit hacky since auto-deploys that kick off from same deployment will have the same task prefix
    setIdPrefix(element: ActivityElement, n: number): UniqueActivityElement {
        return {
            ...element,
            uniqueId: n + "/" + element.Id,
            Children: element.Children ? element.Children.map(c => this.setIdPrefix(c, n)) : null
        };
    }

    setVerbose = (value: boolean) => {
        this.setState({verbose: value}, async () => await this.doRefresh());
    }

    setTail = (value: boolean) => {
        this.setState({tail: value}, async () => await this.doRefresh());
    }

    renderEditStateButton = () => {
        const task = this.state.task;
        if (!task.IsCompleted ||
            statesThatCanBeModified.indexOf(task.State) === -1) {
            return null;
        }

        return OverflowMenu.dialogItem("Edit state",
                <ModifyTaskStateDialog
                    availableStates={statesThatCanBeModified}
                    currentTaskState={task.State}
                    onStateChanged={this.changeTaskState}/>,
            {
                permission: Permission.TaskEdit,
                project: this.props.projectId,
                environment: this.props.environmentId,
                tenant: "*"});
    }

    renderTryAgainButton = () => {
        const task = this.state.task;
        if (!task.IsCompleted) {
            return null;
        }

        if (task.Name === "AdHocScript") {
            const path = task.Arguments.ActionTemplateId ?
                routeLinks.library.stepTemplate(task.Arguments.ActionTemplateId).run :
                routeLinks.tasks.console;
            return OverflowMenu.navItem("Modify and re-run", path, `retry=${task.Id}`);
        } else if (task.CanRerun) {
            return OverflowMenu.item(task.FinishedSuccessfully ? "Re-run" : "Try again",
                this.rerun, {permission: Permission.TaskCreate, project: this.props.projectId, environment: this.props.environmentId, tenant: "*"});
        }
    }

    renderDeployToButton = () => {
        const canRenderDeployTo = this.state.release
            && this.state.taskDetails
            && !this.state.taskDetails.Task.HasPendingInterruptions
            && this.state.taskDetails.Task.State !== TaskStateEnum.Executing;

        return canRenderDeployTo && OverflowMenu.navItem(
            "Deploy To...",
                routeLinks.project(this.state.release.ProjectId)
                    .release(this.state.release.Id)
                    .deployments.create(DeploymentCreateGoal.To, ""),
            null,
            {permission: Permission.DeploymentCreate, project: this.state.release.ProjectId, environment: "*", tenant: "*"});
    }

    changeTaskState = (newTaskState: string, reason: string) => {
        this.doBusyTask(async () => {
            this.setState({task: await repository.Tasks.changeState(this.state.task, newTaskState, reason)});
        });
    }

    rerun = () => {
        this.doBusyTask(async () => {
            const newTask = await repository.Tasks.rerun(this.state.task);
            this.setState({redirectTo: routeLinks.task(newTask).root});
        });
    }

    performCancel = () => {
        this.doBusyTask(async () => {
            try {
                this.setState({cancelPending: true});
                await repository.Tasks.cancel(this.state.task);
                await this.doRefresh();
            } finally {
                this.setState({cancelPending: false});
            }
        });
    }

    renderCancelButton = () => {
        const task = this.state.task;
        if (task.IsCompleted) {
            return null;
        }

        return <PermissionCheck permission={Permission.TaskCancel}
                                project={this.props.projectId}
                                environment={this.props.environmentId}
                                tenant="*">
            <RaisedButton
                type="submit"
                label="Cancel"
                disabled={this.state.cancelPending}
                backgroundColor={alert}
                labelColor={white}
                onClick={this.performCancel}
            />
        </PermissionCheck>;
    }

    render() {
        const redirectTo = this.state.redirectTo;
        if (redirectTo) {
            return <InternalRedirect to={{pathname: redirectTo}} push={false} />;
        }

        const initialLogs = new URI(this.props.location.search).search(true).tasklineid;
        const details = this.state.taskDetails;
        const task = this.state.task;
        const canRender = task && details && !this.props.delayRender();
        const overflowMenuItems = this.getOverflowMenu(canRender);
        const overflow = <OverflowMenu menuItems={overflowMenuItems}/>;
        const actions = canRender ? [this.renderCancelButton(),
        ...(this.props.additionalActions && this.props.additionalActions(task)), overflow] : [];

        const interruptionsCount = (canRender && !task.IsCompleted && this.state.interruptions && this.state.interruptions.Items)
            ? this.state.interruptions.Items.filter(i => i.IsPending).length
            : 0;

        const summaryWarning = interruptionsCount > 0 ?
            `This task has interruption${interruptionsCount > 1 ? "s" : ""} preventing it from continuing` :
            null;

        return <PaperLayout
            title={task ? task.Description : "Task"}
            titleLogo={task ? <TaskStatusIcon item={task}/> : null}
            breadcrumbTitle={this.state.breadcrumbTitle}
            breadcrumbPath={this.state.breadcrumbPath}
            breadcrumbChip={this.state.channelCount > 1 ? <ChannelChip channelName={this.state.channel.Name} fullWidth={true} to={routeLinks.channel(this.state.channel.Id)}  /> : null}
            busy={this.state.busy}
            enableLessIntrusiveLoadingIndicator={this.state.hasLoadedOnce}
            errors={this.state.errors}
            sectionControl={<ActionList actions={actions}/>}
            fullWidth={true}>
            {canRender &&
            <TabContainer defaultValue="taskSummary">
                <TabItem label="Task Summary"
                         value="taskSummary"
                         warning={summaryWarning}>
                    <TaskSummary task={task}
                                 projectId={this.props.projectId}
                                 environmentId={this.props.environmentId}
                                 tenantId={this.props.tenantId}
                                 artifacts={this.state.artifacts}
                                 interruptions={this.state.interruptions}
                                 activityElements={this.state.activityElements}
                                 additionalSidebar={this.props.additionalSidebar}
                                 deploymentHistory={this.state.history}
                                 taskDetails={this.state.taskDetails}
                                 doRefresh={this.doRefresh} />
                </TabItem>
                <TabItem label="Task Log" value="taskLog">
                    <TaskLog details={details}
                             verbose={this.state.verbose}
                             activityElements={this.state.activityElements}
                             tail={this.state.tail}
                             initialExpandedId={initialLogs}
                             showAdditional={() => this.setTail(false)}
                             setVerbose={this.setVerbose}
                             setTail={this.setTail}
                    />
                </TabItem>
                {task.Name === TaskName.AdHocScript &&
                <TabItem label={task.Arguments.ActionTemplateId ? "Template Parameters" : "Script body"}
                         value="adHocScriptSummary">
                    <AdHocScriptTaskSummary task={task}/>
                </TabItem>}
            </TabContainer>}
        </PaperLayout>;
    }

    componentDidUpdate() {
        if (new URI(this.props.location.search).search(true).tasklineid) {
            clearLogFromURI(this.props);
        }
    }

    private doRefresh: Refresh = () => Promise.resolve();

    private getOverflowMenu = (canRender: boolean) => {
        return (canRender ? [this.renderEditStateButton(),
            this.renderTryAgainButton(),
            this.renderDeployToButton()
        ] : []).filter(x => x);
    }

    private loadArtifactsPromise = () => {
        return isAllowed({
            permission: Permission.ArtifactView,
            project: this.props.projectId,
            environment: this.props.environmentId,
            tenant: this.props.tenantId,
        })
            ? repository.Artifacts.list({regarding: this.props.taskId, take: repository.takeAll, order: "asc"}).then(r => r.Items)
            : Promise.resolve([] as ArtifactResource[]);
    }
}

export default withRouter(Task);