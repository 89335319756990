exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_breadcrumb__qeygb {\n  font-size: 0.875rem;\n  /* mark.siedle: This matches the dataTableColumnHeader, so this has a consistent look. */\n  line-height: 1rem;\n  color: #9e9e9e;\n}\n.style_breadcrumb__qeygb .style_breadcrumbLink__20imO {\n  color: #333333;\n  text-decoration: none;\n}\n.style_breadcrumb__qeygb .style_breadcrumbLink__20imO > :active,\n.style_breadcrumb__qeygb .style_breadcrumbLink__20imO > :visited {\n  color: #333333;\n}\n.style_breadcrumb__qeygb .style_breadcrumbLink__20imO:hover {\n  color: #2f93e0;\n}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "style_breadcrumb__qeygb",
	"breadcrumbLink": "style_breadcrumbLink__20imO"
};