import * as React from "react";
import * as cn from "classnames";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { DialogLayout } from "components/DialogLayout/DialogLayout";
import { ActionButton, ActionButtonType } from "components/Button/ActionButton";
import Dialog from "components/Dialog/Dialog";
const styles = require("./confirmationDialog.less");

interface ConfirmationDialogProps {
    open: boolean;
    title: string;
    continueButtonLabel?: string;
    continueButtonBusyLabel?: string;
    continueButtonType?: ActionButtonType;
    onContinueClick(): any;
    onClose(): any;
}

export default class ConfirmationDialog extends DataBaseComponent<ConfirmationDialogProps, DataBaseComponentState> {
    constructor(props: ConfirmationDialogProps) {
        super(props);
        this.state = {};
    }

    onContinueClick() {
        return this.doBusyTask(async () => {
            const result = await this.props.onContinueClick();
            if (result) {
                this.props.onClose();
            }
        });
    }

    render() {
        const cont = <ActionButton key="Continue"
            label={this.props.continueButtonLabel || "Continue"}
            type={this.props.continueButtonType || ActionButtonType.Save}
            busyLabel={this.props.continueButtonBusyLabel}
            disabled={this.state.busy}
            onClick={() => this.onContinueClick()} />;

        const cancel = <ActionButton key="Cancel" label="Cancel" disabled={this.state.busy}
            onClick={() => this.props.onClose()} />;

        return <Dialog open={this.props.open || false}>
            <DialogLayout actions={[cancel, cont]}
                title={this.props.title}
                titleIcon={<em className={cn("fa fa-warning", styles.warningIcon)} />}
                headerClassName={styles.dialogHeader}
                busy={this.state.busy}
                closeDialog={this.props.onClose}
                errors={this.state.errors}>
                {this.props.open && this.props.children}
            </DialogLayout>
        </Dialog>;
    }

}