import {NamedResource} from "./namedResource";
import {ActionTemplateParameterResource} from "./actionTemplateParameterResource";

export enum VariableSetContentType {
    Variables = "Variables",
    ScriptModule = "ScriptModule"
}

export interface LibraryVariableSetResource extends NamedResource {
    Description: string;
    VariableSetId: string;
    ContentType: VariableSetContentType;
    Templates: ActionTemplateParameterResource[];
}

export default LibraryVariableSetResource;