import * as React from "react";
import {connect} from "react-redux";
import {Dispatch, Action} from "redux";
import * as actions from "./reducers/dialog";
import * as selectors from "./selectors";
import GlobalState from "globalState";
import { compose } from "recompose";
import withDialogIdentifier from "./withDialogIdentifier";

interface DialogTriggerRenderProps {
    open: boolean;
    sequence: string;
    closeDialog(): void;
    openDialog(): void;
}

interface DialogTriggerProps {
    render: (props: DialogTriggerRenderProps) => React.ReactElement<any>;
}

class DialogTrigger extends React.PureComponent<DialogTriggerProps & DialogTriggerRenderProps> {
    constructor(props: DialogTriggerProps & DialogTriggerRenderProps) {
        super(props);
    }

    render() {
        const {open, closeDialog, openDialog, sequence, render} = this.props;
        return render({open, closeDialog, openDialog, sequence});
    }

    open() {
        this.props.openDialog();
    }
}

const mapStateToProps = (state: GlobalState, {sequence}: {sequence: string}) => ({
    open: selectors.isDialogOpen(sequence)(state),
    sequence
});

const mapDialogTriggerDispatchProps = (dispatch: Dispatch<Action<any>>, { sequence }: { sequence: string}) => ({
    openDialog: () => dispatch(actions.openDialog(sequence)),
    closeDialog: () => dispatch(actions.onDialogClose()),
});

type EnhancedTriggerProps = Pick<DialogTriggerProps, "render"> & Partial<DialogTriggerProps>;

function enhancer<T>(comp: React.ComponentType<T & DialogTriggerRenderProps>): React.ComponentType<T & Partial<DialogTriggerRenderProps>> {
    return compose<T & DialogTriggerRenderProps, T & Partial<DialogTriggerRenderProps>>(
        withDialogIdentifier(),
        connect(mapStateToProps, mapDialogTriggerDispatchProps)
    )(comp);
}

export { enhancer as withDialogTriggers, EnhancedTriggerProps, DialogTriggerProps, DialogTriggerRenderProps, DialogTrigger };

const EnhancedDialogTrigger = enhancer(DialogTrigger);

export default EnhancedDialogTrigger;