exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_dragHandlePositioningContainer__yob4X {\n  position: relative;\n  width: 0;\n  height: 100%;\n}\n.style_dragHandleDraggableArea__1-Y5C {\n  position: absolute;\n  top: 0.25rem;\n  bottom: 0.25rem;\n  left: -0.5rem;\n  width: 1rem;\n  cursor: col-resize;\n  display: flex;\n  justify-content: center;\n}\n.style_dragHandleIndicator__2PDZd {\n  width: 0.0625rem;\n}\n.style_dragHandleIndicatorLight__obK0P {\n  background-color: #cccccc;\n}\n.style_dragHandleIndicatorDark__2GL-x {\n  background-color: rgba(0, 0, 0, 0.6);\n}\n", ""]);

// exports
exports.locals = {
	"dragHandlePositioningContainer": "style_dragHandlePositioningContainer__yob4X",
	"dragHandleDraggableArea": "style_dragHandleDraggableArea__1-Y5C",
	"dragHandleIndicator": "style_dragHandleIndicator__2PDZd",
	"dragHandleIndicatorLight": "style_dragHandleIndicatorLight__obK0P",
	"dragHandleIndicatorDark": "style_dragHandleIndicatorDark__2GL-x"
};