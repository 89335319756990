import * as React from "react";
import {DataBaseComponent, DataBaseComponentState, DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import {DialogLayout} from "components/DialogLayout/DialogLayout";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import Dialog from "components/Dialog/Dialog";
import {ReactNode} from "react";

interface DeleteDialogProps {
    open: boolean;
    title: string;
    deleteButtonLabel?: string;
    deleteButtonBusyLabel?: string;
    deleteButtonDisabled?: boolean;
    renderContent(doBusyTask: DoBusyTask): ReactNode;
    onDeleteClick(): Promise<boolean>;
    onClose(): void;
}

export default class DeleteDialog extends DataBaseComponent<DeleteDialogProps, DataBaseComponentState> {
    constructor(props: DeleteDialogProps) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps: DeleteDialogProps) {
        if (this.props.open !== nextProps.open) {
            this.setState({errors: null});
        }
    }

    onDeleteClick() {
        return this.doBusyTask(async () => {
            const result = await this.props.onDeleteClick();
            if (result) {
                this.props.onClose();
            }
        });
    }

    render() {
        const del = <ActionButton key="Continue"
                                  label={this.props.deleteButtonLabel || "Delete"}
                                  type={ActionButtonType.Delete}
                                  busyLabel={this.props.deleteButtonBusyLabel || "Deleting..."}
                                  disabled={this.props.deleteButtonDisabled || this.state.busy}
                                  onClick={() => this.onDeleteClick()}/>;

        const cancel = <ActionButton key="Cancel" label="Cancel"
                                     disabled={this.state.busy}
                                     onClick={() => this.props.onClose()}/>;

        return <Dialog open={this.props.open || false}>
            <DialogLayout actions={[cancel, del]}
                          title={this.props.title}
                          busy={this.state.busy}
                          closeDialog={this.props.onClose}
                          errors={this.state.errors}>
                {this.props.open && this.props.renderContent(this.doBusyTask)}
            </DialogLayout>
        </Dialog>;
    }

}
