exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .DialogFrames_hide-sm__31WAW {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .DialogFrames_visible-sm__2yIR8 {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .DialogFrames_hide-md__3Zk03 {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .DialogFrames_visible-md__BeoMB {\n    display: none !important;\n  }\n}\n.DialogFrames_frame__3Bpma {\n  position: fixed;\n  display: flex;\n  flex-direction: column;\n}\n.DialogFrames_wrapper__3xxoK {\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n}\n@media (max-width: 37.4rem) {\n  .DialogFrames_wrapper__3xxoK {\n    align-items: inherit;\n  }\n}\n.DialogFrames_opague__24NFL {\n  background: #fff;\n}\n.DialogFrames_padded__1-DbX {\n  padding: 1em;\n}\n.DialogFrames_fullscreen__2mX8f {\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n.DialogFrames_shadow__1uiCX {\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;\n}\n.DialogFrames_huge__3CxJU {\n  top: 5%;\n  left: 5%;\n  bottom: 5%;\n  right: 5%;\n  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 45px, rgba(0, 0, 0, 0.22) 0px 10px 18px;\n  border-radius: 2px;\n}\n@media (max-width: 37.4rem) {\n  .DialogFrames_huge__3CxJU {\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n  }\n}\n.DialogFrames_medium__3UHr8 {\n  display: flex;\n  flex-direction: column;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n  width: 75%;\n  max-width: 1000px;\n}\n@media (max-width: 79.9rem) {\n  .DialogFrames_medium__3UHr8 {\n    width: 100%;\n  }\n}\n.DialogFrames_small__1pdIL {\n  max-width: 600px;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "DialogFrames_hide-sm__31WAW",
	"hideSm": "DialogFrames_hide-sm__31WAW",
	"visible-sm": "DialogFrames_visible-sm__2yIR8",
	"visibleSm": "DialogFrames_visible-sm__2yIR8",
	"hide-md": "DialogFrames_hide-md__3Zk03",
	"hideMd": "DialogFrames_hide-md__3Zk03",
	"visible-md": "DialogFrames_visible-md__BeoMB",
	"visibleMd": "DialogFrames_visible-md__BeoMB",
	"frame": "DialogFrames_frame__3Bpma",
	"wrapper": "DialogFrames_wrapper__3xxoK",
	"opague": "DialogFrames_opague__24NFL",
	"padded": "DialogFrames_padded__1-DbX",
	"fullscreen": "DialogFrames_fullscreen__2mX8f",
	"shadow": "DialogFrames_shadow__1uiCX",
	"huge": "DialogFrames_huge__3CxJU",
	"medium": "DialogFrames_medium__3UHr8",
	"small": "DialogFrames_small__1pdIL"
};