exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__1ZVzG {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n}\n.style_select__1cFb6 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.style_inlineSearch__3IJgD {\n  display: flex;\n  flex-direction: row;\n  align-items: baseline;\n}\n.style_claimContent__gSpai {\n  display: block;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__1ZVzG",
	"select": "style_select__1cFb6",
	"inlineSearch": "style_inlineSearch__3IJgD",
	"claimContent": "style_claimContent__gSpai"
};