import * as React from "react";
import BaseComponent from "components/BaseComponent";
import Logo from "components/Logo";
import InternalLink from "components/Navigation/InternalLink";
import ActionList from "components/ActionList/ActionList";
import {
    default as OverflowMenu, OverflowMenuDeleteItem, OverflowMenuNavLink,
    OverflowMenuDialogItem
} from "components/Menu/OverflowMenu";
import {EnvironmentResource} from "client/resources/environmentResource";
import {ChannelChip, EnvironmentChip, MissingChip, ChipIcon} from "components/Chips/index";
import {ChannelResource} from "client/resources/channelResource";
import FeatureToggle, {Feature} from "components/FeatureToggle/FeatureToggle";
import {deploymentPartNameClass} from "uiTestClasses";
import {DisabledChip} from "components/Chips/index";
import * as cn from "classnames";
import {baseSizeInPx} from "fontWeights";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";

type MenuItem = OverflowMenuNavLink | OverflowMenuDialogItem | OverflowMenuDeleteItem;

const styles  = require("./style.less");

interface ChannelsLookup {
    Id: string;
    Channel: ChannelResource;
}

interface DeploymentPartProps {
    name: string;
    logoUrl: string;
    index: string;
    children?: any;
    detailsUrl: string;
    menuItems?: Array<MenuItem | MenuItem[]>;
    mainAction?: any;
    environments?: EnvironmentResource[];
    excludedEnvironments?: EnvironmentResource[];
    channelsLookup?: ChannelsLookup[];
    tags?: string[];
    isDisabled?: boolean;
    isPlaceholder?: boolean; //The step hasn't actually been saved yet
}

class DeploymentPart extends BaseComponent<DeploymentPartProps, any> {
    render() {
        const deploymentPartNameSpan = this.props.isDisabled
            ? <span className={`${deploymentPartNameClass}`}>{this.props.name} <DisabledChip /></span>
            : <span className={deploymentPartNameClass}>{this.props.name}</span>;

        const deploymentPartName = this.props.isPlaceholder
            ? deploymentPartNameSpan
            : <InternalLink to={this.props.detailsUrl} size={17 / baseSizeInPx}>
                {deploymentPartNameSpan}
              </InternalLink>;

        return <div className={styles.deploymentPart}>
            <div className={styles.actionSummary}>
                <div className={styles.logo}>
                    <Logo url={this.props.logoUrl} isDisabled={this.props.isDisabled} />
                </div>
                <div className={cn(styles.content, this.props.isDisabled ? styles.disabled : null)}>
                    <div className={styles.contentHeader}>
                        <span className={styles.index}>{this.props.index}</span>
                        {deploymentPartName}
                    </div>
                    <div className={styles.contentBody}>
                        {this.props.children}
                        <div>
                            {this.props.environments && this.props.environments.map(e => <EnvironmentChip key={e.Id} environmentName={e.Name} />)}
                            {this.props.excludedEnvironments &&
                                this.props.excludedEnvironments.map(e => <EnvironmentChip key={e.Id} environmentName={e.Name} isExcluded={true} />)}
                            {this.props.channelsLookup && this.props.channelsLookup.map(ch => this.getChipForChannel(ch.Id, ch.Channel))}
                            <FeatureToggle feature={Feature.MultiTenancy} enabled={true}>
                                {this.props.tags && <TenantTagsList tags={this.props.tags} />}
                            </FeatureToggle>
                        </div>
                    </div>
                </div>
                <div className={styles.contextualActions}>
                    {/* if the step hasn't been saved yet, dont show an overflow menu */}
                    {this.props.isPlaceholder
                      ? ""
                      : <ActionList actions={[
                          this.props.mainAction,
                          <OverflowMenu key={`${this.props.name}-overflow`} menuKey={`${this.props.name}-overflow`} menuItems={this.props.menuItems} />
                          ].filter(a => !!a)}/> }
                </div>
            </div>
        </div>;
    }

    private getChipForChannel(id: string, channel: ChannelResource) {
        return channel
            ? <ChannelChip key={channel.Id} channelName={channel.Name}/>
            : <MissingChip lookupId={id} type={ChipIcon.Channel} />;
    }
}

export default DeploymentPart;