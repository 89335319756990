import StringHelper from "utils/StringHelper";

export default class PageTitleHelper {
    public static setPageTitle(title: string, breadcrumbTitle?: string) {
        // This intentionally only sets the title if we're given one (don't set a default here). This lets us
        // call this helper in a fall-through style. ie. From our layouts first (via AreaTitle), then through to the
        // page (via PaperLayout).
        if (title && typeof title === "string" && title !== StringHelper.ellipsis) {
            const octopusDeploy = "Octopus Deploy";
            let pageTitle = octopusDeploy;
            // tslint:disable-next-line:prefer-conditional-expression
            if (breadcrumbTitle) {
                pageTitle = `${breadcrumbTitle} > ${title} - ${octopusDeploy}`; // Similar to TeamCity, show the most-relevant title first.
            } else {
                pageTitle = `${title} - ${octopusDeploy}`; // Similar to TeamCity, show the most-relevant title first.
            }
            document.title = pageTitle;
        }
    }

    public static setRootPageTitle() {
        document.title = "Octopus Deploy";
    }
}