exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_selectedIcon__3fJJ7 {\n  top: 0.2rem;\n  position: relative;\n  padding-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"selectedIcon": "style_selectedIcon__3fJJ7"
};