exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_controlCell__12fb0 {\n  height: 100%;\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n}\n.style_lastColumn__1BGxG {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n}\n.style_scopeCell__20fhN {\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n.style_fullSizeCell__9KUwh {\n  height: 100%;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"controlCell": "style_controlCell__12fb0",
	"lastColumn": "style_lastColumn__1BGxG",
	"scopeCell": "style_scopeCell__20fhN",
	"fullSizeCell": "style_fullSizeCell__9KUwh"
};