import * as React from "react";
import ActionButton, { ActionButtonType } from "../../Button/ActionButton";
import BaseLayout from "./BaseLayout";
import { LargeDialogFrame, DialogFrameProp } from "./DialogFrames";
import { RenderProps } from "components/Dialog/CustomDialog";
import * as cn from "classnames";

export type TemplatedDialogLayoutProps  = RenderProps & DialogFrameProp & {
    renderTitle?: (props: RenderProps) => React.ReactElement<any>;
    renderContent: (props: RenderProps) => React.ReactElement<any>;
    renderFooter?: (props: RenderProps) => React.ReactElement<any>;
};

export const TemplatedDialogLayout: React.SFC<TemplatedDialogLayoutProps> = ({renderTitle, renderContent, renderFooter, close, open, frame: Frame = LargeDialogFrame}) => (
    <BaseLayout open={open} close={close} render={(renderProps) => (
        <Frame>
            {renderTitle && renderTitle(renderProps)}
            {renderContent && renderContent(renderProps)}
            {renderFooter && renderFooter(renderProps)}
        </Frame>
    )}/>
);

export default TemplatedDialogLayout;