exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_certInstructionList__2xx51 {\n  margin: 0;\n  margin-left: 2rem;\n  list-style-type: decimal;\n}\n.style_certInstructionList__2xx51 li {\n  padding-bottom: 0.625rem;\n}\n.style_certInstructionList__2xx51 li:last-child {\n  padding-bottom: 0;\n}\n.style_testingSuccess__hNxZJ {\n  display: flex;\n}\n.style_testingSuccess__hNxZJ span:nth-child(2) {\n  margin-left: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"certInstructionList": "style_certInstructionList__2xx51",
	"testingSuccess": "style_testingSuccess__hNxZJ"
};