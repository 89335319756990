exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_menuIcon__rA8-Q {\n  font-size: 1.37500rem;\n  height: 2.25rem!important;\n  padding: 0!important;\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: #333333;\n}\n.style_menuIcon__rA8-Q:visited {\n  color: #333333;\n}\n.style_menuIcon__rA8-Q:hover {\n  opacity: 0.5;\n}\n.style_disabledItem__1s4p7 {\n  cursor: not-allowed;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"menuIcon": "style_menuIcon__rA8-Q",
	"disabledItem": "style_disabledItem__1s4p7"
};