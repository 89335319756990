import * as React from "react";
import pluginRegistry, {ActionEditProps} from "../../Actions/pluginRegistry";
import {BaseComponent} from "../../BaseComponent/BaseComponent";
import {KubernetesIngressComponent} from "components/Actions/kubernetes/kubernetesIngressComponent";
import {KubernetesIngressProperties} from "components/Actions/kubernetes/kubernetesProperties";

class KubernetesIngressEditFeature extends BaseComponent<ActionEditProps<KubernetesIngressProperties>, never> {
    render() {
        return <KubernetesIngressComponent
            properties={this.props.properties}
            packages={this.props.packages}
            plugin={this.props.plugin}
            getFieldError={this.props.getFieldError}
            setProperties={this.props.setProperties}
            setPackages={this.props.setPackages}
            doBusyTask={this.props.doBusyTask}
            busy={this.props.busy}
            errors={this.props.errors}
            projectId={this.props.projectId}
            expandedByDefault={true}
            standAlone={false}/>;
    }
}

pluginRegistry.registerFeature({
    featureName: "Octopus.Features.KubernetesIngress",
    title: "Ingress",
    description: "Defines a Kubernetes Ingress resource, which is used to map HTTP traffic to Service resources based on host name and URL path",
    edit: KubernetesIngressEditFeature,
    priority: 10
});