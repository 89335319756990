import releaseRouteLinks from "../Releases/ReleasesRoutes/releaseRouteLinks";
import {ProjectResource} from "../../../../client/resources/projectResource";
import {DashboardProjectResource} from "../../../../client/resources/dashboardProjectResource";
import {ChannelResource} from "../../../../client/resources/channelResource";
import {TriggerResource, TriggerActionType} from "../../../../client/resources/triggerResource";

export default (root: string) => ({
    v3projectRoutes: {
        newProject: `${root}/projects/new`
    },
    projects: {
        root: `${root}/projects`,
        filteredByGroup: (projectGroupId: string) => `${root}/projects?projectGroupId=${projectGroupId}`
    },
    trigger: trigger(root),
    channel: channel(root),
    release: (releaseId: string) => `${root}/releases/${releaseId}`,
    project: (projectSlug: string | ProjectResource | DashboardProjectResource) => {
        if (!!projectSlug && typeof projectSlug !== "string") {
            projectSlug = projectSlug.Slug;
        }
        const specificRoot = `${root}/projects/${projectSlug}`;
        return {
            root: specificRoot,
            ...releaseRouteLinks(specificRoot),
            overview: `${specificRoot}/overview`,
            settings: `${specificRoot}/settings`,
            channels: `${specificRoot}/channels`,
            channel: channel(specificRoot),
            triggers: `${specificRoot}/triggers`,
            trigger: trigger(specificRoot),
            scheduledTrigger: (triggerId: string | TriggerResource) => {
                if (!!triggerId && typeof triggerId !== "string") {
                    triggerId = triggerId.Id;
                }
                return `${specificRoot}/triggers/scheduled/edit/${triggerId}`;
            },
            variables: {
                root: `${specificRoot}/variables`,
                projectTemplates: `${specificRoot}/variables/projectvariabletemplates`,
                commonTemplates: `${specificRoot}/variables/commonvariabletemplates`,
                library: `${specificRoot}/variables/library`,
                preview: `${specificRoot}/variables/preview`,
                all: `${specificRoot}/variables/all`
            },
            process: {
                root: `${specificRoot}/process`,
                stepTemplates: `${specificRoot}/process/steptemplates`,
                childStepTemplates: (parentStepId: string) => ({
                    root: `${specificRoot}/process/childsteptemplates/${parentStepId}`
                }),
                stepNew: (templateType: string, parentStepId?: string, templateId?: string) => {
                    let base = `${specificRoot}/process/step`;
                    if (parentStepId) {
                        base = `${base}/${parentStepId}`;
                    }
                    base = `${base}/new/${templateType}`;
                    if (templateId) {
                        base = `${base}/${templateId}`;
                    }
                    return base;
                },
                step : (actionId: string) => `${specificRoot}/process/step/${actionId}`
            },
            childStepTemplates: (parentStepId: string) => ({
                root: `${specificRoot}/childsteptemplates/${parentStepId}`
            }),
            steptemplates: `${specificRoot}/steptemplates`
        };
    }
});

function trigger(root: string) {
    return (triggerId: string | TriggerResource, triggerType: TriggerActionType = TriggerActionType.AutoDeploy) => {
        if (!!triggerId && typeof triggerId !== "string") {
            triggerId = triggerId.Id;
        }

        if (triggerType === TriggerActionType.AutoDeploy) {
            return `${root}/triggers/edit/${triggerId}`;
        }

        return `${root}/triggers/scheduled/edit/${triggerId}`;
    };
}

function channel(root: string) {
    return (channelId: string | ChannelResource) => {
        if (!!channelId && typeof channelId !== "string") {
            channelId = channelId.Id;
        }
        return `${root}/channels/edit/${channelId}`;
    };
}