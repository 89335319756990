import * as React from "react";
import {DataBaseComponentState} from "components/DataBaseComponent";
import {DataBaseComponent} from "components/DataBaseComponent/DataBaseComponent";
import GlobalState from "globalState";
import {openDialog} from "components/Dialog/reducers/dialog";
import {connect} from "react-redux";
import {ActionButton} from "components/Button/ActionButton";
import ExampleDialogContent from "components/StyleGuide/ExampleDialogContent";
import Dialog from "components/Dialog/Dialog";

interface ExampleComponentThatShowsDialogsProps {
    someProp?: boolean;
}

interface ExampleComponentThatShowsDialogsState extends DataBaseComponentState {
    someState?: string;
}

interface DispatchProps {
    openDialog(id: string): void;
}

interface StateProps {
    dialogAOpen: boolean;
    dialogBOpen: boolean;
}

type Props = ExampleComponentThatShowsDialogsProps & StateProps & DispatchProps;

class ExampleComponentThatShowsDialogsInternal extends DataBaseComponent<Props, ExampleComponentThatShowsDialogsState> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    doSomeProcess() {
        // Do stuff
        this.props.openDialog(Math.random() < 0.5 ? "DialogA" : "DialogB");
    }

    render() {
        return <div>
            <ActionButton onClick={() => this.doSomeProcess()} label="Do a process that opens a dialog"/>
            <Dialog open={this.props.dialogAOpen}>
                <ExampleDialogContent title="Dialog A"/>
            </Dialog>
            <Dialog open={this.props.dialogBOpen}>
                <ExampleDialogContent title="Dialog B"/>
            </Dialog>
        </div>;
    }
}

const mapStateToProps = (state: GlobalState) => {
    return {
        dialogAOpen: state.dialogs && state.dialogs["DialogA"],
        dialogBOpen: state.dialogs && state.dialogs["DialogB"]
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        openDialog: (key: string) => {
            dispatch(openDialog(key));
        }
    };
};

const ExampleComponentThatShowsDialogs = connect<StateProps, DispatchProps, ExampleComponentThatShowsDialogsProps>(
    mapStateToProps,
    mapDispatchToProps
)(ExampleComponentThatShowsDialogsInternal);

export default ExampleComponentThatShowsDialogs;
