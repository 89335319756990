import Client from "../client";
import ConfigurationRepository from "./configurationRepository";
import {SmtpConfigurationResource} from "client/resources";

class SmtpConfigurationRepository extends ConfigurationRepository<SmtpConfigurationResource> {
    constructor(client: Client) {
        super("SmtpConfiguration", client);
    }
}

export default SmtpConfigurationRepository;