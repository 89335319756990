import * as React from "react";
import * as ReactDOM from "react-dom";
import * as cn from "classnames";
import { DialogLayoutDispatchProps } from "../DialogLayout/DialogLayout";

const styles = require("./CustomDialog.less");

interface BodyPortalProps {
    bodyClasses?: string;
}

class BodyPortal extends React.Component<BodyPortalProps, any> {
    mount: HTMLDivElement;

    constructor(props: BodyPortalProps) {
        super(props);
        this.mount = document.createElement("div");
    }

    componentDidMount() {
        document.body.className = cn(document.body.className, this.props.bodyClasses);
        document.body.appendChild(this.mount);
    }

    componentWillUnmount() {
        document.body.classList.remove(this.props.bodyClasses);
        document.body.removeChild(this.mount);
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.mount);
    }
}

interface CustomDialogStateProps {
    open: boolean;
}

export type RenderProps = DialogLayoutDispatchProps & CustomDialogStateProps;

interface CustomDialogRenderProps extends RenderProps {
    render: (props: RenderProps) => React.ReactElement<any>;
}

export type CustomDialogProps = CustomDialogRenderProps & DialogLayoutDispatchProps & CustomDialogStateProps;

export const Overlay: React.SFC<{className?: string}> = ({children, className}) => {
    return <BodyPortal bodyClasses={styles.noscroll}>
        <div className={cn(styles.overlay, className)}>{children}</div>
    </BodyPortal>;
};

export const CustomDialog: React.SFC<CustomDialogProps> = ({open, close, render }) => {
    if (!open) {
        return null;
    }

    const renderProps = { open, close };
    return <Overlay>
        {render(renderProps)}
    </Overlay>;
};

export default CustomDialog;