import * as React from "react";
import { TenantResource } from "client/resources";
import Logo from "components/Logo/Logo";
import Tag from "components/Tag/index";
import MissingVariablesIcon from "../MissingVariablesIcon/MissingVariablesIcon";
import { TagIndex } from "components/tenantTagsets";
import Card from "components/Card/Card";
const styles = require("./style.less");
import routeLinks from "../../../routeLinks";
import InternalLink from "../../../components/Navigation/InternalLink/InternalLink";

interface TenantCardProps {
    tenant: TenantResource;
    hasMissingVariables: boolean;
    tagIndex: TagIndex;
}

const TenantCard: React.StatelessComponent<TenantCardProps> = props => {
    const tenant = props.tenant;
    return <Card logo={<div className={styles.logo}>
        <Logo url={tenant.Links.Logo} />
        <div className={styles.missingIconContainer}>
            <div className={styles.missingIconInner}>
                <MissingVariablesIcon show={props.hasMissingVariables} />
            </div>
        </div>
    </div>}
        header={tenant.Name}
        content={tenant.TenantTags &&
            tenant.TenantTags.map(tt => <Tag
                key={tt}
                description={props.tagIndex[tt].Description}
                tagName={props.tagIndex[tt].Name}
                tagColor={props.tagIndex[tt].Color}
                small={true} />)}
        link={<InternalLink to={routeLinks.tenant(tenant).root} />}
    />;
};

export default TenantCard;