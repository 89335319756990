import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";

export interface NginxBinding {
    protocol: string;
    ipAddress: string;
    port: string;
    certificateVariable?: any;
    certificateLocation: string;
    certificateKeyLocation: string;
    securityProtocols: string[];
    enabled: boolean;
}

// NGINX supports: [SSLv2] [SSLv3] [TLSv1] [TLSv1.1] [TLSv1.2] [TLSv1.3];
const securityProtocolItems: SelectItem[] = [
    {Id: "SSLv2", Name: "SSLv2"},
    {Id: "SSLv3", Name: "SSLv3"},
    {Id: "TLSv1", Name: "TLSv1"},
    {Id: "TLSv1.1", Name: "TLSv1.1"},
    {Id: "TLSv1.2", Name: "TLSv1.2"},
    {Id: "TLSv1.3", Name: "TLSv1.3"},
];

const nginxBindingsFromString = (bindingsString: string): NginxBinding[] => {
    return JSON.parse(bindingsString);
};

const nginxBindingsToString = (bindings: NginxBinding[]): string => {
    return JSON.stringify(bindings);
};

export {nginxBindingsFromString, nginxBindingsToString, securityProtocolItems};