import * as React from "react";
import { AccountResource, ResourceCollection, TagSetResource, EnvironmentResource, TenantResource, AccountType } from "client/resources";
import { tenantChipListIncludingMissing, environmentChipListIncludingMissing } from "components/Chips";
import ListTitle from "components/ListTitle/ListTitle";
import Tag from "components/Tag";
import RadioButtonGroup from "components/form/RadioButton/RadioButtonGroup";
import RadioButton from "components/form/RadioButton/RadioButton";
import MarkdownDescription from "components/MarkdownDescription";

const styles = require("./AccountSummary.less");

interface AccountSummaryProps {
    account: AccountResource;
    environments: EnvironmentResource[];
    tenants: TenantResource[];
    tenantTags: TagSetResource[];
    selected?: boolean;
    showSelection?: boolean;
    onClick(): void;
}

const AccountSummary: React.SFC<AccountSummaryProps> = ({account, onClick, environments, tenants, tenantTags, showSelection, selected}) => {
    return <div className={styles.container} onClick={onClick}>
        {showSelection && <div className={styles.select}>
            <RadioButtonGroup value={selected}
                              onChange={null}
                              noMargin={true} >
                <RadioButton value={true} />
            </RadioButtonGroup>
        </div>}
        <div className={styles.summary}>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <ListTitle>{account.Name}</ListTitle>
                </div>
            </div>
            {account.Description &&
                <div className={styles.row}>
                    <MarkdownDescription markup={account.Description}/>
                </div>
            }
            <div className={styles.row}>
                <span>
                    {environmentChipListIncludingMissing(environments, account.EnvironmentIds)}
                </span>
                {(!!account.TenantIds.length || !!account.TenantTags.length) && <span>
                {tenantChipListIncludingMissing(tenants, account.TenantIds)}
                    {account.TenantTags.map(tt => {
                        const result = tenantTags.find(t => !!t.Tags.find(tag => tag.CanonicalTagName === tt));
                        if (result) {
                            const fu = result.Tags.find(tag => tag.CanonicalTagName === tt);
                            return <Tag
                                key={fu.Id}
                                description={fu.Description}
                                tagName={fu.Name}
                                tagColor={fu.Color}/>;
                        }
                    })}
              </span>}
            </div>
        </div>
    </div>;
};

export default AccountSummary;
export { AccountSummary, AccountSummaryProps };