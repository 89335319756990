exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_listItem__20dF_ {\n  padding: 1.25rem;\n  font-size: 0.875rem !important;\n}\n.style_listItem__20dF_ > div:first-child {\n  padding: 0!important;\n}\n.style_listItem__20dF_ > div:first-child > div:first-child {\n  padding: 0.875rem 1rem!important;\n}\n.style_emptyList__Jfq7l {\n  padding: 0.5rem 1rem;\n  font-size: 0.875rem;\n  color: #9e9e9e;\n}\n.style_pagingControlContainer__uFwzY {\n  margin: 1.5rem 1rem;\n}\n.style_disablePagingEvents__NzHoU {\n  pointer-events: none;\n}\n.style_loadMoreContainer__38w3G {\n  padding: 1rem;\n}\n.style_loadMoreActions__2AGP7 {\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  flex-direction: column;\n  align-items: center;\n}\n.style_loadMoreSubText__2PJQD {\n  font-size: 0.75rem;\n  color: #9e9e9e;\n}\n", ""]);

// exports
exports.locals = {
	"listItem": "style_listItem__20dF_",
	"emptyList": "style_emptyList__Jfq7l",
	"pagingControlContainer": "style_pagingControlContainer__uFwzY",
	"disablePagingEvents": "style_disablePagingEvents__NzHoU",
	"loadMoreContainer": "style_loadMoreContainer__38w3G",
	"loadMoreActions": "style_loadMoreActions__2AGP7",
	"loadMoreSubText": "style_loadMoreSubText__2PJQD"
};