export class BorderCss {
    constructor(readonly widthInRem: number,
                readonly style: BorderStyle,
                readonly color: string) {
    }

    get borderCssString() {
        return `${this.widthInRem}rem ${this.style} ${this.color}`;
    }
}

export type BorderStyle = "none" | "hidden" | "dotted" | "dashed" | "solid"
    | "double" | "groove" | "ridge" | "inset" | "outset" | "inherit" | "initial" | "unset";
