import {ListArgs} from "./basicRepository";
import Client from "../client";
import {EventCategoryResource, EventGroupResource} from "../resources";
import {EventResource, EventAgentResource} from "client/resources/eventResource";
import MixedScopeBaseRepository from "./mixedScopeBaseRepository";

interface EventListArgs extends ListArgs {
    from?: string;
    to?: string;
    fromAutoId?: number;
    toAutoId?: number;
    asCsv?: boolean;
    regarding?: string[];
    regardingAny?: string[];
    internal?: boolean;
    users?: string[];
    projects?: string[];
    environments?: string[];
    eventGroups?: string[];
    eventCategories?: string[];
    eventAgents?: string[];
    tenants?: string[];
    tags?: string[];
    documentTypes?: string[];
}

class EventRepository extends MixedScopeBaseRepository<EventResource, EventResource, EventListArgs> {
    constructor(client: Client) {
        super("Events", client);
    }

    categories(options: any): Promise<EventCategoryResource[]> {
        return this.client.get<EventCategoryResource[]>(this.client.getLink("EventCategories"), options);
    }

    groups(options: any): Promise<EventGroupResource[]> {
        return this.client.get<EventGroupResource[]>(this.client.getLink("EventGroups"), options);
    }

    documentTypes(options: any): Promise<EventGroupResource[]> {
        return this.client.get<EventGroupResource[]>(this.client.getLink("EventDocumentTypes"), options);
    }

    eventAgents(): Promise<EventAgentResource[]> {
        return this.client.get<EventAgentResource[]>(this.client.getLink("EventAgents"));
    }
}

export default EventRepository;