import * as React from "react";
import { DataCube } from "./DataCube";
import {
    ProgressionResource,
    ChannelResource,
    DashboardItemResource,
    TagSetResource,
    DashboardProjectResource,
    DashboardProjectGroupResource,
    ReferenceDataItem
} from "client/resources";
import {ReleaseResource} from "../../../../client/resources/releaseResource";
import {ProjectResource} from "../../../../client/resources/projectResource";
import {TenantedDeploymentMode} from "../../../../client/resources/tenantedDeploymentMode";
import {DashboardTenantResource} from "../../../../client/resources/dashboardTenantResource";
import {keyBy} from "lodash";
import {LifecycleResource} from "../../../../client/resources/lifecycleResource";

class ProgressionDataCube implements DataCube {
    data: ProgressionResource;
    nextAvailableDeployments: {[releaseId: string]: { [environmentId: string]: string[] }} = {};
    blockedReleases: string[] = [];
    tenantTagIndex: { [tenantId: string]: string[] } = {};
    lifecycleIndex: { [lifecycleId: string]: LifecycleResource };
    tagSetIndex: {[canonicalTagName: string]: TagSetResource} = {};
    channelIndex: {[channelId: string]: ChannelResource } = {};
    releaseIndex: {[releaseId: string]: ReleaseResource } = {};
    projectIndex: { [projectId: string]: DashboardProjectResource } = {};
    projectGroupIndex: { [projectGroupId: string]: DashboardProjectGroupResource } = {};
    tenantIndex: { [tenantId: string]: DashboardTenantResource } = {};
    environmentIndex: { [environmentId: string]: ReferenceDataItem } = {};
    channelEnvironments: { [index: string]: string[] };
    missingVariableTenants: string[] = [];
    deployments: DashboardItemResource[] = [];

    constructor(data: ProgressionResource, channels: ChannelResource[], project: ProjectResource) {
        this.data = data;
        this.channelIndex = keyBy(channels, (ch) => ch.Id);
        this.lifecycleIndex = {};
        this.releaseIndex = {};
        this.nextAvailableDeployments = {};

        this.projectIndex[project.Id] = {
            Slug: project.Slug,
            Name: project.Name,
            IsDisabled: project.IsDisabled,
            Id: project.Id,
            Links: project.Links,
            EnvironmentIds: null,
            ProjectGroupId: project.ProjectGroupId,
            CanPerformUntenantedDeployment: project.TenantedDeploymentMode !== TenantedDeploymentMode.Tenanted
        };

        this.channelEnvironments = Object.keys(data.ChannelEnvironments).reduce((idx: {[channelId: string]: string[]}, channelId) => {
            idx[channelId] = data.ChannelEnvironments[channelId].map(k => k.Id);
            return idx;
        }, {});

        this.deployments = (!data || !data.Releases)
            ? []
            : data.Releases.reduce((a, r) => {
                if (r.HasUnresolvedDefect) {
                    this.blockedReleases.push(r.Release.Id);
                }
                this.releaseIndex[r.Release.Id] = r.Release;
                // may not have access to this due to lacking permission
                if (r.NextDeployments) {
                    this.nextAvailableDeployments[r.Release.Id] = r.NextDeployments
                        .reduce<{ [environmentId: string]: string[] }>((ax: { [environmentId: string]: string[] }, e) => {
                        ax[e] = [null]; //The progression dataset is currently only used for non tenant releases
                        return ax;
                    }, {});
                }
                const g = Object.keys(r.Deployments).map(j => r.Deployments[j]);
                a = a.concat(...g);
                return a;
            }, []);

        this.environmentIndex = keyBy(this.data.Environments, env => env.Id);
    }

}

export default ProgressionDataCube;
