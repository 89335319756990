exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_nowrap__3WQwQ {\n  white-space: nowrap;\n}\n", ""]);

// exports
exports.locals = {
	"nowrap": "style_nowrap__3WQwQ"
};