import * as React from "react";
import ActionTemplateSearchResource from "client/resources/actionTemplateSearchResource";
import Logo from "components/Logo/Logo";

const styles = require("./styles.less");

interface ActionTemplateCategoryProps {
    name: string;
    templates: ActionTemplateSearchResource[];
    onCategorySelected: () => void;
}

const ActionTemplateCategory: React.StatelessComponent<ActionTemplateCategoryProps> = (props: ActionTemplateCategoryProps) => {
    const firstTemplate = props.templates[0];

    return <li className={styles.actionCategory} onClick={props.onCategorySelected}>
        <div className={styles.logo}>
            <Logo url={firstTemplate.Links.Logo} size="4.375rem" />
        </div>
        <div className={styles.categoryName}>
            <small>{props.name}</small>
        </div>
    </li>;
};

ActionTemplateCategory.displayName = "ActionTemplateCategory";

export default ActionTemplateCategory;