import * as React from "react";
import { TagIndex } from "components/tenantTagsets";
import { ExpandableFormSection, Summary, Note } from "components/form";
import TagsList from "../../../../components/TagsList";
import {AdvancedTenantTagsSelector} from "components/AdvancedTenantSelector/AdvancedTenantSelector";
import {DoBusyTask} from "components/DataBaseComponent/DataBaseComponent";
import TenantTagsList from "components/TenantTagsList/TenantTagsList";

interface TenantsProps {
    tenantTags: string[];
    tagIndex: TagIndex;
    doBusyTask: DoBusyTask;
    onTenantTagsChange(tenantTags: string[]): void;
}

export default class TenantsExpander extends React.Component<TenantsProps> {
    shouldComponentUpdate(nextProps: TenantsProps) {
        return nextProps.tenantTags !== this.props.tenantTags;
    }

    render() {
        return <ExpandableFormSection
            errorKey="tenantTags"
            title="Tenants"
            summary={this.props.tenantTags.length > 0
                ? Summary.summary(<span>This step will only run for tenants tagged with <TenantTagsList tags={this.props.tenantTags} /></span>)
                : Summary.default("This step will run for all tenants")}
            help="Choose which tenants this step applies to.">
            <Note>This step will only run for tenants matching this filter.
                Clear the filter to make the step run for all tenants.
                Note: if you define a filter, this step will be skipped when performing
                un-tenanted deployments.</Note>
            <AdvancedTenantTagsSelector
                selectedTenantTags={this.props.tenantTags}
                doBusyTask={this.props.doBusyTask}
                onChange={tags => this.props.onTenantTagsChange(tags)}
                showPreviewButton={true}
            />
            </ExpandableFormSection>;
    }
}
