exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__33VFX {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__Jc8GH {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__ml06o {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__b5yWh {\n    display: none !important;\n  }\n}\n.style_machinePolicySummary__3bQsr {\n  color: #333333;\n  font-size: 0.8125rem;\n}\n@media (max-width: 37.4rem) {\n  .style_machinePolicySummary__3bQsr {\n    padding: 0;\n    display: none;\n  }\n}\n.style_machinePolicySummaryLabel__249st {\n  text-align: left;\n  font-weight: 600;\n}\n@media (max-width: 37.4rem) {\n  .style_machinePolicySummaryLabel__249st {\n    text-align: initial;\n  }\n}\n.style_machinePolicySummary__3bQsr table {\n  background-color: transparent;\n}\n.style_machinePolicySummary__3bQsr tr:hover {\n  background-color: transparent !important;\n}\n.style_machinePolicySummary__3bQsr tr {\n  border: 0 !important;\n}\n.style_machinePolicySummary__3bQsr td {\n  padding: 0.125rem 0 !important;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__33VFX",
	"hideSm": "style_hide-sm__33VFX",
	"visible-sm": "style_visible-sm__Jc8GH",
	"visibleSm": "style_visible-sm__Jc8GH",
	"hide-md": "style_hide-md__ml06o",
	"hideMd": "style_hide-md__ml06o",
	"visible-md": "style_visible-md__b5yWh",
	"visibleMd": "style_visible-md__b5yWh",
	"machinePolicySummary": "style_machinePolicySummary__3bQsr",
	"machinePolicySummaryLabel": "style_machinePolicySummaryLabel__249st"
};