exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .SpacesMenu_hide-sm__2hP49 {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .SpacesMenu_visible-sm__17fqa {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .SpacesMenu_hide-md__Xr2E7 {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .SpacesMenu_visible-md__2z_Lx {\n    display: none !important;\n  }\n}\n.SpacesMenu_spaceIcon__1fuxZ {\n  margin-left: 1rem;\n  background-color: #ffffff;\n  width: 2rem;\n  height: 2rem;\n  border-radius: 50%;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.1875rem;\n  border-width: 0.0625rem;\n  border-style: solid;\n  border-color: #ffffff;\n  overflow: hidden;\n}\n.SpacesMenu_spaceIcon__1fuxZ img {\n  max-width: 1.5rem;\n  max-height: 1.5rem;\n}\n.SpacesMenu_spaceMenu__2Nfpw {\n  background-color: #0F2535;\n  text-decoration: none;\n  cursor: pointer;\n}\n.SpacesMenu_spaceMenu__2Nfpw a {\n  height: 100%;\n  color: #ffffff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.SpacesMenu_spaceMenu__2Nfpw a:hover {\n  background-color: #74c0fb;\n  color: #ffffff;\n}\n.SpacesMenu_spaceMenu__2Nfpw a:active,\n.SpacesMenu_spaceMenu__2Nfpw a:focus {\n  background-color: #0A67B1;\n}\n.SpacesMenu_caret__2kNkw {\n  padding: 0.5rem 1rem 0.5rem 0.5rem;\n}\n.SpacesMenu_title__JhIY6 {\n  margin-left: 0.5rem;\n  white-space: nowrap;\n  font-size: 1rem;\n  max-width: 12.5rem;\n  overflow: hidden;\n  font-weight: 500;\n}\n@media (max-width: 37.4rem) {\n  .SpacesMenu_title__JhIY6 {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "SpacesMenu_hide-sm__2hP49",
	"hideSm": "SpacesMenu_hide-sm__2hP49",
	"visible-sm": "SpacesMenu_visible-sm__17fqa",
	"visibleSm": "SpacesMenu_visible-sm__17fqa",
	"hide-md": "SpacesMenu_hide-md__Xr2E7",
	"hideMd": "SpacesMenu_hide-md__Xr2E7",
	"visible-md": "SpacesMenu_visible-md__2z_Lx",
	"visibleMd": "SpacesMenu_visible-md__2z_Lx",
	"spaceIcon": "SpacesMenu_spaceIcon__1fuxZ",
	"spaceMenu": "SpacesMenu_spaceMenu__2Nfpw",
	"caret": "SpacesMenu_caret__2kNkw",
	"title": "SpacesMenu_title__JhIY6"
};