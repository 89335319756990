import { Resource } from "client/resources";

export enum DefectStatus {
    Unresolved = "Unresolved",
    Resolved = "Resolved"
}

export class DefectResource extends Resource {
    Description: string;
    Status?: DefectStatus;
}