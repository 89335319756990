import * as React from "react";
import { EndpointRegistration, CategorizedEndpointResult } from "./endpointRegistry";

export interface RegistrationProps {
    registrations: EndpointRegistration[];
    categories: Record<string, CategorizedEndpointResult>;
}

export function withRegistrations(categorize: (items: EndpointRegistration[]) => Record<string, CategorizedEndpointResult>, getRegistrations: () => EndpointRegistration[]) {
    return <T extends {}>(Component: React.ComponentType<T & RegistrationProps>) => {
        class WithRegistrations extends React.Component<T & Partial<RegistrationProps>, RegistrationProps> {
            constructor(props: T & Partial<RegistrationProps>) {
                super(props);
                const registrations = getRegistrations();
                const categories = categorize(registrations);

                this.state = {
                    registrations,
                    categories
                };
            }

            render() {
                return <Component {...this.state} {...this.props} />;
            }
        }

        return WithRegistrations;
    };
}

export default withRegistrations;