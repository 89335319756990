exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__n_Ecp {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__P2EKE {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__2DPYJ {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__2OHkK {\n    display: none !important;\n  }\n}\n.style_paperContent__382gV {\n  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.2), 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.12), 0 0 0.125rem 0 rgba(0, 0, 0, 0.14);\n  border-radius: 0.25rem;\n  background-color: #ffffff;\n  width: 100%;\n  max-width: 1280px;\n  margin: 0 0 1rem 0;\n  position: relative;\n  display: flex;\n  align-items: stretch;\n  flex-direction: column;\n}\n.style_paperContentFlat__QSsBj {\n  box-shadow: 0;\n  background-color: transparent;\n  width: 100%;\n  max-width: 1280px;\n  margin: 0 1rem;\n  position: relative;\n  display: flex;\n  align-items: stretch;\n  flex-direction: column;\n}\n@media (max-width: 37.4rem) {\n  .style_paperContentFlat__QSsBj {\n    margin: 0.8rem;\n  }\n}\n.style_paperFullWidth__8SfP3 {\n  max-width: none;\n}\n.style_container__23_qZ {\n  display: flex;\n  width: 100%;\n  justify-content: center;\n}\n.style_headerContainer__1Wp7n {\n  background: #ffffff;\n  border-radius: 0.25rem;\n  overflow: hidden;\n}\n.style_busyFromPromise__m7JN_ {\n  width: 100%;\n}\n.style_title__1zL1H {\n  word-wrap: break-word;\n  background-color: #ffffff;\n}\n.style_stickied__3AmB4 {\n  box-shadow: 0 0.0625rem 0.1875rem -0.0625rem rgba(0, 0, 0, 0.34);\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__n_Ecp",
	"hideSm": "style_hide-sm__n_Ecp",
	"visible-sm": "style_visible-sm__P2EKE",
	"visibleSm": "style_visible-sm__P2EKE",
	"hide-md": "style_hide-md__2DPYJ",
	"hideMd": "style_hide-md__2DPYJ",
	"visible-md": "style_visible-md__2OHkK",
	"visibleMd": "style_visible-md__2OHkK",
	"paperContent": "style_paperContent__382gV",
	"paperContentFlat": "style_paperContentFlat__QSsBj",
	"paperFullWidth": "style_paperFullWidth__8SfP3",
	"container": "style_container__23_qZ",
	"headerContainer": "style_headerContainer__1Wp7n",
	"busyFromPromise": "style_busyFromPromise__m7JN_",
	"title": "style_title__1zL1H",
	"stickied": "style_stickied__3AmB4"
};