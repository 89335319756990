import Resource from "./resource";

export class LetsEncryptConfigurationResource extends Resource {
    Enabled: boolean;
    RegistrationEmailAddress: string;
    DnsName: string;
    HttpsPort: number;
    IPAddress: string;
    AcceptLetsEncryptTermsOfService: boolean;
    CertificateExpiryDate: string;
    CertificateThumbprint: string;
    Path: string;
}

export default LetsEncryptConfigurationResource;