import * as React from "react";
import {DockerFeedResource, FeedType} from "client/resources/feedResource";
import {Summary} from "components/form";
import Note from "components/form/Note/Note";
import Text from "components/form/Text/Text";
import ExternalLink from "components/Navigation/ExternalLink/ExternalLink";
import ExpandableFormSection from "components/form/Sections/ExpandableFormSection";
import feedTypeRegistry, {FeedEditProps} from "./feedTypeRegistry";
import BasicFeedCredentialsFormSection from "../BasicFeedCredentialsFormSection";

class DockerFeed extends React.Component<FeedEditProps<DockerFeedResource>> {
    render() {
        return <React.Fragment>
            <ExpandableFormSection
                errorKey="RegistryPath"
                title="Registry Path"
                summary={this.props.feed.RegistryPath ? Summary.summary(this.props.feed.RegistryPath) : Summary.placeholder("Please enter the path of the registry")}
                help={<span>The path of the <ExternalLink
                    href="DockerPullingFromADifferentRegistry">registry</ExternalLink> for the docker host to
                            pull from as accessible from the target.</span>}>
                <Text
                    value={this.props.feed.RegistryPath}
                    onChange={RegistryPath => this.props.onChange({...this.props.feed, RegistryPath})}
                    label="Registry path"
                    error={this.props.getFieldError("RegistryPath")}
                />
                <Note>
                    Example: <code>localhost:5000</code>. Leave empty if the same as the URL host provided
                    above.
                </Note>
            </ExpandableFormSection>
            <BasicFeedCredentialsFormSection model={{username: this.props.feed.Username, password: this.props.feed.Password}}
                                             onChanged={(model) => this.props.onChange({...this.props.feed, Password: model.password, Username: model.username})}/>
        </React.Fragment>;
    }
}

feedTypeRegistry.registerFeedType({
    type: FeedType.Docker,
    edit: DockerFeed,
    text: "Docker Container Registry",
    uriNotes: <span>To use the public Docker Hub registry, set the url to <code>https://index.docker.io</code></span>,
    uriDefault: "https://index.docker.io"
});