import * as React from "react";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import {repository} from "clientInstance";
import {ProjectResource} from "client/resources/projectResource";
import {LifecycleResource} from "client/resources/lifecycleResource";
import Select from "components/form/Select/Select";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap/LifecycleMap";
import {ResourcesById} from "client/repositories/basicRepository";
import {EnvironmentResource} from "client/resources/environmentResource";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";

interface SelectLifecycleProps {
    projectId: string;
    saveDone(): void;
}

interface SelectLifecycleState extends DataBaseComponentState {
    lifecyclePreviews?: LifecycleResource[];
    project?: ProjectResource;
    environmentsById?: ResourcesById<EnvironmentResource>;
}

class SelectLifecycle extends DataBaseComponent<SelectLifecycleProps, SelectLifecycleState> {
    constructor(props: SelectLifecycleProps) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        return this.doBusyTask(async () => {
            const [lifecycles, project, environmentsById] = await  Promise.all([
                repository.Lifecycles.all(),
                repository.Projects.get(this.props.projectId),
                repository.Environments.allById()
            ]);

            const lifecyclePreviews = await Promise.all(lifecycles.map(l => repository.Lifecycles.preview(l)));
            this.setState({lifecyclePreviews, project, environmentsById});
        });
    }

    save() {
        return this.doBusyTask(async () => {
            await repository.Projects.modify(this.state.project);
            await this.props.saveDone();
        });
    }

    render() {
        const body = this.state.project &&
            <div>
                <Select value={this.state.project.LifecycleId}
                        onChange={this.handleLifeCycleChange}
                        items={this.state.lifecyclePreviews.map(l => ({value: l.Id, text: l.Name}))}
                        autoFocus
                        label="Lifecycle"/>
                <LifecycleMap environmentsById={this.state.environmentsById}
                    lifecyclePreview={this.state.lifecyclePreviews.find(l => l.Id === this.state.project.LifecycleId)}
                    showDescription={true}
                />
            </div>;

        return <SaveDialogLayout title="Change lifecycle"
            busy={this.state.busy}
            errors={this.state.errors}
            onSaveClick={() => this.save()}>
            {body}
        </SaveDialogLayout>;
    }

    private handleLifeCycleChange = (value: any) => {
        this.doBusyTask(async () => {
            this.setChildState1("project", {LifecycleId: value});
        });
    }
}

export default SelectLifecycle;