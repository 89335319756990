import * as React from "react";
import * as _ from "lodash";
import {DataBaseComponent, DataBaseComponentState} from "../../DataBaseComponent";
import {IngressTlsCertificate} from "./kubernetesIngressComponent";
import OkDialogLayout from "../../DialogLayout/OkDialogLayout";
import {VariableLookupText} from "../../form/VariableLookupText";
import {BoundCertificateVariableSelect} from "../../form/CertificateSelect/CertificateVariableSelect";
import Note from "../../form/Note/Note";
import ExternalLink from "../../Navigation/ExternalLink/ExternalLink";
import {RemoveItemsList} from "../../RemoveItemsList/RemoveItemsList";
import ActionButton from "../../Button";
import {VariableLookupAutoComplete} from "../../form/VariableLookupAutoComplete";

interface IngressTlsCertificateProps {
    tlsCertificate: IngressTlsCertificate;
    ingressRuleHosts: string[]; // Used for auto-complete values
    localNames: string[];
    projectId: string;
    onSave(hostCertificate: IngressTlsCertificate): boolean;
}

interface IngressTlsCertificateState extends DataBaseComponentState {
    tlsCertificate: IngressTlsCertificate;
}

class IngressTlsHostList extends RemoveItemsList<string> {
}

export class IngressTlsCertificateDialog extends DataBaseComponent<IngressTlsCertificateProps, IngressTlsCertificateState> {
    constructor(props: IngressTlsCertificateProps) {
        super(props);
        this.state = {
            tlsCertificate: null,
        };
    }

    componentDidMount() {
        this.setState({
            tlsCertificate: this.props.tlsCertificate
        });
    }

    render() {
        return <OkDialogLayout
            onOkClick={this.save}
            busy={this.state.busy}
            errors={this.state.errors}
            title="Configure TLS certificate"
        >
            {this.state.tlsCertificate && <div>
                <Note>
                    See the Kubernetes documentation for more information on <ExternalLink href="KubernetesIngressTls">ingress TLS</ExternalLink>.
                </Note>
                <BoundCertificateVariableSelect
                    variableLookup={{
                        localNames: this.props.localNames,
                        projectId: this.props.projectId
                    }}
                    resetValue={""}
                    projectId={this.props.projectId}
                    doBusyTask={this.doBusyTask}
                    value={this.state.tlsCertificate.certificateVariableName}
                    onChange={x => this.setTlsCertificateState({certificateVariableName: x})} />
                <Note>
                    Optionally select a variable which contains a <ExternalLink href="CertificatesDocumentation">certificate</ExternalLink>. You
                    may leave this field blank if your ingress controller has default certificates defined, or is using SSL passthrough.
                </Note>
                <Note>
                    This field is bound to a variable as it is generally desired to use a different certificate for different environments.
                </Note>
                <IngressTlsHostList
                    listActions={[<ActionButton key="add" label="Add Host" onClick={() => this.addHost()}/>]}
                    data={this.state.tlsCertificate.hosts}
                    onRow={(host, hostIndex) =>
                        <VariableLookupAutoComplete
                            localNames={this.props.localNames}
                            projectId={this.props.projectId}
                            value={host}
                            onChange={x => this.onHostChange(hostIndex, x)}
                            label="Host"
                            getOptions={(searchText) => this.getHostOptions(searchText)}
                            allowAnyTextValue={true}
                            hintText="acme.com"
                        />
                    }
                />
                <Note>
                    Configure the hosts which TLS should be configured for. For example, <em>sslexample.foo.com</em>
                </Note>
            </div>}
        </OkDialogLayout>;
    }

    save = () => {
        const tlsCertificate = this.state.tlsCertificate;
        return this.props.onSave(tlsCertificate);
    }

    addHost = () => {
       const hosts = [...this.state.tlsCertificate.hosts, ""];
       this.setTlsCertificateState({hosts});
    }

    onHostChange = (hostIdx: number, value: string) => {
       const hosts = [...this.state.tlsCertificate.hosts];
       hosts[hostIdx] = value;
       this.setTlsCertificateState({hosts});
    }

    getHostOptions = async (searchText: string) => {
        const take = 7;
        const results = _.chain(this.props.ingressRuleHosts)
            .filter(v => !!v)
            .filter(v => v.toLowerCase().includes(searchText.toLowerCase()))
            .value();
        return {
           items: results.slice(0, take).map(v => ({Id: v, Name: v})),
           containsAllResults: results.length <= take
        };
    }

    private setTlsCertificateState<K extends keyof IngressTlsCertificate>(state: Pick<IngressTlsCertificate, K>, callback?: () => void) {
        this.setChildState1("tlsCertificate", state);
    }
}
