import * as React from "react";
import {SensitiveValue} from "../../../client/resources/propertyValueResource";
import ByteSizeFormatter from "utils/ByteSizeFormatter";
import FormFieldProps from "../FormFieldProps";
import Text from "components/form/Text/Text";
import InputWithActions from "components/InputWithActions/InputWithActions";
import IconButtonList from "components/IconButtonList/IconButtonList";
import IconButton, {Icon} from "components/IconButton/IconButton";

interface SensitiveFileUploadProps extends FormFieldProps<SensitiveValue> {
    hintText?: string;
    availablePlaceholder?: string;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
}

interface SensitiveFileState {
    error?: string;
    selectedFile?: File;
}

const MaxFileSize = Math.pow(1024, 5);
export default class SensitiveFileUpload extends React.Component<SensitiveFileUploadProps, SensitiveFileState> {
    input: HTMLInputElement;

    constructor(props: SensitiveFileUploadProps) {
        super(props);
        this.state = {
        };
    }

    openSelector = (e: any) => {
        e.preventDefault();
        this.input.click();
    }

    handleFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({error: null});

        const files = e.currentTarget.files;

        if (files.length < 1) {
            return;
        }

        const file = files[0];

        if (file.size === 0) {
            this.reportError("Attached file is empty. Please select a different file.");
            return;
        }

        if (file.size > MaxFileSize) {
            this.reportError("Selected file is too big!");
            return;
        }

        try {
            const bytes = await this.loadFileBytes(file);
            this.props.onChange({HasValue: true, NewValue: bytes});
            this.setState({selectedFile: file});
        } catch (err) {
            this.setState({error: err});
        }
    }

    reportError(error: string) {
        this.setState({error, selectedFile: null});
    }

    loadFileBytes(file: File): Promise<string> {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const target: any = evt.target;
                if (target.readyState === (FileReader as any).DONE) {
                    const b6 = target.result.indexOf("base64,") + 7;
                    const ress = target.result.slice(b6);
                    res(ress);
                }
            };
            reader.onerror = (evt) => {
                rej((evt as any).message);
            };
            reader.readAsDataURL(file);
        });
    }

    currentValue = (): SensitiveValue => {
        return this.props.value || {
                HasValue: false,
            };
    }

    handleSetOrChange = () => {
        this.input.click();
    }

    handleRemove = (e: any) => {
        this.input.value = null;
        this.setState({
            selectedFile: null
        }, () => {
            this.props.onChange({
                HasValue: false
            });
        });
    }

    getName() {
        if (this.props.value && this.props.value.HasValue && this.props.value.NewValue === null) {
            return this.props.availablePlaceholder || "A file exists";
        }

        const selectedFile = this.state.selectedFile;
        if (selectedFile) {
            return `${selectedFile.name} (${ByteSizeFormatter(selectedFile.size)})`;
        }

        return this.props.hintText || "";
    }

    render() {
        const currentValue = this.currentValue();
        const setOrChange = currentValue.HasValue ? "Change file" : "Choose file";

        const {
            error,
            warning,
            label,
            onChange,
            value,
            hintText,
        } = this.props;
        const err = this.state.error || error;
        const errorText = err || warning;

        return <div>
            <InputWithActions input={<Text
                                        type="text"
                                        hintText={hintText}
                                        value={this.getName()}
                                        label={label}
                                        onChange={this.noop}
                                        onClick={this.openSelector}
                                        error={errorText}
                                    />}
                              actions={<IconButtonList buttons={this.buttons(setOrChange, currentValue)}/>} />
            <input type="file"
                   style={{display: "none"}}
                   onChange={this.handleFileChange}
                   ref={input => this.input = input}/>
        </div>;
    }

    private buttons(uploadText: string, currentValue: SensitiveValue) {
        return [
            <IconButton toolTipContent={uploadText} onClick={this.handleSetOrChange} icon={Icon.Upload}/>,
            currentValue.HasValue ? <IconButton toolTipContent="Remove file" onClick={this.handleRemove} icon={Icon.Remove}/> : null
        ].filter(b => !!b);
    }

    private noop = () => {
        // Empty on purpose
    }
}
