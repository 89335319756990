import * as React from "react";
import {
    ScheduleIntervalResource,
    TriggerScheduleIntervalResource,
    TriggerScheduleResource,
    DaysPerMonthTriggerScheduleResource,
    MonthlyScheduleType,
    DayOfWeek,
    ServerTimezoneResource
} from "client/resources";
import ScheduleInterval from "./ScheduleInterval";
import ScheduleStart from "./ScheduleStart";
import { ExpandableFormSection, Summary, Checkbox, RadioButtonGroup, RadioButton, Select, StringRadioButtonGroup } from "components/form";
import * as cn from "classnames";
import ComponentRow from "components/ComponentRow/ComponentRow";
import { Item } from "components/form/Select/Select";
import { Moment } from "moment";
import * as moment from "moment";

interface DaysPerMonthScheduledTriggerEditorProps {
    schedule: DaysPerMonthTriggerScheduleResource;
    timezones: ServerTimezoneResource[];
    onScheduleChange(schedule: DaysPerMonthTriggerScheduleResource): void;
}

interface DaysPerMonthScheduleTriggerEditorState {
    scheduleStartTime: Moment;
    scheduleTimezone: string;
    monthlyScheduleType: MonthlyScheduleType;
    scheduleDateOfMonth: string;
    scheduleDayOfWeekOrdinal: string;
    scheduleMonthSpecifiedDayOfWeek: DayOfWeek;
}

export class DaysPerMonthScheduledTriggerEditor extends React.Component<DaysPerMonthScheduledTriggerEditorProps, DaysPerMonthScheduleTriggerEditorState> {
    constructor(props: DaysPerMonthScheduledTriggerEditorProps) {
        super(props);
        this.state = this.initState(this.props.schedule);
    }

    componentWillReceiveProps(newProps: DaysPerMonthScheduledTriggerEditorProps) {
        if (newProps.schedule.StartTime !== this.props.schedule.StartTime ||
            newProps.schedule.Timezone !== this.props.schedule.Timezone ||
            newProps.schedule.MonthlyScheduleType !== this.props.schedule.MonthlyScheduleType ||
            newProps.schedule.DateOfMonth !== this.props.schedule.DateOfMonth ||
            newProps.schedule.DayNumberOfMonth !== this.props.schedule.DayNumberOfMonth ||
            newProps.schedule.DayOfWeek !== this.props.schedule.DayOfWeek) {
            this.setState(this.initState(newProps.schedule));
        }
    }

    render() {

        const componentRowSpanStyle = {
            width: "100%",
            marginTop: "2rem"
        };

        return (
            <div>
                <ExpandableFormSection
                    errorKey="ScheduleDaysPerMonth"
                    title="On"
                    summary={this.getDaysPerMonthSummary()}
                    help="Select when in the month the trigger should execute">
                    <StringRadioButtonGroup
                        value={this.state.monthlyScheduleType}
                        onChange={this.onMonthlyScheduleTypeChange}>
                        {[
                            <RadioButton
                                value={MonthlyScheduleType.DateOfMonth}
                                label="Specific date of every month"
                                key={MonthlyScheduleType.DateOfMonth}/>,
                            <RadioButton
                                value={MonthlyScheduleType.DayOfMonth}
                                label="Specific day of week of every month"
                                key={MonthlyScheduleType.DayOfMonth}/>,
                        ]}
                    </StringRadioButtonGroup>
                    {this.state.monthlyScheduleType === MonthlyScheduleType.DateOfMonth && <div>
                        <ComponentRow>
                            <span style={{marginTop: "2rem"}}>The</span>
                            <Select
                                items={this.getDateOfMonthOptions()}
                                value={this.state.scheduleDateOfMonth}
                                onChange={this.onDateOfMonthChange}
                                label="Day of month" />
                            <span style={componentRowSpanStyle}>day of every month</span>
                        </ComponentRow>
                    </div>}
                    {this.state.monthlyScheduleType === MonthlyScheduleType.DayOfMonth && <div>
                        <ComponentRow>
                        <span style={{marginTop: "2rem"}}>The</span>
                            <Select
                                items={this.getDayOfWeekOrdinalOptions()}
                                value={this.state.scheduleDayOfWeekOrdinal}
                                onChange={this.onDayOfWeekOrdinalChange}
                                label="Day of week ordinal" />
                            <Select
                                items={this.getMonthSpecifiedDaysOfWeek()}
                                value={this.state.scheduleMonthSpecifiedDayOfWeek}
                                onChange={this.onMonthSpecifiedDayOfWeekChange}
                                label="Day of week" />
                            <span style={componentRowSpanStyle}>of every month</span>
                        </ComponentRow>
                    </div>}
                </ExpandableFormSection>
                <ScheduleStart
                    startTime={this.state.scheduleStartTime}
                    timezone={this.state.scheduleTimezone}
                    timezones={this.props.timezones}
                    onStartTimeChanged={this.onScheduleStartChange}
                    onTimezoneChanged={this.onScheduleTimezoneChange} />
            </div>
        );
    }

    initState(value?: DaysPerMonthTriggerScheduleResource) {
        const schedule = value || new DaysPerMonthTriggerScheduleResource();
        return {
            scheduleStartTime: moment(schedule.StartTime).utc(),
            scheduleTimezone: schedule.Timezone,
            monthlyScheduleType: schedule.MonthlyScheduleType || MonthlyScheduleType.DateOfMonth,
            scheduleDateOfMonth: schedule.DateOfMonth ? schedule.DateOfMonth.toString() : null,
            scheduleDayOfWeekOrdinal: schedule.DayNumberOfMonth ? schedule.DayNumberOfMonth.toString() : null,
            scheduleMonthSpecifiedDayOfWeek: schedule.DayOfWeek
        };
    }

    private getDaysPerMonthSummary() {
        if (this.state.monthlyScheduleType === MonthlyScheduleType.DateOfMonth &&
            this.state.scheduleDateOfMonth) {
                const dateOfMonth = this.getDateOfMonthOptions().find(opt => {
                    return opt.value === this.state.scheduleDateOfMonth;
                });
                return Summary.summary(<span>The <strong>{dateOfMonth.text}</strong> day of every month</span>);
        }

        if (this.state.monthlyScheduleType === MonthlyScheduleType.DayOfMonth &&
            this.state.scheduleDayOfWeekOrdinal && this.state.scheduleMonthSpecifiedDayOfWeek) {
                const dayOfWeekOrdinal = this.getDayOfWeekOrdinalOptions().find(dowo => {
                    return dowo.value === this.state.scheduleDayOfWeekOrdinal;
                });
                const dayOfWeek = this.getMonthSpecifiedDaysOfWeek().find(dow => {
                    return dow.value === this.state.scheduleMonthSpecifiedDayOfWeek;
                });
                return Summary.summary(<span>The <strong>{dayOfWeekOrdinal.text} {dayOfWeek.text}</strong> of every month</span>);
        }

        return Summary.placeholder("Select what day of the month the schedule should execute.");
    }

    private getDateOfMonthOptions() {
        const items: Item[] = [];
        let text: string;
        for (let value = 1; value <= 31; value++) {
            switch (value) {
                case 1:
                case 21:
                case 31:
                    text = `${value}st`;
                    break;
                case 2:
                case 22:
                    text = `${value}nd`;
                    break;
                case 3:
                case 23:
                    text = `${value}rd`;
                    break;
                default:
                    text = `${value}th`;
                    break;
            }
            items.push({value: value.toString(), text});
        }
        items.push({value: "L", text: "Last"});
        return items;
    }

    private getDayOfWeekOrdinalOptions() {
        return [
            {value: "1", text: "First"},
            {value: "2", text: "Second"},
            {value: "3", text: "Third"},
            {value: "4", text: "Fourth"},
            {value: "L", text: "Last"},
        ];
    }

    private getMonthSpecifiedDaysOfWeek() {
        return [
            {value: "Monday", text: "Monday"},
            {value: "Tuesday", text: "Tuesday"},
            {value: "Wednesday", text: "Wednesday"},
            {value: "Thursday", text: "Thursday"},
            {value: "Friday", text: "Friday"},
            {value: "Saturday", text: "Saturday"},
            {value: "Sunday", text: "Sunday"},
        ];
    }

    private onScheduleStartChange = (startTime: Moment) => {
        this.setState({
            scheduleStartTime: startTime
        }, () => this.raiseChange());
    }

    private onScheduleTimezoneChange = (timezone: string) => {
        this.setState({
            scheduleTimezone: timezone
        }, () => this.raiseChange());
    }

    private onMonthlyScheduleTypeChange = (type: MonthlyScheduleType) => {
        this.setState({
            monthlyScheduleType: type
        }, () => this.raiseChange());
    }

    private onDateOfMonthChange = (dateOfMonth: string) => {
        this.setState({
            scheduleDateOfMonth: dateOfMonth
        }, () => this.raiseChange());
    }

    private onDayOfWeekOrdinalChange = (dayOfWeekOrdinal: string) => {
        this.setState({
            scheduleDayOfWeekOrdinal: dayOfWeekOrdinal
        }, () => this.raiseChange());
    }

    private onMonthSpecifiedDayOfWeekChange = (monthSpecifiedDayOfWeek: DayOfWeek) => {
        this.setState({
            scheduleMonthSpecifiedDayOfWeek: monthSpecifiedDayOfWeek
        }, () => this.raiseChange());
    }

    private raiseChange() {
        this.props.onScheduleChange({
            ...this.props.schedule,
            StartTime: this.state.scheduleStartTime.toDate(),
            Timezone: this.state.scheduleTimezone,
            MonthlyScheduleType: this.state.monthlyScheduleType,
            DateOfMonth: this.state.monthlyScheduleType === MonthlyScheduleType.DateOfMonth ? this.state.scheduleDateOfMonth : null,
            DayNumberOfMonth: this.state.monthlyScheduleType === MonthlyScheduleType.DayOfMonth ? this.state.scheduleDayOfWeekOrdinal : null,
            DayOfWeek: this.state.monthlyScheduleType === MonthlyScheduleType.DayOfMonth ? this.state.scheduleMonthSpecifiedDayOfWeek : null
        });
    }
}