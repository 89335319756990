import * as React from "react";
import { FeedType } from "client/resources/feedResource";
import { Select } from "components/form";
import feedTypeRegistry from "../../areas/library/components/ExternalFeeds/FeedTypes";

interface FeedTypeProps {
    showOnly?: FeedType[];
    disabled?: boolean;
    value: FeedType;
    onChange: (feedType: FeedType) => void;
}

export const FeedTypeSelect: React.StatelessComponent<FeedTypeProps> = props => {
    let allFeedTypes = feedTypeRegistry.getAllTypes();
    if (props.showOnly && props.showOnly.length > 0) {
        allFeedTypes = allFeedTypes.filter(f => props.showOnly.includes(f as FeedType));
    }

    const items = allFeedTypes.map(f => ({value: f, text: feedTypeRegistry.getRegistration(f).text}));
    return <Select
        value={props.value}
        onChange={props.onChange}
        items={items}
        label="Feed type"
        disabled={props.disabled} />;
};