import * as React from "react";
import { List as MaterialList } from "material-ui";
import IconButton from "material-ui/IconButton";
import ClearIcon from "material-ui/svg-icons/content/clear";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
const styles = require("./style.less");
import BorderedListItem from "components/BorderedListItem";
import * as cn from "classnames";

interface RemoveItemsListProps<R> {
    empty?: React.ReactNode;
    data: R[];
    clearButtonToolTip?: string;
    listActions?: JSX.Element[];
    onRow(item: R, idx: number): React.ReactNode;
    onRowTouch?(item: R): void;
    onRemoveRow?(item: R): void;
}

export abstract class RemoveItemsList<R> extends BaseComponent<RemoveItemsListProps<R>, any> {
    private bottomActionVisibilityThreshold = 3;

    constructor(props: RemoveItemsListProps<R>) {
        super(props);
    }

    render() {
        const actions = (this.props.listActions || []).map((action, index) => React.cloneElement(action, { key: index }));
        return (
            <div>
                <div className={styles.actionsMenu}>{actions}</div>
                {this.props.data.length === 0 && this.props.empty && <div className={styles.emptyList}>{this.props.empty}</div>}
                {this.props.data.length !== 0 && <MaterialList>
                    {this.props.data.map((item, index) => this.buildItem(item, index))}
                </MaterialList>}
                <div className={cn(styles.actionsMenu, { [styles.hidden]: this.props.data.length < this.bottomActionVisibilityThreshold })}>{actions}</div>
            </div>
        );
    }

    private buildItem(item: R, index: number) {
        const clearButton = this.props.onRemoveRow ? <IconButton
            tooltip={this.props.clearButtonToolTip}
            onClick={e => {
                e.stopPropagation();
                this.props.onRemoveRow(item);
            }}>
            <ClearIcon />
        </IconButton> : null;

        return <BorderedListItem key={(item as any).Id || index} rightIconButton={clearButton}
            onClick={this.props.onRowTouch ? (e: any) => this.onTouch(item) : null}
            disabled={this.props.onRowTouch ? false : true}>
            {this.props.onRow(item, index)}
        </BorderedListItem>;
    }

    private onTouch(item: R) {
        if (this.props.onRowTouch) {
            this.props.onRowTouch(item);
        }
    }
}