import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import LibraryLayout from "../LibraryLayout/LibraryLayout";
import { repository } from "clientInstance";
import { cloneDeep } from "lodash";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import FormPaperLayout from "components/FormPaperLayout";
import { Permission } from "client/resources";
import {
    ExpandableFormSection,
    Summary,
    Text,
    required,
    MarkdownEditor,
} from "components/form";
import {
    LibraryVariableSetResource,
    VariableSetContentType
} from "client/resources/libraryVariableSetResource";
import Markdown from "components/Markdown/index";
import CodeEditor from "components/CodeEditor/CodeEditor";
import { VariableSetResource } from "client/resources/variableSetResource";
import { VariableResource, VariableType } from "client/resources/variableResource";
import OverflowMenu from "components/Menu/OverflowMenu";
import { ScriptingLanguage } from "components/scriptingLanguage";
import StringHelper from "utils/StringHelper";
import routeLinks from "../../../../routeLinks";
import { CardFill } from "components/form/Sections/ExpandableFormSection";
import InternalRedirect from "../../../../components/Navigation/InternalRedirect/InternalRedirect";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

interface ScriptModuleProps extends RouteComponentProps<ScriptModuleRouteParams> {
    create?: boolean;
}

interface ScriptModuleRouteParams {
    scriptModuleId: string;
}

interface ScriptModuleModel extends LibraryVariableSetResource {
    scriptBody: string;
    variableSet: VariableSetResource;
}

interface ScriptModuleState extends OptionalFormBaseComponentState<ScriptModuleModel> {
    deleted: boolean;
    newId: string;
}

class ScriptModule extends FormBaseComponent<ScriptModuleProps, ScriptModuleState, ScriptModuleModel> {
    constructor(props: ScriptModuleProps) {
        super(props);
        this.state = {
            deleted: false,
            newId: null,
        };
    }

    async componentDidMount() {
        let scriptModule: ScriptModuleModel = null;
        let variableSet: VariableSetResource = null;
        if (this.props.create) {
            scriptModule = {
                Id: null as string,
                Name: null,
                Description: null,
                Links: null,
                VariableSetId: null as string,
                ContentType: VariableSetContentType.ScriptModule,
                Templates: [],
                scriptBody: null,
                variableSet: null
            };
        } else {
            await this.doBusyTask(async () => {
                const result = await repository.LibraryVariableSets.get(this.props.match.params.scriptModuleId);
                variableSet = await repository.Variables.get(result.VariableSetId);
                const bodyVariable = this.getBodyVariable(variableSet);
                const scriptBody = bodyVariable ? bodyVariable.Value : "function Say-Hello()\r\n{\r\n    Write-Output \"Hello, Octopus!\"\r\n}\r\n";
                scriptModule = this.buildModel(result, scriptBody, variableSet);
            });
        }

        this.setState({
            model: scriptModule,
            cleanModel: cloneDeep(scriptModule)
        });
    }

    getBodyVariable(variableSet: VariableSetResource): VariableResource {
        return variableSet.Variables.find(v => v.Name.startsWith("Octopus.Script.Module["));
    }

    render() {
        const title = this.props.create
            ? "New Script Module"
            : this.state.model
                ? this.state.model.Name
                : StringHelper.ellipsis;

        const overFlowActions = [];
        if (!this.props.create && !!this.state.model) {
            overFlowActions.push(OverflowMenu.deleteItemDefault("script module", this.handleDeleteConfirm, { permission: Permission.LibraryVariableSetDelete }));
            overFlowActions.push([OverflowMenu.navItem("Audit Trail",
                routeLinks.configuration.eventsRegardingAny([this.state.model.Id]), null, {
                    permission: Permission.EventView,
                    wildcard: true
                })]);
        }

        const saveText: string = this.state.newId
            ? "Script module created"
            : "Script module details updated";
        const helpText = "This script will be loaded as a PowerShell module (equivalent to a .psm1 file). " +
            "Functions and cmdlets from the module will be exported for use in deployment scripts.";
        return <LibraryLayout {...this.props}>
            <FormPaperLayout
                title={title}
                breadcrumbTitle={"Script Modules"}
                breadcrumbPath={routeLinks.library.scripts.root}
                busy={this.state.busy}
                errors={this.state.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                savePermission={{ permission: this.props.create ? Permission.LibraryVariableSetCreate : Permission.LibraryVariableSetEdit }}
                onSaveClick={() => this.handleSaveClick(false)}
                saveText={saveText}
                expandAllOnMount={this.props.create}
                overFlowActions={overFlowActions} >
                {this.state.deleted && <InternalRedirect to={routeLinks.library.scripts.root} />}
                {this.state.newId && <InternalRedirect to={routeLinks.library.script(this.state.newId)} />}
                {this.state.model && <TransitionAnimation>
                    <ExpandableFormSection
                        errorKey="Name"
                        title="Name"
                        focusOnExpandAll
                        summary={this.state.model.Name ? Summary.summary(this.state.model.Name) : Summary.placeholder("Please enter a name for your script module")}
                        help="A short, memorable, unique name for this script module. Example: Service Configuration.">
                        <Text
                            value={this.state.model.Name}
                            onChange={Name => this.setModelState({ Name })}
                            label="Name"
                            error={this.getFieldError("Name")}
                            validate={required("Please enter a script module name")}
                            autoFocus={true}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        errorKey="Description"
                        title="Description"
                        summary={this.descriptionSummary()}
                        help="This summary will be presented to users when selecting the script module for inclusion in a project.">
                        <MarkdownEditor
                            value={this.state.model.Description}
                            label="Description"
                            onChange={Description => this.setModelState({ Description })}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection
                        errorKey="Body"
                        title="Body"
                        fillCardWidth={CardFill.FillRight}
                        summary={this.scriptBodySummary()}
                        help={helpText}>
                        <h4>PowerShell script</h4>
                        <CodeEditor value={this.state.model.scriptBody}
                            language={ScriptingLanguage.PowerShell}
                            allowFullScreen={true}
                            onChange={scriptBody => this.setModelState({ scriptBody })}
                        />
                    </ExpandableFormSection>
                </TransitionAnimation>}
            </FormPaperLayout>
        </LibraryLayout>;
    }

    buildModel(scriptModule: LibraryVariableSetResource, script: string, vs: VariableSetResource): ScriptModuleModel {

        const thisModel: ScriptModuleModel = {
            Id: scriptModule.Id,
            Name: scriptModule.Name,
            Description: scriptModule.Description,
            VariableSetId: scriptModule.VariableSetId,
            ContentType: scriptModule.ContentType,
            Templates: scriptModule.Templates,
            Links: scriptModule.Links,
            scriptBody: script,
            variableSet: vs
        };
        return thisModel;
    }

    private descriptionSummary() {
        return this.state.model.Description
            ? Summary.summary(<Markdown markup={this.state.model.Description} />)
            : Summary.placeholder("No description provided");
    }

    private scriptBodySummary() {
        return this.state.model.scriptBody
            ? Summary.summary("PowerShell script provided, click to view")
            : Summary.placeholder("Please enter the PowerShell script");
    }

    private handleSaveClick = async (redirectToTest: boolean) => {
        await this.doBusyTask(async () => {
            const isNew = this.state.model.Id == null;
            const result = await repository.LibraryVariableSets.save(this.state.model);
            let variableSet = null;
            if (this.state.model.scriptBody) {
                variableSet = await repository.Variables.get(result.VariableSetId);
                let bodyVar = this.getBodyVariable(variableSet);
                if (!bodyVar) {
                    bodyVar = {
                        Id: "",
                        Name: "",
                        Value: "",
                        Description: null,
                        Scope: {},
                        IsEditable: false,
                        Prompt: null,
                        Type: VariableType.String,
                        IsSensitive: false
                    };
                    variableSet.Variables.push(bodyVar);
                }
                bodyVar.Name = "Octopus.Script.Module[" + this.state.model.Name + "]";
                bodyVar.Value = this.state.model.scriptBody;
                await repository.Variables.save(variableSet);
            }
            const thisModel = this.buildModel(result, this.state.model.scriptBody, variableSet);
            this.setState({
                model: thisModel,
                cleanModel: cloneDeep(thisModel),
                newId: isNew ? result.Id : null,
            });
        });
    }

    private handleDeleteConfirm = async () => {
        const result = await repository.LibraryVariableSets.del(this.state.model);
        this.setState(state => {
            return {
                model: null,
                cleanModel: null, //reset model so that dirty state doesn't prevent navigation
                deleted: true
            };
        });
        return true;
    }
}

export default ScriptModule;