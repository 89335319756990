exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_featuresActionContainer__2bUFI {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"featuresActionContainer": "style_featuresActionContainer__2bUFI"
};