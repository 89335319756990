import * as React from "react";
import {repository} from "clientInstance";
import {DataBaseComponentState} from "components/DataBaseComponent";
import {DataBaseComponent} from "components/DataBaseComponent/DataBaseComponent";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import {required, Text, Checkbox, Note} from "components/form";
import MarkdownEditor from "../../../../../components/form/MarkdownEditor/MarkdownEditor";
import {
    WorkerPoolResource,
    Permission
} from "client/resources";

interface AddWorkerPoolsDialogContentProps {
    throwErrors?: boolean;
    saveDone?(workerPool: WorkerPoolResource): any;
}

interface AddWorkerPoolsDialogContentState extends DataBaseComponentState {
    name: string;
    description: string;
    useGuidedFailure: boolean;
    newWorkerPoolId: string;
}

export default class AddWorkerPoolsDialogContent extends DataBaseComponent<AddWorkerPoolsDialogContentProps, AddWorkerPoolsDialogContentState> {
    constructor(props: AddWorkerPoolsDialogContentProps) {
        super(props);
        this.state = {
            name: "",
            description: "",
            useGuidedFailure: false,
            newWorkerPoolId: ""
        };
    }

    async save() {
        return this.doBusyTask(async () => {
            const result = await repository.WorkerPools.create({
                Id: null,
                Name: this.state.name,
                Description: this.state.description,
                SortOrder: -1, // API automatically assigns this value if <= 0.
                IsDefault: false,
                Links: null
            }, {});
            this.setState({
                newWorkerPoolId: result.Id
            });
            await this.props.saveDone(result);
        });
    }

    render() {
        return <SaveDialogLayout title={"Add Worker Pool"}
                                 busy={this.state.busy}
                                 errors={this.state.errors}
                                 savePermission={{permission: Permission.WorkerEdit}}
                                 onSaveClick={() => this.save()}>
            <Text
                label="New worker pool name"
                value={this.state.name}
                onChange={this.handleNameChange}
                autoFocus={true}
                validate={required("Please enter an worker pool name")}
            />
        </SaveDialogLayout>;
    }

    private handleNameChange = (value: string) => {
        this.setState({name: value});
    }
}
