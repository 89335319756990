import * as React from "react";
import * as cn from "classnames";

const styles = require("./DialogFrames.less");
export type Frame = React.ComponentType<any>;

export interface DialogFrameProp {
    frame?: Frame;
}

export const FrameWrapper: React.SFC<{className?: string}> = ({children, className}) => (
    <div className={cn(className, styles.wrapper, styles.frame, styles.padded)} children={children}/>
);

export const DialogFrame: React.SFC<{className?: string}> = ({children, className}) => {
    return <div className={cn(styles.frame, styles.shadow, className)}>
        {children}
    </div>;
};

export const FullScreenDialogFrame: React.SFC<{className?: string}> = ({children, className}) => {
    return <DialogFrame className={cn(styles.fullscreen, styles.opague, styles.shadow, className)}>
        {children}
    </DialogFrame>;
};

export const LargeDialogFrame: React.SFC<{className?: string}> = ({children, className}) => {
    return <DialogFrame className={cn(styles.huge, styles.opague, styles.shadow, className)}>
        {children}
    </DialogFrame>;
};

export const MediumDialogFrame: React.SFC<{className?: string}> = ({children, className}) => (
    <FrameWrapper>
         <div className={cn(styles.opague, styles.shadow, styles.medium, className)}>
            {children}
        </div>
    </FrameWrapper>
);

export const SmallDialogFrame: React.SFC<{className?: string}> = ({children, className}) => (
    <MediumDialogFrame className={cn(styles.small, className)} children={children} />
);
