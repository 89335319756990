import { KeyValuePair } from "components/EditList/KeyValueEditList";

export interface NginxLocation {
    path: string;
    directives: string;
    headers: string;
    reverseProxy: boolean;
    reverseProxyUrl: string;
    reverseProxyHeaders: string;
    reverseProxyDirectives: string;
}

const nginxLocationsFromString = (locationsString: string): NginxLocation[] => {
    return JSON.parse(locationsString);
};

const nginxLocationsToString = (locations: NginxLocation[]): string => {
    return JSON.stringify(locations);
};

const keyValuesToString = (keyValues: any) => {
    return JSON.stringify(keyValuesToHash(keyValues));
};

function keyValuesToHash(values: KeyValuePair[]) {
    return values.reduce((idx: any, item) => {
        idx[item.key] = item.value;
        return idx;
    }, {});
}

function keyValuesFromString(value: string) {
    if (value === null || value === undefined) {
        return [];
    }

    try {
        const source = JSON.parse(value || "{}");
        return Object.keys(source).reduce((arr, key) => {
            arr.push({key, value: source[key]});
            return arr;
        }, []);
    } catch (e) {
        return [];
    }
}
export {nginxLocationsFromString, nginxLocationsToString, keyValuesToString, keyValuesFromString};