import Resource from "./resource";

export interface LicenseStatusResource extends Resource {
    LicenseKind: LicenseKind;
    IsCompliant: boolean;
    HostingEnvironment: string;
    ComplianceSummary: string;
    EffectiveExpiryDate: string;
    DaysToEffectiveExpiryDate: number;
    Messages: LicenseMessage[];
    Limits: LicenseLimitStatus[];
}

export interface LicenseMessage {
    Message: string;
    Disposition: LicenseMessageDisposition;
}

export interface LicenseLimitStatus {
    Name: string;
    EffectiveLimit: number;
    EffectiveLimitDescription: string;
    IsUnlimited: boolean;
    CurrentUsage: number;
    Message: string;
    Disposition: LicenseMessageDisposition;
}

export enum LicenseKind {
    Unlicensed = "Unlicensed",
    Trial = "Trial",
    CommunityEdition = "CommunityEdition",
    CommercialLicense = "CommercialLicense"
}

export enum LicenseMessageDisposition {
    Information = "Information",
    Warning = "Warning",
    Error = "Error"
}

export default LicenseStatusResource;