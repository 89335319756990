import Resource from "./resource";
import {SensitiveValue} from "./propertyValueResource";
import {TenantedDeploymentMode} from "client/resources";

export interface Certificate {
    SubjectDistinguishedName?: string;
    IssuerDistinguishedName?: string;
    Thumbprint?: string;
    NotAfter?: string;
    NotBefore?: string;
    Version?: number;
    SerialNumber?: string;
    SignatureAlgorithmName?: string;
}

export interface CertificateResource extends Resource, Certificate {
    Name: string;
    Notes?: string;
    CertificateData: SensitiveValue;
    Password: SensitiveValue;
    EnvironmentIds: string[];
    TenantIds: string[];
    TenantTags: string[];
    CertificateDataFormat?: CertificateDataFormat;
    Archived?: string;
    ReplacedBy?: string;
    SubjectCommonName?: string;
    SubjectOrganization?: string;
    IssuerCommonName?: string;
    IssuerOrganization?: string;
    SelfSigned?: boolean;
    IsExpired?: boolean;
    HasPrivateKey?: boolean;
    TenantedDeploymentParticipation?: TenantedDeploymentMode;
    SubjectAlternativeNames?: string[];
    CertificateChain?: X509Certificate[];
}

export type X509Certificate = Certificate;

export enum CertificateDataFormat {
    Pkcs12 = "Pkcs12",
    Der = "Der",
    Pem = "Pem",
    Unknown = "Unknown"
}

export enum CertificateExportPemOptions {
    PrimaryOnly = "PrimaryOnly",
    PrimaryAndChain = "PrimaryAndChain",
    ChainOnly = "ChainOnly"
}