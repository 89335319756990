import * as React from "react";
import * as cn from "classnames";
const styles = require("./executingStyle.less");

const ExecutingIcon = () => {
    return <div className={styles.outer}>
        <div className={styles.border}>
            <div className={styles.wrapper}>
                <div className={cn(styles.pie, styles.spinner)}/>
                <div className={cn(styles.pie, styles.filler1)}/>
                <div className={cn(styles.pie, styles.filler2)}/>
                <div className={styles.mask1}/>
                <div className={styles.mask2}/>
            </div>
        </div>
    </div>;
};

export default ExecutingIcon;