import {MenuNode} from "components/LinksMenu/MenuNode";
import Markdown from "components/Markdown";
import { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { Location } from "history";
import * as H from "history";
import { flatten } from "lodash";
import * as React from "react";
import * as MediaQuery from "react-responsive";
import { matchPath, RouteComponentProps, withRouter } from "react-router";
import { navLinksClass } from "uiTestClasses";
import {baseSizeInPx} from "fontWeights";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import Logo from "../Logo/Logo";
import PriorityNavigation from "../PriorityNavigation/PriorityNavigation";
const styles = require("./style.less");
import InternalNavLink from "../Navigation/InternalNavLink/InternalNavLink";

export class Navigation {
    static navItem(label: string | JSX.Element, path: string, exact?: boolean, permission?: PermissionCheckProps): NavLink {
        return !permission || isAllowed(permission) ? { label, path, exact: !!exact  } : null;
    }
    static navGroup(label: string, path: string, children: NavItem[], permission?: PermissionCheckProps): NavGroup {
        return !permission || isAllowed(permission) ? { groupLabel: label, defaultChildPath: path, children } : null;
    }
}

interface NavLink {
    label: string | JSX.Element;
    path: string;
    exact: boolean;
}

interface NavGroup {
    groupLabel: string;
    defaultChildPath: string;
    children: NavItem[];
}

export type NavItem = NavLink | NavGroup;

interface NavigationSidebarLayoutComponentProps {
    logoUrl?: string;
    image?: React.ReactNode;
    name?: string;
    resourceType?: string;
    description?: string;
    preNavbarComponent?: any; //mark.siedle: Review which type we should use here.
    navLinks: NavItem[];
    content: React.ReactNode;
    location?: H.Location;
}

type NavigationSidebarLayoutProps = NavigationSidebarLayoutComponentProps & RouteComponentProps<void>;

const screenMd = 1279;

const NavigationSidebarLayout: React.StatelessComponent<NavigationSidebarLayoutProps> = props => {
    // we HAVE to use the render prop format here, if we use multiple MediaQuery tags all the content
    // gets torn down and remounted at the mediaquery breakpoints, which means you loose all state.
    // the props.content needs to be the same "shape" with the same key too so that it is retained.
    // Test that form content isn't cleared if you change this.
    return <div>
        <MediaQuery minWidth={screenMd}>
            {(matches: boolean) => {
                if (matches) {
                    return <div className={styles.sidebarLayout}>
                        <div className={styles.sideMenu}>
                            {props.logoUrl && <Logo url={props.logoUrl} size={100 / baseSizeInPx + "rem"} />}
                            {props.image && props.image}
                            {props.name && <div className={styles.name}>
                                {props.resourceType && <div className={styles.resourceType}>
                                    {props.resourceType}
                                </div>}
                                {props.name}
                            </div>}
                            {props.preNavbarComponent && <div className={styles.preNav}>
                                {props.preNavbarComponent}
                            </div>}
                            <nav className={`${navLinksClass} ${styles.links}`}>
                                {props.navLinks.filter(x => !!x).map((link, index) => <NavItemComponent item={link} key={index} />)}
                            </nav>
                            {props.description && <div className={styles.description}>
                                <Markdown markup={props.description} />
                            </div>}
                        </div>
                        <div className={styles.sidebarLayoutContent} key="content">
                            {content(props)}
                        </div>
                    </div>;
                } else {
                    return <div className={styles.stackedLayout}>
                        {(props.logoUrl || props.name) &&
                        <div className={styles.title}>
                            {props.logoUrl &&
                            <div className={styles.logo}><Logo size={50 / baseSizeInPx + "rem"} url={props.logoUrl}/></div>}
                            {props.image && props.image}
                            {props.name && <div className={styles.nameHorizontal}>
                                {props.name}
                            </div>}
                        </div>}
                        {props.preNavbarComponent && <div className={styles.preNav}>{props.preNavbarComponent}</div>}
                        <PriorityNavigation className={`${navLinksClass} ${styles.horizontalLinks}`}
                                            activeItemClassName={styles.selected}
                                            maxNavigationItems={99}
                                            navigationItems={props.navLinks.filter(x => !!x).map(convertNavItemToMenuNode)}/>
                        <div key="content">
                            {content(props)}
                        </div>
                    </div>;
                }
            }}
        </MediaQuery>
    </div>;
};

// ErrorBoundary doesn't reset its state when applied at a level lower than route level and that's why we have to do ourselves here.
function content(props: NavigationSidebarLayoutComponentProps) {
    return <ErrorBoundary key={props.location.pathname}>
        {props.content}
    </ErrorBoundary>;
}

function convertNavItemToMenuNode(item: NavItem): MenuNode {
    if (isGroup(item)) {
        return { label: item.groupLabel, children: item.children.map(convertNavItemToMenuNode) };
    }
    return { url: item.path, text: item.label, exact: item.exact } as any;
}

function isGroup(item: NavItem): item is NavGroup {
    return (item as NavGroup).children !== undefined;
}

type NavItemComponentProps = {item: NavItem};

const NavItemComponent: React.SFC<NavItemComponentProps> = (props: NavItemComponentProps) => {
    const item = props.item;
    return isGroup(item)
        ? renderNavLinkGroup(item)
        : renderNavLink(item.label, item.path, item.exact);

    function renderNavLinkGroup(group: NavGroup) {
        return <ReloadableRoute render={(routeProps: RouteComponentProps<any>) =>
            <NavLinkGroup group={group} location={routeProps.location} />}
        />;
    }
};

function renderNavLink(label: string | JSX.Element, path: string, exact?: boolean) {
    return <InternalNavLink to={path}
                     activeClassName={styles.selected}
                     className={styles.link}
                     key={path}
                     exact={exact}>
        {label}
    </InternalNavLink>;
}

const NavLinkGroup: React.SFC<{ group: NavGroup, location: Location }> = (props: { group: NavGroup, location: Location }) => {
    const allDescendantLinks = getDescendantLinks(props.group);
    const anyDescendantsMatch = allDescendantLinks.some(l => !!matchPath(props.location.pathname, { path: l.path, exact: l.exact }));
    return <div>
        {renderNavLink(props.group.groupLabel, props.group.defaultChildPath, anyDescendantsMatch)}
        {anyDescendantsMatch && <div className={styles.nestedNavLinks}>
            {props.group.children.map((g, index) => <NavItemComponent key={index} item={g} />)}
        </div>}
    </div>;
};

function getDescendantLinks(group: NavGroup): NavLink[] {
    return flatten(group.children.map(c => {
        if (isGroup(c)) {
            return getDescendantLinks(c);
        }
        return [c];
    }));
}

export default withRouter(NavigationSidebarLayout);
