import * as React from "react";
import {
    TriggerScheduleResource,
    EnvironmentResource,
    ResourceCollection,
    ChannelResource,
    ScopedDeploymentActionResource,
    DaysPerMonthTriggerScheduleResource,
    DaysPerWeekTriggerScheduleResource,
    DailyTriggerScheduleResource,
    CronTriggerScheduleResource,
    DeployLatestReleaseActionResource,
    DeployNewReleaseActionResource,
    TriggerScheduleIntervalResource,
    TriggerActionType,
    TriggerScheduleIntervalType,
    TriggerFilterType,
    TriggerScheduleRunType
} from "client/resources";
import DaysPerWeekDescriptionHelper from "./DaysPerWeekDescriptionHelper";
import DaysPerMonthDescriptionHelper from "./DaysPerMonthDescriptionHelper";
import { Moment } from "moment";
import * as moment from "moment";
import DateFormatter from "utils/DateFormatter/DateFormatter";
import ScheduleIntervalDescriptionHelper from "./ScheduleIntervalDescriptionHelper";
import {environmentChipListIncludingMissing, channelChipList} from "components/Chips";
import cronstrue from "cronstrue";

export default class ScheduledTriggerDescriptionHelper {
    public static getScheduleDescription(schedule: TriggerScheduleResource, emptyText: string = "") {
        switch (schedule.FilterType) {
            case TriggerFilterType.DailySchedule:
                return this.getDailyScheduleDescription(schedule as DailyTriggerScheduleResource);
            case TriggerFilterType.DaysPerWeekSchedule:
                return this.getDaysPerWeekScheduleDescription(schedule as DaysPerWeekTriggerScheduleResource, emptyText);
            case TriggerFilterType.DaysPerMonthSchedule:
                return this.getDaysPerMonthScheduleDescription(schedule as DaysPerMonthTriggerScheduleResource, emptyText);
            case TriggerFilterType.CronExpressionSchedule:
                return this.getCronExpressionScheduleDescription(schedule as CronTriggerScheduleResource, emptyText);
        }
    }

    public static getActionDescription(
        action: ScopedDeploymentActionResource,
        environments: EnvironmentResource[],
        channels: ChannelResource[],
        emptyText: string = "") {
        let preText = null;
        let postText = null;
        let sourceEnvironmentId: string = null;
        let destinationEnvironmentId = null;

        switch (action.ActionType) {
            case TriggerActionType.DeployLatestRelease:
                preText = <strong>Deploy the latest release</strong>;
                sourceEnvironmentId = (action as DeployLatestReleaseActionResource).SourceEnvironmentId;
                destinationEnvironmentId = (action as DeployLatestReleaseActionResource).DestinationEnvironmentId;
                break;
            case TriggerActionType.DeployNewRelease:
                preText = <strong>Create a new release</strong>;
                postText = <strong> and deploy </strong>;
                destinationEnvironmentId = (action as DeployNewReleaseActionResource).EnvironmentId;
                break;
        }

        return (
            <span>
                {preText}
                {action.ChannelId && <span> in {channelChipList(channels, [action.ChannelId])}</span>}
                {postText}
                {sourceEnvironmentId && <span> from {environmentChipListIncludingMissing(environments, [sourceEnvironmentId])}</span>}
                <span> to {environmentChipListIncludingMissing(environments, [destinationEnvironmentId])}</span>
            </span>
        );
    }

    public static getScheduleIntervalDescription(interval: TriggerScheduleIntervalResource, emptyText: string = "") {
        const scheduleIntervalDescription = new ScheduleIntervalDescriptionHelper(interval);
        return scheduleIntervalDescription.getSummary(emptyText);
    }

    private static getDailyScheduleDescription(schedule: DailyTriggerScheduleResource) {
        return (
            <span>
                {this.getIntervalDescription(schedule.Interval, schedule.HourInterval, schedule.MinuteInterval)}
                {this.getStartTimeDescription(schedule.StartTime, schedule.RunType)}
            </span>
        );
    }

    private static getDaysPerWeekScheduleDescription(schedule: DaysPerWeekTriggerScheduleResource, emptyText: string) {
        const daysPerWeekDescription = new DaysPerWeekDescriptionHelper(schedule).getSummary(emptyText);
        return (
            <span>
                {this.getIntervalDescription(schedule.Interval, schedule.HourInterval, schedule.MinuteInterval)}
                <span> {daysPerWeekDescription}</span>
                {this.getStartTimeDescription(schedule.StartTime, schedule.RunType)}
            </span>
        );
    }

    private static getDaysPerMonthScheduleDescription(schedule: DaysPerMonthTriggerScheduleResource, emptyText: string) {
        const daysPerMonthDescription = new DaysPerMonthDescriptionHelper(schedule).getSummary(emptyText);
        return (
            <span>
                {daysPerMonthDescription}
                {this.getStartTimeDescription(schedule.StartTime)}
            </span>
        );
    }

    private static getCronExpressionScheduleDescription(schedule: CronTriggerScheduleResource, emptyText: string) {
        let cronMeaning = cronstrue.toString(schedule.CronExpression);
        cronMeaning = cronMeaning.charAt(0).toLowerCase() + cronMeaning.slice(1);
        return <strong>{cronMeaning}</strong>;
    }

    private static getIntervalDescription(intervalType: TriggerScheduleIntervalType, hourInterval?: number, minuteInterval?: number) {
        let intervalDescription = null;
        switch (intervalType) {
            case TriggerScheduleIntervalType.OnceDaily:
                intervalDescription = <strong> once daily</strong>;
                break;
            case TriggerScheduleIntervalType.OnceHourly:
                intervalDescription = <span> <strong>every {hourInterval} {hourInterval > 1 ? "hours" : "hour"} daily</strong></span>;
                break;
            case TriggerScheduleIntervalType.OnceEveryMinute:
                intervalDescription = <span> <strong>every {minuteInterval} {minuteInterval > 1 ? "minutes" : "minute"} daily</strong></span>;
                break;
        }
        return intervalDescription;
    }

    private static getStartTimeDescription(startTime?: Date, runType?: TriggerScheduleRunType) {
        const runTypeOrDefault = runType ? runType : TriggerScheduleRunType.ScheduledTime;
        switch (runTypeOrDefault) {
            case TriggerScheduleRunType.ScheduledTime:
                return (
                    <span>
                        <span> starting at </span>
                        <strong>{this.getFormattedStartTime(startTime)}</strong>
                    </span>
                );
            case TriggerScheduleRunType.Continuously:
                return (
                    <span> running continously</span>
                );
        }
    }

    private static getFormattedStartTime(startTime: Date) {
        return DateFormatter.dateToCustomFormat(moment(startTime).utc(), "hh:mm A");
    }
}