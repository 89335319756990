import * as React from "react";
import ActionButton, { ActionButtonType } from "components/Button/ActionButton";
import Card from "components/Card";
import DialogTrigger, { DialogTriggerRenderProps } from "components/Dialog/DialogTrigger";

const styles = require("./PackagingCard.less");

export interface PackagingCardDispatchProps {
    onNavigate?: () => void;
}

export interface PackagingCardDetailProps {
    header: React.ReactNode;
    description: React.ReactNode;
    buttonLabel?: string;
}

export type PackagingCardProps = PackagingCardDispatchProps & PackagingCardDetailProps;

export const PackagingCard: React.SFC<PackagingCardProps> = (props) => (
    <Card logo={null}
        className={styles.card}
        header={props.header}
        content={<div className={styles.description}>{props.description}</div>}
        includeContentFade={false} /* Don't set true here, or you see the contentFade onHover */
        onHoverView={<div className={styles.onHoverActions} onClick={props.onNavigate}>
            <ActionButton
                label={props.buttonLabel ? props.buttonLabel : "Add"}
                type={ActionButtonType.Primary}
                onClick={props.onNavigate}
            />
        </div>}
    />
);

export type InformationalPackagingCardProps = PackagingCardDetailProps & {
    renderDialog: (props: DialogTriggerRenderProps) => React.ReactElement<any>;
};

export const InformationalPackagingCard: React.SFC<InformationalPackagingCardProps> = ({ buttonLabel = "View Instructions", renderDialog, ...rest }) => (
    <DialogTrigger render={(renderProps) => (
        <React.Fragment>
            <PackagingCard {...rest} onNavigate={renderProps.openDialog} buttonLabel={buttonLabel} />
            {renderDialog(renderProps)}
        </React.Fragment>
    )} />
);

export type SimpleInformationalPackagingCardProps = PackagingCardDetailProps & {
    renderView: () => React.ReactElement<any>
};

export default PackagingCard;