exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".styles_pageDivider__MsBlZ {\n  background-color: rgba(153, 153, 153, 0.08);\n  padding: 0.5rem 1.125rem;\n  margin-right: 1rem;\n  font-size: 1.0625rem;\n  border-radius: 0.25rem;\n  overflow: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"pageDivider": "styles_pageDivider__MsBlZ"
};