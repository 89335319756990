import ParseHelper from "utils/ParseHelper";
import * as React from "react";
import { Switch } from "react-router-dom";
import DeploymentProcessOverview from "./DeploymentProcessOverview";
import StepDetailsLoader from "./StepDetailsLoader";
import ActionTemplateSelector from "areas/projects/components/ActionTemplateSelector/ActionTemplateSelector";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import pageIds from "pageIds";
import { withPage } from "components/Page/Page";

const specificProjectPage = pageIds.project();

export const CreateStepDetailsPage = withPage({page: specificProjectPage.process.stepNew})(StepDetailsLoader);
export const StepDetailsPage = withPage({ page: specificProjectPage.process.step })(StepDetailsLoader);
export const ActionTemplateSelectionPage = withPage({ page: specificProjectPage.process.stepTemplates })(ActionTemplateSelector);
export const DeploymentProcessOverviewPage = withPage({ page: specificProjectPage.process.root })(DeploymentProcessOverview);

export class DeploymentProcessRoute extends React.Component<any, any> {
    static nextStepReloadPath(currentPath: string, currentKey: string): string {
        let path = currentPath;
        if (currentKey) {
            const keyNum = ParseHelper.safeParseInt(currentKey, 0) + 1;
            path = path.slice(0, -currentKey.length) + keyNum.toString();
        } else {
            path = path + "/1";
        }
        return path;
    }

    render() {
        const match = this.props.match;
        return (
            <Switch>
                <ReloadableRoute path={`${match.path}/step/new/:actionType/:template?`} component={CreateStepDetailsPage} />
                <ReloadableRoute path={`${match.path}/step/:stepId/new/:actionType/:template?`} component={CreateStepDetailsPage} />
                <ReloadableRoute path={`${match.path}/step/:stepId/:reloadKey?`} component={StepDetailsPage} />
                <ReloadableRoute path={`${match.path}/steptemplates`} component={ActionTemplateSelectionPage} />
                <ReloadableRoute path={`${match.path}/childsteptemplates/:parentStepId`} component={ActionTemplateSelectionPage} />
                <ReloadableRoute path={`${match.path}`} component={DeploymentProcessOverviewPage} />
            </Switch>
        );
    }
}
