exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__2TMMJ {\n  display: flex;\n  align-items: center;\n  height: 100vh;\n  justify-content: center;\n  flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__2TMMJ"
};