import * as React from "react";
import { isEqual } from "lodash";
import {Cancelable, debounce} from "lodash";

interface ValueProps<TValue> {
    value?: TValue;
    onChange(value: TValue): void;
}

interface DebounceValueState<TValue> {
    value?: TValue;
}

interface DebounceValueProps {
    debounceDelay?: number;
    innerRef?: (component: any) => void; // It would be nice to tighten up the typing here
}

export default function DebounceValue<TProps extends ValueProps<TValue>, TValue>(
    Comp: React.ComponentClass<TProps>
) {
    type DebounceValueInternalProps = TProps & DebounceValueProps;
    return class DebounceValueInternal extends React.Component<DebounceValueInternalProps, DebounceValueState<TValue>> {
        private readonly onChange: ((value: TValue) => void) & Cancelable;

        constructor(props: DebounceValueInternalProps) {
            super(props);
            this.state = {
                value: props.value
            };
            this.onChange = debounce((value: TValue) => this.props.onChange(value), this.props.debounceDelay ? this.props.debounceDelay : 250);
        }

        componentWillReceiveProps(nextProps: Readonly<DebounceValueInternalProps>) {
            if (!isEqual(nextProps.value, this.props.value)) {
                this.setState({value: nextProps.value});
                this.onChange.cancel();
            }
        }

        render() {
            const {
                debounceDelay,
                innerRef,
                ...otherProps
            } = this.props as any; // `as any` because object rest not support in generics yet, tracked by https://github.com/Microsoft/TypeScript/issues/10727
            return <Comp {...otherProps}
                         value={this.state.value}
                         ref={(c) => { if (innerRef) { innerRef(c); }}}
                         onChange={value => {
                            this.setState({value});
                            this.onChange(value);
                        }}
            />;
        }
    };
}