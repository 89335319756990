import {
    ActionTemplateParameterResource,
    ActionTemplateResource,
    ActionUpdateOutcome,
    ActionUpdatePackageUsedBy,
    ActionUpdateRemovedPackageUsage,
    ActionUpdateResultResource,
    DeploymentProcessResource,
    OctopusError
} from "../../client/resources";
import ParseHelper from "../../utils/ParseHelper";
import Markdown from "../Markdown";
import SimpleDataTable from "../SimpleDataTable";
import ActionPropertyTable from "./ActionPropertyTable";
import {ActionButtonType, default as ActionButton} from "../Button/ActionButton";
import {CalloutType, default as Callout} from "../Callout/Callout";
import * as React from "react";
import {repository} from "clientInstance";
import ActionTemplateParameterInput, {SourceItems} from "components/ActionTemplateParameterInput/ActionTemplateParameterInput";
import {FormSectionHeading, UnstructuredFormSection} from "components/form";
import {BaseComponent} from "components/BaseComponent/BaseComponent";
import {RouteComponentProps, withRouter} from "react-router";
import {each, flatten} from "lodash";
import ActionProperties from "client/resources/actionProperties";
import {DeploymentProcessRoute} from "areas/projects/components/DeploymentProcess/DeploymentProcessRoute";
import InternalLink from "components/Navigation/InternalLink";
import routeLinks from "../../routeLinks";
import Note from "../form/Note/Note";
import ActionTemplateParameterInputExpandableFormElement from "../ActionTemplateParameterInput/ActionTemplateParameterInputExpandableFormElement";
import InternalRedirect from "../Navigation/InternalRedirect/InternalRedirect";

interface ActionTemplateEditorProps extends RouteComponentProps<any> {
    actionTemplate: ActionTemplateResource;
    actionId: string;
    properties: ActionProperties;
    process: DeploymentProcessResource;
    localNames?: string[];
    projectId?: string;
    doBusyTask(action: () => Promise<void>): Promise<boolean>;
    setProperties(properties: Partial<ActionProperties>): void;
}

interface ActionTemplateEditorState {
    isUpToDate: boolean;
    manualMergeRequired: boolean;
    version: number;
    actionTemplateVersion: ActionTemplateResource;
    currentPropertyValues: any;
    overrides: any;
    latestRemovesPackagesInUse: boolean;
    removedPackageUsages: ActionUpdateRemovedPackageUsage[];
    sourceItems: SourceItems;
    redirectTo: string;
}

class ActionTemplateEditor extends BaseComponent<ActionTemplateEditorProps, ActionTemplateEditorState> {
    constructor(props: ActionTemplateEditorProps) {
        super(props);

        const version = ParseHelper.safeParseInt(this.props.properties["Octopus.Action.Template.Version"] as string);

        const currentPropertyValues: any = {};
        each(this.props.properties, (propertyValue, propertyName) => {
            currentPropertyValues[propertyName] = this.isPropertySensitive(propertyValue)
                ? "******"
                : propertyValue;
        });

        const stepNames: string[] = props.process ? flatten(props.process.Steps.map((a) => a.Actions.map((action) => action.Name))) : [];

        this.state = {
            manualMergeRequired: false,
            isUpToDate: this.props.actionTemplate.Version === version,
            version,
            actionTemplateVersion: null,
            currentPropertyValues,
            sourceItems: {stepNames, projectId: this.props.projectId},
            overrides: {},
            latestRemovesPackagesInUse: false,
            removedPackageUsages: [],
            redirectTo: null
        };
    }

    async componentDidMount() {
        this.props.doBusyTask(async () => {
            const versionGetter = this.state.isUpToDate
                ? Promise.resolve(this.props.actionTemplate)
                : repository.ActionTemplates.getVersion(this.props.actionTemplate, this.state.version);

            try {
                const actionTemplateVersion = await versionGetter;
                this.setState({ actionTemplateVersion });
            } catch (error) {
                if (error.StatusCode !== 404) {
                    throw error;
                }
            }
        });
    }

    render() {
        if (this.state.redirectTo) {
            return <InternalRedirect to={this.state.redirectTo} push={false}/>;
        }

        const canUpdateAutomatically = !this.state.isUpToDate && !this.state.manualMergeRequired && !this.state.latestRemovesPackagesInUse;

        return <div>
            <FormSectionHeading title={this.props.actionTemplate.Name} />

            <UnstructuredFormSection>
                <span>This step is based on a {this.props.actionTemplate.CommunityActionTemplateId ? "community" : "custom"}
                <InternalLink to={routeLinks.library.stepTemplate(this.props.actionTemplate.Id).root}><strong> {this.props.actionTemplate.Name} </strong></InternalLink>
                step template.</span>
            </UnstructuredFormSection>

            {!this.state.isUpToDate && <Callout type={CalloutType.Warning}>
                <strong>This step is out of date.</strong><br />
                '{this.props.actionTemplate.Name}' step template has changed since this step was configured.
                    {canUpdateAutomatically && <span> Update now to merge the latest changes.</span>}<br />
                {!this.state.actionTemplateVersion && <p>
                    Version '{this.state.version}' of '{this.props.actionTemplate.Name}' step template is not available and properties can only be displayed in read-only
                    mode. To start storing all versions of '{this.props.actionTemplate.Name}' step template and safely update automatically in the future, update
                    to the latest version now.
                </p>}
                {canUpdateAutomatically && <ActionButton
                    type={ActionButtonType.Secondary}
                    label="Update"
                    busyLabel="Updating..."
                    onClick={this.mergeLatest} /> }

                {this.state.manualMergeRequired && <React.Fragment>
                    <p>Unfortunately the step must be updated manually because we don't have enough information to do it automatically. Please, review
                        carefully the new property values below and confirm them when you are ready.</p>
                    <ActionButton
                        type={ActionButtonType.Secondary}
                        label="Confirm"
                        onClick={this.resolveConflicts} />
                </React.Fragment>}

                {this.state.latestRemovesPackagesInUse && <React.Fragment>
                    <p>This step cannot be automatically updated. The latest version of the step template has removed {this.packagePlural(this.state.removedPackageUsages.length)} which this project depends on.</p>
                    <ul>
                        {this.state.removedPackageUsages.map(pkg => this.renderUsedPackage(pkg))}
                    </ul>
                    <p>The above usages must be removed before this step can be updated.</p>
                </React.Fragment>}
            </Callout>}

            {!this.state.manualMergeRequired && !this.state.actionTemplateVersion &&
                <ActionPropertyTable actionProperties={this.props.properties} currentPropertyValues={this.state.currentPropertyValues} />
            }

            {!this.state.manualMergeRequired && this.state.actionTemplateVersion && <div>
                {this.state.actionTemplateVersion.Parameters.map(parameter => {
                    return <ActionTemplateParameterInputExpandableFormElement
                        parameter={parameter}
                        key={parameter.Name}
                        projectId={this.props.projectId}
                        localNames={this.props.localNames}
                        value={this.props.properties[parameter.Name]}
                        doBusyTask={this.props.doBusyTask}
                        sourceItems={this.state.sourceItems}
                        onChange={(x) => this.props.setProperties({[parameter.Name]: x})} />;
                })}
            </div>}

            {this.state.manualMergeRequired && <div>
                {this.resolveTable()}
            </div>}
        </div>;
    }

    private resolveTable = () => {
        return <SimpleDataTable
            data={this.props.actionTemplate.Parameters}
            headerColumns={["Property Name", "Current Property Value", "New Property Value"]}
            onRow={this.buildRow} />;
    }

    private buildRow = (parameter: ActionTemplateParameterResource) => {
        return [
            <span>{parameter.Label || parameter.Name}</span>,
            this.state.currentPropertyValues[parameter.Name],
            <div><ActionTemplateParameterInput
                parameter={parameter}
                doBusyTask={this.props.doBusyTask}
                value={this.state.overrides[parameter.Name]}
                sourceItems={this.state.sourceItems}
                onChange={(x) => this.handleParameterInputChanged(parameter.Name, x)} />
                <Note><Markdown markup={parameter.HelpText}/></Note>
            </div>
        ];
    }

    private handleParameterInputChanged = (name: string, value: any) => {
        this.setState(state => ({overrides: {...state.overrides, [name]: value}}));
    }

    private resolveConflicts = () => {
        this.props.doBusyTask(async () => {
            //Empty values are not usually sent to the server but in this case we really need to know that the user decided to set something to an empty value.
            const overrides = { ...this.state.overrides };
            this.props.actionTemplate.Parameters.forEach(p => {
                if (overrides[p.Name] === undefined) {
                    overrides[p.Name] = null;
                }
            });
            this.setState({ overrides });

            const actionIdsByProcessId: any = {};
            actionIdsByProcessId[this.props.process.Id] = [this.props.actionId];
            try {
                await repository.ActionTemplates.updateActions(this.props.actionTemplate, actionIdsByProcessId, null, overrides);
                this.reloadThePage();
            } catch (error) {
                if (error.StatusCode !== 400) {
                    throw error;
                }
                this.setState({ manualMergeRequired: true });
            }
        });
    }

    private mergeLatest = () => {
        this.props.doBusyTask(async () => {
            const actionIdsByProcessId: any = {};
            actionIdsByProcessId[this.props.process.Id] = [this.props.actionId];
            try {
                await repository.ActionTemplates.updateActions(this.props.actionTemplate, actionIdsByProcessId);
                this.reloadThePage();
            } catch (error) {
                // If it's a conflict from attempting to update the action template
                if (error.StatusCode === 400 && this.isOctopusError(error) && error.Details) {
                    const result = error.Details[0];
                    if (this.isActionUpdateResult(result)) {
                       switch (result.Outcome) {
                           case ActionUpdateOutcome.RemovedPackageInUse: {
                              this.setState({latestRemovesPackagesInUse: true, removedPackageUsages: result.RemovedPackageUsages});
                              break;
                           }
                           default: {
                               const overrides: any = {};
                               this.props.actionTemplate.Parameters.forEach((p) => {
                                   const propertyValue = this.props.properties[p.Name];
                                   if (propertyValue !== undefined) {
                                       overrides[p.Name] = propertyValue;
                                   }
                               });

                               this.setState({overrides, manualMergeRequired: true});
                               break;
                           }
                       }
                    }
                } else {
                    throw error;
                }
            }
        });
    }

    private reloadThePage() {
        const currentPath = this.props.location.pathname;
        const reloadKey = this.props.match.params.reloadKey;
        const path = DeploymentProcessRoute.nextStepReloadPath(currentPath, reloadKey);

        this.setState({redirectTo: path});
    }

    private renderUsedPackage(pkgUsage: ActionUpdateRemovedPackageUsage) {
        return <li>Package {pkgUsage.PackageReference} is used by {this.renderUsedPackageLink(pkgUsage)}</li>;
    }

    private renderUsedPackageLink(pkgUsage: ActionUpdateRemovedPackageUsage) {
        switch (pkgUsage.UsedBy) {
            case ActionUpdatePackageUsedBy.ProjectVersionStrategy: {
                return <InternalLink to={routeLinks.project(this.props.projectId).settings} openInSelf={false}>project versioning strategy</InternalLink>;
            }
            case ActionUpdatePackageUsedBy.ProjectReleaseCreationStrategy: {
                return <InternalLink to={routeLinks.project(this.props.projectId).triggers} openInSelf={false}>project release creation strategy</InternalLink>;
            }
            case ActionUpdatePackageUsedBy.ChannelRule: {
                return <InternalLink to={routeLinks.channel(pkgUsage.UsedById)} openInSelf={false}>rule in channel '{pkgUsage.UsedByName}'</InternalLink>;
            }
            // We should never get here, but we'll attempt to handle gracefully anyway
            default: {
                return <span>{pkgUsage.UsedByName}</span>;
            }
        }
    }

    private isPropertySensitive(property: any) {
        return Object.prototype.toString.call(property) === "[object Object]";
    }

    private isOctopusError(ex: any): ex is OctopusError {
        return (ex as OctopusError).Details !== undefined;
    }

    private isActionUpdateResult(result: any): result is ActionUpdateResultResource {
        return (result as ActionUpdateResultResource).Outcome !== undefined;
    }

    private packagePlural = (n: number) => {
        return n === 1 ? "a package" : "packages";
    }
}

const Editor = withRouter(ActionTemplateEditor);
export default Editor;