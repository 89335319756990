import * as React from "react";
import { DataBaseComponent } from "../DataBaseComponent/DataBaseComponent";
import { EventResource } from "../../client/resources/eventResource";
import { ResourceCollection } from "client/resources/resourceCollection";
import { repository } from "clientInstance";
import { Permission } from "client/resources/permission";
import { PermissionCheck } from "components/PermissionCheck";
import { Callout, CalloutType } from "components/Callout/Callout";
import List from "components/List";
import AuditListRow from "components/AuditListRow/AuditListRow";

class EventListingList extends List<EventResource> { }

interface EventListingProps {
    data: ResourceCollection<EventResource>;
    regarding: string[];
    noDataMessage?: string;
}

export default class EventListing extends DataBaseComponent<EventListingProps, any> {
    constructor(props: EventListingProps) {
        super(props);
        this.state = {
            data: null
        };
    }

    render() {
        const initialData = this.state.data ? this.state.data : this.props.data;
        return <div>
            <PermissionCheck permission={Permission.EventView} wildcard={true} alternate={
                <Callout type={CalloutType.Information}>
                    The {Permission.EventView} permission is required to view results here.
                </Callout>}>
                {initialData
                    ? <EventListingList
                        initialData={initialData}
                        onRow={(item: EventResource) => <AuditListRow event={item} />}
                        showPagingInNumberedStyle={true}
                        onPageSelected={async (skip: number, p: number) => {
                            const result = await repository.Events.list({ regarding: this.props.regarding, skip });
                            this.setState({ data: result });
                        }}
                        wrapRowsInListItem={false} // AuditListRow does custom styling on these rows.
                    />
                    : <div>{this.props.noDataMessage || "There's no data to show."}</div>}
            </PermissionCheck>
        </div>;
    }
}