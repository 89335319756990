exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_minimumEnvText__3Iuc0 {\n  max-width: none!important;\n  width: 2.5rem!important;\n  display: inline-block !important;\n  z-index: 10;\n  height: 1.25rem!important;\n  margin-bottom: 0.2rem!important;\n}\n.style_minimumEnvText__3Iuc0 hr {\n  bottom: 0!important;\n}\n.style_minimumEnvText__3Iuc0 input {\n  font-size: 0.875rem!important;\n}\n.style_minCompleteLabel__2sLhq {\n  margin-left: 0 !important;\n  height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"minimumEnvText": "style_minimumEnvText__3Iuc0",
	"minCompleteLabel": "style_minCompleteLabel__2sLhq"
};