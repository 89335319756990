import * as React from "react";
import * as cn from "classnames";

const styles = require("./style.less");

interface DataTableBodyProps {
    className?: string;
    children?: React.ReactNode;
}

export class DataTableBody extends React.Component<DataTableBodyProps, never> {
    render() {
        return (
            <tbody className={cn(this.props.className, styles.dataTableBody)}>
                {this.props.children}
            </tbody>
        );
    }
}