exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .style_hide-sm__1rfbU {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .style_visible-sm__2pCpd {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .style_hide-md__3xdAx {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .style_visible-md__1SgQn {\n    display: none !important;\n  }\n}\n.style_titleBar__3251A {\n  background-color: #ffffff;\n  height: 3rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 1rem;\n}\n.style_title__Yghwr {\n  flex-grow: 1;\n  font-size: 1.25rem;\n}\n.style_title__Yghwr h1,\n.style_title__Yghwr h2 {\n  font-size: 1.25rem;\n  margin: 0;\n  padding: 0;\n}\n.style_actions__2dVA3 {\n  display: flex;\n  flex-direction: row;\n  justify-items: flex-start;\n  align-items: center;\n}\n.style_pageTitleLink__10IIo {\n  color: #333333;\n  text-decoration: none;\n}\n.style_pageTitleLink__10IIo > :active,\n.style_pageTitleLink__10IIo > :visited {\n  color: #333333;\n}\n.style_pageTitleLink__10IIo:hover {\n  color: #2f93e0;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "style_hide-sm__1rfbU",
	"hideSm": "style_hide-sm__1rfbU",
	"visible-sm": "style_visible-sm__2pCpd",
	"visibleSm": "style_visible-sm__2pCpd",
	"hide-md": "style_hide-md__3xdAx",
	"hideMd": "style_hide-md__3xdAx",
	"visible-md": "style_visible-md__1SgQn",
	"visibleMd": "style_visible-md__1SgQn",
	"titleBar": "style_titleBar__3251A",
	"title": "style_title__Yghwr",
	"actions": "style_actions__2dVA3",
	"pageTitleLink": "style_pageTitleLink__10IIo"
};