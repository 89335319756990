import * as React from "react";
import {ActionButton, ActionButtonType} from "components/Button/ActionButton";
import {
    DialogLayoutCommonProps,
    DialogLayoutDispatchProps,
    DialogLayout,
} from "components/DialogLayout/DialogLayout";
import { DialogLayoutConnect } from "components/Dialog/DialogLayoutConnect";
import PermissionCheck, { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import FormComponent from "../FormComponent/FormComponent";
const styles = require("./style.less");

interface OkDialogLayoutProps extends DialogLayoutCommonProps {
    okButtonLabel?: string;
    okButtonDisabled?: boolean;
    okButtonPermission?: PermissionCheckProps;
    hideCancel?: boolean;
    cancelButtonLabel?: string;
    onOkClick(): any;
}

class OkDialogLayoutInternal extends React.Component<OkDialogLayoutProps & DialogLayoutDispatchProps> {
    okClick = async () => {
        const result = await this.props.onOkClick();
        if (result) {
            this.props.close();
        }
    }

    submitForm = async () => {
        if (!this.isDisabled()) {
            this.okClick();
        }
    }

    isDisabled() {
        return this.props.okButtonDisabled || this.props.busy;
    }

    render() {
        const hasPermissionForOk = !!this.props.okButtonPermission ? isAllowed(this.props.okButtonPermission) : true;

        const {
            children,
            ...other
        } = this.props;

        const ok = <ActionButton key="Ok"
                                label={this.props.okButtonLabel || "Ok"}
                                disabled={this.isDisabled()}
                                onClick={this.okClick}
                                type={ActionButtonType.Primary}/>;
        const cancel = <ActionButton key="Cancel"
                                    label={this.props.cancelButtonLabel || "Cancel"}
                                    disabled={this.props.busy}
                                    onClick={() => this.props.close()}/>;
        const actions = [!this.props.hideCancel && cancel, hasPermissionForOk && ok].filter(a => !!a);

        return <DialogLayout actions={actions} closeDialog={this.props.close} {...other}>
            <FormComponent onFormSubmit={this.submitForm} className={styles.dialogWrapper}>{children}</FormComponent>
        </DialogLayout>;
    }
}

const OkDialogLayout = DialogLayoutConnect.to(OkDialogLayoutInternal);
OkDialogLayout.displayName = "OkDialogLayout";
export default OkDialogLayout;
