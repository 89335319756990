import * as React from "react";
import { CategorizedPackagingRegistration } from "../../Registry/packagingRegistry";
import AspNetCategory from "../../Definitions/AspNetCategoryDefinition";
import DotNetOtherCategory from "../../Definitions/DotNetOtherCategoryDefinition";
import { InfoRegistrationCards } from "../../RegistrationCards";
import {
    CommonCommandLineTextForOctoExe,
    CommonCommandLineTextForOctoExeCodeSample,
    CommonCommandLineTextForOctoPack,
} from "../CommonRegistrationText";

const CommandLinePackagingDialogView: React.SFC<{}> = () => (
    <div>
        <p>
            When packaging .NET applications from command line, we recommend using <i>OctoPack</i> or <i>Octo.exe</i>.
        </p>
        {CommonCommandLineTextForOctoPack}
        {CommonCommandLineTextForOctoExe}
        {CommonCommandLineTextForOctoExeCodeSample}
    </div>
);

export const CommandLinePackagingRegistrationForAspNet: CategorizedPackagingRegistration = {
    displayOrder: 200,
    categories: [AspNetCategory, DotNetOtherCategory],
    name: "Command Line",
    type: "CommandLineForAspNet",
    renderCard: InfoRegistrationCards.basic(
        () => <CommandLinePackagingDialogView />,
        ({ registration }) => ({
            logo: (<div />),
            header: registration.name,
            description: null,
        })
    )
};