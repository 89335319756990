exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MultiSelect_textContainer__1Hiv8 {\n  position: relative;\n}\n.MultiSelect_iconContainer__2tDbT {\n  position: absolute;\n  right: 0;\n  top: 0;\n  cursor: pointer;\n  margin: 0.5625rem;\n}\n.MultiSelect_error__26LJz {\n  font-Size: 0.75rem;\n  line-Height: 0.75rem;\n  color: #db4437;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n}\n.MultiSelect_label__2F3iK {\n  line-height: 22px;\n  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;\n  user-select: none;\n  color: #2f93e0;\n  font-size: 0.75rem;\n  font-weight: 400;\n}\n", ""]);

// exports
exports.locals = {
	"textContainer": "MultiSelect_textContainer__1Hiv8",
	"iconContainer": "MultiSelect_iconContainer__2tDbT",
	"error": "MultiSelect_error__26LJz",
	"label": "MultiSelect_label__2F3iK"
};