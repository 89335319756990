exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_components__1bMlU {\n  margin-top: 0;\n}\n.style_components__1bMlU li {\n  margin-top: 0.5rem;\n}\n.style_entry__T-e6K {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n.style_entry__T-e6K ul.style_bulletList__Rnq_R {\n  margin-left: 3rem;\n}\n.style_entry__T-e6K ul.style_bulletList__Rnq_R li {\n  list-style: disc;\n}\n.style_styleGuide__3LKOV {\n  margin-top: 2rem;\n  margin-left: 2rem;\n  display: flex;\n  flex-direction: row;\n}\n.style_styleGuide__3LKOV > ul {\n  list-style: none;\n}\n.style_styleGuide__3LKOV > ul > li {\n  margin-bottom: 5px;\n}\n.style_heading__37Hfg {\n  text-align: center;\n}\n.style_content__1pgSX {\n  margin-left: 2rem;\n}\n.style_context__30CVu {\n  display: block;\n  margin-bottom: 1rem;\n  padding: 1rem;\n  background-color: rgba(153, 153, 153, 0.08);\n  font-size: 0.875rem;\n  color: #666666;\n}\n.style_context__30CVu p {\n  font-size: 0.875rem;\n  color: #666666;\n}\nthead {\n  font-weight: 600;\n}\n.style_swatch__1eZgc {\n  width: 1.5rem;\n  height: 1.5rem;\n  border: 0.0625rem solid #cccccc;\n}\n.style_white__17nDI {\n  background: #ffffff;\n}\n.style_primaryText__2cu8m {\n  background: #333333;\n}\n.style_secondaryText__Y1P_c {\n  background: #9e9e9e;\n}\n.style_primary__1HggO {\n  background: #2f93e0;\n}\n.style_primaryLight__FNK52 {\n  background: #74c0fb;\n}\n.style_primaryDark__1Oj2r {\n  background: #0A67B1;\n}\n.style_primaryDarkest__3RfrO {\n  background: #0F2535;\n}\n.style_readonlyTextWithStrikethrough__3PIg2 > input {\n  text-decoration: line-through;\n  color: #9e9e9e !important;\n}\n", ""]);

// exports
exports.locals = {
	"components": "style_components__1bMlU",
	"entry": "style_entry__T-e6K",
	"bulletList": "style_bulletList__Rnq_R",
	"styleGuide": "style_styleGuide__3LKOV",
	"heading": "style_heading__37Hfg",
	"content": "style_content__1pgSX",
	"context": "style_context__30CVu",
	"swatch": "style_swatch__1eZgc",
	"white": "style_white__17nDI",
	"primaryText": "style_primaryText__2cu8m",
	"secondaryText": "style_secondaryText__Y1P_c",
	"primary": "style_primary__1HggO",
	"primaryLight": "style_primaryLight__FNK52",
	"primaryDark": "style_primaryDark__1Oj2r",
	"primaryDarkest": "style_primaryDarkest__3RfrO",
	"readonlyTextWithStrikethrough": "style_readonlyTextWithStrikethrough__3PIg2"
};