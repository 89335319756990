import * as React from "react";
import {DeploymentStepResource} from "client/resources/deploymentStepResource";
import Select from "components/form/Select/Select";
import {Callout, CalloutType} from "components/Callout/Callout";
import SpecialVariables from "client/specialVariables";
import {DataBaseComponent, DataBaseComponentState} from "components/DataBaseComponent/DataBaseComponent";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";

interface SelectParentStepProps {
    steps: DeploymentStepResource[];
    onStepSelected: (stepId: string) => void;
    actionName: string;
    currentlyTargetedRoles: string;
}

interface SelectParentStepState extends DataBaseComponentState {
    selectedStepId: string;
}

class SelectParentStep extends DataBaseComponent<SelectParentStepProps, SelectParentStepState> {
    constructor(props: SelectParentStepProps) {
        super(props);

        this.state = {
            selectedStepId: null
        };
    }

    render() {
        return <OkDialogLayout title="Move into Parent Step"
                               busy={this.state.busy}
                               errors={this.state.errors}
                               onOkClick={() => this.onOk()}>
            <p>Move the step <strong>{this.props.actionName}</strong> into the new parent step:</p>
            {this.changeToTargetRoles() &&
            <Callout type={CalloutType.Warning} title="Confirm target role change">
                This step previously targeted a different set of roles than the step you are moving it to.
                This means the step is now likely to run on a different set of machines. Are you sure you wish to move
                the step?
            </Callout>}
            <Select
                label="Select step"
                value={this.state.selectedStepId}
                error={this.state.errors && this.state.errors.fieldErrors.selectedStepId}
                items={this.props.steps.map(s => ({value: s.Id, text: s.Name}))}
                onChange={stepId => this.setState({selectedStepId: stepId, errors: null})}
                allowClear={true}
            />
        </OkDialogLayout>;
    }

    private onOk() {
        if (!this.state.selectedStepId) {
            this.setState({
                errors: {
                    message: "Please select a step",
                    details: null,
                    fieldErrors: {selectedStepId: "Select a step"}
                }
            });
            return false;
        }

        this.props.onStepSelected(this.state.selectedStepId);
        return true;
    }

    private changeToTargetRoles() {
        return this.state.selectedStepId &&
            this.props.currentlyTargetedRoles !== this.props.steps.find(s => s.Id === this.state.selectedStepId).Properties[SpecialVariables.Action.TargetRoles];
    }
}

export default SelectParentStep;