import * as React from "react";
import ActionButton from "components/Button/ActionButton";
import * as FormResource from "client/resources/form";
import Checkbox from "components/form/Checkbox/Checkbox";
import Markdown from "components/Markdown/index";
import Text from "components/form/Text/Text";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import OkDialogLayout from "components/DialogLayout/OkDialogLayout";
import Note from "components/form/Note/Note";
import {required} from "components/form/Validators";
import { client } from "clientInstance";
import {ControlType} from "../../client/resources";
import StringCheckbox from "../form/Checkbox/StringCheckbox";
import Select from "../form/Select/Select";
import selectOptionsToItems from "../form/Select/Options";

interface FormElementProps<TControl extends FormResource.Control> {
    control: TControl;
    disabled?: boolean;
    value: string;
    onChange(value: string): void;
}

const CheckboxFormElement = (props: FormElementProps<FormResource.Checkbox>) => <Checkbox label={props.control.Text}
                                                                                          disabled={props.disabled}
                                                                                          value={props.value === "True"}
                                                                                          onChange={(val) => props.onChange(val ? "True" : "False")}/>;

const TextAreaFormElement = (props: FormElementProps<FormResource.TextArea>) => {
    return <Text label={props.control.Label}
                 multiLine={true}
                 disabled={props.disabled}
                 onChange={props.onChange}
                 value={props.value}/>;
};

const VariableValueFormElement = (props: FormElementProps<FormResource.VariableValue>) => {
    return <div>
        <div>{getVariableInputControl(props)}</div>
        <Note>{props.control.Description}</Note>
    </div>;
};

const getVariableInputControl = (props: FormElementProps<FormResource.VariableValue>) => {
    const controlType = props.control.DisplaySettings && props.control.DisplaySettings["Octopus.ControlType"]
        ? props.control.DisplaySettings["Octopus.ControlType"]
        : ControlType.SingleLineText; // Control type defaults to single-line text

    switch (controlType) {
        case ControlType.SingleLineText:
        case ControlType.MultiLineText:
        case ControlType.Sensitive:
            return <Text label={props.control.Label}
                  multiLine={controlType === ControlType.MultiLineText}
                  disabled={props.disabled}
                  onChange={props.onChange}
                  type={controlType === ControlType.Sensitive ? "password" : ""}
                  validate={props.control.Required ? required(`Please enter a value for ${props.control.Label}`) : null}
                  value={props.value}/>;

        case ControlType.Checkbox:
            return <StringCheckbox
                value={props.value}
                label={props.control.Label}
                onChange={props.onChange}
                disabled={props.disabled}/>;

        case ControlType.Select:
            return <Select
                value={props.value}
                label={props.control.Label}
                items={selectOptionsToItems(props.control.DisplaySettings["Octopus.SelectOptions"])}
                onChange={props.onChange}
                disabled={props.disabled}
                allowClear={!props.control.Required}
                validate={props.control.Required ? required(`Please enter a value for ${props.control.Label}`) : null}/>;

        default:
           throw new Error("Unexpected control-type:" + controlType);
    }
};

const linkResolver = (match: string, p1: string, p2: string, offset: number, all: string) => {
    const url = p2.startsWith("~/") ? client.resolve(p2) : p2;
    return `[${p1}](${url})`;
};

const ParagraphFormElement = (props: {control: FormResource.Paragraph}) => {
    // if ResolveLinks is set, pass the target url on any Markdown links [text](url)
    // through our resolver so we can link within Octopus
    const markDown = props.control.ResolveLinks
        ? props.control.Text.replace(/\[([^\]]+)\]\(([^\)]+)\)/, linkResolver)
        : props.control.Text;
    return <Markdown markup={markDown}/>;
};

interface SubmitButtonGroupFormElementProps {
    control: FormResource.SubmitButtonGroup;
    disabled?: boolean;
    onSubmit(value: string): void;
}

const SubmitButtonGroupFormElement = (props: SubmitButtonGroupFormElementProps) => {
    return <div>
        {props.control.Buttons.map((btn, idx) => {
            if (btn.RequiresConfirmation) {
                return <OpenDialogButton label={btn.Text} disabled={props.disabled} key={idx}>
                    <OkDialogLayout title={`Confirm ${btn.Value}`}
                                    okButtonLabel="Yes"
                                    cancelButtonLabel="No"
                                    errors={null}
                                    busy={null}
                                    onOkClick={() => {
                                        props.onSubmit(btn.Value);
                                        return true;
                                    }}>
                        <div>Are you sure you want to {btn.Value}?</div>
                    </OkDialogLayout>
                </OpenDialogButton>;

            }
            return <ActionButton
                key={idx}
                disabled={props.disabled}
                label={btn.Text}
                onClick={() => {
                    props.onSubmit(btn.Value);
                }}/>;
        })}</div>;
};

interface GenericFormElementProps {
    element: FormResource.FormElement;
    value: string;
    disabled?: boolean;
    onChanged(value: string): void;
    onButtonSelected(name: string): void;
}

const FormResourceInput = (props: GenericFormElementProps) => {
    //IsValue Required
    const {disabled, element, value} = props;
    const control = element.Control;
    switch (control.Type) {
        case FormResource.ControlType.Paragraph: {
            return <ParagraphFormElement control={control as FormResource.Paragraph}/>;
        }
        case FormResource.ControlType.Checkbox: {
            return <CheckboxFormElement control={control as FormResource.Checkbox}
                                        disabled={disabled}
                                        onChange={props.onChanged}
                                        value={value}/>;
        }
        case FormResource.ControlType.TextArea: {
            return <TextAreaFormElement control={control as FormResource.TextArea}
                                        disabled={disabled}
                                        onChange={props.onChanged}
                                        value={value}/>;
        }
        case FormResource.ControlType.VariableValue: {
            return <VariableValueFormElement control={control as FormResource.VariableValue}
                                             disabled={disabled}
                                             onChange={props.onChanged}
                                             value={value}/>;
        }
        case FormResource.ControlType.SubmitButtonGroup: {
            return <SubmitButtonGroupFormElement control={control as FormResource.SubmitButtonGroup}
                                                 disabled={disabled}
                                                 onSubmit={props.onButtonSelected} />;
        }
        default:
            console.warn(`Unknown control type ${control.Type}`);
            return <div>Unknown control type "{control.Type}"</div>;
    }
};

export default FormResourceInput;