var escape = require("../../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__8Wtl6 {\n  margin: 0.5rem 0;\n}\n.style_ok__2Vmy8 {\n  background-image: url(" + escape(require("../PackagesList/shipment-ok.svg")) + ");\n  line-height: 1.6rem;\n  background-repeat: no-repeat;\n  padding-left: 1.75rem;\n  margin-left: 0;\n  margin-bottom: 0.0625rem;\n  background-size: 1.5rem auto;\n}\n.style_error__SP1JQ {\n  background-image: url(" + escape(require("../PackagesList/shipment-error.svg")) + ");\n  line-height: 1.6rem;\n  background-repeat: no-repeat;\n  padding-left: 1.75rem;\n  margin-left: 0;\n  margin-bottom: 0.0625rem;\n  background-size: 1.5rem auto;\n}\ndfn {\n  font-style: normal;\n  color: #333333;\n  margin-left: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__8Wtl6",
	"ok": "style_ok__2Vmy8",
	"error": "style_error__SP1JQ"
};