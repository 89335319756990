import * as React from "react";
import * as cn from "classnames";
import BusyFromPromise from "../BusyFromPromise/BusyFromPromise";
import BusyIndicator from "../BusyIndicator/BusyIndicator";
import SectionTitle from "components/SectionTitle";
import { Errors } from "components/DataBaseComponent/DataBaseComponent";
import ErrorPanel from "components/ErrorPanel/ErrorPanel";
const styles = require("./style.less");
import Sticky, { StickyStatus } from "components/Sticky/Sticky";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import PageTitleHelper from "utils/PageTitleHelper";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";

export interface BreadcrumbProps {
    breadcrumbTitle?: string; // If specified, this text will display above your layout's title.
    breadcrumbPath?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    breadcrumbChip?: JSX.Element;
}

interface PaperLayoutProps extends BreadcrumbProps {
    title?: any;
    titleLogo?: JSX.Element;
    sectionControl?: any;
    busy?: Promise<any> | boolean;
    enableLessIntrusiveLoadingIndicator?: boolean;
    errors?: Errors;
    className?: string;
    innerClassName?: string;
    fullWidth?: boolean;
    flatStyle?: boolean;
    disableAnimations?: boolean;
    disableHeaderAnimations?: boolean;
}

interface PaperLayoutState {
    stickyState: StickyStatus;
}

export const headerId = "paperlayoutstickyheader";

class PaperLayout extends React.Component<PaperLayoutProps, PaperLayoutState> {
    constructor(props: PaperLayoutProps) {
        super(props);
        PageTitleHelper.setPageTitle(props.title, props.breadcrumbTitle);
        this.state = {
            stickyState: 0
        };
    }

    componentWillReceiveProps(nextProps: PaperLayoutProps) {
        // For various screen, we update the title dynamically...
        if (this.props.title !== nextProps.title) {
            PageTitleHelper.setPageTitle(nextProps.title, nextProps.breadcrumbTitle);
        }
    }

    renderHeader() {
        if (this.props.title || this.props.sectionControl) {
            return <Sticky top="#toppanel" innerZ={10}
                onStateChange={x => this.setState({ stickyState: x.status })}>
                <div id={headerId} className={styles.headerContainer}>
                    {this.renderBusy()}
                    <TransitionAnimation disableAnimation={this.props.disableAnimations || this.props.disableHeaderAnimations}>
                        <SectionTitle title={this.props.title}
                            titleLogo={this.props.titleLogo}
                            breadcrumbTitle={this.props.breadcrumbTitle}
                            breadcrumbPath={this.props.breadcrumbPath}
                            breadcrumbChip={this.props.breadcrumbChip}
                            sectionControl={this.props.sectionControl}
                            className={cn(styles.title, { [styles.stickied]: this.state.stickyState === StickyStatus.STATUS_FIXED })}
                            busy={this.props.busy}
                            enableLessIntrusiveLoadingIndicator={this.props.enableLessIntrusiveLoadingIndicator}
                        />
                    </TransitionAnimation>
                </div>
            </Sticky>;
        }
        return this.renderBusy();
    }

    renderBusy() {
        // Note: Don't show the horizontal loading bar on subsequent auto-reloads (it "blips" too quickly and annoys everybody).
        return <div className={styles.busyFromPromise}>
            <BusyFromPromise promise={this.props.busy}>
                {(busy: boolean) => <BusyIndicator show={busy && !this.props.enableLessIntrusiveLoadingIndicator} />}
            </BusyFromPromise>
        </div>;
    }

    renderErrors() {
        const errors = this.props.errors;
        if (!errors) {
            return null;
        }
        return <ErrorPanel message={errors.message}
            details={errors.details}
            detailLinks={errors.detailLinks}
            helpText={errors.helpText}
            fullException={errors.fullException}
            helpLink={errors.helpLink}
        />;
    }

    render() {
        return <div className={cn(styles.container, this.props.className)}>
            <div className={cn(this.props.flatStyle ? styles.paperContentFlat : styles.paperContent, this.props.fullWidth && styles.paperFullWidth, this.props.innerClassName)}>
                {this.renderHeader()}
                <TransitionAnimation>
                    {this.renderErrors()}
                    {this.props.children}
                </TransitionAnimation>
            </div>
        </div>;
    }
}

export default PaperLayout;
export { PaperLayoutProps };