exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_warningList__2c-sY {\n  list-style-type: disc;\n  margin-left: 2rem;\n}\n", ""]);

// exports
exports.locals = {
	"warningList": "style_warningList__2c-sY"
};