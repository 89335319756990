exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_container__KRYx2 {\n  display: flex;\n  width: 100%;\n  align-items: flex-end;\n}\n.style_selectedIcon__sA0M_ {\n  top: 0.2rem;\n  position: relative;\n  padding-right: 0.5rem;\n}\n.style_outerContainer__2cu41 {\n  width: 100%;\n}\n.style_innerContainer__3mocN {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.style_actions__1dJnI {\n  margin-left: 0.5rem;\n}\n.style_select__3VB4M {\n  flex: 1 1 auto;\n}\n", ""]);

// exports
exports.locals = {
	"container": "style_container__KRYx2",
	"selectedIcon": "style_selectedIcon__sA0M_",
	"outerContainer": "style_outerContainer__2cu41",
	"innerContainer": "style_innerContainer__3mocN",
	"actions": "style_actions__1dJnI",
	"select": "style_select__3VB4M"
};