exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".style_pageBanners__1DkZ_ {\n  display: flex;\n  flex-direction: column;\n  background-color: white;\n}\n.style_warning__luMpL {\n  width: 100%;\n  text-align: center;\n  white-space: pre-line;\n}\n", ""]);

// exports
exports.locals = {
	"pageBanners": "style_pageBanners__1DkZ_",
	"warning": "style_warning__luMpL"
};