exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media (max-width: 37.4rem) {\n  .CustomDialog_hide-sm__1IXko {\n    display: none !important;\n  }\n}\n@media (min-width: 37.4rem) {\n  .CustomDialog_visible-sm__521U3 {\n    display: none !important;\n  }\n}\n@media (max-width: 79.9rem) {\n  .CustomDialog_hide-md__1k62K {\n    display: none !important;\n  }\n}\n@media (min-width: 79.9rem) {\n  .CustomDialog_visible-md__3IQ_a {\n    display: none !important;\n  }\n}\n.CustomDialog_root__OySTg {\n  flex: auto;\n  display: flex;\n  flex-direction: column;\n}\n.CustomDialog_root__OySTg > * {\n  flex: auto;\n}\n.CustomDialog_overlay__OyXqY {\n  background-color: rgba(0, 0, 0, 0.6);\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 10;\n}\n.CustomDialog_noscroll__33ReA {\n  overflow-x: hidden;\n  overflow-y: hidden;\n}\n", ""]);

// exports
exports.locals = {
	"hide-sm": "CustomDialog_hide-sm__1IXko",
	"hideSm": "CustomDialog_hide-sm__1IXko",
	"visible-sm": "CustomDialog_visible-sm__521U3",
	"visibleSm": "CustomDialog_visible-sm__521U3",
	"hide-md": "CustomDialog_hide-md__1k62K",
	"hideMd": "CustomDialog_hide-md__1k62K",
	"visible-md": "CustomDialog_visible-md__3IQ_a",
	"visibleMd": "CustomDialog_visible-md__3IQ_a",
	"root": "CustomDialog_root__OySTg",
	"overlay": "CustomDialog_overlay__OyXqY",
	"noscroll": "CustomDialog_noscroll__33ReA"
};