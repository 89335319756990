import * as React from "react";

import {DeploymentResource, EnvironmentResource} from "client/resources";

import {
    DataTableBody,
} from "components/DataTable";
import DeploymentRow from "./DeploymentRow";
import DeploymentChildRow from "./DeploymentChildRow";
import {PhaseResource} from "client/resources";
import {LifecycleStatus} from "../../../../../utils/MapProgressionToStatus/MapProgressionToStatus";
import {DataBaseComponent, DataBaseComponentState} from "../../../../../components/DataBaseComponent/DataBaseComponent";
import {TaskResource} from "../../../../../client/resources/taskResource";

interface PhaseDeploymentsProps {
    title?: string;
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus;
    deployments: DeploymentResource[];
    deploymentTasks: Array<TaskResource<{DeploymentId: string}>>;
    environmentsById: { [id: string]: EnvironmentResource };
    actionButton?: JSX.Element | null;
    [others: string]: any;
}

interface PhaseDeploymentsState extends DataBaseComponentState {
    showChildRows: boolean;
}
export default class PhaseDeployments extends DataBaseComponent<PhaseDeploymentsProps, PhaseDeploymentsState> {
    constructor(props: PhaseDeploymentsProps) {
        super(props);
        this.state = {
            showChildRows: false,
        };
    }

    handleToggleChildRows = () => {
        this.setState({
            showChildRows: !this.state.showChildRows
        });
     }

    render() {
        return (
            <DataTableBody>
                {this.props.deploymentTasks && this.renderDeploymentRows()}
            </DataTableBody>
        );
    }

    private renderDeploymentRows() {
        return this.props.deployments.map((deployment, index) => {
            const task = this.props.deploymentTasks.filter(deploymentTask => deploymentTask.Arguments.DeploymentId === deployment.Id)[0];
            return index === 0
                ? <DeploymentRow
                    key={index}
                    deployment={deployment}
                    task={task}
                    onToggleChildRows={this.handleToggleChildRows}
                    showChildRows={this.state.showChildRows}
                    actionButton={this.props.actionButton}
                    {...this.props} />
                : <DeploymentChildRow
                    key={index}
                    deployment={deployment}
                    task={task}
                    showChildRows={this.state.showChildRows}
                    {...this.props} />;
        });
    }
}